import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import history from 'utils/history';
import getFullName from 'utils/getFullName';
import PayloadMappers from 'utils/PayloadMappers';
import ResponseMappers from 'utils/ResponseMappers';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import useTabs from 'hooks/useTabs';
import useAdminRestriction from 'hooks/useAdminRestriction';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import Loading from 'components/Loading';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import Tabs from 'components/Tabs';
import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import TabPanels from 'components/TabPanels';
import { useStores } from 'components/StateProvider';

import DetailsContainer from './DetailsContainer';
import {
  editVideo,
  fetchAdminVideoDetails,
  videoTabItems,
  sendAddVideo,
} from './HelperFunction';

const tabIdMapper = ['video'];

const handleRedirectToVideoList = () => history.push('/admin/articles-events');

const VideoDetails = () => {
  const {
    auth: { user },
  } = useStores();
  const {
    isDialogOpen: isTabAlertDialogOpen,
    handleOpenDialog: handleOpenTabAlertDialog,
    handleCloseDialog: handleCloseTabAlertDialog,
  } = useDialog();
  const { haveCrudPermission } = useAdminRestriction();

  const videoFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fundType] = useState(searchParams.get('type'));
  const [fetchStatus, setFetchStatus] = useState(true);
  const [isError, setIsError] = useState(false);
  // Manually manage 'dirty', because the form value is a deep object.
  const [dirty, setDirty] = useState(false);
  const { videoId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    isDialogOpen: openSuccessDialog,
    handleOpenDialog: handleOpenSuccessDialog,
    handleCloseDialog: handleCloseSuccessDialog,
  } = useDialog({
    onClose: (videoUid) => {
      if (!videoId && videoUid) {
        history.push(`/admin/videos/${videoUid}`);

        return;
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
  });

  const adminGetVideoDetails = useMemo(
    () => fetchAdminVideoDetails(videoId),
    [videoId, fetchStatus],
  );

  const { apiData: dataValues, isFetchLoading } = useFetchApi({
    url: adminGetVideoDetails,
    defaultApiData: {},
    dependencies: [adminGetVideoDetails],
  });

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  ); // trigger useMemo changes to refetch

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    setValues,
    handleChange,
  } = useFormik({
    initialValues: videoId ? {} : ResponseMappers.admin.video({}),
  });

  useEffect(() => {
    if (!isFetchLoading && Object.keys(dataValues).length)
      setValues(ResponseMappers.admin.video(dataValues));
  }, [isFetchLoading]);

  const handleSubmit = useCallback(
    async (formValues) => {
      try {
        setIsLoading(true);

        const payload = PayloadMappers.admin.video.addVideo({
          ...formValues,
          videoUid: values.video.videoUid,
          isHidden: values.isHidden,
          submitToDraft: true,
          createdBy: getFullName(user),
          updatedBy: getFullName(user),
        });

        let res;
        if (!videoId) {
          res = await sendAddVideo({ payload });
        } else {
          res = await editVideo({ id: videoId, payload });
        }

        if (res?.video?.id) {
          handleOpenSuccessDialog(res.video.videoUid);
        }

        setDirty(false);

        return res;
      } finally {
        setIsLoading(false);
      }
    },
    [values.video?.videoUid, values.isHidden, values.isFeatured],
  );

  const handleSaveDraftVideo = useCallback(
    () => videoFormRef.current?.handleSubmit?.(),
    [handleSubmit, videoFormRef.current?.handleSubmit],
  );

  const defaultTabIdx = useMemo(() => {
    if (!values.videoUid) return 0;

    const index = tabIdMapper.indexOf(searchParams.get('tab'));
    if (index === -1) return 0;
    return index;
  }, []);

  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx,
    callbackDependencies: [values.videoUid],
  });

  useEffect(() => {
    searchParams.set('tab', tabIdMapper[activeTabIdx]);
    setSearchParams(searchParams);
  }, [activeTabIdx]);

  useEffect(() => {
    setIsError(!!Object.values(errors).filter((err) => !!err).length);
  }, [errors, values.video?.title]);

  const handleSetFieldValue = useCallback(
    (field, value, shouldValidate, updateDirty = true) => {
      if (updateDirty) setDirty(true);

      setFieldValue(field, value, shouldValidate);
    },
    [setFieldValue],
  );

  const memoizedTabPanelItems = useMemo(
    () =>
      videoTabItems({
        setFieldValue: handleSetFieldValue,
        setValues,
        setFieldError,
        setDirty,
        onSubmit: handleSubmit,
        videoId: values.videoId || videoId,
        readOnly: !haveCrudPermission,
        fundType,
      }),
    [handleSubmit, values.videoId, haveCrudPermission],
  );

  const onTabChange = useCallback(
    (_props, index) => {
      if ((videoId || values.videoId) && !dirty)
        return handleTabChange(_props, index);

      return handleOpenTabAlertDialog(index.toString());
    },
    [values.videoId, dirty],
  );

  const tabAlertDialogProps = useMemo(() => {
    if (videoId || values.videoId)
      return {
        subtitle: 'Do you want to discard the changes you made?',
        onClose: handleCloseTabAlertDialog,
        confirmButtonLabel: 'Discard',
        onConfirm: () => {
          handleTabChange({}, Number(isTabAlertDialogOpen));
          handleCloseTabAlertDialog();
          setDirty(false);
        },
      };

    return {
      subtitle: 'Please submit Video information first',
      onConfirm: handleCloseTabAlertDialog,
      confirmButtonLabel: 'Ok',
    };
  }, [values.videoUid, isTabAlertDialogOpen]);

  const headerInfoValues = useMemo(
    () => ({
      videoId: values.video?.id || videoId,
      videoUid: values.video?.videoUid,
      isFeatured: values.isFeatured,
      isHidden: values.isHidden,
      createdBy: values.createdBy,
      createdAt: values.createdAt,
      updatedBy: values.updatedBy,
      updatedAt: values.updatedAt,
    }),
    [
      values.video?.videoUid,
      values.isFeatured,
      values.isHidden,
      values.videoId,
      values.createdBy,
      values.createdAt,
      values.updatedAt,
      values.updatedBy,
      videoId,
    ],
  );

  return (
    <DetailsContainer
      refetch={handleRefetch}
      fundStatus={values.status}
      disableSaveDraftBtn={isError}
      onBack={handleRedirectToVideoList}
      onChange={handleChange}
      values={headerInfoValues}
      backButtonLabel={videoId ? 'Video Details' : 'Add new Video'}
      onSaveDraft={handleSaveDraftVideo}
    >
      {isFetchLoading || !Object.keys(values).length ? (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item textAlign="center">
            <CircularProgress size={25} data-testid="progress-screen" />
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={4} data-testid="video-details-content">
          <Grid item xs={12}>
            <Tabs
              key={`${values.video?.id}-${dirty}-${isTabAlertDialogOpen}`}
              value={activeTabIdx}
              onChange={onTabChange}
              centered={false}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              <TabComponents
                activeTabIdx={activeTabIdx}
                tabItems={memoizedTabPanelItems}
                testIdPrefix="add-videos-tab"
              />
            </Tabs>
          </Grid>
          <Grid item xs>
            <TabPanels
              value={activeTabIdx}
              tabItems={memoizedTabPanelItems}
              videoProps={{
                values: values.video,
                errors: !!errors.video,
                ref: videoFormRef,
                imperativeHandleDepedencies: [handleSubmit],
              }}
              boxPanelProps={{ sx: { padding: 0 } }}
            />
          </Grid>
        </Grid>
      )}

      <ConfirmationDialog
        open={!!isTabAlertDialogOpen}
        title="Warning!"
        infoText=""
        {...tabAlertDialogProps}
        dialogContentDependencies={[tabAlertDialogProps.onConfirm]}
      />

      <SuccessDialog
        open={openSuccessDialog}
        onConfirm={handleCloseSuccessDialog}
      />

      <Loading loading={isLoading} popup />
    </DetailsContainer>
  );
};

export default VideoDetails;
