import React, { memo, useMemo } from 'react';
import CardActions from '@mui/material/CardActions';
import { useMediaQuery } from '@mui/material';

import palette from 'theme/palette';
import { FUND_CARD_DEFAULT_CONTENTS } from 'constants/general';
import ShowMe from 'components/ShowMe';

import FundCardHeader from './components/Header/FundCardHeader';
import FundCardStatus from './components/Text/FundCardStatus';
import FundCardTitle from './components/Text/FundCardTitle';
import FundCardDesc from './components/Text/FundCardDesc';
import FundCardButton from './components/FundCardButton';
import FundCardContainer from './components/FundCardContainer';
import FundCardContent from './components/FundCardContent';
import FundCardContentContainer from './components/FundCardContentContainer';

const CardActionStyles = { justifyContent: 'center' };

const FundCardPortrait = ({
  contentList = FUND_CARD_DEFAULT_CONTENTS,
  headerImageSrc = '',
  headerBgColor = palette.reach.purple,
  headerImageProps,
  logoSrc = '',
  status = 'Open',
  title = '[Fund name]',
  fundType,
  // description = '[Fund short desc]',
  buttonLabel = 'View details',
  onClickViewDetail,
  disabled = false,
  width,
  isOpen,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const btnLabel = useMemo(
    () => (isOpen ? buttonLabel : 'Fund Closed'),
    [buttonLabel, isOpen],
  );

  return (
    <FundCardContainer disabled={disabled} maxWidth={width}>
      {/* Header */}
      <ShowMe when={!isMobileScreen}>
        <FundCardHeader
          logoSrc={logoSrc}
          headerBgColor={headerBgColor}
          headerImageSrc={headerImageSrc}
          headerImageProps={headerImageProps}
          width={width}
          disabled={disabled}
          mode="portrait"
        />
      </ShowMe>

      {/* Status */}
      <FundCardStatus text={status} statusColor="black" isOpen={isOpen} />

      {/* Content */}
      <FundCardContentContainer disabled={disabled}>
        <FundCardTitle text={title} />
        <FundCardDesc text={fundType} />
        {/* <FundCardDesc text={description} /> */}
        <FundCardContent contentList={contentList} />
      </FundCardContentContainer>

      {/* Actions */}
      <CardActions sx={CardActionStyles}>
        <FundCardButton
          label={btnLabel}
          onClick={onClickViewDetail}
          buttontype={isOpen ? '' : 'primary'}
          disabled={!isOpen}
        />
      </CardActions>
    </FundCardContainer>
  );
};
export default memo(FundCardPortrait);
