import React, { memo, useCallback, useContext, useMemo } from 'react';

import Accordion from 'components/Accordion';
import Checkbox from 'components/Checkbox';
import SummarySection from 'components/SummarySection';
import InvestorCard from 'components/InvestorCard';

import mapValue from 'utils/mapValue';

import {
  SUMMARY_FIELDS_TRUST_PROFILE,
  SUMMARY_FIELDS_ACCOUNTANT,
} from 'constants/investmentEntity';
import trustProfileValidationSchemas from 'validations/schema/addInvestment/trustProfileValidationSchemas';

import IndividualTrusteeSummary from './IndividualTrusteeSummary';
import CompanyTrusteeSummary from './CompanyTrusteeSummary';

const TrusteeDetailsSummary = ({
  onChange,
  values,
  setValues,
  onBlur,
  touched,
  errors,
  TrustContext,
  TrustActionContext,
}) => {
  const { values: { trustProfile: trustProfileValues } = {} } = mapValue(
    useContext(TrustContext),
    {},
  );
  const { setValues: trustProfileSetValues } = mapValue(
    useContext(TrustActionContext),
    {},
  );

  const handleSetValues = useCallback(
    (index) => (newValues) => {
      const newTrustees = [...values.trustees];

      newTrustees[index] = newValues;

      setValues({ ...values, trustees: newTrustees });
    },
    [values.trustees],
  );

  const handleDeleteTrustee = useCallback(
    (index) => {
      const newTrustees = [...values.trustees];
      newTrustees.splice(index, 1);

      setValues({ ...values, trustees: newTrustees, activeTrustee: 0 });
    },
    [values.trustees],
  );

  const remappedAccountantSummaryFields = useMemo(
    () => [
      {
        ...SUMMARY_FIELDS_ACCOUNTANT[0],
        name: 'trustProfileDocuments',
      },
    ],
    [],
  );

  return (
    <>
      <InvestorCard
        showHeader={false}
        cardBodyProps={{
          style: { borderRadius: '5px' },
        }}
      >
        <SummarySection
          fields={SUMMARY_FIELDS_TRUST_PROFILE}
          setValues={trustProfileSetValues(false)}
          values={trustProfileValues}
          formName="trustProfileForm"
          validationSchema={trustProfileValidationSchemas[0]}
          fieldDividerStyles={{
            margin: '10px 0',
          }}
          title="Trust Details"
        />
        <SummarySection
          fields={remappedAccountantSummaryFields}
          setValues={trustProfileSetValues(true)}
          values={trustProfileValues}
          formName="trustAccountantCertificateForm"
          validationSchema={trustProfileValidationSchemas[1]}
          fieldDividerStyles={{
            margin: '10px 0',
          }}
          title="Accountant's Certificate"
        />
      </InvestorCard>
      <br />
      {values.trustees.map((trustee, index) => (
        <Accordion
          id={index.toString()}
          key={`${index.toString()}-${trustee?.type}`}
          label="Trustee"
          onDelete={index > 0 && handleDeleteTrustee}
        >
          {trustee?.type === 'individual' ? (
            <IndividualTrusteeSummary
              setValues={handleSetValues(index)}
              values={trustee}
            />
          ) : (
            <CompanyTrusteeSummary
              setValues={handleSetValues(index)}
              values={trustee}
            />
          )}
        </Accordion>
      ))}
      <Checkbox
        checked={!!values.isConfirmedSummary}
        error={touched.isConfirmedSummary && errors.isConfirmedSummary}
        label="I confirm that the above information is true and correct"
        name="isConfirmedSummary"
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};

export default memo(TrusteeDetailsSummary);
