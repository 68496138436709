import initialState from '../states/initial';

const auth = (state, action) => {
  switch (action.type) {
    case '@@auth/LOGIN_SUCCESS': {
      return {
        ...state,
        auth: action.payload,
      };
    }
    case '@@auth/LOGOUT_SUCCESS': {
      return {
        ...initialState,
        general: { ...state.general },
      };
    }
    case '@@auth/REFRESH_TOKEN_SUCCESS': {
      return {
        ...state,
        auth: {
          ...state.auth,
          token: action.payload.token,
          tokenExpiry: action.payload.tokenExpiry,
        },
      };
    }
    default:
      return state;
  }
};

export default auth;
