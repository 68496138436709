import React, { memo } from 'react';
import Accordion from 'components/Accordion';
import CompanyDetailsSummary from 'containers/AddInvestmentEntity/components/Forms/CompanyDetailsSummary';
import LabelWithChip from 'components/LabelWithChip';

const CompanyDetail = ({
  values,
  label = 'Company Details',
  withChip = false,
  withEdit = false,
  setValues,
  certificateDownloadable,
  onDelete,
}) => (
  <Accordion
    label={
      <LabelWithChip
        label={label}
        chipLabel={withChip && 'Company'}
        chipColor="joint"
      />
    }
    labelVariant="cardNameLabel"
    onDelete={onDelete}
  >
    <CompanyDetailsSummary
      values={values}
      withEdit={withEdit}
      sectionListView
      setValues={setValues}
      certificateDownloadable={certificateDownloadable}
    />
  </Accordion>
);

export default memo(CompanyDetail);
