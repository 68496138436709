import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';

const VerificationAlreadyActivated = ({ handleLogin }) => (
  <Grid
    container
    spacing={3}
    direction="row"
    style={{
      maxHeight: '-webkit-fill-available',
      textAlign: '-webkit-center',
      marginTop: '30px',
    }}
  >
    <Grid item xs={24} mt="30px">
      <Typography variant="h1">Account already activated</Typography>
    </Grid>

    <Grid item xs={24}>
      <Typography
        variant="body2"
        style={{
          color: '#7C8189',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19px',
        }}
      >
        This account has already been activated. You may click on the login
        button below.
      </Typography>
    </Grid>

    <Grid item xs={24} mt={10}>
      <Button
        style={{
          width: '350px',
          height: '55px',
        }}
        onClick={handleLogin}
      >
        Login to dashboard
      </Button>
    </Grid>
  </Grid>
);
export default memo(VerificationAlreadyActivated);
