import documentSigningValidationSchema from './documentSigningValidationSchema';
import fundIvestmentAmountValidationSchema from './fundIvestmentAmountValidationSchema';

const investmentApplicationSchemas = [
  null,
  fundIvestmentAmountValidationSchema,
  documentSigningValidationSchema,
];

export default investmentApplicationSchemas;
