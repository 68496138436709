import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import config from 'utils/config';

import Footer from './Footer';
import Header from './Header';

const DetailsPageContainer = ({
  children,
  childrenContainerProps = {},
  // header props
  onBackButtonClick,
  backButtonLabel,
  backButtonSubLabel,
  status,
  // footer props
  onSaveDraft,
  disableSaveDraftBtn,
  onDownloadClick,
  onCancel,
  onDelete,
  deleteBtnDisabled,
  downloadBtnDisabled,
  onRevertDraft,
  onSubmitClick,
  submitBtnDisabled,
  submitBtnLabel,
  onPreviewClick,
  footer,
}) => (
  <Paper
    elevation={3}
    sx={{
      padding: '20px',
      background: config.system.appColours.cardBackground,
    }}
  >
    <Grid container rowSpacing={4}>
      <Header
        onBackButtonClick={onBackButtonClick}
        backButtonLabel={backButtonLabel}
        sublabel={backButtonSubLabel}
        status={status}
      />
      <Grid item xs={12} {...childrenContainerProps}>
        {children}
      </Grid>
      {footer || (
        <Footer
          onSaveDraft={onSaveDraft}
          disableSaveDraftBtn={disableSaveDraftBtn}
          onDownloadClick={onDownloadClick}
          downloadBtnDisabled={downloadBtnDisabled}
          onCancel={onCancel}
          onDelete={onDelete}
          deleteBtnDisabled={deleteBtnDisabled}
          onRevertDraft={onRevertDraft}
          onSubmitClick={onSubmitClick}
          submitBtnDisabled={submitBtnDisabled}
          submitBtnLabel={submitBtnLabel}
          onPreviewClick={onPreviewClick}
        />
      )}
    </Grid>
  </Paper>
);

export default DetailsPageContainer;
