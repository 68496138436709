import PayloadMappers from './PayloadMappers';
import ValueOmmiters from './ValueOmmiters';
import getAutoPopulateChipLabel from './getAutoPopulateChipLabel';

const mapDraftedAutoPopulatePersonalDetails = (draftPersonalDetails) =>
  draftPersonalDetails.reduce(
    (personalDetails, personalDetail) => {
      const userDetail = PayloadMappers.personalDetails(personalDetail);

      const newPersonalDetail = {
        ...ValueOmmiters.personalDetails(personalDetail),
        label: getAutoPopulateChipLabel.personalLabel(userDetail),
      };

      const newAddress = {
        ...ValueOmmiters.addressDetails(personalDetail),
        label: getAutoPopulateChipLabel.addressLabel(userDetail),
      };

      return {
        personals: newPersonalDetail.title
          ? personalDetails?.personals.concat(newPersonalDetail)
          : personalDetails?.personals,
        addresses: newAddress.firstAddress
          ? personalDetails?.addresses.concat(newAddress)
          : personalDetails?.addresses,
      };
    },
    { personals: [], addresses: [] },
  );

export default mapDraftedAutoPopulatePersonalDetails;
