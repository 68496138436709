import React, { useCallback, useState } from 'react';

import Grid from '@mui/material/Grid';

import FormTitle from 'components/FormTitle';
import InputField from 'components/Input';
import FieldContainer from 'components/FieldContainer';
import Dropdown from 'components/Dropdown';

import useInvestmentEntityDropdown from 'hooks/useInvestmentEntityDropdown';

import AddEntityDialog from '../UserDetails/InvestmentEntityList/AddEntityDialog';

const EntitySection = ({
  onChange,
  values,
  errors,
  investmentEntities,
  funds,
  fundConfigurations,
  fundConfiguration,
  userData,
}) => {
  const [openAddEntityDialog, setOpenAddEntityDialog] = useState(false);

  const handleOpenAddEntityDialog = useCallback(() => {
    setOpenAddEntityDialog(true);
  }, []);

  const handleCloseAddEntityDialog = useCallback(() => {
    setOpenAddEntityDialog(false);
  }, []);

  const { InvestmentEntityDropdownComponent } = useInvestmentEntityDropdown({
    investmentEntities,
    onClickAddInvestmentEntity: handleOpenAddEntityDialog,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormTitle title="Entity" />
      </Grid>

      <Grid container item xs={12} p={2} rowSpacing={3}>
        <FieldContainer
          item
          label="User"
          labelGridProps={{ xs: 3 }}
          childGridProps={{ xs: 9, lg: 5, xl: 4.5 }}
        >
          <InputField
            fullWidth
            disabled
            data-testid="investmentFund-user-name"
            id="userName"
            name="userName"
            placeholder="User"
            onChange={onChange}
            value={values.userName}
          />
        </FieldContainer>
        <FieldContainer
          item
          label="User Entity"
          labelGridProps={{ xs: 3 }}
          childGridProps={{ xs: 9, lg: 5, xl: 4.5 }}
        >
          <InvestmentEntityDropdownComponent
            data-testid="investmentFund-entity"
            placeholder="Select Entity"
            onChange={onChange}
            name="userEntity"
            value={values.userEntity}
            error={!!errors.userEntity}
            errorValue={errors.userEntity}
          />
        </FieldContainer>
        <FieldContainer
          item
          label="Fund"
          labelGridProps={{ xs: 3 }}
          childGridProps={{ container: true, xs: true, columnGap: 2 }}
        >
          <Grid item xs={6}>
            <Dropdown
              data-testid="investmentFund-fund"
              fullWidth={false}
              id="fund"
              name="fund"
              placeholder="Please select fund"
              options={funds}
              value={values.fund}
              onChange={onChange}
            />
          </Grid>
        </FieldContainer>
        <FieldContainer
          item
          label="Fund Configurations"
          labelGridProps={{ xs: 3 }}
          childGridProps={{ container: true, xs: true, columnGap: 2 }}
        >
          <Grid item xs={6}>
            <Dropdown
              data-testid="investmentFund-fundConfiguration"
              fullWidth={false}
              id="fundConfiguration"
              name="fundConfiguration"
              placeholder="Please select a Fund Configuration"
              options={fundConfigurations}
              value={values.fundConfiguration}
              onChange={onChange}
            />
          </Grid>
        </FieldContainer>
        <FieldContainer
          item
          label="Fund Information"
          labelGridProps={{ xs: 3 }}
          childGridProps={{
            container: true,
            xs: true,
            columnGap: 2,
            rowSpacing: 2,
          }}
        >
          <Grid container direction="row" item xs={6} columnSpacing={2}>
            <FieldContainer item xs={5} label="Currency" alignItems="unset">
              <InputField
                fullWidth
                disabled
                data-testid="investmentFund-dealCurrency"
                id="dealCurrency"
                name="dealCurrency"
                placeholder="--"
                value={fundConfiguration.dealCurrency}
              />
            </FieldContainer>
            <FieldContainer item xs={7} label="Unit Price" alignItems="unset">
              <InputField
                fullWidth
                disabled
                data-testid="investmentFund-sharePrice"
                id="sharePrice"
                name="sharePrice"
                placeholder="---"
                value={fundConfiguration.sharePrice}
              />
            </FieldContainer>
          </Grid>
          <Grid container direction="row" item xs>
            <FieldContainer
              item
              xs={12}
              label="Callable (on applicable)"
              alignItems="unset"
            >
              <InputField
                fullWidth
                disabled
                data-testid="investmentFund-callable"
                id="callable"
                name="callable"
                placeholder="%"
                value={fundConfiguration.callable}
              />
            </FieldContainer>
          </Grid>
          <Grid container item direction="row" xs={6}>
            <FieldContainer
              item
              xs={12}
              label="Minimum investment"
              alignItems="unset"
            >
              <InputField
                fullWidth
                disabled
                data-testid="investmentFund-minInvestment"
                id="minInvestment"
                name="minInvestment"
                placeholder="---"
                value={fundConfiguration.minInvestment}
              />
            </FieldContainer>
          </Grid>
          <Grid container item direction="row" xs>
            <FieldContainer
              item
              xs={12}
              label="Maximum investment"
              alignItems="unset"
            >
              <InputField
                fullWidth
                disabled
                data-testid="investmentFund-maxInvestment"
                id="maxInvestment"
                name="maxInvestment"
                placeholder="---"
                value={fundConfiguration.maxInvestment}
              />
            </FieldContainer>
          </Grid>
          <Grid container item direction="row" xs={6}>
            <FieldContainer item xs={12} label="Access Fee" alignItems="unset">
              <InputField
                fullWidth
                disabled
                data-testid="investmentFund-accessFee"
                id="accessFee"
                name="accessFee"
                placeholder="%"
                value={fundConfiguration.accessFee}
              />
            </FieldContainer>
          </Grid>
          <Grid container item direction="row" xs>
            <FieldContainer
              item
              xs={12}
              label="Application fee"
              alignItems="unset"
            >
              <InputField
                fullWidth
                disabled
                data-testid="investmentFund-applicationFee"
                id="applicationFee"
                name="applicationFee"
                value={fundConfiguration.applicationFee}
              />
            </FieldContainer>
          </Grid>
        </FieldContainer>
      </Grid>

      <AddEntityDialog
        open={openAddEntityDialog}
        onClose={handleCloseAddEntityDialog}
        userData={userData}
      />
    </Grid>
  );
};

export default EntitySection;
