import * as yup from 'yup';

import { PhoneNumberValidationSchemas } from 'validations';

const updatePhoneNumberValidationSchema = yup.object({
  phoneNumber: PhoneNumberValidationSchemas.phoneNumber,
  countryCode: PhoneNumberValidationSchemas.countryCode,
});
const updatePhoneNumberOTPValidationSchema = yup.object({
  otp: PhoneNumberValidationSchemas.otp,
});

const updatePhoneNumberValidationSchemas = [
  updatePhoneNumberValidationSchema,
  updatePhoneNumberOTPValidationSchema,
];

export default updatePhoneNumberValidationSchemas;
