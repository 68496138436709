import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import CashFundAccordion from 'components/Accordion/CashFundAccordion';
import Chip from 'components/Chip';
import FormDivider from 'components/OptionDivider/FormDivider';
import FundCard from 'components/FundCard';
import FundCardHeaderAccordion from 'components/Accordion/FundCardHeaderAccordion';
import SecondaryButton from 'components/Button/SecondaryButton';
import ShowMe from 'components/ShowMe';

import Services from 'api/services';
import history from 'utils/history';
import condTrueFalse from 'utils/condTrueFalse';
import palette from 'theme/palette';
import { getFundStatusChipColor } from 'utils/chipMappers';

import {
  FUND_REDEMPTION_STATUSES,
  PAYMENT_STATUS,
  getPaymentStatus,
} from 'constants/investmentEntity';

import { formatDate } from '../CompleteProfileDialog/helperFunctions';
import InvestedPortfolioDetails from './InvestedPortfolioDetails';
import PaymentDetails from './PaymentDetails';
import CustodialDetails from './CustodialDetails';
import FundRedemptionRequestDetails from './FundRedemptionRequestDetails';

const shownBankDetailsTransferInfoText =
  'To secure your allocation, please transfer the money to the account below';
const hiddenBankDetailsTransferInfoText =
  'To secure your allocation, please make a transfer to the trust account';

const handleRouteToRedemptionRequestPage = () =>
  history.push('/redemption-request');

export const fetchPortfolioPayments = async () => {
  try {
    const response = await Services.getPortfolioPayments();
    return response?.data;
  } catch (_) {
    return [];
  }
};

export const fetchPortfolioOverview = async () => {
  try {
    const response = await Services.getInvestedPortofoliosOverview();
    return response?.data;
  } catch (_) {
    return [];
  }
};

export const getAccordionFooter = ({
  investment,
  portfolioStatus,
  onClickRedeemBtn,
  isRedemptionVisible,
}) => {
  const routeToFundDetail = () =>
    history.push(
      `/dashboard/funds?tab=funds&fundId=${investment?.portfolio?.fundFk}`,
    );
  const routeToInvestFund = () =>
    history.push(`/invest/${investment?.portfolio?.fundFk}`);

  return (
    <Grid
      container
      xs={12}
      columnGap={[0, 2]}
      rowSpacing={[2, 0]}
      justifyContent={['center', 'right']}
    >
      <ShowMe
        when={
          portfolioStatus === PAYMENT_STATUS.PAYMENT_COMPLETED.toLowerCase() &&
          isRedemptionVisible
        }
      >
        <Grid item xs={8} sm={3.5} md={2.5} xl={2}>
          <SecondaryButton
            variant="outlined"
            sx={{ minHeight: '64px', width: '100%' }}
            onClick={routeToInvestFund}
          >
            <Typography variant="smallStatic">Invest</Typography>
          </SecondaryButton>
        </Grid>
        <Grid item xs={8} sm={3.5} md={2.5} xl={2}>
          <SecondaryButton
            variant="outlined"
            sx={{ minHeight: '64px', width: '100%' }}
            onClick={onClickRedeemBtn}
          >
            <Typography variant="smallStatic">Redeem</Typography>
          </SecondaryButton>
        </Grid>
      </ShowMe>
      <Grid item xs={8} sm={3.5} md={2.5} xl={2}>
        <Button
          onClick={routeToFundDetail}
          buttonstyles={{
            color: 'white',
            background: palette.reach.menuLink,
            maxHeight: '64px',
          }}
        >
          <Typography variant="smallStatic" color="white">
            Fund Details
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const getFundRedemptionContentList = ({
  fundRedemptionRequest,
  type,
}) => {
  const contents = [
    {
      heading: 'Request Type',
      value: type,
      spacing: 3,
    },
    {
      heading: 'Date Requested',
      value: formatDate(fundRedemptionRequest?.created_at),
      spacing: 3,
    },
    {
      heading: 'Status',
      value: (
        <Chip
          styles={{ display: 'block' }}
          label={getPaymentStatus(fundRedemptionRequest?.status)}
          color={getFundStatusChipColor({
            status: fundRedemptionRequest?.status,
          })}
        />
      ),
      spacing: 3,
    },
  ];

  return contents;
};

export const getContentList = ({ investment, type }) => {
  if (type === 'Redemption')
    return getFundRedemptionContentList({
      fundRedemptionRequest: investment,
      type,
    });

  const contents = [
    {
      heading: 'Request Type',
      value: type,
      spacing: 3,
    },
    {
      heading: 'Fund Currency',
      value: investment?.portfolio?.fundConfiguration?.dealCurrency,
      spacing: 3,
    },
    {
      heading: 'Status',
      value: (
        <Chip
          styles={{ display: 'block' }}
          label={getPaymentStatus(investment?.portfolio?.paymentStatus)}
          color={getFundStatusChipColor({
            status: investment?.portfolio?.status,
            paymentStatus: investment?.portfolio?.paymentStatus,
          })}
        />
      ),
      spacing: 3,
    },
  ];

  if (
    investment?.portfolio?.paymentStatus === PAYMENT_STATUS.PAYMENT_COMPLETED
  ) {
    contents[0].heading = 'Investment Entity';
    contents[0].value = investment?.name;
  }

  return contents;
};

export const getFundRedemptionAccordionContent = ({
  fundRedemptionRequest,
}) => {
  const fundRedemptionStatus = fundRedemptionRequest?.status;

  let content = null;

  switch (true) {
    case fundRedemptionStatus === FUND_REDEMPTION_STATUSES.APPROVED:
      content = <InvestedPortfolioDetails investment={fundRedemptionRequest} />;
      break;
    case fundRedemptionStatus === FUND_REDEMPTION_STATUSES.PENDING:
      content = (
        <FundRedemptionRequestDetails
          fundRedemptionRequest={fundRedemptionRequest}
        />
      );
      break;
    case fundRedemptionStatus === FUND_REDEMPTION_STATUSES.CANCELED:
      content = (
        <FundRedemptionRequestDetails
          fundRedemptionRequest={fundRedemptionRequest}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  const footer = getAccordionFooter({
    investment: fundRedemptionRequest,
  });

  return (
    <>
      {content}
      <FormDivider bordercolor={palette.reach.primary} width="100%" />
      {footer}
    </>
  );
};

export const getAccordionContent = ({
  investment,
  isRedemptionVisible,
  isMobileScreen,
  type = 'Application',
}) => {
  if (type === 'Redemption')
    return getFundRedemptionAccordionContent({
      fundRedemptionRequest: investment,
      isMobileScreen,
    });

  const portfolioStatus = investment?.portfolio?.status?.toLowerCase();

  const paymentStatus = investment?.portfolio?.paymentStatus;

  let content = null;

  switch (true) {
    case portfolioStatus === PAYMENT_STATUS.PAYMENT_COMPLETED.toLowerCase():
      content = <InvestedPortfolioDetails investment={investment} />;
      break;
    case portfolioStatus === PAYMENT_STATUS.PAYMENT_PENDING.toLowerCase() &&
      paymentStatus === PAYMENT_STATUS.PAYMENT_UNDER_REVIEW:
      content = (
        <PaymentDetails investment={investment} tableOnly={!isMobileScreen} />
      );
      break;
    case portfolioStatus === PAYMENT_STATUS.PAYMENT_PENDING.toLowerCase():
      content = (
        <>
          <ShowMe when={!isMobileScreen}>
            <div style={{ width: '100%' }}>
              <Typography sx={{ textAlign: 'center' }}>
                {condTrueFalse(
                  investment?.portfolio?.fund?.bankAccount?.isShowBankDetails,
                  shownBankDetailsTransferInfoText,
                  hiddenBankDetailsTransferInfoText,
                )}
              </Typography>
            </div>
            <FormDivider bordercolor={palette.reach.primary} width="100%" />
          </ShowMe>
          <ShowMe
            when={investment?.portfolio?.fund?.bankAccount?.isShowBankDetails}
          >
            <PaymentDetails investment={investment} />
            <FormDivider bordercolor={palette.reach.primary} width="100%" />
          </ShowMe>
          <CustodialDetails investment={investment} isSM={isMobileScreen} />
        </>
      );
      break;
    case portfolioStatus === PAYMENT_STATUS.PAYMENT_CANCELLED.toLowerCase():
      content = (
        <PaymentDetails investment={investment} tableOnly={!isMobileScreen} />
      );
      break;
    default:
      content = null;
      break;
  }

  const footer = getAccordionFooter({
    investment,
    portfolioStatus,
    isRedemptionVisible,
    onClickRedeemBtn: () => handleRouteToRedemptionRequestPage(investment),
  });

  return (
    <>
      {content}
      <FormDivider bordercolor={palette.reach.primary} width="100%" />
      {footer}
    </>
  );
};

export const getFundInvestments = ({ data, selectedInvestmentEntity }) =>
  data.reduce(
    (sortedInvestments, investment) => {
      if (
        selectedInvestmentEntity &&
        investment.portfolio.userInvestorMapFk !== selectedInvestmentEntity
      )
        return sortedInvestments;

      if (typeof investment?.portfolio?.fund?.overview === 'string') {
        return {
          ...sortedInvestments,
          cashFunds: sortedInvestments.cashFunds.concat(investment),
        };
      }

      return {
        ...sortedInvestments,
        funds: sortedInvestments.funds.concat(investment),
      };
    },
    { cashFunds: [], funds: [] },
  );

export const CashFundList = ({
  cashFunds,
  isMobileScreen,
  onClickViewDetail,
}) =>
  cashFunds.map((investment, index) => (
    <Grid item xs={12}>
      {isMobileScreen ? (
        <FundCard
          isOpen
          status={null}
          title={investment?.portfolio?.fund?.fundName}
          fundType={`${investment?.name} (Cash fund)`}
          contentList={getContentList({ investment })}
          onClickViewDetail={() => onClickViewDetail(investment)}
        />
      ) : (
        <CashFundAccordion
          fundName={investment?.portfolio?.fund?.fundName}
          contentList={getContentList({ investment })}
          childContainerBackground="white"
        >
          {getAccordionContent({
            investment,
            index,
            isRedemptionVisible:
              !!investment?.portfolio?.fund?.isRedemptionVisible,
          })}
        </CashFundAccordion>
      )}
    </Grid>
  ));

const getInvestmentType = (investment) =>
  condTrueFalse(investment.investmentType, `(${investment.investmentType})`);

export const PortfolioFundList = ({
  funds,
  onClickViewDetail,
  isMobileScreen,
  type = 'Application',
}) => (
  <Grid container item rowSpacing={3}>
    {funds.map((investment, index) => (
      <Grid item xs={12}>
        {isMobileScreen ? (
          <FundCard
            isOpen
            status={null}
            title={investment?.portfolio?.fund?.fundName}
            fundType={`${investment?.portfolio?.fundConfiguration?.dealCurrency} Class`}
            contentList={getContentList({ investment, type })}
            onClickViewDetail={() => onClickViewDetail(investment)}
          />
        ) : (
          <FundCardHeaderAccordion
            key={`${investment?.portfolio?.status}-${index.toString()}`}
            logoSrc={investment?.fundHeaderStorage?.logo}
            headerImageSrc={investment?.fundHeaderStorage?.header}
            headerTitle={investment?.portfolio?.fund?.fundName}
            headerDesc={`${investment?.portfolio?.fundConfiguration?.dealCurrency} Class`}
            chipLabel={investment?.portfolio?.status?.toLowerCase()}
            chipColor={`${investment?.portfolio?.status?.toLowerCase()}ChipColor`}
            contentList={getContentList({ investment, type })}
            childGridSx={{ background: 'white' }}
          >
            {getAccordionContent({
              investment,
              isRedemptionVisible:
                !!investment?.portfolio?.fund?.isRedemptionVisible,
              type,
            })}
          </FundCardHeaderAccordion>
        )}
      </Grid>
    ))}
  </Grid>
);
