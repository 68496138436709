import React, { memo } from 'react';

import Grid from '@mui/material/Grid';

import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import FieldContainer from 'components/FieldContainer';
import FormTitle from 'components/FormTitle';
import TextEditor from 'components/TextEditor';
import Input from 'components/Input';
import UploadInput from 'components/Input/UploadInput';

import { ARTICLE_CATEGORY_OPTIONS } from 'constants/articles';

const ArticleOverview = ({
  readOnly,
  onChange,
  values,
  errors = {},
  onBlur,
  setFieldValue,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Article" />
    </Grid>

    <Grid container item xs={12} rowSpacing={3}>
      <FieldContainer
        item
        xs={7}
        label="Article Category"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Dropdown
          name="category"
          placeholder="Article Category"
          options={ARTICLE_CATEGORY_OPTIONS}
          disabled={readOnly}
          onChange={onChange}
          value={values.category}
          error={!!errors.category}
          errorValue={errors.category}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Author"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="author-input"
          id="author"
          name="author"
          placeholder="author"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.author}
          error={!!errors.author}
          errorValue={errors.author}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Date"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <DatePicker
          fullWidth
          name="date"
          testId="date-input"
          datePickerType="dateTime"
          onChange={onChange}
          value={values.date}
          onBlur={onBlur}
          setFieldValue={setFieldValue}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Tags"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="tags-input"
          id="tags"
          name="tags"
          placeholder="tags"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.tags}
          error={!!errors.tags}
          errorValue={errors.tags}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Summary"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="summary-input"
          id="summary"
          name="summary"
          placeholder="summary"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.summary}
          error={!!errors.summary}
          errorValue={errors.summary}
          inputProps={{ maxLength: 50 }}
        />
      </FieldContainer>
      <FieldContainer item label="title" variant="title">
        <Input
          autoFocus
          fullWidth
          data-testid="title-input"
          id="title"
          name="title"
          onChange={onChange}
          value={values.title}
          error={!!errors.title}
          errorValue={errors.title}
        />
      </FieldContainer>
      <FieldContainer
        item
        label="Article Content"
        variant="title"
        childGridProps={{ sx: { minHeight: '300px' } }}
      >
        <TextEditor
          id="article-content-texteditor"
          onChange={onChange}
          value={values.content}
          name="content"
          options={{ imageFileInput: false }}
        />
      </FieldContainer>
      <FieldContainer
        item
        label="Include PDF:"
        columnSpacing={3}
        labelGridProps={{ xs: 'auto' }}
        childGridProps={{ xs: 'auto' }}
      >
        <UploadInput
          name="articleDocument"
          onChange={onChange}
          value={values.articleDocument}
          placeholder="Select PDF"
          // disabled={disabled}
        />
      </FieldContainer>
    </Grid>
  </Grid>
);

export default memo(ArticleOverview);
