import * as yup from 'yup';

import YupPassword from 'yup-password';
import {
  PASSWORD_MIN_MAX_CHAR_VALIDATION_MSG,
  PASSWORD_MIN_LOWERCASE_VALIDATION_MSG,
  PASSWORD_MIN_UPPERCASE_VALIDATION_MSG,
  PASSWORD_MIN_TWO_NUMBERS_VALIDATION_MSG,
  PASSWORD_REQUIRED_VALIDATION_MSG,
  PASSWORD_MIN_CHAR_VALIDATION_VALUE,
  PASSWORD_MAX_CHAR_VALIDATION_VALUE,
  PASSWORD_MIN_LOWERCASE_VALIDATION_VALUE,
  PASSWORD_MIN_UPPERCASE_VALIDATION_VALUE,
  PASSWORD_MIN_NUMBER_VALIDATION_VALUE,
} from 'constants/registration';

YupPassword(yup);

const password = {
  password: yup
    .string()
    .min(
      PASSWORD_MIN_CHAR_VALIDATION_VALUE,
      PASSWORD_MIN_MAX_CHAR_VALIDATION_MSG,
    )
    .max(
      PASSWORD_MAX_CHAR_VALIDATION_VALUE,
      PASSWORD_MIN_MAX_CHAR_VALIDATION_MSG,
    )
    .minLowercase(
      PASSWORD_MIN_LOWERCASE_VALIDATION_VALUE,
      PASSWORD_MIN_LOWERCASE_VALIDATION_MSG,
    )
    .minUppercase(
      PASSWORD_MIN_UPPERCASE_VALIDATION_VALUE,
      PASSWORD_MIN_UPPERCASE_VALIDATION_MSG,
    )
    .minNumbers(
      PASSWORD_MIN_NUMBER_VALIDATION_VALUE,
      PASSWORD_MIN_TWO_NUMBERS_VALIDATION_MSG,
    )
    .required(PASSWORD_REQUIRED_VALIDATION_MSG),
};

export default password;
