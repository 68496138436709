import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FundCardButton from 'components/FundCard/components/FundCardButton';

const CompleteProfileSection = ({ onClickCompleteProfile }) => (
  <div
    data-testid="complete-profile-section"
    style={{ backgroundColor: '#313131' }}
  >
    <Grid container item direction="row" columnSpacing={4} padding={3}>
      <Grid container item xs={12} md={8} rowSpacing={1}>
        <Grid item>
          <Typography variant="display3" color="white">
            Have you completed your profile?
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="small" color="white">
            In order to gain full access to the data room, and information about
            specific funds, you’ll need to complete your profile.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} textAlign="right" alignSelf="center" mt={{ xs: 4 }}>
        <FundCardButton
          data-testid="complete-profile-btn"
          label={
            <Typography variant="helperPrimary">Complete profile</Typography>
          }
          sx={{ width: '100%', maxWidth: { xs: 'auto', sm: '145px' }, backgroundColor: 'white' }}
          onClick={onClickCompleteProfile}
        />
      </Grid>
    </Grid>
  </div>
);

export default memo(CompleteProfileSection);
