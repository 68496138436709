import React, { useMemo } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';

import UploadInput from 'components/Input/UploadInput';
import BannerHeader from 'components/Banner/BannerHeader';
import ShowMe from 'components/ShowMe';

import UndefinedLogoSrc from 'assets/icons/undefined-logo.svg';
import UndefinedHeaderSrc from 'assets/icons/undefined-header-image.svg';

export const UploadBtnWithLabelHelper = ({
  label,
  onChange,
  values,
  name,
  helperText,
  accept = 'image/*',
  errorValue,
  disabled,
}) => (
  <>
    <ShowMe when={!!label}>
      <Grid item xs={12}>
        <FormLabel>{label}</FormLabel>
      </Grid>
    </ShowMe>
    <Grid item xs={12}>
      <UploadInput
        name={name}
        onChange={onChange}
        value={values?.[name]}
        accept={accept}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs={12}>
      <FormHelperText>{helperText}</FormHelperText>
    </Grid>
    <Grid item xs={12}>
      <FormHelperText error>{errorValue}</FormHelperText>
    </Grid>
  </>
);

const ArticleBannerTeaserHeader = ({
  readOnly,
  onChange,
  values,
  errors = {},
}) => (
  <Grid container item m={1} rowSpacing={3}>
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: '0px !important',
        border: '1px solid #ccc',
        borderRadius: '6px 6px 0px 0px',
      }}
    >
      <BannerHeader
        logoSrc={values?.articleLogoImg?.url || UndefinedLogoSrc}
        headerImageSrc={values?.articleHeaderImg?.url || UndefinedHeaderSrc}
        headerBgColor="#245356"
        width="100%"
        useHeaderBgWrapper
      />
    </Grid>
    <Grid container item xs={12} justifyContent="center" columnSpacing={3}>
      <Grid item xs={4}>
        <UploadBtnWithLabelHelper
          name="articleHeaderImg"
          onChange={onChange}
          values={values}
          label="Article Header Image"
          helperText="1240x165 pixels size (jpg, png) max 2MB"
          errorValue={errors.articleHeaderImg}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <UploadBtnWithLabelHelper
          name="articleLogoImg"
          onChange={onChange}
          values={values}
          label="Article Card Image"
          helperText="220x175 pixels size (jpg, png) max 2MB"
          errorValue={errors.articleLogoImg}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default ArticleBannerTeaserHeader;
