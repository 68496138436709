import React from 'react';

import Grid from '@mui/material/Grid';

import DocumentList from '../Document/DocumentList';

const DataRoomList = ({ fundDataRoomsData = [] }) => (
  <Grid container mt={1}>
    <Grid
      container
      item
      xs={12}
      sx={{
        background: 'white',
        padding: '24px',
      }}
      rowSpacing={3}
    >
      {fundDataRoomsData?.map(
        ({ category, documents, subFolders = [] }, index) => (
          <>
            <Grid item xs={12}>
              <DocumentList
                key={`${index.toString()}-${category}`}
                documents={documents}
                folder={category}
                type="dataroom"
              />
            </Grid>
            <Grid item xs={12}>
              {subFolders.map((subFolder, idx) => (
                <DocumentList
                  subfolder
                  key={`${index.toString()}-${idx.toString()}-${
                    subFolder.category
                  }`}
                  documents={subFolder.documents}
                  folder={subFolder.category}
                  type="dataroom"
                />
              ))}
            </Grid>
          </>
        ),
      )}
    </Grid>
  </Grid>
);

export default DataRoomList;
