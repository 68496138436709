import { ID_TYPE_DRIVER_LICENSE } from 'constants/investmentEntity';
import * as yup from 'yup';

const investorIdentificationValidationSchema = yup.object({
  idType: yup.string().required().label('ID Type'),
  idNumber: yup.string().max(50).required().label('ID Number'),

  stateOfIssue: yup.string().label('Country of Issue').when('idType', {
    is: ID_TYPE_DRIVER_LICENSE,
    then: yup.string().required(),
  }),
  cardNumber: yup.string().label('Card Number').when('idType', {
    is: ID_TYPE_DRIVER_LICENSE,
    then: yup.string().required(),
  }),
});

export default investorIdentificationValidationSchema;
