import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormTitle from 'components/FormTitle';
import UploadButton from 'components/Button/UploadButton';

const SignDocument = ({
  onChange,
  values,
  uploadBtnDisabled,
  onDelete,
  index,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Signing Document" />
    </Grid>

    <Grid container item xs={12} p={2} rowSpacing={3}>
      <Grid item>
        <Typography variant="body">
          Please attach a Signing Document for this Fund
        </Typography>
      </Grid>
      <Grid container item alignItems="center">
        <UploadButton
          name="signDocument"
          value={values.signDocument}
          onChange={onChange}
          onRemoveValidation={(documents) => onDelete(documents, index)}
          maxFiles={1}
          infos={['Maximum 15MB', 'File should be in PDF format']}
          disabled={uploadBtnDisabled}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default memo(SignDocument);
