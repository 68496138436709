import { ID_TYPE_DRIVER_LICENSE } from 'constants/investmentEntity';
import getMobileNumberValue from 'utils/getMobileNumberValue';
import mapValue from 'utils/mapValue';

export const mapPersonalDetails = (personalDetail) => ({
  title: mapValue(personalDetail?.title, ''),
  firstName: mapValue(personalDetail?.firstName, ''),
  middleName: mapValue(personalDetail?.middleName, ''),
  lastName: mapValue(personalDetail?.lastName, ''),
  mobileNumber: mapValue(
    getMobileNumberValue(
      personalDetail?.countryCode,
      personalDetail?.phoneNumber,
    ),
    '',
  ),
  email: mapValue(personalDetail?.email, ''),
  dateOfBirth: mapValue(personalDetail?.dateOfBirth, ''),
  citizenship: mapValue(personalDetail?.country, ''),
});

export const mapAddressDetails = (personalDetail) => ({
  address1: mapValue(personalDetail?.firstAddress, ''),
  address2: mapValue(personalDetail?.secondAddress, ''),
  suburb: mapValue(personalDetail?.suburb, ''),
  city: mapValue(personalDetail?.city, ''),
  state: mapValue(personalDetail?.state, ''),
  postcode: mapValue(personalDetail?.postCode, ''),
  country: mapValue(personalDetail?.addressCountry, ''),
});

const personalDetails = (personalDetail) => ({
  ...mapPersonalDetails(personalDetail),
  ...mapAddressDetails(personalDetail),
  idTypeName: mapValue(personalDetail?.idType, ''),
  idNumber: mapValue(personalDetail?.idNumber, ''),
  stateOfIssue:
    personalDetail?.idType === ID_TYPE_DRIVER_LICENSE
      ? personalDetail?.stateOfIssue
      : undefined,
  cardNumber:
    personalDetail?.idType === ID_TYPE_DRIVER_LICENSE
      ? personalDetail?.cardNumber
      : undefined,
});

export default personalDetails;
