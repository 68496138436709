import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const CardHeader = styled(Grid)(({ theme }) => ({
  padding: '14px 12px',
  borderBottom: `1px solid${theme.palette.reach.blueBorder}`,
  width: '100%',
}));

export default CardHeader;
