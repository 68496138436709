import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import { CancelOutlined } from '@mui/icons-material';

import { ReactComponent as Document } from 'assets/icons/document-file.svg';
import SwitchWithLabel from 'components/Switch/SwitchWithLabel';

import DraggableContainer from './DraggableContainer';

const FileListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.light}`,
  paddingLeft: '36px',
  paddingRight: '8px',
}));
const DocumentList = ({
  fileList = [],
  getFileName,
  onChange,
  onRemove,
  disabled,
  onChangeFiles,
}) => {
  const handleMoveDocument = useCallback(
    (currentIndex, newIndex) => {
      const newFiles = [...fileList];

      // Swap the document index.
      [newFiles[currentIndex], newFiles[newIndex]] = [
        newFiles[newIndex],
        newFiles[currentIndex],
      ];

      onChangeFiles({ newFiles });
    },
    [fileList],
  );

  return (
    <List component="div">
      {fileList?.map((file, index) => (
        <DraggableContainer
          type="documents"
          key={file.id}
          id={file.id}
          index={index}
          onMove={handleMoveDocument}
        >
          <FileListItem
            key={`${file.id}-${index.toString()}`}
            secondaryAction={
              <Grid container alignItems="center" columnSpacing={1}>
                <Grid item>
                  <SwitchWithLabel
                    name="isHidden"
                    onChange={onChange(index)}
                    leftLabel={
                      <Typography variant="small" sx={{ fontWeight: 400 }}>
                        Hide
                      </Typography>
                    }
                    height={20}
                    width={38}
                    checked={file.isHidden}
                    customcolor="black"
                    disabled={disabled}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                  <SwitchWithLabel
                    name="enableView"
                    onChange={onChange(index)}
                    leftLabel={
                      <Typography variant="small" sx={{ fontWeight: 400 }}>
                        View
                      </Typography>
                    }
                    height={20}
                    width={38}
                    checked={file.enableView}
                    customcolor="black"
                    disabled={disabled}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                  <SwitchWithLabel
                    name="enableDownload"
                    onChange={onChange(index)}
                    leftLabel={
                      <Typography variant="small" sx={{ fontWeight: 400 }}>
                        Downloadable
                      </Typography>
                    }
                    height={20}
                    width={38}
                    checked={file.enableDownload}
                    customcolor="black"
                    disabled={disabled}
                  />
                </Grid>
                {onRemove && (
                  <Grid item>
                    <IconButton onClick={onRemove(file, index)}>
                      <CancelOutlined color="error" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            }
          >
            <ListItemIcon>
              <Document />
            </ListItemIcon>
            <ListItemText
              primary={getFileName(
                file?.fileName || file?.originalFileName || file?.title,
              )}
            />
          </FileListItem>
        </DraggableContainer>
      ))}
    </List>
  );
};

export default DocumentList;
