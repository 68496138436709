import React from 'react';
import InvestmentEntityContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityContainer';
import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';

const TrustProfileStepContainer = ({
  title,
  containerProps,
  SideIcon,
  steps,
  stepTitle,
  activeStep,
  isSelectTrustee,
  children,
}) =>
  isSelectTrustee ? (
    <InvestmentEntityContainer onBack={containerProps?.onBack}>
      {children}
    </InvestmentEntityContainer>
  ) : (
    <InvestmentEntityStepContainer
      title={title}
      containerProps={containerProps}
      SideIcon={SideIcon}
      steps={steps}
      stepTitle={stepTitle}
      activeStep={activeStep}
    >
      {children}
    </InvestmentEntityStepContainer>
  );

export default TrustProfileStepContainer;
