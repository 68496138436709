const MOBILE_NUMBER_ALREADY_EXIST = 'error.admin.mobile.number.already.exist';
const MOBILE_NUMBER_INVALID = 'error.admin.invalid.mobile.number';
const EMAIL_ALREADY_EXIST = 'error.admin.email.already.exist';

const ERROR_CODES = {
  MOBILE_NUMBER_ALREADY_EXIST,
  MOBILE_NUMBER_INVALID,
  EMAIL_ALREADY_EXIST,
};
const ERROR_MSG = {
  [MOBILE_NUMBER_ALREADY_EXIST]:
    'Phone number already associated with an account.',
  [MOBILE_NUMBER_INVALID]: 'Invalid phone number.',
  [EMAIL_ALREADY_EXIST]:
    'An account has already been registered with this email.',
};

export default { ERROR_CODES, ERROR_MSG };
