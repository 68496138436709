import { memo } from 'react';
import { styled } from '@mui/material/styles';
import OptionDivider from '.';

const FormDivider = styled(OptionDivider)(
  ({ theme, bordercolor, width, style = {} }) => ({
    border: `1px solid ${bordercolor || theme.palette.border.light}`,
    margin: '32px 0',
    width: width || '90%',
    ...style,
  }),
);

export default memo(FormDivider);
