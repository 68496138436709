import React, { useCallback } from 'react';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';

const PaginationDataTable = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const handlePageChange = useCallback(
    (_event, value) => apiRef.current.setPage(value - 1),
    [apiRef.current],
  );

  return (
    <Pagination
      color="saveButton"
      shape="rounded"
      count={pageCount}
      page={page + 1}
      onChange={handlePageChange}
    />
  );
};

export default PaginationDataTable;
