const getDateDifference = ({ endAt, startAt = new Date() }) => {
  const today = new Date(startAt);
  const endDate = new Date(endAt);
  const timeinmilisec = endDate.getTime() - today.getTime();

  return timeinmilisec / (1000 * 60 * 60 * 24);
};

const getFullDateString = ({ date = null, withTime } = {}) => {
  let dateString = new Date(date).toLocaleDateString('en-AU', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  if (withTime) {
    dateString += new Date(date).toLocaleTimeString('default', {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'long',
    });
  }

  return dateString;
};

export { getDateDifference, getFullDateString };
