import React, { useMemo, memo } from 'react';
import MUIGrid from '@mui/material/Grid';
import StepContainer from './StepContainer';

const Stepper = ({ steps, activeStep }) => {
  const props = useMemo(
    () => ({
      xs: 12 / steps.length,
    }),
    [steps.length],
  );

  return (
    <MUIGrid container>
      {steps.map(({ label }, index) => (
        <MUIGrid key={label} item {...props} sx={{ position: 'relative' }}>
          <StepContainer
            isActive={activeStep === index}
            isDone={activeStep > index}
            isFirst={index === 0}
            isLast={index === steps.length - 1}
          >
            {label}
          </StepContainer>
        </MUIGrid>
      ))}
    </MUIGrid>
  );
};

export default memo(Stepper);
