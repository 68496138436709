import Services from 'api/services';

// eslint-disable-next-line
export const fetchEvents = async () => {
  try {
    const res = await Services.getEvents();
    return res?.data?.data;
  } catch (_) {
    return {};
  }
};
