import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import history from 'utils/history';
import getFullName from 'utils/getFullName';
import PayloadMappers from 'utils/PayloadMappers';
import ResponseMappers from 'utils/ResponseMappers';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import useTabs from 'hooks/useTabs';
import useAdminRestriction from 'hooks/useAdminRestriction';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import Loading from 'components/Loading';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import Tabs from 'components/Tabs';
import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import TabPanels from 'components/TabPanels';
import { useStores } from 'components/StateProvider';

import DetailsContainer from './DetailsContainer';
import {
  editEvent,
  fetchAdminEventDetails,
  eventTabItems,
  sendAddEvent,
} from './HelperFunction';

const tabIdMapper = ['event'];

const handleRedirectToEventList = () => history.push('/admin/articles-events');

const EventDetails = () => {
  const {
    auth: { user },
  } = useStores();
  const {
    isDialogOpen: isTabAlertDialogOpen,
    handleOpenDialog: handleOpenTabAlertDialog,
    handleCloseDialog: handleCloseTabAlertDialog,
  } = useDialog();
  const { haveCrudPermission } = useAdminRestriction();

  const eventFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fundType] = useState(searchParams.get('type'));
  const [fetchStatus, setFetchStatus] = useState(true);
  const [isError, setIsError] = useState(false);
  // Manually manage 'dirty', because the form value is a deep object.
  const [dirty, setDirty] = useState(false);
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    isDialogOpen: openSuccessDialog,
    handleOpenDialog: handleOpenSuccessDialog,
    handleCloseDialog: handleCloseSuccessDialog,
  } = useDialog({
    onClose: (eventUid) => {
      if (!eventId && eventUid) {
        history.push(`/admin/events/${eventUid}`);

        return;
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
  });

  const adminGetEventDetails = useMemo(
    () => fetchAdminEventDetails(eventId),
    [eventId, fetchStatus],
  );

  const { apiData: dataValues, isFetchLoading } = useFetchApi({
    url: adminGetEventDetails,
    defaultApiData: {},
    dependencies: [adminGetEventDetails],
  });

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  ); // trigger useMemo changes to refetch

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    setValues,
    handleChange,
  } = useFormik({
    initialValues: eventId ? {} : ResponseMappers.admin.event({}),
  });

  useEffect(() => {
    if (!isFetchLoading && Object.keys(dataValues).length)
      setValues(ResponseMappers.admin.event(dataValues));
  }, [isFetchLoading]);

  const handleSubmit = useCallback(
    async (formValues) => {
      try {
        setIsLoading(true);

        const payload = PayloadMappers.admin.event.addEvent({
          ...formValues,
          eventUid: values.event.eventUid,
          isHidden: values.isHidden,
          submitToDraft: true,
          createdBy: getFullName(user),
          updatedBy: getFullName(user),
        });

        let res;
        if (!eventId) {
          res = await sendAddEvent({ payload });
        } else {
          res = await editEvent({ id: eventId, payload });
        }

        if (res?.event?.id) {
          handleOpenSuccessDialog(res.event.eventUid);
        }

        setDirty(false);

        return res;
      } finally {
        setIsLoading(false);
      }
    },
    [values.event?.eventUid, values.isHidden, values.isFeatured],
  );

  const handleSaveDraftEvent = useCallback(
    () => eventFormRef.current?.handleSubmit?.(),
    [handleSubmit, eventFormRef.current?.handleSubmit],
  );

  const defaultTabIdx = useMemo(() => {
    if (!values.eventUid) return 0;

    const index = tabIdMapper.indexOf(searchParams.get('tab'));
    if (index === -1) return 0;
    return index;
  }, []);

  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx,
    callbackDependencies: [values.eventUid],
  });

  useEffect(() => {
    searchParams.set('tab', tabIdMapper[activeTabIdx]);
    setSearchParams(searchParams);
  }, [activeTabIdx]);

  useEffect(() => {
    setIsError(!!Object.values(errors).filter((err) => !!err).length);
  }, [errors, values.event?.title]);

  const handleSetFieldValue = useCallback(
    (field, value, shouldValidate, updateDirty = true) => {
      if (updateDirty) setDirty(true);

      setFieldValue(field, value, shouldValidate);
    },
    [setFieldValue],
  );

  const memoizedTabPanelItems = useMemo(
    () =>
      eventTabItems({
        setFieldValue: handleSetFieldValue,
        setValues,
        setFieldError,
        setDirty,
        onSubmit: handleSubmit,
        eventId: values.eventId || eventId,
        readOnly: !haveCrudPermission,
        fundType,
      }),
    [handleSubmit, values.eventId, haveCrudPermission],
  );

  const onTabChange = useCallback(
    (_props, index) => {
      if ((eventId || values.eventId) && !dirty)
        return handleTabChange(_props, index);

      return handleOpenTabAlertDialog(index.toString());
    },
    [values.eventId, dirty],
  );

  const tabAlertDialogProps = useMemo(() => {
    if (eventId || values.eventId)
      return {
        subtitle: 'Do you want to discard the changes you made?',
        onClose: handleCloseTabAlertDialog,
        confirmButtonLabel: 'Discard',
        onConfirm: () => {
          handleTabChange({}, Number(isTabAlertDialogOpen));
          handleCloseTabAlertDialog();
          setDirty(false);
        },
      };

    return {
      subtitle: 'Please submit Event information first',
      onConfirm: handleCloseTabAlertDialog,
      confirmButtonLabel: 'Ok',
    };
  }, [values.eventUid, isTabAlertDialogOpen]);

  const headerInfoValues = useMemo(
    () => ({
      eventId: values.event?.id || eventId,
      eventUid: values.event?.eventUid,
      isFeatured: values.isFeatured,
      isHidden: values.isHidden,
      createdBy: values.createdBy,
      createdAt: values.createdAt,
      updatedBy: values.updatedBy,
      updatedAt: values.updatedAt,
    }),
    [
      values.event?.eventUid,
      values.isFeatured,
      values.isHidden,
      values.eventId,
      values.createdBy,
      values.createdAt,
      values.updatedAt,
      values.updatedBy,
      eventId,
    ],
  );

  return (
    <DetailsContainer
      refetch={handleRefetch}
      fundStatus={values.status}
      disableSaveDraftBtn={isError}
      onBack={handleRedirectToEventList}
      onChange={handleChange}
      values={headerInfoValues}
      backButtonLabel={eventId ? 'Event Details' : 'Add new Event'}
      onSaveDraft={handleSaveDraftEvent}
    >
      {isFetchLoading || !Object.keys(values).length ? (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item textAlign="center">
            <CircularProgress size={25} data-testid="progress-screen" />
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={4} data-testid="event-details-content">
          <Grid item xs={12}>
            <Tabs
              key={`${values.event?.id}-${dirty}-${isTabAlertDialogOpen}`}
              value={activeTabIdx}
              onChange={onTabChange}
              centered={false}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              <TabComponents
                activeTabIdx={activeTabIdx}
                tabItems={memoizedTabPanelItems}
                testIdPrefix="add-events-tab"
              />
            </Tabs>
          </Grid>
          <Grid item xs>
            <TabPanels
              value={activeTabIdx}
              tabItems={memoizedTabPanelItems}
              eventProps={{
                values: values.event,
                errors: !!errors.event,
                ref: eventFormRef,
                imperativeHandleDepedencies: [handleSubmit],
              }}
              boxPanelProps={{ sx: { padding: 0 } }}
            />
          </Grid>
        </Grid>
      )}

      <ConfirmationDialog
        open={!!isTabAlertDialogOpen}
        title="Warning!"
        infoText=""
        {...tabAlertDialogProps}
        dialogContentDependencies={[tabAlertDialogProps.onConfirm]}
      />

      <SuccessDialog
        open={openSuccessDialog}
        onConfirm={handleCloseSuccessDialog}
      />

      <Loading loading={isLoading} popup />
    </DetailsContainer>
  );
};

export default EventDetails;
