import Services from 'api/services';
import omitObjKeys from 'utils/omitObjKeys';
import { USER_STATUS } from 'constants/userStatus';

const updatePhoneNumberAction = async (dispatch, payload) => {
  try {
    const updatePhoneNumberRes = await Services.updateProfileSaveMobileNumber(
      payload,
    );

    dispatch({
      type: '@@profile/UPDATE_PHONE_NUMBER_SUCCESS',
      payload: updatePhoneNumberRes?.data?.mobileNumber,
    });
  } catch (error) {
    dispatch({
      type: '@@profile/UPDATE_PHONE_NUMBER_FAILURE',
      payload: error,
    });

    throw error;
  }
};

const updateUserProfile = async (dispatch) => {
  try {
    const userProfile = await Services.getUserProfile();

    dispatch({
      type: '@@profile/UPDATE_USER_PROFILE',
      payload: {
        ...omitObjKeys(userProfile?.data, ['password']),
        isVerified:
          userProfile?.data?.status_verification === USER_STATUS.VERIFIED ||
          userProfile?.data?.status_verification ===
            USER_STATUS.VERIFIED_OVERSEAS,
      },
    });
  } catch (error) {
    dispatch({
      type: '@@profile/UPDATE_USER_PROFILE_FAILURE',
      payload: error,
    });

    throw error;
  }
};

const updateAccessibility = async (dispatch) => {
  try {
    const userAccessibility = await Services.getUserAccessibility();

    dispatch({
      type: '@@profile/UPDATE_USER_ACCESSIBILITY',
      payload: userAccessibility?.data,
    });
  } catch (error) {
    dispatch({
      type: '@@profile/UPDATE_USER_ACCESSIBILITY',
      payload: error,
    });

    throw error;
  }
};

const updateUserStatusStore = async (dispatch, userStatus) => {
  try {
    dispatch({
      type: '@@profile/UPDATE_USER_PROFILE',
      payload: {
        status_verification: userStatus,
        isVerified:
          userStatus === USER_STATUS.VERIFIED ||
          userStatus === USER_STATUS.VERIFIED_OVERSEAS,
      },
    });
  } catch (error) {
    dispatch({
      type: '@@profile/UPDATE_USER_PROFILE_FAILURE',
      payload: error,
    });

    throw error;
  }
};

const updateUserViewFundStatus = async (dispatch) => {
  try {
    const userProfile = await Services.setUserViewFundStatus();

    dispatch({
      type: '@@profile/UPDATE_USER_PROFILE',
      payload: userProfile.data,
    });
  } catch (error) {
    dispatch({
      type: '@@profile/UPDATE_USER_PROFILE_FAILURE',
      payload: error,
    });

    throw error;
  }
};

export {
  updatePhoneNumberAction,
  updateUserProfile,
  updateAccessibility,
  updateUserStatusStore,
  updateUserViewFundStatus,
};
