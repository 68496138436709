import React, { memo, useCallback } from 'react';

import { Box, Grid, List, Typography } from '@mui/material';

import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

import NavItem from './NavItem';

const platformConfigurationMenuItem = {
  id: '/admin/platform-configuration',
  title: 'Platform Configurations',
  type: 'item',
  url: '/admin/platform-configuration',
  icon: SettingsIcon,
};

const DrawerContent = ({ items, open }) => {
  const navCollapse = useCallback(
    (item) =>
      item.children?.map((menuItem) => (
        <NavItem key={menuItem.id} item={menuItem} level={1} open={open} />
      )),
    [],
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      height="100%"
    >
      <Grid item>
        {items.map((item) => (
          <List
            key={item.id}
            subheader={
              item.title &&
              open && (
                <Box sx={{ pl: 3, mb: 1.5 }}>
                  <Typography variant="chipLabel" color="#B0B0B0">
                    {item.title}
                  </Typography>
                </Box>
              )
            }
            sx={{ mb: open ? 1.5 : 0, py: 0, zIndex: 0 }}
          >
            {navCollapse(item)}
          </List>
        ))}
      </Grid>
      <Grid item>
        <NavItem
          item={platformConfigurationMenuItem}
          level={1}
          open={open}
          sx={{ pl: 2 }}
        />
      </Grid>
    </Grid>
  );
};

export default memo(DrawerContent);
