import React, { useCallback, useMemo, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';

import { base64ToBlob, downloadFile } from 'utils/download';
import PDFViewer from './PDFViewer';
import Toolbar from './Toolbar';

const zoomIncrement = 0.25;

const PDFViewerWithToolbar = ({
  filename,
  downloadable,
  pdfData,
  isLoading,
  style,
  ...props
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [zoomScale, setZoomScale] = useState(isMobileScreen ? 0.5 : 1);

  const toolbarRef = useRef();

  const handleZoomIn = useCallback(() => {
    setZoomScale((prevState) => prevState + zoomIncrement);
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoomScale((prevState) => prevState - zoomIncrement);
  }, []);

  const handleZoomChange = useCallback((e) => {
    setZoomScale(e.target.value);
  }, []);

  const handleDownload = useCallback(() => {
    if (pdfData) {
      const blob = base64ToBlob(pdfData);
      const href = URL.createObjectURL(blob);
      downloadFile({
        href,
        filename,
      });
    }
  }, [pdfData]);

  const PDFViewerContainerHeight = useMemo(
    () => `calc(100% - ${toolbarRef.current?.offsetHeight || 56}px)`,
    [toolbarRef.current?.offsetHeight],
  );

  return (
    <Grid style={style} {...props}>
      <Grid ref={toolbarRef} item xs={12}>
        <Toolbar
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          handleZoomChange={handleZoomChange}
          zoomScale={zoomScale}
          handleDownload={handleDownload}
          downloadable={downloadable}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ height: PDFViewerContainerHeight }}
        alignItems="center"
        justifyContent="center"
        container
      >
        {isLoading ? (
          <Grid item textAlign="center">
            <CircularProgress size={100} />
          </Grid>
        ) : (
          <PDFViewer zoomScale={zoomScale} pdfData={pdfData} />
        )}
      </Grid>
    </Grid>
  );
};

export default PDFViewerWithToolbar;
