import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const EndStepContainer = ({
  imageSrc,
  title,
  subtitle,
  children,
  containerProps = {},
  containerStyles = {},
  subtitleContainerProps = {},
  subtitleTypographyProps = {},
  titleContainerProps,
  childrenContainerProps = {},
  testId,
}) => (
  <Grid
    container
    rowSpacing={3}
    direction="column"
    style={{
      maxHeight: '-webkit-fill-available',
      textAlign: '-webkit-center',
      ...containerStyles,
    }}
    data-testid={testId}
    {...containerProps}
  >
    {imageSrc && (
      <Grid item xs={12}>
        <img alt="end" src={imageSrc} />
      </Grid>
    )}
    <Grid item xs={12} {...titleContainerProps}>
      <Typography variant="h1">{title}</Typography>
    </Grid>
    <Grid item xs={12} {...subtitleContainerProps}>
      <Typography variant="bodyGrey" {...subtitleTypographyProps}>
        {subtitle}
      </Typography>
    </Grid>
    <Grid item xs={12} {...childrenContainerProps}>
      {children}
    </Grid>
  </Grid>
);

export default EndStepContainer;
