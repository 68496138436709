import { useCallback, useState, useEffect } from 'react';

import condTrueFalse from 'utils/condTrueFalse';
import isFunction from 'utils/isFunction';

import useIsMounted from './useIsMounted';

const useFetchApi = ({
  url,
  isFetched,
  setIsLoading,
  setIsFetched,
  defaultApiData = [],
  dependencies = [],
  fetchService,
  mapApiData,
}) => {
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [apiData, setApiData] = useState(defaultApiData);
  const [serverError, setServerError] = useState(null);
  const [fetchStatus, setFetchStatus] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      setIsFetchLoading(true);
      condTrueFalse(setIsLoading, () => setIsLoading(true));

      try {
        let apiResponse;

        if (Array.isArray(url)) {
          apiResponse = await Promise.all(url.map((val) => val));
        } else if (url) {
          apiResponse = await url;
        }

        if (isFunction(fetchService) || isFunction(fetchService?.then)) {
          const res = await fetchService();

          if (Array.isArray(apiResponse)) apiResponse.push(res);
          else if (url) apiResponse = [apiResponse, res];
          else apiResponse = res;
        }

        if (!isCancelled) {
          let data = apiResponse;

          condTrueFalse(mapApiData, () => {
            data = mapApiData(data);
          });
          setApiData(data);
        }
      } catch (error) {
        if (!isCancelled) {
          setServerError(error);
        }
      } finally {
        if (isMounted()) {
          condTrueFalse(setIsLoading, () => setIsLoading(false));
          condTrueFalse(setIsFetched, () => setIsFetched(true));
          setIsFetchLoading(false);
        }
      }
    };

    condTrueFalse(!isFetched, () => fetchData());

    return () => {
      isCancelled = true;
    };
  }, [...dependencies, fetchStatus]);

  const handleRefetch = useCallback(() => {
    setFetchStatus((prevState) => !prevState);
  }, []); // trigger useMemo changes to refetch

  return {
    apiData,
    isFetchLoading,
    serverError,
    handleRefetch,
  };
};

export default useFetchApi;
