import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';

import lazyLoad from 'utils/lazyLoad';
import useSteps from 'hooks/useSteps';
import { useStores } from 'components/StateProvider';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';

import companyTaxDetailsValidationSchemas from 'validations/schema/addInvestment/company/companyTaxDetailsValidationSchemas';

const TaxList = lazyLoad(() => import('./Steps/TaxList'));
const TaxSupportingInformation = lazyLoad(() =>
  import('./Steps/TaxSupportingInformation'),
);
const TaxSummary = lazyLoad(() => import('./Steps/TaxSummary'));

const stepComponents = [
  <TaxList />,
  <TaxSupportingInformation />,
  <TaxSummary />,
];

const TaxDetailsWithSupportingInformation = ({
  onNext,
  onBack,
  values,
  SideIcon,
  steps,
  startAtLastStep,
  activeStep,
  setValues,
  mainTaxOwnerName,
  title = 'Tax Details',
  user: userFromInvestmentProfile,
  ...props
}) => {
  const {
    auth: { user: userFromStore },
  } = useStores();

  const user = useMemo(() => {
    if (userFromInvestmentProfile) return userFromInvestmentProfile;
    return userFromStore;
  }, [userFromInvestmentProfile, userFromStore]);

  const [isStepError, setIsStepError] = useState(false);

  const handleNextLastPage = useCallback(
    ({ values: taxDetailsValues }) => {
      if (setValues) setValues({ ...values, taxDetails: taxDetailsValues });
      onNext();
    },
    [values, setValues, onNext],
  );
  const handleBackLastPage = useCallback(
    ({ values: taxDetailsValues }) => {
      if (setValues) setValues({ ...values, taxDetails: taxDetailsValues });
      onBack();
    },
    [values, setValues, onBack],
  );

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: taxDetailsForm,
  } = useSteps({
    stepComponents,
    validationSchemas: companyTaxDetailsValidationSchemas,
    formParameters: {
      initialValues: {
        taxPurposes: [
          {
            investorName: mainTaxOwnerName,
            noTINReason: '',
            unableObtainTinReason: '',
            index: 0,
          },
        ],
        supportingInformation: {
          controllingPersonFields: { dateOfBirth: null },
        },
        signedAt: new Date(),
        signedBy: `${user.firstName} ${user.lastName}`,
        isConfirmed: false,
        ...values.taxDetails,
      },
    },
    onNextInLastPage: handleNextLastPage,
    onBackInFirstPage: handleBackLastPage,
    defaultPageIndex: startAtLastStep ? stepComponents.length - 1 : 0,
  });

  useEffect(() => {
    taxDetailsForm?.validateForm();
  }, [pageIndex, taxDetailsForm?.validateForm]);

  const isNextButtonDisabled = useMemo(
    () => isStepError || !!Object.keys(taxDetailsForm.errors).length,
    [isStepError, taxDetailsForm.errors],
  );

  return (
    <InvestmentEntityStepContainer
      title={title}
      containerProps={{
        onBack: handleBack,
        onNext: handleNext,
        isNextButtonDisabled,
      }}
      SideIcon={SideIcon}
      steps={steps}
      activeStep={activeStep}
    >
      {React.cloneElement(stepComponents[pageIndex], {
        form: taxDetailsForm,
        errors: taxDetailsForm.errors,
        values: taxDetailsForm.values,
        companyDetailsValues: values?.companyDetails,
        mainTaxOwnerName,
        touched: taxDetailsForm.touched,
        pageIndex,
        onNext: handleNext,
        onBack: handleBack,
        onChange: taxDetailsForm.handleChange,
        onBlur: taxDetailsForm.handleBlur,
        setFieldError: taxDetailsForm.setFieldError,
        setValues: taxDetailsForm.setValues,
        setIsStepError,
        ...props,
      })}
    </InvestmentEntityStepContainer>
  );
};

export default memo(TaxDetailsWithSupportingInformation);
