import React from 'react';
import MUILink from '@mui/material/Link';
import { styled } from '@mui/material/styles';

const StyledLink = styled(MUILink)(({ theme, color }) => ({
  color: color || theme.palette.text.hyperlink,
}));

const Link = ({ ...props }) => <StyledLink underline="none" {...props} />;
export default Link;
