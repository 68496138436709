import React from 'react';

import Grid from '@mui/material/Grid';

import Loading from 'components/Loading';

import useDashboardLoading from 'hooks/useDashboardLoading';

import Content from './Content';

const DashboardContent = ({ onOpenCompleteProfileDialog }) => {
  const { isLoading } = useDashboardLoading();

  return (
    <Grid container item columnSpacing={2}>
      <Grid container item xs={12}>
        <Loading loading={isLoading} popup />
        <Grid item xs={12}>
          <Content onOpenCompleteProfileDialog={onOpenCompleteProfileDialog} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardContent;
