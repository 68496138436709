import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import Button from 'components/Button';
import { useStores } from 'components/StateProvider';

import condTrueFalse from 'utils/condTrueFalse';
import history from 'utils/history';
import useFetchApi from 'hooks/useFetchApi';
import useDashboardLoading from 'hooks/useDashboardLoading';

import ContentContainer from '../ContentContainer';
import EmptyInvestmentEntity from './EmptyInvestmentEntity';
import InvestmentEntity from './InvestmentEntity';
import { fetchEntityList } from './HelperFunction';

const InvestmentEntityList = () => {
  const {
    auth: { user },
  } = useStores();

  const [accordionExpanding, setAccordionExpanding] = useState('');

  const { isFetched, handleSetIsLoading, handleSetIsFetched } =
    useDashboardLoading();

  useEffect(
    () => condTrueFalse(isFetched, () => handleSetIsFetched(false)),
    [],
  );

  const getEntityList = useMemo(() => fetchEntityList(), []);
  const { apiData: data, isFetchLoading } = useFetchApi({
    url: getEntityList,
    isFetched,
    setIsFetched: handleSetIsFetched,
    setIsLoading: handleSetIsLoading,
    dependencies: [isFetched],
  });

  const handleGoToAddInvestmentEntityPage = useCallback(() => {
    history.push('/add-investment-entity');
  }, []);

  const handleAccordionControlled = useCallback((key) => {
    setAccordionExpanding((prevState) => (prevState !== key ? key : ''));
  }, []);

  if (isFetchLoading) return null;
  if (!data?.length)
    return (
      <ContentContainer data-testid="empty-investment-entity-list">
        <EmptyInvestmentEntity
          onClickAddInvestmentEntity={handleGoToAddInvestmentEntityPage}
        />
      </ContentContainer>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data?.map((datum, index) => (
          <InvestmentEntity
            values={datum}
            key={`${datum?.name}-${datum?.type}${index.toString()}`}
            controlledExpanding={accordionExpanding === index.toString()}
            onChangeControlled={() =>
              handleAccordionControlled(index.toString())
            }
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={{ xs: 'center', md: 'flex-end' }}
      >
        <Grid item>
          <Button
            onClick={handleGoToAddInvestmentEntityPage}
            buttoncolor="purpleHazeGradient"
            disabled={user?.isAddInvestmentEntityDisable}
          >
            <Grid container columnSpacing={1}>
              <Grid item alignItems="center">
                <AddCircleRoundedIcon />
              </Grid>
              <Grid item>Add New Investment Entity</Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(InvestmentEntityList);
