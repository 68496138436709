const ShowMe = ({ when, children }) => {
  let componentToRender = null;

  if (when) {
    componentToRender = children;
  }

  return componentToRender;
};

export default ShowMe;
