import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import DetailsPageContainer from 'components/DetailsPageContainer';
import Dropdown from 'components/Dropdown';
import FieldContainer from 'components/FieldContainer';
import LoadingSpinner from 'components/LoadingSpinner';
import Tabs from 'components/Tabs';
import TabPanels from 'components/TabPanels';

import history from 'utils/history';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useFetchApi from 'hooks/useFetchApi';
import useTabs from 'hooks/useTabs';

import Overview from './Overview';
import {
  fetchAdminFundDetailById,
  getReportingDetailsService,
  reportingTabItems,
} from './HelperFunction';

const handleRouteToReportingListPage = () => history.push('/admin/reporting');

const ReportingDetails = () => {
  const { fundId } = useParams();
  const { haveCrudPermission } = useAdminRestriction();
  const [fetchStatus, setFetchStatus] = useState(true);
  const [selectedClass, setSelectedClass] = useState('');
  const [maxDate, setMaxDate] = useState(new Date().toISOString());

  const getFundDetails = useMemo(
    () => fetchAdminFundDetailById(fundId),
    [fundId],
  );

  const { apiData: fundDetails = {} } = useFetchApi({
    url: getFundDetails,
    defaultApiData: {},
    dependencies: [getFundDetails],
  });

  const currency = useMemo(() => {
    if (!fundDetails?.fundConfiguration?.length || !selectedClass)
      return undefined;

    return fundDetails.fundConfiguration.find(
      (fundConfig) => fundConfig.id === selectedClass,
    ).dealCurrency;
  }, [fundDetails?.id, selectedClass]);

  useEffect(() => {
    if (!fundDetails.fundConfiguration?.length) return;

    setSelectedClass(fundDetails.fundConfiguration[0].id);
  }, [fundDetails?.id]);

  const getReportingDetails = useMemo(
    () =>
      getReportingDetailsService({
        fundConfigurationId: selectedClass,
        maxDate,
      }),
    [selectedClass, fetchStatus, maxDate],
  );

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  );

  const {
    apiData: {
      fundUpdates = [],
      fundUpdatesOverview = {},
      fundRegistries = [],
    },
    isFetchLoading,
  } = useFetchApi({
    url: getReportingDetails,
    defaultApiData: {},
    dependencies: [getReportingDetails],
  });

  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx: 0,
    callbackDependencies: [fundDetails.id],
  });

  const fundClassOptions = useMemo(
    () =>
      fundDetails?.fundConfiguration?.map((fundConfiguration) => ({
        label: fundConfiguration.className,
        value: fundConfiguration.id,
      })),
    [fundDetails?.fundConfiguration],
  );

  const memoizedTabPanelItems = useMemo(
    () =>
      reportingTabItems({
        onSubmitCb: handleRefetch,
        fundConfigurationId: selectedClass,
        readOnly: !haveCrudPermission,
        currency,
      }),
    [handleRefetch, selectedClass, haveCrudPermission, currency],
  );

  const handleChangeClass = useCallback((e) => {
    const classId = e.target.value;

    setSelectedClass(classId);
  }, []);

  const handleChangeDate = useCallback((name, value) => {
    const newDate = new Date(value);
    newDate.setUTCHours(23, 59, 59, 999);

    setMaxDate(newDate.toISOString());
  }, []);

  return (
    <DetailsPageContainer
      onBackButtonClick={handleRouteToReportingListPage}
      backButtonLabel={fundDetails?.fundName || '[Fund name]'}
      status={fundDetails?.status}
    >
      <Grid container rowSpacing={3}>
        <Grid container item xs={12} justifyContent="right">
          <FieldContainer
            item
            xs={12}
            md={3}
            lg={2}
            xl={1.5}
            label="Unit Class"
          >
            <Dropdown
              options={fundClassOptions}
              onChange={handleChangeClass}
              name="class"
              value={selectedClass}
              maxheight="44px"
            />
          </FieldContainer>
        </Grid>
        {isFetchLoading ? (
          <LoadingSpinner loading={isFetchLoading} />
        ) : (
          <>
            <Grid item xs={12}>
              <Overview
                firstOverview={fundUpdatesOverview.firstOverview}
                secondOverview={fundUpdatesOverview.secondOverview}
                currency={currency}
              />
            </Grid>
            <Grid item xs={12}>
              <Tabs
                key={fundDetails?.id}
                value={activeTabIdx}
                onChange={handleTabChange}
                centered={false}
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
              >
                <TabComponents
                  activeTabIdx={activeTabIdx}
                  tabItems={memoizedTabPanelItems}
                  testIdPrefix="add-articles-tab"
                />
              </Tabs>
            </Grid>
            <Grid item xs>
              <TabPanels
                value={activeTabIdx}
                tabItems={memoizedTabPanelItems}
                boxPanelProps={{ sx: { padding: 0 } }}
                fundUpdatesProps={{
                  fundUpdates,
                }}
                fundRegistryProps={{
                  fundRegistries,
                  maxDate,
                  onChangeMaxDate: handleChangeDate,
                  fundDetails,
                }}
                fundPerformanceProps={{
                  fundDetails,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </DetailsPageContainer>
  );
};

export default ReportingDetails;
