import React, { useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import Button from 'components/Button';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import LoadingSpinner from 'components/LoadingSpinner';

import useFetchApi from 'hooks/useFetchApi';
import useDialog from 'hooks/useDialog';
import PayloadMappers from 'utils/PayloadMappers';
import services from 'api/services';

import {
  REPORTING_TEMPLATE_CLOSED_END,
  REPORTING_TEMPLATE_OPEN_END,
} from 'constants/funds';
import { ReactComponent as AddCircleIcon } from 'assets/icons/add-circle.svg';

import {
  getClosedEndFundPerformanceHeaders,
  fetchAdminFundPerformancesByFundConfigurationId,
  getOpenEndFundPerformanceHeaders,
  openEndFundPerformanceGroupingModel,
} from './HelperFunction';
import FundPerformanceDialog from './FundPerformanceDialog';

const FundPerformance = ({ fundConfigurationId, fundDetails }) => {
  console.log(fundConfigurationId);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    isDialogOpen: isDialogFundPerformanceOpen,
    handleOpenDialog: handleOpenFundPerformanceDialog,
    handleCloseDialog: handleCloseDialogFundPerformance,
  } = useDialog();
  const {
    isDialogOpen: dialogDeleteOpen,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
  } = useDialog();

  const getFundPerformances = useMemo(
    () =>
      fetchAdminFundPerformancesByFundConfigurationId({
        fundConfigurationId,
      }),
    [fundConfigurationId, fetchStatus],
  );

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  );

  const { apiData: fundPerformances, isFetchLoading } = useFetchApi({
    url: getFundPerformances,
    defaultApiData: {},
    dependencies: [getFundPerformances],
  });

  const { fundPerformanceHeaders, fundPerformanceGroupingModel } =
    useMemo(() => {
      if (fundDetails.reportingTemplate === REPORTING_TEMPLATE_CLOSED_END) {
        return {
          fundPerformanceHeaders: getClosedEndFundPerformanceHeaders({
            onDelete: handleOpenDeleteDialog,
          }),
        };
      }

      return {
        fundPerformanceHeaders: getOpenEndFundPerformanceHeaders({
          onDelete: handleOpenDeleteDialog,
        }),
        fundPerformanceGroupingModel: openEndFundPerformanceGroupingModel,
      };
    }, [fundDetails.reportingTemplate]);

  const handleSubmit = useCallback(
    async (formValues) => {
      try {
        setIsLoading(true);
        const payload = PayloadMappers.admin.fundPerformance.addFundPerformance(
          {
            ...formValues,
            fundConfigurationId,
            reportingTemplate: fundDetails.reportingTemplate,
          },
        );

        if (payload.id) {
          await services.adminEditFundPerformance(payload);
        } else {
          await services.adminAddFundPerformance(payload);
        }

        handleCloseDialogFundPerformance();

        handleRefetch();
      } catch (err) {
        setError(err.response?.data || 'Error on creating Fund updates');
      } finally {
        setIsLoading(false);
      }
    },
    [fundConfigurationId, fundDetails.reportingTemplate],
  );

  const handleDeleteFundPerformance = useCallback(async () => {
    try {
      const id = dialogDeleteOpen;

      setIsLoading(true);

      await services.adminDeleteFundPerformance(id);

      handleCloseDeleteDialog();

      handleRefetch();
    } catch (err) {
      setError(err.response?.data || 'Error on deleting Fund performance');
    } finally {
      setIsLoading(false);
    }
  }, [dialogDeleteOpen]);

  const handleClickRow = useCallback(({ row }) => {
    handleOpenFundPerformanceDialog(row);
  }, []);

  if (isFetchLoading) return <LoadingSpinner loading />;

  return (
    <Grid container rowSpacing={3}>
      <Grid container item xs={12} justifyContent="right">
        <Button
          fullWidth={false}
          prefixIcon={AddCircleIcon}
          buttonstyles={{
            maxHeight: '19px',
          }}
          onClick={handleOpenFundPerformanceDialog}
        >
          Add Performance
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          headerData={fundPerformanceHeaders}
          data={fundPerformances}
          containerProps={{ backgroundColor: 'white' }}
          onRowClick={handleClickRow}
          columnGroupingModel={fundPerformanceGroupingModel}
        />
      </Grid>

      <FundPerformanceDialog
        open={!!isDialogFundPerformanceOpen}
        onClose={handleCloseDialogFundPerformance}
        onSubmit={handleSubmit}
        reportingTemplate={
          fundDetails?.reportingTemplate || REPORTING_TEMPLATE_OPEN_END
        }
        errorText={error}
        disableSubmitBtn={isLoading}
        selectedFundPerformance={isDialogFundPerformanceOpen}
      />

      <Loading popup loading={isLoading} />

      <AdminConfirmationDialog
        title="Confirm Delete?"
        subtitle={
          <strong>
            Are you sure you want to delete this Fund Performance?
          </strong>
        }
        open={dialogDeleteOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteFundPerformance}
        confirmButtonLabel="Yes"
        maxWidth="sm"
        confirmBtnDisabled={isLoading}
      />
    </Grid>
  );
};

export default FundPerformance;
