import React, { useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Chip from 'components/Chip';
import Dropdown from 'components/Dropdown';
import EllipsisTypography from 'components/Typography/EllipsisTypography';

import isFunction from 'utils/isFunction';
import {
  INVESTMENT_ENTITY_STATUSES,
  INVESTMENT_ENTITY_STATUS_LABEL,
} from 'constants/investmentEntity';

const VerticalDivider = () => (
  <Divider orientation="vertical" sx={{ display: 'inline', mr: '8px' }} />
);

const getCustomOptionLabel = ({ name, investmentType, type, status }) => (
  <Grid container direction="row">
    <Grid item xs={7} sm={5}>
      <EllipsisTypography variant="cardNameLabel" fontSize="14px" color="black">
        {name}
      </EllipsisTypography>
    </Grid>
    <Grid item xs={0} sm={3} display={{ xs: 'none', sm: 'initial' }}>
      {' '}
      <VerticalDivider />{' '}
      <Typography variant="body" fontWeight={500} color="black">
        {investmentType || type}
      </Typography>
    </Grid>
    <Grid item xs={5} sm="auto">
      <VerticalDivider />
      <Chip
        label={INVESTMENT_ENTITY_STATUS_LABEL[status]}
        color={`${status.toLowerCase()}ChipColor`}
      />
    </Grid>
    <Divider />
  </Grid>
);

const renderSelectedOption = (selected) => getCustomOptionLabel(selected);

const useInvestmentEntityDropdown = ({
  investmentEntities = [],
  onClickAddInvestmentEntity,
}) => {
  const investmentEntityOptions = useMemo(() => {
    if (!investmentEntities.length && onClickAddInvestmentEntity)
      return [
        {
          label: (
            <Typography variant="bodyGreySmall" fontFamily="PT Serif">
              It looks like you are yet to add an investment entity:
              <Typography
                variant="verificationLink"
                onClick={onClickAddInvestmentEntity}
              >
                {' '}
                Add investment entity
              </Typography>
            </Typography>
          ),
        },
      ];

    const entitiesOptions = investmentEntities.map(
      (investmentEntity, index) => ({
        ...investmentEntity,
        label: investmentEntity.name,
        value: investmentEntity.id || investmentEntity.userInvestorMapId || `${index}-${investmentEntity.name}`,
        customLabel: getCustomOptionLabel(investmentEntity),
        disabled:
          investmentEntity.status === INVESTMENT_ENTITY_STATUSES.CANCELED,
      }),
    );
    return entitiesOptions;
  }, [investmentEntities]);

  const handleChangeInvestmentEntity = useCallback(
    (cb) => (e) => {
      if (!isFunction(cb)) return;

      const originalValue = investmentEntityOptions.find(
        (entityOption) => entityOption.value === e.target.value,
      );

      cb(e, originalValue);
    },
    [investmentEntityOptions],
  );

  const InvestmentEntityDropdownComponent = useCallback(
    ({ onChange, ...props }) => (
      <Dropdown
        onChange={handleChangeInvestmentEntity(onChange)}
        options={investmentEntityOptions}
        renderSelectedOption={renderSelectedOption}
        {...props}
      />
    ),
    [investmentEntityOptions],
  );

  return {
    InvestmentEntityDropdownComponent,
    investmentEntityOptions,
  };
};

export default useInvestmentEntityDropdown;
