import React, { useState, useCallback, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Chip from 'components/Chip';
import TextButton from 'components/Button/TextButton';
import {
  INVESMENT_ENTITY_LIST_FIELD_DISPLAY,
  INVESTMENT_ENTITY_STATUS_LABEL,
} from 'constants/investmentEntity';
import { sendEmailTo } from 'utils/email';
import FieldContainer from 'components/FieldContainer';
import ShowMe from 'components/ShowMe';
import CompanyDetails from './CompanyDetails';
import IndividualDetails from './IndividualDetails';
import TrustDetails from './TrustDetails';
import { mapResponseToDetail } from './HelperFunction';

const getDetailsTypeComponent = (type) => {
  if (type === 'individual') return <IndividualDetails />;
  if (type === 'company') return <CompanyDetails />;
  return <TrustDetails />;
};

const handleMailSend = () => {
  const propsEmail = {
    email: 'contact@reachalts.com.au',
    subject: 'Request: Entity Investment Amendment',
  };
  sendEmailTo(propsEmail);
};
const InvestmentEntity = ({
  values = {},
  controlledExpanding,
  onChangeControlled,
  showStatus = true,
  showRequestChangeButton = true,
}) => {
  const [isExpanding, setIsExpanding] = useState(false);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleExpanding = useCallback(() => {
    if (typeof onChangeControlled === 'function') onChangeControlled();
    else setIsExpanding((prevState) => !prevState);
  }, []);

  const expanded = useMemo(
    () => controlledExpanding ?? isExpanding,
    [controlledExpanding, isExpanding],
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpanding}
      sx={{ marginBottom: { xs: '10px', md: '20px' } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{ alignItems: expanded && isMobileScreen ? 'baseline' : undefined }}
      >
        <Grid container rowSpacing={[2, 0]}>
          {INVESMENT_ENTITY_LIST_FIELD_DISPLAY.map(
            ({
              label,
              name,
              type,
              xs,
              md,
              renderer = (value) => value,
              showOnMobile,
            }) => (
              <ShowMe
                when={
                  (type !== 'chip' || showStatus) &&
                  (!expanded && isMobileScreen ? showOnMobile : true)
                }
                key={`field-${label}`}
              >
                <Grid item xs={expanded && isMobileScreen ? 12 : xs} md={md}>
                  <FieldContainer label={label}>
                    {type === 'text' ? (
                      <Typography variant="display5">
                        {renderer(values[name])}
                      </Typography>
                    ) : (
                      <Chip
                        textTransform="uppercase"
                        label={INVESTMENT_ENTITY_STATUS_LABEL[values?.[name]]}
                        color={`${values?.[name]?.toLowerCase()}ChipColor`}
                      />
                    )}
                  </FieldContainer>
                </Grid>
              </ShowMe>
            ),
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {React.cloneElement(
          getDetailsTypeComponent(values?.type.toLowerCase()),
          {
            values: mapResponseToDetail(values),
            withEdit: false,
          },
        )}
        <br />
        <ShowMe when={showRequestChangeButton}>
          <TextButton
            variant="bodyPurple"
            sx={{ float: 'right', marginBottom: '15px' }}
            onClick={handleMailSend}
          >
            <EditOutlinedIcon /> Request to change/update details
          </TextButton>
        </ShowMe>
      </AccordionDetails>
    </Accordion>
  );
};

export default InvestmentEntity;
