import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ExpiredDocumentLink from 'components/ExpiredDocumentLink';

import { useStores } from 'components/StateProvider';
import { FROM_DOCUMENT_PAGE } from 'constants/general';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';

import DownloadIcon from 'assets/images/download_icon.png';

import Helper from './HelperFunction';

const {
  fetchDataRoomDownloadURL,
  fetchDocumentDownloadURL,
  downloadBase64WithBlob,
} = Helper;

const DownloadDocumentPage = () => {
  const [isExpired, setIsExpired] = useState(false);
  const {
    auth: {
      user: { isDataRoomDisable, isAddInvestmentEntityDisable },
    },
  } = useStores();
  const [searchParams] = useSearchParams();

  const handleRedirectDashboard = useCallback(
    (opts = {}) => history.push('/', opts),
    [history],
  );

  const handleFetchError = useCallback(
    (error) => {
      if (
        [
          'error.data-room.not-allowed',
          'error.data-room.get-document.failed',
        ].includes(error?.code)
      )
        handleRedirectDashboard({
          from: FROM_DOCUMENT_PAGE,
          isDataRoomDisable: true, // hardcode true since getting error from endpoint
          isAddInvestmentEntityDisable,
        });
      if (error?.code === 'error.data-room.download-token.expired')
        setIsExpired(true);
    },
    [isAddInvestmentEntityDisable],
  );

  const handleReloadPage = useCallback(() => window.location.reload(), []);

  const getDownloadURL = useMemo(() => {
    const fundId = searchParams.get('fundId');

    const token = searchParams.get('token');
    // this used for document download
    const type = searchParams.get('type');

    if (type === 'documents') {
      return fetchDocumentDownloadURL({ token, handleFetchError });
    }
    return fetchDataRoomDownloadURL({ fundId, token, handleFetchError });
  }, [handleFetchError]);

  const { apiData: downloadURLData, isFetchLoading } = useFetchApi({
    url: getDownloadURL,
  });

  useEffect(() => {
    downloadBase64WithBlob(
      downloadURLData.data?.file,
      downloadURLData.data?.title,
    );
  }, [downloadURLData, isFetchLoading]);

  // validation
  if (isDataRoomDisable) {
    // add delay to avoid redirecting to blank page
    setTimeout(() => {
      history.push('/', {
        from: FROM_DOCUMENT_PAGE,
        isDataRoomDisable,
        isAddInvestmentEntityDisable,
      });
    }, 50);
    return <div />;
  }

  if (isExpired) {
    return (
      <ExpiredDocumentLink handleRedirectDashboard={handleRedirectDashboard} />
    );
  }

  return (
    <Grid container sx={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        <Typography variant="h2">Download in progress...</Typography>
      </Grid>
      <Grid item xs={12} mt={5}>
        <img src={DownloadIcon} alt="download-icon" />
      </Grid>
      <Grid item xs={12} mt={10}>
        <Typography variant="buttonTextLight">
          Your download will start soon, If your download does not start, please
          <Typography
            sx={{ cursor: 'pointer' }}
            variant="link"
            onClick={handleReloadPage}
          >
            {' '}
            reload the page
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(DownloadDocumentPage);
