import Services from 'api/services';
import { REPORTING_TEMPLATE_CLOSED_END } from 'constants/funds';
import {
  amountCellRenderer,
  amountWithDollarCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const getFundRegistryHeaders = ({
  currency = 'USD',
  reportingTemplate,
} = {}) => {
  let headers = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.33,
    },
    {
      field: 'unitId',
      headerName: 'Unit ID',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'unit',
      headerName: 'Units issued',
      flex: 1,
      renderCell: amountCellRenderer,
    },
    {
      field: 'committedAmount',
      headerName: `Commitment (${currency})`,
      flex: 1,
      renderCell: amountWithDollarCellRenderer,
    },
    {
      field: 'redemptionUnitAmountTotal',
      headerName: 'Unit Redeemed',
      flex: 1,
      renderCell: amountCellRenderer,
    },
    {
      field: 'unitBalance',
      headerName: 'Unit Balance',
      flex: 1,
      renderCell: amountCellRenderer,
    },
    {
      field: 'percentageFundHolding',
      headerName: '% Holding of Fund',
      flex: 1,
      renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
    },
    {
      field: 'called',
      headerName: '% Called',
      flex: 1,
      renderCell: percentCellRenderer,
    },
    {
      field: 'toCall',
      headerName: '% to Call',
      flex: 1,
      renderCell: percentCellRenderer,
    },
    {
      field: 'distribution',
      headerName: 'Distributions',
      flex: 1,
      renderCell: ({ value }) =>
        amountWithDollarCellRenderer({ value, fractions: 2 }),
    },
    {
      field: 'growth',
      headerName: `Growth (${currency})`,
      flex: 1,
      renderCell: amountWithDollarCellRenderer,
    },
    {
      field: 'totalReturn',
      headerName: `Total Return (${currency})`,
      flex: 1,
      renderCell: amountWithDollarCellRenderer,
    },
    {
      field: 'unitPriceNet',
      headerName: `Unit Price (Paid-in, ${currency})`,
      flex: 1,
      renderCell: amountWithDollarCellRenderer,
    },
    {
      field: 'unitPriceGross',
      headerName: `Unit Price (Committed, ${currency})`,
      flex: 1,
      renderCell: amountWithDollarCellRenderer,
    },
    {
      field: 'balanceNet',
      headerName: `Balance (Paid-in, ${currency})`,
      flex: 1,
      renderCell: amountWithDollarCellRenderer,
    },
    {
      field: 'balanceGross',
      headerName: `Balance (Committed, ${currency})`,
      flex: 1,
      renderCell: amountWithDollarCellRenderer,
    },
  ];

  if (reportingTemplate !== REPORTING_TEMPLATE_CLOSED_END) {
    headers = headers.filter(
      (header) =>
        !['distribution', 'growth', 'totalReturn'].includes(header.field),
    );
  }

  return headers;
};

export const fetchFundRegistries = async (fundId) => {
  try {
    if (!fundId) return [];

    const res = await Services.adminGetFundDetailById(fundId);
    return res?.data;
  } catch (_) {
    return {};
  }
};
