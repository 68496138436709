const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i;

const getFileFormat = (fileName) => {
  if (!fileName) return fileName;

  // return array of ['.pdf', 'pdf'];
  return fileName.match(pattern)[1];
};

export default getFileFormat;
