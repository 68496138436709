import Services from 'api/services';
import { dateTimeCellRenderer } from 'utils/Datatables/cellRenderer';

export const fetchAdminOverview = async ({ filter }) => {
  const res = await Services.adminGetOverview(filter);

  return res?.data;
};

export const fetchAdminUserActivities = async ({ filter }) => {
  const res = await Services.adminGetOverviewUserActivity(filter);

  return res?.data;
};

export const userActivityHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.3,
    cellClassName: 'cellBold',
  },
  {
    field: 'user',
    headerName: 'User',
    flex: 1.5,
    cellClassName: 'cellBold',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.75,
  },
  {
    field: 'lastLogin',
    headerName: 'Last login',
    flex: 1,
    cellClassName: 'cellBold',
    renderCell: dateTimeCellRenderer,
  },
  {
    field: 'createdAt',
    headerName: 'Account creation',
    flex: 1,
    renderCell: dateTimeCellRenderer,
  },
];
