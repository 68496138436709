import React, { memo, useMemo } from 'react';

import IsAustraliaResident from 'containers/AddInvestmentEntity/components/Forms/IsAustraliaResident';
import OtherCountryTaxPurpose from 'containers/AddInvestmentEntity/components/Forms/OtherCountryTaxPurpose';
import OtherCountryTaxNumber from 'containers/AddInvestmentEntity/components/Forms/OtherCountryTaxNumber';

import FormTitle from 'components/FormTitle';

import filterCountryOptions from 'utils/strings/filterCountryOptions';

const TaxDetailForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  title,
  hasAustraliaTax,
  existingValues = {},
  noOtherCountryTax = false,
}) => {
  const countryOptions = useMemo(() => {
    const { taxPurposes } = existingValues;

    if (!taxPurposes?.length) return undefined;

    return filterCountryOptions({ taxPurposes });
  }, [existingValues.taxPurposes]);

  return (
    <>
      <FormTitle title={title} />
      <IsAustraliaResident
        values={values}
        errors={errors}
        touched={touched}
        onChange={handleChange}
        onBlur={handleBlur}
        hasAustraliaTaxComponentProps={{ disabledYes: hasAustraliaTax }}
        otherCountryTaxComponentProps={{ options: countryOptions }}
        noOtherCountryTax={noOtherCountryTax}
      />
      {values?.hasAustraliaTax === true && !noOtherCountryTax && (
        <OtherCountryTaxPurpose
          values={values}
          errors={errors}
          touched={touched}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      {(values?.hasAustraliaTax === false || values?.hasOtherCountryTax) && (
        <OtherCountryTaxNumber
          values={values}
          errors={errors}
          touched={touched}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </>
  );
};

export default memo(TaxDetailForm);
