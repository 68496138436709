import MUIMenu from '@mui/material/Menu';

import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled(MUIMenu)(({ theme }) => ({
  '& .MuiMenu-list': {
    padding: '4px 0',
  },
  '& .MuiMenuItem-root': {
    padding: '15px 30px',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1.5),
    },
    '&:active': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity,
      ),
    },
  },
}));

export default StyledMenu;
