const FORGOT_PASSWORD_EMAIL_NOT_FOUND = 'error.forgot-password.email-not-found';
const PASSWORD_SAME_AS_PREVIOUS = 'error.forgot-password.same-password-inputed';

const ERROR_CODES = {
  FORGOT_PASSWORD_EMAIL_NOT_FOUND,
  PASSWORD_SAME_AS_PREVIOUS,
};
const ERROR_MSG = {
  [FORGOT_PASSWORD_EMAIL_NOT_FOUND]:
    "We couldn't find an account with that email address.",
  [PASSWORD_SAME_AS_PREVIOUS]: 'This password has previously been used.',
};

const ERROR = {
  [FORGOT_PASSWORD_EMAIL_NOT_FOUND]: {
    code: FORGOT_PASSWORD_EMAIL_NOT_FOUND,
    message: ERROR_MSG[FORGOT_PASSWORD_EMAIL_NOT_FOUND],
  },
};

export default { ERROR, ERROR_CODES, ERROR_MSG };
