import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Chip from 'components/Chip';
import FieldContainer from 'components/FieldContainer';

import mapValue from 'utils/mapValue';

import {
  FUND_REDEMPTION_REQUEST_DETAILS_FIELDS,
  FUND_REDEMPTION_REQUEST_STATUS_LABEL,
} from 'constants/fundRedemptionRequest';

const FundRedemptionRequestDetailsInfo = ({ values }) => (
  <Grid container spacing={3}>
    {FUND_REDEMPTION_REQUEST_DETAILS_FIELDS.map((item) => (
      <Grid item xs={6} sm={4} md={4} key={`${item.label}-${item.name}`}>
        <FieldContainer label={item.label}>
          {item.name === 'status' ? (
            <Chip
              label={FUND_REDEMPTION_REQUEST_STATUS_LABEL[values?.status]}
              color={`${mapValue(
                values?.status,
                'pending',
              )?.toLowerCase()}ChipColor`}
            />
          ) : (
            <Typography variant="display5">{values?.[item.name]}</Typography>
          )}
        </FieldContainer>
      </Grid>
    ))}
  </Grid>
);
export default FundRedemptionRequestDetailsInfo;
