import React, { useMemo } from 'react';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import config from 'utils/config';
import adminMenuItems from 'router/AdminMenuItems';

import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo-grey.svg';

import DrawerContent from './DrawerContent';
import DrawerHeader from './DrawerHeader';

const AdminDrawer = ({ open, onClose }) => {
  const container = useMemo(
    () => (window !== undefined ? window.document.body : undefined),
    [window],
  );

  return (
    <Drawer
      container={container}
      variant="persistent"
      open={open}
      onClose={onClose}
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: config.drawer.admin.width,
          backgroundImage: 'none',
          boxShadow: 'inherit',
          backgroundColor: '#292929',
        },
      }}
    >
      <DrawerHeader>
        <LogoIcon />
        <IconButton onClick={onClose}>
          <MenuIcon color="darkGrey" />
        </IconButton>
      </DrawerHeader>
      {open && <DrawerContent items={adminMenuItems} open={open} />}
    </Drawer>
  );
};

export default AdminDrawer;
