const INVALID_CODE = 'invalid_code';
const BLOCKED = 'blocked';
const INVALID_TOKEN = 'error.phone-number.token.not.found';

const ERROR_CODES = {
  INVALID_CODE,
  BLOCKED,
  INVALID_TOKEN,
};

const ERROR_MSG = {
  [INVALID_CODE]: 'Wrong code, please try again.',
  [BLOCKED]:
    'You have tried to resend the code 20 times, you can try again later in 24 hours, or using different phone number.',
  [INVALID_TOKEN]: 'Wrong code, please try again.',
};

const ERROR = {
  [INVALID_CODE]: { code: INVALID_CODE, message: ERROR_MSG[INVALID_CODE] },
  [BLOCKED]: { code: BLOCKED, message: ERROR_MSG[BLOCKED] },
  [INVALID_TOKEN]: { code: INVALID_TOKEN, message: ERROR_MSG[INVALID_TOKEN] },
};

export default { ERROR, ERROR_MSG, ERROR_CODES };
