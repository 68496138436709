import React from 'react';

import { Typography } from '@mui/material';

import palette from 'theme/palette';
import Link from 'components/Link';
import { generateMailTo } from 'utils/email';
import {
  REACH_ADMIN_EMAIL,
  REACH_EMAIL_SUBJECT_PERSONAL_AMENDMENT,
} from 'constants/general';

const mailHref = generateMailTo({
  email: REACH_ADMIN_EMAIL,
  subject: REACH_EMAIL_SUBJECT_PERSONAL_AMENDMENT,
});

const ContactAdminDialogContent = (
  <div style={{ padding: '40px', background: palette.reach.grey }}>
    <Typography variant="display6" display="block" marginBottom={3}>
      SECURITY REASONS
    </Typography>
    <Typography variant="h6">
      We are committed to providing you with the best quality service. To add
      additional protection for you and our community, we require that any
      changes to your email or to your name are carefully reviewed. If you would
      like to request a change, please click on the ‘contact administrator’
      button, or send an email to
      <Link href={mailHref} target="_blank">
        {' '}
        contact@reachalts.com.au.
      </Link>
    </Typography>
  </div>
);

export default ContactAdminDialogContent;
