import trimMaskInput from 'utils/trimMaskInput';

const getCompanyIdentifier = (companyDetailsValue) => {
  if (companyDetailsValue?.country === 'AU') {
    return companyDetailsValue?.australianTaxNumber || '';
  }
  if (companyDetailsValue?.country === 'NZ') {
    return companyDetailsValue?.newZealandTaxNumber || '';
  }
  return companyDetailsValue?.otherTaxNumber || '';
};

const companyDetails = (companyDetailsValue) => ({
  companyName: companyDetailsValue?.companyName || '',
  registeredCountry: companyDetailsValue?.country || '',
  companyIdentifier: trimMaskInput(getCompanyIdentifier(companyDetailsValue)),
  isPublicCompany:
    companyDetailsValue?.isPublicCompany !== undefined
      ? companyDetailsValue?.isPublicCompany
      : false,
  isCharity:
    companyDetailsValue?.isNonProfitable !== undefined
      ? companyDetailsValue?.isNonProfitable
      : false,
  address1: companyDetailsValue?.firstAddress || '',
  address2: companyDetailsValue?.secondAddress || '',
  suburb: companyDetailsValue?.suburb || '',
  city: companyDetailsValue?.city || '',
  state: companyDetailsValue?.state || '',
  postcode: companyDetailsValue?.postCode || '',
  country: companyDetailsValue?.addressCountry || '',
});

export default companyDetails;
