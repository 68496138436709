import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from 'components/Chip';
import FieldContainer from 'components/FieldContainer';
import { REQUEST_DETAILS_FIELDS } from 'constants/investmentEntity';

const RequestDetails = ({ values }) => (
  <Grid container spacing={3}>
    {REQUEST_DETAILS_FIELDS.map((item) => (
      <Grid item xs={6} md={3} key={`${item.label}-${item.name}`}>
        <FieldContainer label={item.label}>
          {item.name !== 'status' ? (
            <Typography variant="display5">{values?.[item.name]}</Typography>
          ) : (
            <Chip
              textTransform="capitalize"
              label={values?.[item.name]}
              color={`${
                values?.[item.name]?.toLowerCase() || 'pending'
              }ChipColor`}
            />
          )}
        </FieldContainer>
      </Grid>
    ))}
  </Grid>
);
export default RequestDetails;
