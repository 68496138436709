import React, { useEffect, useCallback, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { UploadFile } from '@mui/icons-material';

import Dialog from 'components/Dialog';
import DragAndDrop from 'components/DragAndDrop';
import LoadingSpinner from 'components/LoadingSpinner';

import useUploadFiles from 'hooks/useUploadFiles';

import DocumentList from './DocumentList';

const UploadDialog = ({
  maxFiles,
  onUpload,
  onCloseCallback,
  onRemoveValidation,
  accept = 'application/pdf',
  open,
  onClose,
  fileRequirementText = 'Files must be in pdf format. Max (15MB)',
  maxSize,
  ...props
}) => {
  const [resetFile, setResetFile] = useState(false);
  const [uploadCbResult, setUploadCbResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = useCallback(
    async (files) => {
      try {
        setIsLoading(true);
        if (typeof onUpload === 'function') {
          await onUpload(files, setUploadCbResult);
        } else {
          setUploadCbResult(files?.target?.value);
        }
      } catch (_) {
        setResetFile(true);
      } finally {
        setIsLoading(false);
      }
    },
    [onUpload],
  );

  const handleRemoveValidation = useCallback(async (fileToRemove) => {
    try {
      if (typeof onRemoveValidation === 'function') {
        const res = await onRemoveValidation(fileToRemove);
        return res;
      }
      return true;
    } catch (_) {
      return false;
    }
  });

  const {
    files,
    handleUpload,
    getFileName,
    handleRemoveFile,
    handleChangeFiles,
    isDisabled,
  } = useUploadFiles({
    name: 'files',
    value: [],
    onChangeCallback: handleFileUpload,
    onRemoveValidation: handleRemoveValidation,
    maxFiles,
    maxFileNameLength: 40,
    newFileAttributes: {
      hide: false,
      view: true,
      downloadable: true,
    },
    updateStateOnUpload: false,
    maxSize,
    accept,
  });

  useEffect(() => {
    if (uploadCbResult) {
      handleChangeFiles({ newFiles: uploadCbResult, callCb: false });
    }
    setUploadCbResult(null);
  }, [uploadCbResult]);

  useEffect(() => {
    if (resetFile) {
      handleChangeFiles({ newFiles: [] });
    }
    setResetFile(false);
  }, [resetFile]);

  useEffect(() => {
    if (!open) {
      onCloseCallback(files);
      setResetFile(true);
    }
  }, [open]);

  const handleClose = useCallback(() => {
    if (isLoading) return;

    onClose();
  }, [isLoading]);

  const dialogContent = useMemo(
    () => (
      <Grid container spacing={3}>
        <Grid container item xs={12} justifyContent="center">
          <UploadFile
            color="saveButton"
            sx={{ height: '88px', width: '69px' }}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="display2"> Upload Files</Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="smallGrey" sx={{ fontSize: '18px !important' }}>
            {fileRequirementText}
          </Typography>
        </Grid>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Grid item xs={12}>
              <DragAndDrop
                onChange={handleUpload}
                accept={accept}
                disabled={isDisabled}
              />
            </Grid>

            <Grid item xs={12}>
              <DocumentList
                fileList={files}
                getFileName={getFileName}
                onRemove={handleRemoveFile}
              />
            </Grid>
          </>
        )}
      </Grid>
    ),
    [files, handleRemoveFile, handleUpload],
  );

  return (
    <Dialog
      onClose={handleClose}
      content={dialogContent}
      maxWidth="sm"
      dialogContentProps={{
        style: {
          maxHeight: '700px',
        },
      }}
      onBackDropClick={null}
      open={open}
      {...props}
    />
  );
};

export default UploadDialog;
