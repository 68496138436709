import React from 'react';
import BankDetail from './BankDetail';
import PersonList from './PersonList';
import TrustDetail from './TrustDetail';
import TrusteeList from './TrusteeList';
import TrustTaxDetails from './TrustTaxDetails';

const TrustDetails = ({ values, ...props }) => (
  <>
    <TrustDetail values={values.detail} />
    <TrusteeList values={values.detail?.trustees} {...props} />
    <PersonList
      values={values.detail?.trustBeneficialOwner}
      listLabel="Beneficial Owner of Trust"
      detailTitle={null}
      {...props}
    />
    {/* Trust Tax Details Here  */}
    <TrustTaxDetails values={values.detail} {...props} />
    <BankDetail
      values={values.detail?.bankDetail}
      label="Trust Bank Detail"
      {...props}
    />
  </>
);

export default TrustDetails;
