import React, { useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dropdown from 'components/Dropdown';
import ShowMe from 'components/ShowMe';

import useDataTables from 'hooks/useDataTables';
import services from 'api/services';
import { REPORTING_TEMPLATE_CLOSED_END } from 'constants/funds';

import IRRTab from './IRRTab';
import MultiplesTab from './MultiplesTab';
import PortfolioTab from './PortfolioTab';
import NetFundReturnTab from './NetFundReturnTab';

const PERFORMANCE_TYPES = {
  PORTFOLIO: 'Portfolio ($)',
  IRRvPME: 'IRR v PME',
  MULTIPLES: 'Multiples',
  NET_FUND_RETURN: 'Net Fund Return (%)',
};

const performanceOptions = Object.values(PERFORMANCE_TYPES).reduce(
  (options, value) => {
    if (value === PERFORMANCE_TYPES.NET_FUND_RETURN) return options;

    // Hide in prod.
    if (
      process.env.REACT_APP_ENV === 'prod' &&
      value === PERFORMANCE_TYPES.IRRvPME
    )
      return options;

    return options.concat({
      label: value,
      value,
    });
  },
  [{ label: '', value: '' }],
);

const PerformanceTab = ({ portfolio }) => {
  const [performanceType, setPerformanceType] = useState(
    portfolio?.fund?.reportingTemplate === REPORTING_TEMPLATE_CLOSED_END
      ? PERFORMANCE_TYPES.PORTFOLIO
      : PERFORMANCE_TYPES.NET_FUND_RETURN,
  );

  const { apiData: fundPerformances, isFetchLoading } = useDataTables({
    fetchService: () =>
      services.getFundPerformancesByPortfolioId({
        portfolioId: portfolio.id,
      }),
    defaultApiData: [],
  });
  const { apiData: portfolioPerformances } = useDataTables({
    fetchService:
      performanceType === PERFORMANCE_TYPES.PORTFOLIO
        ? () =>
            services.getPortfolioPerformancesByPortfolioId({
              portfolioId: portfolio.id,
            })
        : () => true,
    defaultApiData: [],
  });

  const SelectedComponent = useMemo(() => {
    switch (performanceType) {
      case PERFORMANCE_TYPES.PORTFOLIO: {
        return PortfolioTab;
      }
      case PERFORMANCE_TYPES.IRRvPME: {
        return IRRTab;
      }
      case PERFORMANCE_TYPES.MULTIPLES: {
        return MultiplesTab;
      }
      case PERFORMANCE_TYPES.NET_FUND_RETURN: {
        return NetFundReturnTab;
      }
      default: {
        return () => <span>Not Found</span>;
      }
    }
  }, [performanceType]);

  const handleChangePerformanceType = useCallback((e) => {
    setPerformanceType(e.target.value);
  }, []);

  return (
    <Grid container rowSpacing={4}>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={6}>
          <Typography fontWeight="700">{performanceType}</Typography>
        </Grid>
        <ShowMe when={performanceType !== PERFORMANCE_TYPES.NET_FUND_RETURN}>
          <Grid item xs={6} md={2}>
            <Dropdown
              options={performanceOptions}
              onChange={handleChangePerformanceType}
              value={performanceType}
              maxheight="44px"
              minWidth="170px"
            />
          </Grid>
        </ShowMe>
      </Grid>
      <Grid item xs={12}>
        <SelectedComponent
          fundPerformances={fundPerformances}
          portfolioPerformances={portfolioPerformances}
        />
      </Grid>
    </Grid>
  );
};

export default PerformanceTab;
