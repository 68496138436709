import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Chip from 'components/Chip';
import Services from 'api/services';

import { INVESTMENT_ENTITY_STATUS_LABEL } from 'constants/investmentEntity';

const fetchInvestmentEntityListUser = async () => {
  try {
    const response = await Services.getInvestmentEntityListUser();
    return response?.data?.data;
  } catch (_) {
    // handle error here
    return [];
  }
};

const VerticalDivider = () => (
  <Divider orientation="vertical" sx={{ display: 'inline', mr: '8px' }} />
);

const getCustomOptionLabel = ({ name, investmentType, status }) => (
  <Grid container direction="row">
    <Grid item xs={7} sm={5}>
      <Typography variant="cardNameLabel" fontSize="14px" color="black">
        {name}
      </Typography>
    </Grid>
    <Grid item xs={0} sm={4} display={{ xs: 'none', sm: 'initial' }}>
      {' '}
      <VerticalDivider />{' '}
      <Typography variant="body" fontWeight={500} color="black">
        {investmentType}
      </Typography>
    </Grid>
    <Grid item xs={5} sm={3}>
      <VerticalDivider />
      <Chip
        label={INVESTMENT_ENTITY_STATUS_LABEL[status]}
        color={`${status.toLowerCase()}ChipColor`}
      />
    </Grid>
    <Divider />
  </Grid>
);

const renderSelectedOption = (selected) => getCustomOptionLabel(selected);

export default {
  fetchInvestmentEntityListUser,
  getCustomOptionLabel,
  renderSelectedOption,
};
