import {
  amountCellRenderer,
  amountWithDollarCellRenderer,
  dateCellRenderer,
  fundUpdatesCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const transactionOpenEndHeaders = [
  {
    field: 'date',
    headerName: 'Value Date',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(dateCellRenderer),
  },
  {
    field: 'description',
    headerName: 'Descriptions',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(({ value }) => value),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(({ value, row }) =>
      row.transactionType === 'REDEMPTION'
        ? `-${amountWithDollarCellRenderer({ value, fractions: 2 })}`
        : amountWithDollarCellRenderer({ value, fractions: 2 }),
    ),
  },
  {
    field: 'unitChange',
    headerName: 'Unit Quantity',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountCellRenderer)({ ...props, fractions: 2 }),
  },
  {
    field: 'unit',
    headerName: 'Unit Holding',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountCellRenderer)({ ...props, fractions: 2 }),
  },
  {
    field: 'unitPriceNet',
    headerName: 'Unit Price',
    flex: 1,
    renderCell: ({ value, row }) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        value,
        row,
        fractions: 4,
      }),
  },
  {
    field: 'investmentValue',
    headerName: 'Value (est.)',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        ...props,
        fractions: 2,
      }),
  },
];

export const transactionCloseEndHeaders = [
  {
    field: 'date',
    headerName: 'Value Date',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(dateCellRenderer),
  },
  {
    field: 'description',
    headerName: 'Descriptions',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(({ value }) => value),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(({ value, row }) =>
      row.transactionType === 'REDEMPTION'
        ? `-${amountWithDollarCellRenderer({ value, fractions: 2 })}`
        : amountWithDollarCellRenderer({ value, fractions: 2 }),
    ),
  },
  {
    field: 'unitChange',
    headerName: 'Unit Quantity',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountCellRenderer)({ ...props, fractions: 2 }),
  },
  {
    field: 'unit',
    headerName: 'Unit Holding',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountCellRenderer)({ ...props, fractions: 2 }),
  },
  {
    field: 'funded',
    headerName: 'Funded',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        ...props,
        fractions: 2,
      }),
  },
  {
    field: 'distribution',
    headerName: 'Distribution',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        ...props,
        fractions: 2,
      }),
  },
  {
    field: 'investmentValue',
    headerName: 'Value (est.)',
    flex: 1,
    renderCell: (props) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        ...props,
        fractions: 2,
      }),
  },
];
