import * as yup from 'yup';
import trimMaskInput from 'utils/trimMaskInput';

const companyTaxValidationSchema = yup.object({
  hasAustraliaTax: yup.boolean().required().label('Australia Tax Purpose'),
  australiaTax: yup
    .string()
    .label('Tax File Number (TFN)*')
    .when('hasAustraliaTax', {
      is: true,
      then: yup.string().transform(trimMaskInput).min(5).max(18).required(),
    }),
  otherCountryTax: yup
    .string()
    .label('Other country name')
    .when('hasAustraliaTax', {
      is: false,
      then: yup.string().required(),
    }),
  isTINAvailable: yup
    .boolean()
    .label('Other country TIN or equivalent')
    .when('hasAustraliaTax', {
      is: false,
      then: yup.boolean().required(),
    }),
  otherCountryTIN: yup
    .string()
    .label('TIN (Tax Index Number*)')
    .when(['isTINAvailable', 'hasAustraliaTax'], {
      is: (isTINAvailable, hasAustraliaTax) =>
        !!isTINAvailable && hasAustraliaTax === false,
      then: yup.string().min(5).max(18).required(),
    }),
  noTINReason: yup
    .string()
    .label('Reason not providing your TIN')
    .when(['isTINAvailable', 'hasAustraliaTax'], {
      is: (isTINAvailable, hasAustraliaTax) =>
        isTINAvailable === false && hasAustraliaTax === false,
      then: yup.string().required(),
    }),
  unableObtainTinReason: yup
    .string()
    .label('Reason not providing your TIN')
    .when(['noTINReason', 'hasAustraliaTax', 'isTINAvailable'], {
      is: (noTINReason, hasAustraliaTax, isTINAvailable) =>
        noTINReason === '2' &&
        hasAustraliaTax === false &&
        isTINAvailable === false,
      then: yup.string().required(),
    }),
});

export default companyTaxValidationSchema;
