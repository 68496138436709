import React, { memo, useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import Checkbox from 'components/Checkbox';
import PDFViewer from 'components/PDFViewer';
import InvestmentEntityContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityContainer';
import { DOCUMENT_SIGNING_DECLARATION } from 'constants/investmentEntity';
import { useParams } from 'react-router-dom';
import useFetchApi from 'hooks/useFetchApi';
import services from 'api/services';
import ShowMe from 'components/ShowMe';

const DocumentSigning = ({ onChange, values, onBack, onNext, errors }) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { fundId } = useParams();

  const getDocumentData = useMemo(
    () =>
      services.getDocumentSigning({
        fundId,
        fundConfigurationId: values.financialInformationClass,
      }),
    [values.financialInformationClass],
  );

  const { apiData: documentSigningData } = useFetchApi({
    url: getDocumentData,
  });

  const pdfData = useMemo(
    () => window.atob(documentSigningData?.data?.file || ''),
    [documentSigningData?.data?.file],
  );

  const handleSubmit = useCallback(() => onNext({ submit: true }), [onNext]);

  return (
    <Paper elevation={3} sx={{ marginTop: 5 }}>
      <InvestmentEntityContainer
        onNext={handleSubmit}
        onBack={onBack}
        isNextButtonDisabled={!!Object.keys(errors).length}
        withHeader={false}
        nextButtonLabel="Confirm"
      >
        <Grid container spacing={3} padding={[0, 4]}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h1">Document Signing</Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6">
              Information of Agreement and Documents
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider flexItem orientation="horizontal" />
          </Grid>
          <ShowMe when={!!pdfData}>
            <Grid item xs={12}>
              <PDFViewer
                pdfData={pdfData}
                style={{
                  height: isMobileScreen ? '400px' : '900px',
                  width: '100%',
                }}
              />
            </Grid>
          </ShowMe>
          <Grid item xs={12}>
            <Typography>Name of person submitting declaration:</Typography>
          </Grid>
          <Grid item container xs={12} spacing={[0, 8]} columnSpacing={[1, 0]}>
            <Grid item xs="auto">
              <Typography>
                <b>{values?.signedBy}</b>
              </Typography>
            </Grid>
            <Grid item xs textAlign="right">
              <Typography variant="body">
                Date :{' '}
                {values?.signedAt?.toLocaleDateString('en-AU', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
                *
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              label={
                <Typography variant="declarationText">
                  By Submitting this application:
                </Typography>
              }
              name="isConfirmed"
              onChange={onChange}
              sx={{ marginTop: '24px' }}
              checked={!!values.isConfirmed}
            />
          </Grid>
          <Grid item xs={12} pl={4}>
            <ol>
              {DOCUMENT_SIGNING_DECLARATION.map((text) => (
                <Typography key={text}>
                  <li>{text}</li>
                </Typography>
              ))}
            </ol>
          </Grid>
        </Grid>
      </InvestmentEntityContainer>
    </Paper>
  );
};

export default memo(DocumentSigning);
