const getNumbers = (
  string,
  { allowMinus = false, convertToNumbers = true } = {},
) => {
  if (!string || typeof string !== 'string') return string;

  let res;

  // Only accept numbers & '.' & Minus numbers.
  if (allowMinus) res = string.replace(/[^-?\d.]/g, '');
  else {
    // Only accept numbers & '.'.
    res = string.replace(/[^\d.]/g, '');
  }

  if (convertToNumbers) return Number(res);

  return res;
};

export default getNumbers;
