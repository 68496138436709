import React, { memo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import EllipsisTypography from 'components/Typography/EllipsisTypography';
import ImageContent from 'components/FundCard/FundCardLandscape/ImageContent';
import ShowMe from 'components/ShowMe';
import config from 'utils/config';

const fundContentBorderTopWidth = 8;

const defaultDate = new Date().toISOString();

const FeaturedCard = ({
  id,
  thumbnailImgSrc,
  videoSrc = 'https://static.ffx.io/images/$zoom_0.143%2C$multiply_0.7725%2C$ratio_1.5%2C$width_756%2C$x_0%2C$y_31/t_crop_custom/q_86%2Cf_auto/dfdfd4a577ab171b721ba0bd6e5a4a845f0db52a',
  title = 'The 11% Yield That Isn’t in Your Mutual Fund',
  description = '',
  category = 'article category',
  publishedDate = defaultDate,
  height = '100%',
  imageHeight,
}) => {
  const [play, setPlay] = useState(false);

  const handleClick = () => {
    if (play) return;

    setPlay(!play);
  };

  return (
    <Grid
      container
      minHeight={height}
      maxHeight={height}
      sx={{
        background: config.system.appColours.cardBackground,
        cursor: 'pointer',
        flexFlow: { xs: 'wrap-reverse', md: 'inherit' },
      }}
      onClick={handleClick}
    >
      <Grid
        container
        item
        xs={12}
        sm={4}
        md={4}
        lg
        direction="column"
        sx={{
          borderTop: [
            'none',
            'none',
            `${fundContentBorderTopWidth}px black solid`,
          ],
          position: 'relative',
          transition: 'border-top .5',
        }}
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          pt={2}
          justifyContent="center"
        >
          <Grid item xs={12} alignSelf="center" p={3}>
            <EllipsisTypography
              variant="h3"
              display="block"
              fontWeight={700}
              ellipsisAtLine={2}
            >
              {title}
            </EllipsisTypography>
            <EllipsisTypography
              variant="smallStatic"
              marginTop={1.5}
              ellipsisAtLine={2}
            >
              {description}
            </EllipsisTypography>
            <Typography fontSize="11px" mt={2.1}>
              <b>{category}</b> •{' '}
              {new Date(publishedDate).toLocaleString('en-AU', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        lg={6}
        sx={{
          borderTop: [`${fundContentBorderTopWidth}px black solid`, 'none'],
          maxHeight: imageHeight,
        }}
      >
        <ShowMe when={!play}>
          <ImageContent
            height="100%"
            headerImageSrc={thumbnailImgSrc}
            imageStyles={{ maxHeight: imageHeight }}
          />
        </ShowMe>
        {/* <iframe className='video'
        title='Youtube player'
        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
        src={videoSrc}/> */}
        {/* <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/a7DMYNlV4RA?list=RDEMP3ck2hfA_FvE5un61_VQdw"
        title="PKJ 241 – Tak ‘Ku Tahu ‘Kan Hari Esok // Maria Tampubolon"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      /> */}
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            id={`featured-video-${id}`}
            src={play ? `${videoSrc}?autoplay=1` : ''}
            frameBorder="0"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>
        {/* <iframe
          id={`featured-video-${id}`}
          className="featured-video-iframe"
          width="100%"
          height="100%"
          src={play ? `${videoSrc}?autoplay=1` : ''}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ border: 'none', height: '100%' }}
        /> */}
      </Grid>
    </Grid>
  );
};

export default memo(FeaturedCard);
