import React, { memo, useState, useEffect, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import SidebarContainer from 'components/SidebarContainer';

import services from 'api/services';

import { useActions, useStores } from 'components/StateProvider';

import PasswordUpdated from './PasswordUpdated';
import LinkExpired from './LinkExpired';
import UpdatePassword from './UpdatePassword';

const PAGE_STATUS = {
  expired: 'expired',
  onUpdate: 'onUpdate',
  updated: 'updated',
};

const NewPasswordPage = () => {
  const {
    auth: { isAuthenticated },
  } = useStores();
  const { handleLogout } = useActions();
  const [pageStatus, setPageStatus] = useState(PAGE_STATUS.onUpdate);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tokenCheck = async () => {
      const verificationCode = searchParams.get('token');
      const tokenType = searchParams.get('type');
      try {
        if (isAuthenticated) handleLogout();

        if (tokenType === 'change')
          await services.changePasswordVerifyToken({ verificationCode });
        else await services.forgotPasswordVerifyToken({ verificationCode });
      } catch (error) {
        if (error.response.status > 400) {
          setPageStatus(PAGE_STATUS.expired);
        }
      }
    };
    tokenCheck();
  }, []);

  const component = useMemo(() => {
    switch (pageStatus) {
      case PAGE_STATUS.expired:
        return (
          <LinkExpired
            verificationCode={searchParams.get('token')}
            type={searchParams.get('type')}
          />
        );
      case PAGE_STATUS.updated:
        return <PasswordUpdated />;
      default:
        return (
          <UpdatePassword
            setPageStatus={setPageStatus}
            verificationCode={searchParams.get('token')}
          />
        );
    }
  }, [pageStatus]);

  return <SidebarContainer>{component}</SidebarContainer>;
};

export default memo(NewPasswordPage);
