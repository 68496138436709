import { useMemo } from 'react';

import { useStores } from 'components/StateProvider';

import { ROLE_SUPER_ADMIN } from 'constants/userManagement';

const useAdminRestriction = ({ isRestricted = false } = {}) => {
  const {
    auth: { user },
  } = useStores();

  const isSuperAdmin = useMemo(
    () => user.role === ROLE_SUPER_ADMIN,
    [user.role],
  );

  return {
    haveCrudPermission: isSuperAdmin && !isRestricted,
    isSuperAdmin,
  };
};

export default useAdminRestriction;
