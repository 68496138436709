import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid';

import FormFields from 'containers/DashboardPage/components/CompleteProfileDialog/FormFields';
import FormTitle from 'components/FormTitle';

import condTrueFalse from 'utils/condTrueFalse';
import getNumbers from 'utils/strings/getNumbers';
import {
  getAmountPayableToday,
  getAmountReceived,
  getUnits,
} from 'hooks/useInvestmentAmountInfo';
import { PAY_IN_INSTALLMENT } from 'constants/investmentEntity';

import { installmentInformationFormFields } from './HelperFunction';

const InstallmentInformationSection = ({
  onChange,
  values,
  errors,
  setValues,
  fundConfiguration = {},
}) => {
  const handleChange = useCallback(
    (e) => {
      if (e.target.name !== 'totalInvestment') {
        onChange(e);
        return;
      }

      const totalInvestmentValue = getNumbers(e.target.value);
      // Calculate Fees.
      // const units = fundConfiguration.
      const accessFee =
        (fundConfiguration.accessFee || 0) * totalInvestmentValue;
      const amountPayableToday = getAmountPayableToday({
        isPayInInstallment: values.paymentType === PAY_IN_INSTALLMENT,
        fundConfiguration,
        investmentAmount: totalInvestmentValue,
      });
      const amountReceived = getAmountReceived({
        amountPayableToday,
        accessFee,
        applicationFee: fundConfiguration.applicationFee,
      });
      const units = getUnits({
        investmentAmount: totalInvestmentValue,
        sharePrice: fundConfiguration.sharePrice,
      });

      setValues({
        ...values,
        totalInvestment: e.target.value,
        units,
        accessFee,
        amountPayableToday,
        amountReceived,
      });
    },
    [onChange, values],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormTitle title="Installment Information" />
      </Grid>

      <Grid container item xs={12} p={2} rowSpacing={3}>
        {installmentInformationFormFields.map(
          ({ fieldProps, disabled, ...formField }) => (
            <FormFields
              key={formField.name}
              {...formField}
              fieldProps={{
                ...condTrueFalse(fieldProps, () => fieldProps(values), {}),
                'data-testid': `installmentInformationFormField-${formField.name}`,
                disabled,
                currency:
                  formField.fieldType === 'currencyInput'
                    ? fundConfiguration.dealCurrency
                    : undefined,
              }}
              // onBlur={handleBlur}
              onChange={handleChange}
              // setFieldValue={setFieldValue}
              // touched={touched}
              values={values}
              errors={errors}
              gridProps={{
                labelGridProps: { xs: 3 },
                childGridProps: { xs: 4.5 },
              }}
            />
          ),
        )}
      </Grid>
    </Grid>
  );
};

export default InstallmentInformationSection;
