import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as FundIcon } from 'assets/icons/fund.svg';
import { ReactComponent as DataRoomIcon } from 'assets/icons/data-room.svg';
import { ReactComponent as ArticlesEventsIcon } from 'assets/icons/articles-events.svg';

import BarChartIcon from '@mui/icons-material/BarChartOutlined';

const general = {
  id: 'group-general',
  title: 'GENERAL',
  type: 'group',
  children: [
    {
      id: '/admin',
      title: 'Overview',
      type: 'item',
      url: '/admin',
      icon: DashboardIcon,
    },
    {
      id: '/admin/funds',
      title: 'Funds',
      type: 'item',
      url: '/admin/funds',
      icon: FundIcon,
    },
    {
      id: '/admin/data-rooms',
      title: 'Data Rooms',
      type: 'item',
      url: '/admin/data-rooms',
      icon: DataRoomIcon,
    },
    {
      id: '/admin/articles-events',
      title: 'Article & Events',
      type: 'item',
      url: '/admin/articles-events',
      icon: ArticlesEventsIcon,
    },
    {
      id: '/admin/reporting',
      title: 'Reporting',
      type: 'item',
      url: '/admin/reporting',
      icon: BarChartIcon,
    },
  ],
};

export default general;
