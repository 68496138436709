import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import FormTitle from 'components/FormTitle';
import FundCardTeaserHeader from './FundCardTeaserHeader';

const FundCard = ({ readOnly, onChange, values, errors }) => (
  <Grid container mt={2}>
    <Grid item xs={12}>
      <FormTitle title="Fund Card (additional information)" />
    </Grid>
    <FundCardTeaserHeader
      readOnly={readOnly}
      onChange={onChange}
      values={values}
      errors={errors}
    />
  </Grid>
);

export default memo(FundCard);
