import { useReducer } from 'react';
import { STORAGE_KEY } from '../../constants/general';
import ReachAltReducers from '../reducers';
import ReachAltActions from '../actions';
import ReachAltInitialData from './initial';

const ReachAltStores = () => {
  const [reachAltData, dispatch] = useReducer(
    ReachAltReducers,
    ReachAltInitialData,
    (state) => {
      const rawPersistedData = localStorage.getItem(STORAGE_KEY);
      const parsedItems = rawPersistedData ? JSON.parse(rawPersistedData) : {};

      return {
        ...state,
        ...parsedItems,
      };
    },
  );

  const reachAltActions = ReachAltActions({
    dispatch,
    state: reachAltData,
  });

  return {
    reachAltData,
    reachAltActions,
  };
};

export default ReachAltStores;
