import * as yup from 'yup';
import dayjs from 'dayjs';

import trimMaskInput from 'utils/trimMaskInput';
import condTrueFalse from 'utils/condTrueFalse';

const isAustralianRetirementControllingPersonNFE = (
  isNFE,
  isControllingPersonANaturalOrTrust,
  isAustralianRetirement,
) =>
  isNFE === false &&
  !!isControllingPersonANaturalOrTrust &&
  isAustralianRetirement === false;

const companyTaxSupportingInformationValidationSchema = yup.object({
  isAustralianRetirement: yup
    .boolean()
    .required()
    .label('Australia Tax Purpose'),
  isFinancialInstitution: yup.boolean().when('isAustralianRetirement', {
    is: false,
    then: yup.boolean().required().label('Financial Institution'),
  }),
  hasGIIN: yup
    .boolean()
    .when(['isFinancialInstitution', 'isAustralianRetirement'], {
      is: (isFinancialInstitution, isAustralianRetirement) =>
        !!isFinancialInstitution && isAustralianRetirement === false,
      then: yup.boolean().required().label('GIIN'),
    }),
  companyGIIN: yup.string().when('hasGIIN', {
    is: true,
    then: yup
      .string()
      .transform(trimMaskInput)
      .required()
      .label('Company GIIN'),
  }),
  hasNoGIINValues: yup.object().when('hasGIIN', {
    is: false,
    then: yup.lazy((hasNoGIINValues) => {
      const getNoGIINSchema = (additionalFieldsByQuestion) =>
        yup.object().shape({
          question: yup.string().required(),
          ...additionalFieldsByQuestion,
        });

      if (hasNoGIINValues?.question === '1') {
        return getNoGIINSchema({
          exemptBeneficialOwnerName: yup
            .string()
            .required()
            .label('Exempt Beneficial Owner Name'),
        });
      }
      if (hasNoGIINValues?.question === '2') {
        return getNoGIINSchema({
          deemedCompliantFFI: yup
            .string()
            .required()
            .label('Deemed-Compliant FFI'),
        });
      }
      if (hasNoGIINValues?.question === '3') {
        return getNoGIINSchema({
          sponsoringEntityName: yup
            .string()
            .required()
            .label('Sponsored Financial Institution Name'),
          sponsoringEntityGIIN: yup
            .string()
            .required()
            .label('Sponsored Financial Institution GIIN'),
        });
      }
      if (hasNoGIINValues?.question === '4') {
        return getNoGIINSchema({
          trusteeName: yup.string().required().label('Trustee Name'),
          trusteeGIIN: yup.string().required().label('Trustee GIIN'),
        });
      }
      if (hasNoGIINValues?.question === '5') {
        return getNoGIINSchema({
          otherReason: yup.string().required().label('Other'),
        });
      }
      return yup.object().when('hasGIIN', {
        is: false,
        then: getNoGIINSchema(),
      });
    }),
  }),
  isManagedByAnotherInstitution: yup
    .boolean()
    .when(['isFinancialInstitution', 'hasGIIN', 'isAustralianRetirement'], {
      is: (isFinancialInstitution, hasGIIN, isAustralianRetirement) =>
        (isFinancialInstitution === false || !!hasGIIN) &&
        isAustralianRetirement === false,
      then: yup
        .boolean()
        .required()
        .label('Managed by another Financial Institution'),
    }),
  controllingPerson: yup.object().when('isManagedByAnotherInstitution', {
    is: true,
    then: yup.object().shape({
      // is managed by another institution
      isControllingPersonANatural: yup.boolean().required(),
      isControllingPersonATrust: yup.boolean().required(),
    }),
  }),
  controllingPersonFields: yup
    .object()
    .when(
      ['isNFE', 'isControllingPersonANaturalOrTrust', 'isAustralianRetirement'],
      {
        is: isAustralianRetirementControllingPersonNFE,
        then: yup.object().shape({
          // is not NFE
          name: yup.string().required().label('Name'),
          dateOfBirth: yup
            .mixed()
            .test('date', 'Date of Birth is required', (dateOfBirth) => {
              if (dateOfBirth === null) return true;
              return dayjs(dateOfBirth).isValid();
            })
            .test(
              'future date validation',
              'Date of Birth cannot be future date',
              (dateOfBirth) => {
                if (dateOfBirth === null) return true;
                return dayjs(dateOfBirth).isBefore(dayjs());
              },
            )
            .test(
              'future date validation',
              'Date of Birth cannot be too old',
              (dateOfBirth) => {
                if (dateOfBirth === null) return true;
                return dayjs(dateOfBirth).isAfter(
                  dayjs().subtract(100, 'years'),
                );
              },
            ),
          address: yup.string().required().label('Address'),
          country: yup.string().required().label('Country'),
        }),
      },
    ),
  isNFE: yup
    .boolean()
    .when(['isManagedByAnotherInstitution', 'isAustralianRetirement'], {
      is: (isManagedByAnotherInstitution, isAustralianRetirement) =>
        isManagedByAnotherInstitution !== undefined &&
        isAustralianRetirement === false,
      then: yup.boolean().required().label('Non-Financial Entity (NFE)'),
    }),
  isNFEValues: yup.lazy((isNFEValues) => {
    const getNFEValueSchema = (withOtherReason = false) =>
      yup.object().shape({
        question: yup.string().required(),
        ...condTrueFalse(
          withOtherReason,
          { otherReason: yup.string().required().label('Other reason') },
          {},
        ),
      });
    if (isNFEValues?.question === '4') {
      return getNFEValueSchema(true);
    }
    return yup.object().when('isNFE', {
      is: true,
      then: getNFEValueSchema(),
    });
  }),
  isControllingPersonANaturalOrTrust: yup
    .boolean()
    .when(['isNFE', 'isAustralianRetirement'], {
      is: (isNFE, isAustralianRetirement) =>
        isNFE === false && isAustralianRetirement === false,
      then: yup
        .boolean()
        .required()
        .label('Controlling Person is Natural Person or Trust'),
    }),
  // other country tax number validations
  isTINAvailable: yup
    .boolean()
    .label('Other country TIN or equivalent')
    .when(
      ['isNFE', 'isControllingPersonANaturalOrTrust', 'isAustralianRetirement'],
      {
        is: isAustralianRetirementControllingPersonNFE,
        then: yup.boolean().required(),
      },
    ),
  otherCountryTIN: yup
    .string()
    .label('TIN (Tax Index Number*)')
    .when(['isNFE', 'isControllingPersonANaturalOrTrust', 'isTINAvailable'], {
      is: (isNFE, isControllingPersonANaturalOrTrust, isTINAvailable) =>
        isNFE === false &&
        !!isControllingPersonANaturalOrTrust &&
        !!isTINAvailable,
      then: yup.string().min(5).max(18).required(),
    }),
  noTINReason: yup
    .string()
    .label('Reason not providing your TIN')
    .when(
      [
        'isNFE',
        'isControllingPersonANaturalOrTrust',
        'isTINAvailable',
        'isAustralianRetirement',
      ],
      {
        is: (
          isNFE,
          isControllingPersonANaturalOrTrust,
          isTINAvailable,
          isAustralianRetirement,
        ) =>
          isNFE === false &&
          !!isControllingPersonANaturalOrTrust &&
          isTINAvailable === false &&
          isAustralianRetirement === false,
        then: yup.string().required(),
      },
    ),
  unableObtainTinReason: yup
    .string()
    .label('Reason not providing your TIN')
    .when(
      [
        'isControllingPersonANaturalOrTrust',
        'noTINReason',
        'isAustralianRetirement',
      ],
      {
        is: (
          isControllingPersonANaturalOrTrust,
          noTINReason,
          isAustralianRetirement,
        ) =>
          !!isControllingPersonANaturalOrTrust &&
          noTINReason === '2' &&
          isAustralianRetirement === false,
        then: yup.string().required(),
      },
    ),
});

export default companyTaxSupportingInformationValidationSchema;
