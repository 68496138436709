import React from 'react';
import Typography from '@mui/material/Typography';

import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';

import services from 'api/services';

import FundInformation from './FundInformation';
import FundDetails from './FundDetails';
import FinancialInformation from './FinancialInformation';
import SummaryChart from './SummaryChart';
import Dataroom from './Dataroom';

export const fetchAdminFundDetail = async (id) => {
  try {
    if (!id) return {};

    const res = await services.adminGetFundDetail(id);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const fundTabItems = (props = {}) => [
  {
    key: 'fundInformation',
    Icon: PersonIcon,
    label: <Typography variant="h6">Fund Information</Typography>,
    content: <FundInformation fieldKey="fundInformation" {...props} />,
  },
  {
    key: 'fundDetails',
    Icon: PersonIcon,
    label: <Typography variant="h6">Fund Details</Typography>,
    content: <FundDetails fieldKey="fundDetails" {...props} />,
  },
  {
    key: 'summaryChart',
    Icon: PersonIcon,
    label: <Typography variant="h6">Summary Chart</Typography>,
    content: <SummaryChart fieldKey="summaryChart" {...props} />,
  },
  {
    key: 'financialInformations',
    Icon: PersonIcon,
    label: <Typography variant="h6">Financial Information</Typography>,
    content: (
      <FinancialInformation fieldKey="financialInformations" {...props} />
    ),
  },
  {
    key: 'dataRoom',
    Icon: PersonIcon,
    label: <Typography variant="h6">Data Room</Typography>,
    content: <Dataroom fieldKey="dataRooms" {...props} />,
  },
];

export const sendAddFunds = async ({ payload }) => {
  try {
    const res = await services.adminAddFunds(payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const editFund = async ({ id, payload }) => {
  try {
    const res = await services.adminEditFund(id, payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};
