import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import TextButton from 'components/Button/TextButton';

import history from 'utils/history';

const handleRouteToLoginPage = () => {
  history.push('/login');
};

const AuthContainer = ({
  title,
  onBack = handleRouteToLoginPage,
  onNext,
  isNextButtonDisabled,
  children,
  nextLabel = 'Continue',
  backLabel = 'Back',
  currentStep = 0,
  totalStep = 0,
  childGridProps = {},
  linearColor = '#36356B',
  isLoggedIn,
  nextBtnContainerProps = {},
  nextBtnProps = {},
}) => (
  <Grid container rowSpacing={1}>
    <Grid
      container
      item
      xs={12}
      rowSpacing={1}
      justifyContent="space-between"
      flexWrap={{ xs: 'wrap-reverse', md: 'initial' }}
    >
      <Grid item>
        <Typography
          variant="h1"
          sx={{ fontWeight: 600 }}
          data-testid="container-title"
        >
          {title}
        </Typography>
      </Grid>
      {!isLoggedIn && (
        <Grid item sx={{ placeSelf: 'end' }}>
          <Typography variant="body2">
            Already have an account?{' '}
            <TextButton color="link" onClick={handleRouteToLoginPage}>
              Sign in
            </TextButton>
          </Typography>
        </Grid>
      )}
    </Grid>
    <Grid item xs={12}>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <LinearProgress
              variant="determinate"
              value={`${(100 * (currentStep + 1)) / totalStep}`}
              sx={{
                height: '8px',
                backgroundColor: '#BFBFBF',
                '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate':
                  {
                    backgroundColor: linearColor,
                  },
              }}
            />
          </Grid>
          <Grid container item xs={12} p={{ xs: 2, md: 5 }} rowSpacing={2}>
            <Grid
              item
              xs={12}
              paddingX={{ xs: 0, lg: 8 }}
              pt={{ xs: '38px !important', md: 0 }}
              mb={4}
              // textAlign="-webkit-center"
              {...childGridProps}
            >
              {children}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              columnSpacing={2}
              justifyContent="flex-end"
            >
              <Grid item textAlign="right">
                {onBack && (
                  <Button
                    data-testid="button-back"
                    textColor="bluish"
                    variant="text"
                    buttoncolor="transparent"
                    onClick={onBack}
                    sx={{ padding: '10px 34px' }}
                  >
                    <Typography variant="body">{backLabel}</Typography>
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={5}
                md="auto"
                textAlign="right"
                {...nextBtnContainerProps}
              >
                {onNext && (
                  <Button
                    buttoncolor="menuLink"
                    onClick={onNext}
                    disabled={isNextButtonDisabled}
                    size="small"
                    data-testid="button-next"
                    padding="12px 34px 12px 34px"
                    {...nextBtnProps}
                  >
                    <Typography variant="body" color="white">
                      {nextLabel}
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

export default AuthContainer;
