import personalDetailsValidationSchemas from './personalDetailsValidationSchema';
import addressValidationSchemas from './addressValidationSchema';
import investorIdentificationValidationSchemas from './investorIdentificationValidationSchema';
import accountantCertificateValidationSchema from './accountantCertificateValidationSchema';
import investorSummaryValidationSchema from './investorSummaryValidationSchema';

const individualPersonalDetailsValidationSchemas = [
  personalDetailsValidationSchemas,
  addressValidationSchemas,
  investorIdentificationValidationSchemas,
  accountantCertificateValidationSchema,
  // JointInvestorForm
  null,
  investorSummaryValidationSchema,
];

export default individualPersonalDetailsValidationSchemas;
