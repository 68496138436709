const generateQueryParams = (queryParams = {}) => {
  const queryParamKeys = Object.keys(queryParams);
  if (!queryParamKeys.length) return '';

  return queryParamKeys.reduce((queryParam, query) => {
    if (!query || !queryParams[query]) return queryParam;

    return `${queryParam}&${query}=${queryParams[query]}`;
  }, '');
};

export default generateQueryParams;
