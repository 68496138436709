import getNumbers from 'utils/strings/getNumbers';
import mapValue from 'utils/mapValue';
import { REPORTING_TEMPLATE_CLOSED_END } from 'constants/funds';

const setFundCardFilePayload = ({
  uploadFilePayload,
  fundHeaderImg,
  fundCardImage,
  fundLogo,
}) => {
  if (fundHeaderImg)
    uploadFilePayload.push({
      key: 'headerImage',
      ...fundHeaderImg,
    });
  if (fundCardImage)
    uploadFilePayload.push({
      key: 'cardImage',
      ...fundCardImage,
    });
  if (fundLogo)
    uploadFilePayload.push({
      key: 'logoImage',
      ...fundLogo,
    });

  return uploadFilePayload;
};

const mapFundInformationPayload = ({
  fundPercentage,
  endDate,
  periodStatus,
  type,
  ...fundInformation
}) => ({
  ...fundInformation,
  fundPercentage: Number(fundPercentage),
  endDate: !periodStatus ? endDate : '',
  fundType: type,
});

const mapFundCardPayload = ({ fundCards = [...Array(5)], ...fundCardValues }) =>
  fundCards.map((fundCard, index) => ({
    orderId: index + 1,
    ...fundCard,
    value: fundCardValues[`value${index + 1}`],
    heading: fundCardValues[`field${index + 1}`],
  }));

const mapFinancialInfoPayload = (formValues) => ({
  id: mapValue(formValues.id, undefined),
  className: formValues?.className,
  amountToRaise: getNumbers(formValues.amountToRaise),
  callable: Number(formValues.callable),
  currency: formValues.currency,
  unitPrice: getNumbers(formValues.unitPrice),
  accessFee: Number(formValues.accessFee),
  applicationFee: Number(formValues.applicationFee),
  minInvestment: getNumbers(formValues.minInvestment),
  maxInvestment: getNumbers(formValues.maxInvestment),
  classTermYears: formValues.classTermYears,
  classLockupDays: Number(formValues.classLockupDays),
  redemptionRequestTerms: formValues.redemptionRequestTerms,
  fundProgress: getNumbers(formValues.fundProgress),
});

const mapBankDetailPayload = (formValues) => ({
  id: mapValue(formValues?.bankAccountId, undefined),
  bankName: formValues.bankName || undefined,
  accountName: formValues.accountName || undefined,
  accountNumber: formValues.accountNumber,
  bsb: formValues.bsb,
  bankAddress: formValues.bankAddress,
  swiftCode: formValues.swiftCode,
  country: formValues.country,
  isShowBankDetails: formValues.isShowBankDetails,
});

const mapFundTabsOrderId = (fundTabs) =>
  fundTabs.map((fundTab, fundTabIndex) => {
    const fundContents = fundTab.fundContents.map(
      (fundContent, fundContentIndex) => ({
        ...fundContent,
        // blogTitle: fundContent.title,
        orderId: fundContentIndex + 1,
      }),
    );
    return {
      ...fundTab,
      fundContents,
      orderId: fundTabIndex + 1,
    };
  });

const mapDataRoomsPayload = (dataRooms) =>
  dataRooms.map((dataRoom) => ({
    ...dataRoom,
    documents: dataRoom.documents?.map((document) => ({
      id: mapValue(document.id, undefined),
      originalFileName: document.originalFileName || document.title,
      enableDownload: document.enableDownload,
      enableView: document.enableView,
      isHidden: document.isHidden,
      key: document.key,
      isDeleted: !!document.isDeleted,
    })),
    subFolders: dataRoom?.subFolders?.length
      ? mapDataRoomsPayload(dataRoom.subFolders)
      : undefined,
  }));

const addFundDetails = (formValues) => {
  const {
    fundInformation: fundInformationValues,
    financialInformations,
    deletedFinancialInformations,
    deletedFundDocuments,
    fundDetails,
    summaryChart,
    dataRooms,

    submitToDraft,

    createdBy,
    updatedBy,
    isCashFund,
  } = formValues;
  let fundInformationPayload;
  let fundCardPayload;
  let fundTabsPayload;
  const financialClasses = [];
  let dataRoomsPayload;
  let summaryChartPayload;

  // Set Upload File Payloads.
  const uploadFile = [];

  if (fundInformationValues) {
    const { fundCard, fundInformation } = fundInformationValues;

    setFundCardFilePayload({ uploadFilePayload: uploadFile, ...fundCard });

    // Set Fund Information Payloads.
    fundInformationPayload = mapFundInformationPayload({
      ...fundInformation,
      createdBy,
      updatedBy,

      // If fund type is Cash Fund, send overview value.
      overview: isCashFund ? fundInformation.overview : undefined,
    });

    // Set Fund Card Payloads.
    fundCardPayload = mapFundCardPayload(fundCard);
  }

  if (financialInformations?.length) {
    // Upload Sign Document
    financialInformations.forEach((financialInformation, index) => {
      financialClasses.push({
        financialInformation: mapFinancialInfoPayload(financialInformation),
        bankDetail: mapBankDetailPayload(financialInformation),
      });

      if (financialInformation.signDocument?.length) {
        financialInformation.signDocument.forEach((signDocument) => {
          if (!signDocument.file) return;

          const fileNameWithId = `${signDocument.file.name}@@SIGN_DOCUMENT_INDEX=${index}`;

          uploadFile.push({
            key: 'fundDocument',
            ...signDocument,
            fileNameWithId,
          });
        });
      }
    });
  }

  if (fundDetails) {
    fundTabsPayload = mapFundTabsOrderId(fundDetails);
  }
  if (dataRooms) {
    dataRoomsPayload = mapDataRoomsPayload(dataRooms);
  }

  if (summaryChart) {
    summaryChartPayload = summaryChart;
  }

  const payload = {
    id: formValues.fundId || undefined,

    submitToDraft,

    isHidden: !!formValues.isHidden,
    isOpen: !!formValues.isOpen,
    isRedemptionVisible: !!formValues.isRedemptionVisible,
    reportingTemplate:
      formValues.reportingTemplate || REPORTING_TEMPLATE_CLOSED_END,

    fundInformation: fundInformationPayload,
    fundCard: fundCardPayload,
    fundTabs: fundTabsPayload,
    summaryCharts: summaryChartPayload,

    financialClasses,
    deletedFinancialInformations,
    deletedFundDocuments,

    dataRooms: dataRoomsPayload,
  };

  const formData = new FormData();
  formData.append('content', JSON.stringify(payload));

  if (uploadFile.length) {
    uploadFile.forEach(
      (file) =>
        file.file && formData.append(file.key, file.file, file.fileNameWithId),
    );
  }

  return formData;
};

export default {
  addFundDetails,
};
