import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';

import { ReactComponent as VisibilityIcon } from 'assets/icons/visibility.svg';

import Button from 'components/Button';
import TextButton from 'components/Button/TextButton';
import ShowMe from 'components/ShowMe';

const Footer = ({
  onDownloadClick,
  downloadBtnDisabled,
  onCancel,
  onDelete,
  deleteBtnDisabled,
  onRevertDraft,
  onSubmitClick,
  submitBtnDisabled,
  submitBtnLabel = 'Save',
  onPreviewClick,
  previewBtnDisabled,
  onSaveDraft,
  disableSaveDraftBtn,
  rightActions = ['download', 'submit', 'preview', 'saveDraft'],
}) => {
  const rightActionBtns = useMemo(
    () =>
      rightActions.reduce((actions, action) => {
        switch (action) {
          case 'download':
            return actions.concat(
              <ShowMe when={onDownloadClick}>
                <Grid item xs={6} sm={3} justifyContent="center">
                  <Button
                    data-testid="button-download"
                    sx={{ padding: '10px' }}
                    startIcon={
                      <FileDownloadOutlined
                        sx={{ display: { xs: 'none', md: 'initial' } }}
                      />
                    }
                    onClick={onDownloadClick}
                    disabled={downloadBtnDisabled}
                  >
                    Download
                  </Button>
                </Grid>
              </ShowMe>,
            );
          case 'submit':
            return actions.concat(
              <ShowMe when={onSubmitClick}>
                <Grid item xs={6} sm={3}>
                  <Button
                    data-testid="button-submit"
                    sx={{ padding: '10px' }}
                    onClick={onSubmitClick}
                    disabled={submitBtnDisabled}
                  >
                    {submitBtnLabel}
                  </Button>
                </Grid>
              </ShowMe>,
            );
          case 'preview':
            return actions.concat(
              <ShowMe when={onPreviewClick}>
                <Grid item xs={6} sm={3} justifyContent="center">
                  <Button
                    data-testid="button-preview"
                    sx={{ padding: '10px' }}
                    startIcon={
                      <VisibilityIcon
                        sx={{ display: { xs: 'none', md: 'initial' } }}
                      />
                    }
                    onClick={onPreviewClick}
                    buttoncolor="dark"
                    disabled={previewBtnDisabled}
                  >
                    Preview
                  </Button>
                </Grid>
              </ShowMe>,
            );
          case 'saveDraft':
            if (!onSaveDraft) return actions;

            return actions.concat(
              <ShowMe when={onSaveDraft}>
                <Grid item xs={6} sm={3} justifyContent="center">
                  <Button
                    data-testid="button-save-draft"
                    sx={{ padding: '10px' }}
                    onClick={onSaveDraft}
                    disabled={disableSaveDraftBtn}
                  >
                    Save
                  </Button>
                </Grid>
              </ShowMe>,
            );
          default:
            return actions;
        }
      }, []),
    [
      rightActions,
      onPreviewClick,
      onSubmitClick,
      onDownloadClick,
      submitBtnDisabled,
      onSaveDraft,
    ],
  );

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item xs={12} sm={6}>
        <ShowMe when={!!onCancel}>
          <Grid item xs={6} sm={2}>
            <TextButton
              sx={{
                maxHeight: '19px',
                height: '100%',
              }}
              variant="bodyGrey"
              onClick={onCancel}
            >
              Cancel
            </TextButton>
          </Grid>
        </ShowMe>
        {onDelete && (
          <Grid item xs={6} sm={2}>
            <TextButton
              sx={{
                maxHeight: '19px',
                height: '100%',
              }}
              variant="bodyGrey"
              onClick={onDelete}
              disabled={deleteBtnDisabled}
            >
              Delete
            </TextButton>
          </Grid>
        )}
        {onRevertDraft && (
          <Grid item xs={6} sm={4}>
            <TextButton
              sx={{
                maxHeight: '19px',
                height: '100%',
              }}
              variant="bodyGrey"
              onClick={onRevertDraft}
            >
              Revert Changes
            </TextButton>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid
          item
          container
          justifyContent="flex-end"
          columnSpacing={{ xs: 1, md: 3 }}
          alignItems="center"
        >
          {rightActionBtns.map((rightAction) => rightAction)}
        </Grid>
      </Grid>
    </>
  );
};
export default Footer;
