import Services from 'api/services';

export const fetchAdminImageSliders = async () => {
  const res = await Services.adminGetPlatformConfigurationImageSlider();

  return res?.data;
};

export const updateAdminImageSliders = async (images) => {
  const formData = new FormData();

  const payload = images.reduce((imagePayload, image, index) => {
    if (image.file) {
      formData.append('images', image.file);
    }

    return imagePayload.concat({
      id: image.id || null,
      fileName: image.fileName,
      content: image.content,
      orderId: index + 1,
      isDeleted: image.isDeleted,
      key: image.key,
    });
  }, []);

  formData.append('content', JSON.stringify(payload));

  const res = await Services.adminUpdatePlatformConfigurationImageSlider(
    formData,
  );

  return res?.data;
};
