import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

import showFieldWhen from 'utils/showFieldWhen';
import condTrueFalse from 'utils/condTrueFalse';
import getNestedObject from 'utils/getNestedObject';

const MultipleChoiceQuestion = ({
  label,
  name,
  value = null,
  onChange,
  options = [],
  errorValue,
  childrenProps = {},
  questionListContainerProps,
}) => {
  /*
     Options is array of object which has keys of label and value
     e.g: {label: "label 1", value: "value 1"}
  */
  const handleChange = useCallback(
    (e) => {
      if (e.target.checked) {
        const val = {
          target: {
            name,
            value: e.target.name,
          },
        };
        onChange(val);
      }
    },
    [value],
  );

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>{label}</Grid>
      <Grid item container direction="row" textAlign="left">
        {options.map(
          ({
            label: optionsLabel,
            value: optionsValue,
            key,
            children,
            showChildWhen,
          }) => (
            <Grid
              item
              container
              xs={12}
              key={key}
              {...questionListContainerProps}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value === optionsValue}
                      name={optionsValue}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography variant="small">{optionsLabel}</Typography>
                  }
                />
              </Grid>
              {children &&
                showFieldWhen({
                  showWhen: showChildWhen,
                  values: childrenProps?.values,
                }) && (
                  <Grid item xs={12} pl={3}>
                    {React.Children.map(children, (child) =>
                      React.isValidElement(child)
                        ? React.cloneElement(child, {
                            ...childrenProps,
                            value: condTrueFalse(
                              childrenProps?.values,
                              getNestedObject(
                                childrenProps?.values,
                                child.props?.name,
                              ),
                              child?.props?.value,
                            ),
                          })
                        : child,
                    )}
                  </Grid>
                )}
            </Grid>
          ),
        )}
        <FormHelperText error={!!errorValue}>{errorValue}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default memo(MultipleChoiceQuestion);
