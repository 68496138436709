import React, { useCallback, useState } from 'react';
import { ChromePicker } from 'react-color';

import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';

import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import { styled } from '@mui/material/styles';

import Button from 'components/Button';

import { getInputStyles } from 'constants/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '8px',
  ...getInputStyles({ theme }),
}));

const StyledColorInput = styled('div')(({ backgroundcolor }) => ({
  height: '100%',
  backgroundColor: backgroundcolor,
  borderRadius: '10px',
}));

const ColorPicker = ({ name, value, onChange, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColor, setSelectedColor] = useState(value);

  const handleChange = useCallback(
    (e) => {
      setSelectedColor(e.hex);
    },
    [onChange],
  );

  const handleFocus = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);

    const event = {
      target: {
        name,
        value: selectedColor,
      },
    };
    onChange(event);
  }, [selectedColor, onChange]);

  return (
    <>
      <StyledButton
        text={false}
        onClick={handleFocus}
        variant="outlined"
        buttoncolor="transparent"
        disabled={disabled}
      >
        <Grid container>
          <Grid item xs={8}>
            <StyledColorInput backgroundcolor={selectedColor} />
          </Grid>
          <Grid item xs>
            <ColorLensOutlinedIcon color="black" />
          </Grid>
        </Grid>
      </StyledButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ChromePicker color={selectedColor} onChange={handleChange} />
      </Popover>
    </>
  );
};

export default ColorPicker;
