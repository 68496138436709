import React from 'react';

import config from 'utils/config';

const CountryFlagStyles = {
  width: '22px',
  height: '15.71px',
  borderRadius: '4px',
};

const CountryFlag = ({ fileName }) => (
  <img
    loading="lazy"
    width="20"
    src={`${config.public.countryFlags}/${fileName}.png`}
    srcSet={`${config.public.countryFlags}/${fileName}.png 2x`}
    alt="country-flag"
    style={CountryFlagStyles}
  />
);

export default CountryFlag;
