import React, { memo, useCallback } from 'react';

import MUIChip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import condTrueFalse from 'utils/condTrueFalse';

const Chip = ({
  label,
  lowercase = true,
  textTransform = 'capitalize',
  onDelete,
  value,
  height,
  sx = {},
  ...props
}) => {
  const handleDelete = useCallback((e) => {
    onDelete(e, value);
  }, []);

  return (
    <MUIChip
      label={
        <Typography variant="chipLabel" mr={onDelete && 0.5}>
          {condTrueFalse(lowercase && label, label?.toLowerCase?.(), label)}
        </Typography>
      }
      deleteIcon={<CloseIcon onMouseDown={(e) => e.stopPropagation()} />}
      onDelete={onDelete && handleDelete}
      sx={{
        textTransform,
        height: height || { xs: '22px', lg: '28px' },
        '& .MuiChip-deleteIcon': {
          fontSize: '16px',
          color: 'white',
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default memo(Chip);
