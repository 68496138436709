import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import Dropdown from 'components/Dropdown';
import YesNoQuestion from 'components/YesNoQuestion';

import condTrueFalse from 'utils/condTrueFalse';

import { COUNTRY_OPTIONS } from 'constants/investmentEntity';
import TaxContentContainer from './TaxContentContainer';

const OtherCountryTaxPurpose = ({
  values,
  onChange,
  errors,
  touched,
  onBlur,
}) => (
  <TaxContentContainer container>
    <Grid item xs={12}>
      <YesNoQuestion
        name="hasOtherCountryTax"
        label="Are you a resident of any other country for tax purpose?"
        value={values?.hasOtherCountryTax}
        onChange={onChange}
        onBlur={onBlur}
        errorValue={touched?.hasOtherCountryTax && errors.hasOtherCountryTax}
      />
    </Grid>
    <Grid item xs={8}>
      {!!values?.hasOtherCountryTax && (
        <Grid container direction="row">
          <Grid item xs={12}>
            <FormLabel>Your country for tax purposes</FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Dropdown
                autoWidth
                value={values?.otherCountryTax}
                name="otherCountryTax"
                onChange={onChange}
                isBoldOptionLabel
                options={condTrueFalse(
                  values?.hasAustraliaTax,
                  COUNTRY_OPTIONS.filter((cOption) => cOption?.value !== 'AU'),
                  COUNTRY_OPTIONS,
                )}
                onBlur={onBlur}
                errorValue={touched?.otherCountryTax && errors.otherCountryTax}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  </TaxContentContainer>
);

export default OtherCountryTaxPurpose;
