import * as yup from 'yup';

const documentSigningValidationSchema = yup.object({
  isConfirmed: yup
    .bool()
    .required('Please check the declaration.')
    .isTrue('Please check the declaration .')
    .label('Declaration'),
});

export default documentSigningValidationSchema;
