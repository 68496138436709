import React from 'react';
import {
  Routes,
  Route,
  // https://github.com/remix-run/react-router/issues/8264#issuecomment-991271554
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';

import useSegmentAnalytic from 'hooks/useSegmentAnalytic';
import history from '../utils/history';
import RoutesList from './RoutesList';

const Router = () => {
  useSegmentAnalytic({ withSegment: true });

  return (
    <HistoryRouter history={history}>
      <Routes>
        <>
          {RoutesList.map((route) => (
            <Route key={route.id} {...route} />
          ))}
        </>
      </Routes>
    </HistoryRouter>
  );
};
export default Router;
