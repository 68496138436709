import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';

import Button from 'components/Button';
import Loading from 'components/Loading';
import MFADialog from 'components/Dialog/MFADialog';
import PasswordInput from 'components/Input/PasswordInput';
import SidebarContainer from 'components/SidebarContainer';
import Services from 'api/services';
import TextField from 'components/Input';
import TextButton from 'components/Button/TextButton';
import { useActions, useStores } from 'components/StateProvider';

import condTrueFalse from 'utils/condTrueFalse';
import history from 'utils/history';
import mapValue from 'utils/mapValue';
import useRecaptcha from 'hooks/useRecaptcha';
import { ERROR_CODES, ERROR_MESSAGES } from 'constants/errors';
import { ROLE_USER } from 'constants/userManagement';

import loginValidationSchema from './loginValidationSchema';
import ResendVerificationEmail from './components/ResendVerificationEmail';

const loginPageHelmetTitle =
  'Reach Alternative Investments | Login to Invest In Private Equity';
const loginPageMetaDescription =
  "Login to your Reach Alternative Investments' portal to access investment opportunities in global private market funds from the world's leading private equity asset managers";

const LoginPage = () => {
  const {
    handleLogin,
    handleGetUserAccessibility,
    handleGetLatestCurrencyData,
  } = useActions();
  const {
    auth: { isAuthenticated, user },
  } = useStores();
  const [loginError, setLoginError] = useState('');
  const [emailVerificationPayload, setEmailVerificationPayload] =
    useState(null);
  const [openMFADialog, setOpenMFADialog] = useState(false);
  const [MFAError, setMFAError] = useState('');
  const [loadingMFA, setLoadingMFA] = useState(false);
  const [captcha, setCaptcha] = useState('');

  const handleChangeCaptcha = useCallback(
    (newValue) => setCaptcha(newValue),
    [],
  );

  const { RecaptchaComponent } = useRecaptcha({
    onChange: handleChangeCaptcha,
    loadRecaptcha: process.env.REACT_APP_ENV !== 'dev',
  });

  const handleSubmitLogin = async (formValues) => {
    setEmailVerificationPayload(null);
    try {
      await handleLogin({ ...formValues, recaptchaToken: captcha });
    } catch (err) {
      if (err.message === ERROR_CODES.LOGIN.NEED_VERIFICATION_CODE) {
        setOpenMFADialog(true);

        return;
      }

      const errorCode = err.response?.data?.code;

      setLoginError(ERROR_MESSAGES[errorCode]);
      setMFAError(ERROR_MESSAGES[errorCode]);

      if (errorCode === ERROR_CODES.LOGIN.ACCOUNT_NOT_VERIFIED) {
        setEmailVerificationPayload(err.response.data.data);
      }

      window.grecaptcha?.reset?.();
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmitLogin,
  });

  const onClickRegister = () => {
    history.push('/register');
  };
  const handleRedirectForgotPassword = () => {
    history.push('/forgot-password');
  };

  const handleSendVerification = useCallback(async () => {
    setMFAError('');
    await Services.resendEmailVerification(emailVerificationPayload);
  }, [emailVerificationPayload]);

  const handleSubmitMFA = useCallback(
    async (otp) => {
      try {
        setLoadingMFA(true);

        const payload = {
          verificationCode: otp,
          userId: user?.id,
        };
        await handleLogin(payload);
      } catch (err) {
        const errorCode = err.response?.data?.code;

        setMFAError(ERROR_MESSAGES[errorCode]);
      } finally {
        setLoadingMFA(false);
      }
    },
    [user?.id],
  );

  const handleResendMFA = useCallback(() => {
    setMFAError('');
    formik.handleSubmit();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role === ROLE_USER) {
        handleGetUserAccessibility();
        handleGetLatestCurrencyData();
      }

      history.replace(mapValue(history?.location?.state?.href, '/'));
    }
  }, [isAuthenticated, user]);

  const handleCloseMFADialog = useCallback(() => {
    setMFAError('');
    setOpenMFADialog(false);
  }, []);

  const isTrialLimit = useMemo(
    () => MFAError === ERROR_MESSAGES['error.login.reach.trial.limit'],
    [MFAError],
  );

  return (
    <>
      <Helmet>
        <title>{loginPageHelmetTitle}</title>
        <meta name="description" content={loginPageMetaDescription} />
      </Helmet>

      <SidebarContainer withCompanyLogo position="relative">
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="row"
            rowSpacing={3}
            alignItems="center"
            justifyContent="center"
            padding={1.5}
          >
            <Grid container item xs={12} sm={6} md={4} rowSpacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Sign In
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="left">
                <FormLabel>Email*</FormLabel>
                <TextField
                  fullWidth
                  data-testid="email-input"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorValue={formik.errors.email}
                  value={formik.values.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="left">
                <FormLabel>Password*</FormLabel>
                <PasswordInput
                  fullWidth
                  data-testid="password-input"
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  errorValue={formik.errors.password}
                  defaultMargin={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TextSnippetOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <RecaptchaComponent />
              </Grid>
              {(loginError || emailVerificationPayload) && (
                <Grid item xs={12}>
                  {loginError && (
                    <Typography
                      data-testid="login-error-text"
                      variant="bodyGrey"
                      color="error"
                    >
                      {loginError}
                    </Typography>
                  )}
                  {emailVerificationPayload && (
                    <ResendVerificationEmail
                      onSendVerification={handleSendVerification}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12} textAlign={{ xs: 'center', md: 'initial' }}>
                <TextButton
                  color="link"
                  data-testid="forgot-password-btn"
                  onClick={handleRedirectForgotPassword}
                >
                  Forgot password ?
                </TextButton>
              </Grid>
              <Grid item xs={12} textAlign={{ xs: 'center', md: 'initial' }}>
                <Typography variant="bodyGrey">
                  Don&apos;t have an account yet?{' '}
                </Typography>
                <TextButton
                  color="link"
                  data-testid="register-btn"
                  onClick={onClickRegister}
                >
                  Sign up here
                </TextButton>
              </Grid>
            </Grid>
            {/* login button */}
            <Grid item xs={12} mt={{ xs: 0, md: 10 }}>
              <Divider />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item>
                <Button
                  buttoncolor="purpleHazeGradient"
                  data-testid="login-btn"
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    (process.env.REACT_APP_ENV !== 'dev' && !captcha)
                  }
                >
                  Login to Dashboard
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>

        <MFADialog
          open={openMFADialog}
          onConfirm={condTrueFalse(
            isTrialLimit,
            handleCloseMFADialog,
            handleSubmitMFA,
            { executeFn: false },
          )}
          onClose={handleCloseMFADialog}
          errorText={MFAError}
          onClickResendMFA={condTrueFalse(
            MFAError === ERROR_MESSAGES['error.login.token.expired'],
            handleResendMFA,
            undefined,
            { executeFn: false },
          )}
          dialogContentDependencies={[MFAError]}
          submitLabel={condTrueFalse(isTrialLimit, 'OK', 'Continue')}
          setErrorText={setMFAError}
          initialTimer={condTrueFalse(
            MFAError === ERROR_MESSAGES['error.login.token.expired'],
            1,
            undefined,
          )}
          submitBtnDisabled={MFAError && !isTrialLimit}
        />

        <Loading popup loading={formik.isSubmitting || loadingMFA} />
      </SidebarContainer>
    </>
  );
};

export default LoginPage;
