import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from 'components/Chip';
import FieldContainer from 'components/FieldContainer';
import {
  FUND_ENTITY_STATUS,
  FUND_REQUEST_DETAILS_FIELDS,
} from 'constants/investmentEntity';

const chipColorMapping = (status) => {
  switch (status) {
    case FUND_ENTITY_STATUS.STATUSES.PAYMENT_COMPLETED:
      return 'approvedChipColor';
    case FUND_ENTITY_STATUS.STATUSES.PAYMENT_CANCELLED:
      return 'cancelledChipColor';
    default:
      return 'pendingChipColor';
  }
};

const InvestmentRequestDetails = ({ values }) => (
  <Grid container spacing={3}>
    {FUND_REQUEST_DETAILS_FIELDS.map((item) => (
      <Grid item xs={6} sm={4} md={4} key={`${item.label}-${item.name}`}>
        <FieldContainer label={item.label}>
          {item.name === 'paymentStatus' ? (
            <Chip
              label={FUND_ENTITY_STATUS[values?.paymentStatus]?.label}
              color={chipColorMapping(values?.[item.name])}
            />
          ) : (
            <Typography variant="display5">{values?.[item.name]}</Typography>
          )}
        </FieldContainer>
      </Grid>
    ))}
  </Grid>
);
export default InvestmentRequestDetails;
