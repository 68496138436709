import React, { memo, useState, useMemo, useRef, useCallback } from 'react';

import Typography from '@mui/material/Typography';

import SummarySection from 'components/SummarySection';
import InputField from 'components/Input';
import InvestorCard from 'components/InvestorCard';
import {
  SUMMARY_FIELDS_COMPANY_TAX_DETAILS,
  COUNTRY_OPTIONS,
} from 'constants/investmentEntity';

import companyTaxValidationSchema from 'validations/schema/addInvestment/company/companyTaxValidationSchema';
import condTrueFalse from 'utils/condTrueFalse';
import getDropdownValue from 'utils/getDropdownValue';
import filterCountryOptions from 'utils/strings/filterCountryOptions';

import TaxDetailsFooter from '../TaxList/TaxDetailsFooter';

const taxPurposeCountryFilter = ({
  summaryFields,
  taxPurposes,
  currentTaxPurpose,
  disableHasAustraliaTax,
}) => {
  const filteredTaxPurposes = taxPurposes.filter(
    (_taxPurpose) => _taxPurpose.index !== currentTaxPurpose?.index,
  ); // remove current selected value from filtering

  const newSummaryFields = [...summaryFields];

  // Modify Other Country Tax.
  const otherCountryTax = newSummaryFields.find(
    ({ name }) => name === 'otherCountryTax',
  );

  const newCountryTax = {
    ...otherCountryTax,
    options: filterCountryOptions({ taxPurposes: filteredTaxPurposes }),
  };

  Object.assign(otherCountryTax, newCountryTax); // array.prototype.find return by reference since its value is object so we can use this to replace it directly.

  // Modify `hasAustraliaTax`.
  const hasAustraliaTaxField = newSummaryFields.find(
    ({ name }) => name === 'hasAustraliaTax',
  );

  hasAustraliaTaxField.disabled = disableHasAustraliaTax;

  return newSummaryFields;
};

const TaxDetailsSummary = ({
  onChange,
  values,
  setValues,
  taxPurpose,
  taxIndex,
  onDelete,
  hasAustraliaTax,
  withEdit = true,
  additionalChipText,
}) => {
  const summarySectionRef = useRef();
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState(false);

  const TaxDetailSummaryFields = useMemo(
    () => [
      ...taxPurposeCountryFilter({
        summaryFields: SUMMARY_FIELDS_COMPANY_TAX_DETAILS,
        taxPurposes: values?.taxPurposes,
        currentTaxPurpose: taxPurpose,
        disableHasAustraliaTax: hasAustraliaTax && !taxPurpose.hasAustraliaTax,
      }),
      {
        fieldComponent: 'multipleChoiceQuestion',
        fieldLabel: 'Reason not providing your TIN',
        name: 'noTINReason',
        showWhen: [
          { key: 'hasAustraliaTax', value: false },
          { key: 'isTINAvailable', value: false },
        ],
        fastField: false,
        options: [
          {
            label:
              'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
            key: 'answer-no-1',
            value: '1',
            displayValue:
              'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
          },
          {
            label:
              'The entity is otherwise unable to obtain a TIN or equivalent number.',
            value: '2',
            key: 'answer-no-2',
            children: (
              <InputField
                fullWidth
                name="unableObtainTinReason"
                onChange={onChange}
                value={taxPurpose?.unableObtainTinReason}
                placeholder="Please state why"
                onBlur={summarySectionRef?.current?.getHandleBlur()}
                errorValue={
                  summarySectionRef?.current?.getTouchedFields()
                    ?.unableObtainTinReason &&
                  summarySectionRef?.current?.getFormErrors()
                    ?.unableObtainTinReason
                }
              />
            ),
            showChildWhen: [{ key: 'noTINReason', value: '2' }],
            displayValue: taxPurpose?.unableObtainTinReason,
          },
          {
            label: 'No TIN is required',
            value: '3',
            key: 'answer-no-3',
            displayValue: 'No TIN is required',
            children: (
              <Typography variant="caption">
                Note: Only select this reason if the domestic law of the
                relevant jurisdiction does not require the collection of the TIN
                issued by such jurisdiction.
              </Typography>
            ),
          },
        ],
      },
    ],
    [taxPurpose, summarySectionRef?.current, onChange, values?.taxPurposes],
  );

  const chipText = useMemo(
    () =>
      taxPurpose?.hasAustraliaTax
        ? 'Australia'
        : getDropdownValue(taxPurpose?.otherCountryTax, COUNTRY_OPTIONS) ||
          'Please select a country',
    [COUNTRY_OPTIONS, taxPurpose],
  );

  const handleDeleteTaxPurpose = useCallback(() => {
    if (onDelete) {
      onDelete(taxIndex);
      return;
    }

    setValues({
      ...values,
      taxPurposes: values?.taxPurposes?.filter((t) => t?.index !== taxIndex),
      index: 'delete', // used in admin edit
    });
  }, [values, setValues, taxIndex]);

  const handleIsEditChanged = useCallback((newIsEdit) => {
    setIsEdit(newIsEdit);
  }, []);

  const handleEdit = useCallback(() => {
    summarySectionRef?.current?.handleClickEdit();
  }, [summarySectionRef?.current?.handleClickEdit]);

  const handleSave = useCallback(() => {
    summarySectionRef?.current?.handleClickSave({
      setIsEditParent: setIsEdit,
    });
  }, [summarySectionRef?.current, setIsEdit, values, taxIndex]);

  const handleCancel = useCallback(() => {
    setIsEdit(false);
    summarySectionRef?.current?.handleClickDiscard({
      setIsEditParent: setIsEdit,
    });
  }, [summarySectionRef?.current, setIsEdit, taxIndex]);

  const handleSetValues = useCallback(
    (formValues) => {
      setValues({
        ...values,
        taxPurposes: values?.taxPurposes?.map((t) => ({
          ...t,
          ...condTrueFalse(t?.index === taxIndex, formValues, {}),
        })),
        index: taxIndex, // used in admin edit
      });
    },
    [setValues, values, taxIndex],
  );

  const isMainTax = useMemo(() => taxIndex === 0, [taxIndex]);

  return (
    <InvestorCard
      investorName={taxPurpose?.investorName}
      additionalChipColor={isMainTax && 'individual'}
      additionalChipText={isMainTax && additionalChipText}
      chipText={chipText}
      isJoint={!isMainTax}
      onDelete={withEdit && !isMainTax && handleDeleteTaxPurpose}
      showFooterWhenExpand
      footer={
        withEdit && (
          <TaxDetailsFooter
            isEditing={isEdit}
            isMainTax={isMainTax}
            onEdit={handleEdit}
            onSave={handleSave}
            onCancel={handleCancel}
            isError={isError}
          />
        )
      }
      accordion
    >
      <SummarySection
        title={null}
        withEdit={false}
        fields={TaxDetailSummaryFields}
        setValues={handleSetValues}
        values={taxPurpose}
        validationSchema={companyTaxValidationSchema}
        ref={summarySectionRef}
        setIsError={setIsError}
        onIsEditChanged={handleIsEditChanged}
      />
    </InvestorCard>
  );
};

export default memo(TaxDetailsSummary);
