import React from 'react';

import { ReactComponent as FundTeaserIcon } from 'assets/icons/fund-teaser.svg';
import { ReactComponent as PerformanceIcon } from 'assets/icons/performance.svg';
import { ReactComponent as AboutIcon } from 'assets/icons/about.svg';
import { ReactComponent as FeesImpactIcon } from 'assets/icons/fees-impact.svg';
import { ReactComponent as KeyTermsIcon } from 'assets/icons/key-terms.svg';
import { ReactComponent as LegalInformationIcon } from 'assets/icons/legal-information.svg';
import { ReactComponent as DataRoomIcon } from 'assets/icons/data-room.svg';

const fundPageIcons = {
  fundTeaser: FundTeaserIcon,
  performance: PerformanceIcon,
  about: AboutIcon,
  feesImpact: FeesImpactIcon,
  keyTerms: KeyTermsIcon,
  legalInformation: LegalInformationIcon,
  dataRoom: DataRoomIcon,
};

const RenderIcon = ({ IconComponent, ...props }) => (
  <IconComponent {...props} />
);

const renderDataroomOptions = ({ iconProps } = {}) =>
  Object.keys(fundPageIcons).map((key) => ({
    label: <RenderIcon IconComponent={fundPageIcons[key]} {...iconProps} />,
    value: key,
  }));

export { renderDataroomOptions, fundPageIcons };
