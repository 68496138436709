import { REPORTING_TEMPLATE_CLOSED_END } from 'constants/funds';
import mapValue from 'utils/mapValue';
import getNumbers from 'utils/strings/getNumbers';

const mapClosedEndFundPerformance = (formValues) => ({
  irrGross: mapValue(
    getNumbers(formValues.irrGross, { allowMinus: true }),
    formValues.irrGross,
  ),
  irrNet: mapValue(
    getNumbers(formValues.irrNet, { allowMinus: true }),
    formValues.irrNet,
  ),
  moicNet: mapValue(
    getNumbers(formValues.moicNet, { allowMinus: true }),
    formValues.moicNet,
  ),
  tvpi: mapValue(
    getNumbers(formValues.tvpi, { allowMinus: true }),
    formValues.tvpi,
  ),
  dpi: mapValue(
    getNumbers(formValues.dpi, { allowMinus: true }),
    formValues.dpi,
  ),
  rvpi: mapValue(
    getNumbers(formValues.rvpi, { allowMinus: true }),
    formValues.rvpi,
  ),
  pme: mapValue(
    getNumbers(formValues.pme, { allowMinus: true }),
    formValues.pme,
  ),
});

const mapOpenEndFundPerformance = (formValues) => ({
  returnGross_3Month: mapValue(
    getNumbers(formValues.returnGross_3Month, { allowMinus: true }),
    formValues.returnGross_3Month,
  ),
  returnNet_3Month: mapValue(
    getNumbers(formValues.returnNet_3Month, { allowMinus: true }),
    formValues.returnNet_3Month,
  ),
  returnGrossYtd: mapValue(
    getNumbers(formValues.returnGrossYtd, { allowMinus: true }),
    formValues.returnGrossYtd,
  ),
  returnNetYtd: mapValue(
    getNumbers(formValues.returnNetYtd, { allowMinus: true }),
    formValues.returnNetYtd,
  ),
  returnGross_1Year: mapValue(
    getNumbers(formValues.returnGross_1Year, { allowMinus: true }),
    formValues.returnGross_1Year,
  ),
  returnNet_1Year: mapValue(
    getNumbers(formValues.returnNet_1Year, { allowMinus: true }),
    formValues.returnNet_1Year,
  ),
  returnGross_3Year: mapValue(
    getNumbers(formValues.returnGross_3Year, { allowMinus: true }),
    formValues.returnGross_3Year,
  ),
  returnNet_3Year: mapValue(
    getNumbers(formValues.returnNet_3Year, { allowMinus: true }),
    formValues.returnNet_3Year,
  ),
  returnGross_7Year: mapValue(
    getNumbers(formValues.returnGross_7Year, { allowMinus: true }),
    formValues.returnGross_7Year,
  ),
  returnNet_7Year: mapValue(
    getNumbers(formValues.returnNet_7Year, { allowMinus: true }),
    formValues.returnNet_7Year,
  ),
  returnGross_10Year: mapValue(
    getNumbers(formValues.returnGross_10Year, { allowMinus: true }),
    formValues.returnGross_10Year,
  ),
  returnNet_10Year: mapValue(
    getNumbers(formValues.returnNet_10Year, { allowMinus: true }),
    formValues.returnNet_10Year,
  ),
  returnGrossSinceInception: mapValue(
    getNumbers(formValues.returnGrossSinceInception, { allowMinus: true }),
    formValues.returnGrossSinceInception,
  ),
  returnNetSinceInception: mapValue(
    getNumbers(formValues.returnNetSinceInception, { allowMinus: true }),
    formValues.returnNetSinceInception,
  ),
});

const mapFundPerformance = (formValues) => ({
  id: formValues.id,
  fundConfigurationId: formValues.fundConfigurationId,
  date: formValues.date,

  ...(formValues.reportingTemplate === REPORTING_TEMPLATE_CLOSED_END
    ? mapClosedEndFundPerformance(formValues)
    : mapOpenEndFundPerformance(formValues)),
});

const addFundPerformance = (formValues) => {
  const payload = mapFundPerformance(formValues);

  return payload;
};

export default {
  addFundPerformance,
};
