import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import CardCarousel from 'components/CardCarousel';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';

import FeaturedCard from './FeaturedCard';
import EmptyContainer from '../../EmptyContainer';
import { fetchFeaturedArticles } from '../../ArticleDetail/HelperFunction';

const routeToArticleDetail = (articleId) =>
  history.push(`/articles/${articleId}`);

const FeaturedForMembers = () => {
  const getFeaturedArticles = useMemo(() => fetchFeaturedArticles(), []);
  const { apiData: featuredArticles } = useFetchApi({
    url: getFeaturedArticles,
    defaultApiData: [],
  });

  const featuredContents = useMemo(
    () =>
      featuredArticles?.map?.((featuredArticle) => (
        <FeaturedCard
          category={featuredArticle.category}
          title={featuredArticle.title}
          headerImageSrc={
            featuredArticle.articleLogoImgUrl ||
            featuredArticle.articleHeaderImgUrl
          }
          publishedDate={featuredArticle.date}
          onClick={() => routeToArticleDetail(featuredArticle.id)}
          description={featuredArticle.summary || featuredArticle.content}
          htmlStringDescription={!featuredArticle.summary}
          imageHeight="220px"
        />
      )),
    [featuredArticles],
  );

  if (!featuredContents?.length)
    return <EmptyContainer text="No featured content yet." />;

  return (
    <Grid
      container
      xs={12}
      minHeight={{ xs: '330px', sm: '220px' }}
      maxHeight={{ xs: '330px', sm: '220px' }}
      height="100%"
    >
      <CardCarousel cards={featuredContents} />
    </Grid>
  );
};
export default memo(FeaturedForMembers);
