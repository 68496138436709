export const USER_REGISTERED = 'userRegistered';
export const USER_ONBOARDED = 'userOnboarded';
export const SUBMIT_INVESTMENT_ENTITY = 'investmentEntityAdded';
export const SUBMIT_FUND_INVESTMENT = 'invested';

export const SEGMENT_ANALYTICS = {
  USER_REGISTERED,
  USER_ONBOARDED,
  SUBMIT_INVESTMENT_ENTITY,
  SUBMIT_FUND_INVESTMENT,
};
