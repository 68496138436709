import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

const TextButton = ({
  children,
  variant = 'buttonText',
  color,
  Icon,
  iconSx,
  typographySx = {},
  ...props
}) => (
  <ButtonBase {...props}>
    <Typography
      className={props.disabled ? 'disabled' : ''}
      variant={variant}
      color={color}
      display="flex"
      alignItems="center"
      sx={typographySx}
    >
      {Icon && (
        <Icon
          sx={{ width: '16px', height: '16px', marginRight: '8px', ...iconSx }}
        />
      )}
      {children}
    </Typography>
  </ButtonBase>
);
export default TextButton;
