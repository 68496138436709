import React from 'react';

import { GridActionsCellItem } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Chip from 'components/Chip';
import countries from 'utils/countries.json';
import isFunction from 'utils/isFunction';
import thousandSeparator from 'utils/strings/thousandSeparators';
import {
  getFundStatusChipColor,
  getUserStatusChipColor,
} from 'utils/chipMappers';
import { FUND_STATUS } from 'constants/funds';
import { USER_STATUS_LABEL } from 'constants/userStatus';
import Button from 'components/Button';
import SecondaryButton from 'components/Button/SecondaryButton';

export const dateCellRenderer = ({ value }) => {
  const newDate = new Date(value).toLocaleString('en-AU', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  return newDate;
};

export const fundUpdatesCellRenderer =
  (renderer) =>
  ({ value, row, fractions }) =>
    (
      <span style={{ fontWeight: row.id === 'Closing Balance' ? 700 : 400 }}>
        {value !== undefined ? renderer({ value, row, fractions }) : '-'}
      </span>
    );

export const dateTimeCellRenderer = ({ value }) => {
  if (!value) return value;

  const newDate = new Date(value);

  return newDate.toLocaleString('en-AU');
};

export const countryCellRenderer = ({ value }) =>
  countries.find(({ code }) => code === value)?.label || '';

export const statusChipCellRenderer = ({ value = 'pending', label }) => (
  <Chip
    textTransform="capitalize"
    label={label || value}
    color={`${value?.toLowerCase()}ChipColor`}
  />
);

export const fundVisibilityChipCellRenderer = ({ value }) => {
  const label = value ? 'Hidden' : 'Visible';

  return (
    <Chip
      textTransform="capitalize"
      label={label}
      color={`adminFund${label}ChipColor`}
    />
  );
};

export const fundStatusChipCellRenderer = ({ value }) => {
  const label = value ? 'Open' : 'Closed';

  return (
    <Chip
      textTransform="capitalize"
      label={label}
      color={`adminFundStatus${label}ChipColor`}
    />
  );
};

export const fundPostStatusChipCellRenderer = ({
  value = FUND_STATUS.DRAFT,
}) => (
  <Chip
    textTransform="capitalize"
    label={FUND_STATUS[value]}
    color={getFundStatusChipColor({ status: value })}
  />
);

export const userRoleChipCellRenderer = ({ value = 'ADMIN' }) => (
  <Chip
    textTransform="capitalize"
    label={`${value?.replace('_', ' ')}`}
    color={`${value?.toLowerCase()}ChipColor`}
  />
);

export const articleFeaturedChipCellRenderer = ({ value }) => {
  const color = value ? 'Featured' : 'Draft';
  const label = value ? 'Featured' : 'No';

  return (
    <Chip
      textTransform="capitalize"
      label={label}
      color={`article${color}ChipColor`}
    />
  );
};

export const actionCellRenderer = ({
  row,
  onView,
  onEdit,
  onDelete,
  onPreview,
}) => {
  const actions = [];

  if (isFunction(onView))
    actions.push(
      <GridActionsCellItem
        icon={<VisibilityOutlinedIcon />}
        label="View"
        onClick={() => onView(row.id)}
      />,
    );

  if (isFunction(onEdit))
    actions.push(
      <GridActionsCellItem
        icon={<EditOutlinedIcon />}
        label="Edit"
        onClick={() => onEdit(row.id)}
      />,
    );

  if (isFunction(onDelete))
    actions.push(
      <GridActionsCellItem
        icon={<DeleteOutlinedIcon />}
        label="Delete"
        onClick={(e) => {
          e.stopPropagation();
          return onDelete(row.id);
        }}
      />,
    );

  if (isFunction(onPreview))
    actions.push(
      <GridActionsCellItem
        icon={<VisibilityOutlinedIcon />}
        label="Preview"
        onClick={(e) => {
          e.stopPropagation();
          return onPreview(row.id);
        }}
      />,
    );

  return actions;
};

export const redeemBtnCellRenderer = ({ row, onClick, isSelected }) => {
  if (isSelected) {
    return <Button>Selected</Button>;
  }

  const {
    isRedemptionVisible,
    classLockupDays,
    lockupDays,
    lockupDaysRemaining,
  } = row;

  const isLocked = (lockupDaysRemaining ?? lockupDays ?? classLockupDays) > 0;

  let buttonProps = {
    label: 'Locked',
    bordercolor: 'black !important',
    labelProps: { color: 'black' },
    disabled: true,
  };

  if (!isLocked && isRedemptionVisible) {
    buttonProps = {
      label: 'Redeem',
      onClick: () => onClick(row),
    };
  }

  return (
    <SecondaryButton
      variant="outlined"
      sx={{ height: '100%', width: '100%' }}
      bordercolor={buttonProps.bordercolor}
      labelProps={buttonProps.labelProps}
      disabled={buttonProps.disabled}
      onClick={buttonProps.onClick}
    >
      <Typography variant="smallStatic">{buttonProps.label}</Typography>
    </SecondaryButton>
  );
};

export const verificationStatusChipCellRenderer = ({
  value = 'UNVERIFIED',
}) => (
  <Chip
    textTransform="capitalize"
    label={USER_STATUS_LABEL[value]}
    color={getUserStatusChipColor(value)}
  />
);

export const amountCellRenderer = ({ value = 0, fractions }) =>
  thousandSeparator(value, fractions);

export const amountWithDollarCellRenderer = ({ value = 0, fractions = 0 }) =>
  `$${thousandSeparator(value, fractions)}`;

export const percentCellRenderer = ({ value = 0, fractions = 0 }) =>
  fractions ? `${Number(value || 0).toFixed(fractions)}%` : `${value}%`;

export const classLockupDaysCellRenderer = ({ value = 0, row }) =>
  `${row.lockupDaysRemaining ?? value ?? 0} Days`;
