import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';

import ResponseMappers from 'utils/ResponseMappers';

import EventOverview from './EventOverview';

const Event = forwardRef(
  (
    {
      values,
      errors,
      setValues,
      setFieldError,
      setDirty,
      fieldKey,
      onSubmit,
      readOnly,
      imperativeHandleDepedencies = [],
    },
    ref,
  ) => {
    const handleSubmit = async (formValues, formikHelper) => {
      const res = await onSubmit({ [fieldKey]: formValues }, formikHelper);

      if (res.event.id) {
        const newEvent = ResponseMappers.admin.event(res);
        // Populate from BE response
        setValues(newEvent);
        formikHelper.setValues(newEvent.event);
      }
    };

    const eventForm = useFormik({
      initialValues: values,
      validateOnChange: true,
      // validationSchema: fundInformationValidationSchema,
      onSubmit: handleSubmit,
      initialErrors: {},
    });
    const initiated = useRef();

    useEffect(() => {
      initiated.current = false;
    }, []);

    useEffect(() => {
      if (!initiated.current) {
        initiated.current = true;
        return;
      }

      setDirty(true);
    }, [eventForm.values, eventForm.values]);

    useEffect(() => {
      const isError = !!Object.keys(eventForm.errors).length;

      if (isError !== errors) setFieldError(fieldKey, isError);
    }, [eventForm.errors]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues: () => eventForm.values,
        handleSubmit: eventForm.submitForm,
      }),
      [eventForm.values, ...imperativeHandleDepedencies],
    );

    return (
      <Grid container>
        <EventOverview
          readOnly={readOnly}
          onChange={eventForm.handleChange}
          onBlur={eventForm.handleBlur}
          values={eventForm.values}
          errors={eventForm.errors}
          setFieldValue={eventForm.setFieldValue}
        />
      </Grid>
    );
  },
);

export default memo(Event);
