import { useEffect } from 'react';

const scriptText =
  "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3523623,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";

const useHotjar = ({ withHotjar } = {}) => {
  useEffect(() => {
    if (!withHotjar || process.env.REACT_APP_ENV !== 'prod') return () => false;

    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.innerHTML = scriptText;
    head.appendChild(script);

    return () => {
      if (head) head.removeChild(script);
    };
  }, [withHotjar]);
};

export default useHotjar;
