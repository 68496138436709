import React, { memo } from 'react';
import Typography from '@mui/material/Typography';

const FundCardTitle = ({ text, ...props }) => (
  <Typography gutterBottom variant="display3" component="div" {...props}>
    {text}
  </Typography>
);

export default memo(FundCardTitle);
