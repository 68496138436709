import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const Label = ({ label, variant, onDelete }) => (
  <Stack direction="row" spacing={3}>
    <Typography variant={variant}>{label}</Typography>
    {onDelete && <TrashIcon sx={{ cursor: 'pointer' }} onClick={onDelete} />}
  </Stack>
);

export default Label;
