import React from 'react';

import Typography from '@mui/material/Typography';

const EllipsisTypography = ({ ellipsisAtLine = 1, style, ...props }) => (
  <Typography
    {...props}
    style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: ellipsisAtLine,
      WebkitBoxOrient: 'vertical',
      ...style,
    }}
  />
);

export default EllipsisTypography;
