import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as FundIcon } from 'assets/icons/fund.svg';

const management = {
  id: 'group-management',
  title: 'MANAGEMENT',
  type: 'group',
  children: [
    {
      id: '/admin/users',
      title: 'Users',
      type: 'item',
      url: '/admin/users',
      icon: DashboardIcon,
    },
    {
      id: '/admin/groups',
      title: 'Groups',
      type: 'item',
      url: '/admin/groups',
      icon: FundIcon,
    },
  ],
};

export default management;
