import React, { useCallback, useMemo } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

import ShowMe from 'components/ShowMe';

import history from 'utils/history';

import DashboardInsightImg from 'assets/images/dashboard-insight.png';
import DashboardPortfolioImg from 'assets/images/dashboard-portfolio.png';
import DashboardFundImg from 'assets/images/dashboard-fund.png';
import DashboardStatementImg from 'assets/images/dashboard-statement.png';
import DashboardInvestmentEntitiesImg from 'assets/images/dashboard-investment-entities.png';
import DashboardAboutUsImg from 'assets/images/dashboard-aboutus.png';
import DashboardContactInfoImg from 'assets/images/dashboard-contactInfo.png';

import Document from '../Document';
import FundsTab from '../FundsTab';
import InvestmentEntityList from '../InvestmentEntityList';
import PortfolioTab from '../PortfolioTab';
import About from '../About';
import ContactInformation from '../ContactInformation';
import InsightCommunityTab from '../InsightCommunityTab';
import DashboardImageTitle from '../DashboardImageTitle';
import Transactions from '../Transactions';

const Content = ({ onOpenCompleteProfileDialog }) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const handleRedirect = useCallback((path, closeDialog) => {
    if (closeDialog) closeDialog();
    history.push(path);
  }, []);

  const currentFundId = useMemo(
    () => searchParams.get('fundId'),
    [searchParams.get('fundId')],
  );

  const contents = useMemo(
    () => [
      {
        path: '/',
        element: <InsightCommunityTab />,
        exact: true,
        title: 'Insight',
        imgSrc: DashboardInsightImg,
      },
      {
        path: '/funds',
        element: (
          <FundsTab
            handleRedirect={handleRedirect}
            onOpenCompleteProfileDialog={onOpenCompleteProfileDialog}
          />
        ),
        exact: true,
        title: 'Funds',
        imgSrc: DashboardFundImg,
      },
      {
        path: '/portfolio',
        element: <PortfolioTab />,
        exact: true,
        title: 'My Portfolio',
        imgSrc: DashboardPortfolioImg,
      },
      {
        path: '/documents',
        element: <Document />,
        exact: true,
        title: 'My Statements',
        imgSrc: DashboardStatementImg,
      },
      {
        path: '/investment-entity-list',
        element: <InvestmentEntityList />,
        exact: true,
        title: 'Investment Entity',
        imgSrc: DashboardInvestmentEntitiesImg,
      },
      {
        path: '/transactions',
        element: <Transactions />,
        exact: true,
        title: 'Transactions',
        imgSrc: DashboardStatementImg,
      },
      {
        path: '/about',
        element: <About />,
        exact: true,
        imgSrc: DashboardAboutUsImg,
      },
      {
        path: '/contact-information',
        element: <ContactInformation />,
        exact: true,
        imgSrc: DashboardContactInfoImg,
      },
    ],
    [],
  );

  const { title: dashboardTitle, imgSrc } = useMemo(
    () =>
      contents.find(
        (dashboardRoute) =>
          `/dashboard${
            dashboardRoute.path !== '/' ? dashboardRoute.path : ''
          }` === location.pathname,
      ) || { title: '' },
    [location.pathname],
  );

  return (
    <>
      <ShowMe when={!currentFundId && dashboardTitle}>
        <Grid item mb={3}>
          <DashboardImageTitle
            title={dashboardTitle}
            imgSrc={imgSrc}
            titleContainerStyles={{
              left: [
                theme.spacing(0),
                theme.spacing(2),
                theme.spacing(10),
                theme.spacing(15),
                theme.spacing(25),
              ],
            }}
          />
        </Grid>
      </ShowMe>
      <Grid item px={[0, 2, 10, 15, 25]}>
        <Routes>
          {contents.map((content) => (
            <Route key={content.path} {...content} />
          ))}
        </Routes>
      </Grid>
    </>
  );
};

export default Content;
