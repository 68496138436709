import React, { memo, useRef, useEffect } from 'react';
import * as pdfjs from 'pdfjs-dist';

const PDFPage = ({ page, scale }) => {
  const canvasRef = useRef();
  const textLayerRef = useRef();

  useEffect(() => {
    if (!page) {
      return;
    }
    const viewport = page.getViewport({ scale });

    // Prepare canvas using PDF page dimensions
    const canvas = canvasRef.current;
    if (canvas && !canvas.contextLoaded) {
      // remove warning multiple render using same canvas
      canvasRef.current.contextLoaded = true;

      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport,
      };

      page.render(renderContext);
      page.getTextContent().then((textContent) => {
        if (!textLayerRef.current) {
          return;
        }

        // Pass the data to the method for rendering of text over the pdf canvas.
        pdfjs.renderTextLayer({
          textContent,
          container: textLayerRef.current,
          viewport,
          textDivs: [],
        });
      });
    }
  }, [page, scale]);

  return <canvas ref={canvasRef} />;
};

export default memo(PDFPage);
