import React, { memo } from 'react';
import MUITabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';

const StyledTabs = styled(MUITabs)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxHeight: theme.spacing(7.5),
  },
}));

const Tabs = ({ scrollBreakpoint = 'lg', ...props }) => {
  const theme = useTheme();
  const isScrollable = useMediaQuery(theme.breakpoints.down(scrollBreakpoint));

  return (
    <StyledTabs
      variant={isScrollable ? 'scrollable' : 'standard'}
      scrollButtons="auto"
      centered={!isScrollable}
      {...props}
      // value={0}
    />
  );
};

export default memo(Tabs);
