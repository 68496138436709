import * as yup from 'yup';

const companyTaxListValidationSchema = yup.object({
  taxPurposes: yup
    .array()
    .max(5)
    .test('taxPurposes required field', 'Required Field', (value) =>
      value.every((v) =>
        // when one of taxPurposes contains Australian Tax, their hasAustraliaTax field
        // would be false and cannot be changed, thus should be check by the isTinAvailable field
        v?.hasAustraliaTax === false
          ? v?.isTINAvailable !== undefined
          : v?.hasAustraliaTax !== undefined,
      ),
    ),
});

export default companyTaxListValidationSchema;
