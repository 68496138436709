import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextButton from 'components/Button/TextButton';

const DragAndDrop = ({ onChange, accept, disabled }) => {
  const [drag, setDrag] = useState(false);
  const inputRef = useRef();
  const dropRef = useRef();

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      const payload = {
        target: {
          files,
        },
      };
      onChange(payload);
      setDrag(false);
    }
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };

  useEffect(() => {
    dropRef.current.addEventListener('dragover', handleDragOver);
    dropRef.current.addEventListener('drop', handleDrop);
    dropRef.current.addEventListener('dragenter', handleDragEnter);
    dropRef.current.addEventListener('dragleave', handleDragLeave);
    return () => {
      dropRef.current?.removeEventListener('dragover', handleDragOver);
      dropRef.current?.removeEventListener('drop', handleDrop);
      dropRef.current?.removeEventListener('dragenter', handleDragEnter);
      dropRef.current?.removeEventListener('dragleave', handleDragLeave);
    };
  });

  return (
    <Box
      sx={{
        p: 10,
        border: '1px dashed #BDC3CD',
        borderRadius: 3,
        textAlign: 'center',
        background: drag ? '#FDFDFD' : 'transparent',
      }}
      ref={dropRef}
    >
      {drag ? (
        <Typography> Put it down here </Typography>
      ) : (
        <>
          <Typography> Drag &amp; Drop your file(s) here or </Typography>
          <TextButton component="label">
            Browse
            <input
              type="file"
              hidden
              onChange={onChange}
              ref={inputRef}
              multiple
              accept={accept}
              disabled={disabled}
            />
          </TextButton>
        </>
      )}
    </Box>
  );
};

export default DragAndDrop;
