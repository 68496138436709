import React from 'react';

import Grid from '@mui/material/Grid';

import EllipsisTypography from 'components/Typography/EllipsisTypography';
import palette from 'theme/palette';

const FundCardLandscapeContent = ({ contentList, ...props }) => (
  <Grid container item columnSpacing={2} direction="row" {...props}>
    {contentList.map(
      ({ heading, value, direction = 'column', spacing = 6 }, index) => (
        <Grid
          key={`${index.toString()}-${heading}-${value}`}
          container
          item
          xs={12 / (contentList?.length || 1)}
          direction={direction}
        >
          <Grid item xs={spacing}>
            <EllipsisTypography
              variant="bodyGreySmall"
              fontWeight={700}
              color={palette.text.obsidian}
            >
              {heading}
            </EllipsisTypography>
          </Grid>
          <Grid item xs={spacing}>
            {typeof value === 'string' ? (
              <EllipsisTypography variant="smallStatic">
                {value}
              </EllipsisTypography>
            ) : (
              value
            )}
          </Grid>
        </Grid>
      ),
    )}
  </Grid>
);

export default FundCardLandscapeContent;
