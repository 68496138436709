import React, { useCallback, memo, useMemo } from 'react';

import services from 'api/services';
import LinkExpiredContainer from 'components/LinkExpiredContainer';
import AuthContainer from 'components/AuthContainer/indexv2';

const TYPE = {
  CHANGE_PASSWORD: 'change',
  FORGOT_PASSWORD: 'forgot',
};

const LinkExpired = ({ verificationCode, type }) => {
  const handleResendEmail = useCallback(async () => {
    try {
      await services.forgotPasswordResendEmail({ verificationCode });
      return true;
    } catch (err) {
      return err;
    }
  }, [verificationCode]);

  const context = useMemo(
    () =>
      type === TYPE.CHANGE_PASSWORD ? 'change password' : 'reset password',
    [type],
  );
  const expiredMessage = useMemo(
    () =>
      type === TYPE.CHANGE_PASSWORD
        ? 'Change password link has expired'
        : 'Reset password link has expired',
    [type],
  );
  const expirationTime = useMemo(
    () => (type === TYPE.CHANGE_PASSWORD ? '24 hours' : '8 hours'),
    [type],
  );
  return (
    <AuthContainer title="Link Expired" onBack={null} onNext={null}>
      <LinkExpiredContainer
        context={context}
        expiredMessage={expiredMessage}
        expirationTime={expirationTime}
        handleResendEmail={handleResendEmail}
        showResendButton={type === TYPE.FORGOT_PASSWORD}
        showBackToLoginButton={type === TYPE.CHANGE_PASSWORD}
        subtitle={
          type === TYPE.CHANGE_PASSWORD
            ? 'To protect your account, change password link expire after '
            : undefined
        }
      />
    </AuthContainer>
  );
};

export default memo(LinkExpired);
