import Services from 'api/services';
import condTrueFalse from 'utils/condTrueFalse';

const fetchDocumentData = async (searchParams, handleFetchError) => {
  try {
    const isDataRoom = searchParams?.get('type') === 'dataroom';
    const folder = searchParams?.get('folder');
    let documentData;

    if (isDataRoom) {
      documentData = await Services.getDataRoomDocumentView(
        searchParams?.get('fundId'),
        searchParams?.get('token'),
      );
    } else {
      documentData = await Services.viewDocuments({
        folder,
        token: searchParams?.get('token'),
      });
    }

    return documentData;
  } catch (error) {
    condTrueFalse(handleFetchError, () =>
      handleFetchError(error?.response?.data),
    );
    return '';
  }
};

export default {
  fetchDocumentData,
};
