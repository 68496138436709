import React, { memo } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

const SuccessDialog = ({
  open,
  title = 'Success!',
  subtitle = 'Data submitted successfully',
  onConfirm,
}) => (
  <ConfirmationDialog
    open={open}
    title={title}
    subtitle={subtitle}
    infoText=""
    confirmButtonLabel="OK"
    onConfirm={onConfirm}
    subtitleProps={{ variant: 'h6' }}
  />
);

export default memo(SuccessDialog);
