import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';

import { getAccordionFooter } from 'containers/Admin/Funds/Details/FundDetails/HelperFunction';

import UploadSection from './UploadSection';

const defaultImageData = {
  id: null,
  fileName: '',
  content: '',
  // orderId: 3,
  isDeleted: false,
};

const UploadImageSliderList = ({
  onAddImage,
  onDeleteImage,
  onChangeImage,
  onChangeContent,
  disabled,
  images,
}) => {
  const [reinitializeCategory, setReinitializeCategory] = useState(false);

  const handleAddImage = useCallback(() => {
    onAddImage(defaultImageData);
  }, []);

  const handleDeleteImage = useCallback(({ index }) => {
    onDeleteImage({ index });

    setReinitializeCategory(true);
  }, []);

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} rowSpacing={2}>
        {images.map((image) => (
          <Grid item xs={12} p={1} key={`image-${image.index?.toString()}`}>
            <UploadSection
              value={image}
              index={image.index}
              onChangeContent={onChangeContent}
              onChangeImage={onChangeImage}
              onDeleteImage={handleDeleteImage}
              // reinitializeCategory={reinitializeCategory}
              disabled={image.disabled || disabled}
              placeholder="png, jpg, svg. up to 2MB only, 5:1 ratio"
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        {getAccordionFooter({
          addKey: 'Slide',
          onAdd: !disabled && handleAddImage,
          addIconColor: 'black',
          textColor: 'black',
        })}
      </Grid>
    </Grid>
  );
};

export default UploadImageSliderList;
