import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

import Switch from 'components/Switch';
import condTrueFalse from 'utils/condTrueFalse';

const SwitchWithLabel = ({
  leftLabel,
  rightLabel,
  onChange,
  invert,
  containerProps = {},
  ...props
}) => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const handleChangePaymentOpt = (e) => {
    let newValue = e.target.checked;
    e.target.checked = condTrueFalse(invert, !newValue, newValue);

    newValue = !!e.target.checked;

    const newEvent = {
      ...e,
      target: { ...e.target, value: newValue, name: e.target.name },
    };

    onChange(newEvent);
  };

  return (
    <Grid
      container
      item
      // justifyContent="center"
      columnSpacing={1.5}
      direction={isXS ? 'column' : 'row'}
      {...containerProps}
    >
      <Grid item textAlign="center">
        {leftLabel}
      </Grid>
      <Grid item textAlign="center">
        <Switch
          color="saveButton"
          onChange={handleChangePaymentOpt}
          {...props}
        />
      </Grid>
      <Grid item textAlign="center">
        {rightLabel}
      </Grid>
    </Grid>
  );
};

export default memo(SwitchWithLabel);
