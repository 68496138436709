import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import HistoricalValueList from './HistoricalValueList';
import PortfolioChart from './PortfolioChart';
import Notes from './Notes';

const PortfolioTab = ({ portfolioPerformances }) => {
  const historicalValueData = useMemo(
    () => [...portfolioPerformances].reverse(),
    [portfolioPerformances],
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <PortfolioChart data={portfolioPerformances} />
      </Grid>
      <Grid item xs={12}>
        <Notes />
      </Grid>
      <Grid item xs={12}>
        <HistoricalValueList data={historicalValueData} />
      </Grid>
    </Grid>
  );
};

export default PortfolioTab;
