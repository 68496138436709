import Services from 'api/services';

// eslint-disable-next-line
export const fetchFeaturedVideos = async () => {
  try {
    const res = await Services.getVideos();
    return res?.data?.data;
  } catch (_) {
    return {};
  }
};
