import React, { useCallback, useState, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AuthContainer from 'components/AuthContainer/indexv2';
import InfoText from 'components/InfoText';
import PasswordInput from 'components/Input/PasswordInput';
import SidebarContainer from 'components/SidebarContainer';
import { useActions, useStores } from 'components/StateProvider';

import history from 'utils/history';

import services from 'api/services';
import { ERROR_MESSAGES, ERROR_CODES } from 'constants/errors';

const VerifyPassword = () => {
  const {
    auth: { user },
  } = useStores();
  const { handleLogout } = useActions();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const inputDisabled = useMemo(
    () =>
      error ===
        ERROR_MESSAGES[
          ERROR_CODES.CHANGE_PASSWORD.CHANGE_PASSWORD_MAX_ATTEMPT
        ] ||
      error ===
        ERROR_MESSAGES[
          ERROR_CODES.CHANGE_PASSWORD.CHANGE_PASSWORD_TEMPORARY_LOCKED
        ] ||
      user.isChangePasswordDisable,
    [error, user.isChangePasswordDisable],
  );

  const handleSendEmail = useCallback(async () => {
    // here handle send email
    try {
      const response = await services.changePasswordSendEmail({ password });
      if (response.status === 200) {
        handleLogout();
        history.push('/update-password');
      }
    } catch (e) {
      setError(ERROR_MESSAGES[e.response?.data?.code]);
    }
  }, [password]);

  const handleBack = useCallback(() => {
    history.back();
  }, []);

  const handleChangePassword = useCallback((evt) => {
    setPassword(evt.target.value);
    if (!inputDisabled) setError(''); // only reset error if not locked
  }, []);

  return (
    <SidebarContainer>
      <AuthContainer
        isLoggedIn
        title="Update password"
        backLabel="Cancel"
        onBack={handleBack}
        onNext={handleSendEmail}
        isNextButtonDisabled={password.length < 8 || !!error}
      >
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body" textAlign="center" display="block">
              Are you sure want to change your current password?
            </Typography>
            <Typography variant="body" textAlign="center" display="block">
              To continue, please enter your current password below.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ '&.MuiGrid-item': { pt: '14px' } }}
            display="flex"
            justifyContent="center"
          >
            <PasswordInput
              data-testid="password-input"
              value={password}
              onChange={handleChangePassword}
              errorValue={error}
              disabled={inputDisabled}
              sx={{ mb: '10px', maxWidth: 400 }}
              placeholder="Current password*"
            />
          </Grid>
          <InfoText text="For security reasons, all your sessions will be automatically logged out" />
        </Grid>
      </AuthContainer>
    </SidebarContainer>
  );
};

export default VerifyPassword;
