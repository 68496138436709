import React, { useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { COUNTRY_OPTIONS_WITH_PRESERVED } from 'constants/phoneNumber';

import AutoCompletePopper from './AutocompletePopper';
import AutoCompleteInput from './AutocompleteInput';
import AutoCompleteOption from './AutocompleteOption';

const ListBoxProps = {
  sx: {
    maxHeight: 222,
    maxWidth: 216,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderLeft: '15px #D9D9D9 solid',
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '15px',
      marginBottom: '15px',
      backgroundColor: '#F6F6F6',
      borderRadius: '6px',
      height: 0,
      width: 0,
    },
  },
};

const isOptionEqualToValue = (option, value) => option?.code === value?.code;

const AutocompleteCountryCode = (props) => {
  const getAutoCompleteStyles = useCallback(
    (theme) => ({
      width: 200,
      '& .MuiOutlinedInput-root': {
        borderRadius: theme.spacing(0.5, 0, 0, 0.5),
        boxShadow: 'none',
      },
    }),
    [],
  );

  return (
    <Autocomplete
      {...props}
      isOptionEqualToValue={isOptionEqualToValue}
      id="country-select-demo"
      sx={getAutoCompleteStyles}
      options={COUNTRY_OPTIONS_WITH_PRESERVED}
      PopperComponent={AutoCompletePopper}
      ListboxProps={ListBoxProps}
      disableClearable
      autoHighlight
      margin="normal"
      getOptionLabel={(option) => option.code}
      popupIcon={<KeyboardArrowDownIcon />}
      renderOption={AutoCompleteOption}
      renderInput={AutoCompleteInput}
    />
  );
};

export default AutocompleteCountryCode;
