import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { PORTFOLIO_CUSTODIAL_DETAILS_FIELD_DISPLAY } from 'constants/dashboard';

import FieldContainer from 'components/FieldContainer';
import ShowMe from 'components/ShowMe';

const CustodialDetails = ({ investment }) => {
  const shownBankAccountInfo = useMemo(() => {
    if (investment?.portfolio?.fund?.bankAccount?.isShowBankDetails)
      return (
        <>
          <Typography variant="body">
            Please find below the bank account information for the custodial
            account. This is the account that you need to transfer the money to
            receive your units.
          </Typography>
          <Typography
            variant="body"
            sx={{ marginTop: '12px', display: 'block' }}
          >
            <b>Note:</b> Please ensure you transfer funds in the currency
            indicated above.
          </Typography>
        </>
      );

    return (
      <>
        <Typography variant="body">
          In the Documents tab, you will find an invoice with the trust account
          details. Please transfer the amount to the bank account to be issued
          your units.
        </Typography>
        <Typography variant="body" sx={{ marginTop: '12px', display: 'block' }}>
          <b>Note:</b> Please ensure you transfer the correct balance and
          currency to the trust account.
        </Typography>
      </>
    );
  }, [investment?.portfolio?.fund?.bankAccount?.isShowBankDetails]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2">Custodial Account Details</Typography>
      </Grid>
      <Grid item xs={12} mt={1.5}>
        {shownBankAccountInfo}
      </Grid>
      <ShowMe
        when={investment?.portfolio?.fund?.bankAccount?.isShowBankDetails}
      >
        <Grid container sx={{ padding: ['0px', '64px 0px'] }}>
          {PORTFOLIO_CUSTODIAL_DETAILS_FIELD_DISPLAY.map(
            ({ label, name, type, xs, md, renderer = (value) => value }) => (
              <Grid
                item
                xs={xs}
                md={md}
                key={`field-${label}`}
                sx={{ marginTop: ['24px', '44px'] }}
              >
                <FieldContainer label={label}>
                  {type === 'text' ? (
                    <Typography variant="h5">
                      {renderer(investment?.portfolio?.[name], investment)}
                    </Typography>
                  ) : (
                    <Chip
                      label={investment?.portfolio?.paymentStatus}
                      color={`${investment?.portfolio?.status?.toLowerCase()}ChipColor`}
                      sx={{
                        height: '20px',
                      }}
                    />
                  )}
                </FieldContainer>
              </Grid>
            ),
          )}
        </Grid>
      </ShowMe>
    </Grid>
  );
};

export default CustodialDetails;
