import React, { memo, useCallback } from 'react';

import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import Dropdown from 'components/Dropdown';
import FieldContainer from 'components/FieldContainer';
import FormTitle from 'components/FormTitle';
import InputField from 'components/Input';
import ShowMe from 'components/ShowMe';
import TextEditor from 'components/TextEditor';

import useDialog from 'hooks/useDialog';
import WarningIcon from 'assets/icons/warning-2.svg';

import { CLASS_TERM_OPTIONS, CURRENCY_OPTIONS } from 'constants/funds';

const FundFinancialInformation = ({
  onChange,
  values,
  errors,
  onBlur,
  readOnly,
  index,
  onDelete,
}) => {
  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleChange = useCallback(
    (e) => {
      onChange(e, index);
    },
    [onChange, index],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormTitle
          title="Fund Financial Information"
          onDelete={handleOpenDialog}
        />
      </Grid>

      <Grid container item xs={12} p={2} rowSpacing={3}>
        <Grid container item columnSpacing={2}>
          <FieldContainer item xs={6} label="Class Name">
            <InputField
              fullWidth
              data-testid="financial-info-className-input"
              id="className"
              name="className"
              placeholder="Class name*"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.className}
              error={!!errors.className}
              disabled={readOnly}
            />
          </FieldContainer>
        </Grid>

        <Grid container item columnSpacing={2}>
          <FieldContainer item xs={7.75} label="Amount to raise">
            <InputField
              fullWidth
              withThousandSeparator
              data-testid="financial-info-amountToRaise-input"
              id="amountToRaise"
              name="amountToRaise"
              placeholder="00000"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.amountToRaise}
              error={!!errors.amountToRaise}
              disabled={readOnly}
            />
          </FieldContainer>
          <FieldContainer item xs={1.25} label="Currency">
            <Dropdown
              options={CURRENCY_OPTIONS}
              onChange={handleChange}
              name="currency"
              value={values.currency}
              disabled={readOnly}
            />
          </FieldContainer>
          <FieldContainer item xs={3} label="Unit price">
            <InputField
              fullWidth
              withThousandSeparator
              data-testid="financial-info-unitPrice-input"
              id="unitPrice"
              name="unitPrice"
              placeholder="Unit unitPrice"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.unitPrice}
              error={!!errors.unitPrice}
              disabled={readOnly}
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={12} textAlign="right">
          <ShowMe when={!!values.currency}>
            <Typography variant="xsmall">
              {`Once selected, references to currency will be ${values.currency} throughout.`}
            </Typography>
          </ShowMe>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item columnSpacing={2}>
          <FieldContainer item xs={6} label="Minimum investment">
            <InputField
              fullWidth
              withThousandSeparator
              data-testid="financial-info-minInvestment-input"
              id="minInvestment"
              name="minInvestment"
              placeholder="Min investment value"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.minInvestment}
              error={!!errors.minInvestment}
              disabled={readOnly}
            />
          </FieldContainer>
          <FieldContainer item xs={6} label="Maximum investment">
            <InputField
              fullWidth
              withThousandSeparator
              data-testid="financial-info-maxInvestment-input"
              id="maxInvestment"
              name="maxInvestment"
              placeholder="Min investment value"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.maxInvestment}
              error={!!errors.maxInvestment}
              disabled={readOnly}
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item columnSpacing={2}>
          <FieldContainer item xs={4} label="Access fee">
            <InputField
              fullWidth
              data-testid="financial-info-accessFee-input"
              id="accessFee"
              name="accessFee"
              type="number"
              placeholder="%"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.accessFee}
              error={!!errors.accessFee}
              helperText="In percentage (%)"
              disabled={readOnly}
            />
          </FieldContainer>
          <FieldContainer item xs={4} label="Application fee">
            <InputField
              fullWidth
              data-testid="financial-info-applicationFee-input"
              id="applicationFee"
              name="applicationFee"
              type="number"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.applicationFee}
              error={!!errors.applicationFee}
              helperText=" "
              disabled={readOnly}
            />
          </FieldContainer>
          <FieldContainer item xs={4} label="Callable (on aplicable)">
            <InputField
              fullWidth
              data-testid="financial-info-callable-input"
              id="callable"
              name="callable"
              type="number"
              placeholder="%"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.callable}
              error={!!errors.callable}
              helperText="In percentage (%)"
              disabled={readOnly}
            />
          </FieldContainer>
        </Grid>
        <Grid container item columnSpacing={2}>
          <FieldContainer item xs={4} label="Class term">
            <Dropdown
              options={CLASS_TERM_OPTIONS}
              onChange={onChange}
              name="classTermYears"
              value={values.classTermYears}
              error={!!errors.classTermYears}
              disabled={readOnly}
            />
          </FieldContainer>
          <FieldContainer item xs={4} label="Class lockup">
            <InputField
              fullWidth
              data-testid="financial-info-classLockupDays-input"
              id="classLockupDays"
              name="classLockupDays"
              type="number"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.classLockupDays}
              error={!!errors.classLockupDays}
              disabled={readOnly}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">days</InputAdornment>
                ),
              }}
            />
          </FieldContainer>
          <FieldContainer item xs={4} label="Fund progress">
            <InputField
              fullWidth
              data-testid="financial-info-fundProgress-input"
              id="fundProgress"
              name="fundProgress"
              type="number"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.fundProgress}
              error={!!errors.fundProgress}
              disabled={readOnly}
            />
          </FieldContainer>
        </Grid>
        <Grid container item>
          <FieldContainer
            item
            xs={12}
            label="Redemption Request Form: class specific terms"
            childGridProps={{ sx: { minHeight: '225px' } }}
          >
            <TextEditor
              id="financial-info-redemptionRequestTerms-texteditor"
              onChange={onChange}
              value={values.redemptionRequestTerms}
              name="redemptionRequestTerms"
              options={{ minHeight: '200px' }}
            />
          </FieldContainer>
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={isDialogOpen}
        contentText="Are you sure you want to delete this Financial Information?"
        icon={WarningIcon}
        infoText="This action cannot be undone."
        onClose={handleCloseDialog}
        onConfirm={() => onDelete({ index, financialInformationId: values.id })}
      />
    </Grid>
  );
};

export default memo(FundFinancialInformation);
