import React from 'react';
import Typography from '@mui/material/Typography';

import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';

import services from 'api/services';

import Video from './Video';

export const videoTabItems = (props = {}) => [
  {
    key: 'video',
    Icon: PersonIcon,
    label: <Typography variant="h6">Video</Typography>,
    content: <Video fieldKey="video" {...props} />,
  },
];

export const fetchAdminVideoDetails = async (id) => {
  try {
    if (!id) return {};

    const res = await services.adminGetVideoDetails(id);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const sendAddVideo = async ({ payload }) => {
  try {
    const res = await services.adminAddVideo(payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const editVideo = async ({ id, payload }) => {
  try {
    const res = await services.adminEditVideo(id, payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};
