import * as yup from 'yup';

import generalValidations from 'validations/schema/general';

const adminAddUserInvestmentFundValidationSchema = yup.object({
  userEntity: yup.string().required().label('User Entity'),
  fund: yup.string().required().label('Fund'),
  fundConfiguration: yup.string().required().label('Fund Configuration'),

  paymentType: generalValidations.paymentType(yup),
  totalInvestment: generalValidations.totalInvestment(yup),
  units: generalValidations.investmentUnit(yup),
  amountPayableToday: generalValidations.amountPayableToday(yup),
  accessFee: generalValidations.accessFee(yup).notRequired().nullable(true),
  amountReceived: generalValidations.totalPayableToday(yup),

  amountPayableLater: generalValidations.amountPayableLater(yup),
  // total:generalValidations.total(yup),

  isSigned: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),

  investmentStatus: yup.string().required().label('Investment Status'),
});

export default adminAddUserInvestmentFundValidationSchema;
