import React from 'react';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import palette from 'theme/palette';

const SelectClassInput = ({ classes = [], value, onChange }) => (
  <Grid xs={12} md={6}>
    <FormLabel id="select-class-label">
      <Typography fontWeight={700} color={palette.text.obsidian}>
        Please select the class you would like to invest in
      </Typography>
    </FormLabel>
    <RadioGroup
      row
      aria-labelledby='"select-class-label"'
      name="financialInformationClass"
      value={value}
      onChange={onChange}
    >
      {classes.map((financialInformationClass) => (
        <Grid item xs={6} md={6}>
          <FormControlLabel
            value={financialInformationClass.id}
            label={financialInformationClass.className}
            control={<Radio />}
          />
        </Grid>
      ))}
    </RadioGroup>
  </Grid>
);

export default SelectClassInput;
