import React, { memo, useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import CardCarousel from 'components/CardCarousel';
import EventDetailDialog from 'components/Dialog/EventDetailDialog';

import useFetchApi from 'hooks/useFetchApi';
import useScreenQuery from 'hooks/useScreenQuery';

import EventCard from './EventCard';
import { fetchEvents } from './HelperFunction';

const height = '290px';

const UpcomingEvents = () => {
  const { isLaptopScreen, isMobileScreen, isTabletScreen } = useScreenQuery();

  const [selectedEvent, setSelectedEvent] = useState({});
  const getEvents = useMemo(() => fetchEvents(), []);
  const { apiData: data } = useFetchApi({
    url: getEvents,
  });

  const handleClickEvent = useCallback((event) => setSelectedEvent(event), []);

  const handleCloseEvent = useCallback(() => setSelectedEvent({}), []);

  const events = useMemo(
    () =>
      data?.map?.((event) => (
        <EventCard
          height={height}
          category={event.category}
          title={event.title}
          eventDate={event.date}
          onClickEvent={() => handleClickEvent(event)}
        />
      )),
    [data],
  );

  const numberOfCardPerSlide = useMemo(() => {
    switch (true) {
      case isMobileScreen:
        return 1.25;
      case isTabletScreen:
        return 2.25;
      case isLaptopScreen:
        return 3.25;
      default:
        return 4;
    }
  }, [isMobileScreen, isTabletScreen, isLaptopScreen]);

  return (
    <Grid container xs={12} minHeight={height} height="100%">
      <CardCarousel
        cards={events}
        numberOfCardPerSlide={numberOfCardPerSlide}
      />

      <EventDetailDialog
        open={!!selectedEvent.id}
        onClose={handleCloseEvent}
        title={selectedEvent.title}
        content={selectedEvent.content}
        eventDate={selectedEvent.date}
        link={selectedEvent.link}
        category={selectedEvent.category}
      />
    </Grid>
  );
};

export default memo(UpcomingEvents);
