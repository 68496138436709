import React, { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import MUIDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import palette from 'theme/palette';
import ShowMe from 'components/ShowMe';

const StyledTypography = styled(MUITypography)(({ statuscolor }) => ({
  color: '#ffffff',
  background: statuscolor,
  width: 'fit-content',
  padding: '0 15px',
  fontFamily: 'Inter',
}));

const FundCardStatus = ({ text, statusColor, isOpen, ...props }) => {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const color = useMemo(
    () => (isOpen ? 'black' : palette.text.fuchsia),
    [isOpen],
  );
  const dividerStyles = useMemo(
    () => ({
      border: `2px solid ${color}`,
    }),
    [color],
  );

  return (
    <div style={{ textAlign: isXs ? '-webkit-right' : '' }}>
      <MUIDivider sx={dividerStyles} />
      <ShowMe when={text}>
        <StyledTypography
          gutterBottom
          variant="xsmall"
          component="div"
          statuscolor={color}
          textAlign="right"
          {...props}
        >
          {text}
        </StyledTypography>
      </ShowMe>
    </div>
  );
};

export default FundCardStatus;
