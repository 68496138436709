import Services from 'api/services';

export const fetchArticle = async (articleId) => {
  try {
    const res = await Services.getArticle(articleId);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const fetchFeaturedArticles = async () => {
  try {
    const res = await Services.getFeaturedArticles();
    return res?.data?.data;
  } catch (_) {
    return {};
  }
};
