import companyDetailsValidationSchemas from './companyDetailsValidationSchema';
import addressValidationSchemas from '../addressValidationSchema';
import accountantCertificateValidationSchema from '../accountantCertificateValidationSchema';
import investorSummaryValidationSchema from '../investorSummaryValidationSchema';

const companyPersonalDetailsValidationSchemas = [
  companyDetailsValidationSchemas,
  addressValidationSchemas,
  accountantCertificateValidationSchema,
  investorSummaryValidationSchema,
];
export default companyPersonalDetailsValidationSchemas;
