import React from 'react';
import MUIGrid from '@mui/material/Grid';
import MUIDivider from '@mui/material/Divider';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';

import FundCardListText from './Text/FundCardListText';

const ListStyles = { marginTop: '28px' };
const DividerStyles = { margin: '12px 0' };

const FundCardContent = ({ contentList }) => (
  <MUIList sx={ListStyles}>
    {contentList?.map(({ heading, value }, index) => (
      <React.Fragment key={`${index.toString()}-${heading}-${value}`}>
        <MUIListItem disablePadding>
          <MUIGrid container>
            <MUIGrid item xs={7}>
              <FundCardListText text={heading} />
            </MUIGrid>
            <MUIGrid item xs={5}>
              <FundCardListText sx={{ textAlign: 'right' }} text={value} />
            </MUIGrid>
          </MUIGrid>
        </MUIListItem>
        <MUIDivider
          sx={{ xs: { margin: '4px 0' }, sm: DividerStyles }}
          hidden={!(heading || value)}
        />
      </React.Fragment>
    ))}
  </MUIList>
);

export default FundCardContent;
