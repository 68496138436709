import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const CardContainer = styled(Grid)(({ theme, ischild, style }) =>
  ischild === 'true'
    ? {
        borderLeft: `5px solid ${theme.palette.reach.blueBorder}`,
        paddingLeft: '15px',
        marginTop: '10px',
        marginLeft: theme.spacing(1),
        ...style,
      }
    : {},
);

export default CardContainer;
