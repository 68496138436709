import React, { useCallback, useState, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import BankDetails from 'containers/Admin/Approvals/Details/DetailsTab/BankDetails';
import Button from 'components/Button';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import FailedDialog from 'components/Dialog/FailedDialog';
import Loading from 'components/Loading';

import useAdminRestriction from 'hooks/useAdminRestriction';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import { LoadingSpinner } from 'hooks/useDashboardLoading';

import Services from 'api/services';
import history from 'utils/history';
import mapValue from 'utils/mapValue';
import ResponseMappers from 'utils/ResponseMappers';

import EntityDetailDialog from '../FundDetails/EntityDetailDialog';
import { fetchAdminFundRedemptionRequestDetails } from '../HelperFunction';

import DetailsContainer from './DetailsContainer';
import FundRedemptionRequestHeaders from './FundRedemptionRequestHeaders';
import FundRedemptionRequestDetailsSummary from './FundRedemptionRequestDetailsSummary';
import FundRedemptionRequestDetailsInfo from './FundRedemptionRequestDetailsInfo';

const handleRedirectToFundRedemptionRequestList = () => {
  const route = mapValue(
    history.location.state?.onBackRoute,
    '/admin/approvals?type=fundRedemptionRequest',
  );

  history.push(route);
};

const FundRedemptionRequestDetails = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { fundRedemptionRequestId } = useParams();

  const {
    isDialogOpen: isDeleteDialogOpen,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
  } = useDialog();

  const adminGetFundRedemptionRequestDetails = useMemo(
    () => fetchAdminFundRedemptionRequestDetails(fundRedemptionRequestId),
    [fundRedemptionRequestId, fetchStatus],
  );

  const { apiData: values, isFetchLoading } = useFetchApi({
    url: adminGetFundRedemptionRequestDetails,
    defaultApiData: {},
    dependencies: [adminGetFundRedemptionRequestDetails],
    mapApiData:
      ResponseMappers.admin.fundRedemptionRequest.fundRedemptionRequestDetails,
  });

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  ); // trigger useMemo changes to refetch

  const handleOpenDetailDialog = useCallback(() => {
    setOpenDetailDialog(true);
  }, []);

  const handleCloseDetailDialog = useCallback(() => {
    setOpenDetailDialog(false);
  }, []);

  const handleRedirectApproval = useCallback(() => {
    history.push(`/admin/approvals/details/${values?.userInvestorMapId}`);
  }, [values?.userInvestorMapId]);

  const handleDeleteFundRedemptionRequest = useCallback(async () => {
    try {
      handleCloseDeleteDialog();

      await Services.adminDeleteFundRedemptionRequest(fundRedemptionRequestId);

      handleRedirectToFundRedemptionRequestList();
    } catch (err) {
      setError('Delete fund failed');
    }
  }, []);

  const handleEditFundRedemptionRequest = useCallback(async (newValues) => {
    try {
      setLoading(true);

      const payload = {
        redemptionUnitAmount: newValues.redemptionUnitAmount,
        requestedAt: newValues.requestedAt,
      };

      await Services.adminUpdateFundRedemptionRequest({
        id: newValues.id,
        payload,
      });

      handleRefetch();
    } catch (err) {
      setError('Delete fund failed');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleResetError = useCallback(() => setError(''), []);

  if (isFetchLoading) return <LoadingSpinner isLoading />;

  return (
    <DetailsContainer
      fundRedemptionRequestId={fundRedemptionRequestId}
      values={values}
      handleRefetch={handleRefetch}
      readOnly={!haveCrudPermission}
      onDelete={handleOpenDeleteDialog}
    >
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <FundRedemptionRequestDetailsInfo values={values} />
        </Grid>
        <Grid container item xs={3} alignItems="center">
          <Button
            buttoncolor="purpleHazeGradient"
            onClick={handleOpenDetailDialog}
          >
            Entity Detail
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FundRedemptionRequestHeaders values={values} />
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={3}>
        <FundRedemptionRequestDetailsSummary
          title="Payment Details"
          withEdit={haveCrudPermission}
          sectionListView
          values={values || {}}
          setValues={handleEditFundRedemptionRequest}
        />
      </Grid>
      <Grid item xs={12}>
        <BankDetails
          title="Bank Details"
          withEdit={false}
          sectionListView
          values={values?.portfolio?.fundConfiguration?.bankAccount}
        />
      </Grid>

      {openDetailDialog && (
        <EntityDetailDialog
          open={!!openDetailDialog}
          onClose={handleCloseDetailDialog}
          type={values.investmentType}
          onEditEntity={handleRedirectApproval}
          entityId={values?.portfolio?.userInvestorMapFk}
          readOnly={haveCrudPermission}
        />
      )}

      <ConfirmationDialog
        open={!!isDeleteDialogOpen}
        title="Warning!"
        infoText=""
        subtitle="Do you want to delete this Redemption request ?"
        onClose={handleCloseDeleteDialog}
        confirmButtonLabel="Delete"
        onConfirm={handleDeleteFundRedemptionRequest}
        dialogContentDependencies={[handleDeleteFundRedemptionRequest]}
      />
      <FailedDialog
        open={!!error}
        onConfirm={handleResetError}
        subtitle={error}
      />

      <Loading popup loading={loading} />
    </DetailsContainer>
  );
};

export default FundRedemptionRequestDetails;
