import React, { useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

import GradientWrapper from 'components/FundCard/GradientWrapper';

import useFundCardHeaderStyles from 'hooks/useFundCardHeaderStyles';
import condTrueFalse from 'utils/condTrueFalse';
import FundCardHeaderImage from 'components/FundCard/components/Header/FundCardHeaderImage';

const BannerHeader = ({
  disabled,
  headerBgColor,
  headerImageSrc,
  logoSrc,
  width,
  headerImageProps = {},
  useHeaderBgWrapper,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    cardMedia: cardMediaStyles,
    header: headerStyles,
    getCardHeight,
  } = useFundCardHeaderStyles({
    headerBgColor,
    disabled,
    width,
    headerStyles: {
      minHeight: condTrueFalse(isMobileScreen, '150px', '200px'),
      display: 'flex',
    },
    chipStyles: () => ({ marginLeft: {} }),
  });

  const HeaderBackgroundWrapper = useMemo(
    () => (useHeaderBgWrapper ? GradientWrapper : React.Fragment),
    [useHeaderBgWrapper],
  );

  return (
    <div style={headerStyles}>
      <Grid
        container
        sx={{
          position: 'absolute',
          padding: { xs: '14px', sm: '24px' },
          height: '100%',
          width: '100%',
          zIndex: 2,
          marginLeft: 0,
        }}
        columnSpacing={isMobileScreen ? 0 : 1}
      >
        <Grid
          container
          item
          xs={12}
          sm
          mt={1}
          display="flex"
          direction="column"
        >
          <div style={{ alignItems: 'center', display: 'flex' }}>
            {logoSrc && (
              <FundCardHeaderImage logoSrc={logoSrc} {...headerImageProps} />
            )}
          </div>
        </Grid>
      </Grid>
      <HeaderBackgroundWrapper
        {...condTrueFalse(useHeaderBgWrapper, { height: getCardHeight() }, {})}
      >
        <CardMedia
          component={headerImageSrc ? 'img' : 'div'}
          image={headerImageSrc}
          alt="banner-header-img"
          sx={cardMediaStyles}
        />
      </HeaderBackgroundWrapper>
    </div>
  );
};

export default BannerHeader;
