import React, { memo, useCallback, useState } from 'react';
import { Alert, FormLabel, Grid } from '@mui/material';

import FormFields from 'containers/DashboardPage/components/CompleteProfileDialog/FormFields';
import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import CurrencyInput from 'components/Input/CurrencyInput';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import ShowMe from 'components/ShowMe';
import FieldContainer from 'components/FieldContainer';

import {
  PAYMENT_DETAILS_FIELD,
  PAYMENT_TYPE_OPTIONS,
  PAYMENT_OPTIONS_LABEL,
} from 'constants/investmentEntity';
import useDialog from 'hooks/useDialog';
import DatePicker from 'components/DatePicker';

const PaymentForm = ({
  disabled,
  onChange,
  currency,
  values,
  errors,
  setValues,
  withApprovedAt,
  setFieldValue,
}) => {
  const [status, setStatus] = useState(values?.paymentType);

  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleCancelChange = useCallback(() => {
    setStatus(values?.paymentType);
    handleCloseDialog();
  }, []);

  const handlePaymentChange = useCallback((e) => {
    setStatus(e.target.value);
    handleOpenDialog();
  }, []);

  const handleConfirmChange = useCallback(() => {
    setValues({
      id: values.id,
      amountReceiveEqual: false,
      paymentType: status,
    });
    handleCloseDialog();
  }, [status]);

  return (
    <Grid container rowSpacing={1.5} alignItems="center" textAlign="right">
      <Grid item xs={5} paddingX={7}>
        <FormLabel>Payment Type</FormLabel>
      </Grid>
      <Grid item xs={7}>
        <Dropdown
          fullWidth
          sx={{ textAlign: 'left' }}
          data-testid="paymentType-dropdown"
          name="paymentType"
          onChange={handlePaymentChange}
          disabled={disabled}
          value={values.paymentType}
          errorValue={errors.paymentType}
          options={PAYMENT_TYPE_OPTIONS}
        />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="warning" icon={false}>
          Caution: By changing payment type, you will need to enter the value
          manually
        </Alert>
      </Grid>
      {PAYMENT_DETAILS_FIELD.map(
        ({ fieldLabel, formLabel, name, withCurrency }) => (
          <React.Fragment key={name}>
            <Grid item xs={5} paddingX={7}>
              <FormLabel>{formLabel || fieldLabel}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              {withCurrency ? (
                <CurrencyInput
                  fullWidth
                  data-testid={`paymentForm-${name}-input`}
                  id={name}
                  name={name}
                  disabled={disabled}
                  placeholder=""
                  type="number"
                  value={values[name]}
                  errorValue={errors[name]}
                  onChange={onChange}
                  currency={currency}
                />
              ) : (
                <Input
                  fullWidth
                  data-testid={`paymentForm-${name}-input`}
                  id={name}
                  name={name}
                  disabled={disabled}
                  placeholder=""
                  type="number"
                  value={values[name]}
                  errorValue={errors[name]}
                  onChange={onChange}
                />
              )}
            </Grid>
          </React.Fragment>
        ),
      )}
      <ShowMe when={withApprovedAt}>
        <FieldContainer
          item
          label="Lockup Remaining"
          labelGridProps={{ xs: 5, justifyContent: 'right', paddingX: 7 }}
          childGridProps={{ xs: 7 }}
        >
          <Input
            fullWidth
            data-testid="paymentForm-lockupDaysRemaining-input"
            id="lockupDaysRemaining"
            name="lockupDaysRemaining"
            disabled={disabled}
            placeholder=""
            type="number"
            value={values.lockupDaysRemaining}
            errorValue={errors.lockupDaysRemaining}
            onChange={onChange}
          />
        </FieldContainer>

        <FieldContainer
          item
          label="Approval Date"
          labelGridProps={{ xs: 5, justifyContent: 'right', paddingX: 7 }}
          childGridProps={{ xs: 7 }}
        >
          <DatePicker
            fullWidth
            id="approvedAt"
            name="approvedAt"
            testId="paymentForm-approvedAt-datePicker"
            onChange={onChange}
            value={values?.approvedAt}
            errorValue={errors?.approvedAt}
            setFieldValue={setFieldValue}
            disabled={disabled}
          />
        </FieldContainer>
      </ShowMe>
      <AdminConfirmationDialog
        title="Confirm Changes?"
        subtitle={
          <strong>{`Are you sure you want to change this ${
            PAYMENT_OPTIONS_LABEL[values?.paymentType]
          } to ${PAYMENT_OPTIONS_LABEL[status]}?`}</strong>
        }
        open={isDialogOpen}
        onClose={handleCancelChange}
        onConfirm={handleConfirmChange}
        confirmButtonLabel="Yes"
        maxWidth="sm"
      >
        <Alert severity="warning" icon={false}>
          Caution: By changing payment type, you will need to enter the value
          manually
        </Alert>
      </AdminConfirmationDialog>
    </Grid>
  );
};

export default memo(PaymentForm);
