import Services from 'api/services';
import { dateCellRenderer } from 'utils/Datatables/cellRenderer';

export const fetchAdminEmailTemplates = async () => {
  const res = await Services.adminGetPlatformConfigurationEmailTemplates();

  return res?.data;
};

export const fetchAdminEmailTemplateDetails = async (id) => {
  const res = await Services.adminGetPlatformConfigurationEmailTemplateDetails(
    id,
  );

  return res?.data;
};

export const fetchAdminEmailTemplatePreview = async (id, payload) => {
  const res = await Services.adminGetPlatformConfigurationEmailPreview(
    id,
    payload,
  );

  return res?.data;
};

export const updateAdminEmailTemplate = async (payload) => {
  const res =
    await Services.adminUpdatePlatformConfigurationEmailTemplateDetails(
      payload,
    );
  return res?.data;
};

export const emailTemplatesHeaders = [
  {
    field: 'emailName',
    headerName: 'Template Name',
    flex: 2,
    cellClassName: 'cellBold',
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated',
    flex: 1,
    cellClassName: 'cellBold',
    renderCell: dateCellRenderer,
  },
  {
    field: 'updatedBy',
    headerName: 'Updated by',
    flex: 1,
    cellClassName: 'cellBold',
  },
];
