const general = (state, action) => {
  switch (action.type) {
    case '@@general/TOGGLE_EDIT_SUMMARY_SECTION': {
      return {
        ...state,
        general: { ...state.general, editingSummary: action.payload },
      };
    }
    case '@@general/SET_SIDEBAR': {
      return {
        ...state,
        general: { ...state.general, sidebar: action.payload },
      };
    }
    case '@@general/UPDATE_COLOURS': {
      return {
        ...state,
        general: {
          ...state.general,
          colours: { ...state.general.colours, ...action.payload },
        },
      };
    }
    case '@@general/UPDATE_CURRENCY_DATA': {
      return {
        ...state,
        general: { ...state.general, currencyData: action.payload },
      };
    }
    case '@@general/OTHER_ACTION': {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default general;
