import React, { useEffect, useMemo } from 'react';

import services from 'api/services';
import useFetchApi from 'hooks/useFetchApi';
import useDashboardLoading from 'hooks/useDashboardLoading';
import condTrueFalse from 'utils/condTrueFalse';

import ContentContainer from '../ContentContainer';
import EmptyInvestContainer from '../EmptyInvestContainer';
import DocumentFolderList from './DocumentFolderList';

const defaultDocuments = [
  {
    folder: 'My Documents',
    items: [],
  },
];

const Document = () => {
  const fetchDocumentLists = useMemo(() => services.getDocumentLists(), []);

  const { isFetched, handleSetIsLoading, handleSetIsFetched } =
    useDashboardLoading();

  useEffect(
    () => condTrueFalse(isFetched, () => handleSetIsFetched(false)),
    [],
  );

  const { apiData: documentListItems, isFetchLoading } = useFetchApi({
    url: fetchDocumentLists,
    isFetched,
    setIsFetched: handleSetIsFetched,
    setIsLoading: handleSetIsLoading,
    dependencies: [isFetched],
  });

  if (isFetchLoading) return null;
  if (!documentListItems?.data?.length)
    return (
      <ContentContainer data-testid="tab-item-documents">
        <DocumentFolderList folders={defaultDocuments} />
        <EmptyInvestContainer testId="document-list-empty" />
      </ContentContainer>
    );

  return (
    <ContentContainer data-testid="tab-item-documents">
      <DocumentFolderList folders={documentListItems.data} />
    </ContentContainer>
  );
};

export default Document;
