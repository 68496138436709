import React, { useEffect } from 'react';

import Loading from 'components/Loading';
import { useActions } from 'components/StateProvider';

import history from 'utils/history';

const LogoutPage = () => {
  const { handleLogout } = useActions();

  const logout = async () => {
    try {
      await handleLogout();
    } catch (e) {
      history.back();
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return <Loading popup loading />;
};

export default LogoutPage;
