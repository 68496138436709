import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import BackButton from 'components/Button/BackButton';
import history from 'utils/history';
import mapValue from 'utils/mapValue';

import InvestmentEntityContainer from './components/InvestmentEntityContainer';
import GetStartedDescription from './components/GetStartedDescription';

const AddInvestmentEntity = () => {
  const handleBack = useCallback(() => {
    history.push(mapValue(history?.location?.state?.href, '/'));
  }, []);

  const handleGoToSelectEntity = useCallback(() => {
    history.push('/add-investment-entity/select-type', {
      href: history?.location?.state?.href,
    });
  }, []);

  return (
    <InvestmentEntityContainer pt={8}>
      <Grid container justifyContent="center">
        <Grid item marginBottom={5}>
          <Typography variant="h2">Let&apos;s get started</Typography>
        </Grid>
        <Grid container item spacing={3} paddingX={{ xs: 0, md: 12 }}>
          <Grid item xs={12}>
            <GetStartedDescription />
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              columnSpacing={5}
            >
              <Grid item>
                <BackButton
                  onClick={handleBack}
                  label="Cancel"
                  data-testid="button-back"
                />
              </Grid>
              <Grid item md={3}>
                <Button
                  buttoncolor="purpleHazeGradient"
                  onClick={handleGoToSelectEntity}
                  data-testid="button-next"
                >
                  Let&apos;s go
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </InvestmentEntityContainer>
  );
};

export default AddInvestmentEntity;
