import React, { memo } from 'react';
import Typography from '@mui/material/Typography';

const FundCardDesc = ({ text, ...props }) => (
  <Typography gutterBottom variant="bodyGreySmall" {...props}>
    {text}
  </Typography>
);

export default memo(FundCardDesc);
