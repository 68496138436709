const getNestedObject = (object, keys, defaultVal) => {
  const mappedKeys = Array.isArray(keys) ? keys : keys?.split('.');
  const mappedObject = object?.[mappedKeys?.[0]];
  if (mappedObject && mappedKeys.length > 1) {
    return getNestedObject(mappedObject, mappedKeys.slice(1));
  }
  return mappedObject === undefined ? defaultVal : mappedObject;
};

export default getNestedObject;
