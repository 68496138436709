import React, { useCallback, useMemo } from 'react';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import Dropdown from 'components/Dropdown';
import InputField from 'components/Input';
import YesNoQuestion from 'components/YesNoQuestion';

import { COUNTRY_OPTIONS } from 'constants/investmentEntity';
import { FormHelperText } from '@mui/material';
import TaxContentContainer from './TaxContentContainer';

const australiaTaxComponentDefaultProps = { label: 'Tax file number' };

const IsAustraliaResident = ({
  values,
  onChange,
  errors,
  touched,
  onBlur,
  australiaTaxComponentProps = australiaTaxComponentDefaultProps,
  hasAustraliaTaxComponentProps = {},
  otherCountryTaxComponentProps = {},
  noOtherCountryTax = false,
}) => {
  const handleHasAustraliaTax = useCallback((e) => {
    if (!e.target.value || noOtherCountryTax) {
      const payload = {
        target: {
          name: 'hasOtherCountryTax',
          value: !e.target.value,
        },
      };
      onChange(payload);
    }

    const payloadClearOtherCountryTax = {
      target: {
        name: 'otherCountryTax',
        value: '',
      },
    };
    onChange(payloadClearOtherCountryTax);

    // This `onChange` has to be on the last order.
    onChange(e);
  }, []);

  const TaxPurposeOrTaxFileNumber = useMemo(() => {
    if (
      values?.hasAustraliaTax === null ||
      values?.hasAustraliaTax === undefined
    )
      return null;

    if (values?.hasAustraliaTax)
      return (
        <Grid style={australiaTaxComponentProps.containerStyle}>
          <FormLabel>{australiaTaxComponentProps.label}</FormLabel>
          <InputField
            data-testid="australia-tax-input"
            fullWidth
            id="australiaTax"
            name="australiaTax"
            value={values?.australiaTax}
            onChange={onChange}
            onBlur={onBlur}
            errorValue={touched?.australiaTax && errors.australiaTax}
            type="number"
            mask="-"
            maskInterval={3}
            placeholder={australiaTaxComponentProps?.placeholder}
          />
          <FormHelperText>
            {australiaTaxComponentProps.helperText}
          </FormHelperText>
        </Grid>
      );

    return (
      <Grid
        container
        direction="row"
        style={otherCountryTaxComponentProps.containerStyle}
      >
        <Grid item xs={12}>
          <FormLabel>Your country for tax purposes*</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Dropdown
              autoWidth
              value={values?.otherCountryTax}
              name="otherCountryTax"
              onChange={onChange}
              options={
                otherCountryTaxComponentProps?.options || COUNTRY_OPTIONS
              }
              isBoldOptionLabel
              onBlur={onBlur}
              errorValue={touched?.otherCountryTax && errors.otherCountryTax}
              placeholder="Select country*"
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }, [
    values?.hasAustraliaTax,
    values?.australiaTax,
    values?.otherCountryTax,
    touched?.australiaTax,
    errors.australiaTax,
    touched?.otherCountryTax,
    errors.otherCountryTax,
  ]);

  return (
    <TaxContentContainer container data-testid="is-australia-resident-field">
      <Grid item xs={12}>
        <YesNoQuestion
          name="hasAustraliaTax"
          label="Is Australia your country of residence for tax purpose?"
          value={values?.hasAustraliaTax}
          onChange={handleHasAustraliaTax}
          disabledYes={hasAustraliaTaxComponentProps?.disabledYes}
          disabledNo={hasAustraliaTaxComponentProps?.disabledNo}
        />
      </Grid>
      <Grid item xs={8}>
        {TaxPurposeOrTaxFileNumber}
      </Grid>
    </TaxContentContainer>
  );
};
export default IsAustraliaResident;
