import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

import DetailsPageContainer from 'components/DetailsPageContainer';
import LoadingDialog from 'components/LoadingDialog';
import ShowMe from 'components/ShowMe';

import history from 'utils/history';
import getFullName from 'utils/getFullName';
import thousandSeparator from 'utils/strings/thousandSeparators';
import useFetchApi from 'hooks/useFetchApi';
import adminAddUserInvestmentFundValidationSchema from 'validations/schema/adminAddUserInvestmentFundValidationSchema';
import { PAY_IN_INSTALLMENT, PAY_NOW } from 'constants/investmentEntity';
import { VERIFIED, VERIFIED_OVERSEAS } from 'constants/userStatus';

import EntitySection from './EntitySection';
import PaymentDetailsSection from './PaymentDetailsSection';
import InstallmentInformationSection from './InstallmentInformationSection';
import SigninDocument from './SigninDocument';
import InvestmentStatusSection from './InvestmentStatusSection';
import {
  fetchAdminUserEntityList,
  fetchFunds,
  fetchUserData,
  submitUserInvestmentFund,
} from './HelperFunction';

const AddInvestmentFund = () => {
  const { userId } = useParams();
  const [fundConfiguration, setFundConfiguration] = useState({});
  const [error, setError] = useState('');

  const adminGetUserEntityList = useCallback(
    () =>
      Promise.all([
        fetchAdminUserEntityList({ userId }),
        fetchUserData(userId),
        fetchFunds(),
      ]),
    [userId],
  );

  const { apiData: dataValues } = useFetchApi({
    fetchService: adminGetUserEntityList,
    defaultApiData: {
      openFunds: 0,
      users: 0,
      onboarded: 0,
      investmentEntities: 0,
      investments: 0,
      raised: {},
    },
    dependencies: [adminGetUserEntityList],
    mapApiData: (response) => ({
      userEntities: response[0],
      userDetail: { ...response[1], userId: response[1]?.id },
      funds: response[2],
    }),
  });

  const handleBack = useCallback(() => {
    history.push(`/admin/users/edit/${userId}?tab=investmentFunds`);
  }, [userId]);

  const formik = useFormik({
    validationSchema: adminAddUserInvestmentFundValidationSchema,
    initialValues: {
      userName: '',
      userEntity: '',
      fund: '',
      fundConfiguration: '',

      paymentType: PAY_NOW,
      totalInvestment: '',
      units: '',
      accessFee: '',
      amountPayableToday: '',
      amountReceived: '',
      amountPayableLater: '',
      total: '',
      isSigned: false,
      investmentStatus: '',
    },
    enableReinitialize: true,
    onSubmit: (formValues) =>
      submitUserInvestmentFund({ formValues, cb: handleBack, setError }),
  });

  useEffect(() => {
    if (!dataValues?.userDetail) return;

    formik.setFieldValue('userName', getFullName(dataValues?.userDetail));
    formik.setFieldValue('userId', dataValues.userDetail.id);
  }, [dataValues?.userDetail?.id]);

  useEffect(() => {
    formik.setFieldValue('fundConfiguration', '');
  }, [formik.values.fund]);

  const fundConfigurations = useMemo(() => {
    if (!formik.values.fund || !dataValues?.funds?.length) return [];

    return dataValues.funds.find((fund) => fund.id === formik.values.fund)
      .fundConfigurations;
  }, [formik.values.fund, dataValues.funds?.length]);

  useEffect(() => {
    const newFundConfiguration =
      fundConfigurations.find(
        (fundConfig) => fundConfig.id === formik.values.fundConfiguration,
      ) || {};
    if (!newFundConfiguration) return;

    setFundConfiguration(newFundConfiguration);

    formik.setFieldValue(
      'applicationFee',
      thousandSeparator(newFundConfiguration.applicationFee),
    );
  }, [formik.values.fundConfiguration, fundConfigurations]);

  const isUserVerified = useMemo(
    () =>
      !![VERIFIED, VERIFIED_OVERSEAS].includes(
        dataValues?.userDetail?.statusVerification,
      ),
    [dataValues?.userDetail?.statusVerification],
  );

  return (
    <>
      <DetailsPageContainer
        onBackButtonClick={handleBack}
        submitBtnDisabled={!isUserVerified || Object.keys(formik.errors).length}
        backButtonLabel="User"
        onSubmitClick={formik.submitForm}
        submitBtnLabel="Save"
        status="New"
        childrenContainerProps={{ rowSpacing: 2, container: true }}
      >
        <ShowMe when={!isUserVerified}>
          <Grid item xs={12} mb={2}>
            <Alert severity="warning" icon={false}>
              This user not verified yet.
            </Alert>
          </Grid>
        </ShowMe>
        <ShowMe when={!!error}>
          <Grid item xs={12} mb={2}>
            <Alert severity="error" icon={false}>
              {error}
            </Alert>
          </Grid>
        </ShowMe>
        <EntitySection
          values={formik.values}
          errors={formik.errors}
          investmentEntities={dataValues?.userEntities}
          funds={dataValues.funds}
          onChange={formik.handleChange}
          fundConfiguration={fundConfiguration}
          userData={dataValues?.userDetail}
          fundConfigurations={fundConfigurations}
        />
        <ShowMe when={fundConfiguration.id}>
          <PaymentDetailsSection
            values={formik.values}
            errors={formik.errors}
            onChange={formik.handleChange}
            setValues={formik.setValues}
            setFieldValue={formik.setFieldValue}
            fundConfiguration={fundConfiguration}
            touched={formik.touched}
            onBlur={formik.handleBlur}
          />
        </ShowMe>
        <ShowMe when={formik.values.paymentType === PAY_IN_INSTALLMENT}>
          <InstallmentInformationSection
            values={formik.values}
            errors={formik.errors}
            onChange={formik.handleChange}
            setValues={formik.setValues}
            fundConfiguration={fundConfiguration}
          />
        </ShowMe>
        <SigninDocument
          onChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          onBlur={formik.handleBlur}
        />
        <InvestmentStatusSection
          onChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          onBlur={formik.handleBlur}
        />
      </DetailsPageContainer>

      <LoadingDialog loading={formik.isSubmitting} />
    </>
  );
};

export default AddInvestmentFund;
