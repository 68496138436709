import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PrevIcon from '@mui/icons-material/ChevronLeft';
import NextIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';

import palette from 'theme/palette';

const Container = styled('div')(
  ({ overflow = 'hidden', aspectratio = '5/1' }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    aspectRatio: aspectratio,
    overflow,
  }),
);

const SlideContainer = styled('div')(({ width = '100%' }) => ({
  position: 'absolute',
  height: '100%',
  width,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  transition: 'left ease-out 0.5s',
}));

const navBtnPosition = (left) =>
  left ? { left: '-10px' } : { right: '-10px' };

const NavButtonContainer = styled('div')(({ left }) => ({
  position: 'absolute',
  bottom: '35%',
  zIndex: 1,
  ...navBtnPosition(left),
}));

const NavButton = styled('button')(() => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  border: 0,
  height: 40,
  width: 40,
  padding: 0,
}));

const cardSpace = 1.25;

const CardCarousel = ({
  cards = [],
  autoSlide,
  numberOfCardPerSlide = 1,
  cardSliderContainerProps = {},
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex <= 0
        ? cards.length - Math.floor(numberOfCardPerSlide)
        : prevIndex - 1,
    );
  }, [cards.length]);

  const handleNextClick = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex >= cards.length - numberOfCardPerSlide ? 0 : prevIndex + 1,
    );
  }, [cards.length]);

  useEffect(() => {
    if (!autoSlide) return () => true;

    const intervalId = setInterval(() => {
      handleNextClick();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [activeIndex]);

  const getAdditionalCardLeftSpace = useCallback(
    ({ index, activeIdx, numOfCard }) =>
      numOfCard > 1 && index !== activeIdx
        ? cardSpace * (index - (activeIdx % numOfCard))
        : 0,
    [],
  );

  const leftSpacing = 100 / numberOfCardPerSlide + cardSpace;

  return (
    <Container overflow="inherit" {...props}>
      <NavButtonContainer left="true">
        <NavButton
          type="button"
          onClick={handlePrevClick}
          disabled={cards.length < numberOfCardPerSlide}
          sx={{
            textAlign: 'left',
            background: 'linear-gradient(to right, white, transparent)',
          }}
        >
          <PrevIcon
            fontSize="small"
            sx={{ height: '100%', color: palette.reach.purpleHaze }}
          />
        </NavButton>
      </NavButtonContainer>
      <Container {...cardSliderContainerProps}>
        {cards.map((image, index) => (
          <SlideContainer
            key={`${index.toString()}-${image?.content}`}
            style={{
              left: `${leftSpacing * (index - activeIndex)}%`,
            }}
            width={`${
              100 / numberOfCardPerSlide -
              (numberOfCardPerSlide > 1 ? cardSpace * 2 : 0)
            }%`}
          >
            {image}
          </SlideContainer>
        ))}
      </Container>

      <NavButtonContainer>
        <NavButton
          type="button"
          onClick={handleNextClick}
          disabled={cards.length < numberOfCardPerSlide}
          sx={{
            textAlign: 'right',
            background: 'linear-gradient(to left, white, transparent)',
          }}
        >
          <NextIcon
            fontSize="small"
            sx={{ height: '100%', color: palette.reach.purpleHaze }}
          />
        </NavButton>
      </NavButtonContainer>
    </Container>
  );
};

export default CardCarousel;
