import * as yup from 'yup';

const fundInformationValidationSchema = yup.object({
  fundInformation: yup.object({
    // overview
    fundName: yup.string().required().label('Fund Name').min(5).max(50),
    type: yup.string().required().label('Fund Type'),
    region: yup.string().required().label('Region'),
    country: yup.string().label('Country'),
    summary: yup.string().label('Summary'),
    // period
    startDate: yup.string().label('Start Date'),
    periodStatus: yup.boolean(),
    endDate: yup
      .string()
      .when('periodStatus', {
        is: true,
        then: yup.string().required(),
      })
      .label('End Date'),
    showWidget: yup.boolean(),
    showFundPercentage: yup.boolean(),
    fundPercentage: yup
      .number()
      .when('showFundPercentage', {
        is: true,
        then: yup.number(),
      })
      .label('amount raised'),
  }),
  fundCard: yup.object().shape(
    {
      // fund card
      fundHeaderImg: yup
        .mixed()
        .test('acceptedFileType', 'Only Image accepted.', (image) =>
          image?.blob ? image?.blob?.type.includes('image') : true,
        )
        .test('maxFileSize', 'File size is too large.', (image) => {
          const fileSize = image?.blob?.size || 0;
          const fileSizeInMega = fileSize / 1024 / 1024;
          return fileSizeInMega < 2;
        })
        .label('Fund Header Image'),
      fundLogo: yup
        .mixed()
        .test('acceptedFileType', 'Only Image accepted.', (image) =>
          image?.blob ? image?.blob?.type.includes('image') : true,
        )
        .test('maxFileSize', 'File size is too large.', (image) => {
          const fileSize = image?.blob?.size || 0;
          const fileSizeInMega = fileSize / 1024 / 1024;
          return fileSizeInMega < 2;
        })
        .label('Fund Logo Image'),
      fundCardImage: yup
        .mixed()
        .test('acceptedFileType', 'Only Image accepted.', (image) =>
          image?.blob ? image?.blob?.type.includes('image') : true,
        )
        .test('maxFileSize', 'File size is too large.', (image) => {
          const fileSize = image?.blob?.size || 0;
          const fileSizeInMega = fileSize / 1024 / 1024;
          return fileSizeInMega < 2;
        })
        .label('Fund Card Image'),
      field1: yup
        .string()
        .when('value1', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Heading 1'),
      value1: yup
        .string()
        .when('field1', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Value 1'),
      field2: yup
        .string()
        .when('value2', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Heading 2'),
      value2: yup
        .string()
        .when('field2', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Value 2'),
      field3: yup
        .string()
        .when('value3', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Heading 3'),
      value3: yup
        .string()
        .when('field3', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Value 3'),
      field4: yup
        .string()
        .when('value4', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Heading 4'),
      value4: yup
        .string()
        .when('field4', {
          is: (value) => !value,
          then: yup.string(),
          otherwise: yup.string().required(),
        })
        .max(30)
        .label('Value 4'),
    },
    [
      ['field1', 'value1'],
      ['field2', 'value2'],
      ['field3', 'value3'],
      ['field4', 'value4'],
    ],
  ),
});

export default fundInformationValidationSchema;
