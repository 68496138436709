import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import FieldContainer from 'components/FieldContainer';
import ShowMe from 'components/ShowMe';

import { INVESMENT_ENTITY_LIST_FIELD_DISPLAY } from 'constants/investmentEntity';

const EntityDetailsHeader = ({ values }) => (
  <Grid
    item
    container
    sx={(theme) => ({
      background: theme.palette.reach.obsidian,
      padding: 1.5,
      borderRadius: '6px',
      margin: 0,
    })}
    alignItems="center"
  >
    <Grid
      item
      xs={12}
      md={1.5}
      mr={1}
      textAlign="center"
      sx={{ marginBottom: { xs: 2, sm: 0 } }}
    >
      <Typography
        variant="display6"
        sx={(theme) => ({ color: theme.palette.text.white })}
      >
        Entity Details
      </Typography>
    </Grid>
    <Divider
      orientation="vertical"
      variant="middle"
      flexItem
      sx={(theme) => ({
        backgroundColor: theme.palette.border.light,
        margin: 0,
      })}
    />
    <Grid item xs container sx={{ marginLeft: 3 }}>
      {INVESMENT_ENTITY_LIST_FIELD_DISPLAY.map(
        ({ name, label, md, renderer = (value) => value }) => (
          <ShowMe when={name !== 'status'} key={`${name}-${label}`}>
            <Grid item xs md={md}>
              <FieldContainer
                label={label}
                rowSpacing={0}
                variant="small"
                labelColor="white"
              >
                <Typography
                  variant="display6"
                  sx={(theme) => ({
                    color: theme.palette.text.white,
                  })}
                >
                  {renderer(values[name])}
                </Typography>
              </FieldContainer>
            </Grid>
          </ShowMe>
        ),
      )}
    </Grid>
  </Grid>
);

export default EntityDetailsHeader;
