import React from 'react';
import Accordion from 'components/Accordion';
import CompanyTaxDetail from './CompanyTaxDetail';

const CompanyTaxDetailWithAccordion = ({ values }) => (
  <Accordion label="Tax Details" labelVariant="cardNameLabel">
    <CompanyTaxDetail
      taxDetails={values?.taxDetails}
      supportingInformation={values?.supportingInformation}
    />
  </Accordion>
);

export default CompanyTaxDetailWithAccordion;
