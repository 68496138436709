import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import CardCarousel from 'components/CardCarousel';
import ShowMe from 'components/ShowMe';
import InnerHtmlContainer from 'components/InnerHtmlContainer';
import { Image } from 'components/ImageCarousel';
import { fetchAdminImageSliders } from 'containers/Admin/PlatformConfiguration/SliderSettings/HelperFunction';

import useFetchApi from 'hooks/useFetchApi';

const CompanyUpdates = ({ height }) => {
  const { apiData: images } = useFetchApi({
    fetchService: fetchAdminImageSliders,
    defaultApiData: [],
  });

  const imageContent = useMemo(
    () =>
      images.map(({ image, content } = {}) => (
        <>
          <Image src={image} />
          <ShowMe when={content}>
            <Grid position="absolute" left="6%" right="6%">
              <InnerHtmlContainer
                htmlData={content}
                backgroundColor="transparent"
              />
            </Grid>
          </ShowMe>
        </>
      )),
    [images],
  );

  return (
    <Grid container xs={12} minHeight={height} height="100%">
      <CardCarousel cards={imageContent} />
    </Grid>
  );
};

export default CompanyUpdates;
