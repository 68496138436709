import services from 'api/services';
import segmentTrack from 'hooks/useSegmentAnalytic/segmentTrack';
import PayloadMappers from 'utils/PayloadMappers';
import getFullName from 'utils/getFullName';

import { SEGMENT_ANALYTICS } from 'constants/segmentAnalytics';

export const handleUploadCertificates = async ({
  id,
  entityType,
  certificates,
}) => {
  const formData = new FormData();

  certificates.forEach((certificate) => {
    formData.append('certificate', certificate.file);
  });

  await services.addInvestmentEntityUpload({
    id,
    entityType,
    payload: formData,
  });
};

export const handleAddEntity = async (formValues) => {
  const joint = {};
  const { personalDetails, taxDetails, bankDetails, userId, userEmail } =
    formValues;

  if (personalDetails?.joint) {
    const { isConfirmed, signedByName, signedAt } = taxDetails;

    joint.personalDetail = PayloadMappers.personalDetails(
      personalDetails?.joint,
    );
    joint.taxDetail = {
      ...formValues?.joint?.taxDetails,
      isConfirmed,
      signedByName,
      signedAt,
    };
  }

  const payload = {
    personalDetail: PayloadMappers.personalDetails(personalDetails),
    taxDetail: taxDetails,
    bankDetail: PayloadMappers.bankDetails(bankDetails),
    joint: Object.keys(joint).length ? joint : null,
  };

  const {
    data: { individualInvestmentId, individualInvestmentJointId },
  } = await services.addInvestmentEntityIndividual({
    payload,
    queryParams: { userId },
  });

  // Upload files
  if (personalDetails?.certificates?.length) {
    await handleUploadCertificates({
      id: individualInvestmentId,
      entityType: 'Individual',
      certificates: personalDetails.certificates,
    });
  }

  if (personalDetails?.joint?.certificates?.length) {
    await handleUploadCertificates({
      id: individualInvestmentJointId,
      entityType: 'Individual',
      certificates: personalDetails.joint.certificates,
    });
  }

  segmentTrack(SEGMENT_ANALYTICS.SUBMIT_INVESTMENT_ENTITY, {
    investmentEntityName: getFullName(personalDetails, {
      withMiddleName: true,
    }),
    investmentEntityType: 'Individual',
    email: userEmail,
  });
};
