import { useMemo, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const useFundCardHeaderStyles = ({
  cardHeights = {},
  cardMediaStyles = {},
  headerStyles = {},
  headerBgColor,
  chipStyles = () => ({}),
  disabled,
  width = '100%',
}) => {
  const isSM = useMediaQuery(
    (theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm'),
  );
  const isMD = useMediaQuery(
    (theme) => theme.breakpoints.up('sm') && theme.breakpoints.down('md'),
  );

  const cardHeightByBreakpoints = useMemo(
    () => ({
      sm: 'auto',
      md: '275px',
      default: headerStyles?.minHeight || '168px',
      ...cardHeights,
    }),
    [cardHeights, headerStyles?.minHeight],
  );

  const getCardHeight = useCallback(() => {
    switch (true) {
      case isSM:
        return cardHeightByBreakpoints.sm;
      case isMD:
        return cardHeightByBreakpoints.md;
      default:
        return cardHeightByBreakpoints.default;
    }
  }, [isSM, isMD, cardHeightByBreakpoints]);

  const cardMedia = useMemo(
    () => ({
      background: headerBgColor,
      height: '100%',
      maxWidth: '100%',
      ...cardMediaStyles,
    }),
    [cardMediaStyles],
  );
  const header = useMemo(
    () => ({
      position: 'relative',
      height: getCardHeight(),
      width,
      opacity: disabled && '0.2',
      ...headerStyles,
    }),
    [headerStyles, disabled, width, getCardHeight],
  );
  const chip = useMemo(
    () => ({
      marginLeft: !isSM ? '35px' : '10px',
      borderRadius: 'initial',
      padding: '5px',
      height: '24px',
      ...chipStyles(isSM),
    }),
    [chipStyles, isSM],
  );

  return {
    isSM,
    cardMedia,
    header,
    chip,
    getCardHeight,
  };
};

export default useFundCardHeaderStyles;
