import React, { memo, useMemo } from 'react';

import SummarySection from 'components/SummarySection';

import { SUMMARY_FIELDS_USER_COMPLETE_PROFILE } from './HelperFunction';

const Verification = ({ values }) => {
  const fields = useMemo(() => {
    const summaryFields = SUMMARY_FIELDS_USER_COMPLETE_PROFILE;

    if (values.checkedBy !== 'MANUAL_VERIFICATION')
      summaryFields.splice(3, summaryFields.length);

    return summaryFields;
  }, [values.checkedBy]);

  return (
    <SummarySection
      withEdit={false}
      title="Identity Verification Status"
      fields={fields}
      values={values}
    />
  );
};

export default memo(Verification);
