import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDivider from 'components/OptionDivider/FormDivider';
import Button from 'components/Button';
import TextButton from 'components/Button/TextButton';
import ShowMe from 'components/ShowMe';

import Container from './components/Container';

const AdminFormContainer = ({
  children,
  title,
  isSaveButtonDisabled,
  isLoading,
  isView,
  onCancel,
  onFormSubmit,
}) => (
  <form onSubmit={onFormSubmit}>
    <Grid style={{ marginBottom: !!title && '12px' }}>
      <Typography variant="display1">{title}</Typography>
    </Grid>
    <Container>
      {children}
      <FormDivider width="100%" />
      <ShowMe when={!isView}>
        <Grid container display="flex" justifyContent="end">
          <Grid item mr={8} display="flex" alignItems="center">
            <TextButton
              sx={{
                maxHeight: '19px',
                height: '100%',
              }}
              onClick={onCancel}
              variant="bodyGrey"
            >
              Cancel
            </TextButton>
          </Grid>
          <Grid item>
            <Button
              data-testid="button-save"
              buttoncolor="purpleGradient"
              buttonstyles={{
                maxHeight: '19px',
              }}
              disabled={isSaveButtonDisabled || isLoading}
              type="submit"
            >
              <Typography variant="bodyGrey" color="white">
                Save
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </ShowMe>
    </Container>
  </form>
);

export default memo(AdminFormContainer);
