import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DataTable from 'components/DataTable';

import {
  dateCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const headers = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'returnNet_3Month',
    headerName: '3 Months',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'returnNetYtd',
    headerName: 'YTD',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'returnNet_1Year',
    headerName: '1 Year',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'returnNet_3Year',
    headerName: '3 Years',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'returnNet_7Year',
    headerName: '7 Years',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'returnNet_10Year',
    headerName: '10 Years',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'returnNetSinceInception',
    headerName: 'Since Inception',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
];

const getFieldsWithValues = ({ data }) => {
  const fields = {};

  let fieldIndex = 0;
  let dataIndex = 0;

  while (fieldIndex < headers.length && dataIndex < data.length) {
    const { field } = headers[fieldIndex];
    const currentData = data[dataIndex][field];

    if (fields[field] === undefined) fields[field] = false;

    if (currentData) {
      fields[field] = !!currentData;
      fieldIndex += 1;
      dataIndex = 0;
    } else if (dataIndex <= data.length - 1) {
      fieldIndex += 1;
    }

    dataIndex += 1;
  }

  return fields;
};

const HistoricalValueList = ({ data = [] }) => {
  const columnVisibilityModel = useMemo(
    () => getFieldsWithValues({ data }),
    [data],
  );

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} alignItems="center" columnSpacing={2}>
        <Typography fontWeight="700 !important">Historical Values</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          headerData={headers}
          data={data}
          containerProps={{ backgroundColor: 'white' }}
          columnVisibilityModel={columnVisibilityModel}
        />
      </Grid>
    </Grid>
  );
};

export default HistoricalValueList;
