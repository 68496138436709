import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';

import Dropdown from 'components/Dropdown';
import FieldContainer from 'components/FieldContainer';

import ChartSection from './ChartSection';
import SummaryBlockSection from './SummaryBlocksSection';

const SummaryContent = ({
  index,
  readOnly,
  tabTitleOptions,
  onChange,
  values,
  onAddPieSection,
}) => {
  const handleChange = useCallback(
    (e) => {
      onChange({ index, fieldName: 'chart', e });
    },
    [onChange],
  );

  const handleAddPieSection = useCallback(() => {
    onAddPieSection({ index, fieldName: 'chart' });
  }, [onAddPieSection]);

  const handleChangeSummaryBlocks = useCallback(
    (e) => {
      onChange({ index, fieldName: 'summaryBlock', e });
    },
    [onChange],
  );

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <FieldContainer
          label="Visible on tab:"
          labelGridProps={{ xs: 2 }}
          childGridProps={{ xs: 4 }}
        >
          <Dropdown
            multiple
            withDeleteIcon
            name="visibleOnTabs"
            disabled={readOnly}
            value={values?.chart?.visibleOnTabs || []}
            options={tabTitleOptions}
            onChange={handleChange}
          />
        </FieldContainer>
      </Grid>

      {/* Chart Section */}
      <Grid item xs={12}>
        <ChartSection
          summaryIndex={index}
          readOnly={readOnly}
          values={values.chart}
          onAddPieSection={handleAddPieSection}
          onChange={handleChange}
        />
      </Grid>

      {/* Summary Blocks */}
      <Grid item xs={12}>
        <SummaryBlockSection
          summaryIndex={index}
          readOnly={readOnly}
          values={values.summaryBlock}
          onAddField={handleAddPieSection}
          onChange={handleChangeSummaryBlocks}
        />
      </Grid>
    </Grid>
  );
};

export default memo(SummaryContent);
