import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const CardBody = styled(Grid)(
  ({ theme, showborderbottom, padding, style = {} }) => ({
    padding: padding || '32px',
    borderBottom:
      showborderbottom !== 'false' &&
      !!showborderbottom &&
      `1px solid ${theme.palette.reach.blueBorder}`,
    background: theme.palette.reach.white,
    width: '100%',
    ...style,
  }),
);

export default CardBody;
