import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AdminPageContainer from 'components/AdminPageContainer';
import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import TabPanels from 'components/TabPanels';
import Tabs from 'components/Tabs';

import useTabs from 'hooks/useTabs';
import history from 'utils/history';

import {
  INVESTMENT_ENTITY_STATUS,
  INVESTMENT_ENTITY_STATUS_OPTIONS,
} from 'constants/investmentEntityStatus';

import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';

import InvestmentEntity from './InvestmentEntity';
import InvestmentFund from './InvestmentFund';
import FundRedemptionRequest from './FundRedemptionRequest';

const redirectToApprovalDetails = ({ row }) => {
  history.push(`/admin/approvals/details/${row.userInvestorMapId}`);
};

const redirectToApprovalFundDetails = ({ row }) => {
  history.push(`/admin/approvals/fund-details/${row.id}`);
};

const redirectToFundRedemptionRequestDetails = ({ row }) => {
  history.push(`/admin/approvals/fund-redemption-request-details/${row.id}`);
};

const tabs = [
  {
    key: 'investmentEntity',
    Icon: PersonIcon,
    label: <Typography variant="h6">Investment Entity</Typography>,
  },
  {
    key: 'investmentFund',
    Icon: PersonIcon,
    label: <Typography variant="h6">Investment Fund</Typography>,
  },
  {
    key: 'fundRedemptionRequest',
    Icon: PersonIcon,
    label: <Typography variant="h6">Fund Redemption Request</Typography>,
  },
];

const Approvals = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx: Math.max(
      tabs.findIndex((tab) => tab.key === searchParams.get('type')),
      0,
    ),
  });
  const { filter, setFilter } = useState();

  useEffect(() => {
    const newSearchParams = { type: tabs[activeTabIdx].key };
    setSearchParams(newSearchParams);
  }, [activeTabIdx]);

  const tabItems = useMemo(
    () => [
      {
        ...tabs[0],
        content: (
          <InvestmentEntity
            filter={filter}
            onRowClick={redirectToApprovalDetails}
          />
        ),
      },
      {
        ...tabs[1],
        content: (
          <InvestmentFund
            filter={filter}
            onRowClick={redirectToApprovalFundDetails}
          />
        ),
      },
      {
        ...tabs[2],
        content: (
          <FundRedemptionRequest
            filter={filter}
            onRowClick={redirectToFundRedemptionRequestDetails}
          />
        ),
      },
    ],
    [filter],
  );

  return (
    <AdminPageContainer
      pageTitle="Approvals"
      onSearch={setFilter}
      onFilter={(x) => x}
      filterValue={INVESTMENT_ENTITY_STATUS.ALL}
      filterOptions={INVESTMENT_ENTITY_STATUS_OPTIONS}
      testId="admin-approvals-container"
    >
      <Grid item xs={12}>
        <Tabs
          value={activeTabIdx}
          onChange={handleTabChange}
          centered={false}
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <TabComponents
            activeTabIdx={activeTabIdx}
            tabItems={tabItems}
            testIdPrefix="admin-approval-tab"
          />
        </Tabs>
      </Grid>
      <Grid item xs>
        <TabPanels
          value={activeTabIdx}
          tabItems={tabItems}
          boxPanelProps={{ sx: { padding: 0 } }}
        />
      </Grid>
    </AdminPageContainer>
  );
};

export default Approvals;
