import Services from 'api/services';
import PayloadMappers from 'utils/PayloadMappers';

const submitFundInvestment = async (formValues) => {
  const { fundId, ...values } = formValues;
  const payload = PayloadMappers.fundInvest(values);

  await Services.submitFundInvestment({ fundId, payload });
};

export default {
  submitFundInvestment,
};
