import React, { useCallback, useMemo } from 'react';

import DataTable from 'components/DataTable';
import TotalHeaders from 'components/TotalHeaders';

import useDataTables from 'hooks/useDataTables';
import services from 'api/services';
import history from 'utils/history';

import { PAYMENT_STATUS_OPTIONS } from 'constants/investmentEntity';
import { investmentFundHeaders } from 'containers/Admin/Approvals/HelperFunction';

import SearchFilterHeader from '../SearchFilterHeader';
import { getInvestmentFundTotalHeader } from './HelperFunction';

const InvestmentFundList = ({ onRowClick, userId, readOnly }) => {
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    defaultApiData: [],
    fetchService: () => services.adminGetFundPortfolios(userId),
  });
  const handleChangeSearch = useCallback(() => true, []);
  const handleChangeStatus = useCallback(() => true, []);
  const handleAddEntity = useCallback(
    () => history.push(`/admin/users/${userId}/add-investment-fund`),
    [userId],
  );

  const totalHeader = useMemo(
    () => getInvestmentFundTotalHeader(apiData.length),
    [apiData.length],
  );

  return (
    <div data-testid="investment-fund-container">
      <TotalHeaders
        totals={totalHeader}
        onClickAddItem={handleAddEntity}
        addItemLabel="Create Investment"
        disableExportBtn={readOnly}
        disabledAddItemBtn={readOnly}
      >
        <SearchFilterHeader
          statusOptions={PAYMENT_STATUS_OPTIONS}
          onChangeSearch={handleChangeSearch}
          onChangeStatus={handleChangeStatus}
        />
      </TotalHeaders>
      <DataTable
        height="656px"
        headerData={investmentFundHeaders}
        data={apiData}
        setSort={setSort}
        isLoading={isFetchLoading}
        totalData={apiData.length}
        onRowClick={onRowClick}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </div>
  );
};

export default InvestmentFundList;
