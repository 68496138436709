import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import PrevIcon from '@mui/icons-material/ChevronLeft';
import NextIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';

import condTrueFalse from 'utils/condTrueFalse';
import palette from 'theme/palette';
import InnerHtmlContainer from 'components/InnerHtmlContainer';
import ShowMe from 'components/ShowMe';

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  aspectRatio: '5/1',

  [theme.breakpoints.down('lg')]: {
    aspectRatio: '3/1',
  },

  [theme.breakpoints.down('sm')]: {
    aspectRatio: '2/1',
  },
}));

export const SlideContainer = styled('div')(() => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  transition: 'left ease-out 0.5s',
}));

const navBtnPosition = (left) => (left ? { left: '2%' } : { right: '2%' });

const NavButtonContainer = styled('div')(({ left }) => ({
  position: 'absolute',
  bottom: '12.5%',
  zIndex: 1,
  ...navBtnPosition(left),
}));

const NavButton = styled('button')(() => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  backgroundColor: 'rgb(191, 191, 191)',
  border: 0,
  height: 40,
  width: 40,
}));

export const Image = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'fill',
}));

const ImageCarousel = ({ images = [], autoSlide, srcKey }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  }, [images.length]);

  const handleNextClick = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
  }, [images.length]);

  useEffect(() => {
    if (!autoSlide) return () => true;

    const intervalId = setInterval(() => {
      handleNextClick();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [activeIndex]);

  const linearProgressValue = useMemo(
    () => (100 * activeIndex) / images.length,
    [activeIndex],
  );

  return (
    <Container>
      <LinearProgress
        variant="determinate"
        value={linearProgressValue}
        sx={{
          height: '8px',
          '& .MuiLinearProgress-barColorPrimary': {
            transform: `translateX(${linearProgressValue}%) !important`,
            left: `${
              linearProgressValue - linearProgressValue / images.length
            }%`,
            width: `${100 / images.length}%`,
          },
          backgroundColor: '#BFBFBF',
          '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate':
            {
              backgroundColor: '#4B4A80',
            },
        }}
      />
      <NavButtonContainer left="true">
        <NavButton type="button" onClick={handlePrevClick}>
          <PrevIcon
            fontSize="large"
            sx={{ height: '100%', color: palette.reach.purpleHaze }}
          />
        </NavButton>
      </NavButtonContainer>

      {images.map((image, index) => (
        <SlideContainer
          key={`${index.toString()}-${image?.content}`}
          style={{ left: `${(index - activeIndex) * 100}%` }}
        >
          <ShowMe when={condTrueFalse(srcKey, image[srcKey], image)}>
            <Image src={condTrueFalse(srcKey, image[srcKey], image)} />
          </ShowMe>
          <ShowMe when={image?.content}>
            <Grid position="absolute" left="6%" right="6%">
              <InnerHtmlContainer
                htmlData={image?.content}
                backgroundColor="transparent"
              />
            </Grid>
          </ShowMe>
        </SlideContainer>
      ))}

      <NavButtonContainer>
        <NavButton type="button" onClick={handleNextClick}>
          <NextIcon
            fontSize="large"
            sx={{ height: '100%', color: palette.reach.purpleHaze }}
          />
        </NavButton>
      </NavButtonContainer>
    </Container>
  );
};

export default ImageCarousel;
