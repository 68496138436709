import React from 'react';
import Grid from '@mui/material/Grid';

const ItemContainer = ({
  responsive,
  children,
  marginBottom = 0,
  childGridProps = {},
}) =>
  responsive ? (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      justifyContent="center"
      mb={marginBottom}
    >
      <Grid item xs={12} md={5} {...childGridProps}>
        {children}
      </Grid>
    </Grid>
  ) : (
    <Grid item xs={12} mb={marginBottom}>
      {children}
    </Grid>
  );

export default ItemContainer;
