import React, { useCallback, useMemo } from 'react';

import AdminPageContainer from 'components/AdminPageContainer';

import history from 'utils/history';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';

import {
  INVESTMENT_ENTITY_STATUS,
  INVESTMENT_ENTITY_STATUS_OPTIONS,
} from 'constants/investmentEntityStatus';

import TotalHeaders from 'components/TotalHeaders';
import DataTable from 'components/DataTable';
import SplitButton from 'components/SplitButton';

import Services from 'api/services';

import { FUND_CATEGORIES } from 'constants/funds';
import { ReactComponent as AddCircleIcon } from 'assets/icons/add-circle.svg';

import { fundHeaders, getFundTotalHeader } from './HelperFunction';

const addFundBtnOptions = [
  { label: 'Fund', redirect: '/admin/funds/add' },
  { label: 'Teaser', disabled: true },
  { label: 'Cash', redirect: `/admin/funds/add?type=${FUND_CATEGORIES.CASH}` },
];

const redirectToFundDetails = ({ row }) => {
  const type = row.isCashFund ? `?type=${FUND_CATEGORIES.CASH}` : '';
  history.push(`/admin/funds/${row.idFund}${type}`);
};

const Funds = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: Services.adminGetFunds,
    defaultApiData: {},
  });

  const handleClickAddFund = useCallback((e, index) => {
    history.push(addFundBtnOptions[index].redirect);
  }, []);

  const addButtonComponent = useMemo(
    () =>
      haveCrudPermission && (
        <SplitButton
          label="Add New Fund"
          options={addFundBtnOptions}
          onClick={handleClickAddFund}
        />
      ),
    [haveCrudPermission],
  );

  return (
    <AdminPageContainer
      pageTitle="Funds"
      onFilter={(x) => x}
      filterValue={INVESTMENT_ENTITY_STATUS.ALL}
      filterOptions={INVESTMENT_ENTITY_STATUS_OPTIONS}
      addEntityBtnText="Add New Fund"
      addEntityBtnIcon={AddCircleIcon}
      addButtonComponent={addButtonComponent}
    >
      <TotalHeaders
        exportLabel=""
        totals={getFundTotalHeader(apiData.totalItems)}
      />
      <DataTable
        wrapheader
        height="656px"
        headerData={fundHeaders}
        data={apiData.items}
        setSort={setSort}
        isLoading={isFetchLoading}
        totalData={apiData.totalItems}
        onRowClick={redirectToFundDetails}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </AdminPageContainer>
  );
};

export default Funds;
