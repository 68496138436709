import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';

import services from 'api/services';
import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import useDialog from 'hooks/useDialog';
import useAdminRestriction from 'hooks/useAdminRestriction';
import history from 'utils/history';
import DetailsPageContainer from 'components/DetailsPageContainer';

import HeaderInfos from './HeaderInfos';

const DetailsContainer = ({
  children,
  disableSaveDraftBtn,
  onBack,
  onChange,
  values,
  fundStatus = 'draft',
  backButtonLabel,
  onSaveDraft,
}) => {
  const { haveCrudPermission } = useAdminRestriction();

  const {
    isDialogOpen: dialogPublishOpen,
    handleOpenDialog: handleOpenPublishDialog,
    handleCloseDialog: handleClosePublishDialog,
  } = useDialog();
  const {
    isDialogOpen: dialogSaveDraftOpen,
    handleOpenDialog: handleOpenSaveDraftDialog,
    handleCloseDialog: handleCloseSaveDraftDialog,
  } = useDialog();
  const {
    isDialogOpen: dialogDeleteOpen,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
  } = useDialog();

  const handleDeleteEvent = useCallback(async () => {
    try {
      await services.adminDeleteEvents(values.eventUid);
      history.replace('/admin/articles-events');
    } catch (_) {
      // do nothing here
    }
  }, [values.eventUid]);

  const handePublishEvent = useCallback(async () => {
    try {
      await services.adminPublishEvents(values.eventUid);
      history.replace('/admin/articles-events');
    } catch (_) {
      // do nothing here
    }
  }, [values.eventUid]);

  const handleSaveDraftEvent = useCallback(async () => {
    try {
      if (onSaveDraft) {
        await onSaveDraft();
      }
    } finally {
      handleCloseSaveDraftDialog();
    }
  }, [values.id, onSaveDraft]);

  const handleRevertDraft = useCallback(() => {
    // handle revert draft here
  }, []);

  const handleDownload = useCallback(() => {
    // handle download here
  }, []);

  const handlePreview = useCallback(() => {
    // handle Preview here
  }, []);

  return (
    <>
      <DetailsPageContainer
        onBackButtonClick={onBack}
        backButtonLabel={backButtonLabel}
        status={fundStatus}
        onSaveDraft={handleOpenSaveDraftDialog}
        disableSaveDraftBtn={!haveCrudPermission || disableSaveDraftBtn}
        onDownloadClick={handleDownload}
        onCancel={onBack}
        onDelete={handleOpenDeleteDialog}
        deleteBtnDisabled={!haveCrudPermission || !values.eventUid}
        onRevertDraft={handleRevertDraft}
        onSubmitClick={handleOpenPublishDialog}
        submitBtnDisabled={!haveCrudPermission || disableSaveDraftBtn}
        submitBtnLabel="Publish"
        onPreviewClick={handlePreview}
      >
        <Grid container item spacing={2}>
          <HeaderInfos onChange={onChange} values={values} />
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DetailsPageContainer>
      {/* dialog */}
      <AdminConfirmationDialog
        title="Confirm Publish?"
        subtitle={<strong>Are you sure you want to publish this event?</strong>}
        open={dialogPublishOpen}
        onClose={handleClosePublishDialog}
        onConfirm={handePublishEvent}
        confirmButtonLabel="Yes"
        maxWidth="sm"
      />
      <AdminConfirmationDialog
        title="Confirm save as draft?"
        subtitle={<strong>Are you sure you want to save this draft?</strong>}
        open={dialogSaveDraftOpen}
        onClose={handleCloseSaveDraftDialog}
        onConfirm={handleSaveDraftEvent}
        confirmButtonLabel="Yes"
        maxWidth="sm"
      />
      <AdminConfirmationDialog
        title="Confirm Delete?"
        subtitle={<strong>Are you sure you want to delete this event?</strong>}
        open={dialogDeleteOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteEvent}
        confirmButtonLabel="Yes"
        maxWidth="sm"
      />
    </>
  );
};

export default memo(DetailsContainer);
