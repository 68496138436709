const setCursor = ({ value, newValue, event, maskInterval = 4 }) => {
  const { target } = event;
  const cursor = target.selectionStart;
  let newCursor = cursor;

  // Check if pointer should move 1 step because of the input mask.
  const isPointerBeforeMask = newCursor % (maskInterval + 1) === 0;

  if (target.isPaste || (!target.skipMaskPointer && isPointerBeforeMask))
    newCursor += 1;

  if (cursor && newValue !== value) {
    setTimeout(() => target.setSelectionRange(newCursor, newCursor), 10);
  }
};

const formatMaskedValue = ({
  value,
  mask = '-',
  maskInterval = 4,
  maxLength,
  isNumberType,
  event,
}) => {
  if (!value) return value;

  const numberRegex = isNumberType ? '|[^0-9]' : '';

  // Filter string.
  const replaceRegex = new RegExp(` |${mask}${numberRegex}`, 'g');

  // Group the string based on interval.
  const intervalRegex = new RegExp(`.{1,${maskInterval}}`, 'g');

  let newValue = value.replace(replaceRegex, '');

  if (!newValue) return newValue;

  newValue = newValue.match(intervalRegex).join(mask);

  if (maxLength) newValue = newValue.substr(0, maxLength);

  // Make `event` as optional props, so this function can reused by others.
  if (event) {
    setCursor({
      value,
      newValue,
      event,
      maskInterval,
    });
  }

  return newValue || '';
};

export default formatMaskedValue;
