import React, { memo } from 'react';

import Paper from '@mui/material/Paper';

const ContentContainer = ({ children, ...props }) => (
  <Paper elevation={3} sx={{ width: '100%' }} {...props}>
    {children}
  </Paper>
);

export default memo(ContentContainer);
