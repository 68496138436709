import React, { useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import Button from 'components/Button';
import GradientWrapper from 'components/FundCard/GradientWrapper';
import ShowMe from 'components/ShowMe';
import SecondaryButton from 'components/Button/SecondaryButton';

import useFundCardHeaderStyles from 'hooks/useFundCardHeaderStyles';
import condTrueFalse from 'utils/condTrueFalse';
import palette from 'theme/palette';

import FundCardHeaderImage from './FundCardHeaderImage';

const getMobileHeaderHeight = ({
  headerDesc,
  onClickInvest,
  onClickPortfolio,
}) => {
  if (headerDesc?.length < 260) return '150px';

  return `${
    (headerDesc?.length || 1) * ((headerDesc?.length || 1) / 350) +
    (onClickInvest ? 48 : 0) +
    (onClickPortfolio ? 48 : 0)
  }px`;
};

const FundCardHeader = ({
  disabled,
  headerBgColor,
  headerImageSrc,
  logoSrc,
  width,
  headerImageProps = {},
  headerTitle,
  headerDesc,
  onClickInvest,
  onClickPortfolio,
  onClickRedeem,
  investBtnProps = {},
  investBtnDisabled,
  useHeaderBgWrapper,
  isFundOpen = false,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    isSM,
    cardMedia: cardMediaStyles,
    header: headerStyles,
    chip: chipStyles,
    getCardHeight,
  } = useFundCardHeaderStyles({
    headerBgColor,
    disabled,
    width,
    headerStyles: {
      minHeight: condTrueFalse(
        isMobileScreen,
        getMobileHeaderHeight({ headerDesc, onClickPortfolio, onClickInvest }),
        headerDesc?.length > 225 ? '225px' : '200px',
      ),
      display: 'flex',
    },
    chipStyles: () => ({ marginLeft: {} }),
  });

  const HeaderBackgroundWrapper = useMemo(
    () => (useHeaderBgWrapper ? GradientWrapper : React.Fragment),
    [useHeaderBgWrapper],
  );

  return (
    <>
      <div style={headerStyles}>
        <Grid
          container
          sx={{
            position: 'absolute',
            padding: { xs: '14px', sm: '24px' },
            height: '100%',
            width: '100%',
            zIndex: 2,
            marginLeft: 0,
          }}
          columnSpacing={isMobileScreen ? 0 : 1}
        >
          <Grid
            container
            item
            xs={12}
            sm
            mt={1}
            display="flex"
            direction="column"
          >
            <div style={{ alignItems: 'center', display: 'flex' }}>
              {logoSrc && (
                <FundCardHeaderImage logoSrc={logoSrc} {...headerImageProps} />
              )}
              {(headerTitle || headerDesc) && (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    wordBreak: 'break-word',
                    marginLeft: { xs: 1, md: 2, lg: 5 },
                  }}
                >
                  <Typography>
                    <Chip
                      label={isFundOpen ? 'Open' : 'Closed'}
                      color={
                        disabled
                          ? 'fundStatusClosedChipColor'
                          : 'fundStatusOpenChipColor'
                      }
                      style={chipStyles}
                      sx={{ mb: { xs: 0.5, lg: 1 } }}
                    />
                  </Typography>
                  <Typography variant="cardTitle">{headerTitle}</Typography>
                  <Typography
                    sx={(muiTheme) => ({
                      color: muiTheme.palette.text.primary,
                      marginTop: '10px',
                    })}
                    variant="body"
                  >
                    {headerDesc}
                  </Typography>
                </Grid>
              )}
            </div>
          </Grid>
          <ShowMe when={!isSM}>
            <Grid
              container
              item
              xs={12}
              sm="auto"
              mt={isSM && 1.5}
              display="flex"
              direction="column"
              justifyContent="center"
            >
              <ShowMe when={!!onClickPortfolio}>
                <Button
                  onClick={onClickPortfolio}
                  buttonstyles={{
                    height: '40px',
                    maxHeight: '40px !important',
                    padding: '22px 20px 23px 20px',
                    marginBottom: '8px',
                  }}
                >
                  <Typography variant="bodyGrey" color="white">
                    <Grid
                      container
                      display="flex"
                      alignItems="center"
                      columnSpacing={1}
                    >
                      <Grid item sx={{ height: '24px' }}>
                        Fund Portfolio
                      </Grid>
                    </Grid>
                  </Typography>
                </Button>
              </ShowMe>
              <Grid container columnSpacing={onClickRedeem ? 2 : 0}>
                <ShowMe when={!!onClickRedeem}>
                  <Grid item xs={6}>
                    <SecondaryButton
                      variant="outlined"
                      sx={{ height: '100%', width: '100%' }}
                      onClick={onClickRedeem}
                    >
                      <Typography variant="smallStatic">Redeem</Typography>
                    </SecondaryButton>
                  </Grid>
                </ShowMe>
                <ShowMe when={onClickInvest}>
                  <Grid item xs={onClickRedeem ? 6 : 12}>
                    <Button
                      onClick={onClickInvest}
                      {...investBtnProps}
                      buttonstyles={{
                        maxHeight: '40px',
                        ...investBtnProps.buttonstyles,
                      }}
                      disabled={investBtnDisabled}
                    >
                      <Typography variant="bodyGrey" color="white">
                        <Grid
                          container
                          display="flex"
                          alignItems="center"
                          columnSpacing={1}
                        >
                          <Grid item sx={{ height: '24px' }}>
                            Invest
                          </Grid>
                        </Grid>
                      </Typography>
                    </Button>
                  </Grid>
                </ShowMe>
              </Grid>
            </Grid>
          </ShowMe>
        </Grid>
        <HeaderBackgroundWrapper
          {...condTrueFalse(
            useHeaderBgWrapper,
            { height: getCardHeight() },
            {},
          )}
        >
          <CardMedia
            component={headerImageSrc ? 'img' : 'div'}
            image={headerImageSrc}
            alt="fund-header-img"
            sx={cardMediaStyles}
          />
        </HeaderBackgroundWrapper>
      </div>

      <ShowMe when={isSM}>
        {onClickPortfolio && (
          <Button
            onClick={onClickPortfolio}
            buttonstyles={{
              // height: '50px',
              maxHeight: '50px !important',
              // padding: '22px 20px 23px 20px',
              marginTop: '8px',
            }}
          >
            <Typography variant="bodyStatic" color="white">
              <Grid
                container
                display="flex"
                alignItems="center"
                columnSpacing={1}
              >
                <Grid item sx={{ height: '24px' }}>
                  Fund Portfolio
                </Grid>
              </Grid>
            </Typography>
          </Button>
        )}
        {onClickInvest && (
          <Button
            onClick={onClickInvest}
            {...investBtnProps}
            buttonstyles={{
              maxHeight: '50px',
              marginTop: '8px',
              ...investBtnProps.buttonstyles,
              color: 'white',
              background: palette.reach.menuLink,
            }}
            disabled={investBtnDisabled}
          >
            <Typography variant="bodyStatic" color="white">
              <Grid
                container
                display="flex"
                alignItems="center"
                columnSpacing={1}
              >
                <Grid item sx={{ height: '24px' }}>
                  Invest
                </Grid>
              </Grid>
            </Typography>
          </Button>
        )}
      </ShowMe>
    </>
  );
};

export default FundCardHeader;
