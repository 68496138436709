import React from 'react';

import BankDetailsSummary from 'containers/AddInvestmentEntity/components/Forms/BankDetailsSummary';

import getNumbers from 'utils/strings/getNumbers';

const BankDetails = ({ onChange, ...props }) => {
  const handleChange = (newValue) => {
    onChange({
      bankDetail: {
        ...newValue,
        bsb: getNumbers(newValue.bsb, { convertToNumbers: false }).toString(),
      },
    });
  };

  return (
    <div data-testid="bank-details-content">
      <BankDetailsSummary
        {...props}
        setValues={handleChange}
        excludedFields={['bankCode']}
      />
    </div>
  );
};

export default BankDetails;
