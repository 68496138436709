const debounce = (callback, timeout = 300) => {
  let timer = null;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);

    return timer;
  };
};

export default debounce;
