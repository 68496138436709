import React, { useCallback, useMemo, useState } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AdminPageContainer from 'components/AdminPageContainer';
import DataTable from 'components/DataTable';
import Dropdown from 'components/Dropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import TotalHeaders from 'components/TotalHeaders';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';
import Services from 'api/services';

import { PERIOD_ALL, PERIOD_OPTIONS } from 'constants/general';
import { USER_STATUS } from 'constants/userStatus';
import {
  INVESTMENT_ENTITY_STATUS,
  INVESTMENT_ENTITY_STATUS_OPTIONS,
} from 'constants/investmentEntityStatus';

import {
  fetchAdminArticles,
  articleHeaders,
  getArticleTotalHeader,
} from './HelperFunction';

const redirectToArticleDetail = ({ row }) => {
  history.push(`/admin/articles/${row.articleUid}`, {
    onBackRoute: '/admin/articles-events',
  });
};

const routeToNewArticlePage = () => history.push('/admin/articles/add');

const ArticleSection = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [period, setPeriod] = useState(PERIOD_ALL);

  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: Services.adminGetArticles,
    defaultApiData: {},
  });

  return (
    <AdminPageContainer
      pageTitle="Articles"
      onFilter={(x) => x}
      filterValue={INVESTMENT_ENTITY_STATUS.ALL}
      filterOptions={INVESTMENT_ENTITY_STATUS_OPTIONS}
      addEntityBtnText="Add New Article"
      onAddEntity={routeToNewArticlePage}
    >
      <TotalHeaders
        exportLabel=""
        totals={getArticleTotalHeader(apiData.totalItems)}
      />
      <Grid item xs={12}>
        {isFetchLoading ? (
          <LoadingSpinner loading />
        ) : (
          <DataTable
            wrapheader
            hideFooter
            sortingMode="client"
            withCheckbox={false}
            pagination={false}
            height="300px"
            headerData={articleHeaders}
            data={apiData.items}
            setSort={setSort}
            isLoading={isFetchLoading}
            totalData={apiData.totalItems}
            containerProps={{ backgroundColor: 'white' }}
            onRowClick={redirectToArticleDetail}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        )}
      </Grid>
    </AdminPageContainer>
  );
};

export default ArticleSection;
