import { useCallback, useMemo, useState } from 'react';

const byteInMB = 1048576;

const isFileTypeValid = ({ fileType, accept }) =>
  fileType.includes(accept.replaceAll('*', ''));
const useUploadFiles = ({
  name,
  value,
  onChangeCallback,
  maxFiles,
  maxSize,
  maxFileNameLength,
  id,
  ref,
  newFileAttributes = {},
  onRemoveValidation = () => true,
  accept,
}) => {
  const [files, setFiles] = useState(value);

  const handleChangeFiles = useCallback(
    ({ newFiles, callCb = !!onChangeCallback }) => {
      setFiles(newFiles);

      if (callCb) {
        const event = {
          target: {
            name,
            value: newFiles,
          },
        };
        onChangeCallback(event);
      }
    },
    [onChangeCallback, setFiles, files],
  );

  const handleUpload = useCallback(
    (uploadEvent) => {
      const uploadedFiles = [...uploadEvent.target.files].slice(0, maxFiles);

      if (uploadedFiles?.length) {
        const newFiles = uploadedFiles.reduce((_newFiles, uploadedFile) => {
          if (
            (maxSize && uploadedFile.size > maxSize * byteInMB) ||
            !isFileTypeValid({ fileType: uploadedFile.type, accept })
          )
            return _newFiles;

          return _newFiles.concat({
            blob: new Blob([uploadedFile], { type: uploadedFile.type }),
            url: URL.createObjectURL(uploadedFile),
            fileName: uploadedFile.name,
            file: uploadedFile,
            ...newFileAttributes,
          });
        }, []);
        handleChangeFiles({ newFiles: [...files, ...newFiles] });
      }
    },
    [maxSize, maxFiles, files, setFiles, onChangeCallback, newFileAttributes],
  );

  const handleRemoveFile = useCallback(
    (_removedFile, removedIndex) => async () => {
      const newFiles = [...files]?.filter(
        (_file, index) => index !== removedIndex,
      );
      const fileToRemove = [...files].splice(removedIndex, 1);
      if (
        typeof onRemoveValidation === 'function' &&
        (await onRemoveValidation(fileToRemove))
      ) {
        handleChangeFiles({ newFiles });
      }

      const input = ref?.current || document.getElementById(id);

      if (input) {
        const dt = new DataTransfer();
        const { files: inputFiles } = input;

        Array.from(inputFiles).forEach((_, index) => {
          const file = inputFiles[index];
          if (removedIndex !== index) dt.items.add(file);
        });

        input.files = dt.files;
      }
    },
    [setFiles, files],
  );

  const getFileName = useCallback((fileName) => {
    if (fileName?.length > maxFileNameLength) {
      return `${fileName.slice(0, maxFileNameLength)}...${fileName.slice(-4)}`;
    }
    return fileName;
  }, []);

  const isDisabled = useMemo(() => files.length >= maxFiles, [maxFiles, files]);

  return {
    files,
    setFiles,
    handleChangeFiles,
    handleUpload,
    isDisabled,
    getFileName,
    handleRemoveFile,
  };
};

export default useUploadFiles;
