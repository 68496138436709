import React from 'react';

import Typography from '@mui/material/Typography';

import { ReactComponent as CorporateFareIcon } from 'assets/icons/corporate-fare.svg';
import { ReactComponent as PlaceIcon } from 'assets/icons/place.svg';
import { ReactComponent as AccountBoxIcon } from 'assets/icons/account-box.svg';
import { ReactComponent as ClassIcon } from 'assets/icons/class.svg';
import { ReactComponent as PercentIcon } from 'assets/icons/percent.svg';

import InitialValues from 'constants/InitialValues';

import PayloadMappers from 'utils/PayloadMappers';

import AccountantCertificateForm from 'containers/AddInvestmentEntity/components/Forms/AccountantCertificateForm';
import AddressForm from 'containers/AddInvestmentEntity/components/Forms/AddressForm';
import CompanyDetailsForm from 'containers/AddInvestmentEntity/components/Forms/CompanyDetailsForm';
import InvestorIdentificationForm from 'containers/AddInvestmentEntity/components/Forms/InvestorIdentificationForm';
import PersonalDetailsForm from 'containers/AddInvestmentEntity/components/Forms/PersonalDetailsForm';
import TaxDetailForm from 'containers/AddInvestmentEntity/components/Forms/TaxDetailForm';
import ShowMe from 'components/ShowMe';
import AutoPopulateChipList from 'components/AutoPopulateChipList';

import palette from 'theme/palette';

export const addEntityDialogItems = ({ type = '', formikProps, ...props }) => {
  const personalDetailsItem = ({
    label = 'Personal Details',
    title = 'Personal Details',
  } = {}) => ({
    key: 'personalDetails',
    Icon: CorporateFareIcon,
    label: (
      <Typography variant="body" color={palette.reach.inactiveGrey}>
        {label}
      </Typography>
    ),
    content: (
      <PersonalDetailsForm
        {...props}
        isJoint
        fieldDirection="row"
        title={title}
      />
    ),
  });

  const addressItem = ({ title = 'Address (Joint)' } = {}) => ({
    key: 'address',
    Icon: PlaceIcon,
    label: (
      <Typography variant="body" color={palette.reach.inactiveGrey}>
        Address
      </Typography>
    ),
    content: <AddressForm {...props} fieldDirection="row" title={title} />,
  });

  const identificationItem = ({
    title = 'Identification of Investor (Joint)',
  } = {}) => ({
    key: 'identification',
    Icon: AccountBoxIcon,
    label: (
      <Typography variant="body" color={palette.reach.inactiveGrey}>
        Identification
      </Typography>
    ),
    content: <InvestorIdentificationForm {...props} title={title} />,
  });

  const accountantCertificateItem = ({
    title = 'Accountant’s Certificate (Joint)',
  } = {}) => ({
    key: 'accountantCertificate',
    Icon: ClassIcon,
    label: (
      <Typography variant="body" color={palette.reach.inactiveGrey}>
        Accountant Certificate
      </Typography>
    ),
    content: <AccountantCertificateForm {...props} title={title} />,
  });

  const taxDetailItem = ({ title = 'Tax Details' } = {}) => ({
    key: 'taxDetails',
    Icon: PercentIcon,
    label: (
      <Typography variant="body" color={palette.reach.inactiveGrey}>
        Tax Details
      </Typography>
    ),
    content: (
      <TaxDetailForm
        {...formikProps}
        {...props}
        title={title}
        noOtherCountryTax
      />
    ),
  });

  const companyDetailsItem = {
    key: 'trusteeCompanyDetails',
    Icon: CorporateFareIcon,
    label: (
      <Typography variant="body" color={palette.reach.inactiveGrey}>
        Trustee Details
      </Typography>
    ),
    content: <CompanyDetailsForm {...props} title="Company Trustee Details" />,
  };
  switch (type) {
    case 'individual':
      return [
        personalDetailsItem(),
        addressItem(),
        identificationItem(),
        accountantCertificateItem(),
        taxDetailItem({ title: 'Joint Tax Details' }),
      ];
    case 'beneficialOwner':
      return [
        personalDetailsItem({
          label: 'Beneficial Details',
          title: 'Beneficial Owner Details',
        }),
        addressItem({ title: 'Address' }),
        identificationItem({ title: 'Identification of Investor' }),
      ];
    case 'trusteeIndividual':
      return [
        personalDetailsItem({
          label: 'Trustee Details',
          title: 'Individual Trustee Details',
        }),
        addressItem({ title: 'Individual Trustee Address' }),
        identificationItem({ title: 'Identification of Individual Trustee' }),
        accountantCertificateItem({
          title: "Individual Trustee's Accountant Certificate",
        }),
      ];
    case 'trusteeCompany':
      return [
        companyDetailsItem,
        addressItem({ title: 'Company Trustee Address' }),
        accountantCertificateItem({
          title: "Company Trustee's Accountant Certificate",
        }),
      ];
    case 'taxDetail':
      return [taxDetailItem({ title: '' })];
    default:
      return [];
  }
};

export const getCertificatesStatus = (
  currentCertificatesValue = [],
  newCertificatesValue = [],
) => {
  const addCertificates = newCertificatesValue?.filter(
    (certificate) => !!certificate.blob && !!certificate.file,
  );
  const deleteCertificates = currentCertificatesValue
    ?.filter(
      (certificate) =>
        !newCertificatesValue.find(
          (newCertificate) => newCertificate.url === certificate.url,
        ), // find deleted certificates
    )
    .map(({ originalFileName }) => originalFileName); // get their only names

  return {
    addCertificates,
    deleteCertificates,
  };
};

const selectPayloadMappersFromKey = (key) => {
  switch (key) {
    case 'companyBeneficialOwner':
    case 'personalDetail':
    case 'individualTrustee':
      return PayloadMappers.personalDetails;
    case 'companyDetail':
    case 'companyTrustee':
      return PayloadMappers.companyDetails;
    case 'trustDetail':
      return PayloadMappers.trustDetails;
    default:
      return (value) => value;
  }
};

const getTrusteeAddCertificatePayload = (values, key) => {
  switch (key) {
    case 'individualTrustee':
      return { individualDetail: values };
    case 'companyTrustee':
      return { companyDetail: values };
    default:
      return {};
  }
};

export const mapDetailsToPayload = ({
  oldValues,
  newValues,
  certificateKey = '',
  key = 'personalDetail',
  certificateURLKey = 'accountantCertificateURL',
}) => {
  const mapper = selectPayloadMappersFromKey(key);
  const { [certificateURLKey]: certificateURL = [], ...detailsValues } =
    newValues;
  const { addCertificates, deleteCertificates } = getCertificatesStatus(
    oldValues?.[certificateURLKey],
    certificateURL,
  );

  const modifyCertificates = !!(
    addCertificates?.length || deleteCertificates?.length
  );

  const payload = modifyCertificates
    ? {
        ...getTrusteeAddCertificatePayload(detailsValues, key),
        [`add${certificateKey}Certificates`]: addCertificates.map(
          ({ fileName }) => fileName,
        ),
        [`delete${certificateKey}Certificates`]: deleteCertificates,
      }
    : {
        [key]: {
          id: newValues?.id,
          ...mapper(detailsValues),
        },
      };
  return {
    addCertificates,
    payload,
    modifyCertificates,
  };
};

export const getInitialValuesByType = (type) => {
  if (type === 'trusteeCompany') return {};
  return {
    ...InitialValues.personalDetail,
  };
};

export const getAutoPopulatePersonalDetailsHeader = ({
  type,
  tabIndex,
  userDetailList = {},
  selectedPersonalDetailIndex = {},
  onSelectAutoPopulate,
}) => {
  const { recordedData, stepKey } = (() => {
    switch (type) {
      case 'individual':
      case 'beneficialOwner':
      case 'trusteeIndividual': {
        if (!tabIndex)
          return {
            recordedData: userDetailList.personals,
            stepKey: 'personals',
          };
        if (tabIndex === 1)
          return {
            recordedData: userDetailList.addresses,
            stepKey: 'addresses',
          };

        return { recordedData: null };
      }
      case 'trusteeCompany': {
        if (tabIndex === 1)
          return {
            recordedData: userDetailList.addresses,
            stepKey: 'addresses',
          };

        return { recordedData: null };
      }
      default: {
        return { recordedData: null };
      }
    }
  })();

  return (
    <ShowMe when={recordedData}>
      <AutoPopulateChipList
        items={recordedData}
        onClick={(index) =>
          onSelectAutoPopulate({ index, recordedData, stepKey })
        }
        selectedIndex={selectedPersonalDetailIndex[stepKey]}
      />
    </ShowMe>
  );
};
