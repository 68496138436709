import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import DetailsPageContainer from 'components/DetailsPageContainer';
import FieldContainer from 'components/FieldContainer';
import Footer from 'components/DetailsPageContainer/Footer';
import Input from 'components/Input';
import LoadingSpinner from 'components/LoadingSpinner';
import TextEditor from 'components/TextEditor';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import FailedDialog from 'components/Dialog/FailedDialog';

import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import useAdminRestriction from 'hooks/useAdminRestriction';
import history from 'utils/history';

import EmailTemplatePreviewDialog from './EmailTemplatePreviewDialog';
import {
  fetchAdminEmailTemplateDetails,
  fetchAdminEmailTemplatePreview,
  updateAdminEmailTemplate,
} from './HelperFunction';

const handleBack = () =>
  history.push('/admin/platform-configuration?tab=emailTemplates');

const EmailTemplateDetails = () => {
  const { id } = useParams();
  const { haveCrudPermission } = useAdminRestriction();
  const [isLoading, setIsLoading] = useState(false);
  const [emailPreview, setEmailPreview] = useState(false);
  const [error, setError] = useState('');
  const {
    isDialogOpen: isSuccessDialogOpen,
    handleOpenDialog: handleOpenSuccessDialog,
    handleCloseDialog: handleCloseSuccessDialog,
  } = useDialog({ onClose: handleBack });
  const {
    isDialogOpen: isPreviewDialogOpen,
    handleOpenDialog: handleOpenPreviewDialog,
    handleCloseDialog: handleClosePreviewDialog,
  } = useDialog();

  const { apiData } = useFetchApi({
    fetchService: () => fetchAdminEmailTemplateDetails(id),
    defaultApiData: {},
  });

  const formik = useFormik({
    initialValues: apiData,
    onSubmit: updateAdminEmailTemplate,
  });

  useEffect(() => {
    if (!apiData.id) return;

    formik.setValues({ ...apiData });
  }, [apiData.id]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await formik.submitForm();

      handleOpenSuccessDialog();
    } catch (err) {
      setError(err.response?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleResetError = useCallback(() => setError(''), []);

  const handlePreview = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await fetchAdminEmailTemplatePreview(id, formik.values);
      setEmailPreview(res.code);

      handleOpenPreviewDialog();
    } finally {
      setIsLoading(false);
    }
  }, [formik.values]);

  const footer = useMemo(
    () => (
      <Footer
        onCancel={handleBack}
        onSubmitClick={handleSubmit}
        onPreviewClick={handlePreview}
        submitBtnDisabled={isLoading || !haveCrudPermission}
        previewBtnDisabled={isLoading}
        rightActions={['preview', 'submit']}
      />
    ),
    [isLoading, handlePreview, haveCrudPermission],
  );

  if (!formik.values.id) return <LoadingSpinner />;

  return (
    <DetailsPageContainer
      onBackButtonClick={handleBack}
      onCancel={handleBack}
      backButtonLabel="Email Template"
      backButtonSubLabel={formik.values.title}
      status="live"
      footer={footer}
      childrenContainerProps={{ container: true, rowSpacing: 3 }}
    >
      <FieldContainer item label="title" variant="title">
        <Input
          autoFocus
          fullWidth
          data-testid="title-input"
          id="title"
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
          disabled
          // errorValue={touched.firstAddress && errors.firstAddress}
          // onBlur={onBlur}
        />
      </FieldContainer>
      <FieldContainer
        item
        label="Introduction"
        variant="title"
        childGridProps={{ sx: {} }}
      >
        <TextEditor
          id="email-template-introduction"
          onChange={formik.handleChange}
          value={formik.values.introduction}
          name="introduction"
          options={{ imageFileInput: false }}
        />
      </FieldContainer>
      <FieldContainer
        item
        label="Body"
        variant="title"
        childGridProps={{ sx: {} }}
      >
        <TextEditor
          id="email-template-body"
          onChange={formik.handleChange}
          value={formik.values.body}
          name="body"
          options={{ imageFileInput: false }}
        />
      </FieldContainer>
      <FieldContainer
        item
        label="Additional"
        variant="title"
        childGridProps={{ sx: {} }}
      >
        <TextEditor
          id="email-template-additional"
          onChange={formik.handleChange}
          value={formik.values.additional}
          name="additional"
          options={{ imageFileInput: false }}
        />
      </FieldContainer>
      <FieldContainer
        item
        label="Footer"
        variant="title"
        childGridProps={{ sx: {} }}
      >
        <TextEditor
          id="email-template-footer"
          onChange={formik.handleChange}
          value={formik.values.footer}
          name="footer"
          options={{ imageFileInput: false }}
        />
      </FieldContainer>

      <SuccessDialog
        open={isSuccessDialogOpen}
        onConfirm={handleCloseSuccessDialog}
        subtitle="Email template updated successfully!"
      />

      <FailedDialog
        open={!!error}
        onConfirm={handleResetError}
        title="Email template update failed"
        subtitle={error}
      />

      <EmailTemplatePreviewDialog
        open={isPreviewDialogOpen}
        onClose={handleClosePreviewDialog}
        emailPreview={emailPreview}
      />
    </DetailsPageContainer>
  );
};

export default EmailTemplateDetails;
