import React, { useCallback, useMemo, useState } from 'react';

import DataTable from 'components/DataTable';
import TotalHeaders from 'components/TotalHeaders';

import services from 'api/services';
import useDataTables from 'hooks/useDataTables';
import {
  ALL,
  INVESTMENT_ENTITY_STATUS_OPTIONS,
} from 'constants/investmentEntityStatus';
import condTrueFalse from 'utils/condTrueFalse';

import SearchFilterHeader from '../SearchFilterHeader';
import AddEntityDialog from './AddEntityDialog';
import {
  getInvestmentEntityTotalHeader,
  investmentEntityHeaders,
} from './HelperFunction';

const InvestmentEntityList = ({ onRowClick, userId, userData, readOnly }) => {
  const [openAddEntityDialog, setOpenAddEntityDialog] = useState(false);
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
    handleRefetch,
  } = useDataTables({
    defaultApiData: {},
    fetchService: () => services.adminGetUserEntityList(userId),
  });
  const handleChangeSearch = useCallback(() => true, []);
  const handleChangeStatus = useCallback(() => true, []);

  const handleAddEntity = useCallback(() => {
    setOpenAddEntityDialog(true);
  }, []);

  const handleCloseAddEntityDialog = useCallback(({ refetch } = {}) => {
    setOpenAddEntityDialog(false);

    condTrueFalse(refetch, handleRefetch);
  }, []);

  const totalHeader = useMemo(
    () => getInvestmentEntityTotalHeader(apiData.total),
    [apiData.total],
  );

  return (
    <div data-testid="investment-entity-container">
      <TotalHeaders
        totals={totalHeader}
        onClickAddItem={handleAddEntity}
        disabledAddItemBtn={apiData?.data?.length >= 10 || readOnly}
        disableExportBtn={readOnly}
      >
        <SearchFilterHeader
          statusValue={ALL}
          statusOptions={INVESTMENT_ENTITY_STATUS_OPTIONS}
          onChangeSearch={handleChangeSearch}
          onChangeStatus={handleChangeStatus}
        />
      </TotalHeaders>
      <DataTable
        height="656px"
        headerData={investmentEntityHeaders}
        data={apiData.data}
        setSort={setSort}
        isLoading={isFetchLoading}
        totalData={apiData.total}
        onRowClick={onRowClick}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />

      <AddEntityDialog
        open={openAddEntityDialog}
        onClose={handleCloseAddEntityDialog}
        userData={userData}
      />
    </div>
  );
};

export default InvestmentEntityList;
