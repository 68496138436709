import React, { useMemo } from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import SummarySection from 'components/SummarySection';
import mapValue from 'utils/mapValue';
import {
  SUMMARY_FIELDS_COMPANY_DETAILS,
  SUMMARY_FIELDS_ADDRESS,
  SUMMARY_FIELDS_ADDRESS_VIEW,
  SUMMARY_FIELDS_ACCOUNTANT,
} from 'constants/investmentEntity';

import companyPersonalDetailsValidations from 'validations/schema/addInvestment/company/companyPersonalDetailsValidationSchemas';

const CompanyDetailsSummary = ({
  setValues,
  values,
  withEdit = true,
  sectionListView = false,
  certificateDownloadable = false,
}) => {
  const remappedAccountantSummaryFields = useMemo(() => {
    if (!certificateDownloadable) return SUMMARY_FIELDS_ACCOUNTANT;
    return [
      {
        ...SUMMARY_FIELDS_ACCOUNTANT[0],
        fieldComponent: 'download',
        name: 'accountantCertificateURL',
        fieldDisplay: 'block',
        renderer: ({ value: certificateURLs }) =>
          certificateURLs.map((certificateUrl) => (
            <Link
              key={certificateUrl.url}
              href={mapValue(certificateUrl.url, '')}
              target="_blank"
              sx={{ display: 'block' }}
            >
              <Typography variant="linkFile">
                {certificateUrl.originalFileName}
              </Typography>
            </Link>
          )),
      },
    ];
  }, [certificateDownloadable]);

  return (
    <>
      <SummarySection
        title="Company Details"
        fields={SUMMARY_FIELDS_COMPANY_DETAILS}
        setValues={setValues}
        values={values}
        validationSchema={companyPersonalDetailsValidations[0]}
        formName="companyDetailsForm"
        withEdit={withEdit}
        sectionListView={sectionListView}
      />
      <SummarySection
        title="Company Address"
        fields={SUMMARY_FIELDS_ADDRESS}
        viewFields={SUMMARY_FIELDS_ADDRESS_VIEW}
        setValues={setValues}
        values={values}
        validationSchema={companyPersonalDetailsValidations[1]}
        formName="addressForm"
        withEdit={withEdit}
        sectionListView={sectionListView}
      />
      <SummarySection
        title="Accountant's certificate"
        fields={remappedAccountantSummaryFields}
        setValues={setValues}
        values={values}
        validationSchema={companyPersonalDetailsValidations[2]}
        formName="accountantForm"
        withEdit={withEdit}
        sectionListView={sectionListView}
      />
    </>
  );
};

export default CompanyDetailsSummary;
