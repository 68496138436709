import React, { useMemo } from 'react';

import Chip from '@mui/material/Chip';

import Accordion from 'components/Accordion';
import LabelWithChip from 'components/LabelWithChip';

import TaxDetailsSummary from 'containers/AddInvestmentEntity/components/Forms/TaxDetailsSummary';

import joinArrayStringWithSeparator from 'utils/strings/joinArrayStringWithSeparator';
import getCountryByCode from 'utils/getCountryByCode';

const IndividualTaxDetail = ({ values, setValues, ...props }) => {
  const Title = useMemo(() => {
    const name = joinArrayStringWithSeparator([
      values?.firstName,
      values?.middleName,
      values?.lastName,
    ]);
    const australiaTaxChip = values?.hasAustraliaTax ? (
      <Chip label="Australia" color="individual" sx={{ marginLeft: '15px' }} />
    ) : (
      ''
    );
    const otherCountryTaxChip = values?.hasOtherCountryTax ? (
      <Chip
        label={getCountryByCode(values?.otherCountryTax).label}
        color="individual"
        sx={{ marginLeft: '15px' }}
      />
    ) : (
      ''
    );

    return (
      <LabelWithChip
        label={name}
        chipLabel={values?.isJoint ? 'Joint' : 'Individual'}
        chipColor="individual"
      >
        {australiaTaxChip}
        {otherCountryTaxChip}
      </LabelWithChip>
    );
  }, []);

  return (
    <Accordion label={Title} labelVariant="cardNameLabel">
      <TaxDetailsSummary
        values={values}
        showTitle={false}
        isJoint={values?.isJoint}
        onChange={setValues}
        sectionListView
        {...props}
      />
    </Accordion>
  );
};

export default IndividualTaxDetail;
