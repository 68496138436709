import getNumbers from './getNumbers';

const thousandSeparator = (number, fractions) => {
  if (!number) return number;

  const addCommas = (num) =>
    num.toLocaleString('en-AU', {
      maximumFractionDigits: fractions || 4,
      minimumFractionDigits: fractions,
    });

  return addCommas(getNumbers(number));
};

export default thousandSeparator;
