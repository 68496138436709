import React, { memo } from 'react';

import Box from '@mui/material/Box';

const TabPanel = (props) => {
  const { children, value, index, boxPanelProps, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box {...boxPanelProps} sx={{ p: '24px 7px', ...boxPanelProps?.sx }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const TabPanels = ({
  value,
  tabItems,
  boxPanelProps,
  dependencyKey,
  ...props
}) =>
  tabItems.map(({ key, content }, index) => (
    <TabPanel
      key={`${key}-${dependencyKey}`}
      index={index}
      value={value}
      boxPanelProps={boxPanelProps}
    >
      {React.cloneElement(content, props[`${key}Props`])}
    </TabPanel>
  ));

export default memo(TabPanels);
