import { useState, useEffect, useCallback } from 'react';
import {
  PASSWORD_MIN_MAX_CHAR_VALIDATION_MSG,
  PASSWORD_MIN_LOWERCASE_VALIDATION_MSG,
  PASSWORD_MIN_UPPERCASE_VALIDATION_MSG,
  PASSWORD_MIN_TWO_NUMBERS_VALIDATION_MSG,
} from 'constants/registration';

// local functions
const getEachValidationResult = (errors, hint) =>
  !errors?.includes(hint.message);
const getMappedValidationResults = (
  validationResults,
  isValidValue = true,
  errors = [],
) =>
  validationResults.map((validationResult) => ({
    message: validationResult.message,
    isValid:
      typeof isValidValue === 'function'
        ? isValidValue(errors, validationResult)
        : isValidValue,
  }));

const initialValidationResults = [
  {
    message: PASSWORD_MIN_MAX_CHAR_VALIDATION_MSG,
    isValid: false,
  },
  {
    message: PASSWORD_MIN_TWO_NUMBERS_VALIDATION_MSG,
    isValid: false,
  },
  {
    message: PASSWORD_MIN_LOWERCASE_VALIDATION_MSG,
    isValid: false,
  },
  {
    message: PASSWORD_MIN_UPPERCASE_VALIDATION_MSG,
    isValid: false,
  },
];
const usePasswordValidationHints = ({ form = {}, value, validationSchema }) => {
  const [validationResults, setValidationResults] = useState(
    initialValidationResults,
  );

  const handlePasswordValidations = async (passwordValue) => {
    try {
      const passwordValidationSchema = validationSchema;
      // validate by yup to get all the latest errors left on password field
      const validated = await passwordValidationSchema.validate(
        { password: passwordValue },
        {
          abortEarly: false,
        },
      );

      // after validated, it will not send error (not going to the catch block)
      // and needs to be updated as well in here for the validationResults
      // so the latest validation hints result would be all green
      if (validated) {
        setValidationResults(getMappedValidationResults(validationResults));
      }
    } catch (err) {
      // update validation hints
      setValidationResults(
        getMappedValidationResults(
          validationResults,
          getEachValidationResult,
          err.errors,
        ),
      );
    }
  };

  const handleChangePassword = useCallback(
    (evt) => {
      form.handleChange(evt);
      handlePasswordValidations(evt?.target?.value);
    },
    [validationResults],
  );

  useEffect(() => {
    if (
      value &&
      JSON.stringify(initialValidationResults) ===
        JSON.stringify(validationResults)
    ) {
      handlePasswordValidations(value);
    }
  }, [value]);

  return {
    validationResults,
    setValidationResults,
    handlePasswordValidations,
    handleChangePassword,
  };
};
export default usePasswordValidationHints;
