import React, { memo } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const DialogComponent = ({
  dataTestKey,
  open,
  onClose,
  title,
  content,
  dialogActions,
  dialogTitleProps,
  dialogContentProps,
  dialogActionsProps,
  ...props
}) => (
  <Dialog
    data-testid={`${dataTestKey}-dialog`}
    open={open}
    onClose={onClose}
    maxWidth="md"
    {...props}
  >
    {title && (
      <DialogTitle
        minHeight={64}
        variant="h1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...dialogTitleProps}
      >
        {title}
      </DialogTitle>
    )}
    <DialogContent style={{ maxHeight: '400px' }} {...dialogContentProps}>
      {content}
    </DialogContent>
    {dialogActions && (
      <DialogActions
        {...dialogActionsProps}
        style={{ padding: '20px 24px', ...(dialogActionsProps?.style || {}) }}
      >
        {dialogActions}
      </DialogActions>
    )}
  </Dialog>
);

export default memo(DialogComponent);
