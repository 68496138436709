import React from 'react';

import Grid from '@mui/material/Grid';

import DocumentList from './DocumentList';

const DocumentFolderList = ({ folders }) => (
  <Grid container rowSpacing={4}>
    {folders.map(({ title, documents = [], subFolders = [] }, index) => (
      <>
        <Grid item xs={12}>
          <DocumentList
            key={`${index.toString()}-${title}`}
            documents={documents}
            folder={title}
          />
        </Grid>
        <Grid item xs={12}>
          {subFolders.map((subFolder, idx) => (
            <DocumentList
              subfolder
              key={`${index.toString()}-${idx.toString()}-${subFolder.title}`}
              documents={subFolder.documents}
              folder={subFolder.title}
            />
          ))}
        </Grid>
      </>
    ))}
  </Grid>
);

export default DocumentFolderList;
