import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from 'components/Chip';
import FieldContainer from 'components/FieldContainer';

const EntityDetail = ({ entityDetail }) => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Paper elevation={3}>
      <Grid container columnSpacing={2} p={3} rowSpacing={[1, 0]}>
        <Grid item xs={12} md="auto" alignSelf="center">
          <Typography variant="display5">Your Entity:</Typography>
        </Grid>
        <Grid item xs={12} sm={1} md="auto" textAlign="center">
          <Divider
            flexItem
            orientation={isSM ? 'horizontal' : 'vertical'}
            sx={{ display: ['block', 'inline-block'], height: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={7} md={3}>
          <FieldContainer label="Name">
            <Typography variant="display5">{entityDetail?.name}</Typography>
          </FieldContainer>
        </Grid>
        <Grid item xs={6} md={2}>
          <FieldContainer label="Type">
            <Typography variant="display5">
              {entityDetail?.investmentType}
            </Typography>
          </FieldContainer>
        </Grid>
        <Grid item xs={6} md={3}>
          <FieldContainer label="Country">
            <Typography variant="display5">{entityDetail?.country}</Typography>
          </FieldContainer>
        </Grid>
        <Grid item xs={6} md={1.5} lg="auto">
          <FieldContainer label="Status">
            <Chip
              label={entityDetail?.status}
              color={`${
                entityDetail?.status?.toLowerCase() || 'approved'
              }ChipColor`}
            />
          </FieldContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EntityDetail;
