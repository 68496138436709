import React, { useCallback } from 'react';

import InitialValues from 'constants/InitialValues';
import PayloadMappers from 'utils/PayloadMappers';
import addJointValidationSchema from 'validations/schema/adminUpdateEntity/addJointValidationSchema';

import { mapPayloadToTaxDetails } from 'containers/AddInvestmentEntity/Individual/Steps/TaxDetails/HelperFunction';

import { getCertificatesStatus } from './HelperFunction';
import AddEntityDialog from './AddEntityDialog';

const AddJointInvestorDialog = ({
  open,
  onClose,
  onSubmit,
  userId,
  ...props
}) => {
  const handleSubmit = useCallback((formValues) => {
    const { addCertificates } = getCertificatesStatus(
      undefined,
      formValues.certificates,
    );
    const personalDetail = PayloadMappers.personalDetails(formValues);
    const taxDetail = PayloadMappers.taxDetails(formValues);

    onSubmit({
      fileUpload: addCertificates,
      addJoint: {
        personalDetail,
        taxDetail,
      },
    });
  }, []);

  return (
    <AddEntityDialog
      open={open}
      onClose={onClose}
      title="Add Joint Investor"
      type="individual"
      onSubmit={handleSubmit}
      initialValues={{
        ...InitialValues.personalDetail,
        ...mapPayloadToTaxDetails({}),
      }}
      validationSchema={addJointValidationSchema}
      userId={userId}
      dialogProps={props}
    />
  );
};

export default AddJointInvestorDialog;
