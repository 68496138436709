import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormFields from 'containers/DashboardPage/components/CompleteProfileDialog/FormFields';
import FieldContainer from 'components/FieldContainer';

import condTrueFalse from 'utils/condTrueFalse';
import {
  REPORTING_TEMPLATE_CLOSED_END,
  REPORTING_TEMPLATE_OPEN_END,
} from 'constants/funds';

import {
  closedEndFormFields,
  closedEndSinceInceptionFields,
  openEndFormFields,
  openEndPerformanceReturnFields,
  validationSchemas,
} from './HelperFunction';

const Form = ({
  formId,
  onSubmit,
  setIsError,
  reportingTemplate = REPORTING_TEMPLATE_OPEN_END,
  selectedFundPerformance = {},
}) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: { date: null, ...selectedFundPerformance },
    onSubmit,
    validationSchema: validationSchemas[reportingTemplate],
    validateOnMount: true,
  });

  useEffect(() => setIsError(!!Object.keys(errors).length), [errors]);

  const { formFields, subFormFields } = useMemo(() => {
    if (reportingTemplate === REPORTING_TEMPLATE_CLOSED_END) {
      return {
        formFields: closedEndFormFields,
        subFormFields: closedEndSinceInceptionFields,
      };
    }

    return {
      formFields: openEndFormFields,
      subFormFields: openEndPerformanceReturnFields,
    };
  }, [reportingTemplate]);

  return (
    <form data-testid="capital-call-form" id={formId} onSubmit={handleSubmit}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="display3">Fund Update - Performance</Typography>
        </Grid>

        <Grid container item spacing={2}>
          {formFields.map(({ fieldProps, ...formField }, index) => (
            <FormFields
              key={`${formField.name}-${index.toString()}`}
              {...formField}
              fieldProps={{
                ...condTrueFalse(fieldProps, fieldProps, {}),
                'data-testid': `FormField-${formField.name}`,
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              errors={errors}
            />
          ))}
          {subFormFields.map(({ label, id, fields }) => (
            <FieldContainer
              key={id}
              container
              item
              xs={12}
              label={label}
              labelGridProps={{ xs: 2 }}
              childGridProps={{
                xs: 10,
                direction: 'row',
                container: true,
                columnSpacing: 3,
              }}
            >
              {fields.map(({ fieldProps, xs = true, ...formField }, index) => (
                <FormFields
                  key={formField.name}
                  {...formField}
                  fieldProps={{
                    ...condTrueFalse(fieldProps, fieldProps, {}),
                    'data-testid': `FormField-${formField.name}`,
                    fullWidth: false,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  errors={errors}
                  gridProps={{ xs, container: false }}
                />
              ))}
            </FieldContainer>
          ))}
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
