import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FundCardButton from 'components/FundCard/components/FundCardButton';
import ShowMe from 'components/ShowMe';

import config from 'utils/config';
import thousandSeparator from 'utils/strings/thousandSeparators';

const options = { year: 'numeric', month: 'long', day: 'numeric' };
const today = new Date();

const PortfolioSummaryCard = ({
  title,
  value = 0,
  btnOnClick,
  btnLabel,
  btnDisabled,
}) => (
  <Grid
    container
    alignContent="center"
    sx={{
      backgroundColor: config.system.appColours.cardBackground,
      borderTop: `8px ${config.system.appColours.border} solid`,
      transition: 'border-top .5',
      p: [2, 5],
    }}
  >
    <Grid
      container
      item
      xs={12}
      md={9}
      xl={9}
      alignContent="center"
      sx={{ placeContent: { xs: 'center', md: 'inherit' } }}
    >
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item xs={12} mb={3}>
        <Typography variant="display4">{`$${thousandSeparator(
          value,
        )}`}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6" fontSize={14}>
          {`as of ${today.toLocaleDateString('en-AU', options)}`}
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} md={3} xl={3} alignSelf="center">
      <ShowMe when={btnLabel && btnOnClick}>
        <FundCardButton
          disabled={btnDisabled}
          label={btnLabel}
          sx={{ width: '100%', maxWidth: '140px' }}
          onClick={btnOnClick}
        />
      </ShowMe>
    </Grid>
  </Grid>
);

export default memo(PortfolioSummaryCard);
