import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const InfoContainer = ({ content }) => (
  <Grid container>
    <Grid item>
      <Typography variant="bodyPurplehaze" fontFamily="PT Serif">
        {content.label}
      </Typography>
    </Grid>
    <Grid container item direction="column">
      {content.contents.map((info) => (
        <Grid item>
          <Typography variant="body" fontFamily="PT Serif">
            {info}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default memo(InfoContainer);
