import React, { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import Dropdown from 'components/Dropdown';

import {
  PortfolioFundList,
  getFundInvestments,
} from '../../PortfolioTab/HelperFunction';

const PendingTab = ({
  pendingInvestments = [],
  pendingFundRedemptions = [],
  userInvestmentEntities = [],
  selectedInvestmentEntity,
  onChangeInvestmentEntity,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const fundInvestments = useMemo(
    () => getFundInvestments({ data: pendingInvestments }),
    [pendingInvestments],
  );

  return (
    <Grid container data-testid="tab-item-pending" rowSpacing={3}>
      <Grid container item xs={12} alignItems="center" columnSpacing={2}>
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            Pending Investments
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider flexItem variant="middle" sx={{ mr: 0 }} />
        </Grid>
        <Grid item xs={2}>
          <Dropdown
            name="selectedInvestmentEntity"
            value={selectedInvestmentEntity}
            onChange={onChangeInvestmentEntity}
            placeholder="All Entity"
            options={userInvestmentEntities}
            maxheight="44px"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <PortfolioFundList
          funds={fundInvestments.cashFunds}
          isMobileScreen={isMobileScreen}
        />
      </Grid>
      <Grid item xs={12}>
        <PortfolioFundList
          funds={fundInvestments.funds}
          isMobileScreen={isMobileScreen}
        />
      </Grid>
      <Grid item xs={12}>
        <PortfolioFundList
          funds={pendingFundRedemptions}
          isMobileScreen={isMobileScreen}
          type="Redemption"
        />
      </Grid>
    </Grid>
  );
};

export default PendingTab;
