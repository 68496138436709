import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';

import AddCompanyInvestmentEntity from 'containers/AddInvestmentEntity/Company';
import AddIndividualInvestmentEntity from 'containers/AddInvestmentEntity/Individual';
import AddTrustInvestmentEntity from 'containers/AddInvestmentEntity/Trust';
import SelectInvestorType from 'containers/AddInvestmentEntity/SelectInvestorType';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import Dialog from 'components/Dialog';

import useDialog from 'hooks/useDialog';
import mapValue from 'utils/mapValue';
import getSelectedCountry from 'utils/getSelectedCountry';

import TrashIcon from 'assets/icons/trash.svg';
import { ENTITY_TYPES } from 'constants/investmentEntity';

const AddEntityDialog = ({ open, onClose, userData, ...props }) => {
  const [selectedEntityType, setSelectedEntityType] = useState('');
  const {
    isDialogOpen: openConfirmationDialog,
    handleOpenDialog: handleOpenConfirmationDialog,
    handleCloseDialog: handleCloseConfirmationDialog,
  } = useDialog();

  useEffect(() => {
    if (open) setSelectedEntityType('');
  }, [open]);

  const handleSelectType = useCallback((entityType) => {
    setSelectedEntityType(entityType?.key);
  }, []);

  const handleResetSelectedEntityType = useCallback(() => {
    setSelectedEntityType('');
  }, []);

  const handleClose = useCallback(({ refetch } = {}) => {
    handleCloseConfirmationDialog();
    onClose({ refetch });
  }, []);

  const handleSubmitSuccess = useCallback(() => {
    handleClose({ refetch: true });
  }, [handleClose]);

  const dialogContent = useMemo(() => {
    const user = {
      firstName: userData?.firstName,
      middleName: mapValue(userData?.middleName, ''),
      lastName: userData?.lastName,
      id: userData?.userId,
    };

    switch (selectedEntityType) {
      case ENTITY_TYPES.INDIVIDUAL: {
        const initialValues = {
          userId: userData?.userId,
          personalDetails: {
            email: userData?.email,
            ...user,
            phoneNumber: userData?.parsedMobileNumber?.number,
            countryCode: getSelectedCountry(userData?.parsedMobileNumber?.code),
          },
        };

        return (
          <AddIndividualInvestmentEntity
            onBackInFirstPage={handleResetSelectedEntityType}
            initialValues={initialValues}
            onSubmitSuccess={handleSubmitSuccess}
            user={user}
          />
        );
      }
      case ENTITY_TYPES.COMPANY: {
        const initialValues = {
          userId: userData?.userId,
        };

        return (
          <AddCompanyInvestmentEntity
            onBackInFirstPage={handleResetSelectedEntityType}
            initialValues={initialValues}
            onSubmitSuccess={handleSubmitSuccess}
            user={user}
          />
        );
      }
      case ENTITY_TYPES.TRUST: {
        const initialValues = {
          userId: userData?.userId,
        };

        return (
          <AddTrustInvestmentEntity
            onBackInFirstPage={handleResetSelectedEntityType}
            initialValues={initialValues}
            onSubmitSuccess={handleSubmitSuccess}
            user={user}
          />
        );
      }
      default:
        return (
          <SelectInvestorType
            onBack={onClose}
            onSelectType={handleSelectType}
          />
        );
    }
  }, [selectedEntityType]);

  const ConfirmationDialogContent = (
    <Typography variant="bodyLead">
      This will discard <b>ALL</b> unsaved data,
      <br />
      Do you want to continue?
    </Typography>
  );

  return (
    <>
      <Dialog
        content={dialogContent}
        maxWidth="lg"
        dialogContentProps={{
          style: {
            maxHeight: '700px',
          },
        }}
        open={open}
        onClose={handleOpenConfirmationDialog}
        {...props}
      />

      <ConfirmationDialog
        open={openConfirmationDialog}
        title="Warning!"
        subtitle="Are you sure want to cancel this process"
        icon={TrashIcon}
        contentText={ConfirmationDialogContent}
        infoText="This action cannot be undone."
        onClose={handleCloseConfirmationDialog}
        onConfirm={handleClose}
      />
    </>
  );
};

export default AddEntityDialog;
