import React from 'react';
import Typography from '@mui/material/Typography';

import services from 'api/services';

import TransactionsTab from './TransactionsTab';
import PendingTab from './PendingTab';

export const transactionsTabItems = ({ ...props }) => [
  {
    key: 'transactions',
    label: <Typography variant="body">Transactions</Typography>,
    content: <TransactionsTab valueKey="transactions" {...props} />,
  },
  {
    key: 'pending',
    label: <Typography variant="body">Pending</Typography>,
    content: <PendingTab valueKey="pending" {...props} />,
  },
];

export const fetchTransactions = async (investmentEntityId) => {
  try {
    const res = investmentEntityId
      ? await services.getPortfolioPaymentsByInvestmentEntityId(
          investmentEntityId,
        )
      : await services.getInvestedPortofolios();
    return res;
  } catch (_) {
    return {};
  }
};

export const fetchPendingInvestments = async () => {
  const res = await services.getPortfolioPendingPaymentsByInvestmentEntityId();
  return res?.data;
};

export const fetchPendingFundRedemptions = async () => {
  const res = await services.getPendingFundRedemptionRequest();
  return res?.data;
};

export const fetchPendingTransactions = async () => {
  try {
    const pendingInvestments = await fetchPendingInvestments();
    const pendingFundRedemptions = await fetchPendingFundRedemptions();

    return {
      data: {
        pendingInvestments,
        pendingFundRedemptions,
      },
    };
  } catch (_) {
    return {};
  }
};
