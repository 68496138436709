import React from 'react';
import BankDetail from './BankDetail';
import CompanyDetail from './CompanyDetail';
import CompanyTaxDetailWithAccordion from './CompanyTaxDetailWithAccordion';
import PersonList from './PersonList';

const CompanyDetails = ({ values, ...props }) => (
  <>
    <CompanyDetail values={values.detail?.companyDetail} />
    <PersonList
      values={values.detail?.beneficialOwners}
      listLabel="Beneficial Owners"
      {...props}
    />
    <CompanyTaxDetailWithAccordion values={values.detail} {...props} />
    <BankDetail values={values.detail?.bankDetail} {...props} />
  </>
);

export default CompanyDetails;
