const initial = {
  auth: {
    user: {},
    isAuthenticated: false,
  },
  drawer: {
    open: false,
  },
  general: {
    editingSummary: false,
    sidebar: false,
    colours: {
      appBackground: '#FFFFFF',
      appCardBackground: '#FAFAFA',
      appBorder: 'black',
    },
    currencyData: {},
  },
};

export default initial;
