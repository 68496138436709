import React, { useEffect, useMemo } from 'react';

import FlipCountdown from '@rumess/react-flip-countdown';
import { GlobalStyles } from '@mui/material';
import { getDateDifference } from 'utils/date';

const CountdownFlip = ({
  hideYear = true,
  hideMonth = true,
  hideSecond = true,
  size = 'medium',
  theme = 'light',
  titlePosition = 'bottom',
  countdownAlign = 'left',
  titleAlign = 'center',
  customSize = '', // in font size
  startAt,
  endAt,
  ...props
}) => {
  const endCountdown = useMemo(() => {
    if (!startAt) {
      return endAt;
    }
    const startDate = new Date(startAt).setHours(0, 0, 0);
    const endDate = new Date(endAt).setHours(23, 59, 59);
    const toStartDate = new Date().getTime();

    return new Date(toStartDate + (endDate - startDate));
  }, [endAt, startAt]);

  useEffect(() => {
    const dateDifference = Math.floor(getDateDifference({ endAt, startAt }));

    if (
      !endCountdown ||
      endCountdown === 'Invalid Date' ||
      dateDifference < 10 ||
      Number.isNaN(dateDifference)
    )
      return;

    // Get Flip Countdown Card Element.
    const flipCountdownCard = document.querySelector(
      '.flip-countdown-card-sec.one',
    );
    const flipCountdownCardDateUnit = document.querySelector(
      '.flip-countdown-card-sec.two',
    );
    const flipCountdownContainer = document.querySelector(
      '.flip-countdown-card',
    );

    // Remove Hundreds Flip Countdown Card If exist.
    const hundredsFlipCountdownCard = flipCountdownContainer.querySelector(
      '.flip-countdown-card-sec.zero',
    );

    if (hundredsFlipCountdownCard) {
      hundredsFlipCountdownCard.remove();
    }

    // If not found / Hundreds Flip Countdown Card already found, exit.
    if (
      !flipCountdownCard ||
      !flipCountdownCardDateUnit ||
      !flipCountdownContainer
    )
      return;

    // Need to manually set new Date tens of Flip Countdown Card,
    // because it will reset to 0 after 30.
    const dateTens = Math.floor((dateDifference % 100) / 10);

    // Clone Date Tens Unit Card into new Element,
    // to prevent overrides from the actual value.
    const newDateTensCard = flipCountdownCard.cloneNode(true);

    newDateTensCard.firstChild.textContent = dateTens;
    newDateTensCard.lastChild.firstChild.setAttribute('data-value', dateTens);

    // Remove original Date Tens Unit Card
    flipCountdownCard.remove();

    // Append new Date Tens Unit Card with new Value.
    flipCountdownContainer.prepend(newDateTensCard);

    // Date Units
    let dateUnit = dateDifference % (dateTens * 10);

    // If date difference above 99, set Date Hundreds Value manually.
    if (dateDifference > 99) {
      // Set Flip Card for hundreds date.
      const dateHundreds = Math.floor(dateDifference / 100);
      const additionalFlipCard = flipCountdownCard.cloneNode(true);

      additionalFlipCard.classList.remove('one');
      additionalFlipCard.classList.add('zero');
      additionalFlipCard.firstChild.textContent = dateHundreds;
      additionalFlipCard.lastChild.firstChild.setAttribute(
        'data-value',
        dateHundreds,
      );

      // Prepend the Hundreds Flip Countdown Card.
      flipCountdownContainer.prepend(additionalFlipCard);

      dateUnit = (dateDifference % 100) % 10;
    }

    // Clone Date Unit Card into new Element,
    // to prevent overrides from the actual value.
    const newDateCard = flipCountdownCardDateUnit.cloneNode(true);

    newDateCard.firstChild.textContent = dateUnit;
    newDateCard.lastChild.firstChild.setAttribute('data-value', dateUnit);

    // Remove original Date Unit Card
    flipCountdownCardDateUnit.remove();

    // Append new Date Unit Card with new Value.
    flipCountdownContainer.append(newDateCard);
  }, [endCountdown]);

  return (
    <>
      <GlobalStyles
        styles={{
          '& .flip-countdown.theme-light': {
            '--text-color': 'black',
            '--text-bottom-color': 'black',
          },
          '& .flip-countdown': {
            'text-align': countdownAlign,
          },
          '& .flip-countdown-piece': {
            'text-align': titleAlign,
          },
          ...(customSize
            ? {
                '& .flip-countdown-card-sec': {
                  'font-size': `${customSize} !important`,
                },
                '& .flip-countdown-title': {
                  'font-size': `calc(${customSize}/2) !important`,
                },
              }
            : {}),
        }}
      />
      <FlipCountdown
        endAtZero
        titlePosition={titlePosition}
        size={size}
        theme={theme}
        hideYear={hideYear}
        hideMonth={hideMonth}
        hideSecond={hideSecond}
        endAt={endCountdown}
        {...props}
      />
    </>
  );
};

export default CountdownFlip;
