import React, { memo } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import Dropdown from 'components/Dropdown';
import FormTitle from 'components/FormTitle';
import InputField from 'components/Input';
import ShowMe from 'components/ShowMe';
import TextEditor from 'components/TextEditor';

import countryOptions from 'utils/countryOptions';
import { REGION_OPTIONS } from 'constants/general';
import { FUND_TYPE_OPTIONS } from 'constants/funds';

const Overview = ({
  readOnly,
  onChange,
  isCashFund,
  values,
  errors = {},
  onBlur,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Overview" />
    </Grid>

    <Grid container item xs={12} p={2} rowSpacing={3}>
      <Grid container item alignItems="center">
        <Grid item xs={2}>
          <FormLabel>Fund Name</FormLabel>
        </Grid>
        <Grid container item xs spacing={2}>
          <Grid item xs={6}>
            <InputField
              fullWidth
              data-testid="fund-name-input"
              id="fundName"
              name="fundName"
              placeholder="Fund Name"
              disabled={readOnly}
              onChange={onChange}
              onBlur={onBlur}
              value={values.fundName}
              error={!!errors.fundName}
              errorValue={errors.fundName}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Grid>
      <Grid container item alignItems="center">
        <Grid item xs={2}>
          <FormLabel>Type of Fund</FormLabel>
        </Grid>
        <Grid container item xs spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              name="type"
              placeholder="Fund Type"
              options={FUND_TYPE_OPTIONS}
              disabled={readOnly}
              onChange={onChange}
              value={values.type}
              error={!!errors.type}
              errorValue={errors.type}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Grid>
      <Grid container item alignItems="center">
        <Grid item xs={2}>
          <FormLabel>Location</FormLabel>
        </Grid>
        <Grid container item xs spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              name="region"
              placeholder="Region"
              options={REGION_OPTIONS}
              disabled={readOnly}
              onChange={onChange}
              value={values.region}
              error={!!errors.region}
              errorValue={errors.region}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name="country"
              placeholder="Country"
              options={countryOptions(values.region)}
              disabled={readOnly}
              onChange={onChange}
              value={values.country}
              error={!!errors.country}
            />
          </Grid>
        </Grid>
      </Grid>
      <ShowMe when={isCashFund}>
        <Grid container item alignItems="center">
          <Grid item xs={2}>
            <FormLabel>Overview</FormLabel>
          </Grid>
          <Grid container item xs>
            <Grid item xs={12}>
              <TextEditor
                id="fundInformation-overview"
                onChange={onChange}
                value={values.overview}
                name="overview"
              />
            </Grid>
          </Grid>
        </Grid>
      </ShowMe>
      <Grid container item alignItems="center">
        <Grid item xs={2}>
          <FormLabel>Summary</FormLabel>
        </Grid>
        <Grid container item xs>
          <Grid item xs>
            <InputField
              fullWidth
              multiline
              id="summary"
              name="summary"
              data-testid="summary-input"
              rows={4}
              disabled={readOnly}
              onBlur={onBlur}
              onChange={onChange}
              value={values.summary}
              placeholder="Fund Summary (300 characters max)"
              inputProps={{
                maxLength: 300,
              }}
              error={!!errors.summary}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default memo(Overview);
