import services from 'api/services';

import { handleUploadCertificates } from 'containers/AddInvestmentEntity/Individual/HelperFunction';

import condTrueFalse from 'utils/condTrueFalse';
import PayloadMappers from 'utils/PayloadMappers';
import trimMaskInput from 'utils/trimMaskInput';
import segmentTrack from 'hooks/useSegmentAnalytic/segmentTrack';

import { TAX_CATEGORY } from 'constants/investmentEntity';
import { SEGMENT_ANALYTICS } from 'constants/segmentAnalytics';

const getCompanyIdentifier = (companyDetails) => {
  if (companyDetails?.country === 'AU') {
    return companyDetails?.australianTaxNumber || '';
  }
  if (companyDetails?.country === 'NZ') {
    return companyDetails?.newZealandTaxNumber || '';
  }
  return companyDetails?.otherTaxNumber || '';
};

export const mapCompanyDetailsPayload = (companyDetails) => ({
  companyName: companyDetails?.companyName || '',
  registeredCountry: companyDetails?.country || '',
  companyIdentifier: trimMaskInput(getCompanyIdentifier(companyDetails)),
  isPublicCompany:
    companyDetails?.isPublicCompany !== undefined
      ? companyDetails?.isPublicCompany
      : false,
  isCharity:
    companyDetails?.isNonProfitable !== undefined
      ? companyDetails?.isNonProfitable
      : false,
  address1: companyDetails?.firstAddress || '',
  address2: companyDetails?.secondAddress || '',
  suburb: companyDetails?.suburb || '',
  city: companyDetails?.city || '',
  state: companyDetails?.state || '',
  postcode: companyDetails?.postCode || '',
  country: companyDetails?.addressCountry || '',
});

export const handleAddCompanyEntity = async ({
  formValues,
  cb,
  setLoading,
  userEmail,
}) => {
  const { companyDetails, taxDetails, bankDetails, beneficialOwners, userId } =
    formValues;

  try {
    const payload = {
      companyDetails: mapCompanyDetailsPayload(companyDetails),
      taxDetails: PayloadMappers.taxDetailPurposes({
        taxDetails,
        signedBy: taxDetails.signedBy,
        signedAt: taxDetails.signedAt,
        isConfirmed: taxDetails.isConfirmed,
        taxCategory: TAX_CATEGORY.COMPANY,
      }),
      bankDetails: PayloadMappers.bankDetails(bankDetails),
      beneficialOwners: beneficialOwners?.map((beneficialOwner) =>
        PayloadMappers.personalDetails(beneficialOwner),
      ),
    };

    const {
      data: { companyInvestmentId },
    } = await services.addInvestmentEntityCompany({
      payload,
      queryParams: { userId },
    });

    // Upload files
    if (companyDetails?.certificates?.length) {
      await handleUploadCertificates({
        id: companyInvestmentId,
        entityType: 'Company',
        certificates: companyDetails.certificates,
      });
    }

    segmentTrack(SEGMENT_ANALYTICS.SUBMIT_INVESTMENT_ENTITY, {
      investmentEntityName: companyDetails?.companyName,
      investmentEntityType: 'Company',
      email: userEmail,
    });
  } finally {
    condTrueFalse(setLoading, () => setLoading(false));

    condTrueFalse(cb, cb);
  }
};
