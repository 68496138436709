import React, { memo } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

const FailedDialog = ({
  open,
  title = 'Failed !',
  subtitle = 'Data submission failed',
  infoText = '',
  onConfirm,
  ...props
}) => (
  <ConfirmationDialog
    open={open}
    title={title}
    subtitle={subtitle}
    infoText={infoText}
    confirmButtonLabel="OK"
    onConfirm={onConfirm}
    subtitleProps={{ variant: 'display6' }}
    confirmButtonContainerProps={{ lg: true }}
    {...props}
  />
);

export default memo(FailedDialog);
