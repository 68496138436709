import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CountdownTimer from 'components/CountdownTimer';
import RedirectLoginButton from 'components/Button/RedirectLoginButton';
import TextButton from 'components/Button/TextButton';

const LinkExpiredContainer = ({
  context,
  expiredMessage,
  handleResendEmail,
  expirationTime = '8 hours',
  showResendButton = true,
  showBackToLoginButton = false,
  subtitle = 'To protect your account, activation links expire after ',
}) => (
  <Grid
    container
    spacing={3}
    direction="row"
    style={{
      maxHeight: '-webkit-fill-available',
      textAlign: '-webkit-center',
    }}
  >
    <Grid item xs={12}>
      <Typography variant="display4">{expiredMessage}</Typography>
    </Grid>

    <Grid item xs={12}>
      <Typography
        variant="body2"
        style={{
          color: '#7C8189',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19px',
        }}
      >
        {subtitle} {expirationTime}.
      </Typography>
    </Grid>

    {showResendButton && (
      <Grid item xs={12}>
        <CountdownTimer
          timer={90}
          onClick={handleResendEmail}
          typographyVariant="buttonTextLightSerif"
          label={`Resend ${context} email`}
          initialTimer="0"
          direction="column"
        />
      </Grid>
    )}

    {showBackToLoginButton && (
      <Grid item xs={12}>
        <RedirectLoginButton
          component={TextButton}
          fullWidth={false}
          label="Back to Login"
        />
      </Grid>
    )}
  </Grid>
);

export default memo(LinkExpiredContainer);
