import React from 'react';

import { ENTITY_TYPES } from 'constants/investmentEntity';
import condTrueFalse from 'utils/condTrueFalse';

import Accordion from 'components/Accordion';
import LabelWithChip from 'components/LabelWithChip';

import PersonDetail from './PersonDetail';

const PersonList = ({
  values = [],
  listLabel,
  detailTitle,
  isBeneficialOwner = true,
  type,
  withChip = true,
  ...props
}) => (
  <Accordion
    label={
      <LabelWithChip
        label={listLabel}
        chipLabel={values.length}
        chipColor="trusteeCompanyChipColor"
      />
    }
    labelVariant="cardNameLabel"
  >
    {values.map((beneficialOwner) => (
      <PersonDetail
        withChip={withChip && !isBeneficialOwner}
        chipText={condTrueFalse(
          type === ENTITY_TYPES.INDIVIDUAL,
          beneficialOwner?.label,
          undefined,
        )}
        values={beneficialOwner}
        title={detailTitle}
        key={`person-detail-${beneficialOwner?.id}`}
        isBeneficialOwner={isBeneficialOwner}
        chipColor="individual"
        {...props}
      />
    ))}
  </Accordion>
);

export default PersonList;
