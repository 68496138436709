import React, { useEffect, useState } from 'react';

import BankDetails from 'containers/AddInvestmentEntity/components/BankDetails';
import Loading from 'components/Loading';
import FailedDialog from 'components/Dialog/FailedDialog';
import { useStores } from 'components/StateProvider';

import useSteps from 'hooks/useSteps';
import useUserDetailListData from 'hooks/useUserDetailListData';
import condTrueFalse from 'utils/condTrueFalse';

import PersonalDetails from './Steps/PersonalDetails';
import TaxDetails from './Steps/TaxDetails';
import InvestmentSuccess from '../components/InvestmentSuccess';
import { handleAddEntity } from './HelperFunction';

const steps = [
  {
    label: 'Personal Details',
    component: <PersonalDetails />,
  },
  {
    label: 'Tax Details',
    component: <TaxDetails />,
  },
  {
    label: 'Bank Details',
    component: <BankDetails activeStep={2} />,
  },
  {
    label: 'Success',
    component: <InvestmentSuccess />,
  },
];

const stepComponents = steps.map((step) => step.component);
const stepLabels = steps
  .filter((step) => step.label !== 'Success')
  .map((step) => ({ label: step.label }));

const AddIndividualInvestmentEntity = ({
  onBackInFirstPage,
  initialValues = {},
  onSubmitSuccess,
  user,
}) => {
  const {
    auth: {
      user: { id: userId, email: userEmail },
    },
  } = useStores();
  const [startAtLastStep, setStartAtLastStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { userDetailList } = useUserDetailListData({
    userId: user?.id || userId,
  });

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: individualEntityForm,
  } = useSteps({
    stepComponents,
    formParameters: {
      initialValues: { ...initialValues, userEmail },
      onSubmit: handleAddEntity,
    },
    firstStepGoBackURL: 'select-type',
    onBackInFirstPage,
  });

  useEffect(() => {
    individualEntityForm?.validateForm();
  }, [pageIndex, individualEntityForm?.validateForm]);

  const handleNextStep = async ({ submit } = {}) => {
    if (!submit) {
      setStartAtLastStep(false);
      handleNext();

      return;
    }

    try {
      setLoading(true);
      await individualEntityForm.submitForm();

      handleNext();
    } catch (err) {
      setError('Submit entity failed');
    } finally {
      condTrueFalse(setLoading, () => setLoading(false));
      condTrueFalse(onSubmitSuccess, onSubmitSuccess);
    }
  };

  const handleBackStep = (args) => {
    setStartAtLastStep(true);
    handleBack(args);
  };

  return (
    <>
      {React.cloneElement(stepComponents[pageIndex], {
        startAtLastStep,
        onNext: handleNextStep,
        onBack: handleBackStep,
        steps: stepLabels,
        values: individualEntityForm.values,
        setValues: individualEntityForm.setValues,
        isSubmitting: individualEntityForm.isSubmitting,
        userDetailList,
      })}

      <Loading popup loading={loading} />
      <FailedDialog
        open={!!error}
        onConfirm={() => setError('')}
        subtitle={error}
      />
    </>
  );
};

export default AddIndividualInvestmentEntity;
