import React from 'react';
import { InputAdornment } from '@mui/material';

import getCurrencySymbol from 'utils/getCurrencySymbol';

import InputField from '..';

const CurrencyInput = ({
  currency,
  InputProps,
  withThousandSeparator = true,
  withEndAdornment = false,
  allowDecimal = true,
  ...props
}) => (
  <InputField
    withThousandSeparator={withThousandSeparator}
    withEndAdornment={withEndAdornment}
    allowDecimal={allowDecimal}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {getCurrencySymbol({ currency })}
        </InputAdornment>
      ),
      ...InputProps,
    }}
    {...props}
  />
);

export default CurrencyInput;
