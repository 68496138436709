import React, { useCallback, useState, useMemo } from 'react';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Footer from 'containers/Admin/Approvals/Details/Footer';
import Header from 'containers/Admin/Approvals/Details/Header';
import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import AdminSendEmailConfirmationDialog from 'components/Dialog/AdminSendEmailConfirmationDialog';
import AdminFeedbackDialog from 'components/Dialog/AdminFeedbackDialog';
import Checkbox from 'components/Checkbox';
import LoadingProgressDialog from 'components/Dialog/LoadingProgressDialog';
import {
  FUND_ENTITY_LOADING_LABEL,
  FUND_ENTITY_STATUS,
  PAYMENT_OPTIONS,
} from 'constants/investmentEntity';

import Services from 'api/services';
import PayloadMappers from 'utils/PayloadMappers';
import history from 'utils/history';
import mapValue from 'utils/mapValue';
import useDialog from 'hooks/useDialog';
import updateInvestmentAmountValidationSchemas from 'validations/schema/updateInvestmentAmountValidationSchemas';

import PaymentForm from './PaymentForm';
import InstallmentInformation from './PaymentForm/InstallmentInformation';

const ApprovalContainer = ({
  children,
  values,
  handleRefetch,
  readOnly,
  onDelete,
}) => {
  const [reason, setReason] = useState('');

  const {
    isDialogOpen: paymentDialogOpen,
    handleOpenDialog: handleOpenPaymentDialog,
    handleCloseDialog: handleClosePaymentDialog,
  } = useDialog();

  const {
    isDialogOpen: pendingDialogOpen,
    handleOpenDialog: handleOpenPendingDialog,
    handleCloseDialog: handleClosePendingDialog,
  } = useDialog();

  const {
    isDialogOpen: approvalDialogOpen,
    handleOpenDialog: handleOpenApprovalDialog,
    handleCloseDialog: handleCloseApprovalDialog,
  } = useDialog();

  const {
    isDialogOpen: loadingDialogOpen,
    handleOpenDialog: handleOpenLoadingDialog,
    handleCloseDialog: handleCloseLoadingDialog,
  } = useDialog();

  const handleStatusChangedConfirm = useCallback(async () => {
    const newStatus = pendingDialogOpen.isRejected
      ? FUND_ENTITY_STATUS.STATUSES.PAYMENT_CANCELLED
      : FUND_ENTITY_STATUS[values?.portfolio?.paymentStatus]?.prevStatus;

    await Services.adminChangeStatus({
      id: values.portfolio.id,
      type: 'fund',
      status: newStatus,
      reason,
    });

    handleClosePendingDialog();

    handleRefetch();
  }, [pendingDialogOpen.isRejected, reason]);

  const handleReasonChange = useCallback((e) => setReason(e.target.value), []);

  const paymentForm = useFormik({
    initialValues: {
      amountReceiveEqual: true,
      ...values.portfolio,
    },
    validationSchema: updateInvestmentAmountValidationSchemas,
    validateOnMount: true,
  });

  const handleBack = useCallback(() => {
    const route = mapValue(
      history.location.state?.onBackRoute,
      '/admin/approvals?type=fund',
    );

    history.push(route);
  }, []);

  const { approvalLabel, dialogTitle, isEdit, portfolioStatus } =
    useMemo(() => {
      const paymentStatus =
        FUND_ENTITY_STATUS[values?.portfolio?.paymentStatus]?.status;

      switch (paymentStatus) {
        case FUND_ENTITY_STATUS.STATUSES.PAYMENT_UNDER_REVIEW: {
          return {
            approvalLabel: 'Approved',
            dialogTitle: 'Payment Details',
            portfolioStatus: FUND_ENTITY_STATUS.STATUSES.PAYMENT_UNDER_REVIEW,
          };
        }
        case FUND_ENTITY_STATUS.STATUSES.WAITING_FOR_PAYMENT: {
          return {
            approvalLabel: 'Payment Received',
            dialogTitle: 'Payment Details',
            portfolioStatus: FUND_ENTITY_STATUS.STATUSES.WAITING_FOR_PAYMENT,
          };
        }
        default: {
          return {
            approvalLabel: 'Edit investment',
            dialogTitle: 'Edit Investment',
            isEdit: true,
            portfolioStatus: FUND_ENTITY_STATUS.STATUSES.PAYMENT_COMPLETED,
          };
        }
      }
    }, [values.paymentStatus]);

  const handleCancelForm = useCallback(() => {
    // reset values if it's cancelled
    paymentForm.setValues({
      amountReceiveEqual: true,
      ...values.portfolio,
    });
    handleClosePaymentDialog();
  }, [handleClosePaymentDialog]);

  const handleConfirm = useCallback(
    async ({ isSendEmail } = {}) => {
      const { id, ...payload } = PayloadMappers.admin.fund(paymentForm.values);

      if (isEdit || !paymentForm.values.amountReceiveEqual)
        await Services.adminUpdateApprovalFund({ id, payload });

      if (portfolioStatus !== FUND_ENTITY_STATUS.STATUSES.PAYMENT_COMPLETED) {
        handleOpenLoadingDialog(FUND_ENTITY_LOADING_LABEL[portfolioStatus]);
        try {
          await Services.adminApprove({
            id,
            type: 'fund',
            sendNotificationEmailToUser: isSendEmail,
          });
        } finally {
          handleCloseLoadingDialog();
        }
      }

      handleClosePaymentDialog();
      handleRefetch();
    },
    [paymentForm.values],
  );

  const menus = useMemo(
    () => [
      {
        itemLabel: 'Pending',
        onClick: () => {
          handleOpenPendingDialog();
        },
      },
      {
        itemLabel: 'Cancel',
        onClick: () => {
          handleOpenPendingDialog({ isRejected: true });
        },
      },
    ],
    [],
  );

  const handleChangeAmountEqual = useCallback((e) => {
    if (e.target.value === 'true') {
      paymentForm.resetForm();

      return;
    }
    paymentForm.handleChange(e);
  }, []);

  return (
    <>
      <Paper elevation={3} sx={{ padding: '20px' }}>
        <Grid container rowSpacing={3}>
          <Header
            onBackButtonClick={handleBack}
            backButtonLabel="Investment Fund Approval"
          />
          <Grid item xs={12}>
            {children}
          </Grid>
          <Footer
            approvalLabel={approvalLabel}
            onApprovalClick={handleOpenPaymentDialog}
            menus={menus}
            feedbackBtnDisabled={readOnly}
            downloadBtnDisabled={readOnly}
            approvedBtnDisabled={readOnly}
            deleteBtnDisabled={readOnly}
            onDelete={onDelete}
          />
        </Grid>
      </Paper>
      <AdminConfirmationDialog
        title={dialogTitle}
        open={paymentDialogOpen}
        onClose={handleCancelForm}
        onConfirm={handleOpenApprovalDialog}
        confirmButtonLabel="Submit"
        confirmBtnDisabled={!!Object.keys(paymentForm.errors).length}
        dialogContentProps={{ style: { maxHeight: '800px' } }}
      >
        {!isEdit && (
          <Checkbox
            name="amountReceiveEqual"
            label="Amount received equal to investment value"
            sx={{ marginTop: 0 }}
            onChange={handleChangeAmountEqual}
            errors={paymentForm.errors}
            checked={paymentForm.values.amountReceiveEqual}
          />
        )}
        <PaymentForm
          disabled={!isEdit && paymentForm.values.amountReceiveEqual}
          currency="USD"
          onChange={paymentForm.handleChange}
          setValues={paymentForm.setValues}
          values={paymentForm.values}
          errors={paymentForm.errors}
          withApprovedAt={[
            FUND_ENTITY_STATUS.STATUSES.WAITING_FOR_PAYMENT,
            FUND_ENTITY_STATUS.STATUSES.PAYMENT_COMPLETED,
          ].includes(
            FUND_ENTITY_STATUS[values?.portfolio?.paymentStatus]?.status,
          )}
          setFieldValue={paymentForm.setFieldValue}
        />
        {paymentForm.values.paymentType ===
          PAYMENT_OPTIONS.PAY_IN_INSTALLMENT && (
          <InstallmentInformation
            disabled={!isEdit && paymentForm.values.amountReceiveEqual}
            onChange={paymentForm.handleChange}
            currency="USD"
            values={paymentForm.values}
            errors={paymentForm.errors}
          />
        )}
      </AdminConfirmationDialog>

      <AdminFeedbackDialog
        data-testid="fund-feedback-confirmation-dialog"
        open={pendingDialogOpen}
        title="Feedback Reason"
        subtitle="Enter feedback reason for the investor"
        onClose={handleClosePendingDialog}
        onConfirm={handleStatusChangedConfirm}
        onReasonChange={handleReasonChange}
      />

      <AdminSendEmailConfirmationDialog
        data-testid="approve-confirmation-dialog"
        open={approvalDialogOpen}
        title="Confirmation"
        subtitle="Are you sure want to update this fund status?"
        onClose={handleCloseApprovalDialog}
        onConfirm={handleConfirm}
      />

      <LoadingProgressDialog
        open={!!loadingDialogOpen}
        data-testid="loading-progress-dialog"
        subtitle={loadingDialogOpen}
        onClose={handleCloseLoadingDialog}
      />
    </>
  );
};

export default ApprovalContainer;
