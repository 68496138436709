import React, { memo, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import MUIAccordionDetails from '@mui/material/AccordionDetails';
import MUITypography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import condTrueFalse from 'utils/condTrueFalse';

import Label from './Label';

const StyledMUIAccordion = styled(MUIAccordion)(({ theme, sx = {} }) => ({
  background: theme.palette.reach.white,
  border: '1px solid #E4E4E4',
  borderRadius: '6px',
  boxShadow: 'none',
  marginBottom: '16px',
  ...sx,
}));

const StyledMUIAccordionHeader = styled(MUIAccordionSummary)(
  ({ theme, type = 'main', background }) => ({
    background:
      background ||
      (type === 'main'
        ? theme.palette.reach.blueLight
        : 'rgba(217, 217, 217, 0.1)'),
    border: `1px solid ${
      type === 'main'
        ? theme.palette.reach.blueBorder
        : theme.palette.form.disabledBorder
    }`,
    borderRadius: '6px 6px 0px 0px',
    padding: '14.5px 12px',
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  }),
);

const StyledMUIAccordionDetails = styled(MUIAccordionDetails)(() => ({
  marginTop: '25px',
}));

const CollapsedAccordionFooter = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.reach.blueBorder}`,
  borderTop: '0',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  padding: '8px',
  textAlign: 'center',
}));

const Accordion = ({
  id,
  label,
  labelVariant = 'h6',
  children,
  type,
  onDelete,
  sxProps = {},
  withColapsibleFooter = true,
  headerBackground,
  headerContent,
  toggleOnIcon,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleToggleAccordion = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleOpenDeleteConfirmation = useCallback(
    () => setOpenDeleteConfirmation(true),
    [],
  );

  const handleCloseDeleteConfirmation = useCallback(
    () => setOpenDeleteConfirmation(false),
    [],
  );

  const handleDelete = useCallback(() => {
    onDelete(id);
    handleCloseDeleteConfirmation();
  }, [onDelete]);

  return (
    <div style={{ marginBottom: '16px', ...sxProps }}>
      <StyledMUIAccordion
        expanded={open}
        onChange={condTrueFalse(
          !toggleOnIcon,
          handleToggleAccordion,
          undefined,
          { executeFn: false },
        )}
        {...props}
      >
        <StyledMUIAccordionHeader
          expandIcon={
            <ExpandMoreIcon
              onClick={condTrueFalse(
                toggleOnIcon,
                handleToggleAccordion,
                undefined,
                { executeFn: false },
              )}
            />
          }
          aria-controls={`${label}-content`}
          id={`${label}-header`}
          type={type}
          background={headerBackground}
        >
          <Label
            label={label}
            variant={labelVariant}
            onDelete={!!onDelete && handleOpenDeleteConfirmation}
          />
          {headerContent}
        </StyledMUIAccordionHeader>
        <StyledMUIAccordionDetails>{children}</StyledMUIAccordionDetails>
      </StyledMUIAccordion>
      {withColapsibleFooter && !open && (
        <CollapsedAccordionFooter>
          <MUITypography variant="smallGrey">
            Expand to see details
          </MUITypography>
        </CollapsedAccordionFooter>
      )}

      <ConfirmationDialog
        open={!!openDeleteConfirmation}
        title="Warning!"
        infoText=""
        subtitle="Do you want to delete this section ?"
        onClose={handleCloseDeleteConfirmation}
        confirmButtonLabel="Delete"
        onConfirm={handleDelete}
        dialogContentDependencies={[handleDelete]}
      />
    </div>
  );
};

export default memo(Accordion);
