import { useState, useCallback } from 'react';

import condTrueFalse from 'utils/condTrueFalse';

const useDialog = ({ onClose } = {}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(
    (param = true) => setIsDialogOpen(param),
    [],
  );

  const handleCloseDialog = useCallback(() => {
    const param = isDialogOpen;

    setIsDialogOpen(false);

    condTrueFalse(onClose, () => onClose(param));
  }, [onClose, isDialogOpen]);

  return {
    isDialogOpen,
    handleOpenDialog,
    handleCloseDialog,
  };
};

export default useDialog;
