import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import SquareIcon from '@mui/icons-material/Square';

import palette from 'theme/palette';

const FundLinearProgress = ({ fundProgress = 0.1, progressSeparator = 4 }) => {
  const { firstProgressValue, secondProgressValue } = useMemo(
    () => ({
      firstProgressValue: Math.min(
        100,
        fundProgress * (10 / progressSeparator),
      ),
      secondProgressValue:
        Math.max(0, fundProgress - progressSeparator * 10) *
        (10 / (10 - progressSeparator)),
    }),
    [fundProgress],
  );

  return (
    <Grid container item xs={12} rowSpacing={1.5}>
      <Grid
        item
        xs={12}
        position="relative"
        display="flex"
        justifyContent="space-between"
      >
        <Typography display="inline-block">Fund opening</Typography>
        <Typography
          position="absolute"
          display="inline-block"
          left={`${progressSeparator * 9}%`}
        >
          End of drawdown
        </Typography>
        <Typography display="inline-block">Completion</Typography>
      </Grid>
      <Grid item xs={5} position="relative">
        <LinearProgress
          variant="determinate"
          value={firstProgressValue}
          sx={{
            height: 40,
            backgroundColor: '#a2a2a2',
            '& .MuiLinearProgress-bar': {
              backgroundColor: palette.reach.fuchsia,
            },
          }}
        />
        <Typography
          sx={{
            position: 'absolute',
            display: 'inline-block',
            right: '2.5%',
            top: '60%',
            transform: 'translateY(-50%)',
            color: 'white',
          }}
        >
          Drawdown
        </Typography>
      </Grid>
      <Grid item xs={7} position="relative">
        <LinearProgress
          variant="determinate"
          value={secondProgressValue}
          sx={{
            height: 40,
            backgroundColor: '#D9D9D9',
            '& .MuiLinearProgress-bar': {
              backgroundColor: palette.reach.fuchsia,
            },
          }}
        />
        <Typography
          sx={{
            position: 'absolute',
            display: 'inline-block',
            left: '2.5%',
            top: '60%',
            transform: 'translateY(-50%)',
            color: 'white',
          }}
        >
          Harvest
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        position="relative"
        display="flex"
        justifyContent="space-between"
      >
        <Typography display="inline-block">Year 0</Typography>
        <Typography
          position="absolute"
          display="inline-block"
          left={`${progressSeparator * 9.5}%`}
        >
          Year ~4
        </Typography>
        <Typography display="inline-block">Year ~10</Typography>
      </Grid>

      <Grid item xs={12} display="flex">
        <SquareIcon
          style={{ color: palette.reach.fuchsia, marginRight: '16px' }}
        />
        <Typography display="inline">Fund&apos;s progress</Typography>
      </Grid>
    </Grid>
  );
};

export default FundLinearProgress;
