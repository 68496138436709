import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import TrashIcon from '@mui/icons-material/DeleteOutline';

import FieldListContainer from 'components/FieldListContainer';
import Input from 'components/Input';
import Button from 'components/Button';
import TextButton from 'components/Button/TextButton';
import UploadDialog from 'components/UploadDialog';

import useDialog from 'hooks/useDialog';
import FieldContainer from 'components/FieldContainer';
import TextEditor from 'components/TextEditor';

const UploadSection = ({
  value,
  index,
  onChangeImage,
  onChangeContent,
  onDeleteImage,
  disabled,
  maxFiles = 1,
  files = [],
  placeholder = 'Upload Image',
}) => {
  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleUpdateFileList = useCallback(
    (newFiles) => {
      onChangeImage({ index, newFiles });
    },
    [onChangeImage],
  );

  const handleDeleteCategory = useCallback(() => {
    onDeleteImage({ index });
  }, [onDeleteImage]);

  const hanldeChangeImageContent = useCallback(
    (e) => {
      onChangeContent({ index, e });
    },
    [onChangeContent],
  );

  return (
    <FieldListContainer listNo={index + 1}>
      <Grid container item xs spacing={1}>
        <Grid item xs={9}>
          <Input
            fullWidth
            name="category"
            value={value.fileName}
            disabled={disabled}
            fontweight={700}
            placeholder={placeholder}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={handleOpenDialog}
            disabled={disabled}
            buttoncolor="dark"
            maxheight={7}
          >
            Upload
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={1}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <TextButton
            onClick={handleDeleteCategory}
            Icon={TrashIcon}
            color={disabled ? 'disabled' : 'error'}
            iconSx={{ width: '36px', height: '36px' }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldContainer
            item
            label="Text:"
            variant="title"
            childGridProps={{ sx: {} }}
          >
            <TextEditor
              id={`${index}-image-content`}
              onChange={hanldeChangeImageContent}
              value={value.content}
              name="content"
              disable={disabled}
            />
          </FieldContainer>
        </Grid>
      </Grid>
      <UploadDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onCloseCallback={handleUpdateFileList}
        maxFiles={maxFiles - files.length}
        accept="image/*"
        fileRequirementText="File must be an image. Max (2MB)"
        maxSize={2}
      />
    </FieldListContainer>
  );
};

export default UploadSection;
