import { createTheme } from '@mui/material/styles';
import { getInputFontStyles } from 'constants/styles';

import palette from './palette';

const theme = createTheme();

const defaultFontFamily = ['Inter', 'sans-serif'].join(',');

const bodyStyle = {
  fontFamily: defaultFontFamily,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: palette.text.black,
};

const bodyStyles = {
  ...bodyStyle,
  ...getInputFontStyles({ theme }),
};

const smallStyle = {
  fontFamily: defaultFontFamily,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
};

const smallStyles = {
  ...smallStyle,
  [theme.breakpoints.down('xl')]: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 300,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },
};

const typography = {
  fontFamily: ['Inter', 'sans-serif'].join(','),
  color: palette.text.primary,
  h1: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#000000',
    fontSize: '30px',
    lineHeight: '39.75px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '31.8px',
    },
  },
  h2: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#000000',
    fontSize: '26px',
    lineHeight: '34.45px',
  },
  h3: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#000000',
    fontSize: '22px',
    lineHeight: '29.15px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '20.5px',
    },
  },
  h4: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#000000',
    fontSize: '20px',
    lineHeight: '26.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '18.5px',
      fontWeight: 700,
    },
  },
  h5: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#000000',
    fontSize: '18px',
    lineHeight: '23.85px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
    },
  },
  h6: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#000000',
    fontSize: '16px',
    lineHeight: '21.2px',
  },
  display1: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: '#000000',
    fontSize: '30px',
    lineHeight: '39.75px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '28.5px',
    },
  },
  display2: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: '#000000',
    fontSize: '26px',
    lineHeight: '34.45px',
  },
  display3: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: '#000000',
    fontSize: '24px',
    lineHeight: '31.8px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '18px',
    },
  },
  display4: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: '#000000',
    fontSize: '20px',
    lineHeight: '28.5px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  display5: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: '#000000',
    fontSize: '18px',
    lineHeight: '23.85px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  display6: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: '#000000',
    fontSize: '16px',
    lineHeight: '21.2px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '15px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12.5px',
      lineHeight: '18px',
    },
  },
  displayMobile: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: '#000000',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cardTitle: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: palette.text.obsidian,
    fontSize: '36px',
    lineHeight: '130%',
    [theme.breakpoints.down('lg')]: {
      fontSize: '32px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '21px',
    },
  },
  smallSerifBold: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '130%',
    color: palette.text.grey,
  },
  body: bodyStyles,
  bodyStatic: bodyStyle,
  bodyThin: {
    ...bodyStyles,
    fontWeight: 200,
  },
  bodyPrimary: {
    ...bodyStyles,
    color: palette.text.primary,
  },
  bodyGrey: {
    ...bodyStyles,
    color: palette.text.grey,
  },
  bodyGreyStatic: {
    ...bodyStyle,
    color: palette.text.grey,
  },
  bodyDarkGrey: {
    ...bodyStyles,
    color: palette.text.darkGrey,
  },
  bodyPurple: {
    ...bodyStyles,
    color: palette.text.purple,
  },
  bodyPurplehaze: {
    ...bodyStyles,
    color: palette.text.purpleHaze,
  },
  bodyGreySmall: {
    ...bodyStyles,
    color: palette.text.grey,
    fontFamily: ['PT Serif', 'Serif'].join(','),
    [theme.breakpoints.down('md')]: {
      ...smallStyles,
    },
  },
  helperFuchsia: {
    ...smallStyles,
    color: palette.text.fuchsia,
    fontStyle: 'italic',
  },
  helperPrimary: {
    ...smallStyles,
    color: palette.text.primary,
  },
  bodyLead: {
    fontFamily: defaultFontFamily,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    [theme.breakpoints.down('md')]: {
      ...smallStyles,
    },
  },
  title: {
    fontFamily: defaultFontFamily,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: palette.text.primary,
  },
  smallTitle: {
    fontFamily: defaultFontFamily,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    color: palette.text.primary,
  },
  link: {
    ...bodyStyles,
    color: '#7D66BA',
  },
  verificationLink: {
    ...bodyStyles,
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: 'rgb(116, 108, 192)',
  },
  menuLink: {
    ...bodyStyles,
    color: palette.text.menuLink,
  },
  linkFile: {
    ...bodyStyles,
    color: palette.text.fuchsia,
  },
  small: smallStyles,
  smallStatic: smallStyle,
  smallGrey: {
    ...smallStyles,
    color: palette.text.grey,
  },
  smallGreyStatic: {
    ...smallStyle,
    fontFamily: ['PT Serif', 'Serif'].join(','),
    color: palette.text.grey,
  },
  xsmall: {
    fontFamily: defaultFontFamily,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: palette.text.grey,
  },
  buttonText: {
    fontFamily: defaultFontFamily,
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19.36px',
    color: palette.text.button,
    [theme.breakpoints.down('xl')]: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  buttonTextLight: {
    fontFamily: defaultFontFamily,
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.36px',
    color: palette.text.button,
  },
  buttonTextLightSerif: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19.36px',
    color: palette.text.button,
    '&.disabled': {
      opacity: 0.3,
    },
  },
  buttonTextBluish: {
    fontFamily: defaultFontFamily,
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.36px',
    color: palette.text.back,
  },
  buttonTextInfo: {
    fontFamily: defaultFontFamily,
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.36px',
    color: palette.individual.main,
  },
  inputTextBig: {
    fontFamily: defaultFontFamily,
    fontWeight: '500',
    fontSize: '40px',
    lineHeight: '150%',
    color: palette.text.semiBlack,
  },
  avatarText: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
  },
  avatarTextBig: {
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 400,
    color: '#FFFFFF',
    fontSize: '30px',
    lineHeight: '39.75px',
  },
  cardNameLabel: {
    fontFamily: defaultFontFamily,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21.78px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  declarationText: {
    fontSize: '18px',
    lineHeight: '21.78px',
    fontFamily: ['PT Serif', 'Serif'].join(','),
    fontWeight: 700,
    color: palette.text.fuchsia,
  },
  dataRoomLabel: {
    fontFamily: defaultFontFamily,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: palette.text.black,
  },
  chipLabel: {
    fontFamily: defaultFontFamily,
    fontWeight: '700',
    fontSize: '12px',
    color: palette.text.white,
    [theme.breakpoints.down('xl')]: {
      fontWeight: '500',
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: '700',
      fontSize: '12px',
    },
  },
  portfolioUnitSuffix: {
    fontFamily: defaultFontFamily,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '24px',
    color: palette.reach.inactiveGrey,
  },
};

export default typography;
