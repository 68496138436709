import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';

import useFormGridSize from 'hooks/useFormGridSize';

import FormTitle from 'components/FormTitle';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';

import { COUNTRY_OPTIONS, STATE_OPTIONS } from 'constants/investmentEntity';

const AddressForm = ({
  errors,
  onChange,
  values,
  onBlur,
  touched,
  fieldDirection = 'column',
  title,
  ...props
}) => {
  const {
    labelGridProps,
    inputGridProps,
    getCustomInlineGridProps,
    inlineGridContainerProps,
  } = useFormGridSize({ fieldDirection });

  return (
    <>
      <FormTitle title={title} />
      <Grid
        container
        item
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
        data-testid="entity-address-form"
        {...props}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Address</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Input
            autoFocus
            fullWidth
            data-testid="firstaddress-input"
            id="firstAddress"
            name="firstAddress"
            placeholder="Address line 1*"
            onChange={onChange}
            value={values.firstAddress}
            errorValue={touched.firstAddress && errors.firstAddress}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps} />
        <Grid item {...inputGridProps}>
          <Input
            fullWidth
            data-testid="secondaddress-input"
            id="secondAddress"
            name="secondAddress"
            placeholder="Address line 2"
            onChange={onChange}
            value={values.secondAddress}
            errorValue={touched.secondAddress && errors.secondAddress}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Suburb*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Input
            fullWidth
            data-testid="suburb-input"
            id="suburb"
            name="suburb"
            placeholder="Suburb name*"
            onChange={onChange}
            value={values.suburb}
            errorValue={touched.suburb && errors.suburb}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>City*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Input
            fullWidth
            data-testid="city-input"
            id="city"
            name="city"
            placeholder="City name*"
            onChange={onChange}
            value={values.city}
            errorValue={touched.city && errors.city}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={{ xs: 0.5, md: fieldDirection === 'column' ? 2 : 0 }}
        rowSpacing={{ xs: 2, md: 0 }}
        {...inlineGridContainerProps}
      >
        <Grid
          container
          item
          display="flex"
          {...inlineGridContainerProps}
          pr={{ xs: 0, md: 1 }}
        >
          <Grid item {...labelGridProps}>
            <FormLabel>State*</FormLabel>
          </Grid>
          <Grid
            item
            {...getCustomInlineGridProps({ itemsPerRow: 2 }).inputGridProps}
          >
            <Dropdown
              fullWidth
              data-testid="state-dropdown"
              name="state"
              placeholder="Select state*"
              onChange={onChange}
              value={values.state}
              errorValue={touched.state && errors.state}
              onBlur={onBlur}
              options={STATE_OPTIONS}
            />
          </Grid>
        </Grid>
        <Grid container item display="flex" {...inlineGridContainerProps}>
          <Grid item {...labelGridProps}>
            <FormLabel>Postcode*</FormLabel>
          </Grid>
          <Grid
            item
            {...getCustomInlineGridProps({ itemsPerRow: 2 }).inputGridProps}
          >
            <Input
              fullWidth
              data-testid="postcode-input"
              id="postCode"
              name="postCode"
              placeholder="Postcode*"
              type="number"
              onChange={onChange}
              value={values.postCode}
              errorValue={touched.postCode && errors.postCode}
              onBlur={onBlur}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid {...labelGridProps}>
          <FormLabel>Country*</FormLabel>
        </Grid>
        <Grid {...inputGridProps}>
          <Dropdown
            fullWidth
            data-testid="addressCountry-dropdown"
            name="addressCountry"
            placeholder="Select country*"
            isBoldOptionLabel
            onChange={onChange}
            value={values.addressCountry}
            errorValue={touched.addressCountry && errors.addressCountry}
            onBlur={onBlur}
            options={COUNTRY_OPTIONS}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(AddressForm);
