import * as yup from 'yup';

import generalValidations from 'validations/schema/general';

import bankSummaryValidationSchema from './bankSummaryValidationSchema';

export const bankDetailsValidationSchema = yup.object({
  isAustralianBased: yup.boolean(),
  accountName: generalValidations.accountName(yup),
  accountNumber: generalValidations.accountNumber(yup),
  bsb: generalValidations.bsb(yup),
  bankName: yup.string().label('Bank Name').when('isAustralianBased', {
    is: false,
    then: yup.string().required(),
  }),
  swiftCode: generalValidations.swiftCode(yup),
  country: yup.string().label('Country').when('isAustralianBased', {
    is: false,
    then: yup.string().required(),
  }),
  bankCode: generalValidations.bankCode(yup),
  bankAddress: yup
    .string()
    .label('Bank Address')
    .when('isAustralianBased', {
      is: false,
      then: yup.string().required().min(2).max(255),
    }),
});

const bankDetailsValidationSchemas = [
  null,
  bankDetailsValidationSchema,
  bankSummaryValidationSchema,
];

export default bankDetailsValidationSchemas;
