import React from 'react';
import Accordion from 'components/Accordion';
import LabelWithChip from 'components/LabelWithChip';
import TrusteeDetails from './TrusteeDetails';

const TrusteeList = ({ values = [], ...props }) => (
  <Accordion
    label={
      <LabelWithChip
        label="Trustee"
        chipLabel={values.length}
        chipColor="trusteeCompanyChipColor"
      />
    }
    labelVariant="cardNameLabel"
  >
    <TrusteeDetails values={values} {...props} />
  </Accordion>
);

export default TrusteeList;
