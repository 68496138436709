import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';
import LoadingSpinner from 'components/LoadingSpinner';
import AutoPopulateChipList from 'components/AutoPopulateChipList';
import ShowMe from 'components/ShowMe';

import usePrevious from 'hooks/usePrevious';
import useSteps from 'hooks/useSteps';
import condTrueFalse from 'utils/condTrueFalse';
import lazyLoad from 'utils/lazyLoad';
import mapDraftedAutoPopulatePersonalDetails from 'utils/mapDraftedAutoPopulatePersonalDetails';
import InitialValues from 'constants/InitialValues';

import companyBeneficialOwnerValidationSchemas from 'validations/schema/addInvestment/company/beneficialOwner';
import { ReactComponent as CompanyPerson } from 'assets/icons/company-person.svg';

const BeneficialOwnerStartForm = lazyLoad(() =>
  import('./Steps/BeneficialOwnerStartForm'),
);
const BeneficialOwnerDetailsForm = lazyLoad(() =>
  import('containers/AddInvestmentEntity/components/Forms/PersonalDetailsForm'),
);
const BeneficialOwnerAddressForm = lazyLoad(() =>
  import('containers/AddInvestmentEntity/components/Forms/AddressForm'),
);
const BeneficialOwnerIdentificationForm = lazyLoad(() =>
  import(
    'containers/AddInvestmentEntity/components/Forms/InvestorIdentificationForm'
  ),
);
const BeneficialOwnerSummaryForm = lazyLoad(() =>
  import('./Steps/BeneficialOwnerSummaryForm'),
);
const BeneficialOwnerListForm = lazyLoad(() =>
  import('./Steps/BeneficialOwnerListForm'),
);

const stepItems = [
  {
    label: 'Beneficial Owners',
    component: <BeneficialOwnerStartForm />,
  },
  {
    label: 'Beneficial Owners Details',
    component: <BeneficialOwnerDetailsForm isJoint />,
  },
  {
    label: 'Beneficial Owners Address',
    component: <BeneficialOwnerAddressForm />,
  },
  {
    label: 'Identification of Beneficial Owner',
    component: <BeneficialOwnerIdentificationForm />,
  },
  {
    label: 'Beneficial Owners Summary',
    component: <BeneficialOwnerSummaryForm />,
  },
  {
    label: 'Beneficial Owners List',
    component: <BeneficialOwnerListForm />,
  },
];

const stepComponents = stepItems.map((step) => step.component);
const stepTitles = stepItems.map((step) => step.label);

const initialValues = {
  ...InitialValues.personalDetail,
  // beneficial owners
  activeBeneficialOwner: null,
  beneficialOwners: [],
};

const BeneficialOwners = ({
  onNext,
  onBack,
  startAtLastStep,
  steps,
  setValues,
  values,
  activeStep,
  title = 'Beneficial Owner Details',
  userDetailList,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPersonalDetailIndex, setSelectedPersonalDetailIndex] =
    useState({ personals: '', addresses: '' });
  const handleBackFirstPage = useCallback(
    ({ values: trusteeValues, handleSetPageIndex }) => {
      // When user click `back` while adding / editing Beneficial Owner,
      // Return to Beneficial Owner List Step.
      const isAddingBeneficialOwner =
        trusteeValues.activeBeneficialOwner === null &&
        trusteeValues.beneficialOwners.length;
      const isEditingBeneficialOwner =
        trusteeValues.isEditing || trusteeValues.activeBeneficialOwner > 0;

      if (isEditingBeneficialOwner || isAddingBeneficialOwner) {
        handleSetPageIndex(stepComponents.length - 1);

        return;
      }

      condTrueFalse(setValues, () =>
        setValues({
          ...values,
          ...trusteeValues,
        }),
      );

      onBack();
    },
    [values],
  );

  const handleNextLastPage = ({ values: beneficialOwnersValues }) => {
    if (setValues)
      setValues({
        ...values,
        beneficialOwners: beneficialOwnersValues.beneficialOwners,
        isConfirmedNoOtherBeneficialOwner: true,
      });
    onNext();
  };

  const {
    handleBack,
    handleNext,
    pageIndex,
    setPageIndex,
    form: beneficialOwnersForm,
  } = useSteps({
    stepComponents,
    validateFormik: true,
    validationSchemas: companyBeneficialOwnerValidationSchemas,
    formParameters: {
      initialValues: {
        ...initialValues,
        beneficialOwners: values.beneficialOwners,
        isConfirmedNoOtherBeneficialOwner:
          !!values.isConfirmedNoOtherBeneficialOwner,
        agreement: false,
      },
    },
    onNextInLastPage: handleNextLastPage,
    onBackInFirstPage: handleBackFirstPage,
    defaultPageIndex: startAtLastStep ? stepComponents.length - 1 : 0,
  });
  const prevPageIndex = usePrevious(pageIndex);

  useEffect(() => {
    // If initial pageIndex is 0, and  have beneficial owners,
    // redirect to beneficial owners list step.
    if (pageIndex === 0 && values.beneficialOwners.length) {
      setPageIndex(stepItems.length - 1);
    }
  }, []);

  useEffect(() => {
    beneficialOwnersForm?.validateForm();
  }, [pageIndex, beneficialOwnersForm?.validateForm]);

  const handleAddAnotherBeneficialOwner = useCallback(() => {
    beneficialOwnersForm.resetForm({
      values: {
        ...initialValues,
        beneficialOwners: beneficialOwnersForm.values.beneficialOwners,
      },
    });

    setSelectedPersonalDetailIndex({ personals: '', addresses: '' });

    setPageIndex(0);
  }, [beneficialOwnersForm.values.beneficialOwners]);

  const handleEditBeneficialOwner = useCallback(
    (activeBeneficialOwner) => {
      beneficialOwnersForm.resetForm({
        values: {
          ...beneficialOwnersForm.values.beneficialOwners[
            activeBeneficialOwner
          ],
          beneficialOwners: beneficialOwnersForm.values.beneficialOwners,
          isEditing: true,
          activeBeneficialOwner,
        },
      });

      // Set Selected Personal Detail based on active editing beneficial owner
      setSelectedPersonalDetailIndex((prevSelected) => ({
        ...prevSelected,
        personals:
          beneficialOwnersForm.values.beneficialOwners[activeBeneficialOwner]
            ?.selectedpersonals,
        addresses:
          beneficialOwnersForm.values.beneficialOwners[activeBeneficialOwner]
            ?.selectedaddresses,
      }));

      // Open First Step.
      setPageIndex(stepComponents.length - 2);
    },
    [beneficialOwnersForm.values.beneficialOwners],
  );

  const handleDeleteBeneficialOwner = useCallback(
    (index) => {
      const newBeneficialOwners = [
        ...beneficialOwnersForm.values.beneficialOwners,
      ];
      newBeneficialOwners.splice(index, 1);

      // Set last beneficialOwner as active beneficial owner if there's any.
      const newActiveBeneficialOwner = condTrueFalse(
        newBeneficialOwners.length,
        newBeneficialOwners.length - 1,
        null,
      );

      const newActiveValues = condTrueFalse(
        newActiveBeneficialOwner !== null,
        newBeneficialOwners[newActiveBeneficialOwner],
        { ...beneficialOwnersForm.initialValues, ...initialValues },
      );

      beneficialOwnersForm.setValues({
        ...newActiveValues,
        beneficialOwners: newBeneficialOwners,
        activeBeneficialOwner: newActiveBeneficialOwner,
        isConfirmedNoOtherBeneficialOwner:
          !!beneficialOwnersForm.values.isConfirmedNoOtherBeneficialOwner,
      });
    },
    [
      beneficialOwnersForm.values.beneficialOwners,
      beneficialOwnersForm.values.isConfirmedNoOtherBeneficialOwner,
    ],
  );

  const handleStepBack = useCallback(() => {
    // If current step is beneficial owner list & beneficial owner list is empty,
    // move back to first step.
    if (
      pageIndex === stepItems.length - 1 &&
      !beneficialOwnersForm.values.beneficialOwners.length
    ) {
      setPageIndex(0);
      // wait for initial beneficial owner to be loaded
      setTimeout(() => {
        beneficialOwnersForm.resetForm({ values: initialValues });
        beneficialOwnersForm.setErrors({});
      }, 100);

      return;
    }

    handleBack();
  }, [pageIndex, beneficialOwnersForm.values.beneficialOwners]);

  useEffect(() => {
    beneficialOwnersForm?.validateForm();
  }, [pageIndex, beneficialOwnersForm?.validateForm]);

  const handleSelectAutoPopulateUser = ({ index, recordedData, stepKey }) => {
    setLoading(true);
    beneficialOwnersForm.setValues({
      ...beneficialOwnersForm.values,
      ...recordedData[index],
      [`selected${stepKey}`]: index,
    });

    setSelectedPersonalDetailIndex((prevSelected) => ({
      ...prevSelected,
      [stepKey]: index,
    }));

    // Delay loading to reset form component.
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const headChildren = useMemo(() => {
    const savedPersonalDetails = mapDraftedAutoPopulatePersonalDetails(
      beneficialOwnersForm.values.beneficialOwners,
    );

    const { recordedData, stepKey } = (() => {
      switch (pageIndex) {
        case 1:
          return {
            recordedData: userDetailList.personals.concat(
              savedPersonalDetails.personals,
            ),
            stepKey: 'personals',
          };
        case 2:
          return {
            recordedData: userDetailList.addresses.concat(
              savedPersonalDetails.addresses,
            ),
            stepKey: 'addresses',
          };
        default:
          return { recordedData: null };
      }
    })();

    if (!recordedData) return null;

    return (
      <ShowMe when={recordedData}>
        <AutoPopulateChipList
          items={recordedData}
          onClick={(index) =>
            handleSelectAutoPopulateUser({ index, recordedData, stepKey })
          }
          selectedIndex={selectedPersonalDetailIndex[stepKey]}
        />
      </ShowMe>
    );
  }, [selectedPersonalDetailIndex, userDetailList, pageIndex]);

  return (
    <InvestmentEntityStepContainer
      containerProps={{
        onBack: handleStepBack,
        onNext: handleNext,
        isNextButtonDisabled: !!Object.keys(beneficialOwnersForm?.errors)
          .length,
      }}
      title={title}
      SideIcon={CompanyPerson}
      steps={steps}
      activeStep={activeStep}
      stepTitle={stepTitles[pageIndex]}
      headChildren={headChildren}
    >
      {loading ? (
        <LoadingSpinner loading />
      ) : (
        React.cloneElement(stepComponents[pageIndex], {
          errors: beneficialOwnersForm.errors,
          values: beneficialOwnersForm.values,
          touched: beneficialOwnersForm.touched,
          onChange: beneficialOwnersForm.handleChange,
          onBlur: beneficialOwnersForm.handleBlur,
          setFieldError: beneficialOwnersForm.setFieldError,
          setFieldValue: beneficialOwnersForm.setFieldValue,
          setValues: beneficialOwnersForm.setValues,
          onAddAnotherBeneficialOwner: handleAddAnotherBeneficialOwner,
          onEditBeneficialOwner: handleEditBeneficialOwner,
          onDeleteBeneficialOwner: handleDeleteBeneficialOwner,
          prevPageIndex,
        })
      )}
    </InvestmentEntityStepContainer>
  );
};

export default memo(BeneficialOwners);
