import * as yup from 'yup';
import dayjs from 'dayjs';

import { DATE_MONTH_YEAR } from 'constants/formats';

const dateValidationSchema = (label = 'Date of Birth') => ({
  dateOfBirth: yup
    .date()
    .min(dayjs().subtract(100, 'years'), `${label} cannot be too old`)
    .max(dayjs(), `${label} cannot be future date`)
    .typeError(
      `Date of Birth format is incorrect, it should be ${DATE_MONTH_YEAR.toLowerCase()}`,
    )
    .nullable()
    .required()
    .label(label),
});

export default dateValidationSchema;
