const PHONE_NUMBER_INVALID = 'error.invalid.phone.number';
const PHONE_NUMBER_EXIST = 'error.phone-number.already.exist';
const PHONE_NUMBER_BLOCKED = 'error.phone-number.blocked';
const PHONE_NUMBER_BLOCKED_2 = 'error.phone-number.verify';
const PHONE_NUMBER_VERIFICATION_WRONG_CODE = 'error.phone-number.wrong.code';
const PHONE_NUMBER_VERIFICATION_CODE_EXPIRED =
  'error.phone-number.token.expired';

const ERROR_CODES = {
  PHONE_NUMBER_INVALID,
  PHONE_NUMBER_EXIST,
  PHONE_NUMBER_BLOCKED,
  PHONE_NUMBER_BLOCKED_2,
  PHONE_NUMBER_VERIFICATION_WRONG_CODE,
  PHONE_NUMBER_VERIFICATION_CODE_EXPIRED,
};

const ERROR_MSG = {
  [PHONE_NUMBER_INVALID]: 'Invalid phone number.',
  [PHONE_NUMBER_EXIST]: 'Phone number is already registered.',
  [PHONE_NUMBER_BLOCKED]:
    'You have tried to verify the code 20 times, you can try again later in 24 hours, or using different phone number.',
  [PHONE_NUMBER_VERIFICATION_WRONG_CODE]: 'Wrong code, please try again.',
  [PHONE_NUMBER_VERIFICATION_CODE_EXPIRED]:
    'Verification code is already expired.',
};

const ERROR = {
  [PHONE_NUMBER_INVALID]: {
    code: PHONE_NUMBER_INVALID,
    message: ERROR_MSG[PHONE_NUMBER_INVALID],
  },
  [PHONE_NUMBER_EXIST]: {
    code: PHONE_NUMBER_EXIST,
    message: ERROR_MSG[PHONE_NUMBER_EXIST],
  },
  [PHONE_NUMBER_BLOCKED]: {
    code: PHONE_NUMBER_BLOCKED,
    message: ERROR_MSG[PHONE_NUMBER_BLOCKED],
  },
  [PHONE_NUMBER_BLOCKED_2]: {
    code: PHONE_NUMBER_BLOCKED_2,
    message: ERROR_MSG[PHONE_NUMBER_BLOCKED], // using same msg as PHONE_NUMBER_BLOCKED
  },
  [PHONE_NUMBER_VERIFICATION_WRONG_CODE]: {
    code: PHONE_NUMBER_VERIFICATION_WRONG_CODE,
    message: ERROR_MSG[PHONE_NUMBER_VERIFICATION_WRONG_CODE],
  },
  [PHONE_NUMBER_VERIFICATION_CODE_EXPIRED]: {
    code: PHONE_NUMBER_VERIFICATION_CODE_EXPIRED,
    message: ERROR_MSG[PHONE_NUMBER_VERIFICATION_CODE_EXPIRED],
  },
};

export default { ERROR, ERROR_MSG, ERROR_CODES };
