const companyDetail = {
  // companyDetails
  companyName: '',
  australianTaxNumber: '',
  newZealandTaxNumber: '',
  country: '',
  isPublicCompany: null,
  isNonProfitable: null,
  // address
  state: '',
  addressCountry: '',
  // accountantCertificate
  certificates: [],
  // companyDetails contains all value from first step until the last step of Personal Details
  agreement: false,
};

export default companyDetail;
