import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import EndStepContainer from 'components/EndStepContainer';

import DownloadExpiredIcon from 'assets/images/download_expired_icon.png';

const ExpiredDocumentLink = ({ handleRedirectDashboard }) => (
  <EndStepContainer
    title={<Typography variant="cardTitle">Link expired!</Typography>}
    containerStyles={{
      marginTop: 0,
    }}
    subtitle={
      <Typography variant="buttonTextLight">
        We’re sorry, the link you followed has expired
      </Typography>
    }
  >
    <Grid container>
      <Grid item xs={12}>
        <img src={DownloadExpiredIcon} alt="download-expired-icon" />
      </Grid>
      <Grid item xs={12} mt={8}>
        <Button onClick={handleRedirectDashboard} fullWidth={false}>
          Go Home
        </Button>
      </Grid>
    </Grid>
  </EndStepContainer>
);

export default memo(ExpiredDocumentLink);
