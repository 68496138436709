import React, { memo } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import SearchInput from 'components/Input/SearchInput';
import ShowMe from 'components/ShowMe';

import { ReactComponent as AddCircleIcon } from 'assets/icons/add-circle.svg';

const AdminPageContainer = ({
  pageTitle,
  withSearch = true,
  withFilter = true,
  onSearch,
  onFilter,
  filterValue = '',
  filterOptions = [],
  addEntityBtnText,
  addEntityBtnIcon = AddCircleIcon,
  onAddEntity,
  children,
  testId,
  addButtonComponent,
}) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    data-testid={testId}
  >
    <Grid item xs="auto" sx={{ placeSelf: 'self-end' }}>
      <Typography variant="display1">{pageTitle}</Typography>
    </Grid>

    <Grid
      container
      item
      xs={10}
      columnSpacing={2.5}
      justifyContent="right"
      sx={{ placeItems: 'self-end' }}
    >
      <ShowMe when={!!withSearch}>
        <Grid item>
          <SearchInput onSearch={onSearch} />
        </Grid>
      </ShowMe>
      <ShowMe when={!!withFilter}>
        <Grid item xs={2}>
          <FormLabel>Status</FormLabel>
          <Dropdown
            options={filterOptions}
            onChange={onFilter}
            value={filterValue}
            minheight={5.5}
            maxheight="44px"
          />
        </Grid>
      </ShowMe>
      <ShowMe when={!!onAddEntity}>
        <Grid item>
          <Button
            buttoncolor="purpleGradient"
            onClick={onAddEntity}
            maxheight={5.5}
            prefixIcon={addEntityBtnIcon}
          >
            {addEntityBtnText}
          </Button>
        </Grid>
      </ShowMe>
      <ShowMe when={addButtonComponent}>
        <Grid item>{addButtonComponent}</Grid>
      </ShowMe>
    </Grid>

    <Grid
      container
      item
      sx={(theme) => ({
        background: theme.palette.reach.white,
        boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
        borderRadius: '6px',
        padding: 0,
        marginTop: '16px',
      })}
    >
      {children}
    </Grid>
  </Grid>
);

export default memo(AdminPageContainer);
