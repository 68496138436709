import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
  memo,
} from 'react';

import Grid from '@mui/material/Grid';

import Button from 'components/Button';
import condTrueFalse from 'utils/condTrueFalse';

import BlockContentAccordion from './BlockContentAccordion';
import TabAccordion from './TabAccordion';
import { getAccordionFooter } from './HelperFunction';

const defaultBlockContent = {
  title: '',
  position: '',
  content: '',
};

const defaultFundDetail = {
  title: '',
  icon: '',
  isLocked: false,
  fundContents: [],
};

const getActiveTabItems = ({ tabItems }) =>
  tabItems.reduce((tabs, tab, index) => {
    if (tab.isDeleted) return tabs;

    return tabs.concat({
      ...tab,
      index,
      fundContents:
        tab.fundContents && getActiveTabItems({ tabItems: tab.fundContents }),
    });
  }, []);

const FundDetails = ({
  setFieldValue,
  values,
  fieldKey,
  setDirty,
  onSubmit,
  disableSaveBtn,
  readOnly,
}) => {
  const [fundDetailTabs, setFundDetailTabs] = useState(
    getActiveTabItems({ tabItems: values || [{ ...defaultFundDetail }] }),
  );
  const initiated = useRef();
  const tabIdRef = useRef();

  useEffect(() => {
    initiated.current = false;
    tabIdRef.current = new Date().getTime();
  }, []);

  useEffect(() => {
    if (!initiated.current) {
      initiated.current = true;
      return;
    }

    setDirty(true);
  }, [JSON.stringify(fundDetailTabs)]);

  const handleSubmit = useCallback(async () => {
    const res = await onSubmit({ [fieldKey]: fundDetailTabs });

    // Update Filtered Fund Tabs value.
    const newFundDetails = fundDetailTabs.reduce((tabs, tab) => {
      if (tab.isDeleted) return tabs;

      const index = tabs.length;

      return tabs.concat({
        ...tab,
        idTab: res.tabs[index]?.idTab,
        id: res.tabs[index]?.id,
      });
    }, []);

    setFundDetailTabs(newFundDetails);

    setFieldValue(fieldKey, newFundDetails, undefined, false);

    // Manually set initiated to `false` to keep dirty to `false`.
    initiated.current = false;
  }, [fundDetailTabs]);

  const handleAddFundTab = useCallback(() => {
    tabIdRef.current += 1;
    setFundDetailTabs((prevFundDetailsTab) => [
      ...prevFundDetailsTab,
      { ...defaultFundDetail, tempId: tabIdRef.current },
    ]);
  }, []);

  const handleDeleteFundTab = useCallback((tabIndex) => {
    setFundDetailTabs((prevFundDetailsTab) => {
      const newFundDetailsTab = [...prevFundDetailsTab];

      newFundDetailsTab[tabIndex].isDeleted = true;

      return newFundDetailsTab;
    });
  }, []);

  const handleAddFundContent = useCallback(
    (tabIndex) => {
      setFundDetailTabs((prevFundDetailsTab) => {
        const newFundDetailsTab = [...prevFundDetailsTab];

        newFundDetailsTab[tabIndex] = {
          ...newFundDetailsTab[tabIndex],
          fundContents: [
            ...newFundDetailsTab[tabIndex].fundContents,
            defaultBlockContent,
          ],
        };
        return newFundDetailsTab;
      });
    },
    [fundDetailTabs.length],
  );

  const handleDeleteFundContent = useCallback(({ tabIndex, contentIndex }) => {
    setFundDetailTabs((prevFundDetailsTab) => {
      const newFundDetailsTab = [...prevFundDetailsTab];

      newFundDetailsTab[tabIndex].fundContents[contentIndex].isDeleted = true;

      return newFundDetailsTab;
    });
  }, []);

  const footer = useMemo(
    () =>
      condTrueFalse(!readOnly, () =>
        getAccordionFooter({
          addKey: 'Tab',
          onAdd: handleAddFundTab,
          addIconColor: 'black',
          textColor: 'black',
        }),
      ),
    [readOnly],
  );

  const handleChangeTabsContent = useCallback((newValues) => {
    setFundDetailTabs((prevFundDetailsTab) => {
      const newFundDetailsTab = [...prevFundDetailsTab];
      const tabIndex = newFundDetailsTab.findIndex((fundDetailTab) =>
        newValues.id
          ? fundDetailTab.id === newValues.id
          : fundDetailTab?.tempId === newValues?.tempId,
      );
      const newTabsValue = {
        ...newFundDetailsTab[tabIndex],
        ...newValues,
      };
      newFundDetailsTab[tabIndex] = newTabsValue;
      return newFundDetailsTab;
    });
  }, []);

  const handleChangeBlockContent = useCallback(
    (tabIndex, index) => (newValues) => {
      setFundDetailTabs((prevFundDetailsTab) => {
        const newFundDetailsTab = [...prevFundDetailsTab];

        const newBlockContent = {
          ...prevFundDetailsTab[tabIndex].fundContents[index],
          ...newValues,
        };
        newFundDetailsTab[tabIndex].fundContents[index] = newBlockContent;
        return newFundDetailsTab;
      });
    },
    [],
  );

  const fundTabs = useMemo(
    () => getActiveTabItems({ tabItems: fundDetailTabs }),
    [fundDetailTabs],
  );

  return (
    <>
      {fundTabs.map((fundDetailTab, tabIndex) => (
        <TabAccordion
          key={`accordion-tab-${tabIndex.toString()}-${fundDetailTab?.id}-${
            fundDetailTab?.tempId
          }`}
          index={fundDetailTab.index}
          order={tabIndex}
          tabValue={fundDetailTab}
          onDeleteFundTab={handleDeleteFundTab}
          onChange={handleChangeTabsContent}
          onAddBlockContent={handleAddFundContent}
          defaultOpen={tabIndex === 0}
          readOnly={readOnly}
        >
          {fundDetailTab.fundContents.map((blockContent, contentIndex) => (
            <BlockContentAccordion
              key={`accordion-block-content-${blockContent.index.toString()}`}
              index={blockContent.index}
              order={contentIndex}
              tabIndex={fundDetailTab.index}
              tabValue={blockContent}
              onDeleteBlockContent={handleDeleteFundContent}
              onChange={handleChangeBlockContent(tabIndex, contentIndex)}
              readOnly={readOnly}
            />
          ))}
        </TabAccordion>
      ))}

      {footer}

      <Grid textAlign="right">
        <Button
          fullWidth={false}
          data-testid="submit-btn-fundDetails"
          buttoncolor="purpleHazeGradient"
          sx={{ padding: '10px' }}
          onClick={handleSubmit}
          disabled={disableSaveBtn || readOnly}
        >
          Save
        </Button>
      </Grid>
    </>
  );
};

export default memo(FundDetails);
