import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import CardCarousel from 'components/CardCarousel';

import useFetchApi from 'hooks/useFetchApi';
import useScreenQuery from 'hooks/useScreenQuery';

import FeaturedVideoCard from './FeaturedVideoCard';
import EmptyContainer from '../../EmptyContainer';
import { fetchFeaturedVideos } from './HelperFunction';

const FeaturedVideos = () => {
  const { isMobileScreen, isTabletScreen, isLaptopScreen } = useScreenQuery();
  const getFeaturedVideos = useMemo(() => fetchFeaturedVideos(), []);
  const { apiData: featuredVideos } = useFetchApi({
    url: getFeaturedVideos,
  });

  const imageHeight = useMemo(() => {
    switch (true) {
      case isMobileScreen:
        return '280px';
      case isTabletScreen:
        return '235px';
      default:
        return undefined;
    }
  }, [isMobileScreen, isTabletScreen]);

  const featuredContents = useMemo(
    () =>
      featuredVideos?.map?.((featuredVideo) => (
        <FeaturedVideoCard
          id={featuredVideo.id}
          category={featuredVideo.category}
          title={featuredVideo.title}
          // videoSrc="https://www.youtube.com/embed/tu-bgIg-Luo"
          videoSrc={featuredVideo.link}
          thumbnailImgSrc={featuredVideo.videoThumbnailImgUrl}
          publishedDate={featuredVideo.date}
          description={featuredVideo.summary}
          imageHeight={imageHeight}
        />
      )),
    [featuredVideos, imageHeight],
  );

  if (!featuredContents?.length)
    return <EmptyContainer text="No featured videos yet." />;

  return (
    <Grid
      container
      xs={12}
      minHeight={['98vw', 'auto']}
      // maxHeight={['400px', '480px', '370px', 'auto']}
      height="100%"
    >
      <CardCarousel
        cards={featuredContents}
        sx={{ aspectRatio: { sm: '2.5/1', lg: '3.5/1' } }}
        cardSliderContainerProps={{
          sx: (theme) => ({
            [theme.breakpoints.only('xs')]: {
              aspectRatio: '1/1',
            },
          }),
        }}
      />
    </Grid>
  );
};
export default memo(FeaturedVideos);
