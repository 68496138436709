import React from 'react';

import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircle from '@mui/icons-material/CheckCircle';

const styleIcon = {
  width: '16px',
  height: '16px',
};

const SingleValidationHint = ({ hint, isValid }) => (
  <ListItem sx={{ padding: { xs: 0, md: '8px 16px' } }}>
    <ListItemAvatar sx={{ minWidth: 44 }}>
      {isValid ? (
        <CheckCircle sx={styleIcon} color="success" />
      ) : (
        <ErrorIcon sx={styleIcon} color="errorGray" />
      )}
    </ListItemAvatar>
    <ListItemText
      disableTypography
      primary={<Typography variant="smallGreyStatic">{hint} </Typography>}
    />
  </ListItem>
);

export default SingleValidationHint;
