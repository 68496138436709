import React, { memo } from 'react';

import { INSTALLMENT_INFORMATION_FIELD } from 'constants/investmentEntity';
import { FormLabel, Grid } from '@mui/material';
import Input from 'components/Input';
import FormTitle from 'components/FormTitle';
import CurrencyInput from 'components/Input/CurrencyInput';

const InstallmentInformation = ({
  disabled,
  onChange,
  currency,
  values,
  errors,
}) => (
  <>
    <FormTitle title="Installment Information" />
    <Grid container rowSpacing={1.5} alignItems="center" textAlign="right">
      {INSTALLMENT_INFORMATION_FIELD.map(
        ({ fieldLabel, formLabel, name, withCurrency }) => (
          <React.Fragment key={name}>
            <Grid item xs={5} paddingX={7}>
              <FormLabel>{formLabel || fieldLabel}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              {withCurrency ? (
                <CurrencyInput
                  fullWidth
                  data-testid={`paymentForm-${name}-input`}
                  id={name}
                  name={name}
                  disabled={disabled}
                  placeholder=""
                  type="number"
                  value={values[name]}
                  errorValue={errors[name]}
                  onChange={onChange}
                  currency={currency}
                />
              ) : (
                <Input
                  fullWidth
                  data-testid={`paymentForm-${name}-input`}
                  id={name}
                  name={name}
                  disabled={disabled}
                  placeholder=""
                  type="number"
                  value={values[name]}
                  errorValue={errors[name]}
                  onChange={onChange}
                />
              )}
            </Grid>
          </React.Fragment>
        ),
      )}
    </Grid>
  </>
);
export default memo(InstallmentInformation);
