import React, { memo } from 'react';

import { Grid } from '@mui/material';

import NavItem from './NavItem';

const DrawerContent = ({ items, open, ...props }) => (
  <Grid
    container
    direction="column"
    justifyContent="space-between"
    height="100%"
    {...props}
  >
    <Grid item>
      {items.map((item) => (
        <NavItem
          key={item.id}
          item={item}
          level={1}
          open={open}
          sx={{ marginBottom: '36px' }}
        />
      ))}
    </Grid>
  </Grid>
);

export default memo(DrawerContent);
