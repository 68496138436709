import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import BackButton from 'components/Button/BackButton';
import Button from 'components/Button';
import ShowMe from 'components/ShowMe';
import FormDivider from 'components/OptionDivider/FormDivider';

const StyledInvestmentEntityContainer = styled('div')(
  ({ theme, pt = 4, backgroundcolor, padding }) => ({
    backgroundColor: backgroundcolor || theme.palette.reach.white,
    padding: padding || theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: `${theme.spacing(pt)}`,
    },
  }),
);

const InvestmentEntityContainer = ({
  containerLabel = 'Add Investment Entity',
  onBack,
  onNext,
  children,
  isNextButtonDisabled,
  withHeader = true,
  nextButtonLabel = 'Next',
  ...props
}) => (
  <>
    <ShowMe when={withHeader}>
      <Typography variant="h1" marginBottom={6} textAlign="center">
        {containerLabel}
      </Typography>
    </ShowMe>

    <StyledInvestmentEntityContainer {...props}>
      {children}

      <Grid container justifyContent="space-between" mt={3} columnSpacing={5}>
        <Grid item alignSelf="center" xs={6} sm="auto">
          {onBack && (
            <BackButton
              onClick={onBack}
              data-testid="button-back"
              typographyVariant="bodyStatic"
            />
          )}
        </Grid>
        <Grid item xs={6} sm="auto">
          {onNext && (
            <Button
              disabled={isNextButtonDisabled}
              onClick={onNext}
              data-testid="button-next"
              sx={{ padding: '10px 24px' }}
            >
              {nextButtonLabel}
            </Button>
          )}
        </Grid>
      </Grid>
    </StyledInvestmentEntityContainer>
  </>
);

export default memo(InvestmentEntityContainer);
