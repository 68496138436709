import React from 'react';

import Grid from '@mui/material/Grid';

import DataTable from 'components/DataTable';
import LoadingSpinner from 'components/LoadingSpinner';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';

import {
  emailTemplatesHeaders,
  fetchAdminEmailTemplates,
} from './HelperFunction';

const redirectToEmailTemplateDetails = ({ row }) =>
  history.push(`/admin/platform-configuration/email-template/${row.id}`);

const EmailTemplates = () => {
  const { apiData, isFetchLoading } = useFetchApi({
    fetchService: fetchAdminEmailTemplates,
    defaultApiData: [],
    // dependencies: [adminGetOverviewUserActivity],
  });

  return (
    <Grid
      data-testid="admin-platform-configuration-email-templates"
      container
      item
      direction="column"
      rowSpacing={2}
    >
      <Grid item xs={12}>
        {isFetchLoading ? (
          <LoadingSpinner loading />
        ) : (
          <DataTable
            wrapheader
            hideFooter
            sortingMode="client"
            withCheckbox={false}
            pagination={false}
            height="425px"
            headerData={emailTemplatesHeaders}
            data={apiData}
            isLoading={isFetchLoading}
            totalData={apiData.length}
            containerProps={{ backgroundColor: 'white' }}
            onRowClick={redirectToEmailTemplateDetails}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EmailTemplates;
