const drawer = (state, action) => {
  switch (action.type) {
    case '@@drawer/TOGGLE_DRAWER': {
      return {
        ...state,
        drawer: { open: !!action.payload.open },
      };
    }
    case '@@drawer/DRAWER_OTHER_ACTION': {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default drawer;
