import React, { memo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import palette from 'theme/palette';

import AddButton from 'components/Button/AddButton';
import Checkbox from 'components/Checkbox';
import ShowMe from 'components/ShowMe';

import TaxDetailsSummary from './TaxDetailsSummary';
import TaxSupportingInformationSummary from './TaxSupportingInformationSummary';

const TaxSummary = ({
  values,
  onChange,
  setValues,
  onAddTaxPurpose,
  onDelete,
  hasAustraliaTax,
  withEdit = true,
  additionalChipText,
}) => (
  <Grid container rowSpacing={[1, 3]}>
    <Grid item xs={12}>
      <Typography variant="h3">Company Tax Summary</Typography>
      <Divider
        sx={{
          margin: '10px 0 30px',
          border: `1px solid ${palette.text.disabled}`,
        }}
      />
    </Grid>
    {values.taxPurposes.map((taxPurpose, index) => (
      <Grid item xs={12} key={`${index.toString()}`}>
        <TaxDetailsSummary
          taxPurpose={taxPurpose}
          taxIndex={taxPurpose.index}
          values={values}
          setValues={setValues}
          onDelete={onDelete}
          hasAustraliaTax={hasAustraliaTax}
          withEdit={withEdit}
          additionalChipText={additionalChipText}
        />
      </Grid>
    ))}

    {withEdit && onAddTaxPurpose && (
      <Grid item>
        <AddButton
          onClick={onAddTaxPurpose}
          label="Add another country for tax purpose"
        />
      </Grid>
    )}

    <Grid item xs={12}>
      <TaxSupportingInformationSummary
        values={values}
        setValues={setValues}
        withEdit={withEdit}
      />
    </Grid>
    <ShowMe when={!!onChange}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Checkbox
            label={
              <Typography variant="bodyStatic">
                I confirm that the above information is true and correct
              </Typography>
            }
            checked={values?.isConfirmed}
            name="isConfirmed"
            onChange={onChange}
            sx={{ my: 4 }}
          />
        </Grid>
      </Grid>
    </ShowMe>

    <Grid item xs={12}>
      <Typography>Name of person submitting declaration:</Typography>
    </Grid>

    <Grid item container xs={12} alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography variant="bodyStatic">
          <b>{values?.signedBy}</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        // sx={{ paddingTop: '20px', }}
      >
        <Typography variant="bodyStatic">
          Date :{' '}
          {values?.signedAt?.toLocaleDateString('en-AU', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </Typography>
      </Grid>
    </Grid>
    <ShowMe when={withEdit}>
      <Grid item xs={12} mt={2}>
        <Typography variant="declarationText">
          By submitting this information you declare the following:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="bodyStatic">
          I undertake to provide a suitably updated self-certification within 30
          days of any change in circumstances which causes the information
          contained herein to become incorrect. I declare the information above
          to be true and correct.
        </Typography>
      </Grid>
    </ShowMe>
  </Grid>
);

export default memo(TaxSummary);
