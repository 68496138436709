import React, { memo } from 'react';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const FundCardListText = ({ text, isValue, ...props }) => (
  <ListItemText
    primary={
      <Typography variant={isValue ? 'body' : 'display6'}>{text}</Typography>
    }
    {...props}
  />
);

export default memo(FundCardListText);
