import services from 'api/services';

export const getStatementDocuments = async (userId) => {
  try {
    const result = await services.adminGetUserStatementDocuments(userId);
    return result.data;
  } catch (_) {
    return [];
  }
};

export const uploadStatementDocuments = async (userId, formData) => {
  try {
    const result = await services.adminUploadStatementDocuments(
      userId,
      formData,
    );
    return result.data;
  } catch (_) {
    return false;
  }
};

export const deleteStatementDocuments = async (payload) => {
  try {
    const result = await services.adminDeleteStatementDocuments(payload);
    return result.data;
  } catch (_) {
    return false;
  }
};
