import services from 'api/services';

import { USER_STATUS } from 'constants/userStatus';
import {
  userRoleChipCellRenderer,
  statusChipCellRenderer,
  actionCellRenderer,
  verificationStatusChipCellRenderer,
} from 'utils/Datatables/cellRenderer';
import history from 'utils/history';

const redirectToUserDetails = (id) => {
  history.push(`/admin/users/view/${id}`);
};

const redirectToUserEdit = ({ id, status }) => {
  history.push(
    `/admin/users/edit/${id}${
      status.toUpperCase() === USER_STATUS['PRE-REGISTERED']
        ? `?status=${USER_STATUS['PRE-REGISTERED']}`
        : ''
    }`,
  );
};

const viewEditRenderer = ({ row }) =>
  actionCellRenderer({
    row,
    onEdit: () => redirectToUserEdit(row),
    onView: () => redirectToUserDetails(row.id),
  });

export const onUserTableRowClick = ({ row }) => redirectToUserEdit(row);

export const USER_LIST_COLUMNS = [
  { field: 'id', headerName: 'ID', sortable: false, flex: 0.5 },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    flex: 2.5,
  },
  {
    field: 'email',
    headerName: 'Email',
    headerAlign: 'center',
    sortable: true,
    flex: 2.5,
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    sortable: true,
    flex: 1.5,
  },
  {
    field: 'entity',
    headerName: 'Entity',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    flex: 1.5,
  },
  {
    field: 'fund',
    headerName: 'Funds',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    flex: 1.5,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    flex: 1.5,
    renderCell: statusChipCellRenderer,
  },
  {
    field: 'verification',
    headerName: 'Verification',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    flex: 1.5,
    renderCell: verificationStatusChipCellRenderer,
  },
  {
    field: 'preview',
    headerName: 'Preview',
    headerAlign: 'center',
    align: 'center',
    flex: 0.75,
    renderCell: (props) =>
      actionCellRenderer({
        ...props,
        onPreview: (id) => window.open(`/admin/users/${id}/preview`, '_blank'),
      }),
  },
];

export const ADMIN_LIST_COLUMNS = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 50,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    minWidth: 250,
    flex: 4,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    minWidth: 250,
    flex: 4,
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    minWidth: 150,
    flex: 3,
  },
  {
    field: 'role',
    headerName: 'Role',
    headerAlign: 'center',
    align: 'center',
    renderCell: userRoleChipCellRenderer,
    minWidth: 150,
    flex: 3,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    minWidth: 150,
    flex: 3,
    renderCell: statusChipCellRenderer,
  },
  {
    field: 'action',
    type: 'actions',
    headerName: 'Actions',
    flex: 3,
    headerAlign: 'center',
    sortable: false,
    getActions: viewEditRenderer,
  },
];

export const fetchAdminPreviewToken = async (userId) => {
  try {
    const res = await services.adminGetPreviewToken(userId);
    const { data } = res;

    return data;
  } catch (_) {
    return {};
  }
};
