import React from 'react';

import SummarySection from 'components/SummarySection';
import {
  SUMMARY_FIELDS_COMPANY_DETAILS,
  SUMMARY_FIELDS_ADDRESS,
  SUMMARY_FIELDS_ADDRESS_VIEW,
  SUMMARY_FIELDS_ACCOUNTANT,
  SUMMARY_FIELDS_TRUSTEE_TYPE,
} from 'constants/investmentEntity';

import trusteesCompanyValidations from 'validations/schema/addInvestment/trusteesCompanyValidationSchemas';

const CompanyTrusteeSummary = ({ setValues, values }) => (
  <>
    <SummarySection
      title="Trustee Type"
      fields={SUMMARY_FIELDS_TRUSTEE_TYPE}
      setValues={setValues}
      values={values}
      formName="trusteeTypeForm"
      withEdit={false}
    />
    <SummarySection
      title="Trustee Details"
      fields={SUMMARY_FIELDS_COMPANY_DETAILS}
      setValues={setValues}
      values={values}
      validationSchema={trusteesCompanyValidations[0]}
      formName="trusteeDetailsForm"
    />
    <SummarySection
      title="Address"
      fields={SUMMARY_FIELDS_ADDRESS}
      viewFields={SUMMARY_FIELDS_ADDRESS_VIEW}
      setValues={setValues}
      values={values}
      validationSchema={trusteesCompanyValidations[1]}
      formName="addressForm"
    />
    <SummarySection
      title="Accountant's certificate"
      fields={SUMMARY_FIELDS_ACCOUNTANT}
      setValues={setValues}
      values={values}
      validationSchema={trusteesCompanyValidations[2]}
      formName="accountantForm"
    />
  </>
);

export default CompanyTrusteeSummary;
