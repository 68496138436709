import personalDetailsValidationSchema from '../addInvestment/personalDetailsValidationSchema';
import addressValidationSchema from '../addInvestment/addressValidationSchema';
import investorIdentificationValidationSchema from '../addInvestment/investorIdentificationValidationSchema';
import accountantCertificateValidationSchema from '../addInvestment/accountantCertificateValidationSchema';
import companyDetailsValidationSchema from '../addInvestment/company/companyDetailsValidationSchema';

const trusteeCompanyValidationSchema = companyDetailsValidationSchema
  .concat(addressValidationSchema)
  .concat(accountantCertificateValidationSchema);

const trusteeIndividualValidationSchema = personalDetailsValidationSchema
  .concat(addressValidationSchema)
  .concat(investorIdentificationValidationSchema)
  .concat(accountantCertificateValidationSchema);

const addTrusteeValidationSchema = (type) => {
  if (type === 'trusteeCompany') return trusteeCompanyValidationSchema;
  return trusteeIndividualValidationSchema;
};

export default addTrusteeValidationSchema;
