import React, { memo, useCallback } from 'react';

import Typography from '@mui/material/Typography';

import TextButton from 'components/Button/TextButton';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';

const DownloadButton = ({ downloadUrl, title }) => {
  const handleDownload = useCallback(
    () => window.open(downloadUrl, '_blank'),
    [downloadUrl],
  );

  return (
    <TextButton
      Icon={DownloadIcon}
      onClick={handleDownload}
      sx={{
        margin: '34.5px 0 0 14px',
        justifyContent: 'flex-start',
        textAlign: { xs: 'left', md: 'center' },
      }}
    >
      <Typography variant="buttonTextBluish" sx={{ marginLeft: '11px' }}>
        {title}
      </Typography>
    </TextButton>
  );
};

export default memo(DownloadButton);
