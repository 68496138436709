import React, { createContext, useEffect, useState, useMemo } from 'react';

import Loading from 'components/Loading';
import { useStores } from 'components/StateProvider';

import useSteps from 'hooks/useSteps';
import useUserDetailListData from 'hooks/useUserDetailListData';
import condTrueFalse from 'utils/condTrueFalse';

import Trustees from './Steps/Trustees';
import BankDetails from '../components/BankDetails';
import TaxDetails from '../components/TaxDetailsWithSupportingInformation';
import TrustProfile from './Steps/TrustProfile';
import BeneficialOwners from '../Company/Steps/BeneficialOwners';
import InvestmentSuccess from '../components/InvestmentSuccess';
import { handleAddTrustEntity } from './HelperFunction';

const TrustContext = createContext();
const TrustActionContext = createContext();

const steps = [
  {
    label: 'Trust Details',
    component: <TrustProfile />,
  },
  {
    label: 'Trustee Details',
    component: <Trustees />,
  },
  {
    label: 'Beneficial Owners',
    component: <BeneficialOwners title="Beneficial Owners of the Trust" />,
  },
  {
    label: 'Tax Details',
    component: <TaxDetails title="Trust Tax Details" />,
  },
  {
    label: 'Bank Details',
    component: <BankDetails />,
  },
  {
    label: 'Success',
    component: <InvestmentSuccess />,
  },
];

const stepComponents = steps.map((step) => step.component);
const stepLabels = steps.reduce((labels, step) => {
  if (!step.label || step.label === 'Success') return labels;
  return labels.concat({ label: step.label });
}, []);

const AddTrustInvestmentEntity = ({
  onBackInFirstPage,
  initialValues = {},
  onSubmitSuccess,
  user,
}) => {
  const {
    auth: {
      user: { id: userId, email: userEmail },
    },
  } = useStores();
  const [startAtLastStep, setStartAtLastStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userDetailList } = useUserDetailListData({
    userId: user?.id || userId,
  });

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: trustEntityForm,
  } = useSteps({
    stepComponents,
    formParameters: {
      initialValues: {
        trustees: [],
        beneficialOwners: [],
        ...initialValues,
      },
      onSubmit: async (formValues) => {
        setLoading(true);
        await handleAddTrustEntity({
          formValues,
          cb: onSubmitSuccess,
          setLoading,
          userEmail,
        });
      },
    },
    firstStepGoBackURL: 'select-type',
    onBackInFirstPage,
  });

  useEffect(() => {
    trustEntityForm?.validateForm();
  }, [pageIndex, trustEntityForm?.validateForm]);

  const handleNextStep = async ({ submit } = {}) => {
    if (!submit) {
      setStartAtLastStep(false);
      handleNext();

      return;
    }

    try {
      await trustEntityForm.submitForm();
      handleNext();
    } catch (err) {
      // Only temp, waiting error confirmation.
      // eslint-disable-next-line
      return err.response;
    }
  };

  const handleBackStep = (args) => {
    setStartAtLastStep(true);
    handleBack(args);
  };

  const trustContextValues = useMemo(
    () => ({ values: trustEntityForm.values }),
    [trustEntityForm.values],
  );
  const trustActionContextValues = useMemo(
    () => ({
      setValues: (isCertificate) => (values) =>
        trustEntityForm.setValues({
          ...trustEntityForm.values,
          trustProfile: condTrueFalse(
            isCertificate,
            {
              ...trustEntityForm.values.trustProfile,
              trustProfileDocuments: values.trustProfileDocuments,
            },
            values,
          ),
        }),
    }),
    [trustEntityForm.setValues, trustEntityForm.values],
  );

  return (
    <>
      <TrustContext.Provider value={trustContextValues}>
        <TrustActionContext.Provider value={trustActionContextValues}>
          {React.cloneElement(stepComponents[pageIndex], {
            startAtLastStep,
            onNext: handleNextStep,
            onBack: handleBackStep,
            steps: stepLabels,
            values: trustEntityForm.values,
            setValues: trustEntityForm.setValues,
            activeStep: pageIndex,
            mainTaxOwnerName: trustEntityForm.values.trustProfile?.fullName,
            additionalChipText: trustEntityForm.values.trustProfile?.trustType,
            TrustContext,
            TrustActionContext,
            user,
            userDetailList,
          })}
        </TrustActionContext.Provider>
      </TrustContext.Provider>
      <Loading popup loading={loading} />
    </>
  );
};

export default AddTrustInvestmentEntity;
