import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const evergreenFundList = [
  'This Fund is an evergreen fund, meaning there is no finite term or period of the fund. ',
  'Your investment remains in the fund for as long as it suits your needs (subject to the Fund’s terms).',
];

const TermOpenEndTemplate = () => (
  <Grid container item xs={12}>
    <Grid item>
      <Typography variant="title">Evergreen Fund</Typography>

      <ul>
        {evergreenFundList.map((listItem) => (
          <li>
            <Typography>{listItem}</Typography>
          </li>
        ))}
      </ul>
    </Grid>
  </Grid>
);

export default TermOpenEndTemplate;
