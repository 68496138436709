import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';

import useMenu from 'hooks/useMenu';
import Avatar from 'components/Avatar';
import Menu from 'components/Menu';
import history from 'utils/history';
// import { ROLE_USER } from 'constants/userManagement';

import AppBar from './components/AppBar';
import AppBarContainer from './components/AppBarContainer';
import AppBarToolbar from './components/AppBarToolbar';
import AppBarButton from './components/AppBarButton';

import { useActions, useStores } from '../StateProvider';

const transformOriginProps = { horizontal: 'right', vertical: 'top' };
const anchorOriginProps = { horizontal: 'right', vertical: 'bottom' };

const handleRouteToDashboard = () => history.push('/dashboard');

const Navbar = ({
  isChangePasswordOrPhoneNumberPage,
  isAdminPage,
  hasSidebar,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    auth: { isAuthenticated, user },
    drawer: { open: drawerOpen },
  } = useStores();
  const { handleToggleDrawer } = useActions();

  const { anchorEl, open, handleClick, handleClose } = useMenu();

  // Comment this for a while to test if getting user profile in dashboard is fine.
  // useEffect(() => {
  //   if (user.role === ROLE_USER) handleGetUserProfile();
  // }, [user.role]);

  const menus = useMemo(
    () => [
      {
        icon: <PersonOutlineIcon color="errorGray" />,
        itemLabel: 'My Details',
        onClick: () => history.push('/profile'),
      },
      {
        icon: <LogoutIcon color="redIcon" />,
        itemLabel: 'Logout',
        onClick: () => history.push('/logout'),
      },
    ],
    [],
  );

  return (
    <AppBar data-testid="navbar" position="absolute" sx={{ width: '100%' }}>
      <AppBarContainer maxWidth="xl" paddingleft={isAdminPage ? '24px' : ''}>
        <AppBarToolbar
          disableGutters
          isauthenticated={
            isAuthenticated && !isChangePasswordOrPhoneNumberPage
              ? 'true'
              : undefined
          }
        >
          <Grid
            sx={{
              display: 'flex',
              width: ['100%', 'auto'],
              justifyContent:
                !isAdminPage && isMobileScreen ? 'space-around' : 'auto',
            }}
          >
            {(isMobileScreen || isAdminPage) && (
              <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleToggleDrawer}
                edge="start"
              >
                <MenuIcon color="darkGrey" />
              </IconButton>
            )}
            {!isAdminPage && !hasSidebar && (
              <Icon
                style={{ width: '6.5em', height: '1.75em', cursor: 'pointer' }}
                onClick={handleRouteToDashboard}
              >
                <img
                  alt="logo-company"
                  src="/assets/images/Reach-logo.png"
                  height="100%"
                  width="100%"
                />
              </Icon>
            )}
          </Grid>

          {isAuthenticated && !isChangePasswordOrPhoneNumberPage && (
            <Box
              style={{
                display: 'flex',
              }}
            >
              <AppBarButton
                data-testid="navbar-menu-btn"
                backgroundcolor="transparent"
                onClick={handleClick}
              >
                <Typography
                  variant="h6"
                  sx={{ display: { xs: 'none', sm: 'initial' } }}
                >{`${user.firstName} ${user.lastName}`}</Typography>
                <Avatar user={user} />
                <ExpandMoreIcon />
              </AppBarButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={transformOriginProps}
                anchorOrigin={anchorOriginProps}
              >
                {menus.map(({ icon, itemLabel, onClick }) => (
                  <MenuItem
                    key={itemLabel}
                    data-testid={`menu-item-${itemLabel}`}
                    onClick={onClick}
                  >
                    {icon}
                    <Typography variant="body">{itemLabel}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </AppBarToolbar>
      </AppBarContainer>
    </AppBar>
  );
};

export default Navbar;
