import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const ProgressContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.reach.inactiveGrey,
  borderRadius: 6,
}));
const ProgressBarGrid = styled(Grid)(({ theme, progress }) => ({
  backgroundColor: progress
    ? theme.palette.reach.purpleHaze
    : theme.palette.reach.inactiveGrey,
  borderRadius: 6,
  color: theme.palette.text.white,
  padding: 10,
  textAlign: 'center',
}));
const ProgressBar = ({ progress }) => {
  const barLength = useMemo(() => (progress / 100) * 12, [progress]);

  return (
    <ProgressContainer>
      <ProgressBarGrid item xs={barLength} progress={progress}>
        <Typography>{progress}%</Typography>
      </ProgressBarGrid>
    </ProgressContainer>
  );
};

export default ProgressBar;
