import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as Document } from 'assets/icons/document-file.svg';
import { styled } from '@mui/material/styles';
import { CancelOutlined } from '@mui/icons-material';

const FileListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.light}`,
  paddingLeft: '36px',
  paddingRight: '8px',
}));
const DocumentList = ({ fileList = [], getFileName, onRemove }) => (
  <List component="div">
    {fileList.map((file, index) => (
      <FileListItem
        key={`${index.toString()}`}
        secondaryAction={
          <Grid container alignItems="center" columnSpacing={1}>
            <Grid item>
              <IconButton onClick={onRemove(file, index)}>
                <CancelOutlined color="error" />
              </IconButton>
            </Grid>
          </Grid>
        }
      >
        <ListItemIcon>
          <Document />
        </ListItemIcon>
        <ListItemText
          primary={getFileName(file?.fileName || file?.originalFileName)}
        />
      </FileListItem>
    ))}
  </List>
);

export default DocumentList;
