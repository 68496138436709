import { PAYMENT_OPTIONS } from 'constants/investmentEntity';
import getNumbers from 'utils/strings/getNumbers';

const fundInvest = (fundInvestValues) => ({
  committedAmount: getNumbers(fundInvestValues.investmentAmount),
  signedBy: fundInvestValues.signedBy,
  signedAt: fundInvestValues.signedAt,
  paymentType: fundInvestValues.isPayInInstallment
    ? PAYMENT_OPTIONS.PAY_IN_INSTALLMENT
    : PAYMENT_OPTIONS.PAY_NOW,
  userInvestorMapFk: fundInvestValues.entityDetail?.userInvestorMapId,
  fundConfigurationId: fundInvestValues.financialInformationClass,
});

export default fundInvest;
