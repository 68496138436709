import React, { memo, useEffect, useMemo } from 'react';

import lazyLoad from 'utils/lazyLoad';
import useSteps from 'hooks/useSteps';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';
import bankDetailsValidationSchemas from 'validations/schema/addInvestment/bankDetailsValidationSchemas';

const SelectBankLocation = lazyLoad(() => import('./Steps/SelectBankLocation'));
const BankAccount = lazyLoad(() => import('./Steps/BankAccount'));
const BankSummary = lazyLoad(() =>
  import('containers/AddInvestmentEntity/components/Forms/BankSummary'),
);

const stepComponents = [
  <SelectBankLocation />,
  <BankAccount />,
  <BankSummary />,
];
const stepTitles = ['Bank Account'];
const bankDetailsInitialValues = {
  countries: '',
  isConfirmed: false,
  ePaymentAgreement: false,
  country: '',
  accountName: '',
  accountNumber: '',
};

const BankDetails = ({
  onNext,
  onBack,
  values,
  setValues,
  SideIcon,
  steps,
  isSubmitting,
  activeStep,
}) => {
  const handleOnBackInFirstPage = ({ values: bankDetails }) => {
    if (setValues) {
      setValues({ ...values, bankDetails });
    }

    onBack();
  };

  const handleOnNextInLastPage = ({ values: bankDetails }) => {
    if (setValues) {
      setValues({ ...values, bankDetails });
    }
    onNext({ submit: true });
  };

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: bankDetailsForm,
  } = useSteps({
    stepComponents,
    validateFormik: true,
    validationSchemas: bankDetailsValidationSchemas,
    formParameters: {
      initialValues: {
        ...bankDetailsInitialValues,
        ...values?.bankDetails,
      },
    },
    onNextInLastPage: handleOnNextInLastPage,
    onBackInFirstPage: handleOnBackInFirstPage,
  });

  useEffect(() => {
    bankDetailsForm?.validateForm();
  }, [pageIndex, bankDetailsForm?.validateForm]);

  const containerProps = useMemo(() => {
    const showBack = handleBack;
    const showNext = pageIndex !== 0 ? handleNext : null;
    return {
      onBack: showBack,
      onNext: showNext,
      isNextButtonDisabled:
        !!Object.keys(bankDetailsForm.errors).length || isSubmitting,
    };
  }, [pageIndex, bankDetailsForm.values, bankDetailsForm.errors, isSubmitting]);

  return (
    <InvestmentEntityStepContainer
      title="Bank Details"
      containerProps={containerProps}
      SideIcon={SideIcon}
      steps={steps}
      stepTitle={stepTitles[pageIndex]}
      activeStep={activeStep}
    >
      {React.cloneElement(stepComponents[pageIndex], {
        form: bankDetailsForm,
        errors: bankDetailsForm.errors,
        values: bankDetailsForm.values,
        touched: bankDetailsForm.touched,
        pageIndex,
        onNext: handleNext,
        onBack: handleBack,
        onChange: bankDetailsForm.handleChange,
        setValues: bankDetailsForm.setValues,
        onBlur: bankDetailsForm.handleBlur,
        setFieldError: bankDetailsForm.setFieldError,
        initialValues: bankDetailsInitialValues,
      })}
    </InvestmentEntityStepContainer>
  );
};

export default memo(BankDetails);
