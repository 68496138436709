export const phoneNumberFieldStyles = (theme) => ({
  m: theme.spacing(1),
  width: theme.spacing(55),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
});

const getDefaultBorderColor = (disabled, theme) =>
  disabled ? theme.palette.form.disabledBorder : theme.palette.reach.purple;

export const phoneNumberFieldInputStyles =
  ({ errorValue, disabled }) =>
  (theme) => ({
    pl: 0,
    '.MuiOutlinedInput-input': {
      pl: theme.spacing(1),
    },
    '& .MuiTextField-root': {
      height: '62px',
      border: 'none',
      borderRight: `1px solid ${
        errorValue
          ? theme.palette.reach.red
          : getDefaultBorderColor(disabled, theme)
      }`,
      '& .MuiOutlinedInput-root': {
        height: '100%',
      },
      '& .MuiOutlinedInput-root fieldset': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root:hover fieldset': {
        border: 'none',
      },
    },
  });
