import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import BarChart from 'components/Chart/BarChart';

import thousandSeparator from 'utils/strings/thousandSeparators';
import { getFullDateString } from 'utils/date';

import palette from 'theme/palette';
import MixedChart from 'components/Chart/MixedChart';

const defaultColor = [
  '#01D6A1',
  '#161A36',
  '#00A2FF',
  '#1C457D',
  '#D9D9D9',
  '#2350BA',
  palette.reach.yellowWarning,
  palette.reach.blueLight,
];

const PortfolioChart = ({ data = [] }) => {
  const chartData = useMemo(() => {
    if (!data.length) return { labels: [], datasets: [] };

    const labels = [];

    const datasets = [
      {
        label: 'Capital Called',
        field: 'funded',
        type: 'line',
        data: [],
        backgroundColor: defaultColor[0],
      },
      {
        label: 'Capital Committed',
        field: 'currentTotalInvestments',
        type: 'line',
        data: [],
        backgroundColor: defaultColor[1],
      },
      {
        label: 'Growth',
        field: 'growth',
        type: 'bar',
        data: [],
        backgroundColor: defaultColor[2],
      },
      {
        label: 'Distributions',
        field: 'distribution',
        type: 'bar',
        data: [],
        backgroundColor: defaultColor[3],
      },
    ];

    data.forEach((dataItem) => {
      datasets.reduce((newDataSets, dataset, index) => {
        newDataSets[index].data.push(Number(dataItem[dataset.field] || 0));

        return newDataSets;
      }, datasets);

      labels.push(getFullDateString({ date: dataItem.date }));
    });

    return { labels, datasets };
  }, [data]);

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <MixedChart
          data={chartData}
          style={{ marginTop: '16px', maxHeight: '300px' }}
          options={{
            scales: {
              y: {
                ticks: {
                  maxTicksLimit: 10,
                  callback: (label) => `$${thousandSeparator(label)}`,
                },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PortfolioChart;
