export const PASSWORD_MIN_MAX_CHAR_VALIDATION_MSG =
  'Between 8 and  50 characters';
export const PASSWORD_MIN_LOWERCASE_VALIDATION_MSG = 'One lower case character';
export const PASSWORD_MIN_UPPERCASE_VALIDATION_MSG = 'One upper case letter';
export const PASSWORD_MIN_TWO_NUMBERS_VALIDATION_MSG = 'Two numbers';
export const PASSWORD_REQUIRED_VALIDATION_MSG = 'Password is required';

export const PASSWORD_MIN_CHAR_VALIDATION_VALUE = 8;
export const PASSWORD_MAX_CHAR_VALIDATION_VALUE = 50;
export const PASSWORD_MIN_LOWERCASE_VALIDATION_VALUE = 1;
export const PASSWORD_MIN_UPPERCASE_VALIDATION_VALUE = 1;
export const PASSWORD_MIN_NUMBER_VALIDATION_VALUE = 2;

export const CONSENT_TYPES = {
  0: 'consentIsProfessionalInvestor',
  1: 'consentIsNotUSCitizen',
  2: 'consentIsUnderstoodTnc',
};
