import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import SecondaryButton from 'components/Button/SecondaryButton';

import Dialog from '.';

const ConfirmationDialog = ({
  title,
  subtitle,
  contentText,
  infoText = 'This action cannot be undone.',
  icon,
  onClose,
  onConfirm,
  dialogContentDependencies = [],
  confirmButtonContainerProps,
  confirmButtonProps,
  confirmButtonLabel = 'Yes',
  showCancelBtn = true,
  cancelButtonContainerProps = {},
  cancelButtonProps = {},
  cancelBtnLabel = 'Cancel',
  subtitleProps = {},
  closeOnBackgroundClick = true,
  ...props
}) => {
  const DialogTitle = useMemo(
    () => (
      <Grid container direction="column" rowSpacing={3}>
        {icon && (
          <Grid item height={{ xs: '64px' }}>
            <img src={icon} alt="icon" width="100%" height="100%" />
          </Grid>
        )}
        <Grid item textAlign="center">
          <Typography variant="display1">{title}</Typography>
        </Grid>
      </Grid>
    ),
    [title],
  );

  const DialogContent = useMemo(
    () => (
      <Grid
        container
        rowSpacing={3}
        direction="column"
        textAlign="center"
        px={{ xs: 0, sm: 10 }}
      >
        <Grid item>
          <Typography variant="h3" {...subtitleProps}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item>{contentText}</Grid>
        <Grid container item columnSpacing={1.5} justifyContent="center">
          {onClose && showCancelBtn && (
            <Grid item xs={6} md={4} {...cancelButtonContainerProps}>
              <SecondaryButton
                fullWidth
                buttoncolor="purpleGradient"
                onClick={onClose}
                variant="outlined"
                sx={{ py: 1.25, height: '100%' }}
                labelProps={{ variant: 'smallStatic' }}
                {...cancelButtonProps}
              >
                {cancelBtnLabel}
              </SecondaryButton>
            </Grid>
          )}
          {onConfirm && (
            <Grid item xs={6} md={4} {...confirmButtonContainerProps}>
              <Button
                fullWidth
                onClick={onConfirm}
                sx={{ py: 1.25, height: '100%' }}
                text={false}
                buttonstyles={{
                  border: `1px solid ${onClose ? 'transparent' : ''}`,
                }}
                {...confirmButtonProps}
              >
                <Typography
                  variant="bodyStatic"
                  fontSize={14}
                  fontWeight={300}
                  color="white"
                >
                  {confirmButtonLabel}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Typography variant="xsmall" fontStyle="italic" color="black">
            {infoText}
          </Typography>
        </Grid>
      </Grid>
    ),
    [
      subtitle,
      infoText,
      onConfirm,
      ...dialogContentDependencies,
      confirmButtonProps,
    ],
  );

  return (
    <Dialog
      title={DialogTitle}
      content={DialogContent}
      onClose={closeOnBackgroundClick ? onClose : undefined}
      dialogTitleProps={{ mt: 2 }}
      {...props}
    />
  );
};

export default ConfirmationDialog;
