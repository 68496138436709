import React, { useCallback, useMemo, useState } from 'react';

import Drawer from '@mui/material/Drawer';
import { useMediaQuery } from '@mui/material';

import ShowMe from 'components/ShowMe';
import { useActions, useStores } from 'components/StateProvider';

import DashboardMenuItems from 'router/DashboardMenuItems';
import config from 'utils/config';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';

import DrawerContent from './DrawerContent';
import DrawerHeader from './DrawerHeader';

const DashboardDrawer = ({ onClose }) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    drawer: { open },
  } = useStores();
  const { handleToggleDrawer } = useActions();

  const handleOpenDrawer = useCallback(
    () => !isMobileScreen && handleToggleDrawer(true),
    [isMobileScreen],
  );

  const handleCloseDrawer = useCallback(
    () => !isMobileScreen && handleToggleDrawer(false),
    [isMobileScreen],
  );

  const container = useMemo(
    () => (window !== undefined ? window.document.body : undefined),
    [window],
  );

  const drawerWidth = useMemo(() => {
    if (isMobileScreen) {
      return open ? '100%' : '0px';
    }

    return open
      ? config.drawer.dashboard.fullWidth
      : config.drawer.dashboard.width;
  }, [open, isMobileScreen]);

  // const drawerWidth = useMemo(
  //   () =>
  //     open ? config.drawer.dashboard.width * 3 : config.drawer.dashboard.width,
  //   [open],
  // );

  return (
    <Drawer
      container={container}
      variant="persistent"
      open
      onClose={onClose}
      ModalProps={{ keepMounted: true }}
      sx={(theme) => ({
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          backgroundImage: 'none',
          boxShadow: 'inherit',
          backgroundColor: '#FFFFFF',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
          top: isMobileScreen ? '88px' : '0px',
        },
      })}
      onMouseEnter={handleOpenDrawer}
      onMouseLeave={handleCloseDrawer}
    >
      <ShowMe when={!isMobileScreen}>
        <DrawerHeader style={{ justifyContent: 'center' }}>
          <ShowMe when={open}>
            <LogoIcon style={{ width: '150px' }} />
          </ShowMe>
        </DrawerHeader>
      </ShowMe>
      <DrawerContent items={DashboardMenuItems} open={open} />
    </Drawer>
  );
};

export default DashboardDrawer;
