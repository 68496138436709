import React, { useContext, createContext } from 'react';
import ReachAltStores from '../../store/states';
import { STORAGE_KEY } from '../../constants/general';

const StateContext = createContext();
const ActionContext = createContext();

export const StateProvider = ({ children }) => {
  const { reachAltData, reachAltActions } = ReachAltStores();

  // Need hash this object to string,
  // to ease the dependency comparison process on `useEffect`.
  const persistedStates = JSON.stringify({
    auth: reachAltData.auth,
  });

  const persistedStatesValues = JSON.parse(persistedStates);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(persistedStatesValues));

  return (
    <StateContext.Provider value={reachAltData}>
      <ActionContext.Provider value={reachAltActions}>
        {children}
      </ActionContext.Provider>
    </StateContext.Provider>
  );
};

export const useStores = () => useContext(StateContext) || {};
export const useActions = () => useContext(ActionContext) || {};
