import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import ExpandMore from '@mui/icons-material/ExpandMore';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';

import AdminSendEmailConfirmationDialog from 'components/Dialog/AdminSendEmailConfirmationDialog';
import AdminFeedbackDialog from 'components/Dialog/AdminFeedbackDialog';
import Button from 'components/Button';
import Loading from 'components/Loading';
import ShowMe from 'components/ShowMe';
import TextButton from 'components/Button/TextButton';

import condTrueFalse from 'utils/condTrueFalse';
import useDialog from 'hooks/useDialog';
import useMenu from 'hooks/useMenu';

import { entityApprove, entityStatusChange } from './HelperFunction';

const transformOriginProps = { horizontal: 'right', vertical: 'center' };
const anchorOriginProps = { horizontal: 'center', vertical: 'top' };

const Footer = ({
  onApprovalClick,
  entityId,
  onDownloadClick,
  downloadBtnDisabled,
  approvedBtnDisabled,
  feedbackBtnDisabled,
  approvalLabel = 'Approve',
  confirmationDialogSubtitle = 'Are you sure want to approve this entity',
  menus = [],
  onSuccess,
  onSubmitEntityApprove,
  onSubmitEntityStatusChange,
  withFeedbackBtn = true,
  deleteBtnDisabled,
  onDelete,
}) => {
  const [reason, setReason] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const { anchorEl, open, handleClick, handleClose } = useMenu();

  const {
    isDialogOpen: approvalDialogOpen,
    handleOpenDialog: handleOpenApprovalDialog,
    handleCloseDialog: handleCloseApprovalDialog,
  } = useDialog();
  const {
    isDialogOpen: pendingDialogOpen,
    handleOpenDialog: handleOpenPendingDialog,
    handleCloseDialog: handleClosePendingDialog,
  } = useDialog();

  const handleClickApproval = useCallback(() => {
    if (onApprovalClick) return onApprovalClick();

    return handleOpenApprovalDialog();
  }, [onApprovalClick, handleOpenApprovalDialog]);

  const handleStatusChangedConfirm = useCallback(async () => {
    try {
      setLoading(true);
      handleClosePendingDialog();

      if (onSubmitEntityStatusChange)
        await onSubmitEntityStatusChange({ entityId, status, reason });
      else await entityStatusChange({ entityId, status, reason });

      condTrueFalse(onSuccess, onSuccess);
    } finally {
      setLoading(false);
    }
  }, [status, reason]);

  const handleEntityApprove = useCallback(async ({ isSendEmail } = {}) => {
    // handle approve here
    handleCloseApprovalDialog();

    try {
      setLoading(true);

      if (onSubmitEntityApprove)
        await onSubmitEntityApprove({
          entityId,
          status: 'VERIFIED',
          reason: '',
          isSendEmail,
        });
      else
        await entityApprove({
          entityId,
          sendNotificationEmailToUser: isSendEmail,
        });

      condTrueFalse(onSuccess, onSuccess);
    } finally {
      setLoading(false);
    }
  }, []);

  const feedbackMenus = useMemo(
    () =>
      menus.map((menu) => ({
        onClick: () => {
          setStatus(menu.value);
          handleOpenPendingDialog();
        },
        ...menu,
      })),
    [menus],
  );

  const handleReasonChange = useCallback((e) => setReason(e.target.value), []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ShowMe when={onDownloadClick}>
          <Grid item xs={12} sm={6} md={3.5}>
            <Button
              data-testid="button-download"
              sx={{ padding: '10px' }}
              startIcon={<FileDownloadOutlined />}
              onClick={onDownloadClick}
              disabled={downloadBtnDisabled}
            >
              Download Data
            </Button>
          </Grid>
        </ShowMe>
        <ShowMe when={onDelete}>
          <Grid item xs={12} sm={6} md={3.5}>
            <TextButton
              sx={{
                maxHeight: '19px',
                height: '100%',
              }}
              variant="bodyGrey"
              onClick={onDelete}
              disabled={deleteBtnDisabled}
            >
              Delete
            </TextButton>
          </Grid>
        </ShowMe>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid
          item
          container
          justifyContent="flex-end"
          columnSpacing={3}
          alignItems="center"
        >
          <ShowMe when={withFeedbackBtn}>
            <Grid item xs={6} sm={4} md={3} justifyContent="center">
              <Button
                variant="text"
                data-testid="button-feedback"
                onClick={handleClick}
                buttoncolor="transparent"
                textColor="bluish"
                endIcon={<ExpandMore color="jointIcon" />}
                disabled={feedbackBtnDisabled}
                buttonstyles={{ padding: '10px' }}
              >
                Feedback
              </Button>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={transformOriginProps}
                anchorOrigin={anchorOriginProps}
              >
                {feedbackMenus.map(({ itemLabel, onClick }) => (
                  <MenuItem
                    key={itemLabel}
                    data-testid={`menu-item-${itemLabel}`}
                    onClick={onClick}
                    sx={{ padding: 1.5 }}
                  >
                    <Typography variant="body">{itemLabel}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </ShowMe>
          <Grid item xs={6} sm={4} md={3} justifyContent="center">
            <Button
              data-testid="button-approval"
              buttoncolor="purpleHazeGradient"
              sx={{ padding: '10px 34px' }}
              onClick={handleClickApproval}
              disabled={approvedBtnDisabled}
            >
              {approvalLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <AdminSendEmailConfirmationDialog
        data-testid="approve-confirmation-dialog"
        open={approvalDialogOpen}
        title="Confirmation"
        subtitle={confirmationDialogSubtitle}
        onClose={handleCloseApprovalDialog}
        onConfirm={handleEntityApprove}
      />
      <AdminFeedbackDialog
        data-testid="feedback-confirmation-dialog"
        open={pendingDialogOpen}
        title="Feedback Reason"
        subtitle="Enter feedback reason for the investor"
        onClose={handleClosePendingDialog}
        onConfirm={handleStatusChangedConfirm}
        onReasonChange={handleReasonChange}
      />

      <Loading popup loading={loading} />
    </>
  );
};

export default Footer;
