import React, { useCallback, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import AdminPageContainer from 'components/AdminPageContainer';
import Tabs from 'components/Tabs';
import TabPanels from 'components/TabPanels';

import useAdminRestriction from 'hooks/useAdminRestriction';
import history from 'utils/history';
import { USER_STATUS, USER_STATUS_OPTIONS } from 'constants/userStatus';
import { ReactComponent as AddCircleIcon } from 'assets/icons/add-circle.svg';
import { ReactComponent as ManageAccountsIcon } from 'assets/icons/manage-accounts.svg';

import UserTabContent from './UserTabContent';
import AdminTabContent from './AdminTabContent';

const tabItems = [
  {
    key: 'users',
    label: <Typography variant="h6">Users</Typography>,
    Icon: PersonOutlinedIcon,
    content: <UserTabContent />,
  },
  {
    key: 'administrators',
    label: <Typography variant="h6">Administrators</Typography>,
    Icon: ManageAccountsIcon,
    content: <AdminTabContent />,
  },
];

const AdminUsers = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTabIdx, setActiveTabIdx] = useState(0);

  const handleSearch = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleRedirectAddUser = useCallback(() =>
    history.push('/admin/users/add'),
  );

  const handleChangeTab = useCallback((_, tabIndex) => {
    setActiveTabIdx(tabIndex);
  }, []);

  return (
    <AdminPageContainer
      addEntityBtnText="Add new User"
      addEntityBtnIcon={AddCircleIcon}
      onAddEntity={haveCrudPermission && handleRedirectAddUser}
      onFilter={(x) => x}
      onSearch={handleSearch}
      searchValue={searchQuery}
      pageTitle="Users"
      filterValue={USER_STATUS.ALL}
      filterOptions={USER_STATUS_OPTIONS}
    >
      <Grid item width="100%">
        <Tabs
          value={activeTabIdx}
          onChange={handleChangeTab}
          centered={false}
          sx={{ height: '44px', minHeight: '44px' }}
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <TabComponents
            activeTabIdx={activeTabIdx}
            tabItems={tabItems}
            style={{ borderRadius: activeTabIdx === 0 ? '6px 0 0 0' : 'unset' }}
          />
        </Tabs>
        <TabPanels
          value={activeTabIdx}
          tabItems={tabItems}
          boxPanelProps={{ sx: { p: '0px' } }}
        />
      </Grid>
    </AdminPageContainer>
  );
};

export default AdminUsers;
