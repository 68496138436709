import { COUNTRY_OPTIONS } from 'constants/investmentEntity';

const filterCountryOptions = ({ taxPurposes }) =>
  COUNTRY_OPTIONS.filter((country) => {
    if (country.value === 'AU')
      return taxPurposes.every(
        (_taxPurpose) => _taxPurpose.hasAustraliaTax === false,
      );
    return !taxPurposes.find(
      (_taxPurpose) => _taxPurpose.otherCountryTax === country.value,
    );
  });

export default filterCountryOptions;
