import * as yup from 'yup';
import trimMaskInput from 'utils/trimMaskInput';

const taxDetailsValidationSchema = yup.object({
  hasAustraliaTax: yup.boolean().required().label('Australia Tax Purpose'),
  australiaTax: yup
    .string()
    .label('Tax File Number (TFN)*')
    .when('hasAustraliaTax', {
      is: true,
      then: yup.string().transform(trimMaskInput).min(5).max(18).required(),
    }),
  hasOtherCountryTax: yup
    .boolean()
    .label('Residence other country tax purpose')
    .when('hasAustraliaTax', {
      is: true,
      then: yup.boolean().required(),
    }),
  otherCountryTax: yup
    .string()
    .label('Other country name')
    .when(['hasOtherCountryTax', 'hasAustraliaTax'], {
      is: (hasOtherCountryTax, hasAustraliaTax) =>
        hasOtherCountryTax || !hasAustraliaTax,
      then: yup.string().min(1).required(),
    }),
  isTINAvailable: yup
    .boolean()
    .label('Other country TIN or equivalent')
    .when(['hasOtherCountryTax', 'hasAustraliaTax'], {
      is: (hasOtherCountryTax, hasAustraliaTax) =>
        hasOtherCountryTax || !hasAustraliaTax,
      then: yup.boolean().required(),
    }),
  otherCountryTIN: yup
    .string()
    .label('TIN (Tax Index Number*)')
    .when(['isTINAvailable', 'hasOtherCountryTax'], {
      is: (isTINAvailable, hasOtherCountryTax) =>
        !!isTINAvailable && !!hasOtherCountryTax,
      then: yup.string().min(5).max(18).required(),
    }),
  noTINReason: yup
    .string()
    .label('Reason not providing your TIN')
    .when(['isTINAvailable', 'hasOtherCountryTax'], {
      is: (isTINAvailable, hasOtherCountryTax) =>
        isTINAvailable === false && !!hasOtherCountryTax,
      then: yup.string().required(),
    }),
  unableObtainTinReason: yup
    .string()
    .label('Reason not providing your TIN')
    .when(['noTINReason', 'hasOtherCountryTax', 'isTINAvailable'], {
      is: (noTINReason, hasOtherCountryTax, isTINAvailable) =>
        noTINReason === '2' && !!hasOtherCountryTax && isTINAvailable === false,
      then: yup.string().required(),
    }),
});

export default taxDetailsValidationSchema;
