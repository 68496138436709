import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';

import TotalHeaders from 'components/TotalHeaders';
import UploadCategoryList from 'components/UploadCategoryList';
import useUploadCategoryList from 'hooks/useUploadCategoryList';
import SearchFilterHeader from '../SearchFilterHeader';
import {
  deleteStatementDocuments,
  uploadStatementDocuments,
} from './HelperFunction';

const StatementDocuments = forwardRef(
  ({ userId, setFieldValue, fieldKey, values, readOnly }, ref) => {
    const handleChangeSearch = useCallback(() => true);
    const statementDocumentForm = useFormik({
      initialValues: {
        categories: values,
      },
    });

    const {
      tempDeletedFiles,
      tempUploadedFiles,
      setTempDeletedFiles,
      setTempUploadedFiles,
      handleDeleteFiles,
      handleUploadFiles,
      deleteFilesOnCancel,
    } = useUploadCategoryList({
      deleteFileAPICall: deleteStatementDocuments,
      uploadFileAPICAll: uploadStatementDocuments,
      valueBeforeSubmit: values,
      id: userId,
      deleteOnUnmount: false,
    });

    useEffect(() => {
      if (setFieldValue) setFieldValue('tempDeletedFiles', tempDeletedFiles);
    }, [tempDeletedFiles]);

    useEffect(() => {
      if (setFieldValue)
        setFieldValue(fieldKey, statementDocumentForm.values.categories);
    }, [statementDocumentForm.values]);

    useImperativeHandle(
      ref,
      () => ({
        cleanupFiles: () => {
          deleteFilesOnCancel();
        },
        resetTempFiles: () => {
          setTempDeletedFiles(new Map());
          setTempUploadedFiles([]);
        },
      }),
      [tempDeletedFiles, tempUploadedFiles],
    );

    const totalDocuments = useMemo(
      () =>
        statementDocumentForm.values.categories.reduce(
          (total, category) => total + (category?.documents?.length || 0),
          0,
        ),
      [statementDocumentForm.values.categories],
    );

    return (
      <Grid container>
        <TotalHeaders
          totals={[
            { totalLabel: 'Total Documents', totalValue: totalDocuments },
          ]}
          disableExportBtn={readOnly}
        >
          <SearchFilterHeader onChangeSearch={handleChangeSearch} />
        </TotalHeaders>

        <UploadCategoryList
          withSubFolders
          value={statementDocumentForm.values.categories}
          setFieldValue={statementDocumentForm.setFieldValue}
          fieldName="categories"
          onUploadFiles={handleUploadFiles}
          onDeleteFiles={handleDeleteFiles}
          disabled={readOnly}
          maxSize={15}
        />
      </Grid>
    );
  },
);

export default StatementDocuments;
