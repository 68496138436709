import React from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import BackButton from 'components/Button/BackButton';

const AdminFormTitle = ({ title, handleBack }) =>
  handleBack ? (
    <>
      <Grid item>
        <BackButton
          onClick={handleBack}
          typographyVariant="display4"
          label={title}
        />
      </Grid>
      <Grid item xs>
        <Divider flexItem variant="middle" />
      </Grid>
    </>
  ) : (
    <Typography variant="display4">{title}</Typography>
  );

export default AdminFormTitle;
