import React from 'react';
import SummarySection from 'components/SummarySection';

import { INSTALLMENT_INFORMATION_FIELD } from 'constants/investmentEntity';

const InstallmentInformationSummary = ({
  title,
  values,
  setValues,
  withEdit = true,
  sectionListView = false,
}) => (
  <SummarySection
    title={title}
    fields={INSTALLMENT_INFORMATION_FIELD}
    values={values}
    setValues={setValues}
    withEdit={withEdit}
    sectionListView={sectionListView}
  />
);

export default InstallmentInformationSummary;
