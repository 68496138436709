import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import palette from 'theme/palette';

const BackToTopWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  width: '76px',
  height: '64px',
  borderRadius: '6px',
  boxShadow: '0px 4px 10px 3px rgb(0 0 0 / 11%)',
  background: 'rgba(255, 255, 255, 0.8)',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.8)',
  },
  color: theme.palette.reach.purpleHaze,
}));

const BackToTop = ({ scrollToOptions = [0, 0] }) => {
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleBackToTop = useCallback(() => {
    window.scrollTo(...scrollToOptions);
  }, [scrollToOptions]);

  return (
    <Zoom in={scrollTrigger}>
      <BackToTopWrapper onClick={handleBackToTop} role="presentation">
        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{ height: '45px', marginTop: '-10px' }}
          >
            <KeyboardArrowUpIcon
              sx={{
                color: palette.reach.purpleHaze,
                width: '64px',
                height: '64px',
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="xsmall" sx={{ textTransform: 'initial' }}>
              Back to top
            </Typography>
          </Grid>
        </Grid>
      </BackToTopWrapper>
    </Zoom>
  );
};

export default memo(BackToTop);
