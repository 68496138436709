import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import EllipsisTypography from 'components/Typography/EllipsisTypography';
import ImageContent from 'components/FundCard/FundCardLandscape/ImageContent';
import config from 'utils/config';
import InnerHtmlContainer from 'components/InnerHtmlContainer';

const fundContentBorderTopWidth = 8;

const defaultDate = new Date().toISOString();

const FeaturedCard = ({
  headerImageSrc = 'https://static.ffx.io/images/$zoom_0.143%2C$multiply_0.7725%2C$ratio_1.5%2C$width_756%2C$x_0%2C$y_31/t_crop_custom/q_86%2Cf_auto/dfdfd4a577ab171b721ba0bd6e5a4a845f0db52a',
  logoSrc = '',
  title = 'The 11% Yield That Isn’t in Your Mutual Fund',
  description = 'Business development companies pay out at least 90% of the interest they earn in dividends—but carry extra risk',
  category = 'article category',
  publishedDate = defaultDate,
  height = '100%',
  maxHeight = '220px',
  onClick,
  imageHeight,
  htmlStringDescription,
}) => {
  const descriptionComponent = useMemo(
    () =>
      htmlStringDescription ? (
        <InnerHtmlContainer
          htmlData={description}
          setDefaultStyle="padding: 0; max-height: 45px; overflow: hidden; background-color: transparent"
        />
      ) : (
        description
      ),
    [htmlStringDescription, description],
  );

  return (
    <Grid
      container
      columnSpacing={2}
      minHeight={height}
      maxHeight={height}
      sx={{
        background: config.system.appColours.cardBackground,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Grid
        item
        xs={12}
        sm={4.5}
        xl={3.5}
        sx={{
          borderTop: [`${fundContentBorderTopWidth}px black solid`, 'none'],
        }}
      >
        <ImageContent
          height="100%"
          logoSrc={logoSrc}
          headerImageSrc={headerImageSrc}
          imageStyles={{ maxHeight: imageHeight }}
        />
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={7.5}
        xl={8.5}
        direction="column"
        sx={{
          borderTop: ['none', `${fundContentBorderTopWidth}px black solid`],
          position: 'relative',
          transition: 'border-top .5',
        }}
      >
        <Grid container item xs={12} sm={8} direction="row" pt={2}>
          <Grid item xs={12} sm={8} alignSelf="center" p={[2, 0]}>
            <EllipsisTypography
              variant="h3"
              display="block"
              fontWeight={700}
              ellipsisAtLine={2}
            >
              {title}
            </EllipsisTypography>
            <EllipsisTypography
              variant="smallStatic"
              marginTop={1.5}
              ellipsisAtLine={2}
            >
              {descriptionComponent}
            </EllipsisTypography>
            <Typography fontSize="11px" mt={2.1}>
              <b>{category}</b> •{' '}
              {new Date(publishedDate).toLocaleString('en-AU', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(FeaturedCard);
