import * as yup from 'yup';
import password from '../password';

const newPasswordValidationSchema = yup.object({
  ...password,
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords do not match')
    .required()
    .label('Password confirmation'),
});

export default newPasswordValidationSchema;
