import React, { useCallback, useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ChipList from 'components/ChipList';
import Input from 'components/Input';
import InnerHtmlContainer from 'components/InnerHtmlContainer';

import formatCurrency from 'utils/formatCurrency';
import getNumbers from 'utils/strings/getNumbers';

const redemptionPercentages = [
  {
    label: '10%',
    value: 0.1,
  },
  {
    label: '25%',
    value: 0.25,
  },
  {
    label: '50%',
    value: 0.5,
  },
  {
    label: '75%',
    value: 0.75,
  },
  {
    label: 'max',
    value: 1,
  },
];

const RedemptionAmount = ({ onChange, values, errors }) => {
  const [selectedAmountPercentage, setSelectedAmountPercentage] =
    useState(null);

  const handleClickRedemptionPercentage = useCallback(
    (index) => {
      setSelectedAmountPercentage(index);

      const newRedemptionAmount =
        values.selectedPortfolio.unit * redemptionPercentages[index].value;

      onChange({
        target: {
          name: 'redemptionUnitAmount',
          value: newRedemptionAmount,
        },
      });
    },
    [values?.selectedPortfolio?.unit],
  );

  const estimatedValue = useMemo(() => {
    if (
      !values.selectedPortfolio?.fundUpdateOverview?.unitPriceNet ||
      !values.redemptionUnitAmount
    )
      return 0;

    return (
      values.selectedPortfolio.fundUpdateOverview.unitPriceNet *
      getNumbers(values.redemptionUnitAmount)
    );
  }, [
    values.selectedPortfolio?.fundUpdateOverview?.unitPriceNet,
    values.redemptionUnitAmount,
  ]);

  return (
    <Grid container xs={12} justifyContent="center">
      <Grid item xs={12} my={8}>
        <Divider flexItem variant="middle" />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Typography variant="title" sx={{ float: 'left' }} mb={2}>
          Please enter the number of units you&apos;d like to redeem:
        </Typography>
        <Input
          fullWidth
          withThousandSeparator
          id="redemptionUnitAmount"
          name="redemptionUnitAmount"
          type="number"
          placeholder="0"
          onChange={onChange}
          value={values.redemptionUnitAmount}
          errorValue={errors.redemptionUnitAmount}
        />
        <Typography variant="title" sx={{ float: 'left' }} mb={3}>
          Estimated value*:{' '}
          {formatCurrency({
            currency: values.selectedPortfolio?.currency,
            value: estimatedValue,
          })}
        </Typography>

        <ChipList
          onClick={handleClickRedemptionPercentage}
          selectedIndex={selectedAmountPercentage}
          items={redemptionPercentages}
        />
      </Grid>
      <Grid item xs={12} mt={8}>
        <InnerHtmlContainer
          htmlData={
            values.selectedPortfolio?.fundConfiguration?.redemptionRequestTerms
          }
          setDefaultStyle="padding: 0;"
        />
      </Grid>
    </Grid>
  );
};

export default RedemptionAmount;
