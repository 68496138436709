import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import FailedDialog from 'components/Dialog/FailedDialog';
import FundCard from 'components/FundCard';
import Loading from 'components/Loading';
import RedemptionRequestSuccessDialog from 'components/Dialog/RedemptionRequestSuccessDialog';
import ShowMe from 'components/ShowMe';
import { useStores } from 'components/StateProvider';

import formatCurrency from 'utils/formatCurrency';
import getFullName from 'utils/getFullName';
import thousandSeparator from 'utils/strings/thousandSeparators';
import PayloadMappers from 'utils/PayloadMappers';
import useDialog from 'hooks/useDialog';
import useSteps from 'hooks/useSteps';

import Services from 'api/services';
import redemptionRequestSchemas from 'validations/schema/redemptionRequest/redemptionRequestSchemas';
import palette from 'theme/palette';

import SelectEntity from './Steps/SelectEntity';
import RedemptionAmount from './Steps/RedemptionAmount';
import SelectBankAccount from './Steps/SelectBankAccount';
import Confirmation from './Steps/Confirmation';

const steps = [
  {
    label: 'Select Entity & Fund',
    component: <SelectEntity />,
  },
  {
    label: 'Redemption Request Amount',
    component: <RedemptionAmount />,
  },
  {
    label: 'Select Bank Account',
    component: <SelectBankAccount />,
  },
  {
    label: 'Confirmation',
    component: <Confirmation />,
  },
];

const stepComponents = steps.map((step) => step.component);
const stepLabels = steps.reduce((labels, step) => {
  if (!step.label) return labels;

  return labels.concat({ label: step.label });
}, []);

const RedemptionSteps = () => {
  const { fundId } = useParams();
  const [error, setError] = useState('');
  const [startAtLastStep, setStartAtLastStep] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    isDialogOpen: isSubmitDialogOpen,
    handleOpenDialog: handleOpenSubmitDialog,
    handleCloseDialog: handleCloseSubmitDialog,
  } = useDialog();

  const {
    isDialogOpen: isSuccessDialogOpen,
    handleOpenDialog: handleOpenSuccessDialog,
  } = useDialog();

  const {
    auth: { user },
  } = useStores();

  const handleSubmit = useCallback(async (formValues) => {
    try {
      setLoading(true);

      const payload = PayloadMappers.fundRedemptionRequest(formValues);

      await Services.addFundRedemptionRequest({ payload });

      handleOpenSuccessDialog();
    } catch (err) {
      setError('Redemption request submission failed');
    } finally {
      handleCloseSubmitDialog();
      setLoading(false);
    }
  }, []);

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: investmentApplicationForm,
  } = useSteps({
    stepComponents,
    validateFormik: true,
    validationSchemas: redemptionRequestSchemas,
    formParameters: {
      initialValues: {
        investmentAmount: 0,
        investmentEntity: '',
        signedAt: new Date(),
        signedBy: getFullName(user, { withMiddleName: true }),
        isConfirmed: false,
        selectedPortfolio: {},
      },
      onSubmit: handleSubmit,
    },
    firstStepGoBackURL: '/dashboard/portfolio',
  });

  useEffect(() => {
    investmentApplicationForm?.validateForm();
  }, [pageIndex, investmentApplicationForm?.validateForm]);

  const handleNextStep = useCallback(async () => {
    if (pageIndex < stepComponents.length - 1) {
      setStartAtLastStep(false);
      handleNext();

      return;
    }

    handleOpenSubmitDialog();
  }, [handleNext, pageIndex]);

  const handleBackStep = useCallback(
    (args) => {
      setStartAtLastStep(true);
      handleBack(args);
    },
    [handleBack],
  );

  const containerProps = useMemo(
    () => ({
      withHeader: false,
      isNextButtonDisabled:
        !!Object.keys(investmentApplicationForm.errors).length ||
        investmentApplicationForm.isSubmitting,
      backgroundcolor: 'transparent',
      sx: {
        px: [0, 2, 10, 15, 25],
      },
      onBack: handleBackStep,
      onNext: handleNextStep,
      nextButtonLabel: 'Confirm',
    }),
    [
      pageIndex,
      investmentApplicationForm.values,
      investmentApplicationForm.errors,
      investmentApplicationForm.isSubmitting,
    ],
  );

  const contentList = useMemo(() => {
    const { selectedPortfolio } = investmentApplicationForm.values;

    if (!selectedPortfolio) return [];

    const content = [
      {
        heading: 'Units',
        value: thousandSeparator(selectedPortfolio.unit),
      },
      {
        heading: 'Est. Total Portfolio Value',
        value: formatCurrency({
          currency: selectedPortfolio.dealCurrency,
          value: selectedPortfolio.fundUpdateOverview?.estimatedValue,
        }),
      },
    ];

    if (investmentApplicationForm.values.redemptionUnitAmount) {
      content.push({
        heading: 'Redemption Request (units)',
        value: thousandSeparator(
          investmentApplicationForm.values.redemptionUnitAmount,
        ),
      });
    }

    return content;
  }, [
    pageIndex,
    investmentApplicationForm.values.redemptionUnitAmount,
    investmentApplicationForm.values.selectedPortfolio,
  ]);

  const redemptionRequestSummaryValues = useMemo(() => {
    const { selectedPortfolio, entityDetail } =
      investmentApplicationForm.values;

    if (!selectedPortfolio.id || !entityDetail) return {};

    return {
      fundName: selectedPortfolio.fundName,
      className: selectedPortfolio.className,
      redemptionUnitAmount:
        investmentApplicationForm.values.redemptionUnitAmount,
      entityName: entityDetail.name,
      entityAddress: entityDetail.address,
      entityEmail: entityDetail.email,
      accountName: selectedPortfolio.bankAccount.accountName,
      bsb: selectedPortfolio.bankAccount.bsb,
      accountNumber: selectedPortfolio.bankAccount.accountNumber,
      signedBy: investmentApplicationForm.values.signedBy,
    };
  }, [investmentApplicationForm.values]);

  return (
    <InvestmentEntityStepContainer
      title=""
      containerProps={containerProps}
      stepperContainerProps={{ sm: 12, md: 12 }}
      steps={stepLabels}
      activeStep={pageIndex}
      withCancelAll={false}
      fullWidthChildren
      lineStepper
    >
      <ShowMe
        when={pageIndex && investmentApplicationForm.values.selectedPortfolioId}
      >
        <Grid mb={8}>
          <FundCard
            viewOnly
            mode="landscape"
            title={investmentApplicationForm.values.selectedPortfolio?.fundName}
            fundType={investmentApplicationForm.values.selectedPortfolio?.type}
            headerBgColor={
              investmentApplicationForm.values.selectedPortfolio?.headerColor ||
              palette.reach.purple
            }
            headerImageProps={{
              style: { position: 'absolute', bottom: '4px' },
            }}
            contentList={contentList}
            headerImageSrc={
              investmentApplicationForm.values.selectedPortfolio?.fund
                ?.fundHeaderStorage?.header
            }
            logoSrc={
              investmentApplicationForm.values.selectedPortfolio?.fund
                ?.fundHeaderStorage?.logo
            }
            isOpen={investmentApplicationForm.values.selectedPortfolio?.isOpen}
            disabled={
              !investmentApplicationForm.values.selectedPortfolio?.isOpen
            }
            isInvested
          />
        </Grid>
      </ShowMe>
      {React.cloneElement(stepComponents[pageIndex], {
        startAtLastStep,
        onNext: handleNextStep,
        onBack: handleBackStep,
        isNextButtonDisabled: containerProps.isNextButtonDisabled,
        steps: stepLabels,
        values: investmentApplicationForm.values,
        setValues: investmentApplicationForm.setValues,
        isSubmitting: investmentApplicationForm.isSubmitting,
        onChange: investmentApplicationForm.handleChange,
        summaryValues: redemptionRequestSummaryValues,
        selectedPortfolio: investmentApplicationForm.values?.selectedPortfolio,
        errors: investmentApplicationForm.errors,
        touched: investmentApplicationForm.touched,
        onBlur: investmentApplicationForm.handleBlur,
      })}

      <Loading popup loading={loading} />
      <FailedDialog
        open={!!error}
        onConfirm={() => setError('')}
        subtitle={error}
      />

      <ConfirmationDialog
        open={!!isSubmitDialogOpen}
        title="Redemption Request"
        infoText=""
        subtitle="Do you want to submit this redemption request?"
        onClose={handleCloseSubmitDialog}
        confirmButtonLabel="Submit"
        onConfirm={investmentApplicationForm.handleSubmit}
      />

      <RedemptionRequestSuccessDialog open={isSuccessDialogOpen} />
    </InvestmentEntityStepContainer>
  );
};

export default memo(RedemptionSteps);
