import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const LoadingSpinner = () => (
  <Grid item xs={12} container alignItems="center" justifyContent="center">
    <Grid item textAlign="center">
      <CircularProgress size={25} data-testid="loading-spinner" />
    </Grid>
  </Grid>
);

export default LoadingSpinner;
