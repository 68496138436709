import React from 'react';
import { Link } from 'react-router-dom';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material';

import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibleIcon from '@mui/icons-material/VisibilityOutlined';

import ShowMe from 'components/ShowMe';
import { LinkComp } from 'components/TreeView/ExpandableListItem';

import palette from 'theme/palette';

import { ReactComponent as Document } from 'assets/icons/document-file.svg';

const DocumentItem = ({
  folder,
  title,
  id,
  token,
  enableView,
  enableDownload,
  type,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ListItem
      key={`${title}-${id}`}
      disableGutters
      secondaryAction={
        <>
          <LinkComp folder={folder} token={token} type={type}>
            <IconButton
              sx={{ color: palette.link }}
              disabled={!enableView}
              state={{ folder }}
            >
              <VisibleIcon />
            </IconButton>
          </LinkComp>
          <IconButton
            component={Link}
            to={`/document/download?type=documents&token=${token}`}
            target="_blank"
            sx={{ color: palette.link }}
            disabled={!enableDownload}
          >
            <DownloadIcon />
          </IconButton>
        </>
      }
    >
      <ShowMe when={!isMobileScreen}>
        <ListItemIcon>
          <Document />
        </ListItemIcon>
      </ShowMe>
      <ListItemText
        primary={title}
        sx={{
          color: palette.text.primary,
          maxWidth: ['75%', '60%', '100%'],
        }}
      />
    </ListItem>
  );
};

export default DocumentItem;
