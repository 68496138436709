import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PrevIcon from '@mui/icons-material/ChevronLeft';

import ImageContent from 'components/FundCard/FundCardLandscape/ImageContent';
import Loading from 'components/Loading';
import SecondaryButton from 'components/Button/SecondaryButton';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';

import ArticleDetailSection from './ArticleDetailSection';
import { fetchArticle, fetchFeaturedArticles } from './HelperFunction';

const defaultHeaderImg =
  'https://images.skyscrapercenter.com/uploads/Sydney_-2014_(CC__BY)_Marc-Dalmuder201217-121248.jpg';
const handleRouteToDashboard = () => history.push('/');

const ArticleDetail = () => {
  const { articleId } = useParams();

  const getArticle = useMemo(() => fetchArticle(articleId), [articleId]);
  const { apiData: article, isFetchLoading } = useFetchApi({
    url: getArticle,
    dependencies: [getArticle],
  });

  const getFeaturedArticles = useMemo(
    () => fetchFeaturedArticles(articleId),
    [articleId],
  );
  const {
    apiData: featuredArticles,
    isFetchLoading: isFetchFeaturedArticlesLoading,
  } = useFetchApi({
    url: getFeaturedArticles,
  });

  if (isFetchLoading || isFetchFeaturedArticlesLoading)
    return <Loading popup loading />;

  return (
    <Grid container rowSpacing={{ xs: 0, sm: 2 }} columnSpacing={2}>
      <Grid item>
        <SecondaryButton variant="outlined" onClick={handleRouteToDashboard}>
          <Grid container alignItems="center">
            <PrevIcon />
            <Typography variant="body">Back to Insight Page</Typography>
          </Grid>
        </SecondaryButton>
      </Grid>
      <Grid item xs={12}>
        <ImageContent
          height={415}
          headerImageSrc={article.articleHeaderImgUrl || defaultHeaderImg}
        />
      </Grid>
      <Grid item xs={12}>
        <ArticleDetailSection
          articleDetailData={article}
          trendingArticles={featuredArticles}
        />
      </Grid>
    </Grid>
  );
};

export default ArticleDetail;
