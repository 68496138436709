import { FUND_STATUS } from 'constants/funds';
import { PAYMENT_STATUS } from 'constants/investmentEntity';
import { USER_STATUS } from 'constants/userStatus';

const getFundStatusChipColor = ({ status, paymentStatus }) => {
  if (!status) return '';

  const fundStatus = status.toUpperCase();

  switch (true) {
    case fundStatus === FUND_STATUS.PUBLISHED:
    case fundStatus === USER_STATUS.VERIFIED:
    case fundStatus === 'LIVE':
      return 'publishedChipColor';
    case paymentStatus === PAYMENT_STATUS.PAYMENT_UNDER_REVIEW &&
      fundStatus === USER_STATUS.PENDING:
      return 'portfolioPendingChipColor';
    case fundStatus === FUND_STATUS.DRAFT:
    case fundStatus === FUND_STATUS['PUBLISHED-DRAFT']:
    case fundStatus === USER_STATUS.PENDING_OVERSEAS:
    case fundStatus === USER_STATUS.PENDING:
      return 'draftChipColor';
    case paymentStatus === PAYMENT_STATUS.PAYMENT_COMPLETED:
      return 'investedChipColor';
    default:
      return `${status.toLowerCase()}ChipColor`;
  }
};

const getUserStatusChipColor = (status) => {
  if (!status) return '';

  switch (status.toUpperCase()) {
    case USER_STATUS.UNVERIFIED:
    case USER_STATUS.UNVERIFIED_OVERSEAS:
      return 'draftChipColor';
    case USER_STATUS.VERIFIED:
    case USER_STATUS.PENDING:
    case USER_STATUS.CANCELLED:
      return 'publishedChipColor';
    case USER_STATUS.VERIFIED_OVERSEAS:
    case USER_STATUS.PENDING_OVERSEAS:
    case USER_STATUS.CANCELLED_OVERSEAS:
      return 'publishedOverseasChipColor';
    default:
      return `${status.toLowerCase()}ChipColor`;
  }
};

export { getFundStatusChipColor, getUserStatusChipColor };
