import React from 'react';
import Typography from '@mui/material/Typography';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import FeedOutlined from '@mui/icons-material/FeedOutlined';
import InsertChartOutlined from '@mui/icons-material/InsertChartOutlined';

import services from 'api/services';

import history from 'utils/history';
import condTrueFalse from 'utils/condTrueFalse';
import { MY_DOCUMENTS } from 'constants/userManagement';
import { getIsDeletedStatus } from 'containers/Admin/Funds/Details/Dataroom/HelperFunction';

import InvestmentEntityList from './InvestmentEntityList';
import InvestmentFundList from './InvestmentFundList';
import UserProfile from './UserProfile';
import StatementDocuments from './StatementDocuments';
import Verification from './Verification';
import FundRedemptionRequestList from './FundRedemptionRequestList';

const redirectToEntityDetails = ({ row }) => {
  history.push(`/admin/approvals/details/${row.id}`, {
    onBackRoute: `${history.location.pathname}${history.location.search}`,
  });
};

const redirectToFundDetails = ({ row }) => {
  history.push(`/admin/approvals/fund-details/${row.id}`, {
    onBackRoute: `${history.location.pathname}${history.location.search}`,
  });
};

const redirectToFundRedemptionRequestDetails = ({ row }) => {
  history.push(`/admin/approvals/fund-redemption-request-details/${row.id}`, {
    onBackRoute: `${history.location.pathname}${history.location.search}`,
  });
};

export const userDetailsTabItems = ({ disabled, ...props } = {}) => [
  {
    key: 'userProfile',
    Icon: InsertChartOutlined,
    label: <Typography variant="h6">User Profile</Typography>,
    content: <UserProfile fieldKey="userProfile" {...props} />,
  },
  {
    key: 'verification',
    Icon: FeedOutlined,
    label: <Typography variant="h6">Verification</Typography>,
    content: <Verification fieldKey="verification" {...props} />,
    disabled,
  },
  {
    key: 'investmentEntity',
    Icon: FeedOutlined,
    label: <Typography variant="h6">Investment Entity</Typography>,
    content: (
      <InvestmentEntityList onRowClick={redirectToEntityDetails} {...props} />
    ),
    disabled,
  },
  {
    key: 'investmentFunds',
    Icon: BarChartOutlined,
    label: <Typography variant="h6">Investment Funds</Typography>,
    content: (
      <InvestmentFundList onRowClick={redirectToFundDetails} {...props} />
    ),
    disabled,
  },
  {
    key: 'fundRedemptionRequests',
    Icon: BarChartOutlined,
    label: <Typography variant="h6">Redemptions</Typography>,
    content: (
      <FundRedemptionRequestList
        onRowClick={redirectToFundRedemptionRequestDetails}
        {...props}
      />
    ),
    disabled,
  },
  {
    key: 'documents',
    Icon: FeedOutlined,
    label: <Typography variant="h6">Documents</Typography>,
    content: <StatementDocuments fieldKey="categories" {...props} />,
    disabled,
  },
];

export const updateUserDetails = async (userId, payload) => {
  try {
    await services.adminEditUser(userId, payload);
    return true;
  } catch (_) {
    return false;
  }
};

export const deleteUser = async ({ userId, status, cb }) => {
  try {
    const res = await services.adminDeleteUser(userId, status);

    condTrueFalse(cb, cb);

    return res;
  } catch (e) {
    return e;
  }
};

export const adminUserVerification = async ({ entityId, status, reason }) => {
  try {
    const payload = {
      statusVerification: status,
      feedback: reason,
    };
    const res = await services.adminUserVerification({ id: entityId, payload });
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const mapDocumentsData = ({
  title,
  documents = [],
  subFolders = [],
  id,
}) => ({
  id,
  category: title,
  documents: documents?.map((document) => ({
    ...document,
    isDeleted: !!document?.isDeleted,
  })),
  disabled: title === MY_DOCUMENTS,
  subFolders: subFolders?.map(mapDocumentsData),
});

export const getOrderedDocuments = ({ tempDeletedFiles, categories }) => {
  const deletedDataRooms = [...tempDeletedFiles.values()].reduce(
    (deletedFiles, category, index) => {
      if (!category.isDeleted) return deletedFiles;

      return deletedFiles.concat({
        ...category,
        orderId: index,
      });
    },
    [],
  );

  const orderedDocuments = categories
    .map((category, index) => ({
      ...category,
      orderId: index + 1,
      ...getIsDeletedStatus({
        dataRoomId: category.id,
        documents: category.documents,
        deletedFiles: tempDeletedFiles,
        folderId: category.folderId,
      }),
      subFolders: category.subFolders?.map((subFolder, subFolderIdx) => ({
        ...subFolder,
        ...getIsDeletedStatus({
          dataRoomId: subFolder.id,
          documents: subFolder.documents,
          deletedFiles: tempDeletedFiles,
          folderId: subFolder.folderId,
        }),
        orderId: subFolderIdx + 1,
      })),
    }))
    // Merge deleted category to payload.
    .concat(deletedDataRooms);

  return orderedDocuments;
};
