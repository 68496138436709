import React, { useCallback, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import condTrueFalse from 'utils/condTrueFalse';

import AdminConfirmationDialog from './AdminConfirmationDialog';

const AdminSendEmailConfirmationDialog = ({
  checkboxLabel = 'Send Email notification to user?',
  onConfirm,
  ...props
}) => {
  const [isSendEmail, setIsSendEmail] = useState(true);

  const handleChange = useCallback((e) => setIsSendEmail(e.target.checked), []);

  const handleConfirm = useCallback(
    () => condTrueFalse(onConfirm, () => onConfirm({ isSendEmail })),
    [onConfirm, isSendEmail],
  );

  return (
    <AdminConfirmationDialog
      data-testid="admin-send-email-confirmation-dialog"
      title="Send Email"
      subtitle="Do you want to send Email to the investor?"
      onConfirm={handleConfirm}
      {...props}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isSendEmail}
            name="isSendEmail"
            onChange={handleChange}
          />
        }
        label={<Typography variant="small">{checkboxLabel}</Typography>}
      />
    </AdminConfirmationDialog>
  );
};

export default AdminSendEmailConfirmationDialog;
