import * as yup from 'yup';

import { PAYMENT_OPTIONS } from 'constants/investmentEntity';

const updateInvestmentAmountValidationSchemas = yup.object({
  paymentType: yup.string().required().label('Payment Type'),
  totalInvestment: yup.string().required().label('Total Investment'),
  investmentUnit: yup.string().required().label('Investment Units'),
  amountPayableToday: yup.string().required().label('Amount Payable Today'),
  accessFee: yup.string().required().label('Access Fee'),
  applicationFee: yup.string().required().label('Application Fee'),
  totalPayableToday: yup.string().required().label('Amount Received'),

  amountPayableLater: yup
    .string()
    .nullable()
    .when('paymentType', {
      is: PAYMENT_OPTIONS.PAY_IN_INSTALLMENT,
      then: yup.string().required(),
    })
    .label('Amount Payable Later'),
  total: yup
    .string()
    .when('paymentType', {
      is: PAYMENT_OPTIONS.PAY_IN_INSTALLMENT,
      then: yup.string().required(),
    })
    .label('Total'),
});

export default updateInvestmentAmountValidationSchemas;
