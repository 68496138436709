import React, { useCallback } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import debounce from 'utils/debounce';

import InputField from '..';

const SearchInput = ({ onSearch }) => {
  const handleChange = useCallback(
    debounce((e) => {
      const newValue = e.target.value;

      debounce(onSearch, 2000)(newValue);
    }),
    [],
  );

  return (
    <InputField
      uncontrolled
      onChange={handleChange}
      placeholder="Search"
      InputProps={{
        style: { minHeight: 'auto', maxHeight: '44px' },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
