import { useEffect, useState } from 'react';

const defaultMinutes = 1;
const defaultTokenExpiry = new Date(
  new Date().getTime() + defaultMinutes * 60000,
);

const INTERVAL_CONFIGURATIONS = {
  // Hours
  3600: { intervalValue: 60000 * 60 },
  // Minutes
  120: { intervalValue: 60000 },
  // Seconds
  60: { intervalValue: 1000 },
};

const useIsTokenExpiring = ({ tokenExpiry = defaultTokenExpiry } = {}) => {
  const [tokenTimeLeft, setTokenTimeLeft] = useState(
    new Date(tokenExpiry).getTime() / 1000 - new Date().getTime() / 1000,
  );
  const [intervalValue, setIntervalValue] = useState(1000);

  useEffect(() => {
    setTokenTimeLeft(
      new Date(tokenExpiry).getTime() / 1000 - new Date().getTime() / 1000,
    );
  }, [tokenExpiry]);

  useEffect(() => {
    if (tokenTimeLeft < 0) return undefined;

    // If token expiry more than 1 hour, set interval to 1 hour.
    if (tokenTimeLeft > 3600)
      setIntervalValue(INTERVAL_CONFIGURATIONS[3600].intervalValue);
    // If token expiry more than 2 minutes, set interval to 1 minute.
    else if (tokenTimeLeft > 120)
      setIntervalValue(INTERVAL_CONFIGURATIONS[120].intervalValue);

    const intervalId = setInterval(() => {
      const newTokenTimeLeft = tokenTimeLeft - intervalValue / 1000;
      setTokenTimeLeft(newTokenTimeLeft);

      if (
        intervalValue === INTERVAL_CONFIGURATIONS[3600].intervalValue &&
        newTokenTimeLeft < 3600
      )
        setIntervalValue(INTERVAL_CONFIGURATIONS[120].intervalValue);
      else if (
        intervalValue === INTERVAL_CONFIGURATIONS[120].intervalValue &&
        newTokenTimeLeft < 120
      )
        setIntervalValue(INTERVAL_CONFIGURATIONS[60].intervalValue);
    }, intervalValue);

    return () => {
      clearInterval(intervalId);
    };
  }, [intervalValue, tokenTimeLeft]);

  return { tokenTimeLeft, isTokenExpired: tokenTimeLeft < 0 };
};

export default useIsTokenExpiring;
