import getNumbers from './strings/getNumbers';

const calculateValueByPercentage = ({ value, percentage }) => {
  if (!value || !percentage) return 0;

  let valueNumber = value;
  if (typeof valueNumber !== 'number') valueNumber = getNumbers(valueNumber);

  return Number(((valueNumber / 100) * percentage).toFixed(2));
};

export default calculateValueByPercentage;
