import React, { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import history from 'utils/history';
import services from 'api/services';

import { useStores } from 'components/StateProvider';

import Header from '../Details/Header';
import Footer from '../Details/Footer';

const handleRedirectToApprovalList = () =>
  history.push('/admin/approvals?type=fundRedemptionRequest');

const DetailsContainer = ({
  children,
  fundRedemptionRequestId,
  isApproved,
  readOnly,
  onDelete,
}) => {
  const {
    general: { editingSummary },
  } = useStores();

  const menus = useMemo(
    () => [
      {
        itemLabel: 'Pending',
        value: 'PENDING',
      },
      {
        itemLabel: 'Reject',
        value: 'REJECTED',
      },
    ],
    [],
  );

  const handleApprove = useCallback(async ({ entityId, isSendEmail }) => {
    const payload = {
      id: entityId,
      sendNotificationEmailToUser: isSendEmail,
    };

    return services.adminApproveFundRedemptionRequest(payload);
  }, []);

  const handleReject = useCallback(async ({ entityId, status, reason }) => {
    const payload = {
      id: entityId,
      reason,
      status,
    };

    return services.adminRejectFundRedemptionRequest(payload);
  }, []);

  const handleBack = useCallback(() => history.back(), []);

  return (
    <Paper elevation={3} sx={{ padding: '20px' }}>
      <Grid container rowSpacing={3}>
        {/* header */}
        <Header
          onBackButtonClick={handleBack}
          backButtonLabel="Redemption Request Approval"
        />
        {/* content */}
        <Grid item xs={12}>
          {children}
        </Grid>

        {/* footer */}
        <Footer
          entityId={fundRedemptionRequestId}
          menus={menus}
          approvedBtnDisabled={!!editingSummary || isApproved || readOnly}
          feedbackBtnDisabled={!!editingSummary || readOnly}
          downloadBtnDisabled={readOnly}
          approvalLabel="Approve"
          confirmationDialogSubtitle="Are you sure want to approve this fund redemption request?"
          onSuccess={handleRedirectToApprovalList}
          deleteBtnDisabled={readOnly}
          onDelete={onDelete}
          onSubmitEntityApprove={handleApprove}
          onSubmitEntityStatusChange={handleReject}
        />
      </Grid>
    </Paper>
  );
};

export default DetailsContainer;
