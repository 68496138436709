import React, { forwardRef, useMemo } from 'react';

import MUIButton from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const StyledButton = styled(MUIButton)(
  ({
    theme,
    buttoncolor = 'menuLink',
    buttonstyles,
    maxheight = 8,
    padding,
    ...props
  }) => ({
    background: theme.palette.reach[buttoncolor],
    borderColor: theme.palette.reach[buttoncolor],
    borderRadius: '0px',
    boxShadow: 'none',
    '&:disabled': {
      background: theme.palette.reach.disabled,
      borderRadius: '0px',
      color: theme.palette.text.white,
    },
    textTransform: 'none',
    padding: padding || '22px 34px 23px 34px',
    maxHeight: theme.spacing(maxheight),
    [theme.breakpoints.down('md')]: {
      padding: '16px 34px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: !props?.fullWidth && theme.spacing(10),
    },
    ...buttonstyles,
  }),
);

const TextIcon = ({ SuffixIcon, Icon, children }) => (
  <Grid container display="flex" alignItems="center" columnSpacing={1}>
    {Icon && (
      <Grid item sx={{ height: '24px' }}>
        <Icon />
      </Grid>
    )}
    <Grid item sx={{ height: '24px' }}>
      {children}
    </Grid>
    {SuffixIcon && (
      <Grid item sx={{ height: '24px' }}>
        <SuffixIcon />
      </Grid>
    )}
  </Grid>
);

const Button = forwardRef(
  (
    {
      children,
      textColor = 'white',
      prefixIcon: Icon,
      suffixIcon: SuffixIcon,
      text = true,
      ...props
    },
    ref,
  ) => {
    const textBtnContent = useMemo(
      () => (
        <Typography
          variant="h6"
          color={textColor}
          sx={{ ':hover': { color: 'black' } }}
        >
          {Icon || SuffixIcon ? (
            <TextIcon SuffixIcon={SuffixIcon} Icon={Icon}>
              {children}
            </TextIcon>
          ) : (
            children
          )}
        </Typography>
      ),
      [text, textColor, Icon, SuffixIcon, children],
    );

    return (
      <StyledButton ref={ref} variant="contained" fullWidth {...props}>
        {text ? textBtnContent : children}
      </StyledButton>
    );
  },
);

export default Button;
