export const STORAGE_KEY = 'reachAlts';

export const COPYRIGHT_TEXT =
  'Copyright © 2024 Reach Alternative Investments. All rights reserved.';

export const REACH_WEBSITE = 'https://www.reachalts.com.au';

export const REACH_TNC =
  'https://www.reachalts.com.au/policies/terms-and-conditons';

export const REACH_PRIVACY_POLICY =
  'https://www.reachalts.com.au/policies/privacy-policy';

export const REACH_NON_CLOSURE_AGREEMENT =
  'https://www.reachalts.com.au/policies/nondisclosure-agreement';

export const REACH_ADMIN_EMAIL = 'contact@reachalts.com.au';

export const REACH_EMAIL_SUBJECT_PERSONAL_AMENDMENT =
  'Request: Personal Details Amendment';

export const PUBLIC_URLS = [
  '/change-',
  '/password-recovery',
  '/update-password',
  '/document',
];

export const FULLSCREEN_BROWSER_URLS = ['/document/view'];

export const FUND_CARD_DEFAULT_CONTENTS = [
  {
    heading: 'Underlying Fund Size',
    value: '[Value]',
  },
  {
    heading: 'Performance',
    value: '[Value]',
  },
  {
    heading: 'Target Size',
    value: '[Value]',
  },
  {
    heading: 'Minimum Investment',
    value: '[Value]',
  },
];

export const FROM_DOCUMENT_PAGE = 'documentView';

export const REGION_OPTIONS = [
  { label: 'Asia', value: 'ASIA' },
  { label: 'Middle East, North Africa, and Greater Arabia', value: 'MENAGA' },
  { label: 'Europe', value: 'EUROPE' },
  { label: 'North America', value: 'NA' },
  { label: 'Central America and the Carribean', value: 'CAC' },
  { label: 'South America', value: 'SA' },
  { label: 'Sub-Saharan Africa', value: 'SSA' },
  { label: 'Australia and Oceania', value: 'AO' },
  { label: 'Global', value: 'GLOBAL' },
];

export const PERIOD_ALL = 'ALL';

export const PERIOD_OPTIONS = [
  { label: 'All', value: PERIOD_ALL },
  { label: 'Day', value: 'DAY' },
  { label: 'Week', value: 'WEEK' },
  { label: 'Month', value: 'MONTH' },
  { label: 'Year', value: 'YEAR' },
];
