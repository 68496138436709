import React, { memo } from 'react';

const PDFPageWrapper = ({ children, pageStyle }) => (
  <div
    style={{
      ...pageStyle,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: '1px solid #ccc',
    }}
  >
    {children}
  </div>
);

export default memo(PDFPageWrapper);
