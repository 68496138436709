import React, { useEffect, useState, useCallback } from 'react';

import useSteps from 'hooks/useSteps';
import Loading from 'components/Loading';
import useUserDetailListData from 'hooks/useUserDetailListData';
import { useStores } from 'components/StateProvider';

import CompanyDetails from './Steps/CompanyDetails';
import BeneficialOwners from './Steps/BeneficialOwners';
import TaxDetails from '../components/TaxDetailsWithSupportingInformation';
import BankDetails from '../components/BankDetails';
import InvestmentSuccess from '../components/InvestmentSuccess';
import { handleAddCompanyEntity } from './HelperFunction';

const steps = [
  {
    label: 'Company Details',
    component: <CompanyDetails />,
  },
  {
    label: 'Beneficial Owners',
    component: <BeneficialOwners />,
  },
  {
    label: 'Tax Details',
    component: (
      <TaxDetails investorType="company" additionalChipText="Company Tax" />
    ),
  },
  {
    label: 'Bank Details',
    component: <BankDetails />,
  },
  {
    label: '',
    component: <InvestmentSuccess />,
  },
];

const stepComponents = steps.map((step) => step.component);
const stepLabels = steps.reduce((labels, step) => {
  if (!step.label) return labels;

  return labels.concat({ label: step.label });
}, []);

const AddCompanyInvestmentEntity = ({
  onBackInFirstPage,
  initialValues = {},
  onSubmitSuccess,
  user,
}) => {
  const {
    auth: {
      user: { id: userId, email: userEmail },
    },
  } = useStores();
  const [startAtLastStep, setStartAtLastStep] = useState(false);
  const [loading, setLoading] = useState(false);

  const { userDetailList } = useUserDetailListData({
    userId: user?.id || userId,
  });

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: companyEntityForm,
  } = useSteps({
    stepComponents,
    formParameters: {
      initialValues: {
        beneficialOwners: [],
        ...initialValues,
      },
      onSubmit: (formValues) => {
        setLoading(true);
        handleAddCompanyEntity({
          formValues,
          cb: onSubmitSuccess,
          setLoading,
          userEmail,
        });
      },
    },
    firstStepGoBackURL: 'select-type',
    onBackInFirstPage,
  });

  useEffect(() => {
    companyEntityForm?.validateForm();
  }, [pageIndex, companyEntityForm?.validateForm]);

  const handleNextStep = useCallback(
    async ({ submit } = {}) => {
      if (!submit) {
        setStartAtLastStep(false);
        handleNext();

        return;
      }

      try {
        await companyEntityForm.submitForm();
        handleNext();
      } catch (err) {
        // Only temp, waiting error confirmation.
        // eslint-disable-next-line
        return err.response;
      }
    },
    [companyEntityForm.submitForm, handleNext, setStartAtLastStep],
  );

  const handleBackStep = useCallback(
    (args) => {
      setStartAtLastStep(true);
      handleBack(args);
    },
    [setStartAtLastStep, handleBack],
  );

  return (
    <>
      {React.cloneElement(stepComponents[pageIndex], {
        startAtLastStep,
        onNext: handleNextStep,
        onBack: handleBackStep,
        steps: stepLabels,
        values: companyEntityForm.values,
        setValues: companyEntityForm.setValues,
        activeStep: pageIndex,
        isSubmitting: companyEntityForm.isSubmitting,
        mainTaxOwnerName: companyEntityForm.values?.companyDetails?.companyName,
        user,
        userDetailList,
      })}

      <Loading popup loading={loading} />
    </>
  );
};

export default AddCompanyInvestmentEntity;
