import React, { useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Form from './Form';

const CompleteProfileDialog = ({ open, onClose, onSubmit }) => {
  const [isError, setIsError] = useState(false);

  const dialogContent = useMemo(
    () => (
      <Form
        formId="complete-profile-form"
        onSubmit={onSubmit}
        setIsError={setIsError}
      />
    ),
    [],
  );

  const dialogActions = useMemo(
    () => (
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Button
            data-testid="complete-profile-submit-btn"
            form="complete-profile-form"
            type="submit"
            buttoncolor="menuLink"
            textColor="white"
            disabled={isError}
          >
            Submit Details
          </Button>
        </Grid>
      </Grid>
    ),
    [onSubmit, isError],
  );

  return (
    <Dialog
      dataTestKey="complete-profile"
      title="Complete Profile"
      open={open}
      onClose={onClose}
      content={dialogContent}
      dialogActions={dialogActions}
    />
  );
};

export default CompleteProfileDialog;
