import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import lazyLoad from 'utils/lazyLoad';

import useSteps from 'hooks/useSteps';

import { AUSTRALIA_DEFAULT_VALUE } from 'constants/phoneNumber';
import SidebarContainer from 'components/SidebarContainer';

import registrationValidationSchemas from 'validations/schema/registrationValidationSchemas';

const EligibilityCriteria = lazyLoad(() =>
  import('./Steps/EligibilityCriteria'),
);
const ProfileDetails = lazyLoad(() => import('./Steps/ProfileDetails'));
const Email = lazyLoad(() => import('./Steps/Email'));
const Consent = lazyLoad(() => import('./Steps/Consent'));
const SuccessRegistration = lazyLoad(() =>
  import('./Steps/SuccessRegistration'),
);
const VerifyOTP = lazyLoad(() => import('./Steps/VerifyOTP'));
const CreatePassword = lazyLoad(() => import('./Steps/CreatePassword'));
const RegisterPhoneNumber = lazyLoad(() =>
  import('./Steps/RegisterPhoneNumber'),
);

const registrationPageHelmetTitle =
  'Reach Alternative Investments | Sign Up to Invest In Private Equity';
const metaDescription =
  "Sign up to Reach Alternative Investments to gain access to global private market funds and invest in exclusive private equity funds from the world's leading asset managers. Reach manages the complexities of Australian compliance making it easy to invest in private equity.";

const stepComponents = [
  <EligibilityCriteria />,
  <ProfileDetails />,
  <Email />,
  <RegisterPhoneNumber />,
  <VerifyOTP />,
  <CreatePassword />,
  <Consent />,
  <SuccessRegistration />,
];

const RegistrationPage = () => {
  const {
    handleBack,
    handleNext,
    pageIndex,
    form: registrationForm,
  } = useSteps({
    stepComponents,
    validationSchemas: registrationValidationSchemas,
    formParameters: {
      initialValues: {
        countryCode: AUSTRALIA_DEFAULT_VALUE,
        consent: {
          0: false,
          1: false,
          2: false,
        },
      },
    },
  });

  useEffect(() => {
    registrationForm?.validateForm();
  }, [pageIndex, registrationForm?.validateForm]);

  return (
    <>
      <Helmet>
        <title>{registrationPageHelmetTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <SidebarContainer withCompanyLogo>
        <form
          style={{ height: 'inherit' }}
          onSubmit={registrationForm.handleSubmit}
        >
          {React.cloneElement(stepComponents[pageIndex], {
            errors: registrationForm.errors,
            values: registrationForm.values,
            touched: registrationForm.touched,
            pageIndex,
            onNext: handleNext,
            onBack: handleBack,
            onChange: registrationForm.handleChange,
            onBlur: registrationForm.handleBlur,
            setFieldError: registrationForm.setFieldError,
            totalPage: stepComponents.length,
          })}
        </form>
      </SidebarContainer>
    </>
  );
};

export default RegistrationPage;
