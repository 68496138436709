import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import Input from 'components/Input';
import PhoneNumber from 'components/PhoneNumber';
import ValidationHints from 'components/ValidationHints';
import PasswordInput from 'components/Input/PasswordInput';
import FormDivider from 'components/OptionDivider/FormDivider';
import ShowMe from 'components/ShowMe';

import palette from 'theme/palette';
import Button from 'components/Button';

const dividerStyles = {
  border: `1px solid ${palette.border.light2}`,
  margin: '16px 0',
};

const UserDetailsForm = ({
  errors,
  isView,
  onChange,
  onBlur,
  handleChangePassword,
  handleResetPassword,
  handleResendVerificationEmail,
  touched,
  validationResults = [],
  values,
  isEdit,
}) => (
  <>
    <Grid item container mt={2} xs={12} display="flex" flexDirection="row">
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>Email</FormLabel>
      </Grid>
      <Grid item xs={4}>
        <Input
          data-testid="email-input"
          disabled={isView}
          id="email"
          name="email"
          value={values.email}
          onChange={onChange}
          onBlur={onBlur}
          errorValue={touched.email && errors.email}
          placeholder="Email"
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>

    <FormDivider width="100%" style={dividerStyles} />

    <Grid item container xs={12} display="flex" flexDirection="row">
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>First name</FormLabel>
      </Grid>
      <Grid item xs={4}>
        <Input
          data-testid="firstname-input"
          disabled={isView}
          id="firstName"
          name="firstName"
          placeholder="First name"
          onChange={onChange}
          value={values.firstName}
          errorValue={touched.firstName && errors.firstName}
          onBlur={onBlur}
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>

    <FormDivider width="100%" style={dividerStyles} />

    <Grid item container xs={12} display="flex" flexDirection="row">
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>Middle name (optional)</FormLabel>
      </Grid>
      <Grid item xs={4}>
        <Input
          data-testid="middlename-input"
          disabled={isView}
          id="middleName"
          name="middleName"
          placeholder="Middle name"
          onChange={onChange}
          value={values.middleName}
          errorValue={touched.middleName && errors.middleName}
          onBlur={onBlur}
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>

    <FormDivider width="100%" style={dividerStyles} />

    <Grid item container xs={12} display="flex" flexDirection="row">
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>Last name</FormLabel>
      </Grid>
      <Grid item xs={4}>
        <Input
          data-testid="lastname-input"
          disabled={isView}
          id="lastName"
          name="lastName"
          placeholder="Last name"
          onChange={onChange}
          value={values.lastName}
          errorValue={touched.lastName && errors.lastName}
          onBlur={onBlur}
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>

    <FormDivider width="100%" style={dividerStyles} />

    <Grid item container xs={12} display="flex" flexDirection="row">
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>Phone number</FormLabel>
      </Grid>
      <Grid item xs={4}>
        <PhoneNumber
          data-testid="phone-number"
          autocompleteTestId="phone-number-code-area"
          disabled={isView}
          id="phoneNumber"
          name="phoneNumber"
          value={values.phoneNumber}
          countryCodeValue={values.countryCode}
          sx={{ margin: 0, width: 'auto' }}
          onChange={onChange}
          onBlur={onBlur}
          errorValue={touched.phoneNumber && errors.phoneNumber}
        />
      </Grid>
    </Grid>

    <FormDivider width="100%" style={dividerStyles} />

    <ShowMe when={!isView || handleResendVerificationEmail}>
      <Grid item container xs={12} display="flex" flexDirection="row">
        <Grid container item xs={3} alignItems="center">
          <FormLabel>Password</FormLabel>
        </Grid>
        <Grid item xs={4}>
          <PasswordInput
            fullWidth
            autoComplete="new-password"
            data-testid="password-input"
            defaultMargin={false}
            id="password"
            name="password"
            placeholder="Password"
            disabled={isView}
            onChange={handleChangePassword}
            value={isEdit ? 'password' : values.password}
            showIcon={!isEdit}
            inputProps={{ readOnly: isEdit }}
          />
          {!isEdit && <ValidationHints hints={validationResults} />}
        </Grid>
        <ShowMe when={isEdit && handleResetPassword}>
          <Grid container item xs={2.5} alignItems="center" marginLeft={2}>
            <Button
              buttoncolor="purpleHazeGradient"
              maxheight={3.5}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </Grid>
        </ShowMe>
        <ShowMe when={isEdit && handleResendVerificationEmail}>
          <Grid
            container
            item
            xs={4}
            xl={2.5}
            alignItems="center"
            marginLeft={2}
          >
            <Button
              buttoncolor="purpleHazeGradient"
              maxheight={3.5}
              onClick={handleResendVerificationEmail}
            >
              <Typography variant="body" color="white">
                Resend Verification Email
              </Typography>
            </Button>
          </Grid>
        </ShowMe>
        <FormDivider width="100%" style={dividerStyles} />
      </Grid>
    </ShowMe>
  </>
);

export default memo(UserDetailsForm);
