import * as yup from 'yup';

const adminFundPerformanceClosedEndValidationSchema = yup.object({
  date: yup.string().required(),
  irrGross: yup.string().nullable(),
  irrNet: yup.string().nullable(),
  moicNet: yup.string().nullable(),
  tvpi: yup.string().nullable(),
  dpi: yup.string().nullable(),
  rvpi: yup.string().nullable(),
  pme: yup.string().nullable(),
});

export default adminFundPerformanceClosedEndValidationSchema;
