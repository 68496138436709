import React, { memo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import getNumbers from 'utils/strings/getNumbers';
import useInvestmentAmountInfo from 'hooks/useInvestmentAmountInfo';

import ShowMe from 'components/ShowMe';

import palette from 'theme/palette';

const DataRow = ({ variant, label, value, ...props }) => (
  <Grid container item textAlign="center" {...props}>
    <Grid item xs={6} textAlign={['left', 'right']} pr={{ xs: 0, md: '10%' }}>
      <Typography variant={variant}>{label}</Typography>
    </Grid>
    <ShowMe when={value}>
      <Grid item xs={6} textAlign="right" pr={{ xs: 0, md: '15%' }}>
        <Typography variant="display4">{value}</Typography>
      </Grid>
    </ShowMe>
  </Grid>
);

const PaymentDetails = ({ values, fundConfiguration }) => {
  const { paymentAmountInfos, totalPayableToday, installmentInfos } =
    useInvestmentAmountInfo({
      fundConfiguration,
      investmentAmount: getNumbers(values.investmentAmount),
      isPayInInstallment: values.isPayInInstallment,
    });

  return (
    <Grid container item justifyContent="center" rowSpacing={2.5}>
      <Grid container item rowSpacing={2}>
        {paymentAmountInfos.map(
          ({ show = true, key, ...paymentAmountInfo }) => (
            <ShowMe when={show} key={key}>
              <DataRow {...paymentAmountInfo} />
            </ShowMe>
          ),
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ width: '100%' }} />
      </Grid>

      <Grid container item>
        <DataRow
          key="totalpayable"
          variant="display4"
          label="Total payable"
          value={totalPayableToday}
          sx={{
            backgroundColor: palette.reach.grey,
            borderRadius: '4px',
            p: { xs: '10px', md: '4px' },
          }}
        />
      </Grid>

      <ShowMe when={values.isPayInInstallment}>
        <Grid item xs={12}>
          <Divider sx={{ width: '100%' }} />
        </Grid>

        <Grid container item my={1.5}>
          <DataRow label="Installment Information" variant="display4" />
        </Grid>

        <Grid container item rowSpacing={2}>
          {installmentInfos.map(({ key, ...installmentInfo }) => (
            <DataRow key={key} {...installmentInfo} />
          ))}
        </Grid>
      </ShowMe>
    </Grid>
  );
};

export default memo(PaymentDetails);
