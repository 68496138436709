import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';

import useFormGridSize from 'hooks/useFormGridSize';

import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import DatePicker from 'components/DatePicker';
import PhoneNumber from 'components/PhoneNumber';

import { TITLE_OPTIONS, COUNTRY_OPTIONS } from 'constants/investmentEntity';
import FormTitle from 'components/FormTitle';

const PersonalDetailsForm = ({
  errors,
  onChange,
  values,
  onBlur,
  touched,
  setFieldValue,
  isJoint,
  title,
  fieldDirection = 'column',
}) => {
  const { labelGridProps, inputGridProps } = useFormGridSize({
    fieldDirection,
  });

  return (
    <>
      <FormTitle title={title} />
      <Grid
        data-testid="entity-personal-details-form"
        container
        item
        mt={{ xs: 0, md: 2 }}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Title*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Dropdown
            autoFocus
            data-testid="personal-details-title-dropdown"
            name="title"
            placeholder="Title*"
            onChange={onChange}
            value={values.title}
            errorValue={touched.title && errors.title}
            onBlur={onBlur}
            options={TITLE_OPTIONS}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Legal first name*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Input
            fullWidth
            data-testid="firstname-input"
            id="firstName"
            name="firstName"
            placeholder="First name"
            onChange={onChange}
            value={values.firstName}
            errorValue={touched.firstName && errors.firstName}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Legal middle name</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Input
            fullWidth
            data-testid="middlename-input"
            id="middleName"
            name="middleName"
            placeholder="Middle name"
            onChange={onChange}
            value={values.middleName}
            errorValue={touched.middleName && errors.middleName}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Legal last name*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Input
            fullWidth
            data-testid="lastname-input"
            id="lastName"
            name="lastName"
            placeholder="Last name"
            onChange={onChange}
            value={values.lastName}
            errorValue={touched.lastName && errors.lastName}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Date of birth*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <DatePicker
            fullWidth
            name="dateOfBirth"
            testId="dateofbirth-input"
            onChange={onChange}
            value={values.dateOfBirth}
            errorValue={
              values.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth
            }
            onBlur={onBlur}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Country of citizenship*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Dropdown
            data-testid="country-dropdown"
            name="country"
            placeholder="Select country*"
            isBoldOptionLabel
            onChange={onChange}
            value={values.country}
            errorValue={touched.country && errors.country}
            onBlur={onBlur}
            options={COUNTRY_OPTIONS}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Phone number*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps} display="flex" flexDirection="column">
          <PhoneNumber
            fullWidth
            data-testid="phone-number"
            autocompleteTestId="phone-number-code-area"
            id="phoneNumber"
            name="phoneNumber"
            value={values.phoneNumber}
            countryCodeValue={values.countryCode}
            disabled={!isJoint}
            sx={{ margin: 0, width: 'auto' }}
            onChange={onChange}
            onBlur={onBlur}
            errorValue={touched.phoneNumber && errors.phoneNumber}
          />
          {!isJoint && (
            <Typography variant="helperFuchsia">cannot be changed</Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        mt={2}
        xs={12}
        md={12}
        display="flex"
        flexDirection={fieldDirection}
      >
        <Grid item {...labelGridProps}>
          <FormLabel>Email*</FormLabel>
        </Grid>
        <Grid item {...inputGridProps}>
          <Input
            fullWidth
            data-testid="email-input"
            id="email"
            name="email"
            value={values.email}
            disabled={!isJoint}
            onChange={onChange}
            onBlur={onBlur}
            errorValue={touched.email && errors.email}
          />
          {!isJoint && (
            <Typography variant="helperFuchsia">cannot be changed</Typography>
          )}
        </Grid>
      </Grid>
      {!isJoint && (
        <Grid container mt={2}>
          <Typography>
            <b>Note:</b> Some fields are auto populated from your profile
            details and some cannot be edited due to consistency and security of
            individual investor data.
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default memo(PersonalDetailsForm);
