import Services from 'api/services';

import {
  countryCellRenderer,
  dateCellRenderer,
  statusChipCellRenderer,
} from 'utils/Datatables/cellRenderer';
import thousandSeparator from 'utils/strings/thousandSeparators';

import { FUND_REDEMPTION_REQUEST_STATUS_LABEL } from 'constants/fundRedemptionRequest';

export const investmentEntityHeaders = [
  {
    field: 'userInvestorMapId',
    headerName: 'ID',
    width: 70,
    cellClassName: 'cellBold',
  },
  {
    field: 'entityName',
    headerName: 'Entity Name',
    flex: 1,
    cellClassName: 'cellBold',
  },
  {
    field: 'entityType',
    headerName: 'Type',
    flex: 0.75,
    cellClassName: 'cellBold',
  },
  {
    field: 'requestDate',
    headerName: 'Req. Date',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 0.5,
    renderCell: countryCellRenderer,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.5,
    renderCell: statusChipCellRenderer,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
];

export const investmentFundHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    cellClassName: 'cellBold',
  },
  {
    field: 'entityName',
    headerName: 'Entity Name',
    flex: 1,
    cellClassName: 'cellBold',
  },
  {
    field: 'fundName',
    headerName: 'Fund Name',
    flex: 0.75,
    cellClassName: 'cellBold',
  },
  {
    field: 'reqDate',
    headerName: 'Req. Date',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 0.5,
    renderCell: ({ row }) => `${row.currency}$${thousandSeparator(row.amount)}`,
  },
  {
    field: 'payment',
    headerName: 'Payment',
    flex: 0.5,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.5,
    renderCell: statusChipCellRenderer,
  },
];

export const fundRedemptionRequestHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    cellClassName: 'cellBold',
  },
  {
    field: 'entityName',
    headerName: 'Entity Name',
    flex: 1,
    cellClassName: 'cellBold',
  },
  {
    field: 'fundName',
    headerName: 'Fund',
    flex: 0.75,
    cellClassName: 'cellBold',
  },
  {
    field: 'className',
    headerName: 'Class',
    flex: 0.5,
  },
  {
    field: 'requestedAt',
    headerName: 'Req. Date',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
  {
    field: 'redemptionUnitAmount',
    headerName: 'Unit redeemed',
    flex: 0.5,
    renderCell: ({ value }) => `${thousandSeparator(value)}`,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.5,
    renderCell: ({ value }) =>
      statusChipCellRenderer({
        value,
        label: FUND_REDEMPTION_REQUEST_STATUS_LABEL[value],
      }),
  },
];

export const getInvestmentEntityTotalHeader = (totalValue) => [
  { totalLabel: 'Total Investment Entities', totalValue },
];

export const getInvestmentFundTotalHeader = (totalValue) => [
  { totalLabel: 'Total Investment Funds', totalValue },
];

export const getFundRedemptionRequestTotalHeader = (totalValue) => [
  { totalLabel: 'Total Requests', totalValue },
];

export const fetchAdminFundEntity = async (entityId) => {
  try {
    const res = await Services.adminGetFundEntity(entityId);
    const { data } = res;

    return {
      ...data,
      portfolio: {
        ...data.portfolio,
        name: data.name,
        applicationFee: data.portfolio?.applicationFee || 0,
      },
      fund: {
        ...data.fund,
        currency: data.portfolio.currency,
        paymentType: data.portfolio.paymentType,
        referenceNumber: data.portfolio.referenceNumber,
      },
    };
  } catch (_) {
    return {};
  }
};

export const fetchAdminFundRedemptionRequestDetails = async (id) => {
  try {
    const res = await Services.adminGetFundRedemptionRequestDetails(id);
    const { data } = res;

    return data;
  } catch (_) {
    return {};
  }
};

export const downloadExportData = async ({ category, verificationCode }) => {
  let res = {};

  if (verificationCode) {
    const payload = {
      verificationCode: verificationCode.toString(),
    };

    res = await Services.adminVerifyExportInvestment(category, payload);
  } else {
    res = await Services.adminExportInvestment(category);
  }

  return res?.data;
};
