import React, { memo, useCallback } from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { popoverClasses } from '@mui/material/Popover';

import Chip from 'components/Chip';
import OptionDivider from 'components/OptionDivider';

import palette from 'theme/palette';
import condTrueFalse from 'utils/condTrueFalse';

import { getInputStyles } from 'constants/styles';

const StyledMUISelect = styled(MUISelect)(
  ({
    theme,
    minheight = 8,
    maxheight = 'auto',
    minWidth,
    disableUnderline,
    ...props
  }) => ({
    boxSizing: 'border-box',
    borderRadius: '0px',
    background: theme.palette.reach.white,
    maxHeight: maxheight,
    minWidth,
    // ...getInputStyles({ theme }),
    // [theme.breakpoints.up('lg')]: {
    //   minHeight: theme.spacing(minheight),
    // },
    '& fieldset': {
      border: disableUnderline
        ? '0px'
        : `1px solid ${theme.palette.text.fuchsia}`,
    },
    '&:hover fieldset': {
      border:
        disableUnderline || props.disabled
          ? '0px'
          : `2px solid ${theme.palette.text.fuchsia} !important`,
    },
    '&:valid fieldset': {
      border: disableUnderline
        ? undefined
        : `2px solid ${theme.palette.reach.green}`,
    },
    '&:invalid fieldset': {
      border: disableUnderline
        ? undefined
        : `2px solid ${theme.palette.reach.red}`,
    },
    '&.Mui-focused fieldset': {
      border: disableUnderline
        ? undefined
        : `2px solid ${theme.palette.text.fuchsia}`,
    },
    '&.Mui-disabled': {
      background: theme.palette.form.disabled,
      border: disableUnderline
        ? undefined
        : `1px solid ${theme.palette.form.disabledBorder}`,
    },
  }),
);

const getSelectedChipOption = ({ options, value }) =>
  options?.find((opt) => opt.value === value);

const renderChip = ({ options, selected, onDelete }) => {
  const handleDelete = (_e, deleted) =>
    onDelete && onDelete({ selected, deleted });

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value) => (
        <Chip
          key={`${value}-${onDelete ? selected.length : ''}`}
          value={value}
          label={getSelectedChipOption({ options, value })?.label}
          color="purpleHaze"
          onDelete={handleDelete}
        />
      ))}
    </Box>
  );
};

const renderSelectedValue = ({ options, selected, renderSelectedOption }) => {
  const selectedOption = options?.find((opt) => opt.value === selected);

  return condTrueFalse(
    renderSelectedOption,
    () => renderSelectedOption(selectedOption),
    selectedOption?.label,
  );
};

const Dropdown = ({
  options,
  errorValue,
  placeholder,
  fullWidth = true,
  isBoldOptionLabel = false,
  autoWidth = true,
  renderSelectedOption,
  multiple,
  withDeleteIcon,
  ...props
}) => {
  const handleDelete = useCallback(
    ({ selected, deleted }) => {
      const { name, onChange } = props;

      let newSelected = [...selected];
      newSelected = newSelected.filter((value) => value !== deleted);

      const event = {
        target: {
          name,
          value: newSelected,
        },
      };

      onChange(event);
    },
    [withDeleteIcon && props.value.length],
  );

  const renderValue = useCallback(
    (selected) => {
      if (!selected && selected !== 0) {
        return (
          <Typography variant="bodyStatic" sx={{ opacity: 0.5 }}>
            {placeholder}
          </Typography>
        );
      }

      if (multiple)
        return renderChip({
          options,
          selected,
          onDelete: withDeleteIcon && handleDelete,
        });

      return renderSelectedValue({ options, selected, renderSelectedOption });
    },
    [handleDelete, options, props.value],
  );

  return (
    <FormControl error={!!errorValue} fullWidth={fullWidth}>
      <StyledMUISelect
        inputProps={{ 'data-testid': `${props?.name}-dropdown-input` }}
        multiple={multiple}
        IconComponent={KeyboardArrowDownIcon}
        autoWidth={autoWidth}
        displayEmpty
        renderValue={renderValue}
        MenuProps={{
          sx: {
            [`& .${popoverClasses.paper}`]: {
              marginTop: '10px',
              borderRadius: '0px',
              maxHeight: '248px',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderLeft: '15px #D9D9D9 solid',
                borderRadius: '6px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#F6F6F6',
                borderRadius: '6px',
                height: 0,
                width: 0,
              },
            },
          },
        }}
        {...props}
      >
        {options?.map(({ label, value, divider, customLabel, disabled }) =>
          divider ? (
            <OptionDivider key={label} bordercolor={palette.border.light} />
          ) : (
            <MenuItem
              value={value}
              key={value}
              data-testid={`${props?.name}-dropdown-item-${value}`}
              disabled={disabled}
            >
              {condTrueFalse(
                customLabel,
                customLabel,
                condTrueFalse(isBoldOptionLabel, <b>{label}</b>, label),
              )}
            </MenuItem>
          ),
        )}
      </StyledMUISelect>
      {errorValue && <FormHelperText>{errorValue}</FormHelperText>}
    </FormControl>
  );
};

export default memo(Dropdown);
