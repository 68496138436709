import React, { memo, useEffect, useMemo } from 'react';

import lazyLoad from 'utils/lazyLoad';
import useSteps from 'hooks/useSteps';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';

import isNotEmptyObject from 'utils/isNotEmptyObject';
import omitObjKeys from 'utils/omitObjKeys';
import isObjectContainsAnyValue from 'utils/isObjectContainsAnyValue';
import PayloadMappers from 'utils/PayloadMappers';
import taxDetailValidationSchemas from 'validations/schema/addInvestment/taxDetailsValidationSchemas';

import { mapPayloadToTaxDetails } from './HelperFunction';

const TaxDetailsStep = lazyLoad(() => import('./Steps/TaxDetails'));
const Summary = lazyLoad(() => import('./Steps/Summary'));

const stepComponents = [<TaxDetailsStep />, <Summary />];

const updateFieldValues = ({ values, taxDetailsValues, setValues }) => {
  if (setValues) {
    const [individualTaxDetails, jointTaxDetails] = taxDetailsValues.investors;

    const signedValue = {
      signedAt: taxDetailsValues.signedAt,
      signedBy: taxDetailsValues.signedBy,
    };

    const joint = isNotEmptyObject(jointTaxDetails)
      ? {
          joint: {
            ...values?.joint,
            taxDetails: PayloadMappers.taxDetails(jointTaxDetails),
            ...signedValue,
          },
        }
      : {};

    setValues({
      ...values,
      taxDetails: {
        ...(isObjectContainsAnyValue(
          omitObjKeys(individualTaxDetails, [
            'firstName',
            'lastName',
            'signedAt',
          ]),
        )
          ? PayloadMappers.taxDetails({
              ...individualTaxDetails,
              ...signedValue,
            })
          : {}),
        ...signedValue,
        isConfirmed: taxDetailsValues.isConfirmed,
      },
      ...joint,
    });
  }
};

const TaxDetails = ({
  onNext,
  onBack,
  values,
  setValues,
  SideIcon,
  steps,
  startAtLastStep,
}) => {
  const investors = useMemo(() => {
    if (isNotEmptyObject(values?.personalDetails?.joint)) {
      const { joint, ...individual } = values.personalDetails;
      return [
        {
          ...mapPayloadToTaxDetails(values.taxDetails),
          firstName: individual.firstName,
          lastName: individual.lastName,
        },
        {
          ...mapPayloadToTaxDetails(values?.joint?.taxDetails),
          firstName: joint.firstName,
          lastName: joint.lastName,
        },
      ];
    }
    return [
      {
        ...mapPayloadToTaxDetails(values.taxDetails),
        firstName: values?.personalDetails?.firstName,
        lastName: values?.personalDetails?.lastName,
      },
    ];
  }, []);

  const handleOnBackInFirstPage = ({ values: taxDetailsValues }) => {
    updateFieldValues({ values, taxDetailsValues, setValues });

    onBack();
  };

  // cant use useCallback on this, not sure why, but it will be malfunction
  const handleOnNextInLastPage = ({ values: taxDetailsValues }) => {
    updateFieldValues({ values, taxDetailsValues, setValues });

    onNext();
  };

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: taxDetailsForm,
  } = useSteps({
    stepComponents,
    validationSchemas: taxDetailValidationSchemas,
    formParameters: {
      initialValues: {
        investors,
        signedAt: values?.taxDetails?.signedAt || new Date(),
        signedBy: values?.taxDetails?.signedBy || '',
        isConfirmed: !!values?.taxDetails?.isConfirmed,
      },
    },
    onNextInLastPage: handleOnNextInLastPage,
    onBackInFirstPage: handleOnBackInFirstPage,
    defaultPageIndex: startAtLastStep ? stepComponents.length - 1 : 0,
  });

  useEffect(() => {
    taxDetailsForm?.validateForm();
  }, [pageIndex, taxDetailsForm?.validateForm]);

  return (
    <InvestmentEntityStepContainer
      title="Tax Details"
      containerProps={{
        onBack: handleBack,
        onNext: handleNext,
        isNextButtonDisabled: !!Object.keys(taxDetailsForm.errors).length,
      }}
      SideIcon={SideIcon}
      steps={steps}
      activeStep={1}
    >
      {React.cloneElement(stepComponents[pageIndex], {
        form: taxDetailsForm,
        errors: taxDetailsForm.errors,
        values: taxDetailsForm.values,
        touched: taxDetailsForm.touched,
        pageIndex,
        onNext: handleNext,
        onBack: handleBack,
        onChange: taxDetailsForm.handleChange,
        onBlur: taxDetailsForm.handleBlur,
        setFieldError: taxDetailsForm.setFieldError,
        setValues: taxDetailsForm.setValues,
      })}
    </InvestmentEntityStepContainer>
  );
};

export default memo(TaxDetails);
