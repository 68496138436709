import axios from 'axios';
import config from 'utils/config';
import history from 'utils/history';
import { ERROR_CODES } from 'constants/errors';

const api = axios.create({
  baseURL: config.api.url,
  timeout: 120000,
});

api.interceptors.request.use(
  (request) => {
    const token = `Bearer ${config.api.token}`;
    request.headers.Authorization = token;
    return request;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const isTokenInvalid = [
      ERROR_CODES.LOGIN.INVALID_TOKEN,
      ERROR_CODES.LOGIN.EXPIRED_TOKEN,
    ].includes(error.response?.data?.code);

    if (error.response?.status === 401 && isTokenInvalid) {
      return history.push('/logout');
    }

    return Promise.reject(error);
  },
);

export default api;
