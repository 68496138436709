import React from 'react';

import Popper from '@mui/material/Popper';

const AutoCompletePopper = (props) => (
  <Popper
    {...props}
    style={{ width: 'fit-content' }}
    sx={(theme) => ({
      '& .MuiPaper-root': {
        borderRadius: theme.spacing(1),
        boxShadow: `${theme.spacing(0, 0.5, 0.5)} rgba(0, 0, 0, 0.12)`,
      },
    })}
  />
);

export default AutoCompletePopper;
