import React, { memo } from 'react';
import {
  AddCircleOutline,
  Download,
  RemoveCircleOutline,
} from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import palette from 'theme/palette';

const Toolbar = ({
  handleZoomIn,
  handleZoomOut,
  handleZoomChange,
  handleDownload,
  zoomScale,
  downloadable,
}) => (
  <Grid
    container
    alignItems="center"
    justifyContent="space-between"
    sx={{ background: palette.reach.blueAppBarPDF }}
  >
    <Grid item xs="auto" />
    <Grid item xs="auto" alignItems="center" container>
      <Grid item>
        <IconButton onClick={handleZoomIn} size="large">
          <AddCircleOutline color="light" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={handleZoomOut} size="large">
          <RemoveCircleOutline color="light" />
        </IconButton>
      </Grid>
      <Grid item>
        <FormControl size="small" sx={{ p: 1 }}>
          <Select
            value={zoomScale}
            onChange={handleZoomChange}
            sx={{ color: '#FFF', border: '0', background: '#6872BE' }}
          >
            <MenuItem value={0.5}>50%</MenuItem>
            <MenuItem value={0.75}>75%</MenuItem>
            <MenuItem value={1}>100%</MenuItem>
            <MenuItem value={1.25}>125%</MenuItem>
            <MenuItem value={1.5}>150%</MenuItem>
            <MenuItem value={2}>200%</MenuItem>
            <MenuItem value={3}>300%</MenuItem>
            <MenuItem value={4}>400%</MenuItem>
            <MenuItem value={zoomScale} sx={{ display: 'none' }}>{`${
              zoomScale * 100
            }%`}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <Grid item xs="auto">
      {downloadable && (
        <IconButton size="large" onClick={handleDownload}>
          <Download color="light" />
        </IconButton>
      )}
    </Grid>
  </Grid>
);

export default memo(Toolbar);
