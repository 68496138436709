import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SwitchWithLabel from 'components/Switch/SwitchWithLabel';
import FieldContainer from 'components/FieldContainer';
import Dropdown from 'components/Dropdown';
import mapValue from 'utils/mapValue';
import { REPORTING_TEMPLATE_OPTIONS } from 'constants/funds';

const HeaderInfos = ({ onChange, values }) => (
  <>
    {/* Fund Visibility & Status */}
    <Grid container item xs={5} rowSpacing={1}>
      <Grid item xs={12}>
        <FieldContainer
          label="Fund Visibility:"
          labelColor="black"
          labelGridProps={{ xs: 5 }}
          childGridProps={{ xs: 7 }}
        >
          <SwitchWithLabel
            invert
            name="isHidden"
            checked={!values.isHidden}
            onChange={onChange}
            leftLabel="Hidden"
            rightLabel="Visible"
            height={18}
            width={34}
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer
          label="Fund Status:"
          labelColor="black"
          labelGridProps={{ xs: 5 }}
          childGridProps={{ xs: 7 }}
        >
          <SwitchWithLabel
            name="isOpen"
            checked={!!values.isOpen}
            onChange={onChange}
            leftLabel="Closed"
            rightLabel="Open"
            height={18}
            width={34}
          />
        </FieldContainer>
      </Grid>
    </Grid>

    {/* Created & Updated By */}
    <Grid container item xs={3.5} rowSpacing={1}>
      <Grid item xs={12}>
        <FieldContainer
          label="Created by:"
          labelColor="black"
          labelGridProps={{ xs: 4 }}
          childGridProps={{ xs: 8 }}
        >
          <Typography variant="body">
            {mapValue(values?.createdBy, '-')}
          </Typography>
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer
          label="Updated by:"
          labelColor="black"
          labelGridProps={{ xs: 4 }}
          childGridProps={{ xs: 8 }}
        >
          <Typography variant="body">
            {mapValue(values?.updatedBy, '-')}
          </Typography>
        </FieldContainer>
      </Grid>
    </Grid>

    {/* Created & Updated At */}
    <Grid container item xs={3.5} rowSpacing={1}>
      <Grid item xs={12}>
        <FieldContainer
          label="Date Created:"
          labelColor="black"
          labelGridProps={{ xs: 4 }}
          childGridProps={{ xs: 8 }}
        >
          <Typography variant="body">
            {values?.createdAt
              ? new Date(values.createdAt).toLocaleDateString('en-AU')
              : '-'}
          </Typography>
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer
          label="Last Updated:"
          labelColor="black"
          labelGridProps={{ xs: 4 }}
          childGridProps={{ xs: 8 }}
        >
          <Typography variant="body">
            {values?.updatedAt
              ? new Date(values.updatedAt).toLocaleDateString('en-AU')
              : '-'}
          </Typography>
        </FieldContainer>
      </Grid>
    </Grid>

    {/* Redemptions & Reporting Template */}
    <Grid container item xs={5} rowSpacing={1}>
      <Grid item xs={12}>
        <FieldContainer
          label="Redemptions:"
          labelColor="black"
          labelGridProps={{ xs: 5 }}
          childGridProps={{ xs: 7 }}
        >
          <SwitchWithLabel
            name="isRedemptionVisible"
            checked={!!values.isRedemptionVisible}
            onChange={onChange}
            leftLabel="Hidden"
            rightLabel="Visible"
            height={18}
            width={34}
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer
          label="Reporting template:"
          labelColor="black"
          labelGridProps={{ xs: 5 }}
          childGridProps={{ xs: 7 }}
        >
          <Dropdown
            fullWidth={false}
            name="reportingTemplate"
            options={REPORTING_TEMPLATE_OPTIONS}
            value={values.reportingTemplate}
            maxheight="36px"
            placeholder="Reporting template"
            onChange={onChange}
          />
        </FieldContainer>
      </Grid>
    </Grid>
  </>
);

export default HeaderInfos;
