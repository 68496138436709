import React, { memo } from 'react';

import FundCardLandscape from './FundCardLandscape';
import FundCardPortrait from './FundCardPortrait';

const CARD_WIDTH_PORTRAIT = '530px';

const FundCard = ({ mode = 'portrait', ...props }) =>
  mode === 'portrait' ? (
    <FundCardPortrait width={CARD_WIDTH_PORTRAIT} {...props} />
  ) : (
    <FundCardLandscape {...props} />
  );

export default memo(FundCard);
