import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';

import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { ThemeProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './index.css';
import App from './App';
import { StateProvider } from './components/StateProvider';
import theme from './theme';
import reportWebVitals from './reportWebVitals';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateProvider>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Reach Alternative Investments</title>
            <meta
              name="description"
              content="Reach Alternative Investments for private equity funds"
            />
          </Helmet>
          <App />
        </DndProvider>
      </ThemeProvider>
    </StateProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
