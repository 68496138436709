import React from 'react';

import IndividualTaxDetail from 'containers/DashboardPage/components/InvestmentEntityList/IndividualTaxDetail';
import PayloadMappers from 'utils/PayloadMappers';

const TaxDetailList = ({ values, onChange, ...props }) => {
  const handleChange = (index) => (e) => {
    const newValue = e.target.value;
    if (index) {
      onChange({
        joint: {
          taxDetail: PayloadMappers.taxDetails(newValue),
        },
      });

      return;
    }

    onChange({
      taxDetail: PayloadMappers.taxDetails(newValue),
    });
  };

  return (
    <div data-testid="tax-details-content">
      {values?.map((taxDetail, index) => (
        <IndividualTaxDetail
          values={taxDetail}
          key={`taxDetail-${taxDetail?.id}`}
          setValues={handleChange(index)}
          {...props}
        />
      ))}
    </div>
  );
};
export default TaxDetailList;
