import * as yup from 'yup';

const signingValidationSchema = yup.object({
  isSigned: yup
    .bool()
    .required('Please check the declaration.')
    .isTrue('Please check the declaration.')
    .label('Declaration'),
});

export default signingValidationSchema;
