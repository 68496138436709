import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

import DetailsPageContainer from 'components/DetailsPageContainer';
import LoadingDialog from 'components/LoadingDialog';
import ShowMe from 'components/ShowMe';

import history from 'utils/history';
import getFullName from 'utils/getFullName';
import thousandSeparator from 'utils/strings/thousandSeparators';
import useFetchApi from 'hooks/useFetchApi';
import services from 'api/services';
import adminAddUserFundRedemptionRequestValidationSchema from 'validations/schema/adminAddUserFundRedemptionRequestValidationSchema';
import { FUND_REDEMPTION_REQUEST_STATUS_OPTIONS } from 'constants/investmentEntity';

import RequestDetailsSection from './RequestDetailsSection';
import PaymentInstructionsSection from './PaymentInstructionsSection';
import SigninDocument from './SigninDocument';
import InvestmentStatusSection from './InvestmentStatusSection';
import InvestorDetailsSection from './InvestorDetailsSection';
import {
  fetchAdminUserEntityList,
  fetchFunds,
  fetchUserData,
  submitUserFundRedemptionRequest,
} from './HelperFunction';

const todaysDate = new Date().toISOString();

const AddFundRedemptionRequest = () => {
  const { userId } = useParams();
  const [fundConfiguration, setFundConfiguration] = useState({});
  const [approvedPortfolios, setApprovedPortfolios] = useState([]);
  const [error, setError] = useState('');

  const adminGetUserEntityList = useCallback(
    () =>
      Promise.all([
        fetchAdminUserEntityList({ userId }),
        fetchUserData(userId),
        fetchFunds(),
      ]),
    [userId],
  );

  const { apiData: dataValues } = useFetchApi({
    fetchService: adminGetUserEntityList,
    defaultApiData: {
      openFunds: 0,
      users: 0,
      onboarded: 0,
      investmentEntities: 0,
      investments: 0,
      raised: {},
    },
    dependencies: [adminGetUserEntityList],
    mapApiData: (response) => ({
      userEntities: response[0],
      userDetail: { ...response[1], userId: response[1]?.id },
      funds: response[2],
    }),
  });

  const handleBack = useCallback(() => {
    history.push(`/admin/users/edit/${userId}?tab=fundRedemptionRequests`);
  }, [userId]);

  const formik = useFormik({
    validationSchema: adminAddUserFundRedemptionRequestValidationSchema,
    initialValues: {
      userEntity: '',
      fund: '',
      fundConfiguration: '',
      portfolio: '',
      redemptionUnitAmount: '',
      status: FUND_REDEMPTION_REQUEST_STATUS_OPTIONS[0].value,
      requestedAt: todaysDate,

      isSigned: false,
      investmentStatus: '',
    },
    enableReinitialize: true,
    onSubmit: (formValues) =>
      submitUserFundRedemptionRequest({ formValues, cb: handleBack, setError }),
  });

  useEffect(() => {
    if (!dataValues?.userDetail) return;

    formik.setFieldValue('userName', getFullName(dataValues?.userDetail));
    formik.setFieldValue('userId', dataValues.userDetail.id);
  }, [dataValues?.userDetail?.id]);

  useEffect(() => {
    formik.setFieldValue('fundConfiguration', '');
  }, [formik.values.fund]);

  const fundConfigurations = useMemo(() => {
    if (!formik.values.fund || !dataValues?.funds?.length) return [];

    return dataValues.funds.find((fund) => fund.id === formik.values.fund)
      .fundConfigurations;
  }, [formik.values.fund, dataValues.funds?.length]);

  useEffect(() => {
    const newFundConfiguration =
      fundConfigurations.find(
        (fundConfig) => fundConfig.id === formik.values.fundConfiguration,
      ) || {};
    if (!newFundConfiguration) return;

    setFundConfiguration(newFundConfiguration);

    formik.setFieldValue(
      'applicationFee',
      thousandSeparator(newFundConfiguration.applicationFee),
    );
  }, [formik.values.fundConfiguration, fundConfigurations]);

  const getApprovedPortfolios = useCallback(
    async (fundConfigurationId) => {
      const res =
        await services.adminGetPortfoliosByFundConfigurationIdAndUserId({
          fundConfigurationId,
          userId,
        });

      setApprovedPortfolios(
        res.data?.map((portfolio) => ({
          ...portfolio,
          label: `${portfolio.referenceNumber} [units: ${portfolio.unit}]`,
          value: portfolio.id,
        })),
      );
    },
    [userId],
  );

  useEffect(() => {
    if (!fundConfiguration.id) {
      setApprovedPortfolios([]);
      formik.setFieldValue('portfolio', '');

      return;
    }

    getApprovedPortfolios(fundConfiguration.id);
  }, [fundConfiguration.id]);

  useEffect(() => {
    if (!formik.values.portfolio) return;

    formik.setFieldValue(
      'selectedPortfolio',
      approvedPortfolios.find(
        (portfolio) => portfolio.id === formik.values.portfolio,
      ),
    );
  }, [formik.values.portfolio, approvedPortfolios.length]);

  return (
    <>
      <DetailsPageContainer
        onBackButtonClick={handleBack}
        submitBtnDisabled={Object.keys(formik.errors).length}
        backButtonLabel="User"
        onSubmitClick={formik.submitForm}
        submitBtnLabel="Save"
        status="New"
        childrenContainerProps={{ rowSpacing: 4, container: true }}
      >
        <ShowMe when={!!error}>
          <Grid item xs={12} mb={2}>
            <Alert severity="error" icon={false}>
              {error}
            </Alert>
          </Grid>
        </ShowMe>
        <Grid item xs={12}>
          <RequestDetailsSection
            values={formik.values}
            errors={formik.errors}
            investmentEntities={dataValues?.userEntities}
            funds={dataValues.funds}
            onChange={formik.handleChange}
            fundConfiguration={fundConfiguration}
            userData={dataValues?.userDetail}
            fundConfigurations={fundConfigurations}
            portfolios={approvedPortfolios}
            setFieldValue={formik.setFieldValue}
          />
        </Grid>
        <Grid item xs={12}>
          <InvestorDetailsSection
            values={formik.values}
            errors={formik.errors}
            investmentEntities={dataValues?.userEntities}
            funds={dataValues.funds}
            onChange={formik.handleChange}
            fundConfiguration={fundConfiguration}
            userData={dataValues?.userDetail}
            fundConfigurations={fundConfigurations}
          />
        </Grid>
        <ShowMe when={fundConfiguration.id}>
          <Grid item xs={12}>
            <PaymentInstructionsSection
              values={fundConfiguration.bankAccount}
              errors={formik.errors}
              onChange={formik.handleChange}
              setValues={formik.setValues}
              setFieldValue={formik.setFieldValue}
              fundConfiguration={fundConfiguration}
              touched={formik.touched}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </ShowMe>
        <SigninDocument
          onChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          onBlur={formik.handleBlur}
        />
        <InvestmentStatusSection
          onChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          onBlur={formik.handleBlur}
        />
      </DetailsPageContainer>

      <LoadingDialog loading={formik.isSubmitting} />
    </>
  );
};

export default AddFundRedemptionRequest;
