import React, { useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';

import ShowMe from 'components/ShowMe';
import Dialog from 'components/Dialog';
import SubmitCancel from 'components/Dialog/Actions/SubmitCancel';

import Form from './Form';

const FundUpdatesDialog = ({
  open,
  onClose,
  onSubmit,
  selectedFundUpdatesType,
  errorText,
  disableSubmitBtn,
}) => {
  const [isError, setIsError] = useState(true);

  const dialogContent = useMemo(
    () => (
      <>
        <Form
          formId="fund-updates-form"
          onSubmit={onSubmit}
          setIsError={setIsError}
          selectedFundUpdatesType={selectedFundUpdatesType}
        />
        <ShowMe when={!!errorText}>
          <Typography variant="body" color="red" mt={3} textAlign="center">
            {errorText}
          </Typography>
        </ShowMe>
      </>
    ),
    [selectedFundUpdatesType, onSubmit, errorText],
  );

  const dialogActions = useMemo(
    () => (
      <SubmitCancel
        submitLabel="Save"
        submitBtnProps={{
          form: 'fund-updates-form',
          type: 'submit',
        }}
        submitDisabled={isError || disableSubmitBtn}
      />
    ),
    [onSubmit, isError, disableSubmitBtn],
  );

  return (
    <Dialog
      dataTestKey="fund-updates"
      maxWidth="sm"
      open={open}
      onClose={onClose}
      content={dialogContent}
      dialogActions={dialogActions}
    />
  );
};

export default FundUpdatesDialog;
