import React from 'react';
import Grid from '@mui/material/Grid';

import SingleValidationHint from './components/SingleValidationHint';

const ValidationHints = ({ hints, column = 1 }) => (
  <Grid container item xs={12}>
    {hints.map((hint) => (
      <Grid item xs={12} md={12 / column} key={hint.message}>
        <SingleValidationHint hint={hint.message} isValid={hint.isValid} />
      </Grid>
    ))}
  </Grid>
);

export default ValidationHints;
