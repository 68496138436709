import React, { useMemo } from 'react';
import SummarySection from 'components/SummarySection';

import {
  SUMMARY_FIELDS_AUSTRALIAN_BANK_SUMMARY,
  SUMMARY_FIELDS_OVERSEAS_BANK_SUMMARY,
} from 'constants/investmentEntity';
import { bankDetailsValidationSchema } from 'validations/schema/addInvestment/bankDetailsValidationSchemas';

const BankDetailsSummary = ({
  title,
  values,
  setValues,
  withEdit = true,
  sectionListView = false,
  excludedFields,
}) => {
  const fields = useMemo(() => {
    const bankFields = values?.isAustralianBased
      ? SUMMARY_FIELDS_AUSTRALIAN_BANK_SUMMARY
      : SUMMARY_FIELDS_OVERSEAS_BANK_SUMMARY;

    if (!excludedFields) return bankFields;

    return bankFields.filter((field) => !excludedFields.includes(field.name));
  }, [excludedFields]);

  return (
    <SummarySection
      title={title}
      fields={fields}
      values={values}
      setValues={setValues}
      validationSchema={bankDetailsValidationSchema}
      withEdit={withEdit}
      sectionListView={sectionListView}
    />
  );
};

export default BankDetailsSummary;
