import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';

import ResponseMappers from 'utils/ResponseMappers';
import adminVideoValidationSchema from 'validations/schema/admin/adminVideoValidationSchema';

import VideoOverview from './VideoOverview';

const Video = forwardRef(
  (
    {
      values,
      errors,
      setValues,
      setFieldError,
      setDirty,
      fieldKey,
      onSubmit,
      readOnly,
      imperativeHandleDepedencies = [],
    },
    ref,
  ) => {
    const handleSubmit = async (formValues, formikHelper) => {
      const res = await onSubmit({ [fieldKey]: formValues }, formikHelper);

      if (res.video.id) {
        const newVideo = ResponseMappers.admin.video(res);
        // Populate from BE response
        setValues(newVideo);
        formikHelper.setValues(newVideo.video);
      }
    };

    const videoForm = useFormik({
      initialValues: values,
      validateOnChange: true,
      validationSchema: adminVideoValidationSchema,
      onSubmit: handleSubmit,
      initialErrors: {},
    });
    const initiated = useRef();

    useEffect(() => {
      initiated.current = false;
    }, []);

    useEffect(() => {
      if (!initiated.current) {
        initiated.current = true;
        return;
      }

      setDirty(true);
    }, [videoForm.values, videoForm.values]);

    useEffect(() => {
      const isError = !!Object.keys(videoForm.errors).length;

      if (isError !== errors) setFieldError(fieldKey, isError);
    }, [videoForm.errors]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues: () => videoForm.values,
        handleSubmit: videoForm.submitForm,
      }),
      [videoForm.values, ...imperativeHandleDepedencies],
    );

    return (
      <Grid container>
        <VideoOverview
          readOnly={readOnly}
          onChange={videoForm.handleChange}
          onBlur={videoForm.handleBlur}
          values={videoForm.values}
          errors={videoForm.errors}
          setFieldValue={videoForm.setFieldValue}
        />
      </Grid>
    );
  },
);

export default memo(Video);
