import getFullName from './getFullName';

const personalLabel = (personalData) => getFullName(personalData);

const addressLabel = (addressData) => addressData.address1;

export default {
  personalLabel,
  addressLabel,
};
