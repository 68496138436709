import { useState, useCallback } from 'react';

import condTrueFalse from 'utils/condTrueFalse';
import isFunction from 'utils/isFunction';

const useTabs = ({
  defaultTabIdx = 0,
  tabChangeCallback,
  callbackDependencies = [],
  isTabValidated = true,
  tabs = [],
} = {}) => {
  const [activeTabIdx, setActiveTabIdx] = useState(defaultTabIdx);

  const handleTabChange = useCallback(
    async (_props, index) => {
      if (
        condTrueFalse(isTabValidated && !tabs[index]?.disabled, isTabValidated)
      ) {
        setActiveTabIdx(index);
        if (isFunction(tabChangeCallback)) {
          await tabChangeCallback();
        }
      }
    },
    [...callbackDependencies, isTabValidated, tabs],
  );

  return {
    activeTabIdx,
    setActiveTabIdx,
    handleTabChange,
  };
};

export default useTabs;
