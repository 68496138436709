import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import EllipsisTypography from 'components/Typography/EllipsisTypography';
import config from 'utils/config';
import palette from 'theme/palette';
import useScreenQuery from 'hooks/useScreenQuery';

const defaultEventDate = new Date().toISOString();

const EventCard = ({
  eventDate = defaultEventDate,
  category = 'webinar',
  title = 'Smart ways to setup your finance investment from a young age. Smart ways to',
  height = '260px',
  onClickEvent,
}) => {
  const { isExtraLargeScreen } = useScreenQuery();
  const { date, month, fullDate, time } = useMemo(
    () => ({
      date: new Date(eventDate).getDate(),
      month: new Date(eventDate).toLocaleString('en-AU', { month: 'short' }),
      fullDate: new Date(eventDate).toDateString(),
      time: new Date(eventDate).toLocaleTimeString('default', {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'long',
      }),
    }),
    [eventDate],
  );

  return (
    <Grid
      container
      minHeight={height}
      maxHeight={height}
      sx={{ cursor: 'pointer' }}
      onClick={onClickEvent}
    >
      <Grid
        container
        item
        xs={12}
        direction="column"
        sx={{
          backgroundColor: config.system.appColours.cardBackground,
        }}
        px={[1.5, 3]}
      >
        <Grid
          item
          xs={6}
          display="flex"
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Typography
            variant="display3"
            color="#7877CE"
            fontFamily="Inter"
            mb={2}
          >
            {month}
          </Typography>
          <Typography variant="bodyStatic" fontWeight={700} fontSize={28}>
            {date}
          </Typography>
        </Grid>
        <Grid item xs={6} alignSelf="center">
          <Typography
            variant="smallStatic"
            fontSize="11px"
            fontWeight={700}
            color={palette.text.obsidian}
          >
            {category}
          </Typography>
          <EllipsisTypography
            variant="smallStatic"
            ellipsisAtLine={title.length > 20 && !isExtraLargeScreen ? 1 : 2}
            color={palette.text.obsidian}
            marginBottom={1.5}
          >
            {title}
          </EllipsisTypography>
          <Typography
            variant="smallStatic"
            fontSize="11px"
            display="block"
            color={palette.text.obsidian}
          >
            {fullDate}
          </Typography>
          <Typography
            variant="smallStatic"
            fontSize="11px"
            color={palette.text.obsidian}
          >
            {time}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(EventCard);
