import React, { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import history from 'utils/history';
import { ENTITY_TYPES } from 'constants/investmentEntity';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as TrustIcon } from 'assets/icons/trust.svg';

import IconButton from 'components/Button/IconButton';
import TextButton from 'components/Button/TextButton';

import Link from 'components/Link';
import { generateMailTo } from 'utils/email';
import { REACH_ADMIN_EMAIL } from 'constants/general';

import palette from 'theme/palette';
import InvestmentEntityContainer from '../components/InvestmentEntityContainer';

const mailHref = generateMailTo({
  email: REACH_ADMIN_EMAIL,
  subject: '',
});

const handleSelectType = ({ key, href }) => {
  history.push(`/add-investment-entity/${key}`, { href });
};

const getInvestorTypes = ({ onClick = handleSelectType, href }) => [
  {
    key: ENTITY_TYPES.INDIVIDUAL,
    label: 'Individual / Joint',
    icon: <PersonIcon />,
    onClick: () => onClick({ key: ENTITY_TYPES.INDIVIDUAL, href }),
  },
  {
    key: ENTITY_TYPES.COMPANY,
    label: 'Company',
    icon: <CompanyIcon width={93} height={93} />,
    onClick: () => onClick({ key: ENTITY_TYPES.COMPANY, href }),
  },
  {
    key: ENTITY_TYPES.TRUST,
    label: 'Trust',
    icon: <TrustIcon />,
    onClick: () => onClick({ key: ENTITY_TYPES.TRUST, href }),
  },
];

const SelectInvestorType = ({ onBack, onSelectType }) => {
  const handleBack = useCallback(() => {
    if (onBack) {
      onBack();
      return;
    }

    history.push('/add-investment-entity', {
      href: history?.location?.state?.href,
    });
  }, []);

  const investorTypes = useMemo(
    () =>
      getInvestorTypes({
        onClick: onSelectType,
        href: history?.location?.state?.href,
      }),
    [],
  );

  return (
    <InvestmentEntityContainer onBack={handleBack}>
      <Grid
        container
        spacing={{ xs: 1, md: 8 }}
        justifyContent="center"
        direction="column"
        px={{ xl: 14, lg: 10, md: 5, xs: 3 }}
      >
        <Grid item textAlign="center">
          <Typography variant="h2" mb={4}>
            Type of investor
          </Typography>
          <Typography variant="body">
            Please select the entity that you want to register to invest.
          </Typography>
        </Grid>

        <Grid
          container
          item
          justifyContent="center"
          spacing={{ xs: 2, md: 4, lg: 8 }}
          direction="row"
        >
          {investorTypes.map(({ key, ...investorType }) => (
            <Grid item key={key}>
              <IconButton {...investorType} />
            </Grid>
          ))}
        </Grid>

        <Grid item textAlign="center">
          <TextButton variant="displayMobile" color={palette.reach.link}>
            <Link href={mailHref} target="_blank">
              Contact us
            </Link>
          </TextButton>
        </Grid>
      </Grid>
    </InvestmentEntityContainer>
  );
};

export default SelectInvestorType;
