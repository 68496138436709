import React from 'react';
import CompanyDetailsSummary from 'containers/AddInvestmentEntity/components/Forms/CompanyDetailsSummary';
import { mapDetailsToPayload } from './HelperFunction';

const CompanyDetails = ({ onChange, values, ...props }) => {
  const handleChange = (newValues) => {
    const { addCertificates, payload } = mapDetailsToPayload({
      oldValues: values,
      newValues,
      key: 'companyDetail',
    });

    onChange({
      fileUpload: addCertificates,
      ...payload,
    });
  };
  return (
    <CompanyDetailsSummary
      certificateDownloadable
      setValues={handleChange}
      values={values}
      {...props}
    />
  );
};
export default CompanyDetails;
