const INVESTMENT_INVALID_PARAMETERS = 'error.investment.invalid-parameters';

const ERROR_CODES = {
  INVESTMENT_INVALID_PARAMETERS,
};
const ERROR_MSG = {
  [INVESTMENT_INVALID_PARAMETERS]:
    'Investment data is invalid, pleasse try again.',
};

const ERROR = {
  [INVESTMENT_INVALID_PARAMETERS]: {
    code: INVESTMENT_INVALID_PARAMETERS,
    message: ERROR_MSG[INVESTMENT_INVALID_PARAMETERS],
  },
};

export default { ERROR, ERROR_CODES, ERROR_MSG };
