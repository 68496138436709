import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ShowMe from 'components/ShowMe';
import MoneyToUnitsConverter from 'components/MoneyToUnitsConverter';

import condTrueFalse from 'utils/condTrueFalse';
import formatCurrency from 'utils/formatCurrency';
import getNumbers from 'utils/strings/getNumbers';
import palette from 'theme/palette';

import SubmitCancel from './Actions/SubmitCancel';
import Dialog from '.';

const defaultContentText = (
  <span>
    Redemptions for the Reach Treasury Fund are processed on a monthly basis,
    provided that are made before 5pm on the 24th of each month (if it falls on
    a weekend or a non-business day, then by 5pm of the last business day before
    the 24th.
    <br />
    <br />
    If you’ve agreed to put funds in the [Reach Treasury Fund] awaiting capital
    calls into another Fund, then you will not be able to withdraw an amount
    that means the remaining balance is less than what is to be called in the
    Reach Alts Fund.
  </span>
);

const FundRedemptionDialog = ({
  title = 'Fund',
  subtitle = 'Redemption',
  contentText = defaultContentText,
  onClose,
  onConfirm,
  dialogContentDependencies = [],
  confirmButtonProps,
  errorText,
  submitLabel = 'Continue',
  setErrorText,
  submitBtnDisabled,
  portfolio = {},
  ...props
}) => {
  const [redemptionAmount, setRedemptionAmount] = useState('');

  useEffect(() => {
    if (!props.open) setRedemptionAmount('');
  }, [props.open]);

  const handleChange = useCallback((event) => {
    condTrueFalse(setErrorText, () => setErrorText(''));
    setRedemptionAmount(event.target.value);
  }, []);

  const handleSubmitRedemption = useCallback(() => {
    condTrueFalse(onConfirm, () => onConfirm(redemptionAmount));
  }, [onConfirm, redemptionAmount]);

  const DialogTitle = useMemo(() => title, [title]);

  const DialogContent = useMemo(
    () => (
      <Grid container rowSpacing={3} direction="column">
        <Grid item>
          <Typography variant="smallGrey" pl={6}>
            {subtitle}
          </Typography>
          <Grid item px={6} my={6}>
            <Typography variant="smallStatic">{contentText}</Typography>
          </Grid>
          <Divider flexItem orientation="horizontal" />
        </Grid>
        <Grid container item rowSpacing={1} direction="column" px={6}>
          <Grid item>
            <MoneyToUnitsConverter
              updateToMax
              name="redemptionAmount"
              value={redemptionAmount}
              unitPrice={portfolio.sharePrice}
              currency={portfolio.currency}
              onChange={handleChange}
              minMoney={0}
              maxMoney={Number(portfolio.committedAmount)}
            />
          </Grid>
          <Grid item textAlign="center">
            <Typography variant="h6" display="inline" mr={4}>
              Minimum redemption
            </Typography>
            <Typography variant="display6">
              : {formatCurrency({ currency: portfolio?.currency, value: 5000 })}{' '}
              (or entire balance)
            </Typography>
          </Grid>
          <ShowMe when={props.open && errorText}>
            <Grid item xs={12}>
              <Typography variant="body" color={palette.reach.red}>
                {errorText}
              </Typography>
            </Grid>
          </ShowMe>
        </Grid>
      </Grid>
    ),
    [
      subtitle,
      errorText,
      redemptionAmount,
      ...dialogContentDependencies,
      confirmButtonProps,
      portfolio,
    ],
  );

  return (
    <Dialog
      title={DialogTitle}
      content={DialogContent}
      dialogActions={
        <SubmitCancel
          submitLabel={submitLabel}
          onSubmitClick={handleSubmitRedemption}
          submitDisabled={!getNumbers(redemptionAmount) || submitBtnDisabled}
        />
      }
      onClose={onClose}
      maxWidth="md"
      dialogTitleProps={{
        mt: 2,
        justifyContent: 'auto',
        alignItems: 'auto',
        variant: 'display4',
        minHeight: '18px',
        px: '72px !important',
      }}
      dialogContentProps={{
        sx: { overflowY: 'hidden' },
        style: { maxHeight: '500px' },
      }}
      dialogActionsProps={{
        sx: { px: '72px !important', pb: '40px !important' },
      }}
      {...props}
    />
  );
};

export default FundRedemptionDialog;
