export const ARTICLE_CATEGORY_OPTIONS = [
  { label: 'Private Equity', value: 'Private Equity' },
  { label: 'Venture Capital', value: 'Venture Capital' },
  { label: 'Private Credit', value: 'Private Credit' },
  { label: 'Impact', value: 'Impact' },
  { label: 'Buyouts', value: 'Buyouts' },
  { label: 'Infrastructure', value: 'Infrastructure' },
  { label: 'Secondaries', value: 'Secondaries' },
  { label: 'Market Trends', value: 'Market Trends' },
  { label: 'Investment Strategies', value: 'Investment Strategies' },
  { label: 'Portfolio Management', value: 'Portfolio Management' },
  { label: 'Regulatory Updates', value: 'Regulatory Updates' },
  { label: 'Economic Analysis', value: 'Economic Analysis' },
  { label: 'Fund Performance', value: 'Fund Performance' },
  { label: 'Risk Management', value: 'Risk Management' },
  { label: 'Sector Focus', value: 'Sector Focus' },
  { label: 'Global Markets', value: 'Global Markets' },
  { label: 'Interviews', value: 'Interviews' },
  { label: 'Case Studies', value: 'Case Studies' },
  { label: 'Sustainable Investing', value: 'Sustainable Investing' },
  { label: 'Fundraising Insights', value: 'Fundraising Insights' },
  { label: 'Emerging Markets', value: 'Emerging Markets' },
  { label: 'Liquidity', value: 'Liquidity' },
  { label: 'Reach Alts', value: 'Reach Alts' },
  { label: 'Webinars', value: 'Webinars' },
  { label: 'Meet the Manager', value: 'Meet the Manager' },
  { label: 'Hedge Funds', value: 'Hedge Funds' },
  { label: 'Diversification', value: 'Diversification' },
  { label: 'Market Analysis', value: 'Market Analysis' },
  { label: 'Investor Profiles', value: 'Investor Profiles' },
];

export const VIDEO_CATEGORY_OPTIONS = [
  ...ARTICLE_CATEGORY_OPTIONS,
  { label: 'Fireside Chat', value: 'Fireside Chat' },
];
