import React, { memo } from 'react';

import Chip from '@mui/material/Chip';

const TotalChip = ({ label }) => (
  <Chip
    label={label}
    color="fundStatusClosedChipColor"
    sx={{ height: '24px', marginLeft: '10px' }}
  />
);

export default memo(TotalChip);
