import React, { useCallback } from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

import Check from '@mui/icons-material/Check';

import { Typography, styled } from '@mui/material';

import palette from 'theme/palette';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  borderTopWidth: '3px',
  top: '15px',
  // [`&.${stepConnectorClasses.alternativeLabel}`]: {
  //   top: 10,
  //   left: 'calc(-50% + 16px)',
  //   right: 'calc(50% + 16px)',
  // },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.reach.purpleHaze,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.reach.purpleHaze,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: '3px',
    borderRadius: 1,
  },
}));

const StepCircle = ({ style = [], ...props }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '25px',
      backgroundColor: palette.reach.purpleHaze,
      width: '38px',
      height: '38px',
      ...style,
    }}
    {...props}
  />
);

const ProgressStepper = ({ steps = [], activeStep = 0 }) => {
  const QontoStepIcon = useCallback(
    (props) => {
      const { active, completed, icon } = props;

      const StepIcon = steps[icon - 1].icon;

      switch (true) {
        case completed:
          return (
            <StepCircle>
              <Check sx={{ color: 'white' }} />
            </StepCircle>
          );
        case active:
          return (
            <StepCircle>
              <StepIcon
                className="active-icon-color-inverse"
                sx={{ color: 'white' }}
              />
            </StepCircle>
          );
        default:
          return (
            <StepCircle style={{ backgroundColor: '#E4E4E4' }}>
              <StepIcon
                className="active-icon-color-inverse"
                sx={{ color: 'white' }}
              />
            </StepCircle>
          );
      }
    },
    [steps],
  );

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<QontoConnector />}
    >
      {steps.map(({ label }, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            sx={{ textAlign: 'left' }}
          >
            <Typography
              sx={{ fontWeight: 500, fontSize: '10px', color: '#A5A5A5' }}
            >
              Step {index + 1}
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: '13px' }}>
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ProgressStepper;
