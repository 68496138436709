import { useState } from 'react';

import Services from 'api/services';
import ResponseMappers from 'utils/ResponseMappers';

import useFetchApi from './useFetchApi';

const useUserDetailListData = ({
  userId,
  defaultApiData = { personals: [], addresses: [] },
}) => {
  const [isUserDetailListFetched, seUserDetailListtIsFetched] = useState(false);

  const { apiData: userDetailList } = useFetchApi({
    fetchService: () => Services.getInvestmentEntityUserDetailList(userId),
    defaultApiData,
    mapApiData: (data) =>
      ResponseMappers.investmentEntityUserDetailList(data?.data),
    isFetched: isUserDetailListFetched,
    setIsFetched: seUserDetailListtIsFetched,
  });

  return { userDetailList };
};

export default useUserDetailListData;
