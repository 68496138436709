import personalDetailsValidationSchema from '../addInvestment/personalDetailsValidationSchema';
import taxDetailsValidationSchema from '../addInvestment/taxDetailsValidationSchema';
import addressValidationSchema from '../addInvestment/addressValidationSchema';
import investorIdentificationValidationSchema from '../addInvestment/investorIdentificationValidationSchema';

const addJointValidationSchema = personalDetailsValidationSchema
  .concat(taxDetailsValidationSchema)
  .concat(addressValidationSchema)
  .concat(investorIdentificationValidationSchema);

export default addJointValidationSchema;
