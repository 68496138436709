import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import Input from 'components/Input';

const ProfileForm = ({ values }) => (
  <Grid container item spacing={3} justifyContent="center">
    <Grid container spacing={3} sm={10} md={8} item>
      <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="firstName">
          <Typography variant="body">First Name</Typography>
        </InputLabel>
        <Input
          disabled
          fullWidth
          data-testid="firstName-input"
          id="firstName"
          name="firstName"
          value={values.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="middleName">
          <Typography variant="body">Middle Name</Typography>
        </InputLabel>
        <Input
          disabled
          fullWidth
          data-testid="middleName-input"
          id="middleName"
          name="middleName"
          value={values.middleName}
        />
      </Grid>
    </Grid>
    <Grid container spacing={3} sm={10} md={8} item>
      <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="lastName">
          <Typography variant="body">Last Name</Typography>
        </InputLabel>
        <Input
          disabled
          fullWidth
          data-testid="lastName-input"
          id="lastName"
          name="lastName"
          value={values.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="email">
          <Typography variant="body">Email</Typography>
        </InputLabel>
        <Input
          disabled
          fullWidth
          data-testid="email-input"
          id="email"
          name="email"
          value={values.email}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default memo(ProfileForm);
