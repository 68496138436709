import MUIContainer from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import config from 'utils/config';

const drawerDashboardWidth = config.drawer.dashboard.width + 12;
const AppBarContainer = styled(MUIContainer)(({ theme, paddingleft }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(0)} 0px ${
      paddingleft || theme.spacing(0)
    } !important`,
    marginLeft: `${drawerDashboardWidth}px`,
    width: `calc(100% - ${drawerDashboardWidth}px)`,
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0px',
    padding: `${theme.spacing(3)} ${theme.spacing(1)} 0px ${
      paddingleft || theme.spacing(1)
    } !important`,
  },
}));

export default AppBarContainer;
