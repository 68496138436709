import React from 'react';

import Grid from '@mui/material/Grid';

import FormTitle from 'components/FormTitle';
import SignSection from 'components/SignSection';

const SigninDocument = ({ values, ...props }) => (
  <Grid container item>
    <Grid item xs={12}>
      <FormTitle title="Signin Document" />
    </Grid>

    <Grid container item xs={12} rowSpacing={3}>
      <SignSection signedBy={values.userName} values={values} {...props} />
    </Grid>
  </Grid>
);

export default SigninDocument;
