import { memo } from 'react';
import { styled } from '@mui/material/styles';
import MUIDivider from '@mui/material/Divider';

const OptionDivider = styled(MUIDivider)(
  ({ theme, bordercolor, style = {} }) => ({
    border: `1px solid ${bordercolor || theme.palette.text.grey}`,
    marginBottom: '10px',
    cursor: 'unset',
    padding: 0,
    marginRight: '14px',
    marginLeft: '14px',
    ...style,
  }),
);

export default memo(OptionDivider);
