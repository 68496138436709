import * as yup from 'yup';

const email = {
  email: yup.string().email().required().label('Email'),
  emailConfirmation: yup
    .string()
    .oneOf([yup.ref('email'), null], 'Your email addresses do not match')
    .required()
    .label('Email Confirmation'),
};

export default email;
