import getAutoPopulateChipLabel from 'utils/getAutoPopulateChipLabel';

import {
  mapResponseAddressDetail,
  mapResponsePersonalDetail,
} from './personalDetail';

const investmentEntityUserDetailList = (detailList) => ({
  personals: detailList?.personals?.map((personal) => ({
    ...mapResponsePersonalDetail(personal),
    label: getAutoPopulateChipLabel.personalLabel(personal),
  })),
  addresses: detailList?.addresses?.map((address) => ({
    ...mapResponseAddressDetail(address),
    label: getAutoPopulateChipLabel.addressLabel(address),
  })),
});

export default investmentEntityUserDetailList;
