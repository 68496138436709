import React, { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import TrashIcon from '@mui/icons-material/DeleteOutline';

import { useFormik } from 'formik';

import { getAccordionFooter } from 'containers/Admin/Funds/Details/FundDetails/HelperFunction';
import FieldListContainer from 'components/FieldListContainer';
import Input from 'components/Input';
import Button from 'components/Button';
import TextButton from 'components/Button/TextButton';
import UploadDialog from 'components/UploadDialog';
import ShowMe from 'components/ShowMe';

import useUploadFiles from 'hooks/useUploadFiles';
import useDialog from 'hooks/useDialog';

import DocumentList from './DocumentList';
import DraggableContainer from './DraggableContainer';

const defaultSubfolderData = {
  category: '',
  documents: [],
};

const Category = ({
  value,
  index,
  onChange,
  onDeleteCategory,
  reinitializeCategory,
  onUploadFiles,
  onDeleteFiles,
  disabled,
  maxFiles = 10,
  maxSize,
  withSubFolders,
  subFolder,
}) => {
  const categoryForm = useFormik({
    initialValues: value || {
      category: '',
      documents: [],
    },
  });

  const handleDeleteFiles = useCallback(async (fileToRemove) => {
    let result = false;
    if (fileToRemove?.length) {
      const { key } = fileToRemove[0];
      result = await onDeleteFiles([key], { category: value });
    }
    return !!result;
  }, []);

  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    files,
    getFileName,
    handleRemoveFile,
    handleChangeFiles,
    isDisabled,
  } = useUploadFiles({
    name: 'documents',
    value: categoryForm?.documents || value?.documents || [],
    onChangeCallback: categoryForm.handleChange,
    onRemoveValidation: handleDeleteFiles,
    maxFiles,
    maxFileNameLength: 40,
  });

  useEffect(() => {
    if (reinitializeCategory) {
      categoryForm.setValues(value);
      handleChangeFiles({ newFiles: value.documents });
    }
  }, [reinitializeCategory, value]);

  useEffect(() => {
    if (!reinitializeCategory) onChange(categoryForm.values);
  }, [JSON.stringify(categoryForm.values)]);

  const handleFileListChange = useCallback(
    (fileIndex) => (e) => {
      const { name, value: targetValue } = e.target;
      if (name) {
        const fileData = [...files];
        fileData[fileIndex][name] = !!targetValue;
        handleChangeFiles({ newFiles: fileData });
      }
    },
    [files],
  );

  const handleUpdateFileList = useCallback(
    (dialogFiles) => {
      const newFile = dialogFiles.map((dialogFile) => ({
        ...dialogFile,
        isHidden: false,
        enableView: true,
        enableDownload: true,
        isDeleted: false,
        title: dialogFile?.originalFileName,
        folder: categoryForm.values.category,
      }));

      handleChangeFiles({ newFiles: [...files, ...newFile] });
    },
    [files, categoryForm.values.category],
  );

  const handleDeleteCategory = useCallback(() => {
    onDeleteCategory({ category: value });
  }, [onDeleteCategory]);

  const handleAddSubFolder = useCallback(() => {
    const newSubFolders = [...categoryForm.values.subFolders];
    newSubFolders.push(defaultSubfolderData);

    categoryForm.setValues({
      ...categoryForm.values,
      subFolders: newSubFolders,
    });
  }, [categoryForm.values]);

  const handleDeleteSubFolder = useCallback(
    (deletedIndex) =>
      ({ category: deletedSubFolder }) => {
        const newSubFolders = [...categoryForm.values.subFolders].filter(
          (_, subFolderIdx) => subFolderIdx !== deletedIndex,
        );

        categoryForm.setFieldValue('subFolders', newSubFolders);

        const deletedDocuments =
          categoryForm.values.subFolders[deletedIndex]?.documents || [];

        onDeleteFiles(
          deletedDocuments.map((document) => document.key),
          { category: deletedSubFolder, isDeleteCategory: true },
        );
      },
    [categoryForm.values.subFolders],
  );

  const handleChangeSubFolder = useCallback(
    (subFolderIndex) => (subFolderValues) => {
      const newSubFolders = [...categoryForm.values.subFolders];
      newSubFolders[subFolderIndex] = { ...subFolderValues };

      categoryForm.setFieldValue('subFolders', newSubFolders);
    },
    [categoryForm.values.subFolders],
  );

  const handleMoveSubCategory = useCallback(
    (currentIndex, newIndex) => {
      const newSubFolders = [...categoryForm.values.subFolders];

      // Swap the sub category index.
      [newSubFolders[currentIndex], newSubFolders[newIndex]] = [
        newSubFolders[newIndex],
        newSubFolders[currentIndex],
      ];

      categoryForm.setFieldValue('subFolders', newSubFolders);
    },
    [categoryForm.values.subFolders],
  );

  return (
    <FieldListContainer listNo={index} hidden={subFolder}>
      <Grid container item xs spacing={1}>
        <Grid item xs={9}>
          <Input
            fullWidth
            placeholder="Category Name"
            name="category"
            value={categoryForm.values.category}
            onChange={categoryForm.handleChange}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleOpenDialog} disabled={isDisabled || disabled}>
            Upload
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={1}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <TextButton
            onClick={handleDeleteCategory}
            Icon={TrashIcon}
            color={disabled ? 'disabled' : 'error'}
            iconSx={{ width: '36px', height: '36px' }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={11}>
          <DocumentList
            fileList={files}
            name="categoryFiles"
            getFileName={getFileName}
            onChange={handleFileListChange}
            onRemove={!disabled && handleRemoveFile}
            disabled={disabled}
            onChangeFiles={handleChangeFiles}
          />
        </Grid>
        <ShowMe when={!subFolder}>
          <Grid container item xs={12}>
            {value?.subFolders?.map?.((category, idx) => (
              <Grid item xs={12} p={1} key={`category-${category.id}`}>
                <DraggableContainer
                  type="subCategory"
                  id={category.id}
                  index={idx}
                  onMove={handleMoveSubCategory}
                  itemProps={{
                    subCategoryId: category.id,
                    categoryId: value.id,
                  }}
                >
                  <Category
                    subFolder
                    value={category}
                    index={idx + 1}
                    onChange={handleChangeSubFolder(idx)}
                    onDeleteCategory={handleDeleteSubFolder(idx)}
                    reinitializeCategory={reinitializeCategory}
                    onUploadFiles={onUploadFiles}
                    onDeleteFiles={onDeleteFiles}
                    disabled={disabled}
                    maxSize={maxSize}
                  />
                </DraggableContainer>
              </Grid>
            ))}
          </Grid>
        </ShowMe>
        <ShowMe when={!subFolder && withSubFolders}>
          <Grid item xs={12}>
            {getAccordionFooter({
              addKey: 'Subcategory',
              onAdd: handleAddSubFolder,
              addIconColor: 'black',
              textColor: 'black',
            })}
          </Grid>
        </ShowMe>
      </Grid>
      <UploadDialog
        open={isDialogOpen}
        onUpload={onUploadFiles}
        onClose={handleCloseDialog}
        onCloseCallback={handleUpdateFileList}
        onRemoveValidation={handleDeleteFiles}
        maxFiles={maxFiles - files.length}
        maxSize={maxSize}
      />
    </FieldListContainer>
  );
};

export default Category;
