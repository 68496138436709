import * as yup from 'yup';

import dateValidationSchema from 'validations/dateValidationSchema';

const individualFormValidationSchema = () => ({
  firstName: yup.string().required('First Name is required.'),
  middleName: yup.string().label('Middle Name'),
  lastName: yup.string().required('Last Name is required.'),
  ...dateValidationSchema(),
});

export default individualFormValidationSchema;
