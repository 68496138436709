import React from 'react';

import { Typography } from '@mui/material';

const points = [
  <>
    <b>MOIC</b> (Multiple on Invested Capital) measures the total value returned after all fees and expenses, relative to the amount of capital invested. Expressed as a multiple. For example, 1.5x means the fund has returned 1.5 times the invested capital, it indicates the net profit generated.
  </>,
  <>
    <b>DPI</b> (Distributions to Paid-In Capital) measures the cash that has been returned to you as a multiple of the capital you have contributed. For example, a DPI of 0.8x means that 80% of the invested capital has been returned. It indicates how much of the investment has been realised.
  </>,
  <>
    <b>RVPI</b> (Residual Value to Paid-In Capital) measures the remaining value of the investment relative to the capital invested. For example, an RVPI of 0.7x means that the remaining value of the investment is 70% of the invested capital. It indicates the unrealised value that still remains.
  </>,
  <>
    <b>TVPI</b> (Total Value to Paid-In Capital) combines DPI and RVPI to measure the total value generated by an investment, both realised and unrealised, as a multiple of the capital invested. For example, a TVPI of 1.5x means that the total value (both distributed and remaining) is 1.5 times the invested capital. It provides a comprehensive view of the investment&apos;s overall performance.
  </>,
];

const importantNote = `It is important to note in the early years, during the investment phase, returns will likely be lower (or negative) due to the time required for investments to mature and generate returns. Consequently, early performance should not be seen as indicative of the fund's long-term potential, with returns likely escalating towards the end of the fund’s lifecycle. Past performance is not a guarantee nor necessarily a reliable indicator of future performance.`;
const infoNote = 'The above multiples are standard measures in the private markets space to help you understand different aspects of the fund’s performance:';

const Notes = () => (
  <>
    <Typography fontWeight={700}>Notes:</Typography>
    <Typography>{infoNote}</Typography>
    <ul>
      {points.map((point) => (
        <li>{point}</li>
        ))}
    </ul>
    <Typography>{importantNote}</Typography>
  </>
);

export default Notes;
