import redemptionAmountValidationSchema from './redemptionAmountValidationSchema';
import selectFundValidationSchema from './selectFundValidationSchema';
import signingValidationSchema from './signingValidationSchema';

const redemptionRequestSchemas = [
  selectFundValidationSchema,
  redemptionAmountValidationSchema,
  null,
  signingValidationSchema,
];

export default redemptionRequestSchemas;
