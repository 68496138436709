import config from '../utils/config';

const MAIN_URL = config.api.url;

export default {
  AUTH_LOGIN: `${MAIN_URL}/login`,
  AUTH_REFRESH_TOKEN: `${MAIN_URL}/login/refresh`,
  AUTH_LOGOUT: `${MAIN_URL}/logout`,
  AUTH_REGISTER: `${MAIN_URL}/registration/sign-up`,
  AUTH_LOGIN_VERIFY_MFA: `${MAIN_URL}/login/verify`,
  REGISTRATION_CHECK_EMAIL: `${MAIN_URL}/registration/check-email`,
  REGISTRATION_RESEND_EMAIL_VERIFICATION: `${MAIN_URL}/registration/resend-email-verification`,
  REGISTRATION_VERIFY_EMAIL: `${MAIN_URL}/registration/email-verification/verify`,
  REGISTRATION_SEND_MOBILE_NUMBER_VERIFICATION_CODE: `${MAIN_URL}/registration/verification-code/send`,
  REGISTRATION_RESEND_MOBILE_NUMBER_VERIFICATION_CODE: `${MAIN_URL}/registration/verification-code/resend`,
  REGISTRATION_VERIFY_MOBILE_NUMBER_VERIFICATION_CODE: `${MAIN_URL}/registration/verification-code/verify`,
  REGISTRATION_UPDATE_CONSENT: `${MAIN_URL}/registration/consent`,
  USER_PROFILE_SEND_MOBILE_NUMBER_VERIFICATION_CODE: `${MAIN_URL}/user/phone-number/change`,
  USER_PROFILE_RESEND_MOBILE_NUMBER_VERIFICATION_CODE: `${MAIN_URL}/user/phone-number/resend`,
  USER_PROFILE_VERIFY_MOBILE_NUMBER_VERIFICATION_CODE: `${MAIN_URL}/user/phone-number/verify`,
  USER_PROFILE_SAVE_MOBILE_NUMBER: `${MAIN_URL}/user/phone-number/save`,
  FORGOT_PASSWORD_SEND_EMAIL: `${MAIN_URL}/login/password/forgot`,
  FORGOT_PASSWORD_VERIFY: `${MAIN_URL}/login/password/verify`,
  FORGOT_PASSWORD_RESEND_EMAIL: `${MAIN_URL}/login/password/resend`,
  PASSWORD_RESET: `${MAIN_URL}/login/password/reset`,
  GET_USER_PROFILE: `${MAIN_URL}/user/profile`,
  GET_USER_ACCESSIBILITY: `${MAIN_URL}/user/accessibility`,
  CHANGE_PASSWORD_SEND_EMAIL: `${MAIN_URL}/user/password/confirm`,
  CHANGE_PASSWORD_VERIFY: `${MAIN_URL}/user/password/verify`,

  GET_FUNDS: `${MAIN_URL}/funds`,
  GET_ARTICLES: `${MAIN_URL}/articles`,
  GET_EVENTS: `${MAIN_URL}/events`,
  GET_VIDEOS: `${MAIN_URL}/videos`,

  ADD_INVESTMENT_ENTITY_INDIVIDUAL: `${MAIN_URL}/investments/individual`,
  ADD_INVESTMENT_ENTITY_COMPANY: `${MAIN_URL}/investments/company`,
  ADD_INVESTMENT_ENTITY_TRUST: `${MAIN_URL}/investments/trust`,
  ADD_INVESTMENT_ENTITY_UPLOAD: `${MAIN_URL}/investments/certificate/upload`,
  INVESTMENT_ENTITY: `${MAIN_URL}/investments`,
  DOCUMENTS: `${MAIN_URL}/documents`,
  DOCUMENTS_DOWNLOAD: `${MAIN_URL}/documents/download`,
  DOCUMENTS_VIEW: `${MAIN_URL}/documents/view`,
  GET_INVESTMENT_USER: `${MAIN_URL}/investments/user`,
  GET_INVESTMENT_PAYMENTS: `${MAIN_URL}/investments/payments`,
  GET_PENDING_INVESTMENT_PAYMENTS: `${MAIN_URL}/investments/pending-payments`,
  GET_INVESTED_INVESTMENTS: `${MAIN_URL}/investments/invested`,
  GET_INVESTMENT_OVERVIEW: `${MAIN_URL}/investments/overview`,
  SUBMIT_FUND_INVESTMENT: `${MAIN_URL}/investments/calculate`,
  REDEEM_CASH_FUND_INVESTMENT: `${MAIN_URL}/investments/redeem`,

  SUBMIT_USER_VERIFICATION_GREEN_ID: `${MAIN_URL}/user/verification/au-nz`,
  SUBMIT_USER_VERIFICATION: `${MAIN_URL}/user/verification/overseas`,
  GET_INVESTMENT_ENTITY_USER_DETAIL_LIST: `${MAIN_URL}/investments/list/personal-address-detail`,
  SET_USER_VIEW_FUND_STATUS: `${MAIN_URL}/user/view-fund`,

  // Fund Redemption Requests
  FUND_REDEMPTION_REQUEST: `${MAIN_URL}/fund-redemption-request`,

  // Transactions
  TRANSACTIONS: `${MAIN_URL}/transactions`,

  // Portfolio Market Transactions
  MARKET_TRANSACTIONS: `${MAIN_URL}/investments/market-transacations/byFundConfigurationId`,
  USER_INVESTMENT_COMMITMENTS: `${MAIN_URL}/investments/user-investment-commitments`,

  ADMIN_USER: `${MAIN_URL}/admin/user`,
  ADMIN_GET_ADMINS: `${MAIN_URL}/admin/users?type=admin`,
  ADMIN_GET_USERS: `${MAIN_URL}/admin/users?type=user`,
  ADMIN_USER_RESET_PASSWORD: `${MAIN_URL}/admin/user/reset-password`,
  ADMIN_GET_APPROVAL_ENTITIES: `${MAIN_URL}/admin/approval/entities?`,
  ADMIN_GET_APPROVAL_ENTITY: `${MAIN_URL}/admin/approval/entity`,
  ADMIN_GET_APPROVAL_FUNDS: `${MAIN_URL}/admin/approval/portfolios?`,
  ADMIN_FUND_ENTITY: `${MAIN_URL}/admin/approval/portfolio`,
  ADMIN_APPROVAL_APPROVE: `${MAIN_URL}/admin/approval/approve`,
  ADMIN_APPROVAL_REJECT: `${MAIN_URL}/admin/approval/reject`,
  ADMIN_USER_VERIFICATION: (id) => `${MAIN_URL}/admin/user/${id}/verification`,
  ADMIN_APPROVAL_EDIT_ENTITY_INDIVIDUAL: `${MAIN_URL}/admin/investments/individual`,
  ADMIN_APPROVAL_EDIT_ENTITY_COMPANY: `${MAIN_URL}/admin/investments/company`,
  ADMIN_APPROVAL_EDIT_ENTITY_TRUST: `${MAIN_URL}/admin/investments/trust`,

  ADMIN_PORTFOLIO: `${MAIN_URL}/admin/portfolio`,

  // Admin Fund Redemption Requests
  ADMIN_FUND_REDEMPTION_REQUEST: `${MAIN_URL}/admin/fund-redemption-request`,

  ADMIN_GET_FUNDS: `${MAIN_URL}/admin/funds?`,
  ADMIN_FUNDS: `${MAIN_URL}/admin/funds`,
  ADMIN_GET_PUBLIC_FUNDS: `${MAIN_URL}/admin/funds/public`,

  ADMIN_GET_ARTICLES: `${MAIN_URL}/admin/articles?`,
  ADMIN_ARTICLES: `${MAIN_URL}/admin/articles`,

  ADMIN_GET_EVENTS: `${MAIN_URL}/admin/events?`,
  ADMIN_EVENTS: `${MAIN_URL}/admin/events`,

  ADMIN_GET_VIDEOS: `${MAIN_URL}/admin/videos?`,
  ADMIN_VIDEOS: `${MAIN_URL}/admin/videos`,

  ADMIN_UPDATE_APPROVAL_FUND: `${MAIN_URL}/admin/portfolio`,

  ADMIN_FUNDS_DATA_ROOM: `${MAIN_URL}/admin/funds/data-rooms`,
  ADMIN_ENTITY: `${MAIN_URL}/admin/entity`,
  ADMIN_STATEMENT_DOCUMENTS: `${MAIN_URL}/admin/document`,
  ADMIN_EXPORT_INVESTMENT: `${MAIN_URL}/admin/investments/export`,
  ADMIN_EXPORT_VERIFY_INVESTMENT: `${MAIN_URL}/admin/investments/export/verify`,

  ADMIN_GET_OVERVIEW: `${MAIN_URL}/admin/overview`,
  ADMIN_GET_OVERVIEW_USER_ACTIVITY: `${MAIN_URL}/admin/overview/users`,
  ADMIN_ADD_USER_INVESTMENT_FUND: `${MAIN_URL}/admin/portfolio`,

  ADMIN_PLATFORM_CONFIGURATION_EMAIL_TEMPLATES: `${MAIN_URL}/admin/configuration/email`,
  ADMIN_PLATFORM_CONFIGURATION_IMAGE_SLIDER: `${MAIN_URL}/admin/configuration/image`,
  ADMIN_PLATFORM_CONFIGURATION_COLOURS: `${MAIN_URL}/admin/configuration/colours`,

  ADMIN_FUND_UPDATES: `${MAIN_URL}/admin/fund-updates`,
  ADMIN_FUND_REGISTRY: `${MAIN_URL}/admin/fund-registry`,
  ADMIN_FUND_PERFORMANCE: `${MAIN_URL}/admin/fund-performance`,

  FUND_PERFORMANCE: `${MAIN_URL}/fund-performances`,
};
