import mapValue from 'utils/mapValue';
import { FUND_STATUS, REPORTING_TEMPLATE_CLOSED_END } from 'constants/funds';

const defaultEndDate = () => {
  const date = new Date();
  return new Date(date.setDate(date.getDate() + 1));
};

const mapFundCard = ({ heading, value, index }) => ({
  [`field${index + 1}`]: heading || '',
  [`value${index + 1}`]: value || '',
});

const defaultFundCards = (fundCard = [...Array(5)]) =>
  fundCard.reduce(
    (fundCards, currFundCard, index) => ({
      ...fundCards,
      ...mapFundCard({ ...currFundCard, index }),
    }),
    {},
  );

export const FUND_DETAILS_DEFAULT_VALUES = {
  fundInformation: {
    summary: '',
    fundName: '',
    type: '',
    startDate: new Date(),
    endDate: defaultEndDate(),
    country: '',
    region: '',
    showWidget: false,
    showFundPercentage: false,
    fundPercentage: 0,
  },
  fundCard: {
    fundLogo: '',
    fundCardImage: '',
    fundHeaderImg: '',
  },
  financialInformation: {
    amountToRaise: 0,
    callable: 0,
    currency: '',
    unitPrice: 0,
    accessFee: 0,
    minInvestment: 0,
    maxInvestment: 0,
    bankDetail: {},
    classTermYears: '',
    fundProgress: 0,
  },
  bankDetail: {
    bankName: '',
    accountName: '',
    accountNumber: '',
    bsb: '',
    bankAddress: '',
    swiftCode: '',
    country: '',
    isShowBankDetails: false,
  },
  summaryCharts: [],
};

const mapVisibleOnTabsResponse = (visibleOnTabs = []) =>
  visibleOnTabs.map(
    (visibleOnTab) => visibleOnTab?.idTab || visibleOnTab?.fundTab?.idTab,
  );

export const mapSummaryChartResponse = (summaryChart = {}) => ({
  chart: {
    id: summaryChart.id,
    visibleOnTabs: mapValue(
      mapVisibleOnTabsResponse(summaryChart.visibleOnTabs),
      [],
    ),
    chartTitle: summaryChart.chartTitle,
    pieSections: mapValue(summaryChart.pieSections, []),
    pieNote: summaryChart.pieNote,
  },
  summaryBlock: {
    title: summaryChart.title,
    fields: summaryChart.fields,
    summaryNote: summaryChart.summaryNote,
  },
});

export const mapDataRoomResponse = (dataRooms = []) =>
  dataRooms.map((dataRoom) => ({
    ...dataRoom,
    documents:
      dataRoom.documents?.map((document) => ({
        ...document,
        originalFileName: document.title || document.originalFileName,
      })) || [],
  }));

export const mapFinancialInformation = (values) => ({
  ...FUND_DETAILS_DEFAULT_VALUES.financialInformation,
  ...FUND_DETAILS_DEFAULT_VALUES.bankDetail,
  ...values?.bankAccount,
  ...values,
  bankAccountId: values?.bankAccount?.id,
  unitPrice: mapValue(
    values.sharePrice,
    FUND_DETAILS_DEFAULT_VALUES.financialInformation.unitPrice,
  ),
  currency: mapValue(
    values.dealCurrency,
    FUND_DETAILS_DEFAULT_VALUES.financialInformation.currency,
  ),
  signDocument: values.fundConfigurationDocument?.document
    ? [
        {
          ...values.fundConfigurationDocument,
          originalFileName: values.fundConfigurationDocument?.documentName,
          url: values.fundConfigurationDocument?.document,
        },
      ]
    : [],
});

const fundDetails = (values) => {
  const financialInformationValues = values?.financialInformations?.length
    ? values.financialInformations.map?.(mapFinancialInformation)
    : [mapFinancialInformation({})];

  const response = {
    fundId: values.fundInformation?.id,
    isHidden: !!values?.fundInformation?.isHidden,
    isOpen: !!values?.fundInformation?.isOpen,
    createdBy: values?.fundInformation?.createdBy,
    createdAt: values?.fundInformation?.created_at,
    updatedBy: values?.fundInformation?.updatedBy,
    updatedAt: values?.fundInformation?.updated_at,
    isRedemptionVisible: values?.fundInformation?.isRedemptionVisible,
    reportingTemplate: mapValue(
      values?.fundInformation?.reportingTemplate,
      REPORTING_TEMPLATE_CLOSED_END,
    ),
    status: mapValue(values?.fundInformation?.status, FUND_STATUS.DRAFT),
    fundInformation: {
      fundInformation: {
        ...FUND_DETAILS_DEFAULT_VALUES.fundInformation,
        ...values.fundInformation,
        startDate:
          values.fundInformation?.startAt ||
          FUND_DETAILS_DEFAULT_VALUES.fundInformation.startDate,
        endDate:
          values.fundInformation?.expiryAt ||
          FUND_DETAILS_DEFAULT_VALUES.fundInformation.endDate,
      },
      fundCard: {
        ...FUND_DETAILS_DEFAULT_VALUES.fundCard,
        fundLogo: {
          url: mapValue(values.fundInformation?.imageAndDocument?.logo, ''),
          fileName: mapValue(
            values.fundInformation?.imageAndDocument?.logoName,
            '',
          ),
        },
        fundHeaderImg: {
          url: mapValue(values.fundInformation?.imageAndDocument?.header, ''),
          fileName: mapValue(
            values.fundInformation?.imageAndDocument?.headerName,
            '',
          ),
        },
        fundCardImage: {
          url: mapValue(values.fundInformation?.imageAndDocument?.card, ''),
          fileName: mapValue(
            values.fundInformation?.imageAndDocument?.cardName,
            '',
          ),
        },
        ...defaultFundCards(values.fundInformation?.fundCard),
        fundCards: values.fundInformation?.fundCard,
      },
    },
    // financialInformation: {
    //   ...FUND_DETAILS_DEFAULT_VALUES.financialInformation,
    //   ...FUND_DETAILS_DEFAULT_VALUES.bankDetail,
    //   ...values?.financialInformation?.bankAccount,
    //   ...values.financialInformation,
    //   unitPrice: mapValue(
    //     values.financialInformation?.sharePrice,
    //     FUND_DETAILS_DEFAULT_VALUES.financialInformation.unitPrice,
    //   ),
    //   currency: mapValue(
    //     values.financialInformation?.dealCurrency,
    //     FUND_DETAILS_DEFAULT_VALUES.financialInformation.currency,
    //   ),
    //   signDocument: values.fundInformation?.imageAndDocument?.document
    //     ? [
    //         {
    //           originalFileName:
    //             values.fundInformation?.imageAndDocument?.documentName,
    //           url: values.fundInformation?.imageAndDocument?.document,
    //         },
    //       ]
    //     : [],
    // },
    financialInformations: financialInformationValues,
    deletedFinancialInformations: [],
    deletedFundDocuments: [],

    fundDetails: mapValue(values?.fundDetail?.fundTabsWithContent, []),
    summaryChart: mapValue(values.summaryChart, []).map(
      mapSummaryChartResponse,
    ),
    dataRooms: mapDataRoomResponse(values?.dataRooms),
  };

  response.fundInformation.fundInformation.periodStatus = response.fundId
    ? !values.fundInformation?.expiryAt
    : !response.fundInformation?.fundInformation?.endDate;

  return response;
};

export default fundDetails;
