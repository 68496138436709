import { STORAGE_KEY } from 'constants/general';
import { ROLE_USER } from 'constants/userManagement';

const project = require('../../package.json');

const config = {
  system: {
    name: project.name,
    version: project.version,
    releaseDate: project.releaseDate,

    get appColours() {
      const colours = JSON.parse(
        localStorage.getItem(`${STORAGE_KEY}-appColours`) || '{}',
      );
      return {
        background: colours.appBackground,
        cardBackground: colours.appCardBackground,
        border: colours.appBorder,
      };
    },

    get currencyData() {
      const currencyData = JSON.parse(
        localStorage.getItem(`${STORAGE_KEY}-currencyData`) || '{}',
      );
      return currencyData;
    },
  },
  api: {
    url: process.env.REACT_APP_BACKEND_URL,
    get token() {
      const tempRole = sessionStorage.getItem(`${STORAGE_KEY}-tempRole`);
      const previewToken = sessionStorage.getItem(
        `${STORAGE_KEY}-previewToken`,
      );

      // If current session is a User Preview session, return the temp user token.
      if (tempRole === ROLE_USER && previewToken) {
        return previewToken;
      }

      return localStorage.getItem(`${STORAGE_KEY}-token`);
    },
  },
  greenID: {
    url: process.env.REACT_APP_GREEN_ID_URL,
    accountId: process.env.REACT_APP_GREEN_ID_ACCOUNT_ID,
    apiCode: process.env.REACT_APP_GREEN_ID_API_CODE,
  },
  public: {
    countryFlags: `${process.env.REACT_APP_FRONTEND_URL}/public-assets/images/country-flags`,
    baseUrl: process.env.REACT_APP_FRONTEND_URL,
    emailBcc: process.env.REACT_APP_EMAIL_BCC,
  },
  drawer: {
    admin: {
      width: 280,
    },
    dashboard: {
      width: 90,
      fullWidth: 90 * 3,
    },
  },
  googleRecaptcha: {
    key: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
  },
};

export default config;
