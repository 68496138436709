import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from 'components/Dialog';
import Dropdown from 'components/Dropdown';
import { useStores } from 'components/StateProvider';

import useFetchApi from 'hooks/useFetchApi';
import useDashboardLoading from 'hooks/useDashboardLoading';
import useCashFundRedemption from 'hooks/useCashFundRedemption';
import history from 'utils/history';
import condTrueFalse from 'utils/condTrueFalse';

import EmptyInvestContainer from '../EmptyInvestContainer';
import ContentContainer from '../ContentContainer';
import PortfolioSummaryCard from './PortfolioSummaryCard';
import PortfolioOverviewChart from './PortfolioOverviewChart';
import {
  PortfolioFundList,
  fetchPortfolioOverview,
  fetchPortfolioPayments,
  getAccordionContent,
  getFundInvestments,
} from './HelperFunction';

const PortfolioTab = () => {
  const {
    auth: { user },
  } = useStores();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [activeInvestment, setActiveInvestment] = useState('');
  const [selectedInvestmentEntity, setSelectedInvestmentEntity] = useState('');

  const {
    FundRedemptionDialogComponent,
    RedemptionConfirmationDialogComponent,
    RedemptionLoadingComponent,
    RedemptionSuccessDialogComponent,
  } = useCashFundRedemption([]);
  const { isFetched, handleSetIsLoading, handleSetIsFetched } =
    useDashboardLoading();

  useEffect(
    () => condTrueFalse(isFetched, () => handleSetIsFetched(false)),
    [],
  );

  const getPortfolioPayments = useMemo(() => fetchPortfolioPayments(), []);
  const { apiData: data, isFetchLoading } = useFetchApi({
    url: getPortfolioPayments,
    isFetched,
    setIsFetched: handleSetIsFetched,
    setIsLoading: handleSetIsLoading,
    dependencies: [isFetched],
  });

  const getPortfolioOverview = useMemo(() => fetchPortfolioOverview(), []);
  const { apiData: portfolioOverview } = useFetchApi({
    url: getPortfolioOverview,
  });

  const handleRedirectFunds = useCallback(
    (fundId) => () => {
      const params = condTrueFalse(fundId, `?tab=funds&fundId=${fundId}`, '');
      history.push(`/dashboard${params}`);
    },
    [],
  );

  const handleClickViewDetail = useCallback((investment) => {
    setActiveInvestment(investment);
  }, []);

  const handleCloseDetailInvestmentDialog = useCallback(
    () => setActiveInvestment(''),
    [],
  );

  const userInvestmentEntitiesOptions = useMemo(() => {
    const userEntityOptions = [{ label: 'All Entity', value: '' }];

    if (!data.length) return userEntityOptions;

    const uniqueUserEntities = new Map();

    data.forEach((userPortfolio) => {
      if (!uniqueUserEntities.get(userPortfolio.portfolio.userInvestorMapFk)) {
        userEntityOptions.push({
          label: userPortfolio.name,
          value: userPortfolio.portfolio.userInvestorMapFk,
        });
      }

      uniqueUserEntities.set(
        userPortfolio.portfolio.userInvestorMapFk,
        userPortfolio.name,
      );
    });

    return userEntityOptions;
  }, [data]);

  const handleChangeInvestmentEntity = useCallback((e) => {
    setSelectedInvestmentEntity(e.target.value);
  }, []);

  const fundInvestments = useMemo(
    () => getFundInvestments({ data, selectedInvestmentEntity }),
    [data, selectedInvestmentEntity],
  );

  if (isFetchLoading) return <div data-testid="tab-item-portfolio" />;
  if (!data?.length)
    return (
      <ContentContainer data-testid="empty-portfolio-container">
        <EmptyInvestContainer
          onClickFunds={handleRedirectFunds()}
          testId="tab-item-portfolio"
        />
      </ContentContainer>
    );

  return (
    <Grid item data-testid="tab-item-portfolio" xs>
      <Grid container item xs={12} rowSpacing={5} direction="row">
        <Grid container item xs={12} alignItems="center">
          <Grid item>
            <Typography variant="small" fontWeight="700 !important">
              Overview
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider flexItem variant="middle" sx={{ mr: 0 }} />
          </Grid>
          <Grid
            item
            xs={12}
            p={{ xs: 0, lg: '0px 10% 0px 5%', xl: '0px 15% 0px 5%' }}
          >
            <PortfolioOverviewChart portfolioOverview={portfolioOverview} />
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center" columnSpacing={2}>
          <Grid item>
            <Typography variant="small" fontWeight="700 !important">
              Investments
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider flexItem variant="middle" sx={{ mr: 0 }} />
          </Grid>
          <Grid item xs={2}>
            <Dropdown
              name="selectedInvestmentEntity"
              value={selectedInvestmentEntity}
              onChange={handleChangeInvestmentEntity}
              placeholder="All Entity"
              options={userInvestmentEntitiesOptions}
              maxheight="44px"
            />
          </Grid>
        </Grid>

        <PortfolioFundList
          funds={fundInvestments.cashFunds}
          isMobileScreen={isMobileScreen}
          onClickViewDetail={handleClickViewDetail}
        />

        <PortfolioFundList
          funds={fundInvestments.funds}
          isMobileScreen={isMobileScreen}
          onClickViewDetail={handleClickViewDetail}
        />
      </Grid>

      <Dialog
        open={!!activeInvestment}
        content={getAccordionContent({ investment: activeInvestment })}
        onClose={handleCloseDetailInvestmentDialog}
      />

      <FundRedemptionDialogComponent />

      <RedemptionConfirmationDialogComponent />

      <RedemptionSuccessDialogComponent />

      <RedemptionLoadingComponent />
    </Grid>
  );
};

export default memo(PortfolioTab);
