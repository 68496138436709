import React, { memo, useCallback, useEffect, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import { Divider, Typography } from '@mui/material';

import ShowMe from 'components/ShowMe';
import { useStores } from 'components/StateProvider';

import condTrueFalse from 'utils/condTrueFalse';
import history from 'utils/history';
import useFetchApi from 'hooks/useFetchApi';
import useDashboardLoading from 'hooks/useDashboardLoading';

import HelperFunction from '../../HelperFunction';
import FeaturedForMembers from './FeaturedForMembers';
import UpcomingEvents from './UpcomingEvents';
import MoreArticles from './MoreArticles';
import PortfolioSummaryCard from '../PortfolioTab/PortfolioSummaryCard';
import FeaturedVideos from './FeaturedVideos';
import CompanyUpdates from './CompanyUpdates';

const { fetchAllFunds } = HelperFunction;

const handleRouteToPortfolio = () => history.push('/dashboard/portfolio');

const handleRouteToInvestFund = (fundId) => history.push(`/invest/${fundId}`);

const InsightCommunityTab = ({ handleViewFundDetail, mode = 'portrait' }) => {
  const {
    auth: { user },
  } = useStores();
  const { isFetched, handleSetIsLoading, handleSetIsFetched } =
    useDashboardLoading();

  useEffect(
    () => condTrueFalse(isFetched, () => handleSetIsFetched(false)),
    [],
  );

  const getFunds = useMemo(() => fetchAllFunds(), []);
  const { apiData: fundsData, isFetchLoading } = useFetchApi({
    url: getFunds,
    isFetched,
    setIsFetched: handleSetIsFetched,
    setIsLoading: handleSetIsLoading,
    dependencies: [isFetched],
  });

  const cashFund = useMemo(
    () =>
      fundsData.find(
        (fund) => fund.isOpen && typeof fund.overview === 'string',
      ) || {},
    [fundsData],
  );

  const PortfolioSummaryList = useCallback(
    () => (
      <Grid container item columnSpacing={[3, 5]}>
        <Grid item xs={6}>
          <PortfolioSummaryCard
            title="My Portfolio Value"
            value={user.portfolioInvestmentTotal}
            btnLabel="View"
            btnOnClick={handleRouteToPortfolio}
          />
        </Grid>
        <Grid item xs={6}>
          <PortfolioSummaryCard
            title="Treassury Fund"
            value={user.cashFundInvestmentTotal}
            btnLabel="Deposit"
            btnOnClick={() => handleRouteToInvestFund(cashFund.id)}
            btnDisabled={!cashFund.id}
          />
        </Grid>
      </Grid>
    ),
    [user, cashFund],
  );

  if (isFetchLoading) return null;

  return (
    <Grid
      data-testid="tab-item-insight-community"
      container
      spacing={3}
      rowSpacing={4}
    >
      <ShowMe when={process.env.REACT_APP_ENV === 'dev'}>
        <Grid item xs={12}>
          <PortfolioSummaryList />
        </Grid>
      </ShowMe>

      <Grid container item xs={12} alignItems="center">
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            Featured for Members
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider flexItem variant="middle" sx={{ mr: 0 }} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FeaturedForMembers />
      </Grid>

      <Grid container item xs={12} rowSpacing={3}>
        <Grid container item xs={12} alignItems="center">
          <Grid item>
            <Typography variant="small" fontWeight="700 !important">
              Featured Videos
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider flexItem variant="middle" sx={{ mr: 0 }} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FeaturedVideos />
        </Grid>
      </Grid>

      <Grid container item xs={12} alignItems="center">
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            Upcoming Events
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider flexItem variant="middle" sx={{ mr: 0 }} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <UpcomingEvents />
      </Grid>

      <Grid container item xs={12} alignItems="center">
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            Company Updates
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider flexItem variant="middle" sx={{ mr: 0 }} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CompanyUpdates />
      </Grid>

      <Grid container item xs={12} alignItems="center">
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            More articles
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider flexItem variant="middle" sx={{ mr: 0 }} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MoreArticles />
      </Grid>
    </Grid>
  );
};

export default memo(InsightCommunityTab);
