import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import ShowMe from 'components/ShowMe';

import checkList from 'assets/icons/check-list.svg';

import Dialog from '.';

const LoadingProgressDialog = ({
  subtitle = 'Submitting',
  icon = checkList,
  contentText,
  dialogContentDependencies = [],
  withLoadingProgress = true,
  subtitleProps = {},
  ...props
}) => {
  const DialogContent = useMemo(
    () => (
      <Grid
        container
        rowSpacing={2}
        direction="column"
        textAlign="center"
        px={{ xs: 0, sm: 4 }}
        minWidth={300}
      >
        <Grid
          container
          item
          display="flex"
          alignItems="center"
          columnSpacing={2}
        >
          <ShowMe when={!!icon}>
            <Grid item>
              <img src={icon} alt="icon" width="30px" height="100%" />
            </Grid>
          </ShowMe>
          <Grid item>
            <Typography variant="display3" {...subtitleProps}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
        {withLoadingProgress && (
          <Grid item xs={12}>
            <LinearProgress color="success" />
          </Grid>
        )}
      </Grid>
    ),
    [subtitle, ...dialogContentDependencies],
  );

  return <Dialog content={DialogContent} maxWidth="xs" {...props} />;
};

export default LoadingProgressDialog;
