import React, { memo } from 'react';

import AccordionComponent from 'components/Accordion';

import palette from 'theme/palette';

const Accordion = ({ index = 0, tabValue, ...props }) => (
  <AccordionComponent
    toggleOnIcon
    withColapsibleFooter={false}
    headerBackground="white"
    label={`Summary ${index + 1}`}
    sx={{
      border: `1px solid ${palette.reach.purpleHaze}`,
    }}
    {...props}
  />
);

export default memo(Accordion);
