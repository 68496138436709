import React, { useMemo, useState, useCallback, useEffect } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Link from 'components/Link';
import FundCardHeader from 'components/FundCard/components/Header/FundCardHeader';
import TabPanels from 'components/TabPanels';
import Tabs from 'components/Tabs';
import AddInvestmentDialog from 'components/Dialog/AddInvestmentDialog';
import { useActions, useStores } from 'components/StateProvider';

import condTrueFalse from 'utils/condTrueFalse';
import history from 'utils/history';
import lazyLoad from 'utils/lazyLoad';
import { generateMailTo } from 'utils/email';

import useDashboardLoading from 'hooks/useDashboardLoading';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import { HEADER_DEFAULT_BG_COLOR } from 'constants/funds';
import { fundPageIcons } from 'constants/Icons';
import { REACH_ADMIN_EMAIL } from 'constants/general';

import { PAYMENT_STATUS } from 'constants/investmentEntity';
import { ReactComponent as DataRoomIcon } from 'assets/icons/data-room.svg';
import { ReactComponent as FundTeaserIcon } from 'assets/icons/fund.svg';

import palette from 'theme/palette';

import FundDetailTabComponents from './TabComponents';
import DataRoomList from './DataRoomList';
import HelperFunction from '../../HelperFunction';

const FundDetailSection = lazyLoad(() => import('./FundDetailSection'));

const { fetchFundDetail, fetchFundDataRooms } = HelperFunction;

const mailHref = generateMailTo({
  email: REACH_ADMIN_EMAIL,
  subject: '',
});

const headerLogoProps = {
  style: {
    width: '106px',
    height: '100px',
  },
};

const handleRouteToRedemptionRequestPage = () =>
  history.push('/redemption-request');

const FundDetail = ({
  fundId,
  handleBack,
  handleRedirect,
  onOpenCompleteProfileDialog,
}) => {
  const { handleUpdateUserViewFundStatus } = useActions();
  const { isLoading, isFetched, handleSetIsLoading, handleSetIsFetched } =
    useDashboardLoading();
  const [activeTabIdx, setActiveTabIdx] = useState(0);

  const {
    auth: {
      user: { isVerified, hasViewFundDetail },
    },
  } = useStores();
  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  const getFundDetail = useMemo(() => fetchFundDetail(fundId), [fundId]);
  const getFundDataRooms = useCallback(
    () => (isVerified ? fetchFundDataRooms(fundId) : Promise.resolve([])),
    [fundId, isVerified],
  );

  const {
    apiData: [fundDetailData, fundDataRoomsData],
    handleRefetch,
  } = useFetchApi({
    url: getFundDetail,
    fetchService: getFundDataRooms,
    setIsFetched: handleSetIsFetched,
    setIsLoading: handleSetIsLoading,
  });

  useEffect(() => {
    if (isFetched) handleRefetch();
  }, [isVerified]);

  useEffect(() => {
    if (isVerified && !hasViewFundDetail) handleUpdateUserViewFundStatus();
  }, [isVerified, hasViewFundDetail]);

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const isAbleToInvest = useMemo(
    () => isVerified && fundDetailData?.fundInformation?.isOpen,
    [isVerified && fundDetailData?.fundInformation?.isOpen],
  );

  const handleClickInvest = useCallback(() => {
    condTrueFalse(
      !isAbleToInvest,
      () => handleOpenDialog(),
      () => handleRedirect(`/invest/${fundId}`, handleCloseDialog),
    );
  }, [fundId, isAbleToInvest]);

  const isFundInvested = useMemo(
    () => fundDetailData?.isInvested,
    [fundDetailData?.isInvested],
  );

  const isFundPaymentCompleted = useMemo(
    () =>
      fundDetailData?.portfolio &&
      fundDetailData.portfolio.status.toLowerCase() ===
        PAYMENT_STATUS.PAYMENT_COMPLETED.toLowerCase(),
    [fundDetailData?.portfolio],
  );

  const isRedemptionVisible = useMemo(
    () => !!fundDetailData?.fundInformation?.isRedemptionVisible,
    [fundDetailData?.fundInformation?.isRedemptionVisible],
  );

  const handleClickRedeem = useCallback(() => {
    condTrueFalse(
      isFundPaymentCompleted && isRedemptionVisible,
      handleRouteToRedemptionRequestPage,
      null,
    );
  }, [fundDetailData?.portfolio, isFundPaymentCompleted, isRedemptionVisible]);

  const tabItems = useMemo(() => {
    const fundContentItems = () =>
      fundDetailData?.fundDetail?.fundTabsWithContent?.reduce(
        (fundContents, fundContent, index) =>
          fundContents.concat({
            key: fundContent.id,
            label: <Typography variant="h6">{fundContent.title}</Typography>,
            Icon: fundPageIcons[fundContent?.icon] || FundTeaserIcon,
            disabled:
              (fundContent.isLocked && !isVerified) ||
              (!fundDetailData?.fundInformation?.isOpen &&
                index &&
                !isFundInvested),
            content: (
              <FundDetailSection
                fundDetailData={{
                  ...fundDetailData,
                  fundContents: fundContent.fundContents,
                }}
                fundDetailId={fundContent.id}
                handleBack={handleBack}
                tabIndex={index}
                onClickInvest={handleClickInvest}
                investBtnDisabled={!isAbleToInvest}
                onClickRedeem={
                  isFundPaymentCompleted &&
                  isRedemptionVisible &&
                  handleClickRedeem
                }
              />
            ),
          }),
        [],
      ) || [];

    return [
      ...fundContentItems(),
      {
        key: 'dataRoom',
        label: <Typography variant="h6">Data Room</Typography>,
        Icon: DataRoomIcon,
        content: <DataRoomList />,
        disabled:
          !isVerified ||
          (!fundDetailData?.fundInformation?.isOpen && !isFundInvested),
      },
    ];
  }, [
    isVerified,
    fundDetailData,
    handleClickInvest,
    isFundInvested,
    isAbleToInvest,
    handleClickRedeem,
  ]);

  const handleChangeTab = useCallback(
    (_, tabIndex) => {
      if (tabItems[tabIndex].disabled) {
        handleOpenDialog();
        return;
      }
      setActiveTabIdx(tabIndex);
    },
    [tabItems],
  );

  const { dialogContent, confirmButtonLabel, onConfirm } = useMemo(() => {
    if (!isVerified)
      return {
        confirmButtonLabel: 'Complete Profile',
        onConfirm: () => {
          handleCloseDialog();
          onOpenCompleteProfileDialog();
        },
        dialogContent: (
          <>
            To view additional details of the{' '}
            <b>{fundDetailData?.fundInformation?.fundName?.trim()}</b>, you need
            to first complete your profile.
          </>
        ),
      };

    return {
      dialogContent: (
        <>
          Unfortunately, the raise period for this fund has closed.
          <br />
          <br />
          If you’d like to be shown similar funds, or if you’d like to know when
          an opportunity to buy into this fund may arise, please contact us at
          <Link href={mailHref} target="_blank">
            {' '}
            contact@reachalts.com.au.
          </Link>
        </>
      ),
    };
  }, [
    isVerified,
    fundDetailData?.fundInformation?.fundName,
    onOpenCompleteProfileDialog,
  ]);

  if (isLoading) return null;

  return (
    <Grid container rowSpacing={{ xs: 0, sm: 2 }} columnSpacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          padding: 0,
          marginTop: { xs: 0, sm: 0 },
        }}
      >
        <FundCardHeader
          logoSrc={
            !isSM && fundDetailData?.fundInformation?.imageAndDocument?.logo
          }
          headerBgColor={fundDetailData?.headerColor || HEADER_DEFAULT_BG_COLOR}
          headerImageSrc={
            fundDetailData?.fundInformation?.imageAndDocument?.header
          }
          width="100%"
          isFundOpen={fundDetailData?.fundInformation?.isOpen}
          headerImageProps={headerLogoProps}
          headerTitle={fundDetailData?.fundInformation?.fundName}
          headerDesc={fundDetailData?.fundInformation?.summary}
          onClickInvest={handleClickInvest}
          onClickPortfolio={condTrueFalse(
            isFundInvested,
            () => () => handleRedirect('/dashboard/portfolio', null),
          )}
          onClickRedeem={condTrueFalse(
            isFundPaymentCompleted && isRedemptionVisible,
            handleClickRedeem,
            undefined,
            { executeFn: false },
          )}
          investBtnDisabled={!isAbleToInvest}
          investBtnProps={{
            buttonstyles: condTrueFalse(
              !isVerified,
              {
                color: palette.text.disabled,
                background: palette.reach.disabledGreyDark,
              },
              {},
            ),
          }}
          useHeaderBgWrapper
        />
        <Box
          sx={{
            borderTop: '1px solid #BDC3CD',
            borderColor: 'divider',
            background: '#FFFFFF',
            mt: { xs: 4, sm: 0 },
          }}
        >
          <Tabs
            value={activeTabIdx}
            onChange={handleChangeTab}
            centered={false}
            sx={{ height: '44px', minHeight: '44px' }}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            variant="scrollable"
          >
            <FundDetailTabComponents
              activeTabIdx={activeTabIdx}
              tabItems={tabItems}
              dependencyKey={isVerified}
            />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TabPanels
          value={activeTabIdx}
          fundTeaserProps={{ isSM, fundDetailData, handleBack }}
          dataRoomProps={{ fundDataRoomsData }}
          tabItems={tabItems}
          boxPanelProps={{ sx: { p: 0 } }}
          dependencyKey={isVerified}
        />
      </Grid>
      <AddInvestmentDialog
        isDialogOpen={isDialogOpen}
        contentText={dialogContent}
        dialogContentProps={{
          style: {
            minHeight: 'auto',
            maxHeight: 'auto',
          },
        }}
        onClose={handleCloseDialog}
        showCancelBtn={false}
        confirmButtonLabel={confirmButtonLabel}
        onConfirm={onConfirm}
      />
    </Grid>
  );
};

export default FundDetail;
