import React, { memo, useCallback, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';

import ShowMe from 'components/ShowMe';

import { INVESTMENT_ENTITY_STATUSES } from 'constants/investmentEntity';

import useInvestmentEntityDropdown from 'hooks/useInvestmentEntityDropdown';
import HelperFunction from './HelperFunction';

const descriptions = [
  'We’re excited to help you get started.',
  'If you haven’t already, the first step is to add an investment entity. This is the legal owner of the investment. It can be you, personally, or you can invest through a family trust, SMSF, company etc. You can add as many investment entities as suits your needs.',
  'If you haven’t added an investment entity yet, simply click on the link to add one.  If you have, select it from the dropdown box and click next. You do not need to wait for the entity to be ‘approved’, you can go on to finish the investment process even if the entity status remains as ‘received’.',
];

const SelectEntityForm = ({ setValues, values }) => {
  const handleGoToAddInvestmentEntityPage = useCallback(() => {
    history.replace('/add-investment-entity', {
      href: `/invest/${values.fundId}`,
    });
  }, [values?.fundId]);

  const getInvestmentEntities = useMemo(
    () => HelperFunction.fetchInvestmentEntityListUser(),
    [],
  );

  const { apiData: investmentEntities, isFetchLoading } = useFetchApi({
    url: getInvestmentEntities,
  });

  const { InvestmentEntityDropdownComponent } = useInvestmentEntityDropdown({
    investmentEntities,
    onClickAddInvestmentEntity: handleGoToAddInvestmentEntityPage,
  });

  const investmentEntityOptions = useMemo(() => {
    if (!investmentEntities.length)
      return [
        {
          label: (
            <Typography variant="bodyGreySmall" fontFamily="PT Serif">
              It looks like you are yet to add an investment entity:
              <Typography
                variant="verificationLink"
                onClick={handleGoToAddInvestmentEntityPage}
              >
                {' '}
                Add investment entity
              </Typography>
            </Typography>
          ),
        },
      ];

    const entitiesOptions = investmentEntities.map(
      (investmentEntity, index) => ({
        ...investmentEntity,
        label: investmentEntity.name,
        // Should change this to id later on new Endpoint.
        value: `${index}-${investmentEntity.name}`,
        customLabel: HelperFunction.getCustomOptionLabel(investmentEntity),
        disabled:
          investmentEntity.status === INVESTMENT_ENTITY_STATUSES.CANCELED,
      }),
    );
    return entitiesOptions;
  }, [investmentEntities]);

  const handleChangeInvestmentEntity = useCallback(
    (e, originalValue) => {
      const { name, country, status, investmentType, userInvestorMapId } =
        originalValue;

      setValues({
        ...values,
        entityDetail: {
          name,
          country,
          status,
          investmentType,
          userInvestorMapId,
        },
        investmentEntity: e.target.value,
      });
    },
    [investmentEntityOptions],
  );

  if (isFetchLoading) {
    return (
      <Grid item xs={12} textAlign="center" alignSelf="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container item rowSpacing={4}>
      <Grid item>
        <Typography variant="h1">
          Congratulations on making the decision to invest!
        </Typography>
      </Grid>
      <Grid item>
        {descriptions.map((description) => (
          <Typography key={description} variant="body">
            {description}
            <br />
            <br />
          </Typography>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body">
          Please select your Investment Entity that will invest:
        </Typography>
        <InvestmentEntityDropdownComponent
          autoFocus
          data-testid="investmentEntity-dropdown"
          name="investmentEntity"
          placeholder="Select your entity"
          autoWidth={false}
          onChange={handleChangeInvestmentEntity}
          value={values.investmentEntity}
        />
        <ShowMe when={investmentEntities.length < 10}>
          <Grid xs={12} textAlign="right" mt={1}>
            <Typography variant="bodyGreySmall" fontFamily="PT Serif">
              Haven&apos;t set up an investment entity yet?
              <Typography
                variant="verificationLink"
                sx={{
                  cursor: 'pointer',
                  display: { xs: 'block', sm: 'initial' },
                }}
                onClick={handleGoToAddInvestmentEntityPage}
              >
                {' '}
                Add investment entity
              </Typography>
            </Typography>
          </Grid>
        </ShowMe>
      </Grid>
    </Grid>
  );
};

export default memo(SelectEntityForm);
