import companyDetailsValidationSchemas from './company/companyDetailsValidationSchema';
import addressValidationSchemas from './addressValidationSchema';
import accountantCertificateValidationSchema from './accountantCertificateValidationSchema';

const trusteesCompanyValidationSchemas = [
  companyDetailsValidationSchemas,
  addressValidationSchemas,
  accountantCertificateValidationSchema,
  null,
];
export default trusteesCompanyValidationSchemas;
