import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ShowMe from 'components/ShowMe';

import InvestmentEntityContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityContainer';

import getNumbers from 'utils/strings/getNumbers';

import InvestmentAmountForm from './InvestmentAmountForm';
import SelectClassInput from './SelectClassInput';

const InvestmentAmount = ({
  onNext,
  onBack,
  onChange,
  values,
  fundConfiguration,
  fundInformationClasses = [],
  errors,
  touched,
  onBlur,
}) => (
  <div style={{ marginTop: '20px' }}>
    <InvestmentEntityContainer
      onNext={onNext}
      onBack={onBack}
      isNextButtonDisabled={!getNumbers(values.investmentAmount)}
      pt={8}
      withHeader={false}
      nextButtonLabel="Confirm"
    >
      <Grid
        container
        justifyContent="center"
        direction="column"
        rowSpacing={[2, 4]}
        textAlign={['left', 'center']}
        xs={12}
      >
        <Grid item>
          <Typography variant="h1" mb={[4, 0]}>
            Subscription
          </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <SelectClassInput
            onChange={onChange}
            value={values.financialInformationClass}
            classes={fundInformationClasses}
          />
        </Grid>
        <ShowMe when={!!fundConfiguration}>
          <Grid item>
            {/* Amount Calculation Form */}
            <InvestmentAmountForm
              onChange={onChange}
              values={values}
              fundConfiguration={fundConfiguration}
              errors={errors}
              touched={touched}
              onBlur={onBlur}
            />
          </Grid>
        </ShowMe>
      </Grid>
    </InvestmentEntityContainer>
  </div>
);

export default memo(InvestmentAmount);
