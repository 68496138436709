import React from 'react';

import DataTable from 'components/DataTable';
import TotalHeaders from 'components/TotalHeaders';

import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';
import Services from 'api/services';

import ResponseMappers from 'utils/ResponseMappers';

import {
  getFundRedemptionRequestTotalHeader,
  fundRedemptionRequestHeaders,
} from './HelperFunction';

const FundRedemptionRequest = ({ onRowClick }) => {
  const { haveCrudPermission } = useAdminRestriction();
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: Services.adminGetPaginatedFundRedemptionRequests,
    defaultApiData: {},
    mapApiData:
      ResponseMappers.admin.fundRedemptionRequest
        .paginatedFundRedemptionRequest,
  });

  return (
    <div data-testid="fund-redemption-request-container">
      <TotalHeaders
        totals={getFundRedemptionRequestTotalHeader(apiData.totalItems)}
        // onClickExport={handleExport}
        disableExportBtn={!haveCrudPermission}
      />
      <DataTable
        height="656px"
        headerData={fundRedemptionRequestHeaders}
        data={apiData.items}
        setSort={setSort}
        isLoading={isFetchLoading}
        totalData={apiData.totalItems}
        onRowClick={onRowClick}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </div>
  );
};

export default FundRedemptionRequest;
