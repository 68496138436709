import React from 'react';
import SummarySection from 'components/SummarySection';

import { FUND_REDEMPTION_REQUEST_DETAILS_SUMMARY_FIELD } from 'constants/fundRedemptionRequest';

const FundRedemptionRequestDetailsSummary = ({
  title,
  values,
  setValues,
  withEdit = true,
  sectionListView = false,
  onClickEdit,
}) => (
  <SummarySection
    title={title}
    fields={FUND_REDEMPTION_REQUEST_DETAILS_SUMMARY_FIELD}
    values={values}
    setValues={setValues}
    withEdit={withEdit}
    sectionListView={sectionListView}
    onClickEdit={onClickEdit}
  />
);

export default FundRedemptionRequestDetailsSummary;
