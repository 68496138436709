import React from 'react';

import Grid from '@mui/material/Grid';

import ReportingSection from './ReportingSection';

const Reporting = () => (
  <Grid container rowSpacing={3}>
    <Grid item xs={12}>
      <ReportingSection />
    </Grid>
  </Grid>
);

export default Reporting;
