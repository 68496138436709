import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';
import AutoPopulateChipList from 'components/AutoPopulateChipList';
import ShowMe from 'components/ShowMe';
import LoadingSpinner from 'components/LoadingSpinner';
import { useStores } from 'components/StateProvider';

import useSteps from 'hooks/useSteps';
import lazyLoad from 'utils/lazyLoad';
import getSelectedCountry from 'utils/getSelectedCountry';
import InitialValues from 'constants/InitialValues';

import individualPersonalDetailsValidations from 'validations/schema/addInvestment/individualPersonalDetailsValidationSchemas';

const PersonalDetailsForm = lazyLoad(() =>
  import('../../../components/Forms/PersonalDetailsForm'),
);
const AddressForm = lazyLoad(() =>
  import('../../../components/Forms/AddressForm'),
);
const InvestorIdentificationForm = lazyLoad(() =>
  import('../../../components/Forms/InvestorIdentificationForm'),
);
const AccountantCertificateForm = lazyLoad(() =>
  import('../../../components/Forms/AccountantCertificateForm'),
);
const JointInvestorForm = lazyLoad(() =>
  import('../../../components/Forms/JointInvestorForm'),
);
const SummaryForm = lazyLoad(() =>
  import('../../../components/Forms/SummaryForm'),
);

const stepComponents = [
  <PersonalDetailsForm />,
  <AddressForm />,
  <InvestorIdentificationForm />,
  <AccountantCertificateForm />,
  <JointInvestorForm />,
  <SummaryForm />,
];
const stepTitles = [
  'Personal Details',
  'Address',
  'Identification of Investor',
  'Accountant’s Certificate',
  <span>
    Joint Investor{' '}
    <Typography variant="smallGrey" fontStyle="italic">
      (Optional)
    </Typography>
  </span>,
  'Summary',
];

const PersonalDetails = ({
  onNext,
  onBack,
  startAtLastStep,
  steps,
  setValues,
  values,
  userDetailList,
}) => {
  const [isSetupJoint, setIsSetupJoint] = useState(false);
  const [tempFormValues, setTempFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedPersonalDetailIndex, setSelectedPersonalDetailIndex] =
    useState({ personals: '', addresses: '' });
  const {
    auth: {
      user: { email, firstName, middleName, lastName, parsedMobileNumber },
    },
  } = useStores();

  const handleNextLastPage = ({ values: personalDetailsValues }) => {
    if (setValues)
      setValues({ ...values, personalDetails: personalDetailsValues });
    onNext();
  };

  const handleBackFirstPage = useCallback(
    ({ handleSetPageIndex, setFormValues }) => {
      if (!isSetupJoint) {
        onBack();

        return;
      }

      // Back into Joint Investor Step.
      handleSetPageIndex(stepComponents.length - 2);
      setIsSetupJoint(false);

      // Set Form's Value to previouse Form Values,
      // if user go back from setting up joint investor.
      setFormValues(tempFormValues);
    },
    [isSetupJoint, tempFormValues],
  );

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: personalDetailsForm,
    setPageIndex,
  } = useSteps({
    stepComponents,
    validateFormik: true,
    validationSchemas: individualPersonalDetailsValidations,
    formParameters: {
      initialValues: {
        ...InitialValues.personalDetail,
        email,
        firstName,
        middleName: middleName || '',
        lastName,
        phoneNumber: parsedMobileNumber?.number,
        countryCode: getSelectedCountry(parsedMobileNumber?.code),
        // personalDetails contains all value from first step until the last step of Personal Details
        ...values?.personalDetails,
      },
    },
    onNextInLastPage: handleNextLastPage,
    onBackInFirstPage: handleBackFirstPage,
    defaultPageIndex: startAtLastStep ? stepComponents.length - 1 : 0,
  });

  useEffect(() => {
    personalDetailsForm?.validateForm();
  }, [pageIndex, personalDetailsForm?.validateForm]);

  const handleActivateSetupJoint = useCallback(() => {
    // Save normal individual investor's value.
    setTempFormValues(personalDetailsForm.values);

    // Reset form for Joint Investor.
    personalDetailsForm.resetForm({
      values: personalDetailsForm.values.joint || InitialValues.personalDetail,
    });

    // Return to first step.
    setPageIndex(0);

    setIsSetupJoint(true);
  }, [personalDetailsForm.values]);

  const handleDeactivateSetupJoint = useCallback(() => {
    if (!isSetupJoint) return;

    setIsSetupJoint(false);

    // Replace `joint` value with the new value.
    const newFormValues = {
      ...tempFormValues,
      joint: personalDetailsForm.values,
    };
    personalDetailsForm.setValues(newFormValues);
  }, [personalDetailsForm.values]);

  const handleDeleteJoint = useCallback(() => {
    personalDetailsForm.setFieldValue('joint', null);
  }, []);

  const handleSelectAutoPopulateUser = ({ index, recordedData, stepKey }) => {
    setLoading(true);
    personalDetailsForm.setValues({
      ...personalDetailsForm.values,
      ...recordedData[index],
    });

    setSelectedPersonalDetailIndex((prevSelected) => ({
      ...prevSelected,
      [stepKey]: index,
    }));

    // Delay loading to reset form component.
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const headChildren = useMemo(() => {
    const { recordedData, stepKey } = (() => {
      switch (true) {
        case !pageIndex && isSetupJoint:
          return {
            recordedData: userDetailList.personals,
            stepKey: 'personals',
          };
        case pageIndex === 1:
          return {
            recordedData: userDetailList.addresses,
            stepKey: 'addresses',
          };
        default:
          return { recordedData: null };
      }
    })();

    return (
      <ShowMe when={recordedData}>
        <AutoPopulateChipList
          items={recordedData}
          onClick={(index) =>
            handleSelectAutoPopulateUser({ index, recordedData, stepKey })
          }
          selectedIndex={selectedPersonalDetailIndex[stepKey]}
        />
      </ShowMe>
    );
  }, [selectedPersonalDetailIndex, userDetailList, pageIndex, isSetupJoint]);

  return (
    <InvestmentEntityStepContainer
      containerProps={{
        onBack: handleBack,
        onNext: handleNext,
        isNextButtonDisabled: !!Object.keys(personalDetailsForm.errors).length,
      }}
      steps={steps}
      activeStep={0}
      stepTitle={stepTitles[pageIndex]}
      isJoint={isSetupJoint}
      headChildren={headChildren}
    >
      {loading ? (
        <LoadingSpinner loading />
      ) : (
        React.cloneElement(stepComponents[pageIndex], {
          errors: personalDetailsForm.errors,
          values: personalDetailsForm.values,
          touched: personalDetailsForm.touched,
          onNext: handleNext,
          onBack: handleBack,
          onChange: personalDetailsForm.handleChange,
          onBlur: personalDetailsForm.handleBlur,
          setFieldError: personalDetailsForm.setFieldError,
          setFieldValue: personalDetailsForm.setFieldValue,
          setValues: personalDetailsForm.setValues,
          handleActivateSetupJoint,
          handleDeactivateSetupJoint,
          isJoint: isSetupJoint,
          onDeleteJoint: handleDeleteJoint,
        })
      )}
    </InvestmentEntityStepContainer>
  );
};

export default memo(PersonalDetails);
