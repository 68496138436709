import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import palette from 'theme/palette';

import ConfirmationDialog from './ConfirmationDialog';

const AddInvestmentDialog = ({
  contentText,
  _handleRedirectInvestmentEntityList,
  isDialogOpen,
  confirmButtonProps,
  ...props
}) => (
  <ConfirmationDialog
    dialogActionsProps={{
      style: { padding: '20px 0' },
    }}
    dialogContentProps={{
      style: {
        minHeight: '527.3px',
        maxHeight: '527.3px',
      },
    }}
    PaperProps={{
      sx: {
        maxWidth: '600px',
      },
    }}
    confirmButtonContainerProps={{
      xs: 12,
      md: 12,
    }}
    confirmButtonProps={{
      buttoncolor: 'purpleGradient',
      ...confirmButtonProps,
    }}
    confirmButtonLabel="Add Investment Entity"
    open={isDialogOpen}
    contentText={
      <Grid container>
        <Grid item xs={12}>
          <Icon style={{ width: '6.5em', height: '1.75em' }}>
            <img
              alt="logo-company"
              src="/assets/images/Reach-logo.png"
              height="100%"
              width="100%"
            />
          </Icon>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '70px' }}>
          <LockOutlinedIcon
            sx={{
              width: '104px',
              height: '104px',
              color: palette.reach.inactiveGrey,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '55px' }}>
          <Typography>
            {contentText}
            {/* <TextButton
              variant="buttonText"
              color={palette.reach.link}
              onClick={handleRedirectInvestmentEntityList}
            >
              investment entity status
            </TextButton> */}
          </Typography>
        </Grid>
      </Grid>
    }
    infoText={null}
    {...props}
  />
);

export default memo(AddInvestmentDialog);
