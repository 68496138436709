import React from 'react';

import SummarySection from 'components/SummarySection';
import {
  SUMMARY_FIELDS_PERSONAL_DETAILS,
  SUMMARY_FIELDS_ADDRESS,
  SUMMARY_FIELDS_ADDRESS_VIEW,
  SUMMARY_FIELDS_IDENTIFICATION,
  SUMMARY_FIELDS_ACCOUNTANT,
  SUMMARY_FIELDS_TRUSTEE_TYPE,
} from 'constants/investmentEntity';

import trusteesIndividualValidations from 'validations/schema/addInvestment/trusteesIndividualValidationSchemas';

const IndividualTrusteeSummary = ({ setValues, values }) => (
  <>
    <SummarySection
      title="Trustee Type"
      fields={SUMMARY_FIELDS_TRUSTEE_TYPE}
      setValues={setValues}
      values={values}
      formName="trusteeTypeForm"
      withEdit={false}
    />
    <SummarySection
      title="Trustee Details"
      fields={SUMMARY_FIELDS_PERSONAL_DETAILS(true)}
      setValues={setValues}
      values={values}
      validationSchema={trusteesIndividualValidations[0]}
      formName="trusteeDetailsForm"
    />
    <SummarySection
      title="Address"
      fields={SUMMARY_FIELDS_ADDRESS}
      viewFields={SUMMARY_FIELDS_ADDRESS_VIEW}
      setValues={setValues}
      values={values}
      validationSchema={trusteesIndividualValidations[1]}
      formName="addressForm"
    />
    <SummarySection
      title="Identification of Investor"
      fields={SUMMARY_FIELDS_IDENTIFICATION}
      setValues={setValues}
      values={values}
      validationSchema={trusteesIndividualValidations[2]}
      formName="identificationForm"
    />
    <SummarySection
      title="Accountant's certificate"
      fields={SUMMARY_FIELDS_ACCOUNTANT}
      setValues={setValues}
      values={values}
      validationSchema={trusteesIndividualValidations[3]}
      formName="accountantForm"
    />
  </>
);

export default IndividualTrusteeSummary;
