import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import FormTitle from 'components/FormTitle';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import ShowMe from 'components/ShowMe';

import {
  ID_TYPE_DRIVER_LICENSE,
  ID_TYPE_OPTIONS,
  STATE_OPTIONS,
} from 'constants/investmentEntity';

const identificationInfo =
  'It is important that we comply with all Australian regulation, including those provisions relating to anti-money laundring and counter-financing of terrorism, among other things. Therefore, we require proof of your identity.';

const stateOptions = [...STATE_OPTIONS].slice(0, -1);

const InvestorIdentificationForm = ({
  errors,
  onChange,
  values,
  onBlur,
  touched,
  title,
}) => (
  <>
    <FormTitle title={title} />
    <Grid
      item
      mt={{ xs: 0, md: 2 }}
      xs={12}
      data-testid="entity-investor-identification-form"
    >
      <Typography>{identificationInfo}</Typography>
    </Grid>
    <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
      <FormLabel>ID Type*</FormLabel>
      <Dropdown
        autoFocus
        data-testid="idType-dropdown"
        name="idType"
        placeholder="Select type*"
        onChange={onChange}
        value={values.idType}
        errorValue={touched.idType && errors.idType}
        onBlur={onBlur}
        options={ID_TYPE_OPTIONS}
      />
    </Grid>
    <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
      <FormLabel>ID Number*</FormLabel>
      <Input
        data-testid="idnumber-input"
        id="idNumber"
        name="idNumber"
        placeholder="Number*"
        onChange={onChange}
        value={values.idNumber}
        errorValue={touched.idNumber && errors.idNumber}
        onBlur={onBlur}
      />
    </Grid>
    <ShowMe when={values.idType === ID_TYPE_DRIVER_LICENSE}>
      <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
        <FormLabel>State of Issue*</FormLabel>
        <Dropdown
          data-testid="stateOfIssue-dropdown"
          id="stateOfIssue"
          name="stateOfIssue"
          onChange={onChange}
          value={values.stateOfIssue}
          errorValue={touched.stateOfIssue && errors.stateOfIssue}
          onBlur={onBlur}
          options={stateOptions}
        />
      </Grid>
      <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
        <FormLabel>Card Number*</FormLabel>
        <Input
          data-testid="cardNumber-input"
          id="cardNumber"
          name="cardNumber"
          placeholder="Card Number*"
          onChange={onChange}
          value={values.cardNumber}
          errorValue={touched.cardNumber && errors.cardNumber}
          onBlur={onBlur}
        />
      </Grid>
    </ShowMe>
  </>
);

export default memo(InvestorIdentificationForm);
