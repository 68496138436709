import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import Grid from '@mui/material/Grid';

import ShowMe from 'components/ShowMe';
import WarningIcon from 'components/Icons/Warning';

import isNumber from 'utils/isNumber';
import isMobileDevice from 'utils/isMobileDevice';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import InputBox from './components/InputBox';

const getInputParent = (e) =>
  e.target.parentElement.parentElement.parentElement;

const getInputChild = (e) => e.firstChild.firstChild.firstChild;

const getInputNextSibling = (e) =>
  getInputChild(getInputParent(e).nextElementSibling);

const getInputPrevSibling = (e) =>
  getInputChild(getInputParent(e).previousElementSibling);

const OTPInput = forwardRef(
  (
    {
      numbers = 6,
      onSubmit,
      value,
      testid,
      submitOnChange,
      inputtype,
      withIcon,
      ...props
    },
    ref,
  ) => {
    const [otpValue, setOtpValue] = useState(() => {
      if (value) return value.split('');
      return [];
    });
    const [inputs] = useState(Array.from(Array(numbers).keys()));
    const inputRef = useRef();

    useEffect(() => {
      if (
        typeof onSubmit === 'function' &&
        (submitOnChange || otpValue.filter((otp) => otp).length === numbers)
      ) {
        onSubmit(otpValue.join(''));
      }
    }, [otpValue]);

    const moveFocus = (e) => {
      const next = e.target.tabIndex;
      if (next < 5) {
        getInputNextSibling(e).select();
      }
    };

    const onKeyUp = useCallback((e) => {
      e.preventDefault();
      const { key } = e;

      if (key === 'Backspace' || key === 'ArrowLeft') {
        const next = e.target.tabIndex - 1;
        if (next > -1) {
          getInputPrevSibling(e).select();
        }
      } else if (key === 'ArrowRight') {
        moveFocus(e);
      }
    }, []);

    const handleChange = useCallback(
      (e, index) => {
        const newValue = e.target.value;
        const newOtpValue = [...otpValue];
        newOtpValue[index] = newValue;

        setOtpValue(newOtpValue);

        if (isNumber(newValue)) {
          moveFocus(e);
        }
      },
      [otpValue],
    );

    const onPaste = useCallback(
      (e) => {
        e.preventDefault();
        const activeInput = e.target.tabIndex;

        // Get pastedData in an array of max size.
        const pastedString = e.clipboardData
          .getData('text/plain')
          .slice(0, numbers - activeInput);

        if (!isNumber(pastedString)) return;

        const pastedData = pastedString.split('');
        const newOtpValue = [...otpValue];

        // Populate new OTP Value.
        for (let pos = Number(activeInput); pos < numbers; pos += 1) {
          newOtpValue[pos] = pastedData.shift();
        }

        setOtpValue(newOtpValue);
      },
      [otpValue],
    );

    useImperativeHandle(
      ref,
      () => ({
        handleResetOTP: () => setOtpValue([]),
      }),
      [],
    );

    return (
      <Grid
        ref={inputRef}
        container
        direction="row"
        columnSpacing={1}
        maxWidth={364}
        margin="0 auto"
      >
        <ShowMe when={withIcon && ['success', 'error'].includes(inputtype)}>
          <Grid item xs={1} />
        </ShowMe>
        <Grid container item spacing={1} xs={withIcon && inputtype ? 9.5 : 12}>
          {inputs.map((input, index) => (
            <Grid key={input.toString()} item xs={12 / numbers}>
              <InputBox
                {...props}
                autoFocus={!index}
                data-testid={`${testid}-${index}`}
                type={isMobileDevice() ? 'tel' : 'text'}
                id={index.toString()}
                onChange={handleChange}
                value={otpValue[input]}
                onKeyUp={onKeyUp}
                onPaste={onPaste}
                readOnly={inputtype === 'success'}
                inputtype={inputtype}
              />
            </Grid>
          ))}
        </Grid>
        <ShowMe when={withIcon}>
          <ShowMe when={inputtype === 'success'}>
            <Grid item xs={1}>
              <CheckIcon width="14px" />
            </Grid>
          </ShowMe>
          <ShowMe when={inputtype === 'error'}>
            <Grid item xs={1}>
              <WarningIcon />
            </Grid>
          </ShowMe>
        </ShowMe>
      </Grid>
    );
  },
);

export default OTPInput;
