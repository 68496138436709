import React from 'react';

import MUIButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { styled } from '@mui/material/styles';
import ShowMe from 'components/ShowMe';

const StyledBackButton = styled(MUIButton)(({ theme }) => ({
  color: theme.palette.text.back,
  textTransform: 'none',
}));

const BackButton = ({
  label = 'Back',
  sublabel,
  typographyVariant = 'body',
  sublabelVariant = 'display3',
  ...props
}) => (
  <StyledBackButton
    variant="text"
    {...props}
    startIcon={<ArrowBackIosNewIcon />}
  >
    <Typography variant={typographyVariant}>{label}</Typography>
    <ShowMe when={sublabel}>
      <Typography variant={sublabelVariant} fontWeight={400}>
        &nbsp;- {sublabel}
      </Typography>
    </ShowMe>
  </StyledBackButton>
);

export default BackButton;
