import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormFields from 'containers/DashboardPage/components/CompleteProfileDialog/FormFields';
import condTrueFalse from 'utils/condTrueFalse';

import { validationSchemas } from './helperFunctions';

const Form = ({ formId, onSubmit, setIsError, selectedFundUpdatesType }) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: { date: null },
    onSubmit,
    validationSchema: validationSchemas[selectedFundUpdatesType?.key],
    validateOnMount: true,
  });

  useEffect(() => setIsError(!!Object.keys(errors).length), [errors]);

  return (
    <form data-testid="capital-call-form" id={formId} onSubmit={handleSubmit}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="display3">
            Fund Update - {selectedFundUpdatesType?.label}
          </Typography>
        </Grid>

        <Grid container item spacing={2}>
          {selectedFundUpdatesType.fields?.map(
            ({ fieldProps, ...formField }, index) => (
              <FormFields
                key={`${selectedFundUpdatesType?.key}-${index.toString()}`}
                {...formField}
                fieldProps={{
                  ...condTrueFalse(fieldProps, fieldProps, {}),
                  'data-testid': `FormField-${selectedFundUpdatesType?.key}`,
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
              />
            ),
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
