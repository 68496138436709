import React, { memo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DownloadButton from 'components/Button/DownloadButton';
import FormTitle from 'components/FormTitle';
import UploadButton from 'components/Button/UploadButton';

import { DOWNLOAD_CERTIFICATE_URL } from 'constants/investmentEntity';

const AccountantCertificateForm = ({ onChange, values, errors, title }) => (
  <>
    <FormTitle title={title} />
    <Grid container direction="column">
      <Typography>
        Please attach an accountant’s certificate indicating you are either:
      </Typography>
      <ul>
        <li>
          <Typography>
            a ‘sophisticated investor’ for the purposes of Chapter 6D; or
          </Typography>
        </li>
        <li>
          <Typography>
            a ‘wholesale client’ for the purposes, of Chapter 7 of the
            Corporations Act 2001 (Cth).
          </Typography>
        </li>
      </ul>

      <DownloadButton
        downloadUrl={DOWNLOAD_CERTIFICATE_URL}
        title="Download Accountant’s Certificate Template"
      />
    </Grid>
    <Divider sx={{ margin: '35px 0 25px' }} />
    <Grid container mt={2}>
      <UploadButton
        name="certificates"
        onChange={onChange}
        value={values?.certificates}
        errorValue={errors?.certificates}
      />
    </Grid>
    <Grid container mt={2}>
      <Typography>
        <b>Note:</b> You can submit your application without uploading this
        document. However, we will require this document prior to approving your
        application.
      </Typography>
    </Grid>
  </>
);

export default memo(AccountantCertificateForm);
