import {
  REPORTING_TEMPLATE_CLOSED_END,
  REPORTING_TEMPLATE_OPEN_END,
} from 'constants/funds';
import dayjs from 'dayjs';

import adminFundPerformanceClosedEndValidationSchema from 'validations/schema/admin/fundPerformance/adminFundPerformanceClosedEndValidationSchema';
import adminFundPerformanceOpenEndValidationSchema from 'validations/schema/admin/fundPerformance/adminFundPerformanceOpenEndValidationSchema';

export const closedEndFormFields = [
  {
    fieldType: 'datePicker',
    name: 'date',
    label: 'Value Date',
    maxDate: dayjs(),
    gridProps: {
      labelGridProps: { xs: 2 },
      childGridProps: { xs: 4 },
    },
    fieldProps: { fullWidth: false },
  },
];

export const openEndFormFields = [
  {
    fieldType: 'datePicker',
    name: 'date',
    label: 'Value Date',
    maxDate: dayjs(),
    gridProps: {
      labelGridProps: { xs: 2 },
      childGridProps: { xs: 4 },
    },
    fieldProps: { fullWidth: false },
  },
];

export const closedEndSinceInceptionFields = [
  {
    id: 'since-inception',
    label: 'Since inception',
    fields: [
      {
        fieldType: 'input',
        name: 'irrGross',
        label: 'IRR (Gross)',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
        },
      },
      {
        fieldType: 'input',
        name: 'irrNet',
        label: 'IRR (Net)',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
        },
      },
      {
        fieldType: 'input',
        name: 'moicNet',
        label: 'MOIC (Net)',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
        },
      },
      {
        fieldType: 'input',
        name: 'tvpi',
        label: 'TVPI',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
        },
      },
      {
        fieldType: 'input',
        name: 'dpi',
        label: 'DPI',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
        },
      },
      {
        fieldType: 'input',
        name: 'rvpi',
        label: 'RVPI',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
        },
      },
      {
        fieldType: 'input',
        name: 'pme',
        label: 'PME',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
        },
      },
    ],
  },
];

export const openEndPerformanceReturnFields = [
  {
    id: 'returnGross',
    label: 'Return (Gross)',
    fields: [
      {
        fieldType: 'input',
        name: 'returnGross_3Month',
        label: '3 Months',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnGrossYtd',
        label: 'YTD',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnGross_1Year',
        label: '1 Year',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnGross_3Year',
        label: '3 Years',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnGross_7Year',
        label: '7 Years',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnGross_10Year',
        label: '10 Years',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnGrossSinceInception',
        label: 'Since Inception',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
    ],
  },
  {
    id: 'returnNet',
    label: 'Return (Net)',
    fields: [
      {
        fieldType: 'input',
        name: 'returnNet_3Month',
        label: '',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnNetYtd',
        label: '',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnNet_1Year',
        label: '',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnNet_3Year',
        label: '',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnNet_7Year',
        label: '',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnNet_10Year',
        label: '',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
      {
        fieldType: 'input',
        name: 'returnNetSinceInception',
        label: '',
        fieldProps: {
          placeholder: '0.00%',
          withThousandSeparator: true,
          type: 'number',
          allowMinus: true,
        },
      },
    ],
  },
];

export const validationSchemas = {
  [REPORTING_TEMPLATE_CLOSED_END]:
    adminFundPerformanceClosedEndValidationSchema,
  [REPORTING_TEMPLATE_OPEN_END]: adminFundPerformanceOpenEndValidationSchema,
};
