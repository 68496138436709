const USD = 'US$';
const AUD = 'A$';
const EUR = 'EU€';
const EUD = '€$';
const NZD = 'NZ$';

export default {
  USD,
  AUD,
  EUR,
  EUD,
  NZD,
};
