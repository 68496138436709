import { useEffect, useCallback, useState } from 'react';

const getNewTempDeletedFiles = ({
  deletedFiles,
  fileKeys,
  isDeleteCategory,
  category,
  dataRooms,
  deleteFile,
}) => {
  const newDeletedFiles = new Map(deletedFiles);

  // Set map if not registered yet.
  if (!newDeletedFiles.get(category.id))
    newDeletedFiles.set(category.id, {
      id: category.id,
      documents: [],
    });

  const deletedCategory = newDeletedFiles.get(category.id);

  if (isDeleteCategory) {
    // Set category's `isDeleted` to true.
    newDeletedFiles.set(deletedCategory.id, {
      ...(dataRooms.find((dataRoom) => dataRoom.id === category.id) ||
        category),
      isDeleted: true,
    });

    const tempDocuments = category.documents.map((document) => document.key);
    // If there's unregistered document, delete from S3.
    if (tempDocuments.length) deleteFile({ keys: tempDocuments });

    return newDeletedFiles;
  }

  const deletedDocument = category.documents.find((document) =>
    fileKeys.includes(document.key),
  );

  // If deleted document is belong to new Category, delete from S3.
  if (!deletedDocument) {
    deleteFile({ keys: fileKeys });
    return newDeletedFiles;
  }

  const newDeletedDocuments = [...deletedCategory.documents];

  // Register deleted document to state.
  newDeletedFiles.set(deletedCategory.id, {
    ...deletedCategory,
    documents: [
      ...newDeletedDocuments,
      { ...deletedDocument, isDeleted: true },
    ],
  });

  return newDeletedFiles;
};

const useUploadCategoryList = ({
  deleteFileAPICall: deleteFile,
  uploadFileAPICAll: uploadFile,
  valueBeforeSubmit: values,
  id, // idFund in dataroom or userId in statement/documents
  deleteOnUnmount = true,
  willUnmountRef,
}) => {
  const [tempUploadedFiles, setTempUploadedFiles] = useState([]);
  const [tempDeletedFiles, setTempDeletedFiles] = useState(new Map());

  const handleDeleteFiles = useCallback(
    (fileKeys, { category, isDeleteCategory } = {}) => {
      if (id && category?.id) {
        setTempDeletedFiles((prevState) =>
          getNewTempDeletedFiles({
            deletedFiles: prevState,
            category,
            fileKeys,
            isDeleteCategory,
            dataRooms: values,
            deleteFile,
          }),
        );

        return true;
      }

      if (fileKeys.length) {
        return deleteFile({ keys: fileKeys });
      }

      return fileKeys;
    },
    [values],
  );

  const deleteFilesOnCancel = useCallback(() => {
    if (
      !tempUploadedFiles.length ||
      (deleteOnUnmount && !willUnmountRef?.current)
    )
      return;
    const fileKeys = tempUploadedFiles.map((file) => file.key);
    handleDeleteFiles(fileKeys);
  }, [handleDeleteFiles, tempUploadedFiles, tempUploadedFiles]);

  useEffect(
    () => () => {
      if (deleteOnUnmount) deleteFilesOnCancel();
    },
    [deleteOnUnmount],
  );

  const handleUploadFiles = useCallback(
    async (dataFiles, updateFiles) => {
      const uploadFiles = dataFiles.target.value;
      if (uploadFiles.length) {
        const formData = new FormData();
        uploadFiles.forEach((file) => {
          formData.append('documents', file.file);
        });
        const result = await uploadFile(id, formData);
        updateFiles(result);

        setTempUploadedFiles((prevState) => [...prevState, ...result]);
      }
    },
    [id],
  );

  return {
    tempDeletedFiles,
    setTempDeletedFiles,
    tempUploadedFiles,
    setTempUploadedFiles,
    handleDeleteFiles,
    handleUploadFiles,
    deleteFilesOnCancel,
  };
};

export default useUploadCategoryList;
