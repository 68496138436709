import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';

import Alert from '@mui/material/Alert';

import useDialog from 'hooks/useDialog';

import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import FailedDialog from 'components/Dialog/FailedDialog';
import LoadingProgressDialog from 'components/Dialog/LoadingProgressDialog';
import condTrueFalse from 'utils/condTrueFalse';
import getMobileNumberValue from 'utils/getMobileNumberValue';
import Services from 'api/services';

import UserForm from '../../AddEditUserPage/components/UserForm';
import { resetPassword } from './HelperFunction';

const UserProfile = ({
  setFieldValue,
  values,
  userId,
  readOnly,
  isPreRegistered,
}) => {
  const [error, setError] = useState('');
  const {
    isDialogOpen: isResetPwdDialogOpen,
    handleOpenDialog: handleOpenResetPwdDialog,
    handleCloseDialog: handleCloseResetPwdDialog,
  } = useDialog();
  const {
    isDialogOpen: successDialogOpen,
    handleOpenDialog: handleSuccessDialogOpen,
    handleCloseDialog: handleSuccessDialogClose,
  } = useDialog();
  const {
    isDialogOpen: isResendEmailVerificationDialogOpen,
    handleOpenDialog: handleOpenResendEmailVerificationDialog,
    handleCloseDialog: handleCloseResendEmailVerificationDialog,
  } = useDialog();
  const userProfileForm = useFormik({
    initialValues: values,
  });

  useEffect(() => {
    if (setFieldValue && Object.keys(userProfileForm.values).length)
      setFieldValue('userProfile', userProfileForm.values);
  }, [userProfileForm.values]);

  const handleResetPassword = useCallback(async () => {
    // handle reset password here
    const result = await resetPassword(userId);
    if (result.message) {
      handleCloseResetPwdDialog();
      handleSuccessDialogOpen();
    }
  }, []);

  const handleResendVerificationEmail = useCallback(async () => {
    // handle resend verification email here
    try {
      await Services.resendEmailVerification({
        email: values?.email,
        mobileNumber: getMobileNumberValue(
          values?.countryCode,
          values?.phoneNumber,
        ),
      });
      handleSuccessDialogOpen();
    } catch (err) {
      setError(err.response?.data?.message);
    } finally {
      handleCloseResendEmailVerificationDialog();
    }
  }, []);

  const handleResetError = useCallback(() => setError(''), []);

  return (
    <>
      <UserForm
        errors={userProfileForm.errors}
        onChange={userProfileForm.handleChange}
        onBlur={userProfileForm.handleBlur}
        handleResetPassword={condTrueFalse(
          isPreRegistered,
          undefined,
          handleOpenResetPwdDialog,
          { executeFn: false },
        )}
        handleResendVerificationEmail={condTrueFalse(
          isPreRegistered,
          handleOpenResendEmailVerificationDialog,
          undefined,
          { executeFn: false },
        )}
        touched={userProfileForm.touched}
        values={userProfileForm.values}
        isEdit
        isView={readOnly}
        isPreRegistered={isPreRegistered}
      />
      <AdminConfirmationDialog
        title="Confirm Changes?"
        subtitle={
          <strong>Are you sure you want to reset this user password?</strong>
        }
        open={isResetPwdDialogOpen}
        onClose={handleCloseResetPwdDialog}
        onConfirm={handleResetPassword}
        confirmButtonLabel="Yes"
        maxWidth="sm"
      >
        <Alert severity="warning" icon={false}>
          Caution: This action will send the user an email to set a new password
        </Alert>
      </AdminConfirmationDialog>
      <AdminConfirmationDialog
        open={isResendEmailVerificationDialogOpen}
        title="Resend Email Verification"
        subtitle={
          <strong>
            Are you sure you want to resend Email Verification for this user ?
          </strong>
        }
        onClose={handleCloseResendEmailVerificationDialog}
        onConfirm={handleResendVerificationEmail}
        confirmButtonLabel="Yes"
        maxWidth="sm"
      >
        <Alert severity="warning" icon={false}>
          Caution: This action will send the user an email to verify their
          Email.
        </Alert>
      </AdminConfirmationDialog>
      <LoadingProgressDialog
        subtitle="An email has been sent to the user"
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
        withLoadingProgress={false}
        maxWidth="lg"
      />

      <FailedDialog
        open={!!error}
        onConfirm={handleResetError}
        title="Submit Error"
        subtitle={error}
      />
    </>
  );
};

export default UserProfile;
