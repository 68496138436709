import { styled } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme } = {}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  minHeight: `${theme.spacing(11)} !important`,
  marginBottom: theme.spacing(3.75),
}));

export default DrawerHeader;
