import React, { memo } from 'react';

import Typography from '@mui/material/Typography';

const getStartedDescriptions = [
  'Before you can view additional information on our funds, or invest, you need to add an ‘investment entity’.  An ‘investment entity’ is simply the person or entity that will be making the investment (i.e. it may be you personally, or you may be investing on behalf of your Family Trust or SMSF, or through a company).',
  'You can link more than one ‘investment entity’ with your account and select which one will invest.',
  'By proceeding, you confirm you are authorised to provide us with the following infomation.  You’ll need to provide a driver’s lience or passport. You also consent to it being verified by the document issuer or official holder via a third-party system.',
];

const GetStartedDescription = () =>
  getStartedDescriptions.map((description) => (
    <Typography key={description} variant="body">
      {description}
      <br />
      <br />
    </Typography>
  ));

export default memo(GetStartedDescription);
