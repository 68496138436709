export const ALL = 'ALL';
export const ACTIVE = 'ACTIVE';
export const BLOCKED = 'BLOCKED';
export const SUSPENDED = 'SUSPENDED';
export const VERIFIED_WITH_CHANGES = 'VERIFIED_WITH_CHANGES';
export const IN_PROGRESS = 'IN_PROGRESS';
export const UNVERIFIED = 'UNVERIFIED';
export const UNVERIFIED_OVERSEAS = 'UNVERIFIED_OVERSEAS';
export const VERIFIED = 'VERIFIED';
export const VERIFIED_OVERSEAS = 'VERIFIED_OVERSEAS';
export const PENDING = 'PENDING';
export const PENDING_OVERSEAS = 'PENDING_OVERSEAS';
export const CANCELLED = 'CANCELLED';
export const CANCELLED_OVERSEAS = 'CANCELLED_OVERSEAS';
export const PRE_REGISTERED = 'PRE-REGISTERED';

export const USER_STATUS = {
  [ALL]: ALL,
  [ACTIVE]: ACTIVE,
  [SUSPENDED]: SUSPENDED,
  [BLOCKED]: BLOCKED,
  [IN_PROGRESS]: IN_PROGRESS,
  [VERIFIED_WITH_CHANGES]: VERIFIED_WITH_CHANGES,
  [UNVERIFIED]: UNVERIFIED,
  [UNVERIFIED_OVERSEAS]: UNVERIFIED_OVERSEAS,
  [VERIFIED]: VERIFIED,
  [VERIFIED_OVERSEAS]: VERIFIED_OVERSEAS,
  [PENDING]: PENDING,
  [PENDING_OVERSEAS]: PENDING_OVERSEAS,
  [CANCELLED]: CANCELLED,
  [CANCELLED_OVERSEAS]: CANCELLED_OVERSEAS,
  [PRE_REGISTERED]: PRE_REGISTERED,
};

export const USER_STATUS_LABEL = {
  [UNVERIFIED]: 'Unverified',
  [UNVERIFIED_OVERSEAS]: 'Unverified',
  [PENDING]: 'Pending',
  [PENDING_OVERSEAS]: 'Pending',
  [VERIFIED]: 'Verified',
  [VERIFIED_OVERSEAS]: 'Verified',
  [UNVERIFIED]: 'Unverified',
  [UNVERIFIED_OVERSEAS]: 'Unverified',
  [CANCELLED]: 'Cancelled',
  [CANCELLED_OVERSEAS]: 'Cancelled',
  [PRE_REGISTERED]: 'Pre-Registered',
};

export const USER_STATUS_OPTIONS = [
  { label: 'All', value: ALL },
  { label: 'Active', value: ACTIVE },
  { label: 'Suspended', value: SUSPENDED },
  { label: 'Blocked', value: BLOCKED },
];
