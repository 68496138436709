import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import history from 'utils/history';

const textColor = '#F5F5F5';
const iconSelectedColor = '#FFFFFF';
const navItemSelectedColor = '#555555';

const NavItem = ({ item, open, sx = {} }) => {
  const location = useLocation();

  const itemIcon = useMemo(() => {
    if (!item.icon) return null;

    const Icon = item.icon;
    return <Icon />;
  });

  const isSelected = useMemo(
    () =>
      location.pathname === item.id ||
      (item.id !== '/admin' && location.pathname.includes(item.id)),
    [open, location.pathname],
  );

  const handleRoute = () => history.push(item.url);

  return (
    <ListItemButton
      disabled={item.disabled}
      onClick={handleRoute}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: '28px',
        py: 1,
        '&:hover': {
          bgcolor: navItemSelectedColor,
        },
        '&.Mui-selected': {
          bgcolor: navItemSelectedColor,
          color: iconSelectedColor,
          '&:hover': {
            color: iconSelectedColor,
            bgcolor: navItemSelectedColor,
          },
        },
        ...sx,
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            borderRadius: 1.5,
            width: 36,
            height: 36,
            alignItems: 'center',
            justifyContent: 'center',
            'svg > path': {
              fill: 'white',
            },
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography
            variant="buttonTextLight"
            sx={{ color: isSelected ? iconSelectedColor : textColor }}
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default NavItem;
