import React from 'react';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import FormTitle from 'components/FormTitle';
import InputField from 'components/Input';
import FieldContainer from 'components/FieldContainer';
import Dropdown from 'components/Dropdown';
import DatePicker from 'components/DatePicker';

const date = new Date();

const RequestDetailsSection = ({
  onChange,
  values,
  errors,
  funds,
  fundConfigurations,
  portfolios,
  setFieldValue,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Request Details" />
    </Grid>

    <Grid container item xs={12} p={2} rowSpacing={3}>
      <FieldContainer
        item
        label="Fund Name"
        labelGridProps={{ xs: 3 }}
        childGridProps={{ container: true, xs: true, columnGap: 2 }}
      >
        <Grid item xs={6}>
          <Dropdown
            data-testid="fundRedemptionRequest-fund"
            id="fund"
            name="fund"
            placeholder="Please select fund"
            options={funds}
            value={values.fund}
            onChange={onChange}
            error={!!errors.fund}
            errorValue={errors.fund}
          />
        </Grid>
      </FieldContainer>
      <FieldContainer
        item
        label="Fund Class"
        labelGridProps={{ xs: 3 }}
        childGridProps={{ container: true, xs: true, columnGap: 2 }}
      >
        <Grid item xs={6}>
          <Dropdown
            data-testid="fundRedemptionRequest-fundConfiguration"
            id="fundConfiguration"
            name="fundConfiguration"
            placeholder="Please select a Fund Configuration"
            options={fundConfigurations}
            value={values.fundConfiguration}
            onChange={onChange}
          />
        </Grid>
      </FieldContainer>
      <FieldContainer
        item
        label="Portfolio (Reference number)"
        labelGridProps={{ xs: 3 }}
        childGridProps={{ container: true, xs: true, columnGap: 2 }}
      >
        <Grid item xs={6}>
          <Dropdown
            data-testid="fundRedemptionRequest-portfolio"
            id="portfolio"
            name="portfolio"
            placeholder="Please select a Fund Configuration"
            options={portfolios}
            value={values.portfolio}
            onChange={onChange}
          />
        </Grid>
      </FieldContainer>

      <FieldContainer
        item
        label="Redemption Request"
        labelGridProps={{ xs: 3 }}
        childGridProps={{ container: true, xs: true, columnGap: 2 }}
      >
        <Grid item xs={6}>
          <InputField
            fullWidth
            withThousandSeparator
            type="number"
            data-testid="fundRedemptionRequest-redemptionUnitAmount"
            id="redemptionUnitAmount"
            name="redemptionUnitAmount"
            value={values.redemptionUnitAmount}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">units</InputAdornment>
              ),
            }}
            error={!!errors.redemptionUnitAmount}
            errorValue={errors.redemptionUnitAmount}
          />
        </Grid>
      </FieldContainer>

      <FieldContainer
        item
        label="Request Date"
        labelGridProps={{ xs: 3 }}
        childGridProps={{ container: true, xs: true, columnGap: 2 }}
      >
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            id="requestedAt"
            name="requestedAt"
            testId="fundRedemptionRequest-requestedAt"
            onChange={onChange}
            value={values.requestedAt}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </FieldContainer>
    </Grid>
  </Grid>
);

export default RequestDetailsSection;
