import React, { useMemo } from 'react';
import axios from 'axios';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import BackToTop from 'components/BackToTop';
import Chip from 'components/Chip';
import InnerHtmlContainer from 'components/InnerHtmlContainer';
import PDFViewer from 'components/PDFViewer';
import ShowMe from 'components/ShowMe';

import services from 'api/services';
import history from 'utils/history';
import useScreenQuery from 'hooks/useScreenQuery';
import useFetchApi from 'hooks/useFetchApi';

import ArticleCard from '../InsightCommunityTab/MoreArticles/ArticleCard';

const defaultTredingArticles = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

const handleRouteToArticleDetail = (articleId) =>
  history.push(`/articles/${articleId}?`);

const ArticleDetailSection = ({
  articleDetailData = {
    title: 'Reach PGIM Real Estate US Feeder Fund',
    createdBy: 'Richard Mayo',
    category: 'article category',
    date: new Date().toISOString,
    content:
      '<h1><span style="font-size: 24px">1-FUND PERFORMANCE</span><br></h1><div>​<span style="font-size: 14px">The Fund invests in EPF IV. EPF IV will primarily seek to acquire non-performing, non-core and capital inefficient whole loans, real assets and other asset-backed credit and credit-like instruments, as well as associated operating platforms arising from the deleveraging and distress in the European financial system. Apollo anticipates that the average hold period for investments in the Fund will be two to four years.</span></div><div><br></div><h1>Reach Alts Profile<br></h1><div class="se-component se-video-container __se__float-none" contenteditable="false"><figure style="width:595px; height:334px;"><iframe src="https://www.youtube.com/embed/ZO3yB9k6ySg" data-proportion="true" data-size="595px,334px" data-align="none" data-file-name="ZO3yB9k6ySg" data-file-size="0" data-origin="100%,56.25%" style="width:595px; height:334px;"></iframe></figure></div><p>​<br></p>',
  },
  trendingArticles = defaultTredingArticles,
}) => {
  const { isMobileScreen, isTabletScreen } = useScreenQuery();

  const hasRightSection = useMemo(
    () => !!trendingArticles.length,
    [trendingArticles.length],
  );

  const categoryAndTagChips = useMemo(
    () => (
      <>
        <Chip
          label={articleDetailData.category}
          color="selectedChipColor"
          sx={{ mr: 1 }}
        />
        <Chip label={articleDetailData.tags} color="unselectedChipColor" />
      </>
    ),
    [articleDetailData.category, articleDetailData.tags],
  );

  const getDocumentData = useMemo(() => {
    if (
      !articleDetailData?.document?.id ||
      process.env.REACT_APP_ENV === 'prod'
    )
      return null;

    return services.getArticleDocument({ id: articleDetailData.document.id });
  }, [articleDetailData?.document?.id]);

  const { apiData: articleDocumentData } = useFetchApi({
    url: getDocumentData,
    defaultApiData: {},
    dependencies: [getDocumentData],
  });

  const articleDocument = useMemo(
    () =>
      articleDocumentData?.data?.file
        ? window.atob(articleDocumentData.data?.file)
        : '',
    [articleDocumentData],
  );

  return (
    <Grid container columnSpacing={2}>
      <Grid
        display="flex"
        container
        item
        xs={12}
        columnSpacing={{ sm: 0, lg: 2 }}
        rowSpacing={{ xs: 5, md: 0 }}
      >
        <Grid
          container
          item
          xs={12}
          md={hasRightSection ? 9 : 12}
          direction="column"
        >
          <Grid item backgroundColor="white">
            <Grid container p={2} flexDirection="column" rowSpacing={1}>
              <Grid item>
                <Typography fontSize="11px">
                  <b>{articleDetailData.category}</b> •{' '}
                  {new Date(articleDetailData.date).toLocaleString('en-AU', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                  })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="display1">
                  {articleDetailData.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{articleDetailData.createdBy}</Typography>
              </Grid>
              <Grid item xs={12}>
                {categoryAndTagChips}
              </Grid>
              <Grid item xs={12} mt={5}>
                <Divider />
              </Grid>
            </Grid>
            <InnerHtmlContainer
              htmlData={articleDetailData?.content}
              imageMaxWidth="100%"
            />
            <ShowMe when={!!articleDocument}>
              <Grid item xs={12} p={2}>
                <PDFViewer
                  pdfData={articleDocument}
                  style={{
                    height: isMobileScreen ? '400px' : '900px',
                    // width: '100%',
                  }}
                />
              </Grid>
            </ShowMe>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={hasRightSection ? 3 : 0}
          direction="column"
        >
          <Grid container px={4} py={3} backgroundColor="white">
            <Grid item xs={12} mb={3}>
              <Typography variant="display5">TRENDING NOW</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              rowSpacing={4}
              columnSpacing={isTabletScreen ? 3 : undefined}
              maxWidth={isMobileScreen ? 'inherit !important' : ''}
              justifyContent={{ xs: 'center', md: 'initial' }}
            >
              {trendingArticles.map((trendingArticle = {}) => (
                <Grid item>
                  <ArticleCard
                    key={trendingArticle.id}
                    id={trendingArticle.id}
                    articleContentContainerProps={{ px: [] }}
                    imageSrc={
                      trendingArticle.articleLogoImgUrl ||
                      trendingArticle.articleHeaderImgUrl
                    }
                    onClick={handleRouteToArticleDetail}
                    articleDate={trendingArticle.date}
                    category={trendingArticle.category}
                    name={trendingArticle.title}
                    backgroundColor="white"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BackToTop />
    </Grid>
  );
};

export default ArticleDetailSection;
