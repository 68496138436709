import React, { memo, useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useStores } from 'components/StateProvider';
import Button from 'components/Button';
import BackButton from 'components/Button/BackButton';
import Input from 'components/Input';
import history from 'utils/history';

const BottomContainer = ({ values }) => {
  const {
    auth: { user },
  } = useStores();

  const fields = useMemo(
    () => [
      {
        key: 'password',
        label: 'Password',
        type: 'password',
        defaultValue: 'password',
        onClick: () => history.push('/profile/change-password'),
        disabled: user?.isChangePasswordDisable,
      },
      {
        key: 'mobileNumber',
        label: 'Phone Number',
        type: 'text',
        defaultValue: '',
        onClick: () => history.push('/profile/change-phone-number'),
      },
    ],
    [user?.isChangePasswordDisable],
  );

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const handleBack = useCallback(() => history.push('/'), []);

  return (
    <Grid container spacing={10} item>
      <Grid container spacing={2} item>
        {fields.map(({ key, label, type, defaultValue, onClick, disabled }) => (
          <Grid
            key={key}
            container
            spacing={2}
            item
            xs={12}
            justifyContent="center"
            alignItems="self-end"
            direction="row"
          >
            <Grid item xs={8} sm={6} md={5} lg={4}>
              <InputLabel htmlFor={key}>
                <Typography variant="body">{label}</Typography>
              </InputLabel>
              <Input
                disabled
                fullWidth
                data-testid={`${key}-input`}
                id={key}
                name={key}
                type={type}
                value={values[key] || defaultValue}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={3} lg={3}>
              <Button fullWidth onClick={onClick} disabled={disabled}>
                <Typography variant="subtitle1White">
                  Change {!isXS && label}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={5} sm={4} textAlign={!isXS && 'right'}>
        <BackButton
          onClick={handleBack}
          label="Back"
          size="large"
          data-testid="button-back"
        />
      </Grid>
    </Grid>
  );
};

export default memo(BottomContainer);
