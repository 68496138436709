import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FundCardButton from 'components/FundCard/components/FundCardButton';

import config from 'utils/config';
import history from 'utils/history';
import palette from 'theme/palette';

const RedirectToFunds = () => history.push('/dashboard/funds');

const EmptyInvestContainer = ({ testId }) => (
  <Grid
    data-testid={testId}
    container
    alignContent="center"
    sx={{
      // backgroundColor: config.system.appColours.cardBackground,
      borderTop: `8px ${config.system.appColours.border} solid`,
      transition: 'border-top .5',
      py: [2, 5],
    }}
  >
    <Grid
      container
      item
      xs={12}
      alignContent="center"
      sx={{ placeContent: { xs: 'center', md: 'inherit' } }}
    >
      <Typography variant="h6">
        You are yet to invest.
        <br />
        To view our funds, click on the button below.
      </Typography>
    </Grid>

    <Grid item xs={12} my={4}>
      <Divider />
    </Grid>

    <Grid item xs={12} textAlign="right">
      <FundCardButton
        label="View Funds"
        sx={{
          width: '100%',
          maxWidth: '140px',
          backgroundColor: palette.reach.menuLink,
          color: 'white',
          borderColor: 'transparent',
          transition: 'background-color .5s linear',
          '&:hover': {
            backgroundColor: palette.reach.menuLink,
            borderColor: 'transparent',
          },
        }}
        onClick={RedirectToFunds}
        labelProps={{ color: 'white' }}
      />
    </Grid>
  </Grid>
);

export default EmptyInvestContainer;
