import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AuthContainer from 'components/AuthContainer/indexv2';
import SidebarContainer from 'components/SidebarContainer';
import palette from 'theme/palette';

const ContainerStyles = {
  maxHeight: '-webkit-fill-available',
  textAlign: '-webkit-center',
  marginTop: { xs: 0, md: '30px' },
};
const SubTitleStyles = (theme) => ({
  color: theme.palette.text.grey,
  fontWeight: 400,
  fontSize: theme.spacing(2),
  lineHeight: '19px',
});

const VerificationSuccess = ({ fullName = '', handleLogin }) => (
  <SidebarContainer withCompanyLogo>
    <AuthContainer
      title="Your account has been activated"
      linearColor={palette.reach.green}
      nextLabel="Login to Dashboard"
      nextBtnContainerProps={{ xs: 'auto' }}
      onNext={handleLogin}
      onBack={null}
      nextBtnProps={{ buttoncolor: 'purpleHazeGradient' }}
    >
      <Grid container direction="row" sx={ContainerStyles}>
        <Grid item xs={12} mt={{ xs: 0, md: '30px' }}>
          <Typography variant="h1">Congratulations, {fullName}!</Typography>
          <Typography variant="h1">
            Your account was activated successfully.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={3}>
          <Typography variant="body2" sx={SubTitleStyles}>
            You can now login with the email and password you provided when you
            signed up.
          </Typography>
        </Grid>
      </Grid>
    </AuthContainer>
  </SidebarContainer>
);
export default memo(VerificationSuccess);
