import React from 'react';

import BeneficialOwnerSummary from 'containers/AddInvestmentEntity/Company/Steps/BeneficialOwners/Steps/BeneficialOwnerSummaryForm/Summary';
import IndividualDetailsSummary from 'containers/AddInvestmentEntity/components/Forms/IndividualDetailsSummary';

import Accordion from 'components/Accordion';
import LabelWithChip from 'components/LabelWithChip';

import joinArrayStringWithSeparator from 'utils/strings/joinArrayStringWithSeparator';

const PersonDetail = ({
  values,
  title = 'Individual Details',
  withChip = false,
  isBeneficialOwner,
  chipColor = 'joint',
  chipText = 'Individual',
  setValues,
  onDelete,
  ...props
}) => (
  <Accordion
    label={
      <LabelWithChip
        label={joinArrayStringWithSeparator([
          values.firstName,
          values.middleName,
          values.lastName,
        ])}
        chipLabel={withChip && chipText}
        chipColor={chipColor}
      />
    }
    onDelete={onDelete}
    labelVariant="cardNameLabel"
  >
    {React.cloneElement(
      isBeneficialOwner ? (
        <BeneficialOwnerSummary />
      ) : (
        <IndividualDetailsSummary />
      ),
      {
        values,
        setValues,
        detailsTitle: title,
        sectionListView: true,
        ...props,
      },
    )}
  </Accordion>
);

export default PersonDetail;
