import * as Auth from './auth';
import * as Profile from './profile';
import Drawer from './drawer';
import General from './general';

const ReachAltActions = ({ dispatch, state }) => {
  const handleLogin = (data) => Auth.loginAction(dispatch, data);
  const handleLogout = () => Auth.logoutAction(dispatch);
  const handleRefreshToken = (data) => Auth.refreshTokenAction(dispatch, data);
  const handleGetUserProfile = () => Profile.updateUserProfile(dispatch, state);
  const handleGetUserAccessibility = () =>
    Profile.updateAccessibility(dispatch);

  const handleUpdatePhoneNumber = (data) =>
    Profile.updatePhoneNumberAction(dispatch, data);

  const handleUpdateUserStatusStore = (data) =>
    Profile.updateUserStatusStore(dispatch, data);

  const handleUpdateUserViewFundStatus = () =>
    Profile.updateUserViewFundStatus(dispatch);

  // DRAWER
  const handleToggleDrawer = (isOpen) =>
    Drawer.toggleDrawerAction(dispatch, { open: isOpen });

  // GENERAL
  const handleToggleEditSummary = (data) =>
    General.toggleEditSummaryAction(dispatch, data);
  const handleSetSidebar = (data) => General.setSidebarAction(dispatch, data);
  const handleUpdateAppColours = (data) =>
    General.updateAppColours(dispatch, data);
  const handleGetLatestCurrencyData = () =>
    General.updateLatestCurrencyData(dispatch);

  return {
    handleLogin,
    handleLogout,
    handleRefreshToken,
    handleUpdatePhoneNumber,
    handleUpdateUserStatusStore,
    handleGetUserAccessibility,
    handleGetUserProfile,
    handleUpdateUserViewFundStatus,
    handleToggleDrawer,
    handleToggleEditSummary,
    handleSetSidebar,
    handleUpdateAppColours,
    handleGetLatestCurrencyData,
  };
};

export default ReachAltActions;
