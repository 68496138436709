import React, { memo } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import TextButton from 'components/Button/TextButton';

const TaxDetailsFooter = ({
  isEditing,
  isMainTax,
  onEdit,
  onSave,
  onCancel,
  form = {},
  isError,
}) => {
  if (isEditing === null) {
    return (
      <TextButton
        data-testid="add-tax-button"
        Icon={AddCircleIcon}
        onClick={onEdit}
        color={isMainTax ? 'individualIcon' : 'jointIcon'}
        variant="buttonTextInfo"
        sx={{ marginLeft: '5px', cursor: 'pointer', float: 'right' }}
      >
        Add tax details
      </TextButton>
    );
  }
  if (isEditing) {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingLeft: '20px' }}
      >
        <Grid item>
          <TextButton variant="buttonTextLight" onClick={onCancel}>
            Cancel
          </TextButton>
        </Grid>
        <Grid item>
          <Button
            data-testid="tax-save-button"
            variant="contained"
            color="saveButton"
            onClick={onSave}
            sx={{ textTransform: 'none' }}
            disabled={isError || !!Object.keys(form?.errors || {}).length}
          >
            Save <SaveOutlinedIcon sx={{ marginLeft: '10px' }} />
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <TextButton variant="buttonTextInfo" onClick={onEdit}>
      <EditOutlinedIcon /> Edit tax details
    </TextButton>
  );
};

export default memo(TaxDetailsFooter);
