import React from 'react';
import TextField from '@mui/material/TextField';

import CountryFlag from 'components/CountryFlag';

import countries from 'utils/countries.json';

const getInputValues = (inputProps) =>
  inputProps.value &&
  `+${countries.find(({ code }) => code === inputProps.value)?.phone}`;

const AutoCompleteInput = ({ inputProps, ...params }) => (
  <div style={{ position: 'relative' }}>
    {inputProps.value && (
      <span
        style={{
          position: 'absolute',
          transform: 'translateY(50%)',
          marginLeft: '10px',
          marginTop: '5px',
          height: '50%',
          zIndex: '99',
        }}
      >
        {/* Write logic to have Icon from inputProps.value */}
        <CountryFlag fileName={inputProps.value.toLowerCase()} />
      </span>
    )}
    <TextField
      {...params}
      variant="outlined"
      inputProps={{
        ...inputProps,
        style: { paddingLeft: '32px', paddingTop: '5.5px', fontWeight: 600 },
        value: getInputValues(inputProps),
        readOnly: true,
      }}
    />
  </div>
);

export default AutoCompleteInput;
