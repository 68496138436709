import React, { memo, useMemo } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = ({ title, options = {}, ...props }) => (
  <Bar
    options={{
      plugins: { legend: { position: 'bottom' } },
      ...options,
    }}
    {...props}
  />
);

export default memo(BarChart);
