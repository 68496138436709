import * as yup from 'yup';

import getNumbers from 'utils/strings/getNumbers';

const adminAddUserFundRedemptionRequestValidationSchema = yup.object({
  userEntity: yup.string().required().label('User Entity'),
  fund: yup.string().required().label('Fund'),
  fundConfiguration: yup.string().required().label('Fund Configuration'),

  redemptionUnitAmount: yup
    .mixed()
    .when('selectedPortfolio', (selectedPortfolio, schema) =>
      schema.test({
        test: (redemptionUnitAmount) =>
          getNumbers(redemptionUnitAmount) <= selectedPortfolio.unit,
        message: 'Redemption Amount cannot be greater than owned units',
      }),
    )
    .required()
    .label('Redemption Amount'),

  isSigned: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),

  status: yup.string().required().label('Redemption Request Status'),
});

export default adminAddUserFundRedemptionRequestValidationSchema;
