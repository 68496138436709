import React, { memo, useMemo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import AdminFormContainer from 'components/AdminFormContainer';

import history from 'utils/history';
import PayloadMappers from 'utils/PayloadMappers';
import ResponseMappers from 'utils/ResponseMappers';
import scrollIntoErrorField from 'utils/scrollIntoErrorField';
import condTrueFalse from 'utils/condTrueFalse';

import usePasswordValidationHints from 'hooks/usePasswordValidationHints';
import useFetchApi from 'hooks/useFetchApi';

import { AUSTRALIA_DEFAULT_VALUE } from 'constants/phoneNumber';
import { ERROR_MESSAGES } from 'constants/errors';
import addEditUserSchemas from 'validations/schema/addEditUserSchemas';
import passwordSchema from 'validations/password';

import Services from 'api/services';

import UserForm from './components/UserForm';
import HelperFunction from './HelperFunction';

const { fetchUserData } = HelperFunction;
const { user: userPayloadMapper } = PayloadMappers.admin;
const { user: userResponseMapper } = ResponseMappers.admin;

const passwordYupSchemaObject = yup.object(passwordSchema);

const AddEditUserPage = () => {
  const params = useParams();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const userId = useMemo(() => params?.userId, [params]);
  const isEdit = useMemo(
    () => history?.location?.pathname?.includes('edit'),
    [history],
  );
  const isView = useMemo(
    () => history?.location?.pathname?.includes('view'),
    [history],
  );
  const isAdd = useMemo(
    () => history?.location?.pathname?.includes('add'),
    [history],
  );

  const getUserData = useMemo(
    () =>
      condTrueFalse(
        isAdd,
        () => Promise.resolve(),
        () => fetchUserData(userId),
      ),
    [userId, isAdd],
  );
  const { apiData: userData } = useFetchApi({
    url: getUserData,
  });

  const handleRedirectUserList = useCallback(() =>
    history.push('/admin/users'),
  );

  const handleSubmit = useCallback(
    async (values, { setFieldError }) => {
      const mappedValues = userPayloadMapper(values);
      try {
        setIsLoadingSubmit(true);
        let res;
        if (isEdit) {
          res = await Services.adminEditUser(userId, mappedValues);
        } else {
          res = await Services.adminAddUser(mappedValues);
        }
        if (res) handleRedirectUserList();
      } catch (error) {
        switch (true) {
          case error?.response?.data?.code?.includes('mobile.number'):
            setFieldError(
              'phoneNumber',
              ERROR_MESSAGES[error?.response?.data?.code],
            );
            scrollIntoErrorField('phoneNumber');
            break;
          case error?.response?.data?.code?.includes('email'):
            setFieldError('email', ERROR_MESSAGES[error?.response?.data?.code]);
            scrollIntoErrorField('email');
            break;
          default:
            break;
        }
      } finally {
        setIsLoadingSubmit(false);
      }
    },
    [isEdit, userId],
  );

  const userForm = useFormik({
    validationSchema: addEditUserSchemas,
    initialValues: {
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      password: '',
      role: '',
      status: '',
      phoneNumber: '',
      countryCode: AUSTRALIA_DEFAULT_VALUE,
      isEdit,
      ...condTrueFalse(isEdit || isView, userResponseMapper(userData)),
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const { validationResults, handleChangePassword } =
    usePasswordValidationHints({
      form: userForm,
      value: userForm?.values.password,
      validationSchema: passwordYupSchemaObject,
    });

  const isSaveButtonDisabled = useMemo(() => {
    const pwdValidationPassed = !!validationResults.find(
      (hint) => !hint.isValid,
    );
    const isNotEditOrPwdIsFilled = !isEdit || !!userForm.values.password;
    const noFormErrors = !!Object.keys(userForm?.errors)?.length;

    return (pwdValidationPassed && isNotEditOrPwdIsFilled) || noFormErrors;
  }, [validationResults, userForm?.errors, userForm?.values, isEdit]);

  const formTitle = useMemo(() => {
    switch (true) {
      case isEdit:
        return 'Edit User';
      case isView:
        return 'View User';
      default:
        return 'Add User';
    }
  }, [isEdit, isView]);

  return (
    <AdminFormContainer
      title={formTitle}
      onFormSubmit={userForm.handleSubmit}
      onCancel={handleRedirectUserList}
      isSaveButtonDisabled={isSaveButtonDisabled}
      isLoading={isLoadingSubmit}
      isView={isView}
    >
      <UserForm
        errors={userForm?.errors}
        isView={isView}
        onChange={userForm?.handleChange}
        onBlur={userForm?.handleBlur}
        handleChangePassword={handleChangePassword}
        handleRedirectUserList={handleRedirectUserList}
        touched={userForm?.touched}
        validationResults={validationResults}
        values={userForm?.values}
      />
    </AdminFormContainer>
  );
};

export default memo(AddEditUserPage);
