import React from 'react';
import Accordion from 'components/Accordion';
import LabelWithChip from 'components/LabelWithChip';
import TaxDetailList from './TaxDetailList';

const TaxDetailListWithAccordion = ({ values = [], ...props }) => (
  <Accordion
    label={
      <LabelWithChip
        label="Tax Detail"
        chipLabel={values.length}
        chipColor="trusteeCompanyChipColor"
      />
    }
    labelVariant="cardNameLabel"
  >
    <TaxDetailList values={values} {...props} />
  </Accordion>
);

export default TaxDetailListWithAccordion;
