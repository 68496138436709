import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { auBankFields, overseasBankFields } from './HelperFunction';

const SelectBankAccount = ({ selectedPortfolio }) => {
  const bankAccountInfo = useMemo(() => {
    if (!selectedPortfolio?.fundConfiguration?.bankAccount) return '';

    const { bankAccount } = selectedPortfolio.fundConfiguration;

    const fields =
      bankAccount.country === 'AU' ? auBankFields : overseasBankFields;

    return fields.map(({ label, field, spacing }) => (
      <Grid container item {...spacing} rowSpacing={4}>
        <Grid item xs={12}>
          <Typography variant="title">{label}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{bankAccount[field]}</Typography>
        </Grid>
      </Grid>
    ));
  }, [selectedPortfolio]);

  return (
    <Grid container xs={12} rowSpacing={6}>
      <Grid item xs={12}>
        <Typography variant="smallTitle">
          Please confirm the account you&apos;d like to receive the funds:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="link" fontWeight={600}>
          Australian Bank Account
        </Typography>
      </Grid>
      <Grid container item xs={12} pl={4}>
        {bankAccountInfo}
      </Grid>
      <Grid container item xs={12} justifyContent="right">
        <Typography variant="small" fontSize={14}>
          Do you require payment to be made to a different account? You&apos;ll
          need to contact admin.
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Typography variant="small" fontSize={14}>
          <b>Please note:</b>
          <br />
          The bank account name must match the investor&apos;s name. The
          redemption account must be the same as the account noted during the
          application process, or an explanation must be provided. Payment to
          third parties are not permitted.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SelectBankAccount;
