import React, { useState } from 'react';

import Grid from '@mui/material/Grid';

import AdminPageContainer from 'components/AdminPageContainer';
import DataTable from 'components/DataTable';
import LoadingSpinner from 'components/LoadingSpinner';
import TotalHeaders from 'components/TotalHeaders';

import history from 'utils/history';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';
import Services from 'api/services';

import { PERIOD_ALL } from 'constants/general';
import {
  INVESTMENT_ENTITY_STATUS,
  INVESTMENT_ENTITY_STATUS_OPTIONS,
} from 'constants/investmentEntityStatus';

import { videoHeaders, getVideoTotalHeader } from './HelperFunction';

const redirectToVideoDetail = ({ row }) => {
  history.push(`/admin/videos/${row.videoUid}`, {
    onBackRoute: '/admin/article-events',
  });
};

const routeToNewVideoPage = () => history.push('/admin/videos/add');

const VideoSection = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [period, setPeriod] = useState(PERIOD_ALL);

  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: Services.adminGetVideos,
    defaultApiData: {},
  });

  return (
    <AdminPageContainer
      pageTitle="Videos"
      onFilter={(x) => x}
      filterValue={INVESTMENT_ENTITY_STATUS.ALL}
      filterOptions={INVESTMENT_ENTITY_STATUS_OPTIONS}
      addEntityBtnText="Add New Video"
      onAddEntity={routeToNewVideoPage}
    >
      <TotalHeaders
        exportLabel=""
        totals={getVideoTotalHeader(apiData.totalItems)}
      />
      <Grid item xs={12}>
        {isFetchLoading ? (
          <LoadingSpinner loading />
        ) : (
          <DataTable
            wrapheader
            hideFooter
            sortingMode="client"
            withCheckbox={false}
            pagination={false}
            height="300px"
            headerData={videoHeaders}
            data={apiData.items}
            setSort={setSort}
            isLoading={isFetchLoading}
            totalData={apiData.totalItems}
            containerProps={{ backgroundColor: 'white' }}
            onRowClick={redirectToVideoDetail}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        )}
      </Grid>
    </AdminPageContainer>
  );
};

export default VideoSection;
