import * as yup from 'yup';

const taxDetailValidationSchema = yup.object({
  investors: yup
    .array()
    .test('investors', 'Required Field', (value) =>
      value.every((v) => v.hasAustraliaTax !== undefined),
    ),
});

export default taxDetailValidationSchema;
