import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import BackButton from 'components/Button/BackButton';
import Chip from 'components/Chip';

import condTrueFalse from 'utils/condTrueFalse';
import { getFundStatusChipColor } from 'utils/chipMappers';

const Header = ({
  onBackButtonClick,
  backButtonLabel = 'Add New Fund',
  sublabel,
  status,
}) => (
  <Grid item container xs={12} rowSpacing={4} alignItems="center">
    <Grid item>
      <BackButton
        data-testid="button-back"
        onClick={onBackButtonClick}
        typographyVariant="display3"
        label={backButtonLabel}
        sublabel={sublabel}
      />
    </Grid>
    <Grid item xs>
      <Divider flexItem variant="middle" />
    </Grid>
    <Grid item>
      <Chip
        label={status}
        color={condTrueFalse(
          getFundStatusChipColor({ status }),
          getFundStatusChipColor({ status }),
          'rejectedChipColor',
        )}
      />
    </Grid>
  </Grid>
);

export default Header;
