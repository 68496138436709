import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { mapPayloadToTaxDetails } from 'containers/AddInvestmentEntity/Individual/Steps/TaxDetails/HelperFunction';
import SummarySection from 'components/SummarySection';

import Services from 'api/services';
import ResponseMappers from 'utils/ResponseMappers';

import {
  COUNTRY_OPTIONS,
  SUMMARY_FIELDS_COMPANY_TAX_DETAILS,
  SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION,
  SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_NO_GIIN,
  SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_QUESTION_4,
} from 'constants/investmentEntity';
import formatMaskedValue from 'utils/formatMaskedValue';

const CompanyTaxDetailSummaryFields = (values) => [
  ...SUMMARY_FIELDS_COMPANY_TAX_DETAILS,
  {
    fieldComponent: 'multipleChoiceQuestion',
    fieldLabel: 'Reason not providing your TIN',
    name: 'noTINReason',
    showWhen: [
      { key: 'hasAustraliaTax', value: false },
      { key: 'isTINAvailable', value: false },
    ],
    options: [
      {
        label:
          'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
        key: 'answer-no-1',
        value: '1',
        displayValue:
          'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
      },
      {
        label:
          'The entity is otherwise unable to obtain a TIN or equivalent number.',
        value: '2',
        key: 'answer-no-2',
        displayValue: values?.unableObtainTinReason,
      },
      {
        label: 'No TIN is required',
        value: '3',
        key: 'answer-no-3',
        displayValue: 'No TIN is required',
      },
    ],
  },
];

const CompanyTaxSupportSummaryFields = (values) => [
  // Question 1-3
  ...SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION,
  ...SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_NO_GIIN(
    values?.hasNoGIINValues,
  ),
  // Question 4
  ...SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_QUESTION_4,
  // Question 5
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Are you an active Non-Financial Entity (NFE)?',
    name: 'isNFE',
    showWhen: [
      {
        key: 'isManagedByAnotherInstitution',
        value: (isManagedByAnotherInstitution) =>
          isManagedByAnotherInstitution !== undefined,
      },
    ],
    required: true,
  },
  {
    fieldComponent: 'multipleChoiceQuestion',
    fieldLabel: 'Active Non-Financial Entity (NFE)',
    name: 'isNFEValues.question',
    showWhen: [
      {
        key: 'isManagedByAnotherInstitution',
        value: (isManagedByAnotherInstitution, v) =>
          isManagedByAnotherInstitution !== undefined && !!v.isNFE,
      },
    ],
    fastField: false,
    options: [
      {
        label:
          'Less than 50% of the active NFE’s gross income from the preceding calendar year is passive income and less than 50% of its assets during the preceding calendar year are assets held for the production of passive income.',
        key: 'answer-no-1',
        value: '1',
        displayValue:
          'Less than 50% of the active NFE’s gross income from the preceding calendar year is passive income and less than 50% of its assets during the preceding calendar year are assets held for the production of passive income.',
      },
      {
        label:
          'Corporation that is regularly traded or a related entity of a regularly traded corporation.',
        key: 'answer-no-2',
        value: '2',
        displayValue:
          'Corporation that is regularly traded or a related entity of a regularly traded corporation.',
      },
      {
        label:
          'Governmental Entity, International Organization or Central Bank.',
        key: 'answer-no-3',
        value: '3',
        displayValue:
          'Governmental Entity, International Organization or Central Bank.',
      },
      {
        label: 'Other',
        value: '4',
        key: 'answer-no-4',
        displayValue: values?.isNFEValues?.otherReason,
      },
    ],
  },
  // Question 6
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: (
      <Grid>
        <Typography>Does one or more of the following apply to you?</Typography>
        <ul>
          <li>
            Is any natural person that exercises control over you (for
            corporations, this would include directors or beneficial owners who
            ultimately own 25% or more of the share capital) a tax resident of
            any country outside Australia?
          </li>
          <br />
          <li>
            If you are a trust, is any natural person including trustee,
            protector, beneficiary, settlor or any other natural person
            exercising ultimate effective control over the trust a tax resident
            of any country outside of Australia?
          </li>
        </ul>
      </Grid>
    ),
    name: 'isControllingPersonANaturalOrTrust',
    showWhen: [{ key: 'isNFE', value: false }],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Name of the controlling person',
    placeholder: "Person's name",
    name: 'controllingPersonFields.name',
    showWhen: [
      {
        key: 'isNFE',
        value: false,
      },
    ],
  },
  {
    fieldComponent: 'datePicker',
    fieldLabel: 'Date of birth',
    placeholder: "Person's date of birth",
    name: 'controllingPersonFields.dateOfBirth',
    showWhen: [
      {
        key: 'isNFE',
        value: false,
      },
    ],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Address',
    placeholder: "Person's address",
    name: 'controllingPersonFields.address',
    showWhen: [
      {
        key: 'isNFE',
        value: false,
      },
    ],
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Country for tax purposes',
    placeholder: "Person's country",
    name: 'controllingPersonFields.country',
    options: COUNTRY_OPTIONS,
    isBoldOptionLabel: true,
    showWhen: [
      {
        key: 'isNFE',
        value: false,
      },
    ],
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Other country TIN or equivalent',
    name: 'isTINAvailable',
    required: true,
    showWhen: [{ key: 'isNFE', value: false }],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'TIN (Tax Index Number*)',
    name: 'otherCountryTIN',
    showWhen: [
      {
        key: 'isTINAvailable',
        value: true,
      },
    ],
  },
  {
    fieldComponent: 'multipleChoiceQuestion',
    fieldLabel: 'Reason not providing your TIN',
    name: 'noTINReason',
    showWhen: [
      {
        key: 'isNFE',
        value: false,
      },
      {
        key: 'isTINAvailable',
        value: false,
      },
    ],
    options: [
      {
        label:
          'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
        key: 'answer-no-1',
        value: '1',
        displayValue:
          'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
      },
      {
        label:
          'The entity is otherwise unable to obtain a TIN or equivalent number.',
        value: '2',
        key: 'answer-no-2',
        displayValue: values?.unableObtainTinReason,
      },
      {
        label: 'No TIN is required',
        value: '3',
        key: 'answer-no-3',
        displayValue: 'No TIN is required',
      },
    ],
  },
];

const mapTaxDetailResponse = (taxDetail = {}) => {
  const { label, name = '', id, ...otherDetail } = taxDetail;
  const [firstName, middleName, lastName] = name.split(' ');
  const names = {
    firstName,
    lastName: lastName || middleName,
    middleName: lastName ? middleName : '',
  };
  return {
    id,
    label,
    isJoint: label === 'Joint',
    ...names,
    ...mapPayloadToTaxDetails(otherDetail),
  };
};

const splitTaxDetailAndSupportInformation = (taxDetail) => {
  const taxDetailToMap = [...taxDetail];
  const supportingInformation = taxDetailToMap.pop();

  const taxDetails = taxDetailToMap.map(mapTaxDetailResponse);

  return { taxDetails, supportingInformation };
};

const mapBankDetail = ({ bankDetail = {} } = {}) => ({
  ...bankDetail,
  bsb: formatMaskedValue({
    value: bankDetail?.bsb,
    mask: '-',
    maskInterval: 3,
    isNumberType: true,
  }),
});

const mapResponseToIndividualDetail = (values) => {
  const { detail, ...otherValues } = values;
  const personalDetail = detail?.personalDetail?.map(
    ResponseMappers.personalDetail,
  );
  const taxDetail = detail?.taxDetail?.map(mapTaxDetailResponse);
  return {
    ...otherValues,
    detail: {
      bankDetail: mapBankDetail({ bankDetail: detail?.bankDetail }),
      personalDetail,
      taxDetail,
    },
  };
};
const mapCompanyIdentifierResponse = (country, companyIdentifier) => {
  if (country === 'AU') {
    return { australianTaxNumber: companyIdentifier };
  }
  if (country === 'NZ') {
    return { newZealandTaxNumber: companyIdentifier };
  }
  return { otherTaxNumber: companyIdentifier };
};
const mapCompanyDetailResponse = (detail) => {
  const {
    registeredCountry,
    companyIdentifier,
    isCharity,
    address1,
    address2,
    postcode,
    country,
    accountantCertificate,
    ...otherDetail
  } = detail;

  return {
    ...otherDetail,
    addressCountry: country,
    country: registeredCountry,
    ...mapCompanyIdentifierResponse(registeredCountry, companyIdentifier),
    isNonProfitable: isCharity,
    firstAddress: address1,
    secondAddress: address2,
    postCode: postcode,
    certificates: accountantCertificate?.map((certificateName) => ({
      fileName: certificateName,
    })),
  };
};

const mapResponseToCompanyDetail = (values) => {
  const { detail, ...otherValues } = values;
  const companyDetail = mapCompanyDetailResponse(detail.companyDetail);
  const beneficialOwners = detail?.companyBeneficialOwner?.map(
    ResponseMappers.personalDetail,
  );

  const { taxDetails, supportingInformation } =
    splitTaxDetailAndSupportInformation(detail.taxDetail);

  return {
    ...otherValues,
    detail: {
      companyDetail,
      bankDetail: mapBankDetail({ bankDetail: detail?.bankDetail }),
      beneficialOwners,
      taxDetails,
      supportingInformation,
    },
  };
};

const mapResponseToTrustDetail = (values) => {
  const { detail, ...otherValues } = values;

  if (!detail) return {};

  const trustDetail = {
    trustType: detail.trustType,
    fullName: values.name,
    country: values.country,
    ABN: detail.businessIdentifier,
  };

  const trustees = detail.trustees.map((e) => {
    if (e.label === 'Company') return mapCompanyDetailResponse(e);
    return ResponseMappers.personalDetail(e);
  });
  const trustBeneficialOwner = detail?.trustBeneficialOwner?.map(
    ResponseMappers.personalDetail,
  );

  const { taxDetails, supportingInformation } =
    splitTaxDetailAndSupportInformation(detail.taxDetail);

  return {
    ...otherValues,
    detail: {
      ...detail,
      trustees,
      bankDetail: mapBankDetail({ bankDetail: detail?.bankDetail }),
      trustBeneficialOwner,
      taxDetails,
      supportingInformation,
      trustDetail,
    },
  };
};

const mapResponseToDetail = (values) => {
  const { type = '' } = values;

  switch (type?.toLowerCase()) {
    case 'individual': {
      return mapResponseToIndividualDetail(values);
    }
    case 'company': {
      return mapResponseToCompanyDetail(values);
    }
    case 'trust': {
      return mapResponseToTrustDetail(values);
    }
    default: {
      return values;
    }
  }
};

const fetchEntityList = async () => {
  try {
    const response = await Services.getInvestmentEntityList();
    return response?.data?.data;
  } catch (_) {
    // handle error here
    return [];
  }
};

const TaxDetailSummary = ({
  values,
  withEdit,
  setValues,
  withTitle = true,
}) => (
  <SummarySection
    title={withTitle && (values?.investorName || values?.label)}
    fields={CompanyTaxDetailSummaryFields(values)}
    values={values}
    withEdit={withEdit}
    setValues={setValues}
    sectionListView
  />
);
const TaxSupportSummary = ({
  values,
  withEdit,
  setValues,
  withTitle = true,
}) => (
  <SummarySection
    title={withTitle && 'Supporting Tax Details'}
    fields={CompanyTaxSupportSummaryFields(values)}
    values={values}
    withEdit={withEdit}
    setValues={setValues}
    sectionListView
  />
);

export {
  CompanyTaxDetailSummaryFields,
  CompanyTaxSupportSummaryFields,
  fetchEntityList,
  mapResponseToIndividualDetail,
  splitTaxDetailAndSupportInformation,
  mapResponseToCompanyDetail,
  mapResponseToDetail,
  TaxDetailSummary,
  TaxSupportSummary,
};
