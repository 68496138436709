import Services from 'api/services';
import { FUND_STATUS } from 'constants/funds';

export const fetchAdminFunds = async (query) => {
  const res = await Services.adminGetFunds({
    ...query,
    status: FUND_STATUS.PUBLISHED,
  });

  return res;
};

export const reportingHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    width: 75,
    cellClassName: 'cellBold',
  },
  {
    field: 'fundName',
    headerName: 'Fund Name',
    flex: 1,
    cellClassName: 'cellBold',
  },
];

export const getFundTotalHeader = (totalValue) => [
  { totalLabel: 'Total Funds', totalValue },
];
