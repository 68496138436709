import * as yup from 'yup';

import individualFormValidationSchema from './individualFormValidationSchema';
import addressValidationSchema from './addressValidationSchema';

const nzDriverLicenseValidationSchema = yup.object({
  driversLicenceNumber: yup.string().required().label('Driver License Number'),
  driversLicenceVersion: yup
    .string()
    .required()
    .label('Driver License Version'),
  ...individualFormValidationSchema(),
  ...addressValidationSchema(),
});

export default nzDriverLicenseValidationSchema;
