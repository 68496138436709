import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';
import AutoPopulateChipList from 'components/AutoPopulateChipList';
import ShowMe from 'components/ShowMe';
import LoadingSpinner from 'components/LoadingSpinner';

import condTrueFalse from 'utils/condTrueFalse';
import lazyLoad from 'utils/lazyLoad';
import mapDraftedAutoPopulatePersonalDetails from 'utils/mapDraftedAutoPopulatePersonalDetails';
import usePrevious from 'hooks/usePrevious';
import useSteps from 'hooks/useSteps';

import { ReactComponent as CompanyTrustIcon } from 'assets/icons/company-trust.svg';
import { ReactComponent as CompanyPerson } from 'assets/icons/company-person.svg';
import PersonIcon from 'assets/images/person-trust.png';

import trusteesCompanyValidationSchemas from 'validations/schema/addInvestment/trusteesCompanyValidationSchemas';
import trusteesIndividualValidationSchemas from 'validations/schema/addInvestment/trusteesIndividualValidationSchemas';
import trusteeListSummaryValidationSchemas from 'validations/schema/addInvestment/trusteeListSummaryValidationSchemas';

import TrusteeDetailsSummary from './TrusteeDetailsSummary';

const CompanyDetailsForm = lazyLoad(() =>
  import('../../../components/Forms/CompanyDetailsForm'),
);
const AddressForm = lazyLoad(() =>
  import('../../../components/Forms/AddressForm'),
);
const AccountantCertificateForm = lazyLoad(() =>
  import('../../../components/Forms/AccountantCertificateForm'),
);
const InvestorIdentificationForm = lazyLoad(() =>
  import('../../../components/Forms/InvestorIdentificationForm'),
);
const PersonalDetailsForm = lazyLoad(() =>
  import('../../../components/Forms/PersonalDetailsForm'),
);
const EmptyStep = lazyLoad(() => import('./Steps/EmptyStep'));
const TrusteeList = lazyLoad(() => import('./Steps/TrusteeList'));

const trusteeIndividualStepComponents = [
  <PersonalDetailsForm isJoint />,
  <AddressForm />,
  <InvestorIdentificationForm />,
  <AccountantCertificateForm />,
];

const trusteeCompanyStepComponents = [
  <CompanyDetailsForm />,
  <AddressForm />,
  <AccountantCertificateForm />,
  <EmptyStep stepIndex={3} />,
];

const trusteeListSummaryStepComponents = [
  <TrusteeList />,
  <TrusteeDetailsSummary />,
];

const trusteeIndividualStepTitles = [
  'Trustee Individual Details',
  'Trustee Individual Address',
  'Identification of Individual Trustee',
  'Individual Trustee’s Accountant Certificate',
];

const trusteeCompanyStepTitles = [
  'Company Trustee Details',
  'Trustee Company Registered Address',
  'Company Trustee Accountant’s Certificate',
  'Empty Step',
];
const trusteeListSummaryStepTitles = ['Trustee', 'Summary'];

const trusteeListStepIndex = 4;
const trusteeSummaryStepIndex = 5;

const TrusteesDetails = ({
  onNext,
  onBack,
  startAtLastStep,
  steps,
  setValues,
  values,
  TrustContext,
  TrustActionContext,
  userDetailList,
}) => {
  const [trusteeType, setTrusteeType] = useState(values?.trusteeType);
  const [loading, setLoading] = useState(false);
  const [selectedPersonalDetailIndex, setSelectedPersonalDetailIndex] =
    useState({ personals: '', addresses: '' });

  const handleBackFirstPage = useCallback(
    ({
      values: trusteeValues,
      setFormValues,
      handleSetPageIndex,
      stepComponentsLength,
      confirmToDiscardTrustee,
    }) => {
      // Back to TrusteeList Form Page when Editing.
      if (handleSetPageIndex && trusteeValues.isEditingTrustee) {
        // Revert Active Trustee's value to it's original value.
        setFormValues({
          ...trusteeValues,
          ...trusteeValues.trustees[trusteeValues.activeTrustee],
        });

        // Move back to Trustee List Step Page.
        handleSetPageIndex(stepComponentsLength - 2);

        return;
      }

      const newValues = {
        ...values,
        ...trusteeValues,
      };

      if (newValues?.addingNewTrustee) {
        setSelectedPersonalDetailIndex({ personals: '', addresses: '' });
      }

      condTrueFalse(setValues, () =>
        setValues({
          ...newValues,
          addingNewTrustee: condTrueFalse(
            confirmToDiscardTrustee,
            false,
            !!newValues?.addingNewTrustee,
          ),
        }),
      );

      onBack();
    },
    [values],
  );

  const handleNextLastPage = ({ values: trusteeValues }) => {
    if (setValues)
      setValues({
        ...values,
        ...trusteeValues,
      });
    onNext();
  };

  const trusteeSteps = useMemo(() => {
    let newTrusteeType = trusteeType;
    let initialValues = {};
    const isCancellingNewTrustee = values?.addingNewTrustee && !newTrusteeType;

    if (isCancellingNewTrustee && values?.trustees?.length) {
      const trusteeIdx = values.trustees.length - 1;
      const lastTrustee = values.trustees[trusteeIdx];
      newTrusteeType = lastTrustee?.type;
      initialValues = { activeTrustee: trusteeIdx };
    }

    let components = trusteeIndividualStepComponents;
    let titles = trusteeIndividualStepTitles;
    let validationSchemas = trusteesIndividualValidationSchemas;

    if (newTrusteeType === 'company') {
      components = trusteeCompanyStepComponents;
      titles = trusteeCompanyStepTitles;
      validationSchemas = trusteesCompanyValidationSchemas;
    }

    return {
      components: [...components, ...trusteeListSummaryStepComponents],
      titles: [...titles, ...trusteeListSummaryStepTitles],
      validationSchemas: [
        ...validationSchemas,
        ...trusteeListSummaryValidationSchemas,
      ],

      // Assign Trustee List Page as Initial Page,
      // When user abort Creating New Trustee.
      initialPageIndex: condTrueFalse(
        isCancellingNewTrustee,
        components.length,
        0,
      ),
      initialValues,
    };
  }, [trusteeType]);

  const {
    handleBack,
    handleNext,
    pageIndex,
    setPageIndex,
    form: trusteeForm,
  } = useSteps({
    stepComponents: trusteeSteps.components,
    validateFormik: true,
    validationSchemas: trusteeSteps.validationSchemas,
    formParameters: {
      initialValues: {
        type: trusteeType,
        // companyDetails
        country: '',
        // address
        state: '',
        addressCountry: '',
        // accountantCertificate
        certificates: [],

        // individual
        title: '',
        dateOfBirth: null,
        idType: '',

        // trustee list
        trustees: values?.trustees,
        isConfirmedNoOtherTrustee: false,
        activeTrustee: null,
        addingNewTrustee: values?.addingNewTrustee,

        ...trusteeSteps.initialValues,
      },
    },
    onNextInLastPage: handleNextLastPage,
    onBackInFirstPage: handleBackFirstPage,
    defaultPageIndex: condTrueFalse(
      trusteeSteps.initialPageIndex,
      trusteeSteps.initialPageIndex,
      condTrueFalse(startAtLastStep, trusteeSteps.components.length - 1, 0),
    ),
  });
  const prevPageIndex = usePrevious(pageIndex);

  useEffect(() => {
    // Forcefully move to Trustee List page.
    condTrueFalse(!values.addingNewTrustee && values.trustees.length, () =>
      setPageIndex(trusteeListStepIndex),
    );
  }, []);

  useEffect(() => {
    const { activeTrustee } = trusteeForm.values;
    if (activeTrustee === null || !trusteeForm.values.trustees.length) return;

    const activeTrusteeValue = trusteeForm.values.trustees[activeTrustee];

    // Re-populate form with trustee value when active trustee is changed.
    trusteeForm.setValues({
      ...trusteeForm.values,
      ...activeTrusteeValue,
      activeTrustee,
    });

    // Update TrusteeType State, when Active Trustee's type is different.
    condTrueFalse(
      activeTrusteeValue.type && trusteeType !== activeTrusteeValue.type,
      () => setTrusteeType(activeTrusteeValue.type),
    );
  }, [trusteeForm.values.activeTrustee, trusteeForm.values.trustees]);

  const isOnTrusteeListStep = useMemo(
    () => pageIndex === trusteeListStepIndex,
    [pageIndex],
  );

  useEffect(() => {
    trusteeForm?.validateForm();

    // Reset 'addingNewTrustee' value if True.
    condTrueFalse(
      isOnTrusteeListStep && values.addingNewTrustee,
      setValues({
        ...values,
        addingNewTrustee: false,
      }),
    );
  }, [pageIndex, trusteeForm?.validateForm]);

  const SideIcon = useMemo(() => {
    switch (true) {
      case pageIndex === trusteeListStepIndex:
      case pageIndex === trusteeSummaryStepIndex:
        return CompanyPerson;
      case trusteeType === 'company':
        return CompanyTrustIcon;
      default:
        return PersonIcon;
    }
  }, [pageIndex, trusteeType]);

  const handleEditTrustee = useCallback(
    (activeTrustee) => () => {
      if (activeTrustee !== trusteeForm.values.activeTrustee) {
        // Change Active Trustee if different.
        trusteeForm.setFieldValue('activeTrustee', activeTrustee);
      }

      trusteeForm.setFieldValue('isEditingTrustee', true);

      // Set Selected Personal Detail based on active editing Trustee
      setSelectedPersonalDetailIndex((prevSelected) => ({
        ...prevSelected,
        personals:
          trusteeForm.values.trustees[activeTrustee]?.selectedpersonals,
        addresses:
          trusteeForm.values.trustees[activeTrustee]?.selectedaddresses,
      }));

      // Open First Step.
      setPageIndex(0);
    },
    [trusteeForm.values.trustees],
  );

  const handleStepBack = useCallback(() => {
    // Back to first step, if trustees is empty.
    if (isOnTrusteeListStep && !trusteeForm.values.trustees.length) {
      handleBackFirstPage({
        values: trusteeForm.values,
        confirmToDiscardTrustee: true,
      });

      return;
    }

    handleBack();
  }, [pageIndex, trusteeForm.values.trustees]);

  const handleSelectAutoPopulateUser = ({ index, recordedData, stepKey }) => {
    setLoading(true);
    trusteeForm.setValues({
      ...trusteeForm.values,
      ...recordedData[index],
      [`selected${stepKey}`]: index,
    });
    // trusteeForm.setFieldValue('countryCode', recordedData[index]?.countryCode);

    setSelectedPersonalDetailIndex((prevSelected) => ({
      ...prevSelected,
      [stepKey]: index,
    }));

    // Delay loading to reset form component.
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const headChildren = useMemo(() => {
    let personalsPageIndex = 0;
    const addresesPageIndex = 1;
    if (trusteeType !== 'individual') {
      // Hide Personals Autopopulate when trustee type not individual.
      personalsPageIndex = -1;
    }

    const savedTrustee = mapDraftedAutoPopulatePersonalDetails(values.trustees);

    const { recordedData, stepKey } = (() => {
      switch (pageIndex) {
        case personalsPageIndex:
          return {
            recordedData: userDetailList.personals.concat(
              savedTrustee.personals,
            ),
            stepKey: 'personals',
          };
        case addresesPageIndex:
          return {
            recordedData: userDetailList.addresses.concat(
              savedTrustee.addresses,
            ),
            stepKey: 'addresses',
          };
        default:
          return { recordedData: null };
      }
    })();

    return (
      <ShowMe when={recordedData}>
        <AutoPopulateChipList
          items={recordedData}
          onClick={(index) =>
            handleSelectAutoPopulateUser({ index, recordedData, stepKey })
          }
          selectedIndex={selectedPersonalDetailIndex[stepKey]}
        />
      </ShowMe>
    );
  }, [trusteeType, selectedPersonalDetailIndex, userDetailList, pageIndex]);

  return (
    <InvestmentEntityStepContainer
      title="Trustee Details"
      containerProps={{
        onBack: handleStepBack,
        onNext: handleNext,
        isNextButtonDisabled: !!Object.keys(trusteeForm?.errors).length,
      }}
      SideIcon={SideIcon}
      steps={steps}
      activeStep={1}
      stepTitle={trusteeSteps.titles[pageIndex]}
      headChildren={headChildren}
    >
      {loading ? (
        <LoadingSpinner loading />
      ) : (
        React.cloneElement(trusteeSteps.components[pageIndex], {
          errors: trusteeForm.errors,
          values: trusteeForm.values,
          touched: trusteeForm.touched,
          onChange: trusteeForm.handleChange,
          onBlur: trusteeForm.handleBlur,
          setFieldError: trusteeForm.setFieldError,
          setFieldValue: trusteeForm.setFieldValue,
          setValues: trusteeForm.setValues,
          onAddAnotherTrustee: handleBackFirstPage,
          onEditTrustee: handleEditTrustee,
          setPageIndex,
          prevPageIndex,
          trusteeForm,
          trusteeInitialValues: trusteeSteps.initialValues,
          TrustContext,
          TrustActionContext,
        })
      )}
    </InvestmentEntityStepContainer>
  );
};

export default memo(TrusteesDetails);
