import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import TextButton from 'components/Button/TextButton';
import ShowMe from 'components/ShowMe';

const SubmitCancel = ({
  onCancelClick,
  onSubmitClick,
  cancelDisabled,
  submitDisabled,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
  containerProps = {},
  cancelBtnProps = {},
  submitBtnProps = {},
}) => (
  <Grid container justifyContent="space-between" {...containerProps}>
    <Grid item mr={8} display="flex" alignItems="center">
      <ShowMe when={onCancelClick}>
        <TextButton
          data-testid="button-cancel"
          sx={{
            maxHeight: '19px',
            height: '100%',
          }}
          disabled={cancelDisabled}
          onClick={onCancelClick}
          variant="bodyGrey"
          {...cancelBtnProps}
        >
          {cancelLabel}
        </TextButton>
      </ShowMe>
    </Grid>
    <Grid item>
      <Button
        data-testid="button-save"
        buttonstyles={{
          maxHeight: '19px',
        }}
        onClick={onSubmitClick}
        disabled={submitDisabled}
        type="submit"
        {...submitBtnProps}
      >
        <Typography variant="bodyGrey" color="white">
          {submitLabel}
        </Typography>
      </Button>
    </Grid>
  </Grid>
);

export default SubmitCancel;
