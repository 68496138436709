import React, { useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';

import ShowMe from 'components/ShowMe';
import Dialog from 'components/Dialog';
import SubmitCancel from 'components/Dialog/Actions/SubmitCancel';

import Form from './Form';

const FundPerformanceDialog = ({
  open,
  onClose,
  onSubmit,
  reportingTemplate,
  errorText,
  disableSubmitBtn,
  selectedFundPerformance,
}) => {
  const [isError, setIsError] = useState(true);

  const dialogContent = useMemo(
    () =>
      open ? (
        <>
          <Form
            formId="fund-performance-form"
            onSubmit={onSubmit}
            setIsError={setIsError}
            reportingTemplate={reportingTemplate}
            selectedFundPerformance={selectedFundPerformance}
          />
          <ShowMe when={!!errorText}>
            <Typography variant="body" color="red" mt={3} textAlign="center">
              {errorText}
            </Typography>
          </ShowMe>
        </>
      ) : null,
    [open, reportingTemplate, selectedFundPerformance, onSubmit, errorText],
  );

  const dialogActions = useMemo(
    () => (
      <SubmitCancel
        submitLabel="Save"
        submitBtnProps={{
          form: 'fund-performance-form',
          type: 'submit',
        }}
        submitDisabled={isError || disableSubmitBtn}
      />
    ),
    [onSubmit, isError, disableSubmitBtn],
  );

  return (
    <Dialog
      dataTestKey="fund-performance"
      maxWidth="lg"
      open={open}
      onClose={onClose}
      content={dialogContent}
      dialogActions={dialogActions}
    />
  );
};

export default FundPerformanceDialog;
