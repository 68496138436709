import React, { memo } from 'react';
import Grid from '@mui/material/Grid';

import ShowMe from 'components/ShowMe';

import SelectEntityForm from './SelectEntityForm';
import PortfolioList from './PortfolioList';

const SelectEntity = ({ values, setValues }) => (
  <Grid container item xs={12} rowSpacing={8} justifyContent="center">
    <Grid item xs={12}>
      <SelectEntityForm values={values} setValues={setValues} />
    </Grid>
    <Grid item xs={12}>
      <ShowMe when={!!values.investmentEntity}>
        <PortfolioList
          investmentEntityId={values.investmentEntity}
          values={values}
          setValues={setValues}
        />
      </ShowMe>
    </Grid>
  </Grid>
);

export default memo(SelectEntity);
