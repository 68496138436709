import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import HistoricalValueList from './HistoricalValueList';
import MultiplesChart from './MultiplesChart';
import Notes from './Notes';

const MultiplesTab = ({ fundPerformances = [] }) => {
  const chartData = useMemo(
    () => fundPerformances.slice(-2),
    [fundPerformances],
  );

  const fundPerformancesData = useMemo(
    () => [...fundPerformances].reverse(),
    [fundPerformances],
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <MultiplesChart data={chartData} />
      </Grid>
      <Grid item xs={12}>
        <Notes />
      </Grid>
      <Grid item xs={12}>
        <HistoricalValueList data={fundPerformancesData} />
      </Grid>
    </Grid>
  );
};

export default memo(MultiplesTab);
