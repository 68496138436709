import services from 'api/services';

import condTrueFalse from 'utils/condTrueFalse';
import getFileFormat from 'utils/strings/getFileFormat';
import PayloadMappers from 'utils/PayloadMappers';
import segmentTrack from 'hooks/useSegmentAnalytic/segmentTrack';

import { TAX_CATEGORY } from 'constants/investmentEntity';
import { SEGMENT_ANALYTICS } from 'constants/segmentAnalytics';

// Temporary disable prefer default export.
// eslint-disable-next-line
export const handleAddTrustEntity = async ({
  formValues,
  cb,
  setLoading,
  userEmail,
}) => {
  const {
    trustProfile,
    trustees,
    beneficialOwners,
    taxDetails,
    bankDetails,
    userId,
  } = formValues;

  try {
    const payload = {
      trustDetails: PayloadMappers.trustDetails(trustProfile),
      trusteeDetails: PayloadMappers.trustees(trustees),
      taxDetails: PayloadMappers.taxDetailPurposes({
        taxDetails,
        ...taxDetails,
        taxCategory: TAX_CATEGORY.TRUST,
      }),
      bankDetails: PayloadMappers.bankDetails(bankDetails),
      beneficialOwners: PayloadMappers.beneficialOwners(beneficialOwners),
    };

    const formData = new FormData();
    formData.append('content', JSON.stringify(payload));

    // TrustProfile Certificates.
    trustProfile.trustProfileDocuments.forEach((certificate) => {
      formData.append('trustCertificate', certificate.file);
    });

    // Trustees Certificates.
    trustees.forEach((trustee, trusteeIdx) => {
      trustee.certificates.forEach((certificate, index) => {
        const idNumber =
          payload.trusteeDetails[trusteeIdx]?.idNumber ||
          payload.trusteeDetails[trusteeIdx]?.companyIdentifier;
        const fileName = `${
          payload.trusteeDetails[trusteeIdx].trusteeType
        }-${idNumber}-${index + 1}.${getFileFormat(certificate.fileName)}`;
        formData.append('accountantCertificate', certificate.file, fileName);
      });
    });

    await services.addInvestmentEntityTrust({
      payload: formData,
      queryParams: { userId },
    });

    segmentTrack(SEGMENT_ANALYTICS.SUBMIT_INVESTMENT_ENTITY, {
      investmentEntityName: trustProfile?.fullName,
      investmentEntityType: 'Trust',
      email: userEmail,
    });
  } finally {
    condTrueFalse(setLoading, () => setLoading(false));

    condTrueFalse(cb, cb);
  }
};
