import * as yup from 'yup';
import passwordSchema from 'validations/password';
import emailSchema from 'validations/email';
import profileDetailSchema from 'validations/profileDetail';
import phoneNumberSchema from 'validations/phoneNumberSchemas';

// remove unused field validations
delete emailSchema.emailConfirmation;
delete phoneNumberSchema.otp;

const addEditUserSchemas = yup.object({
  ...emailSchema,
  ...profileDetailSchema,
  ...phoneNumberSchema,
  password: yup
    .string()
    .when('isEdit', {
      is: false,
      then: passwordSchema.password,
    })
    .nullable(),
  role: yup.string().required().label('Role'),
  status: yup.string().required().label('Status'),
});

export default addEditUserSchemas;
