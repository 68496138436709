import user from './user';
import fund from './fund';
import fundDetails from './fundDetails';
import article from './article';
import event from './event';
import video from './video';
import fundUpdates from './fundUpdates';
import fundPerformance from './fundPerformance';

export default {
  user,
  fund,
  fundDetails,
  article,
  event,
  video,
  fundUpdates,
  fundPerformance,
};
