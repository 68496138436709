import React, { memo } from 'react';
import Grid from '@mui/material/Grid';

import InvestmentEntityContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityContainer';

import Button from 'components/Button';
import BackButton from 'components/Button/BackButton';

import SelectEntityForm from './SelectEntityForm';

const SelectEntity = ({ onNext, onBack, values, setValues }) => (
  <InvestmentEntityContainer pt={8} withHeader={false} paddingx={0}>
    <Grid container item xs={12} rowSpacing={8} justifyContent="center">
      <Grid item xs={12}>
        <SelectEntityForm values={values} setValues={setValues} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <BackButton
            onClick={onBack}
            label="Cancel"
            size="large"
            data-testid="button-back"
          />
        </Grid>
        <Grid item xs={6} sm="auto">
          <Button
            buttoncolor="purpleHazeGradient"
            onClick={onNext}
            data-testid="button-next"
            disabled={!values.investmentEntity}
          >
            Let&apos;s go
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </InvestmentEntityContainer>
);

export default memo(SelectEntity);
