import { PAYMENT_OPTIONS } from 'constants/investmentEntity';
import formatCurrency from 'utils/formatCurrency';
import thousandSeparator from 'utils/strings/thousandSeparators';
import condTrueFalse from 'utils/condTrueFalse';

export const PORTFOLIO_PAYMENT_DETAILS_FIELD_DISPLAY = [
  {
    label: 'Fund name',
    name: 'portfolio',
    type: 'text',
    renderer: (portfolio) => portfolio?.fund?.fundName,
    xs: 12,
    md: 3,
  },
  {
    label: 'Entity',
    name: 'name',
    type: 'text',
    xs: 12,
    md: 3,
  },
  {
    label: 'Amount',
    name: 'portfolio',
    type: 'text',
    renderer: (portfolio) =>
      formatCurrency({
        value: Number(portfolio?.committedAmount),
        currency: portfolio?.currency,
      }),
    xs: 12,
    md: 2,
  },
  {
    label: 'Units',
    name: 'portfolio',
    type: 'text',
    renderer: (portfolio) => thousandSeparator(Number(portfolio?.unit)),
    xs: 12,
    md: 2,
  },
  {
    label: 'Status',
    name: 'status',
    type: 'chip',
    xs: 12,
    md: 2,
  },
];
export const FUND_REDEMPTION_REQUEST_DETAILS_FIELD_DISPLAY = [
  {
    label: 'Fund name',
    name: 'portfolio',
    type: 'text',
    renderer: (portfolio) => portfolio?.fund?.fundName,
    xs: 12,
    md: 3,
  },
  {
    label: 'Class',
    name: 'portfolio',
    type: 'text',
    renderer: (portfolio) => portfolio?.fundConfiguration?.className,
    xs: 12,
    md: 2,
  },
  {
    label: 'Entity',
    name: 'name',
    type: 'text',
    xs: 12,
    md: 3,
  },
  {
    label: 'Units',
    name: 'redemptionUnitAmount',
    type: 'text',
    renderer: (unit) => thousandSeparator(Number(unit)),
    xs: 12,
    md: 2,
  },
  {
    label: 'Status',
    name: 'status',
    type: 'chip',
    xs: 12,
    md: 2,
  },
];

export const PORTFOLIO_CUSTODIAL_DETAILS_FIELD_DISPLAY = [
  {
    label: 'Amount to transfer (payable today)',
    name: 'totalPayable',
    type: 'text',
    renderer: (value, investment) =>
      formatCurrency({
        value: Number(
          condTrueFalse(
            investment?.portfolio?.paymentType ===
              PAYMENT_OPTIONS.PAY_IN_INSTALLMENT,
            investment?.portfolio?.installmentTotalPayable,
            value,
          ),
        ),
        currency: investment?.portfolio?.currency,
      }),
    xs: 12,
    md: 6,
  },
  {
    label: 'Reference number (please include in the transfer details)',
    name: 'referenceNumber',
    type: 'text',
    xs: 12,
    md: 6,
  },
  {
    label: 'Bank account name',
    name: 'fund',
    type: 'text',
    renderer: (fund) => fund?.bankAccount?.bankName,
    xs: 12,
    md: 6,
  },
  {
    label: 'Bank address',
    name: 'fund',
    type: 'text',
    renderer: (fund) => fund?.bankAccount?.bankAddress,
    xs: 12,
    md: 6,
  },
  {
    label: 'BSB',
    name: 'fund',
    type: 'text',
    renderer: (fund) => fund?.bankAccount?.bsb,
    xs: 12,
    md: 6,
  },
  {
    label: 'Account number',
    name: 'fund',
    type: 'text',
    renderer: (fund) => fund?.bankAccount?.accountNumber,
    xs: 12,
    md: 6,
  },
];
