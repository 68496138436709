import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';

import DetailsPageContainer from 'components/DetailsPageContainer';
import TabPanels from 'components/TabPanels';
import LoadingSpinner from 'components/LoadingSpinner';
import Tabs from 'components/Tabs';

import history from 'utils/history';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useTabs from 'hooks/useTabs';
import useFetchApi from 'hooks/useFetchApi';

import { platformConfigurationTabItems } from './HelperFunction';

const PlatformConfiguration = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { haveCrudPermission } = useAdminRestriction();
  const { userId } = useParams();

  const { apiData: userData, isFetchLoading: userFetchLoading } = useFetchApi({
    // url: fetchDataUser,
  });

  const userDetailsForm = useFormik({
    initialValues: {
      userProfile: {},
      categories: [],
    },
  });

  const memoizedTabItems = useMemo(() => {
    const tabItems = platformConfigurationTabItems({
      setFieldValue: userDetailsForm.setFieldValue,
      readOnly: !haveCrudPermission,
      // disabled: isPreRegistered,
    });

    // tabItems[1].hidden = !userData?.statusVerification;

    return tabItems;
  }, [userId, userDetailsForm.values.userProfile, haveCrudPermission]);

  const defaultTabIdx = useMemo(() => {
    const index = memoizedTabItems.findIndex(
      (e) => !e.hidden && e.key === searchParams.get('tab'),
    );

    if (index === -1) return 0;

    return index;
  }, [memoizedTabItems]);

  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx,
    tabs: memoizedTabItems,
  });

  useEffect(() => {
    const currentTab = memoizedTabItems[activeTabIdx];

    const newSearchParams = {
      tab: currentTab?.key,
    };

    setSearchParams(newSearchParams);
  }, [activeTabIdx, memoizedTabItems]);

  const handleBack = useCallback(() => {
    history.push('/admin');
  }, []);

  return (
    <DetailsPageContainer
      onBackButtonClick={handleBack}
      onCancel={handleBack}
      backButtonLabel="Platform Configuration"
      backButtonSubLabel={memoizedTabItems[activeTabIdx].subLabel}
      status="live"
      footer
      downloadBtnDisabled={!haveCrudPermission}
      submitBtnDisabled={!haveCrudPermission}
      deleteBtnDisabled={!haveCrudPermission}
    >
      <Grid container item rowSpacing={4}>
        {userFetchLoading ? (
          <LoadingSpinner />
        ) : (
          <Grid container item xs={12} data-testid="userDetailsTabsContents">
            <Grid item xs={12}>
              <Tabs
                key="user-details-tabs"
                value={activeTabIdx}
                onChange={handleTabChange}
                centered={false}
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
              >
                <TabComponents
                  dependencyKey={userDetailsForm.values.userProfile?.userId}
                  activeTabIdx={activeTabIdx}
                  tabItems={memoizedTabItems}
                  testIdPrefix="user-details-tab"
                />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <TabPanels
                dependencyKey={userDetailsForm.values.userProfile?.userId}
                value={activeTabIdx}
                tabItems={memoizedTabItems}
                boxPanelProps={{ sx: { padding: 0, pt: 4 } }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </DetailsPageContainer>
  );
};

export default PlatformConfiguration;
