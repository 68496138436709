import countries from 'utils/countries.json';
import condTrueFalse from 'utils/condTrueFalse';
import { dateTimeCellRenderer } from 'utils/Datatables/cellRenderer';

import {
  COUNTRY_OPTIONS_WITH_PRESERVED,
  PRESERVED_COUNTRIES,
} from './phoneNumber';

export const TITLE_OPTIONS = [
  { label: 'Mr', value: 'MR' },
  { label: 'Ms', value: 'MS' },
  { label: 'Mrs', value: 'MRS' },
  { label: 'Miss', value: 'MISS' },
  { label: 'Dr', value: 'DR' },
  { label: 'Prof', value: 'PROF' },
];

export const COUNTRY_OPTIONS = COUNTRY_OPTIONS_WITH_PRESERVED.map((c) => ({
  label: c.label,
  value: c.code,
  ...(c.label === 'divider' ? { divider: true } : {}),
}));

export const COUNTRY_OPTIONS_ALPHA3 = countries.map((c) => ({
  label: c.label,
  value: c.alpha3Code,
  ...(c.label === 'divider' ? { divider: true } : {}),
}));

export const STATE_OPTIONS = [
  { label: 'NSW', value: 'NSW' },
  { label: 'VIC', value: 'VIC' },
  { label: 'QLD', value: 'QLD' },
  { label: 'WA', value: 'WA' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'ACT', value: 'ACT' },
  { label: 'NT', value: 'NT' },
  { label: 'Outside Australia', value: 'OUTSIDE' },
];
const stateOptions = [...STATE_OPTIONS].slice(0, -1);

export const ID_TYPE_DRIVER_LICENSE = 'DRIVER_LICENSE';
export const ID_TYPE_PASSPORT = 'PASSPORT';
export const ID_TYPE_OTHER = 'OTHER';

export const ID_TYPE_OPTIONS = [
  { label: "Driver's license", value: ID_TYPE_DRIVER_LICENSE },
  { label: 'Passport', value: ID_TYPE_PASSPORT },
  { label: 'Other ID', value: ID_TYPE_OTHER },
];

export const DOWNLOAD_CERTIFICATE_URL =
  'https://assets.website-files.com/629d7290c36b1b8cd675393f/62df62f5fa43e876965cff75_Qualified%20Investor%20Certificate.pdf';

export const ACCOUNTANT_CERTIFICATE_MAX_FILES = 2;
export const ACCOUNTANT_CERTIFICATE_MAX_UPLOAD_SIZE = 8; // in MB
export const ACCOUNTANT_CERTIFICATE_ACCEPTED_FILE = 'application/pdf'; // in MB

export const TAX_DETAILS_SUMMARY = [
  { key: 'hasAustraliaTax', title: 'Australia tax purpose?' },
  {
    key: 'australiaTax',
    title: 'Tax File Number (TFN)*',
    dependencies: [{ key: 'hasAustraliaTax', value: true }],
  },
  {
    key: 'hasOtherCountryTax',
    title: 'Residence other country tax purpose?',
  },
  {
    key: 'otherCountryTax',
    title: 'Other country name',
    dependencies: [{ key: 'hasOtherCountryTax', value: true }],
  },
  {
    key: 'isTINAvailable',
    title: 'Other country TIN or equivalent',
    dependencies: [{ key: 'hasOtherCountryTax', value: true }],
  },
  {
    key: 'noTINReason',
    title: 'Reason not providing your TIN',
    dependencies: [
      { key: 'hasOtherCountryTax', value: true },
      { key: 'isTINAvailable', value: false },
    ],
  },
];

export const COMPANY_TAX_DETAILS_SUMMARY = [
  { key: 'hasAustraliaTax', title: 'Australia tax purpose?' },
  {
    key: 'australiaTax',
    title: 'Tax File Number (TFN)*',
    dependencies: [{ key: 'hasAustraliaTax', value: true }],
  },
  {
    key: 'otherCountryTax',
    title: 'Other country name',
    dependencies: [{ key: 'hasAustraliaTax', value: false }],
  },
  {
    key: 'isTINAvailable',
    title: 'Other country TIN or equivalent',
    dependencies: [{ key: 'hasAustraliaTax', value: false }],
  },
  {
    key: 'noTINReason',
    title: 'Reason not providing your TIN',
    dependencies: [
      { key: 'hasAustraliaTax', value: false },
      { key: 'isTINAvailable', value: false },
    ],
  },
];

export const SUMMARY_FIELDS_PERSONAL_DETAILS = (isJoint = false) => [
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Title',
    name: 'title',
    required: true,
    options: TITLE_OPTIONS,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Legal first name',
    name: 'firstName',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Legal middle name',
    name: 'middleName',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Legal last name',
    name: 'lastName',
    required: true,
  },
  {
    fieldComponent: 'datePicker',
    fieldLabel: 'Date of Birth',
    name: 'dateOfBirth',
    required: true,
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Country of Citizenship',
    name: 'country',
    required: true,
    options: COUNTRY_OPTIONS,
  },
  {
    fieldComponent: 'phone',
    fieldLabel: 'Phone',
    name: 'phoneNumber',
    required: true,
    disabled: !isJoint,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Email',
    name: 'email',
    disabled: !isJoint,
    required: true,
  },
];

export const SUMMARY_FIELDS_ADDRESS = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Address line 1',
    name: 'firstAddress',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Address line 2',
    name: 'secondAddress',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Suburb',
    name: 'suburb',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'City',
    name: 'city',
    required: true,
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'State',
    name: 'state',
    required: true,
    options: STATE_OPTIONS,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Postcode',
    name: 'postCode',
    required: true,
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Country',
    name: 'addressCountry',
    required: true,
    options: COUNTRY_OPTIONS,
  },
];

export const SUMMARY_FIELDS_ADDRESS_VIEW = [
  {
    fieldLabel: 'Complete address',
    keys: [
      { key: 'firstAddress' },
      { key: 'secondAddress' },
      { key: 'suburb' },
      { key: 'city' },
      {
        key: 'state',
        options: STATE_OPTIONS,
      },
      { key: 'postCode' },
      {
        key: 'addressCountry',
        options: COUNTRY_OPTIONS,
      },
    ],
    isMultipleValue: true,
  },
];

export const SUMMARY_FIELDS_IDENTIFICATION = [
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'ID Type',
    name: 'idType',
    required: true,
    options: ID_TYPE_OPTIONS,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'ID Number',
    name: 'idNumber',
    required: true,
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'State of Issue',
    name: 'stateOfIssue',
    required: true,
    options: stateOptions,
    showWhen: [{ key: 'idType', value: ID_TYPE_DRIVER_LICENSE }],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Card Number',
    name: 'cardNumber',
    required: true,
    showWhen: [{ key: 'idType', value: ID_TYPE_DRIVER_LICENSE }],
  },
];

export const SUMMARY_FIELDS_ACCOUNTANT = [
  {
    fieldComponent: 'upload',
    fieldLabel: "Accountant's certificate",
    name: 'certificates',
    maxSize: ACCOUNTANT_CERTIFICATE_MAX_UPLOAD_SIZE,
    maxFileNameLength: 10,
  },
];

export const SUMMARY_FIELDS_COMPANY_DETAILS = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Registered company name',
    name: 'companyName',
    required: true,
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Country where the business is registered',
    name: 'country',
    required: true,
    options: COUNTRY_OPTIONS,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'ACN / ABN',
    name: 'australianTaxNumber',
    required: true,
    type: 'number',
    mask: '-',
    maskInterval: 3,
    showWhen: [{ key: 'country', value: 'AU' }],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'NZBN / NZCN',
    name: 'newZealandTaxNumber',
    required: true,
    type: 'number',
    mask: '-',
    maskInterval: 3,
    showWhen: [{ key: 'country', value: 'NZ' }],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Tax Number',
    name: 'otherTaxNumber',
    required: true,
    type: 'number',
    mask: '-',
    maskInterval: 3,
    showWhen: [
      {
        key: 'country',
        value: (country) => !PRESERVED_COUNTRIES.includes(country),
      },
    ],
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Are you a public company?',
    name: 'isPublicCompany',
    required: true,
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Is this a charity or not-for-profit?',
    name: 'isNonProfitable',
    required: true,
  },
];

export const SUMMARY_FIELDS_TAX_DETAILS = [
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Australia tax purpose',
    name: 'hasAustraliaTax',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Tax File Number (TFN)*',
    name: 'australiaTax',
    showWhen: [{ key: 'hasAustraliaTax', value: true }],
    type: 'number',
    mask: '-',
    maskInterval: 3,
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Residence other country tax purpose?',
    name: 'hasOtherCountryTax',
    showWhen: [{ key: 'hasAustraliaTax', value: true }],
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Other country name',
    name: 'otherCountryTax',
    options: COUNTRY_OPTIONS,
    showWhen: [{ key: 'hasOtherCountryTax', value: true }],
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Other country name',
    name: 'otherCountryTax',
    options: COUNTRY_OPTIONS,
    showWhen: [
      { key: 'hasAustraliaTax', value: false },
      { key: 'hasOtherCountryTax', value: false },
    ],
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Other country TIN or equivalent',
    name: 'isTINAvailable',
    required: true,
    showWhen: [
      {
        key: 'hasOtherCountryTax',
        value: (hasOtherCountryTax, values) =>
          !!hasOtherCountryTax || values?.hasAustraliaTax === false,
      },
    ],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'TIN (Taxpayer Identification Number*)',
    name: 'otherCountryTIN',
    showWhen: [
      { key: 'isTINAvailable', value: true },
      {
        key: 'hasOtherCountryTax',
        value: (hasOtherCountryTax, values) =>
          !!hasOtherCountryTax || values?.hasAustraliaTax === false,
      },
    ],
  },
];

export const SUMMARY_FIELDS_AUSTRALIAN_BANK_SUMMARY = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Account name',
    name: 'accountName',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'BSB (Bank State Branch) number',
    name: 'bsb',
    required: true,
    mask: '-',
    maskInterval: 3,
    type: 'number',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Account number',
    name: 'accountNumber',
    required: true,
  },
];

export const SUMMARY_FIELDS_OVERSEAS_BANK_SUMMARY = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Account name',
    name: 'accountName',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Account number / IBAN',
    name: 'accountNumber',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Bank name',
    name: 'bankName',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'BIC / SWIFT code',
    name: 'swiftCode',
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Country',
    name: 'country',
    required: true,
    options: COUNTRY_OPTIONS,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Bank code / Routing number',
    name: 'bankCode',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Bank address',
    name: 'bankAddress',
  },
];

export const SUMMARY_FIELDS_TRUSTEE_TYPE = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Trustee Type',
    name: 'type',
  },
];

export const SUMMARY_FAST_UPDATES_FIELDS = [
  'hasAustraliaTax',
  'hasOtherCountryTax',
  'isTINAvailable',
  'noTINReason',
];

export const SUMMARY_DROPDOWN_FIELDS = ['state', 'country'];

export const BANK_SUMMARY_CONFIRMATION_CHECKBOXES = [
  {
    name: 'isConfirmed',
    label: 'I confirm that the above information is true and correct',
  },
  {
    name: 'ePaymentAgreement',
    label: 'I will pay by electronic funds transfer',
    subLabel: '(payment instruction will be provided)',
  },
];

export const AUSTRALIA_BANK_INFO_FIELDS = [
  {
    label: 'Account Name',
    name: 'accountName',
    placeholder: 'Your Bank Account Name*',
  },
  {
    label: 'BSB',
    name: 'bsb',
    placeholder: 'Bank State Branch number*',
    type: 'number',
    mask: '-',
    maskInterval: 3,
  },
  {
    label: 'Account Number',
    name: 'accountNumber',
    placeholder: 'Your Bank Account number*',
    type: 'number',
    mask: '-',
    maskInterval: 3,
  },
];

export const OVERSEAS_BANK_INFO_FIELDS = [
  {
    label: 'Account Name',
    name: 'accountName',
    placeholder: 'Your Bank Account Name*',
  },
  {
    label: 'Account number/ IBAN',
    name: 'accountNumber',
    placeholder: 'Account number/ IBAN',
  },
  { label: 'Bank name', name: 'bankName', placeholder: 'Your bank name*' },
  { label: 'BIC / SWIFT code ', name: 'swiftCode', placeholder: 'BIC / SWIFT' },
  {
    label: 'Country',
    name: 'country',
    placeholder: 'Select country*',
    type: 'dropdown',
    options: COUNTRY_OPTIONS,
  },
  {
    label: 'Bank code / Routing number',
    name: 'bankCode',
    placeholder: 'Bank code / Routing number',
  },
  {
    label: 'Bank address*',
    name: 'bankAddress',
    placeholder: 'Your Bank Address*',
    md: 12,
  },
];

export const TRUST_TYPE_OPTIONS = [
  { label: 'Superannuation Fund', value: 'Superannuation Fund' },
  { label: 'Family Trust', value: 'Family Trust' },
  { label: 'Regulated Trust', value: 'Regulated Trust' },
  { label: 'Charitable Trust', value: 'Charitable Trust' },
  { label: 'Testamentary Trust', value: 'Testamentary Trust' },
  { label: 'Non-regulated Trust', value: 'Non-regulated Trust' },
  { label: 'Bare Trust', value: 'Bare Trust' },
  { label: 'Discretionary Trust', value: 'Discretionary Trust' },
  { label: 'Unit Trust', value: 'Unit Trust' },
];

export const SUMMARY_FIELDS_TRUST_PROFILE = [
  {
    fieldComponent: 'dropdown',
    options: TRUST_TYPE_OPTIONS,
    fieldLabel: 'Trust Type',
    name: 'trustType',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Full name of trust/designation',
    name: 'fullName',
  },
  {
    fieldComponent: 'dropdown',
    options: COUNTRY_OPTIONS,
    fieldLabel: 'Country where the trust is established',
    name: 'country',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'ABN',
    name: 'ABN',
  },
];

export const TRUST_PROFILE_FORM_ITEMS = [
  {
    label: 'Trust type',
    type: 'dropdown',
    options: TRUST_TYPE_OPTIONS,
    name: 'trustType',
    placeholder: 'Select trust type*',
    autoFocus: true,
  },
  {
    label: 'Full name of trust/designation',
    name: 'fullName',
    placeholder: 'Full name*',
  },
  {
    label: 'Country where the trust is established',
    type: 'dropdown',
    options: COUNTRY_OPTIONS,
    name: 'country',
    placeholder: 'Select country*',
  },
  {
    label: 'ABN (if registered)',
    name: 'ABN',
    placeholder: 'ABN*',
    type: 'number',
  },
];

export const SUMMARY_FIELDS_COMPANY_TAX_DETAILS = [
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Australia tax purpose',
    name: 'hasAustraliaTax',
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Tax File Number (TFN)*',
    name: 'australiaTax',
    showWhen: [{ key: 'hasAustraliaTax', value: true }],
    type: 'number',
    mask: '-',
    maskInterval: 3,
  },
  {
    fieldComponent: 'dropdown',
    fieldLabel: 'Other country name',
    name: 'otherCountryTax',
    options: COUNTRY_OPTIONS,
    unfilteredOptions: COUNTRY_OPTIONS,
    isBoldOptionLabel: true,
    showWhen: [{ key: 'hasAustraliaTax', value: false }],
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Other country TIN or equivalent',
    name: 'isTINAvailable',
    required: true,
    showWhen: [{ key: 'hasAustraliaTax', value: false }],
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'TIN (Taxpayer Identification Number*)',
    name: 'otherCountryTIN',
    showWhen: [
      { key: 'isTINAvailable', value: true },
      {
        key: 'hasAustraliaTax',
        value: false,
      },
    ],
  },
];

export const SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION = [
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Are you an Australian Retirement Fund?',
    name: 'isAustralianRetirement',
    required: true,
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel: 'Are you a financial institution?',
    name: 'isFinancialInstitution',
    showWhen: [{ key: 'isAustralianRetirement', value: false }],
    alwaysExecuteOnChange: true,
    required: true,
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel:
      'Do you have a GIIN (Global Intermediary Identification Number)?',
    name: 'hasGIIN',
    showWhen: [{ key: 'isFinancialInstitution', value: true }],
    required: true,
  },
  {
    fieldComponent: 'input',
    fieldLabel: "Company's GIIN",
    placeholder: "Your company's GIIN",
    name: 'companyGIIN',
    showWhen: [{ key: 'hasGIIN', value: true }],
    type: 'number',
    mask: '-',
    maskInterval: 3,
  },
];

export const getHasNoGIINLabel = (secondLabel, values) => {
  switch (values?.question) {
    case '1': {
      return 'Exempt beneficial owner';
    }
    case '2': {
      return 'Deemed-Compliant FFI';
    }
    case '3': {
      return condTrueFalse(
        secondLabel,
        'Sponsoring entity GIIN',
        'Sponsoring entity name',
      );
    }
    case '4': {
      return condTrueFalse(
        secondLabel,
        'Trustee Documented Trust GIIN',
        'Trustee Documented Trust name',
      );
    }
    case '5': {
      return 'Other';
    }
    default: {
      return 'Has No GIIN';
    }
  }
};

export const SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_NO_GIIN = (
  values,
) => [
  {
    fieldComponent: 'multipleChoiceQuestion',
    fieldLabel: getHasNoGIINLabel(false, values),
    name: 'hasNoGIINValues.question',
    showWhen: [
      {
        key: 'hasGIIN',
        value: (hasGIIN, v) => hasGIIN === false && !!v.isFinancialInstitution,
      },
    ],
    options: [
      {
        label: 'Who is the exempt beneficial owner?',
        key: 'answer-no-1',
        value: '1',
        showChildWhen: [
          {
            key: 'hasNoGIINValues',
            value: (hasNoGIINValues) => hasNoGIINValues?.question === '1',
          },
        ],
        displayValue: values?.exemptBeneficialOwnerName,
      },
      {
        label:
          'Deemed-Compliant FFI (other than a Sponsored FI or Trustee Document Trust)',
        key: 'answer-no-2',
        value: '2',
        displayValue: values?.deemedCompliantFFI,
      },
      {
        label: 'If you are a Sponsored Financial Institution, please provide:',
        key: 'answer-no-3',
        value: '3',
        displayValue: values?.sponsoringEntityName,
      },
      {
        label: 'Trustee Documented Trust, please provide:',
        value: '4',
        key: 'answer-no-4',
        displayValue: values?.trusteeName,
      },
      {
        label: 'Other',
        value: '5',
        key: 'answer-no-5',
        displayValue: values?.otherReason,
      },
    ],
  },
  // mock field to display second input
  {
    fieldComponent: 'multipleChoiceQuestion',
    fieldLabel: getHasNoGIINLabel(true, values),
    name: 'hasNoGIINValues.question',
    showWhen: [
      {
        key: 'hasNoGIINValues',
        value: (hasNoGIINValues, _, isEditSummary) =>
          ['3', '4'].includes(hasNoGIINValues?.question) && !isEditSummary,
      },
    ],
    fastField: false,
    options: [
      {
        key: 'answer-no-3',
        value: '3',
        showChildWhen: [
          {
            key: 'hasNoGIINValues',
            value: (hasNoGIINValues) => hasNoGIINValues?.question === '3',
          },
        ],
        displayValue: values?.sponsoringEntityGIIN,
      },
      {
        value: '4',
        key: 'answer-no-4',
        showChildWhen: [
          {
            key: 'hasNoGIINValues',
            value: (hasNoGIINValues) => hasNoGIINValues?.question === '4',
          },
        ],
        displayValue: values?.trusteeGIIN,
      },
    ],
  },
];

export const SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_QUESTION_4 = [
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel:
      'Are you an Investment Entity resident outside Australia and managed by another Financial Institution?',
    name: 'isManagedByAnotherInstitution',
    showWhen: [
      {
        key: 'isFinancialInstitution',
        value: (isFinancialInstitution, v) =>
          isFinancialInstitution === false || v.hasGIIN !== undefined,
      },
    ],
    required: true,
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel:
      'Is any natural person that exercises control over you a tax resident of any country outside Australia? (for corporations, this would include directors or beneficial owners who ultimately own 25% or more of the share capital).',
    name: 'controllingPerson.isControllingPersonANatural',
    showWhen: [
      {
        key: 'isFinancialInstitution',
        value: (isFinancialInstitution, v) =>
          (isFinancialInstitution === false || !!v.hasGIIN) &&
          !!v.isManagedByAnotherInstitution,
      },
    ],
    required: true,
  },
  {
    fieldComponent: 'yesNoQuestion',
    fieldLabel:
      'If you are a trust, is any natural person including trustee, protector, beneficiary, settlor or any other natural person exercising ultimate effective control over the trust a tax resident of any country outside of Australia?',
    name: 'controllingPerson.isControllingPersonATrust',
    showWhen: [
      {
        key: 'isFinancialInstitution',
        value: (isFinancialInstitution, v) =>
          (isFinancialInstitution === false || !!v.hasGIIN) &&
          !!v.isManagedByAnotherInstitution,
      },
    ],
    required: true,
  },
];

export const SUMMARY_FIELDS_TRUST_DETAIL = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Trust Type',
    name: 'trustType',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'ABN',
    name: 'businessIdentifier',
  },
];

export const SUMMARY_FIELDS_REDEMPTION_REQUEST_INVESTOR_DETAILS = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Entity',
    name: 'entityName',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Registered Address',
    name: 'entityAddress',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Email Address',
    name: 'entityEmail',
  },
];

export const SUMMARY_FIELDS_REDEMPTION_REQUEST_FUND_DETAILS = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Fund Name',
    name: 'fundName',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Class',
    name: 'className',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Redemption Request',
    name: 'redemptionUnitAmount',
  },
];

export const SUMMARY_FIELDS_REDEMPTION_REQUEST_PAYMENT_INSTRUCTION = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Account Name',
    name: 'accountName',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'BSB',
    name: 'bsb',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Account Number',
    name: 'accountNumber',
  },
];

export const PAY_NOW = 'Pay Now';
export const PAY_IN_INSTALLMENT = 'Pay In Installment';

const PAYMENT_COMPLETED = 'Completed';
const PAYMENT_COMPLETED_LABEL = 'Invested';
const PAYMENT_PENDING = 'PENDING';
const PAYMENT_PENDING_LABEL = 'Pending';
const PAYMENT_CANCELLED = 'Cancelled';
const PAYMENT_UNDER_REVIEW = 'Under Review';
const WAITING_FOR_PAYMENT = 'Waiting For Payment';

const PENDING_PAYMENT = 'Waiting for Payment';
const PENDING_REVIEW = 'Application Received';

const PORTFOLIO_STATUS_PENDING = 'PENDING';
const PORTFOLIO_STATUS_COMPLETED = 'COMPLETED';
const PORTFOLIO_STATUS_CANCELLED = 'CANCELLED';

export const PAYMENT_STATUS = {
  PAYMENT_COMPLETED,
  PAYMENT_PENDING,
  PAYMENT_CANCELLED,
  PAYMENT_UNDER_REVIEW,
};

export const getPaymentStatus = (status) => {
  switch (status) {
    case WAITING_FOR_PAYMENT:
      return PENDING_PAYMENT;
    case PAYMENT_UNDER_REVIEW:
      return PENDING_REVIEW;
    case PAYMENT_COMPLETED:
      return PAYMENT_COMPLETED_LABEL;
    case PAYMENT_PENDING:
      return PAYMENT_PENDING_LABEL;
    default:
      return status;
  }
};

export const PAYMENT_STATUS_OPTIONS = [
  { label: 'Completed', value: PAYMENT_COMPLETED },
  { label: 'Pending', value: PAYMENT_PENDING },
  { label: 'Cancelled', value: PAYMENT_CANCELLED },
  { label: 'Under Review', value: PAYMENT_UNDER_REVIEW },
];

export const PAYMENT_OPTIONS = {
  PAY_NOW,
  PAY_IN_INSTALLMENT,
};

export const PAYMENT_OPTIONS_LABEL = {
  [PAY_NOW]: 'Full payment',
  [PAY_IN_INSTALLMENT]: 'Installment',
};

export const INVESMENT_ENTITY_LIST_FIELD_DISPLAY = [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    xs: 7,
    md: 4,
    showOnMobile: true,
  },
  {
    label: 'Type',
    name: 'type',
    type: 'text',
    xs: 3,
    md: 2,
  },
  {
    label: 'Country',
    name: 'country',
    type: 'text',
    renderer: (value) =>
      countries.find(({ code }) => code === value)?.label || '',
    xs: 3,
    md: 3,
  },
  {
    label: 'Status',
    name: 'status',
    type: 'chip',
    xs: 5,
    md: 2,
    showOnMobile: true,
  },
];

export const INVESMENT_FUND_LIST_FIELD_DISPLAY = [
  {
    label: 'Fund Name',
    name: 'fundName',
    type: 'text',
    xs: 12,
    md: 5,
  },
  {
    label: 'Region',
    name: 'region',
    type: 'text',
    xs: 3,
    md: 2,
  },
  {
    label: 'Currency',
    name: 'currency',
    type: 'text',
    xs: 3,
    md: 1,
  },
  {
    label: 'Payment Method',
    name: 'paymentType',
    type: 'text',
    xs: 3,
    md: 2,
    renderer: (value) => PAYMENT_OPTIONS_LABEL[value] || value,
  },
  {
    label: 'Reference Number',
    name: 'referenceNumber',
    type: 'text',
    xs: 3,
    md: 2,
  },
];

const INDIVIDUAL = 'Individual';
const COMPANY = 'Company';
const TRUST = 'Trust';
const OTHER = 'Other';

export const TRUSTEE_TYPES = {
  INDIVIDUAL,
  COMPANY,
};

export const ENTITY_TYPES = {
  INDIVIDUAL,
  COMPANY,
  TRUST,
  OTHER,
};

export const TAX_CATEGORY = {
  INDIVIDUAL: 'I',
  COMPANY: 'C',
  TRUST: 'T',
  OTHER: 'O',
};

export const INVESTMENT_ENTITY_STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  CANCELED: 'REJECTED',
};

export const FUND_REDEMPTION_STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  CANCELED: 'REJECTED',
};

export const INVESTMENT_ENTITY_STATUS_LABEL = {
  PENDING: 'RECEIVED',
  APPROVED: 'APPROVED',
  CANCELED: 'REJECTED',
  REJECTED: 'REJECTED',
};

export const DOCUMENT_SIGNING_DECLARATION = [
  'I/we declare that all statements and details made by me/us are complete and accurate;',
  'I/we agree to be bound by the terms of the offer and the Trust Deed;',
  'I/we was/were given access to the Information Memorandum, Trust Deed and Application Form. I/we have read and understood those documents, and sought advice if applicable;',
  'I/we represent, warrant and undertake to Trustee and Manager that my/our subscription for the offer will not cause the Trustee or me/us to violate the laws of any jurisdiction which may be applicable to this offer;',
  'I/we agree to receive all communications regarding this application and all future communications by the Manager / Trustee electronically;',
  'Electronic execution of the application is authorised under the Commonwealth Electronic Transaction Act 1999. By completing and submitting your application, I/we agree that I am/we are bound by the above terms and it does not require a physical signature.',
];

export const FUND_ENTITY_STATUS = {
  [PAYMENT_UNDER_REVIEW]: {
    status: PAYMENT_UNDER_REVIEW,
    prevStatus: PAYMENT_UNDER_REVIEW,
    label: 'Need Review',
    portfolioStatus: PORTFOLIO_STATUS_PENDING,
  },
  [WAITING_FOR_PAYMENT]: {
    status: WAITING_FOR_PAYMENT,
    prevStatus: PAYMENT_UNDER_REVIEW,
    label: WAITING_FOR_PAYMENT,
    portfolioStatus: PORTFOLIO_STATUS_PENDING,
  },
  [PAYMENT_COMPLETED]: {
    status: PAYMENT_COMPLETED,
    prevStatus: WAITING_FOR_PAYMENT,
    label: 'Completed',
    portfolioStatus: PORTFOLIO_STATUS_COMPLETED,
  },
  [PAYMENT_CANCELLED]: {
    status: PAYMENT_CANCELLED,
    prevStatus: PAYMENT_UNDER_REVIEW,
    label: 'Cancelled',
    portfolioStatus: PORTFOLIO_STATUS_CANCELLED,
  },
  STATUSES: {
    PAYMENT_UNDER_REVIEW,
    WAITING_FOR_PAYMENT,
    PAYMENT_COMPLETED,
    PAYMENT_CANCELLED,
  },
};

export const FUND_ENTITY_STATUS_OPTIONS = Object.values(
  FUND_ENTITY_STATUS.STATUSES,
).map((status) => ({
  label: FUND_ENTITY_STATUS[status].label,
  value: status,
}));

export const FUND_REDEMPTION_REQUEST_STATUS_OPTIONS = Object.values(
  FUND_REDEMPTION_STATUSES,
).map((status) => ({
  label: status.charAt(0) + status.toLowerCase().slice(1),
  value: status,
}));

export const FUND_ENTITY_LOADING_LABEL = {
  [PAYMENT_UNDER_REVIEW]: 'Investment Revieved',
  [WAITING_FOR_PAYMENT]: 'Confirm Payment Received',
};

export const MIN_FUND_INVESTMENT_AMOUNT = 5000;
export const MAX_FUND_INVESTMENT_AMOUNT = 999999999999;

export const REQUEST_DETAILS_FIELDS = [
  {
    label: 'Entity Request Number',
    name: 'entityRequestNumber',
  },
  {
    label: 'Username',
    name: 'username',
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Status',
    name: 'status',
  },
];

export const FUND_REQUEST_DETAILS_FIELDS = [
  {
    label: 'Investment Request Number',
    name: 'id',
  },
  {
    label: 'Entity Name',
    name: 'name',
  },
  {
    label: 'Status',
    name: 'paymentStatus',
  },
];

export const PAYMENT_DETAILS_FIELD = [
  {
    fieldLabel: 'Total Investment',
    name: 'totalInvestment',
    type: 'number',
    withCurrency: true,
    fieldType: 'currencyInput',
  },
  {
    fieldLabel: 'Investment Units',
    name: 'investmentUnit',
    type: 'number',
  },
  {
    fieldLabel: 'Amount Payable today',
    name: 'amountPayableToday',
    type: 'number',
    withCurrency: true,
    fieldType: 'currencyInput',
  },
  {
    fieldLabel: 'Access Fee',
    name: 'accessFee',
    type: 'number',
    withCurrency: true,
    fieldType: 'currencyInput',
  },
  {
    fieldLabel: 'Application Fee',
    name: 'applicationFee',
    type: 'number',
    withCurrency: true,
    fieldType: 'currencyInput',
  },
  {
    fieldLabel: 'Total Payable today',
    formLabel: 'Amount Received',
    name: 'totalPayableToday',
    type: 'number',
    withCurrency: true,
    fieldType: 'currencyInput',
    displaySx: {
      fontWeight: 600,
    },
  },
  {
    fieldLabel: 'Lockup',
    name: 'lockupDays',
    type: 'number',
    renderer: ({ value }) => `${value || 0} days`,
  },
];

export const PAYMENT_DETAILS_FIELD_WITH_APPROVAL_DATE = [
  ...PAYMENT_DETAILS_FIELD,
  {
    fieldLabel: 'Lockup remaining',
    name: 'lockupDaysRemaining',
    type: 'number',
    renderer: ({ value }) => `${value || 0} days`,
  },
  {
    fieldLabel: 'Approval Date',
    name: 'approvedAt',
    fieldType: 'datePicker',
    renderer: dateTimeCellRenderer,
  },
];

export const INSTALLMENT_INFORMATION_FIELD = [
  {
    fieldLabel: 'Amount Payable Later',
    name: 'amountPayableLater',
    type: 'number',
    withCurrency: true,
  },
  {
    fieldLabel: 'Total',
    name: 'total',
    type: 'number',
    withCurrency: true,
    displaySx: {
      fontWeight: 600,
    },
  },
];

export const PAYMENT_TYPE_OPTIONS = [
  {
    label: PAYMENT_OPTIONS_LABEL[PAY_IN_INSTALLMENT],
    value: PAY_IN_INSTALLMENT,
  },
  { label: PAYMENT_OPTIONS_LABEL[PAY_NOW], value: PAY_NOW },
];
