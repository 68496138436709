import auth from './auth';
import drawer from './drawer';
import profile from './profile';
import general from './general';

const ReachAltStores = (state, action) => {
  switch (true) {
    case action.type?.includes('@@auth'):
      return auth(state, action);
    case action.type?.includes('@@profile'):
      return profile(state, action);
    case action.type?.includes('@@drawer'):
      return drawer(state, action);
    case action.type?.includes('@@general'):
      return general(state, action);
    default:
      return {};
  }
};

export default ReachAltStores;
