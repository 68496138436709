import React, { useCallback } from 'react';

import InitialValues from 'constants/InitialValues';
import PayloadMappers from 'utils/PayloadMappers';
import taxDetailsValidationSchema from 'validations/schema/addInvestment/taxDetailsValidationSchema';

import { ReactComponent as PercentIcon } from 'assets/icons/percent.svg';
import AddEntityDialog from './AddEntityDialog';

const AddTaxDetailDialog = ({
  open,
  onClose,
  onSubmit,
  entityUpdateKey,
  ...props
}) => {
  const handleSubmit = useCallback((formValues) => {
    const addKey = `add${entityUpdateKey}`;
    const payload = PayloadMappers.taxDetails(formValues);

    onSubmit({
      [addKey]: payload,
    });
  }, []);

  return (
    <AddEntityDialog
      withTab={false}
      open={open}
      onClose={onClose}
      icon={PercentIcon}
      title="Add Another Country for Tax Purpose"
      type="taxDetail"
      onSubmit={handleSubmit}
      initialValues={InitialValues.taxDetail}
      validationSchema={taxDetailsValidationSchema}
      {...props}
    />
  );
};

export default AddTaxDetailDialog;
