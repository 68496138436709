import addressValidationSchema from '../../addressValidationSchema';
import investorIdentificationValidationSchema from '../../investorIdentificationValidationSchema';
import investorSummaryValidationSchema from '../../investorSummaryValidationSchema';
import personalDetailsValidationSchema from '../../personalDetailsValidationSchema';
import beneficialOwnerListValidationSchema from './beneficialOwnerListValidationSchema';

const companyBeneficialOwnerValidationSchemas = [
  null,
  personalDetailsValidationSchema,
  addressValidationSchema,
  investorIdentificationValidationSchema,
  investorSummaryValidationSchema,
  beneficialOwnerListValidationSchema,
];

export default companyBeneficialOwnerValidationSchemas;
