import React, { memo } from 'react';

import Grid from '@mui/material/Grid';

import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import FieldContainer from 'components/FieldContainer';
import FormTitle from 'components/FormTitle';
import TextEditor from 'components/TextEditor';
import Input from 'components/Input';

import { VIDEO_CATEGORY_OPTIONS } from 'constants/articles';
import { UploadBtnWithLabelHelper } from '../../ArticleDetails/Article/ArticleBanner/ArticleBannerTeaserHeader';

const VideoOverview = ({
  readOnly,
  onChange,
  values,
  errors = {},
  onBlur,
  setFieldValue,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Video" />
    </Grid>

    <Grid container item xs={12} rowSpacing={3}>
      <FieldContainer
        item
        xs={7}
        label="Video Category"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Dropdown
          name="category"
          placeholder="Video Category"
          options={VIDEO_CATEGORY_OPTIONS}
          disabled={readOnly}
          onChange={onChange}
          value={values.category}
          error={!!errors.category}
          errorValue={errors.category}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Video Name"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="title-input"
          id="title"
          name="title"
          placeholder="Video Name"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.title}
          error={!!errors.title}
          errorValue={errors.title}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Video Summary"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="summary-input"
          id="summary"
          name="summary"
          placeholder="Video Name"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.summary}
          error={!!errors.summary}
          errorValue={errors.summary}
          inputProps={{ maxLength: 50 }}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Date"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <DatePicker
          fullWidth
          name="date"
          testId="date-input"
          onChange={onChange}
          value={values.date}
          onBlur={onBlur}
          setFieldValue={setFieldValue}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Embed Link"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="link-input"
          id="link"
          name="link"
          placeholder="Video"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.link}
          error={!!errors.link}
          errorValue={errors.link}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Video Thumbnail Image"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <UploadBtnWithLabelHelper
          name="videoThumbnailImg"
          onChange={onChange}
          values={values}
          helperText="500x300 pixels size (jpg, png) max 2MB"
          errorValue={errors.videoThumbnailImg}
          disabled={readOnly}
        />
      </FieldContainer>
      <FieldContainer
        item
        label=""
        variant="title"
        childGridProps={{ sx: { minHeight: '300px' } }}
      >
        <TextEditor
          id="video-content-texteditor"
          onChange={onChange}
          value={values.content}
          name="content"
          options={{ imageFileInput: false }}
        />
      </FieldContainer>
    </Grid>
  </Grid>
);

export default memo(VideoOverview);
