import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import formatCurrency from 'utils/formatCurrency';

const infos = [
  {
    key: 'minInvestment',
    // To match spacing.
    label: <span>Minimum Investment&nbsp;</span>,
  },
  {
    key: 'maxInvestment',
    label: 'Maximum Investment',
  },
];

const InvestmentAmountInfo = ({ fundConfiguration }) => (
  <div style={{ display: 'inline-block', minWidth: '264px' }}>
    {infos.map(({ key, label }) => (
      <Grid key={key} container columnSpacing={1}>
        <Grid item xs="auto" textAlign="left">
          <Typography variant="body">{label}</Typography>
        </Grid>
        <Grid item xs="auto">
          <Typography variant="body" fontWeight={700}>{`: ${formatCurrency({
            currency: fundConfiguration.dealCurrency,
            value: fundConfiguration[key],
          })}`}</Typography>
        </Grid>
      </Grid>
    ))}
  </div>
);

export default memo(InvestmentAmountInfo);
