import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MoneyToUnitsConverter from 'components/MoneyToUnitsConverter';
import Input from 'components/Input';
import ShowMe from 'components/ShowMe';

import getNumbers from 'utils/strings/getNumbers';
import palette from 'theme/palette';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import InvestmentAmountInfo from './InvestmentAmountInfo';
import PaymentOptions from './PaymentOptions';
import PaymentDetails from './PaymentDetails';

const minAmountWarningLabel =
  '*Applicants under or over the min. or max. investment amount will be subject to review.';

const InvestmentAmountForm = ({
  onChange,
  values,
  fundConfiguration = {},
  unitConverter,
}) => {
  // Need to do warning validation manually,
  // because user can still submit with this warning.
  const investmentAmountWarning = useMemo(() => {
    const amount = getNumbers(values.investmentAmount);

    const isAmountOverMinInvestment = amount >= fundConfiguration.minInvestment;
    const isAmountBelowMaxInvestment =
      amount <= fundConfiguration.maxInvestment;

    if (
      !amount ||
      !fundConfiguration.minInvestment ||
      (isAmountOverMinInvestment && isAmountBelowMaxInvestment)
    )
      return null;

    return (
      <span>
        <WarningIcon height={18} width={18} />
        {minAmountWarningLabel}
      </span>
    );
  }, [fundConfiguration.minInvestment, values.investmentAmount]);

  return (
    <Grid container item rowSpacing={4} xs={12} justifyContent="center">
      <ShowMe when={unitConverter}>
        <MoneyToUnitsConverter
          autoFocus
          name="investmentAmount"
          value={values.investmentAmount}
          unitPrice={fundConfiguration.sharePrice}
          currency={fundConfiguration.dealCurrency}
          onChange={onChange}
          errors={{ investmentAmount: investmentAmountWarning }}
        />
      </ShowMe>
      <ShowMe when={!unitConverter}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body"
            fontWeight={700}
            color={palette.text.obsidian}
            sx={{ float: 'left' }}
          >
            Please enter the investment amount
          </Typography>
          <Input
            fullWidth
            withThousandSeparator
            id="investmentAmount"
            name="investmentAmount"
            type="number"
            placeholder="00000"
            onChange={onChange}
            value={values.investmentAmount}
            error={false}
            errorValue={investmentAmountWarning}
          />
        </Grid>
      </ShowMe>

      <Grid item xs={12} textAlign={['left', 'center']}>
        <InvestmentAmountInfo fundConfiguration={fundConfiguration} />
      </Grid>

      {/* Hide this for now */}
      <ShowMe when={false}>
        <Grid item xs={12}>
          <PaymentOptions
            isPayInInstallment={values.isPayInInstallment}
            onChange={onChange}
          />
        </Grid>
      </ShowMe>

      <Grid item xs={12}>
        <PaymentDetails
          isPayInInstallment={values.isPayInInstallment}
          fundConfiguration={fundConfiguration}
          values={values}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default memo(InvestmentAmountForm);
