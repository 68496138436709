import * as yup from 'yup';

const adminFundPerformanceOpenEndValidationSchema = yup.object({
  date: yup.string().required(),

  returnGross_3Month: yup.string().nullable(),
  returnNet_3Month: yup.string().nullable(),
  returnGrossYtd: yup.string().nullable(),
  returnNetYtd: yup.string().nullable(),
  returnGross_1Year: yup.string().nullable(),
  returnNet_1Year: yup.string().nullable(),
  returnGross_3Year: yup.string().nullable(),
  returnNet_3Year: yup.string().nullable(),
  returnGross_7Year: yup.string().nullable(),
  returnNet_7Year: yup.string().nullable(),
  returnGross_10Year: yup.string().nullable(),
  returnNet_10Year: yup.string().nullable(),
  returnGrossSinceInception: yup.string().nullable(),
  returnNetSinceInception: yup.string().nullable(),
});

export default adminFundPerformanceOpenEndValidationSchema;
