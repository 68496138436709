import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const FieldListContainer = ({ listNo, children, hidden }) => (
  <Grid container columnSpacing={1}>
    <Grid item minWidth={24} hidden={hidden}>
      <div
        style={{
          display: 'flex',
          placeItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black',
          height: '100%',
        }}
      >
        <Typography variant="body" color="white">
          {listNo}
        </Typography>
      </div>
    </Grid>
    {children}
  </Grid>
);

export default FieldListContainer;
