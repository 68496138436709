import React, { memo, useCallback, useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Search from '@mui/icons-material/Search';
import Reorder from '@mui/icons-material/Reorder';
import TextSnippet from '@mui/icons-material/TextSnippet';
import Typography from '@mui/material/Typography';

import AccountCircle from '@mui/icons-material/AccountCircle';
import { useMediaQuery } from '@mui/material';

import { ReactComponent as ManageAccountsIcon } from 'assets/icons/manage-accounts.svg';

import CashFundCard from 'components/FundCard/CashFundCard';
import CircularStepper from 'components/Stepper/CircularStepper';
import FundCard from 'components/FundCard';
import ProgressStepper from 'components/Stepper/ProgressStepper';
import ShowMe from 'components/ShowMe';
import { useStores } from 'components/StateProvider';

import useFetchApi from 'hooks/useFetchApi';
import useDashboardLoading from 'hooks/useDashboardLoading';
import condTrueFalse from 'utils/condTrueFalse';

import palette from 'theme/palette';

import EmptyContainer from './EmptyContainer';
import HelperFunction from '../HelperFunction';

const { fetchAllFunds } = HelperFunction;

const steps = [
  { label: 'Register account', icon: AccountCircle },
  { label: 'Complete onboarding', icon: ManageAccountsIcon },
  { label: 'View fund details', icon: Search },
  { label: 'Add investment Entity', icon: Reorder },
  { label: 'Invest', icon: TextSnippet },
];

const FundCardsGrid = ({ handleViewFundDetail, mode = 'portrait' }) => {
  const {
    auth: { user },
  } = useStores();
  const { isFetched, handleSetIsLoading, handleSetIsFetched } =
    useDashboardLoading();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(
    () => condTrueFalse(isFetched, () => handleSetIsFetched(false)),
    [],
  );

  const getFunds = useMemo(() => fetchAllFunds(), []);
  const { apiData: fundsData, isFetchLoading } = useFetchApi({
    url: getFunds,
    isFetched,
    setIsFetched: handleSetIsFetched,
    setIsLoading: handleSetIsLoading,
    dependencies: [isFetched],
  });

  const activeStep = useMemo(() => {
    switch (true) {
      case !user?.isVerified:
        return 1;
      case !user?.hasViewFundDetail:
        return 2;
      case !user?.hasEntity:
        return 3;
      case !user?.hasInvested:
        return 4;
      case user?.hasInvested:
        return 5;
      default:
        return 0;
    }
  }, [user]);

  const funds = useMemo(
    () =>
      fundsData.reduce(
        (filteredFunds, fundData) => {
          if (typeof fundData.overview === 'string') {
            return {
              ...filteredFunds,
              cashFunds: filteredFunds.cashFunds.concat(fundData),
            };
          }
          if (fundData.isOpen) {
            return {
              ...filteredFunds,
              openFunds: filteredFunds.openFunds.concat(fundData),
            };
          }
          return {
            ...filteredFunds,
            closedFunds: filteredFunds.closedFunds.concat(fundData),
          };
        },
        { cashFunds: [], openFunds: [], closedFunds: [] },
      ),
    [fundsData],
  );

  const FundCardList = useCallback(({ fundItems }) => {
    if (!fundItems?.length)
      return (
        <Grid item xs={12}>
          <EmptyContainer text="No funds at the moment." />
        </Grid>
      );

    return fundItems?.map(
      ({
        id,
        fundName,
        fundType,
        headerColor,
        isOpen,
        fundCards,
        headerImage,
        isInvested,
        portfolios,
      }) => (
        <Grid
          key={id}
          data-testid={`fund-card-${id}`}
          item
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <FundCard
            mode={mode}
            onClickViewDetail={handleViewFundDetail(id)}
            title={fundName}
            // description={fundType}
            fundType={fundType}
            headerBgColor={headerColor || palette.reach.purple}
            headerImageProps={{
              style: { position: 'absolute', bottom: '4px' },
            }}
            contentList={fundCards}
            headerImageSrc={headerImage?.header}
            logoSrc={headerImage?.logo}
            isOpen={isOpen}
            disabled={!isOpen}
            isInvested={isInvested}
            portfolios={portfolios}
            {...(!isOpen
              ? {
                  // onClickViewDetail: null,
                  // disabled: true,
                  // buttonLabel: 'See more like this?',
                  status: 'Closed',
                  // statusColor: palette.reach.black,
                }
              : {})}
          />
        </Grid>
      ),
    );
  }, []);

  const CashFundList = useCallback(
    () =>
      funds.cashFunds.map((cashFund) => (
        <Grid key={cashFund.id} item xs={12} alignContent="center">
          <CashFundCard
            fundName={cashFund?.fundName}
            overview={cashFund?.overview}
            onClickFund={handleViewFundDetail(cashFund?.id)}
            isInvested={cashFund?.isInvested}
            portfolios={cashFund?.portfolios}
          />
        </Grid>
      )),
    [funds.cashFunds],
  );

  const stepperComponent = useMemo(() => {
    if (isMobileScreen)
      return (
        <CircularStepper
          steps={steps}
          activeStep={activeStep}
          label={steps[activeStep]?.label}
        />
      );

    return <ProgressStepper steps={steps} activeStep={activeStep} />;
  }, [isMobileScreen, steps, activeStep]);

  if (isFetchLoading) return null;

  return (
    <Grid data-testid="tab-item-funds" container item spacing={3}>
      <ShowMe when={activeStep < steps.length}>
        <Grid item xs={12}>
          <div
            style={{
              backgroundColor: 'white',
              borderTop: '7px solid black',
              padding: '32px 0px',
            }}
          >
            {stepperComponent}
          </div>
        </Grid>
      </ShowMe>

      <CashFundList />

      <Grid container item xs={12} alignItems="center">
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            Available Funds
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider flexItem variant="middle" sx={{ mr: 0 }} />
        </Grid>
      </Grid>
      <FundCardList fundItems={funds.openFunds} />

      <Grid container item xs={12} alignItems="center">
        <Grid item xs>
          <Divider flexItem variant="middle" sx={{ ml: 0 }} />
        </Grid>
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            Completed
          </Typography>
        </Grid>
      </Grid>
      <FundCardList fundItems={funds.closedFunds} />
    </Grid>
  );
};

export default memo(FundCardsGrid);
