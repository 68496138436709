const CHANGE_PASSWORD_INVALID = 'error.change-password.password.invalid';
const CHANGE_PASSWORD_MAX_ATTEMPT =
  'error.change-password.reach-trial-limit-wrong-password-10-times';
const CHANGE_PASSWORD_TEMPORARY_LOCKED = 'error.change-password.lock-temporary';

const ERROR_CODES = {
  CHANGE_PASSWORD_INVALID,
  CHANGE_PASSWORD_MAX_ATTEMPT,
  CHANGE_PASSWORD_TEMPORARY_LOCKED,
};
const ERROR_MSG = {
  [CHANGE_PASSWORD_INVALID]: 'Incorrect password, please try again.',
  [CHANGE_PASSWORD_MAX_ATTEMPT]:
    'Incorrect password. You can change your password in 24 hours later.',
  [CHANGE_PASSWORD_TEMPORARY_LOCKED]:
    'You can change your password in 24 hours later.',
};

const ERROR = {
  [CHANGE_PASSWORD_INVALID]: {
    code: CHANGE_PASSWORD_INVALID,
    message: ERROR_MSG[CHANGE_PASSWORD_INVALID],
  },
};

export default { ERROR, ERROR_CODES, ERROR_MSG };
