import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { STORAGE_KEY } from 'constants/general';
import { ROLE_USER } from 'constants/userManagement';

import history from 'utils/history';
import { fetchAdminPreviewToken } from './HelperFunction';

const UserPreview = () => {
  const { userId } = useParams();

  const getUserPreviewToken = useCallback(async () => {
    const { accessToken } = await fetchAdminPreviewToken(userId);

    sessionStorage.setItem(`${STORAGE_KEY}-previewToken`, accessToken);
    sessionStorage.setItem(`${STORAGE_KEY}-tempRole`, ROLE_USER);

    history.push('/');
  }, [userId]);

  useEffect(() => {
    getUserPreviewToken();
  }, [getUserPreviewToken]);

  return <>Loading...</>;
};

export default UserPreview;
