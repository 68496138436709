import React from 'react';
import Box from '@mui/material/Box';
import MUITypography from '@mui/material/Typography';

import CountryFlag from 'components/CountryFlag';
import OptionDivider from 'components/OptionDivider';

const AutoCompleteOption = (
  props,
  { code, label, phone, type },
  { selected },
) =>
  type === 'divider' ? (
    <OptionDivider key={type} />
  ) : (
    <Box
      data-testid={`phone-number-code-area-item-${code}`}
      component="li"
      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
      {...props}
    >
      <>
        <CountryFlag fileName={code?.toLowerCase()} />
        <MUITypography
          variant="xsmall"
          sx={{ fontWeight: selected ? 600 : 400 }}
        >
          +{phone} {label}
        </MUITypography>
      </>
    </Box>
  );

export default AutoCompleteOption;
