import React from 'react';

import { ENTITY_TYPES } from 'constants/investmentEntity';

import BankDetail from './BankDetail';
import PersonList from './PersonList';
import TaxDetailListWithAccordion from './TaxDetailListWithAccordion';

const IndividualDetails = ({ values, ...props }) => (
  <>
    <PersonList
      values={values.detail?.personalDetail}
      listLabel="Personal Details"
      detailTitle="Individual Details"
      isBeneficialOwner={false}
      type={ENTITY_TYPES.INDIVIDUAL}
      {...props}
    />
    <TaxDetailListWithAccordion values={values.detail?.taxDetail} {...props} />
    <BankDetail values={values.detail?.bankDetail} {...props} />
  </>
);

export default IndividualDetails;
