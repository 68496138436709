import admin from './admin';
import bankDetails from './bankDetails';
import companyDetails from './companyDetails';
import personalDetails from './personalDetails';
import taxDetailPurposes from './taxDetailPurposes';
import taxDetails from './taxDetails';
import beneficialOwners from './beneficialOwners';
import trustees from './trustees';
import trustDetails from './trustDetails';
import fundInvest from './fundInvest';
import fundRedemptionRequest from './fundRedemptionRequest';

export default {
  admin,
  bankDetails,
  companyDetails,
  personalDetails,
  taxDetailPurposes,
  taxDetails,
  beneficialOwners,
  trustees,
  trustDetails,
  fundInvest,
  fundRedemptionRequest,
};
