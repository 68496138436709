import React from 'react';
import MUIButton from '@mui/material/Button';
import MUITypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(MUIButton)(({ theme, sx = {} }) => ({
  background: theme.palette.reach.grey5,
  borderRadius: '0px',
  width: '192px',
  height: '192px',
  ...sx,
}));

const Container = styled('div')(() => ({
  display: 'inline-block',
  textAlign: 'center',
}));

const IconButton = ({
  icon,
  label,
  variant = 'h5',
  containerProps,
  ...props
}) => (
  <Container {...containerProps}>
    <StyledIconButton variant="text" {...props}>
      {typeof icon === 'string' ? <img src={icon} alt={label} /> : icon}
    </StyledIconButton>
    <MUITypography variant={variant} mt="12px">
      {label}
    </MUITypography>
  </Container>
);

export default IconButton;
