const ACCOUNT_NOT_VERIFIED = 'error.login.account.not.verified';
const INCORRECT_EMAIL_PASSWORD = 'error.login.incorrect.email.password';
const INVALID_TOKEN = 'error.login.invalid-token.please-relogin';
const EXPIRED_TOKEN = 'error.login.token.expired';
const USER_AUTH_CONTROL_NOT_FOUND = 'error.login.user.auth.not.found';
const ACCOUNT_LOCKED = 'error.login.account.locked';
const ACCOUNT_DISABLED = 'error.login.account.disabled';
const NEED_VERIFICATION_CODE = 'needVerificationCode';
const TEMP_LOCK = 'error.login.temporary.locked';
const REACH_TRIAL_LIMIT = 'error.login.reach.trial.limit';
const DATA_NOT_FOUND = 'error.login.data-not-found';
const SERVER_ERROR = 'error.login.server-error';
const WRONG_CODE = 'error.login.invalid-token.please-relogin';
const TOO_MANY_REQUEST = 'error.too-many-request';
const RECAPTCHA_INVALID_TOKEN = 'error.recaptcha.invalid.token';

const ERROR_CODES = {
  ACCOUNT_NOT_VERIFIED,
  INCORRECT_EMAIL_PASSWORD,
  INVALID_TOKEN,
  EXPIRED_TOKEN,
  USER_AUTH_CONTROL_NOT_FOUND,
  ACCOUNT_LOCKED,
  ACCOUNT_DISABLED,
  NEED_VERIFICATION_CODE,
  TEMP_LOCK,
  REACH_TRIAL_LIMIT,
  DATA_NOT_FOUND,
  SERVER_ERROR,
  WRONG_CODE,
  TOO_MANY_REQUEST,
};

const ERROR_MSG = {
  [INCORRECT_EMAIL_PASSWORD]: 'Incorrect email or password.',
  [ACCOUNT_NOT_VERIFIED]: 'You need to activate this account.',
  [INVALID_TOKEN]: 'Invalid token, Please re-login.',
  [EXPIRED_TOKEN]: 'The code you entered has expired.',
  [USER_AUTH_CONTROL_NOT_FOUND]:
    'Auth Control not found, Please contact Admin.',
  [ACCOUNT_LOCKED]:
    'Your account has been disabled, please login in 24 hours later.',
  [ACCOUNT_DISABLED]:
    'Your account has been disabled, please contact us at contact@reachalts.com.au .',
  [WRONG_CODE]: 'Wrong code, please try again',
  [TEMP_LOCK]:
    'This account has been temporarily blocked for 24 hours due to multiple authentication failures. To unlock it please contact the admin.',
  [REACH_TRIAL_LIMIT]:
    'Due too multiple failed attempts, this account has been temporary locked for 24 hours. To unlock it, please contact admin.',
  [DATA_NOT_FOUND]: 'Error, data not found.',
  [SERVER_ERROR]: 'Server error.',
  [TOO_MANY_REQUEST]: 'Too many requests. Please try again in 2 minutes',
  [RECAPTCHA_INVALID_TOKEN]:
    'Recaptcha authentication failed. Please try again.',
};

const ERROR = {
  [INCORRECT_EMAIL_PASSWORD]: {
    code: INCORRECT_EMAIL_PASSWORD,
    message: ERROR_MSG[INCORRECT_EMAIL_PASSWORD],
  },
  [ACCOUNT_NOT_VERIFIED]: {
    code: ACCOUNT_NOT_VERIFIED,
    message: ERROR_MSG[ACCOUNT_NOT_VERIFIED],
  },
  [INVALID_TOKEN]: {
    code: INVALID_TOKEN,
    message: ERROR_MSG[INVALID_TOKEN],
  },
  [EXPIRED_TOKEN]: {
    code: EXPIRED_TOKEN,
    message: ERROR_MSG[EXPIRED_TOKEN],
  },
  [USER_AUTH_CONTROL_NOT_FOUND]: {
    code: USER_AUTH_CONTROL_NOT_FOUND,
    message: ERROR_MSG[USER_AUTH_CONTROL_NOT_FOUND],
  },
  [ACCOUNT_LOCKED]: {
    code: ACCOUNT_LOCKED,
    message: ERROR_MSG[ACCOUNT_LOCKED],
  },
  [ACCOUNT_DISABLED]: {
    code: ACCOUNT_DISABLED,
    message: ERROR_MSG[ACCOUNT_DISABLED],
  },
  [TOO_MANY_REQUEST]: {
    code: TOO_MANY_REQUEST,
    message: ERROR_MSG[TOO_MANY_REQUEST],
  },
  [RECAPTCHA_INVALID_TOKEN]: {
    code: RECAPTCHA_INVALID_TOKEN,
    message: ERROR_MSG[RECAPTCHA_INVALID_TOKEN],
  },
};

export default { ERROR, ERROR_MSG, ERROR_CODES };
