import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import BankDetails from 'containers/Admin/Approvals/Details/DetailsTab/BankDetails';
import Button from 'components/Button';
import ShowMe from 'components/ShowMe';
import FieldContainer from 'components/FieldContainer';
import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import FailedDialog from 'components/Dialog/FailedDialog';

import updateInvestmentAmountValidationSchemas from 'validations/schema/updateInvestmentAmountValidationSchemas';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import { LoadingSpinner } from 'hooks/useDashboardLoading';

import Services from 'api/services';
import condTrueFalse from 'utils/condTrueFalse';
import history from 'utils/history';
import PayloadMappers from 'utils/PayloadMappers';
import mapValue from 'utils/mapValue';

import {
  FUND_ENTITY_STATUS,
  INVESMENT_FUND_LIST_FIELD_DISPLAY,
  PAYMENT_OPTIONS,
} from 'constants/investmentEntity';

import InvestmentRequestDetails from './InvestmentRequestDetails';
import ApprovalContainer from './ApprovalContainer';
import PaymentDetailsSummary from './PaymentDetailsSummary';
import InstallmentInformationSummary from './InstallmentInformationSummary';
import EntityDetailDialog from './EntityDetailDialog';
import InstallmentInformation from './PaymentForm/InstallmentInformation';
import PaymentForm from './PaymentForm';
import { fetchAdminFundEntity } from '../HelperFunction';

const handleRedirectToFundList = () => {
  const route = mapValue(
    history.location.state?.onBackRoute,
    '/admin/approvals?type=fund',
  );

  history.push(route);
};

const FundDetails = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [error, setError] = useState('');
  const { fundsId } = useParams();

  const {
    isDialogOpen: isDeleteDialogOpen,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
  } = useDialog();

  const {
    isDialogOpen: editPaymentDialogOpen,
    handleOpenDialog: handleOpenEditPaymentDialog,
    handleCloseDialog: handleCloseEditPaymentDialog,
  } = useDialog();

  const adminGetFundEntity = useMemo(
    () => fetchAdminFundEntity(fundsId),
    [fundsId, fetchStatus],
  );

  const { apiData: values, isFetchLoading } = useFetchApi({
    url: adminGetFundEntity,
    defaultApiData: {},
    dependencies: [adminGetFundEntity],
  });

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  ); // trigger useMemo changes to refetch

  const handleEditPayment = useCallback(async (formValues) => {
    const { id, ...payload } = PayloadMappers.admin.fund(formValues);

    await Services.adminUpdateApprovalFund({ id, payload });

    handleCloseEditPaymentDialog();
    handleRefetch();
  }, []);

  const editPaymentForm = useFormik({
    initialValues: {},
    validationSchema: updateInvestmentAmountValidationSchemas,
    validateOnMount: true,
    onSubmit: handleEditPayment,
  });

  useEffect(() => {
    if (values.userInvestorMapId) {
      editPaymentForm.setValues({ ...values.portfolio });
    }
  }, [values.userInvestorMapId]);

  const handleOpenDetailDialog = useCallback(() => {
    setOpenDetailDialog(true);
  }, []);

  const handleCloseDetailDialog = useCallback(() => {
    setOpenDetailDialog(false);
  }, []);

  const handleRedirectApproval = useCallback(() => {
    history.push(`/admin/approvals/details/${values?.userInvestorMapId}`);
  }, [values?.userInvestorMapId]);

  const handleDeleteFund = useCallback(async () => {
    try {
      handleCloseDeleteDialog();

      await Services.adminDeleteFundEntity(fundsId);

      handleRedirectToFundList();
    } catch (err) {
      setError('Delete fund failed');
    }
  }, []);

  const handleResetError = useCallback(() => setError(''), []);

  if (isFetchLoading) return <LoadingSpinner isLoading />;

  return (
    <ApprovalContainer
      values={values}
      handleRefetch={handleRefetch}
      readOnly={!haveCrudPermission}
      onDelete={handleOpenDeleteDialog}
    >
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <InvestmentRequestDetails values={values?.portfolio} />
        </Grid>
        <Grid container item xs={3} alignItems="center">
          <Button
            buttoncolor="purpleHazeGradient"
            onClick={handleOpenDetailDialog}
          >
            Entity Detail
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            sx={(theme) => ({
              background: theme.palette.reach.obsidian,
              padding: 1.5,
              borderRadius: '6px',
              margin: 0,
            })}
            container
            alignItems="center"
          >
            <Grid item xs={12} sx={{ margin: 1 }}>
              <Typography
                variant="display5"
                sx={(theme) => ({ color: theme.palette.text.white })}
              >
                Investment Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider
                variant="middle"
                flexItem
                sx={(theme) => ({
                  backgroundColor: theme.palette.border.light,
                  margin: 0,
                })}
              />
            </Grid>
            <Grid item xs container sx={{ padding: 1 }}>
              {INVESMENT_FUND_LIST_FIELD_DISPLAY.map(
                ({ name, label, md, renderer }) => (
                  <ShowMe when={name !== 'status'} key={`${name}-${label}`}>
                    <Grid item xs md={md}>
                      <FieldContainer label={label}>
                        <Typography
                          variant="display5"
                          sx={(theme) => ({
                            color: theme.palette.text.white,
                          })}
                        >
                          {condTrueFalse(
                            renderer,
                            () => renderer(values?.fund?.[name]),
                            values?.fund?.[name],
                          )}
                        </Typography>
                      </FieldContainer>
                    </Grid>
                  </ShowMe>
                ),
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={3}>
        <PaymentDetailsSummary
          title="Payment Details"
          withEdit={haveCrudPermission}
          sectionListView
          values={values?.portfolio || {}}
          onClickEdit={handleOpenEditPaymentDialog}
        />
      </Grid>
      <ShowMe
        when={
          values?.portfolio?.paymentType === PAYMENT_OPTIONS.PAY_IN_INSTALLMENT
        }
      >
        <Grid item xs={12}>
          <InstallmentInformationSummary
            title="Installment Information"
            withEdit={false}
            sectionListView
            values={values?.portfolio || {}}
          />
        </Grid>
      </ShowMe>
      <Grid item xs={12}>
        <BankDetails
          title="Bank Details"
          withEdit={false}
          sectionListView
          values={values?.bankAccount}
        />
      </Grid>

      {openDetailDialog && (
        <EntityDetailDialog
          open={!!openDetailDialog}
          onClose={handleCloseDetailDialog}
          type={values.investmentType}
          onEditEntity={handleRedirectApproval}
          entityId={values.userInvestorMapId}
          readOnly={haveCrudPermission}
        />
      )}

      <ShowMe when={editPaymentDialogOpen && haveCrudPermission}>
        <AdminConfirmationDialog
          open
          title="Edit Payment"
          onClose={handleCloseEditPaymentDialog}
          onConfirm={editPaymentForm.submitForm}
          confirmButtonLabel="Submit"
          confirmBtnDisabled={!!Object.keys(editPaymentForm.errors).length}
          dialogContentProps={{ style: { maxHeight: '800px' } }}
        >
          <PaymentForm
            currency={values.currency}
            onChange={editPaymentForm.handleChange}
            setValues={editPaymentForm.setValues}
            setFieldValue={editPaymentForm.setFieldValue}
            values={editPaymentForm.values}
            errors={editPaymentForm.errors}
            withApprovedAt={[
              FUND_ENTITY_STATUS.STATUSES.PAYMENT_COMPLETED,
            ].includes(
              FUND_ENTITY_STATUS[values?.portfolio?.paymentStatus]?.status,
            )}
          />
          {editPaymentForm.values.paymentType ===
            PAYMENT_OPTIONS.PAY_IN_INSTALLMENT && (
            <InstallmentInformation
              onChange={editPaymentForm.handleChange}
              currency={values.currency}
              values={editPaymentForm.values}
              errors={editPaymentForm.errors}
            />
          )}
        </AdminConfirmationDialog>
      </ShowMe>

      <ConfirmationDialog
        open={!!isDeleteDialogOpen}
        title="Warning!"
        infoText=""
        subtitle="Do you want to delete this fund ?"
        onClose={handleCloseDeleteDialog}
        confirmButtonLabel="Delete"
        onConfirm={handleDeleteFund}
        dialogContentDependencies={[handleDeleteFund]}
      />
      <FailedDialog
        open={!!error}
        onConfirm={handleResetError}
        subtitle={error}
      />
    </ApprovalContainer>
  );
};

export default FundDetails;
