import mapValue from 'utils/mapValue';

const ARTICLE_DEFAULT_VALUES = {
  article: {
    id: '',
    articleUid: '',
    category: '',
    summary: '',
    author: '',
    date: new Date(),
    tags: '',
  },
  isHidden: false,
  isFeatured: false,
  createdBy: '',
  createdAt: new Date().toISOString(),
  updatedBy: '',
  updatedAt: '',
};

const mapImgSrc = ({ url, fileName }) => (url ? { url, fileName } : {});

const article = (values) => ({
  article: {
    ...ARTICLE_DEFAULT_VALUES.article,
    ...values.article,

    articleHeaderImg: mapImgSrc({
      url: values.article?.articleHeaderImgUrl,
      fileName: values.article?.articleHeaderImgKey,
    }),
    articleLogoImg: mapImgSrc({
      url: values.article?.articleLogoImgUrl,
      fileName: values.article?.articleLogoImgKey,
    }),
    articleDocument: {
      // blob: new Blob([file], { type: file.type }),
      // url: URL.createObjectURL(file),
      fileName: values.article?.document?.documentName,
      // file,
    },
  },
  isHidden: values.article?.isHidden,
  isFeatured: values.article?.isFeatured,
  createdBy: values.article?.createdBy,
  updatedBy: values.article?.updatedBy,
  createdAt: mapValue(
    values.article?.created_at,
    ARTICLE_DEFAULT_VALUES.article.createdAt,
  ),
  updatedAt: mapValue(
    values.article?.updated_at,
    ARTICLE_DEFAULT_VALUES.article.updatedAt,
  ),
});

export default article;
