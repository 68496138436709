import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';

import ColorPicker from 'components/ColorPicker';
import FieldListContainer from 'components/FieldListContainer';
import FieldContainer from 'components/FieldContainer';
import Input from 'components/Input';
import ShowMe from 'components/ShowMe';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const PieSection = ({
  index,
  values,
  onChange,
  onDeletePieSection,
  readOnly,
}) => {
  const handleChange = useCallback(
    (e) => {
      onChange(e, index);
    },
    [index, onChange],
  );

  const handleDeletePieSection = useCallback(() => {
    onDeletePieSection(index);
  }, [onDeletePieSection]);

  return (
    <FieldListContainer listNo={index + 1}>
      <Grid item xs={4.25} md={4.75}>
        <FieldContainer
          label="Slice Details"
          direction="column"
          alignItems="unset"
        >
          <Input
            fullWidth
            name="sliceDetails"
            value={values.sliceDetails}
            onChange={handleChange}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={4.25} md={4.75}>
        <FieldContainer label="Value" direction="column" alignItems="unset">
          <Input
            fullWidth
            name="value"
            value={values.value}
            onChange={handleChange}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>
      <Grid item xs alignSelf="end">
        <FieldContainer label="" direction="column" alignItems="unset">
          <ColorPicker
            name="color"
            value={values.color}
            onChange={handleChange}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>
      <ShowMe when={!readOnly}>
        <Grid
          item
          xs="auto"
          onClick={handleDeletePieSection}
          sx={{ cursor: 'pointer', paddingBottom: 2, alignSelf: 'end' }}
        >
          <TrashIcon />
        </Grid>
      </ShowMe>
    </FieldListContainer>
  );
};

export default memo(PieSection);
