import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useActions, useStores } from 'components/StateProvider';

import history from 'utils/history';

import Services from 'api/services';

import VerificationAlreadyActivated from './VerificationAlreadyActivated';
import VerificationExpired from './VerificationExpired';
import VerificationSuccess from './VerificationSuccess';

const EmailVerificationPages = {
  activated: VerificationAlreadyActivated,
  expired: VerificationExpired,
  success: VerificationSuccess,
};

const EmailVerification = () => {
  const {
    auth: { isAuthenticated },
  } = useStores();
  const { handleLogout } = useActions();
  const [searchParams] = useSearchParams();
  const [Component, setComponent] = useState(null);
  const email = searchParams.get('email');
  const mobileNumber = searchParams.get('mobileNumber');
  const fullName = searchParams.get('fullName');

  const resendVerificationEmailPayload = useMemo(
    () => ({
      email,
      mobileNumber: mobileNumber?.replace(/\s/g, ''),
    }),
    [email, mobileNumber],
  );

  const handleShowEmailVerificationComponent = (verifyResultCode) => {
    switch (verifyResultCode) {
      case 'EXPIRED':
        setComponent(EmailVerificationPages.expired);
        break;
      case 'ACTIVATED':
        setComponent(EmailVerificationPages.activated);
        break;
      case 'SUCCEED':
        setComponent(EmailVerificationPages.success);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isAuthenticated) handleLogout();

    const handleVerifyEmail = async () => {
      let verifyResult;
      try {
        verifyResult = await Services.verifyEmail({
          token: window.location.search.replace('?token=', ''),
        });
      } catch (err) {
        verifyResult = err?.response;
      } finally {
        handleShowEmailVerificationComponent(verifyResult?.data?.code);
      }
    };
    handleVerifyEmail();
  }, []);

  const handleLogin = useCallback(() => history.push('login'), [history]);

  return (
    <div>
      {Component && (
        <Component
          data-testid="email-verification"
          handleLogin={handleLogin}
          resendVerificationEmailPayload={resendVerificationEmailPayload}
          fullName={fullName}
        />
      )}
    </div>
  );
};

export default EmailVerification;
