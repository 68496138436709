import * as yup from 'yup';

const addressValidationSchema = yup.object({
  firstAddress: yup.string().trim().max(255).required().label('Address line 1'),
  secondAddress: yup.string().trim().max(255).label('Address line 2'),
  suburb: yup.string().trim().max(100).required().label('Suburb'),
  city: yup.string().trim().max(100).required().label('City'),
  state: yup.string().required().label('State'),
  postCode: yup.string().trim().max(12).required().label('Postcode'),
  addressCountry: yup.string().required().label('Country'),
});

export default addressValidationSchema;
