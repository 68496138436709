import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';

import Chip from 'components/Chip';
import FundCardHeaderAccordion from 'components/Accordion/FundCardHeaderAccordion';
import FormDivider from 'components/OptionDivider/FormDivider';

import history from 'utils/history';
import palette from 'theme/palette';

import { getPaymentStatus } from 'constants/investmentEntity';
import { getFundStatusChipColor } from 'utils/chipMappers';

import { getAccordionFooter } from '../../PortfolioTab/HelperFunction';
import PortfolioUpdates from './PortfolioUpdates';

const handleRouteToRedemptionRequestPage = () =>
  history.push('/redemption-request');

const PortfolioList = ({ portfolios = [] } = {}) => {
  const getContentList = useCallback(
    (portfolio) => [
      {
        heading: 'Entity',
        value: portfolio?.name,
        spacing: 3,
      },
      {
        heading: 'Currency',
        value: portfolio?.currency,
        spacing: 3,
      },
      {
        heading: 'Status',
        value: (
          <Chip
            styles={{ display: 'block' }}
            label={getPaymentStatus(portfolio?.paymentStatus)}
            color={getFundStatusChipColor({
              status: portfolio?.status,
              paymentStatus: portfolio?.paymentStatus,
            })}
          />
        ),
        spacing: 3,
      },
    ],
    [],
  );

  const getAccordionContent = useCallback(({ investment }) => {
    let content = '';

    if (investment?.portfolio)
      content = <PortfolioUpdates portfolio={investment.portfolio} />;

    const footer = getAccordionFooter({
      investment,
      portfolioStatus: investment?.portfolio?.paymentStatus?.toLowerCase(),
      isRedemptionVisible: investment?.portfolio?.fund?.isRedemptionVisible,
      onClickRedeemBtn: handleRouteToRedemptionRequestPage,
    });

    return (
      <>
        {content}
        <FormDivider bordercolor={palette.reach.primary} width="100%" />
        {footer}
      </>
    );
    // return content;
  }, []);

  return (
    <Grid container rowSpacing={3}>
      {portfolios?.map((portfolio) => (
        <Grid item xs={12} key={portfolio.id}>
          <FundCardHeaderAccordion
            key={portfolio.id}
            logoSrc={portfolio?.fundHeaderStorage?.logo}
            headerImageSrc={portfolio?.fundHeaderStorage?.header}
            headerTitle={portfolio?.portfolio?.fund?.fundName}
            headerDesc={portfolio?.portfolio?.fundConfiguration?.className}
            chipLabel={portfolio?.portfolio?.status?.toLowerCase()}
            chipColor={`${portfolio?.portfolio?.status?.toLowerCase()}ChipColor`}
            contentList={getContentList({
              ...portfolio,
              ...portfolio?.portfolio,
            })}
            childGridSx={{ padding: 0 }}
          >
            {getAccordionContent({ investment: portfolio })}
          </FundCardHeaderAccordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(PortfolioList);
