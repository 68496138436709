import React, { useEffect } from 'react';

import { Grid, Icon, IconButton, Typography } from '@mui/material';

import Link from 'components/Link';
import ShowMe from 'components/ShowMe';
import { useActions } from 'components/StateProvider';

import { ReactComponent as ReachLogoWhite } from 'assets/icons/reach-logo-white.svg';
import { ReactComponent as Linkedin } from 'assets/icons/linkedin.svg';
import { ReactComponent as WebIcon } from 'assets/icons/web-icon.svg';
import defaultSidebarImage from 'assets/images/default-sidebar-container-img.png';
import config from 'utils/config';

const SidebarContainer = ({
  children,
  backgroundImage = defaultSidebarImage,
  withCompanyLogo,
  ...props
}) => {
  const { handleSetSidebar } = useActions();

  useEffect(() => {
    handleSetSidebar(true);
    return () => {
      handleSetSidebar(false);
    };
  }, []);

  return (
    <Grid container {...props}>
      <Grid
        item
        sx={{
          position: 'fixed',
          width: config.drawer.admin.width,
          height: '100%',
          top: 0,
          left: 0,
          justifyContent: 'center',
          backgroundImage: `url(${backgroundImage})`,
        }}
        display={{ xs: 'none', md: 'initial' }}
      >
        <Grid
          container
          p={10}
          sx={{
            background: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100%',
            alignContent: 'space-between',
          }}
        >
          <Grid item xs={12}>
            <ReachLogoWhite width={120} />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography color="#B0B0B0" variant="xsmall">
                SOCIAL MEDIA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <IconButton>
                <Link
                  href="https://www.linkedin.com/company/reachalts"
                  target="_blank"
                >
                  <Linkedin />
                </Link>
              </IconButton>
              <IconButton>
                <Link href="https://www.reachalts.com.au" target="_blank">
                  <WebIcon />
                </Link>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ShowMe when={withCompanyLogo}>
        <Grid
          item
          xs={12}
          textAlign="center"
          display={{ xs: 'initial', md: 'none' }}
        >
          <Icon
            style={{ width: '6.5em', height: '1.75em' }}
            // onClick={handleRouteToDashboard}
          >
            <img
              alt="logo-company"
              src="/assets/images/Reach-logo.png"
              height="100%"
              width="100%"
            />
          </Icon>
        </Grid>
      </ShowMe>
      <Grid item xs={12} p={3} pt={{ xs: 5, md: 3 }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default SidebarContainer;
