import countries from 'utils/countries';
import PRESERVED_COUNTRY_FLAGS from 'constants/preservedCountries';

export const PRESERVED_COUNTRIES = ['AU', 'NZ'];
export const AUSTRALIA_DEFAULT_VALUE = {
  code: 'AU',
  label: 'Australia',
  phone: '61',
  suggested: true,
};

const excludedPreservedCountries = countries.filter(
  (country) => !PRESERVED_COUNTRIES.includes(country.code),
);
export const COUNTRY_OPTIONS_WITH_PRESERVED = PRESERVED_COUNTRY_FLAGS.concat(
  excludedPreservedCountries,
);
