import React, { memo, useCallback, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import palette from 'theme/palette';

import AddButton from 'components/Button/AddButton';
import FieldContainer from 'components/FieldContainer';
import Input from 'components/Input';
import TextEditor from 'components/TextEditor';

import FieldSection from './FieldSection';

const defaultField = {
  heading: '',
  value: '',
};

const SummaryBlockSection = ({ summaryIndex, values, onChange, readOnly }) => {
  const [openNoteEditor, setOpenNoteEditor] = useState(!!values.summaryNote);

  const handleChangeField = useCallback(
    (e, fieldIndex) => {
      const { name, value } = e.target;
      const newFields = [...values.fields];
      const newValue = { ...newFields[fieldIndex], [name]: value };

      newFields[fieldIndex] = { ...newValue };

      const event = {
        target: {
          name: 'fields',
          value: newFields,
        },
      };

      onChange(event);
    },
    [onChange, values.fields],
  );

  const handleAddField = useCallback(() => {
    const newFields = [...values.fields];

    newFields.push(defaultField);

    const event = {
      target: {
        name: 'fields',
        value: newFields,
      },
    };
    onChange(event);
  }, [onChange]);

  const handleDeleteField = useCallback(
    (fieldIndex) => {
      const newFields = [...values.fields];

      newFields.splice(fieldIndex, 1);

      const event = {
        target: {
          name: 'fields',
          value: newFields,
        },
      };

      onChange(event);
    },
    [onChange],
  );

  const handleOpenNoteEditor = useCallback(() => {
    setOpenNoteEditor(true);
  }, []);

  return (
    <Grid
      container
      rowSpacing={{ xs: 2, lg: 3 }}
      sx={{
        padding: 2,
        paddingTop: 0,
        marginTop: '0px !important',
        border: `1px solid ${palette.form.disabledBorder}`,
      }}
    >
      <Grid
        item
        xs={12}
        borderBottom={`1px solid ${palette.form.disabledBorder}`}
      >
        <Typography variant="display5">Summary Blocks</Typography>
      </Grid>

      <Grid item xs={5}>
        <FieldContainer label="Title">
          <Input
            fullWidth
            name="title"
            onChange={onChange}
            value={values.title}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>

      {values.fields.map((field, fieldIndex) => (
        <Grid key={`summary-block-field-${fieldIndex.toString()}`} item xs={12}>
          <FieldSection
            index={fieldIndex}
            values={field}
            readOnly={readOnly}
            onChange={handleChangeField}
            onDeleteFieldSection={handleDeleteField}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <AddButton
          label="Add additional fields"
          iconColor={palette.reach.bluish}
          textColor="bluish"
          onClick={handleAddField}
          sx={{ marginTop: 0 }}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {openNoteEditor ? (
          <TextEditor
            id={`summary-blockSection-${summaryIndex.toString()}`}
            onChange={onChange}
            value={values.summaryNote}
            name="summaryNote"
            readOnly={readOnly}
          />
        ) : (
          <AddButton
            label="Add note to appear under pie chart"
            iconColor={palette.reach.bluish}
            textColor="bluish"
            sx={{ marginTop: 0 }}
            onClick={handleOpenNoteEditor}
            readOnly={readOnly}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default memo(SummaryBlockSection);
