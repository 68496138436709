import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import config from 'utils/config';

const EmptyContainer = ({ testId, text }) => (
  <Grid
    data-testid={testId}
    container
    alignContent="center"
    sx={{
      borderTop: `8px ${config.system.appColours.border} solid`,
      transition: 'border-top .5',
      py: [2, 5],
    }}
  >
    <Grid
      container
      item
      xs={12}
      alignContent="center"
      sx={{ placeContent: { xs: 'center', md: 'inherit' } }}
    >
      <Typography variant="h6">{text}</Typography>
    </Grid>
  </Grid>
);

export default EmptyContainer;
