import React, { useCallback } from 'react';

import InitialValues from 'constants/InitialValues';
import PayloadMappers from 'utils/PayloadMappers';
import addBeneficialOwnerValidationSchema from 'validations/schema/adminUpdateEntity/addBeneficialOwnerValidationSchema';

import AddEntityDialog from './AddEntityDialog';

const AddBeneficialOwnerDialog = ({
  open,
  onClose,
  onSubmit,
  entityUpdateKey,
  ...props
}) => {
  const handleSubmit = useCallback((formValues) => {
    const addKey = `add${entityUpdateKey}`;
    const payload = PayloadMappers.personalDetails(formValues);

    onSubmit({
      [addKey]: payload,
    });
  }, []);

  return (
    <AddEntityDialog
      open={open}
      onClose={onClose}
      title="Add Beneficial Owner"
      type="beneficialOwner"
      onSubmit={handleSubmit}
      initialValues={InitialValues.personalDetail}
      validationSchema={addBeneficialOwnerValidationSchema}
      {...props}
    />
  );
};

export default AddBeneficialOwnerDialog;
