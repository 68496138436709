import React from 'react';

import Grid from '@mui/material/Grid';

import InvestmentInfo from './InvestmentInfo';
import DetailsTabs from './DetailsTabs';

const InvestedPortfolioDetails = ({ investment = {} }) => (
  <Grid container rowSpacing={4}>
    <InvestmentInfo portfolio={investment?.portfolio} />

    <DetailsTabs portfolio={investment?.portfolio} />
  </Grid>
);

export default InvestedPortfolioDetails;
