import admin from './admin';
import fundDetails from './fundDetails';
import investmentEntityUserDetailList from './investmentEntityUserDetailList';
import personalDetail from './personalDetail';

export default {
  admin,
  fundDetails,
  personalDetail,
  investmentEntityUserDetailList,
};
