import React, { memo } from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import Tab from 'components/Tab';

const TabComponents = ({
  activeTabIdx,
  tabItems,
  style,
  testIdPrefix = 'fund-detail-tab',
  dependencyKey,
  ...props
}) =>
  tabItems.map(
    (
      {
        key,
        label,
        Icon,
        disabled,
        iconColorSelected = 'active-icon-color-inverse',
        hidden,
      },
      index,
    ) => (
      <Tab
        {...props}
        hidden={!!hidden}
        id={`${key}-${activeTabIdx}`}
        key={`${key}-${dependencyKey}`}
        data-testid={`${testIdPrefix}-${key}`}
        value={index}
        selected={index === activeTabIdx}
        icon={disabled ? LockOutlinedIcon : Icon}
        label={label}
        iconPosition="start"
        iconColorSelected={iconColorSelected}
        iconColor="icon-color"
        sx={(theme) => ({
          height: '40px',
          minHeight: '40px',
          backgroundColor:
            index === activeTabIdx && theme.palette.reach.fuchsia,
          border:
            index !== activeTabIdx
              ? `1px solid ${theme.palette.reach.fuchsia}`
              : undefined,
          '& .MuiTypography-root': {
            color:
              index === activeTabIdx
                ? `${theme.palette.reach.white} !important`
                : theme.palette.reach.inactiveGrey,
          },
          mr: 1,
          ...style,
        })}
      />
    ),
  );

export default memo(TabComponents);
