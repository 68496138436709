import countries from 'utils/countries';
import mapValue from 'utils/mapValue';

export const mapResponsePersonalDetail = (personalDetailResponse) => {
  const {
    mobileNumber,
    parsedMobileNumber,
    citizenship,
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    email,
  } = personalDetailResponse;

  const phoneNumber = parsedMobileNumber
    ? parsedMobileNumber.number
    : mobileNumber;
  const countryCode = parsedMobileNumber
    ? countries.find((_country) => _country.phone === parsedMobileNumber.code)
    : '';

  return {
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    email,
    countryCode,
    phoneNumber,
    country: citizenship,
  };
};

export const mapResponseAddressDetail = (personalDetailResponse) => ({
  firstAddress: personalDetailResponse?.address1,
  secondAddress: personalDetailResponse?.address2,
  addressCountry: personalDetailResponse?.country,
  postCode: personalDetailResponse?.postcode,
  city: personalDetailResponse?.city,
  state: personalDetailResponse?.state,
  suburb: personalDetailResponse?.suburb,
});

const personalDetail = (personalDetailResponse = {}) => {
  const {
    mobileNumber,
    citizenship,
    address1,
    address2,
    country,
    postcode,
    idTypeName,
    accountantCertificate,
    parsedMobileNumber,
    cardNumber,
    stateOfIssue,
    ...otherDetail
  } = personalDetailResponse;

  return {
    ...otherDetail,
    ...mapResponsePersonalDetail(personalDetailResponse),
    ...mapResponseAddressDetail(personalDetailResponse),
    idType: idTypeName,
    certificates: accountantCertificate?.map((certificateName) => ({
      fileName: certificateName,
    })),
    cardNumber: mapValue(cardNumber, ''),
    stateOfIssue: mapValue(stateOfIssue, ''),
  };
};

export default personalDetail;
