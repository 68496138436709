import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dropdown from 'components/Dropdown';
import FormTitle from 'components/FormTitle';
import InputField from 'components/Input';
import FieldContainer from 'components/FieldContainer';
import SwitchWithLabel from 'components/Switch/SwitchWithLabel';

import { COUNTRY_OPTIONS } from 'constants/investmentEntity';

const CustodialBankDetails = ({
  onChange,
  values,
  errors,
  onBlur,
  readOnly,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Custodial Bank Details" />
    </Grid>

    <Grid container item xs={12} p={2} rowSpacing={3}>
      <Grid item xs={12}>
        <SwitchWithLabel
          name="isShowBankDetails"
          onChange={onChange}
          leftLabel={
            <Typography
              variant="small"
              sx={{ fontWeight: '700 !important', color: 'black !important' }}
            >
              Hide details
            </Typography>
          }
          rightLabel={
            <Typography
              variant="small"
              sx={{ fontWeight: '700 !important', color: 'black !important' }}
            >
              Show details
            </Typography>
          }
          height={30}
          width={60}
          checked={values.isShowBankDetails}
          customcolor="black"
          containerProps={{ justifyContent: 'center' }}
        />
      </Grid>
      <Grid container item columnSpacing={2}>
        <FieldContainer item xs={6} label="Account Name">
          <InputField
            fullWidth
            data-testid="bank-details-accountName-input"
            id="accountName"
            name="accountName"
            placeholder="Bank account name*"
            onChange={onChange}
            onBlur={onBlur}
            value={values.accountName}
            error={!!errors.accountName}
            disabled={readOnly}
          />
        </FieldContainer>
        <FieldContainer item xs={6} label="Bank name">
          <InputField
            fullWidth
            data-testid="bank-details-bankName-input"
            id="bankName"
            name="bankName"
            placeholder="Bank name*"
            onChange={onChange}
            onBlur={onBlur}
            value={values.bankName}
            error={!!errors.bankName}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>
      <Grid container item columnSpacing={2}>
        <FieldContainer item xs={6} label="Account number">
          <InputField
            fullWidth
            type="number"
            data-testid="financial-info-accountNumber-input"
            id="accountNumber"
            name="accountNumber"
            placeholder="Account number"
            onChange={onChange}
            onBlur={onBlur}
            value={values.accountNumber}
            error={!!errors.accountNumber}
            disabled={readOnly}
          />
        </FieldContainer>
        <FieldContainer item xs={6} label="BSB">
          <InputField
            fullWidth
            type="number"
            data-testid="financial-info-bsb-input"
            id="bsb"
            name="bsb"
            placeholder="BSB Number"
            onChange={onChange}
            onBlur={onBlur}
            value={values.bsb}
            error={!!errors.bsb}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>
      <Grid container item columnSpacing={2}>
        <FieldContainer item xs={6} label="SWIFT code">
          <InputField
            fullWidth
            data-testid="financial-info-swiftCode-input"
            id="swiftCode"
            name="swiftCode"
            placeholder="SWIFT"
            onChange={onChange}
            onBlur={onBlur}
            value={values.swiftCode}
            error={!!errors.swiftCode}
            disabled={readOnly}
          />
        </FieldContainer>
        <FieldContainer item xs={6} label="Country">
          <Dropdown
            options={COUNTRY_OPTIONS}
            onChange={onChange}
            name="country"
            value={values.country}
            error={!!errors.country}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>
      <Grid container item>
        <FieldContainer item xs={12} label="Bank address*">
          <InputField
            fullWidth
            data-testid="financial-info-bankAddress-input"
            id="bankAddress"
            name="bankAddress"
            placeholder="Bank address*"
            onChange={onChange}
            onBlur={onBlur}
            value={values.bankAddress}
            error={!!errors.bankAddress}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>
    </Grid>
  </Grid>
);

export default memo(CustodialBankDetails);
