import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AuthContainer from 'components/AuthContainer/indexv2';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import history from 'utils/history';
import palette from 'theme/palette';

const handleLogin = () => history.replace('/login');

const PasswordUpdated = () => (
  <AuthContainer
    title="Password Updated"
    nextLabel="Go to login"
    onBack={handleLogin}
    onNext={handleLogin}
    linearColor={palette.reach.green}
  >
    <Grid
      container
      spacing={3}
      direction="row"
      style={{
        maxHeight: '-webkit-fill-available',
        textAlign: '-webkit-center',
        marginTop: '30px',
      }}
    >
      <Grid item xs={12}>
        <CheckIcon height={44} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="bodyGrey">
          Your password has been changed successfully.
          <br />
          Use your new password to login.
        </Typography>
      </Grid>
    </Grid>
  </AuthContainer>
);

export default memo(PasswordUpdated);
