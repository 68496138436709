import React from 'react';

import { Typography } from '@mui/material';

const points = [
  <>
    <b>Capital called</b> is the amount of your commitment you have paid to date.
  </>,
  <>
    <b>Growth</b> is the cumulative unrealised $ value of your holdings excluding your capital contribution.
  </>,
  <>
    <b>Distributions</b> are the cumulative $ value of the amounts paid to you from the fund.
  </>,
  <>
    <b>Total Return</b> is both the ‘Growth’ & ‘Distributions’ together (i.e. the $ value of the realised and unrealised parts of your investment, excluding capital called).
  </>,
];

const importantNote = `It is important to note in the early years, during the investment phase, returns will likely be lower (or negative) due to the time required for investments to mature and generate returns. Consequently, early performance should not be seen as indicative of the fund's long-term potential, with returns likely escalating towards the end of the fund’s lifecycle. Past performance is not a guarantee nor necessarily a reliable indicator of future performance.`;

const Notes = () => (
  <>
    <Typography fontWeight={700}>Notes:</Typography>
    <Typography>As a guide to terminology:</Typography>
    <ul>
      {points.map((point) => (
        <li>{point}</li>
        ))}
    </ul>
    <Typography>{importantNote}</Typography>
  </>
);

export default Notes;
