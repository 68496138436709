import * as yup from 'yup';

import individualFormValidationSchema from './individualFormValidationSchema';
import addressValidationSchema from './addressValidationSchema';

const auVisaValidationSchema = yup.object({
  visaCountryOfIssue: yup.string().required().label('Country of Issue'),
  visaPassportNumber: yup.string().required().label('Passport Number'),
  ...individualFormValidationSchema(),
  ...addressValidationSchema(),
});

export default auVisaValidationSchema;
