import currencyCodes from 'constants/currencyCodes';

const formatCurrency = ({
  region = 'en-US',
  currency = 'USD',
  params = { maximumFractionDigits: '2' },
  value,
}) => {
  if (!currency) return value;

  const currencyCode = currencyCodes[currency] || '';

  const formattedCurrency = new Intl.NumberFormat(region, {
    style: currencyCode ? 'decimal' : 'currency',
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
    roundingMode: 'halfCeil',
    ...params,
  }).format(value);

  return `${currencyCode + formattedCurrency}`.replace(/^(\D+)/, '$1 ');
};

export default formatCurrency;
