import React, { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';

import DataTable from 'components/DataTable';

import Services from 'api/services';
import useDataTables from 'hooks/useDataTables';

import { getPortfolioHeaders, mapPortfoliosData } from './HelperFunction';

const PortfolioList = ({ investmentEntityId, values, setValues }) => {
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: () =>
      Services.getInvestedPortfoliosByInvestmentEntity(investmentEntityId),
    defaultApiData: [],
    dependencies: [investmentEntityId],
    mapApiData: mapPortfoliosData,
  });

  const handleSelectPortfolio = useCallback(
    (portfolio) => {
      setValues({
        ...values,
        selectedPortfolioId: portfolio.id,
        selectedPortfolio: portfolio,
      });
    },
    [values],
  );

  const portfolioHeaders = useMemo(
    () =>
      getPortfolioHeaders({
        onClickRedeem: handleSelectPortfolio,
        selectedPortfolioId: values.selectedPortfolioId,
      }),
    [values],
  );

  return (
    <>
      <Typography variant="body">
        Please select the Fund you&apos;d like to redeem from:
      </Typography>

      <DataTable
        hideFooter
        pagination={false}
        withCheckbox={false}
        headerData={portfolioHeaders}
        data={apiData}
        setSort={setSort}
        isLoading={isFetchLoading}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  );
};

export default PortfolioList;
