import React from 'react';
import SwitchCase from 'components/SwitchCase';
import isFunction from 'utils/isFunction';
import PersonDetail from './PersonDetail';
import CompanyDetail from './CompanyDetail';

const TrusteeDetails = ({
  values,
  certificateDownloadable,
  onDelete,
  setValues = () => {
    /* empty function to avoid error on unit test */
  },
  ...props
}) =>
  values.map((trustee, index) => (
    <SwitchCase
      key={`trustee-${trustee?.label}-${
        trustee?.lastName || trustee?.companyName
      }`}
      keymatch="key"
      matchValue={trustee?.label?.toLowerCase()}
      defaultCase=""
    >
      <PersonDetail
        key="individual"
        values={trustee}
        title="Trustee Individual Details"
        withChip
        isBeneficialOwner={false}
        setValues={setValues}
        certificateDownloadable={certificateDownloadable}
        isJoint
        onDelete={isFunction(onDelete) && onDelete(index)}
        {...props}
      />
      <CompanyDetail
        key="company"
        values={trustee}
        withChip
        label={trustee?.companyName}
        setValues={setValues}
        certificateDownloadable={certificateDownloadable}
        onDelete={isFunction(onDelete) && onDelete(index)}
        {...props}
      />
    </SwitchCase>
  ));

export default TrusteeDetails;
