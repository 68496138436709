import React from 'react';

import SecondaryButton from 'components/Button/SecondaryButton';

const SecondaryButtonStyles = {
  width: '50%',
  fontWeight: { xs: 400, sm: 700 },
  marginBottom: '10px',
  minHeight: { xs: '39px', sm: 'auto' },
};

const FundCardButton = ({ label, sx = {}, backgroundColor, ...props }) => (
  <SecondaryButton
    variant="outlined"
    sx={{ ...SecondaryButtonStyles, ...sx }}
    {...props}
  >
    {label}
  </SecondaryButton>
);

export default FundCardButton;
