import Services from 'api/services';

// eslint-disable-next-line
export const fetchColours = async () => {
  try {
    const res = await Services.adminGetPlatformConfigurationColours();
    return res?.data;
  } catch (_) {
    return [];
  }
};
