import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DataTable from 'components/DataTable';

import {
  dateCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const headers = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'irrNet',
    headerName: 'IRR (Net)',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'pme',
    headerName: 'PME',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: props.value, fractions: 2 }),
  },
];

const HistoricalValueList = ({ data = [] }) => (
  <Grid container rowSpacing={3}>
    <Grid item xs={12} alignItems="center" columnSpacing={2}>
      <Typography fontWeight="700 !important">Historical Values</Typography>
    </Grid>
    <Grid item xs={12}>
      <DataTable
        wrapheader
        hideFooter
        pagination={false}
        withCheckbox={false}
        headerData={headers}
        data={data}
        containerProps={{ backgroundColor: 'white' }}
      />
    </Grid>
  </Grid>
);

export default HistoricalValueList;
