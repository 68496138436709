import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import Chip from 'components/Chip';
import InnerHtmlContainer from 'components/InnerHtmlContainer';
import ShowMe from 'components/ShowMe';

import config from 'utils/config';
import { getPaymentStatus } from 'constants/investmentEntity';
import { getFundStatusChipColor } from 'utils/chipMappers';
import FundCardButton from './components/FundCardButton';

const fundContentBorderTopWidth = 8;

const CashFundCard = ({
  height = 88,
  fundName,
  overview,
  onClickFund,
  isInvested,
  portfolios = [],
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const fundPortfolioStatusChips = useMemo(
    () => (
      <ShowMe when={isInvested && portfolios.length}>
        {[
          ...new Map(
            portfolios.map((portfolio) => [
              portfolio?.paymentStatus,
              portfolio,
            ]),
          ).values(),
        ].map((portfolio) => (
          <Chip
            key={portfolio.id}
            style={{ marginLeft: '8px' }}
            height="20px"
            label={getPaymentStatus(portfolio.paymentStatus)}
            color={getFundStatusChipColor({
              status: portfolio.status,
              paymentStatus: portfolio.paymentStatus,
            })}
          />
        ))}
      </ShowMe>
    ),
    [isInvested, portfolios],
  );

  return (
    <Grid
      container
      minHeight={height}
      alignContent="center"
      sx={{
        backgroundColor: config.system.appColours.cardBackground,
        borderTop: `${fundContentBorderTopWidth}px black solid`,
        transition: 'border-top .5',
        p: [1, 2],
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={4}
        alignContent="center"
        sx={{ placeContent: { xs: 'center', md: 'inherit' } }}
      >
        <Typography variant="h6" color="#7877CE">
          {fundName}
        </Typography>
        <ShowMe when={!isMobileScreen}>{fundPortfolioStatusChips}</ShowMe>
      </Grid>
      <Grid container item xs={12} md={4}>
        <InnerHtmlContainer htmlData={overview} backgroundColor="transparent" />
      </Grid>
      <Grid container item xs={12} md={4} sx={{ placeContent: 'center' }}>
        <ShowMe when={isMobileScreen}>
          <FundCardButton label="View details" onClick={onClickFund} />
        </ShowMe>
        <ShowMe when={!isMobileScreen}>
          <Typography
            variant="h6"
            color="#7877CE"
            sx={{ cursor: 'pointer' }}
            onClick={onClickFund}
          >
            MORE &nbsp; &gt;
          </Typography>
        </ShowMe>
      </Grid>
    </Grid>
  );
};

export default memo(CashFundCard);
