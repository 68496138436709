import React from 'react';

import Grid from '@mui/material/Grid';

import AdminFormTitle from 'components/AdminFormTitle';

import condTrueFalse from 'utils/condTrueFalse';

const AdminFieldWrapper = ({
  children,
  title,
  titleContainerProps,
  handleBack,
  ...props
}) => (
  <>
    {title ? (
      <Grid
        item
        {...condTrueFalse(handleBack, {
          container: true,
          xs: 12,
          columnSpacing: 2,
          alignItems: 'center',
        })}
        {...titleContainerProps}
      >
        <AdminFormTitle handleBack={handleBack} title={title} />
      </Grid>
    ) : null}
    <Grid item ml={1} xs={12} {...props}>
      {children}
    </Grid>
  </>
);

export default AdminFieldWrapper;
