import React from 'react';

import palette from 'theme/palette';

const HeaderImageStyles = {
  background: palette.reach.white,
  margin: '14px',
};

const FundCardHeaderImage = ({ logoSrc, ...props }) => (
  <img
    src={logoSrc}
    alt="company-logo"
    width="65px"
    height="100%"
    {...props}
    style={{
      ...HeaderImageStyles,
      ...props?.style,
    }}
  />
);

export default FundCardHeaderImage;
