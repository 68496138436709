import React, { memo, useEffect, useMemo, useState } from 'react';

import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';
import ShowMe from 'components/ShowMe';
import AutoPopulateChipList from 'components/AutoPopulateChipList';

import useSteps from 'hooks/useSteps';
import lazyLoad from 'utils/lazyLoad';

import companyPersonalDetailsValidations from 'validations/schema/addInvestment/company/companyPersonalDetailsValidationSchemas';
import InitialValues from 'constants/InitialValues';

const CompanyDetailsForm = lazyLoad(() =>
  import('../../../components/Forms/CompanyDetailsForm'),
);
const AddressForm = lazyLoad(() =>
  import('../../../components/Forms/AddressForm'),
);
const AccountantCertificateForm = lazyLoad(() =>
  import('../../../components/Forms/AccountantCertificateForm'),
);
const SummaryForm = lazyLoad(() =>
  import('../../../components/Forms/SummaryForm'),
);

const stepComponents = [
  <CompanyDetailsForm />,
  <AddressForm />,
  <AccountantCertificateForm />,
  <SummaryForm type="Company" />,
];
const stepTitles = [
  'Company Details',
  'Company Registered Address',
  'Accountant’s Certificate',
  'Summary',
];

const CompanyDetails = ({
  onNext,
  onBack,
  SideIcon,
  startAtLastStep,
  steps,
  setValues,
  values,
  activeStep,
  userDetailList,
}) => {
  const [selectedCompanyDetailIndex, setSelectedCompanyDetailIndex] =
    useState('');
  const handleNextLastPage = ({ values: companyDetailsValues }) => {
    if (setValues)
      setValues({ ...values, companyDetails: companyDetailsValues });
    onNext();
  };

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: companyDetailsForm,
  } = useSteps({
    stepComponents,
    validateFormik: true,
    validationSchemas: companyPersonalDetailsValidations,
    formParameters: {
      initialValues: {
        ...InitialValues.companyDetail,
        ...values?.companyDetails,
      },
    },
    onNextInLastPage: handleNextLastPage,
    onBackInFirstPage: onBack,
    defaultPageIndex: startAtLastStep ? stepComponents.length - 1 : 0,
  });

  useEffect(() => {
    companyDetailsForm?.validateForm();
  }, [pageIndex, companyDetailsForm?.validateForm]);

  const handleSelectAutoPopulateUser = ({ index, recordedData }) => {
    companyDetailsForm.setValues({
      ...companyDetailsForm.values,
      ...recordedData[index],
    });

    setSelectedCompanyDetailIndex(index);
  };

  const headChildren = useMemo(() => {
    if (pageIndex !== 1) return null;

    const recordedData = userDetailList.addresses;

    return (
      <ShowMe when={recordedData}>
        <AutoPopulateChipList
          items={recordedData}
          onClick={(index) =>
            handleSelectAutoPopulateUser({ index, recordedData })
          }
          selectedIndex={selectedCompanyDetailIndex}
        />
      </ShowMe>
    );
  }, [selectedCompanyDetailIndex, userDetailList, pageIndex]);

  return (
    <InvestmentEntityStepContainer
      containerProps={{
        onBack: handleBack,
        onNext: handleNext,
        isNextButtonDisabled: !!Object.keys(companyDetailsForm?.errors).length,
      }}
      SideIcon={SideIcon}
      steps={steps}
      activeStep={activeStep}
      stepTitle={stepTitles[pageIndex]}
      headChildren={headChildren}
    >
      {React.cloneElement(stepComponents[pageIndex], {
        errors: companyDetailsForm.errors,
        values: companyDetailsForm.values,
        touched: companyDetailsForm.touched,
        onChange: companyDetailsForm.handleChange,
        onBlur: companyDetailsForm.handleBlur,
        setFieldError: companyDetailsForm.setFieldError,
        setFieldValue: companyDetailsForm.setFieldValue,
        setValues: companyDetailsForm.setValues,
      })}
    </InvestmentEntityStepContainer>
  );
};

export default memo(CompanyDetails);
