import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';

import DataTable from 'components/DataTable';
import {
  getFirstOverviewHeaders,
  getSecondOverviewHeaders,
} from './HelperFunction';

const Overview = ({ firstOverview = [], secondOverview = [], currency }) => {
  const firstOverviewHeaders = useMemo(
    () => getFirstOverviewHeaders({ currency }),
    [currency],
  );

  const secondOverviewHeaders = useMemo(
    () => getSecondOverviewHeaders({ currency }),
    [currency],
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item>
        <Typography variant="display5">Overview</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          height="125px"
          headerData={firstOverviewHeaders}
          data={firstOverview}
          // isLoading={isFetchLoading}
          containerProps={{ backgroundColor: 'white' }}
        />
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          height="125px"
          headerData={secondOverviewHeaders}
          data={secondOverview}
          // isLoading={isFetchLoading}
          containerProps={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  );
};
export default Overview;
