import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import condTrueFalse from 'utils/condTrueFalse';
import Chip from 'components/Chip';
import Switch from 'components/Switch';

const chipStyles = {
  minWidth: '160px',
  height: '100%',
};

const PaymentOptions = ({ isPayInInstallment, onChange }) => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const handleChangePaymentOpt = (e) => {
    const newValue = e.target.checked;
    e.target.value = newValue;

    onChange(e);
  };

  return (
    <Grid
      container
      item
      justifyContent="center"
      columnSpacing={1.5}
      direction={isXS ? 'column' : 'row'}
    >
      <Grid item textAlign="center">
        <Chip
          lowercase={false}
          label={<Typography>Pay now</Typography>}
          color={condTrueFalse(isPayInInstallment, 'disabled', 'saveButton')}
          sx={chipStyles}
        />
      </Grid>
      <Grid item textAlign="center">
        <Switch
          name="isPayInInstallment"
          color="saveButton"
          onChange={handleChangePaymentOpt}
          checked={!!isPayInInstallment}
        />
      </Grid>
      <Grid item textAlign="center">
        <Chip
          lowercase={false}
          label={<Typography>Pay in installment</Typography>}
          color={condTrueFalse(isPayInInstallment, 'saveButton', 'disabled')}
          sx={chipStyles}
        />
      </Grid>
    </Grid>
  );
};

export default memo(PaymentOptions);
