import React, { useMemo } from 'react';
import MUIButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import palette from 'theme/palette';

const btnStyles = ({ buttontype, theme }) => {
  if (buttontype === 'primary')
    return {
      '&:hover': {
        background: theme.palette.reach.pastel,
        borderColor: 'transparent',
      },
    };

  return {};
};

const StyledButton = styled(MUIButton)(
  ({ theme, buttontype, backgroundcolor, bordercolor }) => ({
    textTransform: 'none',
    borderColor: bordercolor || backgroundcolor || palette.text.fuchsia,
    color: palette.text.fuchsia,
    fontFamily: 'PT Serif',
    borderRadius: 0,
    backgroundColor: backgroundcolor,
    '&:hover': {
      background: theme.palette.reach.grey,
      borderColor: palette.text.fuchsia,
    },
    ...btnStyles({ theme, buttontype }),
  }),
);

const SecondaryButton = ({
  children,
  labelProps = {},
  buttontype,
  ...props
}) => {
  const { labelColor = palette.text.fuchsia, btnProps } = useMemo(() => {
    if (buttontype === 'primary') {
      return {
        labelColor: 'white',
        btnProps: {
          backgroundcolor: palette.text.fuchsia,
        },
      };
    }

    return {};
  }, [buttontype]);

  return (
    <StyledButton
      variant="contained"
      buttontype={buttontype}
      {...btnProps}
      {...props}
    >
      <Typography variant="body" color={labelColor} {...labelProps}>
        {children}
      </Typography>
    </StyledButton>
  );
};

export default SecondaryButton;
