import * as yup from 'yup';

const taxSummaryValidationSchema = yup.object({
  isConfirmed: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),
  signedBy: yup.string().required('Please state your name.'),
});

export default taxSummaryValidationSchema;
