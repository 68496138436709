const profile = (state, action) => {
  switch (action.type) {
    case '@@profile/UPDATE_PHONE_NUMBER_SUCCESS':
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            mobileNumber: action.payload,
          },
        },
      };
    case '@@profile/UPDATE_USER_ACCESSIBILITY':
    case '@@profile/UPDATE_USER_PROFILE':
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default profile;
