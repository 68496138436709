export const auBankFields = [
  {
    label: 'Account Name',
    field: 'accountName',
    spacing: { xs: 6, sm: 4 },
  },
  {
    label: 'BSB',
    field: 'bsb',
    spacing: { xs: 6, sm: 4 },
  },
  {
    label: 'Account Number',
    field: 'accountNumber',
    spacing: { xs: 6, sm: 4 },
  },
];

export const overseasBankFields = [
  {
    label: 'Account Name',
    field: 'accountName',
    spacing: { xs: 6, sm: 4, md: 3, lg: 2 },
  },
  {
    label: 'Acc No./IBAN',
    field: 'accountNumber',
    spacing: { xs: 6, sm: 4, md: 3, lg: 2 },
  },
  {
    label: 'Bank Name',
    field: 'bankName',
    spacing: { xs: 6, sm: 4, md: 3, lg: 1.5 },
  },
  {
    label: 'SWIFT/BIC',
    field: 'switftCode',
    spacing: { xs: 6, sm: 4, md: 3, lg: 1.25 },
  },
  {
    label: 'Country',
    field: 'country',
    spacing: { xs: 6, sm: 4, md: 3, lg: 1.25 },
  },
  {
    label: 'Bank code/routing no.',
    field: 'bankCode',
    spacing: { xs: 6, sm: 4, md: 3, lg: 2 },
  },
  {
    label: 'Bank Address',
    field: 'bankAddress',
    spacing: { xs: 6, sm: 4, md: 3, lg: 2 },
  },
];
