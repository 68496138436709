import React, { useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import Grid from '@mui/material/Grid';

import AddEntityDialog from 'containers/Admin/Approvals/Details/DetailsTab/AddEntityDialog';
import Button from 'components/Button';
import DetailsContent from 'containers/Admin/Approvals/Details/DetailsContent';
import EntityDetailsHeader from 'containers/Admin/Approvals/Details/EntityDetailsHeader';
import RequestDetails from 'containers/Admin/Approvals/Details/RequestDetails';
import { fetchAdminApprovalEntity } from 'containers/Admin/Approvals/Details//HelperFunction';

import useFetchApi from 'hooks/useFetchApi';

import palette from 'theme/palette';
import { mapResponseToDetail } from 'containers/DashboardPage/components/InvestmentEntityList/HelperFunction';

const EntityDetailDialog = ({
  initialValues,
  onEditEntity,
  onDownloadClick,
  onClose,
  entityId,
  readOnly,
  ...props
}) => {
  const adminGetApprovalEntity = useMemo(
    () => fetchAdminApprovalEntity(entityId),
    [entityId],
  );

  const { apiData: values, isFetchLoading } = useFetchApi({
    url: adminGetApprovalEntity,
    defaultApiData: {},
    dependencies: [adminGetApprovalEntity],
  });

  const titleFormComponent = useMemo(
    () => (
      <Grid container item rowSpacing={1}>
        <Grid container item xs={12}>
          <RequestDetails values={values} />
        </Grid>
        <Grid container item xs={12}>
          <EntityDetailsHeader values={values} />
        </Grid>
        <Grid item xs={12}>
          {isFetchLoading ? (
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item textAlign="center">
                <CircularProgress size={100} data-testid="progress-screen" />
              </Grid>
            </Grid>
          ) : (
            <DetailsContent
              readOnly
              values={mapResponseToDetail(values)}
              withEdit={false}
            />
          )}
        </Grid>
      </Grid>
    ),
    [isFetchLoading, values],
  );

  const dialogActions = useMemo(
    () => (
      <Grid container direction="row" justifyContent="space-between">
        <Grid item mr={8} display="flex" alignItems="center">
          <Button
            sx={{ padding: '10px' }}
            startIcon={<FileDownloadOutlined />}
            onClick={onDownloadClick}
            disabled={!readOnly}
          >
            Download Data
          </Button>
        </Grid>
        <Grid container item xs="auto" columnSpacing={3}>
          <Grid item xs="auto">
            <Button
              variant="buttonTextBluish"
              onClick={onEditEntity}
              buttoncolor="transparent"
              textColor={palette.reach.bluish}
              buttonstyles={{ padding: '10px' }}
              disabled={!readOnly}
            >
              Edit Entity Details
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="text"
              onClick={onClose}
              buttoncolor="transparent"
              textColor={palette.reach.bluish}
              buttonstyles={{ padding: '10px' }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    ),
    [readOnly],
  );

  return (
    <AddEntityDialog
      {...props}
      onClose={onClose}
      initialValues={initialValues}
      titleFormComponent={titleFormComponent}
      dialogActionsComponent={dialogActions}
      dialogProps={{ dialogTitleProps: {} }}
    />
  );
};

export default EntityDetailDialog;
