import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LoadingGif from 'assets/images/loading.gif';
import LoadingDialog from 'components/LoadingDialog';

const popupSx = {
  position: 'absolute',
  top: '40%',
  left: { xs: '40%', md: '45%' },
  maxWidth: '150px',
};

const Loading = ({ loading = false, label = 'Loading...', popup = false }) => {
  if (!loading) return null;

  if (popup) return <LoadingDialog loading={loading} label={label} />;

  return (
    <Grid
      container
      item
      textAlign="center"
      height="fit-content"
      sx={popup ? popupSx : {}}
    >
      <Grid item xs={12}>
        <img src={LoadingGif} alt="Loading..." />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="bodyLead" align="left">
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(Loading);
