import Services from 'api/services';
import {
  articleFeaturedChipCellRenderer,
  dateCellRenderer,
  fundPostStatusChipCellRenderer,
  fundVisibilityChipCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const fetchAdminOverview = async ({ filter }) => {
  const res = await Services.adminGetOverview(filter);

  return res?.data;
};

export const fetchAdminArticles = async ({ filter }) => {
  const res = await Services.adminGetArticles(filter);

  return res?.data;
};

export const articleHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.3,
    cellClassName: 'cellBold',
  },
  {
    field: 'title',
    headerName: 'Article Name',
    flex: 1.5,
    cellClassName: 'cellBold',
  },
  {
    field: 'isHidden',
    headerName: 'Article Visibility',
    flex: 0.75,
    renderCell: fundVisibilityChipCellRenderer,
  },
  {
    field: 'isFeatured',
    headerName: 'Featured',
    flex: 1,
    cellClassName: 'cellBold',
    renderCell: articleFeaturedChipCellRenderer,
  },
  {
    field: 'status',
    headerName: 'Post Status',
    flex: 0.75,
    renderCell: fundPostStatusChipCellRenderer,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
];

export const getArticleTotalHeader = (totalValue) => [
  { totalLabel: 'Total Articles', totalValue },
];

export const eventHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.3,
    cellClassName: 'cellBold',
  },
  {
    field: 'title',
    headerName: 'Event Name',
    flex: 1.5,
    cellClassName: 'cellBold',
  },
  {
    field: 'isHidden',
    headerName: 'Event Visibility',
    flex: 0.75,
    renderCell: fundVisibilityChipCellRenderer,
  },
  {
    field: 'status',
    headerName: 'Post Status',
    flex: 0.75,
    renderCell: fundPostStatusChipCellRenderer,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
];

export const getEventTotalHeader = (totalValue) => [
  { totalLabel: 'Total Events', totalValue },
];

export const videoHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.3,
    cellClassName: 'cellBold',
  },
  {
    field: 'title',
    headerName: 'Video Name',
    flex: 1.5,
    cellClassName: 'cellBold',
  },
  {
    field: 'isHidden',
    headerName: 'Video Visibility',
    flex: 0.75,
    renderCell: fundVisibilityChipCellRenderer,
  },
  {
    field: 'status',
    headerName: 'Post Status',
    flex: 0.75,
    renderCell: fundPostStatusChipCellRenderer,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
];

export const getVideoTotalHeader = (totalValue) => [
  { totalLabel: 'Total Videos', totalValue },
];
