import condTrueFalse from 'utils/condTrueFalse';

import { TRUSTEE_TYPES } from 'constants/investmentEntity';
import mapPersonalDetailsPayload from './personalDetails';
import mapCompanyDetailsPayload from './companyDetails';

const getOriginalFileNames = (trustee) => ({
  originalFileName1: trustee.certificates?.[0]?.fileName || '',
  originalFileName2: trustee.certificates?.[1]?.fileName || '',
});

const trustees = (trusteesValues) => {
  if (!trusteesValues?.length) return trusteesValues;

  return trusteesValues.map((trustee) =>
    condTrueFalse(
      trustee.type === 'individual',
      () => ({
        ...mapPersonalDetailsPayload(trustee),
        trusteeType: TRUSTEE_TYPES.INDIVIDUAL,
        ...getOriginalFileNames(trustee),
      }),
      () => ({
        ...mapCompanyDetailsPayload(trustee),
        trusteeType: TRUSTEE_TYPES.COMPANY,
        ...getOriginalFileNames(trustee),
      }),
    ),
  );
};

export default trustees;
