import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';

import { ReactComponent as TickSquareIcon } from 'assets/icons/tick-square.svg';

import history from 'utils/history';

import Dialog from '.';

const getFullDateAndTimeString = () => {
  const date = new Date();

  return `${date.toLocaleDateString('en-AU', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    weekday: 'long',
  })} - ${date.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

const routeToTransactionPage = () => history.push('/dashboard/transactions');

const RedemptionRequestSuccessDialog = (props) => {
  const DialogContent = useMemo(
    () => (
      <Grid container spacing={3} direction="column" textAlign="center" p={8}>
        <Grid item>
          <TickSquareIcon />
        </Grid>
        <Grid item>
          <Typography variant="display3">
            Redemption Request Received!
          </Typography>
        </Grid>
        <Grid item mb={4}>
          <Typography variant="smallStatic">
            Thank you for submitting a redemption request.
            <br />
            We will be in touch with further details about your request.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">
            {getFullDateAndTimeString()}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            fullWidth={false}
            onClick={routeToTransactionPage}
            maxheight={6}
          >
            Transaction Page
          </Button>
        </Grid>
      </Grid>
    ),
    [],
  );

  return (
    <Dialog
      content={DialogContent}
      maxWidth="sm"
      dialogTitleProps={{
        mt: 2,
        justifyContent: 'auto',
        alignItems: 'auto',
        variant: 'display4',
        minHeight: '32px',
      }}
      dialogContentProps={{
        sx: { padding: 0, overflowY: 'hidden' },
        style: { maxHeight: '700px' },
      }}
      {...props}
    />
  );
};

export default memo(RedemptionRequestSuccessDialog);
