const paginatedFundRedemptionRequest = (response) => {
  if (!response.data?.items?.length) return response;

  response.data.items = response.data.items.map((fundRedemptionRequest) => ({
    ...fundRedemptionRequest,
    entityName: fundRedemptionRequest?.portfolio?.userInvestorMap?.name,
    fundName: fundRedemptionRequest?.portfolio?.fund?.fundName,
    className: fundRedemptionRequest?.portfolio?.fundConfiguration?.className,
    unit: fundRedemptionRequest?.portfolio?.unit,
  }));

  return response;
};

const fundRedemptionRequests = (response) => {
  if (!response.data?.rows?.length) return response;

  response.data.rows = response.data.rows.map((fundRedemptionRequest) => ({
    ...fundRedemptionRequest,
    entityName: fundRedemptionRequest?.portfolio?.userInvestorMap?.name,
    fundName: fundRedemptionRequest?.portfolio?.fund?.fundName,
    className: fundRedemptionRequest?.portfolio?.fundConfiguration?.className,
    unit: fundRedemptionRequest?.portfolio?.unit,
  }));

  return response;
};

const fundRedemptionRequestDetails = (response) => {
  if (!response?.id) return response;

  const fundRedemptionRequest = {
    ...response,
    entityName: response?.portfolio?.userInvestorMap?.name,
    fundName: response?.portfolio?.fund?.fundName,
    className: response?.portfolio?.fundConfiguration?.className,
    committedAmount: response?.portfolio?.committedAmount,
    classLockupDays: response?.portfolio?.fundConfiguration?.classLockupDays,
    referenceNumber: response?.portfolio?.referenceNumber,
    investmentType: response?.portfolio?.userInvestorMap?.investmentType,
    estimatedValue:
      (response?.fundUpdateOverview?.balanceNet || 0) *
      (response?.redemptionUnitAmount || 0),
  };

  return fundRedemptionRequest;
};

export default {
  paginatedFundRedemptionRequest,
  fundRedemptionRequests,
  fundRedemptionRequestDetails,
};
