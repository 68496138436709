import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dialog from 'components/Dialog';
import InnerHtmlContainer from 'components/InnerHtmlContainer';
import SubmitCancel from 'components/Dialog/Actions/SubmitCancel';

const EmailTemplatePreviewDialog = ({
  open,
  onClose,
  emailPreview,
  ...props
}) => {
  const DialogTitle = useMemo(
    () => <Typography variant="display3">Preview</Typography>,
    [],
  );

  const DialogContent = useMemo(
    () => (
      <Grid
        container
        rowSpacing={3}
        direction="column"
        textAlign="center"
        px={{ xs: 0, sm: 10 }}
      >
        <Grid item>
          <InnerHtmlContainer
            htmlData={emailPreview}
            imageMaxWidth="100%"
            setDefaultStyle="padding: 0;"
          />
        </Grid>
      </Grid>
    ),
    [emailPreview],
  );

  return (
    <Dialog
      open={open}
      title={DialogTitle}
      content={DialogContent}
      onClose={onClose}
      dialogTitleProps={{ mt: 2, justifyContent: 'left' }}
      maxWidth="lg"
      dialogContentProps={{ style: { maxHeight: 'auto' } }}
      dialogActions={
        <SubmitCancel
          submitLabel="Close"
          onSubmitClick={onClose}
          submitBtnProps={{ buttoncolor: 'dark' }}
        />
      }
      {...props}
    />
  );
};

export default memo(EmailTemplatePreviewDialog);
