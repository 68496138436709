import React, { useMemo, useState, memo, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import ExpiredDocumentLink from 'components/ExpiredDocumentLink';
import PDFViewer from 'components/PDFViewer';
import { useStores } from 'components/StateProvider';

import { FROM_DOCUMENT_PAGE } from 'constants/general';
import history from 'utils/history';
import useFetchApi from 'hooks/useFetchApi';

import Helper from './HelperFunction';

const { fetchDocumentData } = Helper;

const isMyDocumentFolder = (folder) => folder === 'My Documents';

const ViewDocumentPage = () => {
  const {
    auth: {
      user: { isDataRoomDisable, isAddInvestmentEntityDisable },
    },
  } = useStores();
  const [searchParams] = useSearchParams();

  const [isExpired, setIsExpired] = useState(false);
  const [pdfData, setPdfData] = useState('');
  const [filename, setFilename] = useState('pdffile.pdf');
  const [downloadable, setDownloadable] = useState(false);

  const handleRedirectDashboard = useCallback(
    (opts = {}) => history.push('/', opts),
    [history],
  );

  const handleFetchError = useCallback((error) => {
    if (error?.code === 'error.data-room.get-document.failed')
      handleRedirectDashboard({
        from: FROM_DOCUMENT_PAGE,
        isDataRoomDisable: true, // hardcode true since getting error from endpoint
        isAddInvestmentEntityDisable,
      });
    if (error?.code === 'error.data-room.download-token.expired')
      setIsExpired(true);
  }, []);

  const getViewURL = useMemo(
    () => fetchDocumentData(searchParams, handleFetchError),
    [searchParams, handleFetchError],
  );

  const { apiData: documentData, isFetchLoading } = useFetchApi({
    url: getViewURL,
    defaultApiData: '',
  });

  useEffect(() => {
    if (documentData) {
      setPdfData(window.atob(documentData.data?.file));
      setFilename(documentData.data?.title);
      setDownloadable(documentData.data?.enableDownload);
    }
  }, [documentData]);

  // validation
  if (
    !isFetchLoading &&
    !isMyDocumentFolder(searchParams.get('folder')) &&
    isDataRoomDisable
  ) {
    // add delay to avoid redirecting to blank page
    setTimeout(() => {
      history.push('/', {
        from: FROM_DOCUMENT_PAGE,
        isDataRoomDisable,
        isAddInvestmentEntityDisable,
      });
    }, 50);
    return <div />;
  }

  if (isExpired) {
    return (
      <ExpiredDocumentLink handleRedirectDashboard={handleRedirectDashboard} />
    );
  }

  return (
    <PDFViewer
      data-testid="document-viewer"
      pdfData={pdfData}
      filename={filename}
      downloadable={downloadable}
      isLoading={isFetchLoading}
      style={{
        height: '100%',
        width: '100%',
      }}
    />
  );
};

export default memo(ViewDocumentPage);
