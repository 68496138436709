import React, { useState, memo, useCallback } from 'react';
import Typography from '@mui/material/Typography';

import CountdownTimer from 'components/CountdownTimer';
import TextButton from 'components/Button/TextButton';

const ResendVerificationEmail = ({ onSendVerification }) => {
  const [isSent, setIsSent] = useState(false);

  const handleSendVerification = useCallback(() => {
    setIsSent(true);

    onSendVerification();
  }, [onSendVerification]);

  if (!isSent) {
    return (
      <TextButton
        data-testid="resend-verification-email-btn"
        onClick={handleSendVerification}
      >
        Resend verification email
      </TextButton>
    );
  }

  return (
    <>
      <Typography
        data-testid="resend-verification-email-sent"
        variant="bodyGrey"
      >
        Verification email has been sent, please check your email
      </Typography>

      <CountdownTimer
        timer={90}
        onClick={handleSendVerification}
        typographyVariant="bodyGrey"
      />
    </>
  );
};

export default memo(ResendVerificationEmail);
