import dayjs from 'dayjs';

import adminFundUpdateCapitalCallValidationSchema from 'validations/schema/admin/fundUpdate/adminFundUpdateCapitalCallValidationSchema';
import adminFundUpdatePriceUpdateValidationSchema from 'validations/schema/admin/fundUpdate/adminFundUpdatePriceUpdateValidationSchema';
import adminFundUpdateDistributionValidationSchema from 'validations/schema/admin/fundUpdate/adminFundUpdateDistributionValidationSchema';

export const capitalCallInitialValues = {
  date: null,
  called: '',
};
export const priceUpdateInitialValues = {
  date: null,
  unitPriceGross: '',
  unitPriceNet: '',
};
export const distributionInitialValues = {
  date: null,
  distribution: '',
};

const capitalCallFormFields = [
  {
    fieldType: 'datePicker',
    name: 'date',
    label: 'Value Date',
    maxDate: dayjs(),
    gridProps: {
      labelGridProps: { xs: 3 },
      childGridProps: { xs: 9 },
    },
  },
  {
    fieldType: 'input',
    name: 'called',
    label: '% Called',
    gridProps: {
      labelGridProps: { xs: 3 },
      childGridProps: { xs: 9 },
    },
    fieldProps: {
      withThousandSeparator: true,
      type: 'number',
    },
  },
];
const priceUpdateFormFields = [
  {
    fieldType: 'datePicker',
    name: 'date',
    label: 'Value Date',
    maxDate: dayjs(),
    gridProps: {
      labelGridProps: { xs: 3 },
      childGridProps: { xs: 9 },
    },
  },
  {
    fieldType: 'input',
    name: 'unitPriceGross',
    label: 'Unit Price (Gross)',
    gridProps: {
      labelGridProps: { xs: 3 },
      childGridProps: { xs: 9 },
    },
    fieldProps: {
      withThousandSeparator: true,
      type: 'number',
    },
  },
  {
    fieldType: 'input',
    name: 'unitPriceNet',
    label: 'Unit Price (Net)',
    gridProps: {
      labelGridProps: { xs: 3 },
      childGridProps: { xs: 9 },
    },
    fieldProps: {
      withThousandSeparator: true,
      type: 'number',
    },
  },
];
const distributionFormFields = [
  {
    fieldType: 'datePicker',
    name: 'date',
    label: 'Value Date',
    maxDate: dayjs(),
    gridProps: {
      labelGridProps: { xs: 3 },
      childGridProps: { xs: 9 },
    },
  },
  {
    fieldType: 'input',
    name: 'distribution',
    label: 'Distribution',
    gridProps: {
      labelGridProps: { xs: 3 },
      childGridProps: { xs: 9 },
    },
    fieldProps: {
      withThousandSeparator: true,
      type: 'number',
    },
  },
];

const CAPITAL_CALL = 'CAPITAL_CALL';
const PRICE_UPDATE = 'PRICE_UPDATE';
const DISTRIBUTION = 'DISTRIBUTION';

export const validationSchemas = {
  [CAPITAL_CALL]: adminFundUpdateCapitalCallValidationSchema,
  [PRICE_UPDATE]: adminFundUpdatePriceUpdateValidationSchema,
  [DISTRIBUTION]: adminFundUpdateDistributionValidationSchema,
};

export const fundUpdatesTypes = [
  {
    key: CAPITAL_CALL,
    label: 'Capital Call',
    fields: capitalCallFormFields,
    initialValues: capitalCallInitialValues,
  },
  {
    key: PRICE_UPDATE,
    label: 'Price Update',
    fields: priceUpdateFormFields,
    initialValues: priceUpdateInitialValues,
  },
  {
    key: DISTRIBUTION,
    label: 'Distribution',
    fields: distributionFormFields,
    initialValues: distributionInitialValues,
  },
];
