import MUIButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const AppBarButton = styled(MUIButton)(({ dark, backgroundcolor }) => ({
  color: dark ? 'white' : 'black',
  backgroundColor: backgroundcolor || (dark ? 'black' : 'white'),
  textTransform: 'none',
}));

export default AppBarButton;
