const base64ToBlob = (base64String) => {
  const arrayBuffer = new ArrayBuffer(base64String.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < base64String.length; i += 1) {
    int8Array[i] = base64String.charCodeAt(i);
  }
  return new Blob([int8Array], { type: 'application/pdf' });
};

const downloadFile = ({ href, filename }) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = href;
  downloadLink.download = filename;
  downloadLink.click();
};

export { base64ToBlob, downloadFile };
