import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import FailedDialog from 'components/Dialog/FailedDialog';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import useDialog from 'hooks/useDialog';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useFetchApi from 'hooks/useFetchApi';

import Services from 'api/services';
import history from 'utils/history';
import mapValue from 'utils/mapValue';

import { mapResponseToDetail } from 'containers/DashboardPage/components/InvestmentEntityList/HelperFunction';
import { INVESTMENT_ENTITY_STATUSES } from 'constants/investmentEntity';

import DetailsContainer from './DetailsContainer';
import RequestDetails from './RequestDetails';
import DetailsContent from './DetailsContent';
import EntityDetailsHeader from './EntityDetailsHeader';
import { fetchAdminApprovalEntity, updateApproval } from './HelperFunction';

const handleRedirectToApprovalList = () => {
  const route = mapValue(
    history.location.state?.onBackRoute,
    '/admin/approvals',
  );

  history.push(route);
};

const Details = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [fetchStatus, setFetchStatus] = useState(true);
  const [error, setError] = useState('');
  const [type, setType] = useState('individual');
  const { entityId } = useParams();
  const {
    isDialogOpen: isDeleteDialogOpen,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
  } = useDialog();

  const adminGetApprovalEntity = useMemo(
    () => fetchAdminApprovalEntity(entityId),
    [entityId, fetchStatus],
  );

  const { apiData: values, isFetchLoading } = useFetchApi({
    url: adminGetApprovalEntity,
    defaultApiData: {},
    dependencies: [adminGetApprovalEntity],
  });

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  ); // trigger useMemo changes to refetch

  const handleUpdateApproval = useCallback(
    (newValues) => {
      // eslint-disable-next-line
      const {
        fileUpload = [],
        fileUploadKey = 'certificate',
        ...payload
      } = newValues;
      const formData = new FormData();

      formData.append('content', JSON.stringify(payload));

      if (fileUpload?.length) {
        fileUpload.forEach((certificate) =>
          formData.append(fileUploadKey, certificate.file),
        );
      }

      updateApproval({
        id: entityId,
        payload: formData,
        type,
        callback: handleRefetch,
      });
    },
    [type],
  );

  const handleDeleteApproval = useCallback(async () => {
    try {
      handleCloseDeleteDialog();

      await Services.adminDeleteApprovalEntity(entityId);

      handleRedirectToApprovalList();
    } catch (err) {
      setError('Delete entity failed');
    }
  }, []);

  const { values: formValues, setValues } = useFormik({
    initialValues: null,
    onSubmit: handleUpdateApproval,
  });

  useEffect(() => {
    if (Object.keys(values).length) {
      setType(values?.type);
      setValues(mapResponseToDetail(values));
    }

    if (isFetchLoading) setValues(null);
  }, [isFetchLoading]);

  const handleResetError = useCallback(() => setError(''), []);

  if (!formValues) return null;

  return (
    <DetailsContainer
      entityId={entityId}
      refetch={handleRefetch}
      isApproved={formValues.status === INVESTMENT_ENTITY_STATUSES.APPROVED}
      readOnly={!haveCrudPermission}
      onDelete={handleOpenDeleteDialog}
    >
      {isFetchLoading ? (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item textAlign="center">
            <CircularProgress size={100} data-testid="progress-screen" />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4} data-testid="details-content">
          <Grid item xs={12}>
            <RequestDetails values={values} />
          </Grid>
          <Grid item xs={12}>
            <EntityDetailsHeader values={values} />
          </Grid>
          <Grid item xs={12}>
            <DetailsContent
              readOnly={!haveCrudPermission}
              values={formValues}
              onChange={handleUpdateApproval}
            />
          </Grid>
        </Grid>
      )}

      <ConfirmationDialog
        open={!!isDeleteDialogOpen}
        title="Warning!"
        infoText=""
        subtitle="Do you want to delete this entity ?"
        onClose={handleCloseDeleteDialog}
        confirmButtonLabel="Delete"
        onConfirm={handleDeleteApproval}
        dialogContentDependencies={[handleDeleteApproval]}
      />
      <FailedDialog
        open={!!error}
        onConfirm={handleResetError}
        subtitle={error}
      />
    </DetailsContainer>
  );
};

export default Details;
