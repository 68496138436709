import getNumbers from 'utils/strings/getNumbers';
import trimMaskInput from 'utils/trimMaskInput';

import { PAYMENT_OPTIONS } from 'constants/investmentEntity';

const accountName = (yup) =>
  yup.string().min(2).max(100).required().label('Account Name');

const accountNumber = (yup) =>
  yup.string().transform(trimMaskInput).max(20).label('Account Number');

const bsb = (yup) =>
  yup
    .string()
    .label('BSB')
    .when('isAustralianBased', {
      is: true,
      then: yup.string().transform(trimMaskInput).min(6).max(6).required(),
    });

const bankName = (yup) => yup.string().max(60).required().label('Bank Name');

const swiftCode = (yup) => yup.string().max(20);

const country = (yup) => yup.string().required();

const bankCode = (yup) => yup.string().max(20);

const bankAddress = (yup) =>
  yup.string().required().min(2).max(255).label('Bank Address');

// Investment Amount Validations.
const paymentType = (yup) => yup.string().required().label('Payment Type');
const totalInvestment = (yup) =>
  yup
    .string()
    .required()
    .test(
      'minInvestment',
      'Minimum Investment is 5.000',
      (value) => getNumbers(value) >= 5000,
    )
    .label('Total Investment');
const investmentUnit = (yup) =>
  yup.string().required().label('Investment Units');
const amountPayableToday = (yup) =>
  yup.string().required().label('Amount Payable Today');
const accessFee = (yup) => yup.string().required().label('Access Fee');
const totalPayableToday = (yup) =>
  yup.string().required().label('Amount Received');
const amountPayableLater = (yup) =>
  yup
    .string()
    .when('paymentType', {
      is: PAYMENT_OPTIONS.PAY_IN_INSTALLMENT,
      then: yup.string().required(),
    })
    .label('Amount Payable Later');
const total = (yup) =>
  yup
    .string()
    .when('paymentType', {
      is: PAYMENT_OPTIONS.PAY_IN_INSTALLMENT,
      then: yup.string().required(),
    })
    .label('Total');

export default {
  accountName,
  accountNumber,
  bsb,
  bankName,
  swiftCode,
  country,
  bankCode,
  bankAddress,
  paymentType,
  totalInvestment,
  investmentUnit,
  amountPayableToday,
  accessFee,
  totalPayableToday,
  amountPayableLater,
  total,
};
