import * as yup from 'yup';
import password from 'validations/password';
import email from 'validations/email';
import profileDetail from 'validations/profileDetail';

const eligibilityCriteriaValidation = yup.object({});

const profileDetailValidation = yup.object(profileDetail);

const emailValidation = yup.object(email);

const OTPValidation = yup.object({
  otp: yup.string().length(6).required(),
});

const passwordValidation = yup.object(password);

const consentValidation = yup.object({
  consent: yup
    .object({
      0: yup.bool().oneOf([true]),
      1: yup.bool().oneOf([true]),
      2: yup.bool().oneOf([true]),
    })
    .required(),
});

const phoneNumberValidation = yup.object({
  phoneNumber: yup.number().required().label('Mobile Number'),
  countryCode: yup.object().required().label('Country Code'),
});

const registrationValidationSchemas = [
  eligibilityCriteriaValidation,
  profileDetailValidation,
  emailValidation,
  phoneNumberValidation,
  OTPValidation,
  passwordValidation,
  consentValidation,
];

export default registrationValidationSchemas;
