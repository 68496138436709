import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import FieldContainer from 'components/FieldContainer';
import ShowMe from 'components/ShowMe';

import condTrueFalse from 'utils/condTrueFalse';

import { FUND_REDEMPTION_REQUEST_FIELD_DISPLAY } from 'constants/fundRedemptionRequest';

const FundRedemptionRequestHeaders = ({ values }) => (
  <Grid
    item
    sx={(theme) => ({
      background: theme.palette.reach.obsidian,
      padding: 1.5,
      borderRadius: '6px',
      margin: 0,
    })}
    container
    alignItems="center"
  >
    <Grid item xs={12} sx={{ margin: 1 }}>
      <Typography
        variant="display5"
        sx={(theme) => ({ color: theme.palette.text.white })}
      >
        Redemption Request
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider
        variant="middle"
        flexItem
        sx={(theme) => ({
          backgroundColor: theme.palette.border.light,
          margin: 0,
        })}
      />
    </Grid>
    <Grid item xs container sx={{ padding: 1 }}>
      {FUND_REDEMPTION_REQUEST_FIELD_DISPLAY.map(
        ({ name, label, md, renderer }) => (
          <ShowMe when={name !== 'status'} key={`${name}-${label}`}>
            <Grid item xs md={md}>
              <FieldContainer label={label}>
                <Typography
                  variant="display5"
                  sx={(theme) => ({
                    color: theme.palette.text.white,
                  })}
                >
                  {condTrueFalse(
                    renderer,
                    () => renderer(values?.[name]),
                    values?.[name],
                  )}
                </Typography>
              </FieldContainer>
            </Grid>
          </ShowMe>
        ),
      )}
    </Grid>
  </Grid>
);

export default memo(FundRedemptionRequestHeaders);
