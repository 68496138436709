import React, { useCallback, useMemo, useState } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dropdown from 'components/Dropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import TotalHeaders from 'components/TotalHeaders';
import { useStores } from 'components/StateProvider';

import useFetchApi from 'hooks/useFetchApi';

import currencyCodes from 'constants/currencyCodes';
import { PERIOD_ALL, PERIOD_OPTIONS } from 'constants/general';

import config from 'utils/config';
import { fetchAdminOverview } from './HelperFunction';

const OverviewSection = () => {
  const {
    drawer: { open: drawerOpen },
  } = useStores();
  const [period, setPeriod] = useState(PERIOD_ALL);

  const adminGetOverview = useMemo(
    () => fetchAdminOverview({ filter: period }),
    [period],
  );

  const { apiData: dataValues, isFetchLoading } = useFetchApi({
    url: adminGetOverview,
    defaultApiData: {
      openFunds: 0,
      users: 0,
      onboarded: 0,
      investmentEntities: 0,
      investments: 0,
      raised: {},
    },
    dependencies: [adminGetOverview],
  });

  const handleChangePeriod = useCallback((e) => {
    setPeriod(e.target.value);
  }, []);

  const totalHeadersValue = useMemo(() => {
    const raisedValues = Object.keys(dataValues.raised).reduce(
      (values, key) => {
        if (!dataValues.raised[key] || dataValues.raised[key] === '0')
          return values;

        return values.concat(`${currencyCodes[key]}${dataValues.raised[key]}`);
      },
      [],
    );

    const raised = raisedValues.length ? raisedValues : '0';

    return [
      {
        key: 'openFunds',
        totalLabel: 'Open Funds',
        totalValue: dataValues.openFunds,
      },
      { key: 'users', totalLabel: 'Users', totalValue: dataValues.users },
      {
        key: 'onboarded',
        totalLabel: 'Onboarded',
        totalValue: dataValues.onboarded,
      },
      {
        key: 'investmentEntities',
        totalLabel: 'Investment Entities',
        totalValue: dataValues.investmentEntities,
      },
      {
        key: 'investments',
        totalLabel: 'Investments',
        totalValue: dataValues.investments,
      },
      { key: 'raised', totalLabel: 'Raised', totalValue: raised },
    ];
  }, [dataValues]);

  const totalHeadersColumnSpacing = useMemo(() => {
    if (drawerOpen)
      return {
        md: 3,
        xl: 6,
      };

    return {
      md: 4,
      xl: 12,
    };
  }, [drawerOpen]);

  return (
    <Grid
      data-testid="admin-overview-section"
      container
      item
      direction="column"
      rowSpacing={2}
      sx={{ background: config.system.appColours.cardBackground }}
    >
      <Grid item>
        <Typography variant="display1">Overview</Typography>
      </Grid>

      <Grid item display="flex" alignItems="center">
        <FormLabel sx={{ mr: 2 }}>Period</FormLabel>
        <Dropdown
          data-testid="overview-period-dropdown"
          fullWidth={false}
          options={PERIOD_OPTIONS}
          value={period}
          onChange={handleChangePeriod}
          name="overviewPeriod"
          sx={{ minWidth: '150px' }}
          minheight={5.5}
          maxheight="44px"
        />
      </Grid>

      <Grid item xs={12}>
        {isFetchLoading ? (
          <LoadingSpinner loading />
        ) : (
          <TotalHeaders
            totalFullWidth
            columnSpacing={totalHeadersColumnSpacing}
            withDivider={false}
            background="white"
            totals={totalHeadersValue}
            exportLabel=""
          />
        )}
      </Grid>
    </Grid>
  );
};

export default OverviewSection;
