import mapValue from 'utils/mapValue';

const VIDEO_DEFAULT_VALUES = {
  video: {
    id: '',
    videoUid: '',
    category: '',
    summary: '',
    title: '',
    date: new Date(),
    link: '',
  },
  isHidden: false,
  createdBy: '',
  createdAt: new Date().toISOString(),
  updatedBy: '',
  updatedAt: '',
};

const mapImgSrc = ({ url, fileName }) => (url ? { url, fileName } : {});

const video = (values) => ({
  video: {
    ...VIDEO_DEFAULT_VALUES.video,
    ...values.video,

    videoThumbnailImg: mapImgSrc({
      url: values.video?.videoThumbnailImgUrl,
      fileName: values.video?.videoThumbnailImgKey,
    }),
  },
  isHidden: values.video?.isHidden,
  createdBy: values.video?.createdBy,
  updatedBy: values.video?.updatedBy,
  createdAt: mapValue(
    values.video?.created_at,
    VIDEO_DEFAULT_VALUES.video.createdAt,
  ),
  updatedAt: mapValue(
    values.video?.updated_at,
    VIDEO_DEFAULT_VALUES.video.updatedAt,
  ),
});

export default video;
