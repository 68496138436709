import React from 'react';
import MUISwitch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import palette from 'theme/palette';

const defaultWidth = 70;
const defaultHeight = 36;

const StyledSwitch = styled(MUISwitch)(
  ({
    theme,
    height = defaultHeight,
    width = defaultWidth,
    customcolor = '',
  }) => {
    const switchColor = (() => {
      if (customcolor) return customcolor;
      return theme.palette.mode === 'dark'
        ? palette.reach.blueLight
        : palette.reach.purpleHaze;
    })();
    return {
      width,
      height,
      borderRadius: height / 2,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: Math.floor(height / 10),
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: `translateX(${width / 2}px)`,
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: switchColor,
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: height - height / 6,
        height: height - height / 6,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    };
  },
);

const Switch = ({ ...props }) => <StyledSwitch {...props} />;

export default Switch;
