export const ALL = 'ALL';
export const APPROVED = 'APPROVED';
export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';

export const INVESTMENT_ENTITY_STATUS = {
  [ALL]: ALL,
  [APPROVED]: APPROVED,
  [PENDING]: PENDING,
  [REJECTED]: REJECTED,
};

export const INVESTMENT_ENTITY_STATUS_OPTIONS = [
  { label: 'All', value: ALL },
  { label: 'Approved', value: APPROVED },
  { label: 'Pending', value: PENDING },
  { label: 'Rejected', value: REJECTED },
];
