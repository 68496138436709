import { useCallback, useState } from 'react';
import useFetchApi from './useFetchApi';

const useDataTables = ({
  initialPage = 0,
  initialSort = [{}], // MUI return sort value as array by default.
  initialFilter = {},
  fetchService,
  pageSize = 10, // row per page
  defaultApiData = [],
  dependencies = [],
  mapApiData,
}) => {
  const [page, setPage] = useState(initialPage);
  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState(initialFilter);
  const [paginationModel, setPaginationModel] = useState({
    pageSize,
    page: initialPage,
  });

  const getData = useCallback(
    () =>
      fetchService({
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        order: (sort?.[0]?.sort || '').toUpperCase(),
        column: sort?.[0]?.field,

        // filter,
      }),
    [paginationModel, sort, filter, ...dependencies],
  );

  const { apiData, isFetchLoading, handleRefetch } = useFetchApi({
    dependencies: [getData],
    fetchService: getData,
    mapApiData,
  });

  return {
    page,
    pageSize,
    sort,
    filter,
    apiData: apiData?.data || apiData || defaultApiData,
    isFetchLoading,
    setPage,
    setSort,
    setFilter,
    handleRefetch,
    paginationModel,
    setPaginationModel,
  };
};
export default useDataTables;
