import React from 'react';

import OverviewSection from './OverviewSection';
import UserActivitySection from './UserActivitySection';

const AdminOverview = () => (
  <>
    <OverviewSection />
    <UserActivitySection />
  </>
);

export default AdminOverview;
