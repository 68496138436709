import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';

import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import SummarySection from 'components/SummarySection';
import InputField from 'components/Input';
import condTrueFalse from 'utils/condTrueFalse';
import taxDetailsValidationSchema from 'validations/schema/addInvestment/taxDetailsValidationSchema';
import { SUMMARY_FIELDS_TAX_DETAILS } from 'constants/investmentEntity';

const TaxDetailsSummary = ({
  onChange,
  values = {},
  name,
  isJoint,
  withEdit = true,
  showTitle = true,
  sectionListView = false,
  ...props
}) => {
  const handleSave = useCallback((formValues) => {
    const onChangePayload = {
      target: {
        name,
        value: formValues,
      },
    };

    condTrueFalse(onChange, () => onChange(onChangePayload));
  }, []);

  const InvestorTaxForm = useFormik({
    initialValues: { ...values },
    onSubmit: handleSave,
    validationSchema: taxDetailsValidationSchema,
  });

  const Title = useMemo(
    () => (
      <span>
        {`${values?.firstName} ${values?.lastName}`}
        <Chip
          label={isJoint ? 'Joint' : 'Individual'}
          sx={{ marginLeft: '15px' }}
          color={isJoint ? 'joint' : 'individual'}
        />
      </span>
    ),
    [values?.firstName, values?.lastName],
  );

  // put multiple choice questions here since we need input field to be wrapped with useMemo
  const CustomizedSummaryFields = useMemo(
    () => [
      ...SUMMARY_FIELDS_TAX_DETAILS,
      {
        fieldComponent: 'multipleChoiceQuestion',
        fieldLabel: 'Reason not providing your TIN',
        name: 'noTINReason',
        showWhen: [
          {
            key: 'hasOtherCountryTax',
            value: (hasOtherCountryTax, summaryValues) =>
              !!hasOtherCountryTax || summaryValues?.hasAustraliaTax === false,
          },
          { key: 'isTINAvailable', value: false },
        ],
        fastField: false,
        options: [
          {
            label:
              'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
            key: 'answer-no-1',
            value: '1',
            displayValue:
              'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
          },
          {
            label:
              'The entity is otherwise unable to obtain a TIN or equivalent number.',
            value: '2',
            key: 'answer-no-2',
            children: (
              <InputField
                fullWidth
                name="unableObtainTinReason"
                onChange={onChange}
                value={values?.unableObtainTinReason}
                placeholder="Please state why"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={InvestorTaxForm.errors.unableObtainTinReason}
              />
            ),
            showChildWhen: [{ key: 'noTINReason', value: '2' }],
            displayValue: values?.unableObtainTinReason,
          },
          {
            label: 'No TIN is required',
            value: '3',
            key: 'answer-no-3',
            displayValue: 'No TIN is required',
            children: (
              <Typography variant="caption">
                Note: Only select this reason if the domestic law of the
                relevant jurisdiction does not require the collection of the TIN
                issued by such jurisdiction.
              </Typography>
            ),
          },
        ],
      },
    ],
    [values],
  );

  return (
    <SummarySection
      title={showTitle && Title}
      titleTypographyVariant="h4"
      fields={CustomizedSummaryFields}
      setValues={handleSave}
      values={values}
      formName="taxDetailsForm"
      validationSchema={taxDetailsValidationSchema}
      withEdit={withEdit}
      sectionListView={sectionListView}
      {...props}
    />
  );
};

export default TaxDetailsSummary;
