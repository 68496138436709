import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid';
import PasswordInput from 'components/Input/PasswordInput';
import Typography from '@mui/material/Typography';

import Loading from 'components/Loading';
import ShowMe from 'components/ShowMe';
import { useActions, useStores } from 'components/StateProvider';

import history from 'utils/history';
import useIsTokenExpiring from 'hooks/useIsTokenExpiring';
import { ERROR_MESSAGES } from 'constants/errors';

import ConfirmationDialog from './ConfirmationDialog';

const routeToLogout = () => history.push('/logout');

const SessionExpiringConfirmationDialog = (props) => {
  const {
    auth: { user, tokenExpiry },
  } = useStores();
  const { handleRefreshToken } = useActions();
  const [loading, setLoading] = useState(false);
  const [refreshTokenError, setRefreshTokenError] = useState('');
  const { tokenTimeLeft, isTokenExpired } = useIsTokenExpiring({ tokenExpiry });

  useEffect(() => {
    if (isTokenExpired) routeToLogout();
  }, [isTokenExpired]);

  const handleSubmitRefreshToken = useCallback(
    async (formValues) => {
      try {
        setLoading(true);

        await handleRefreshToken({
          email: user.email,
          password: formValues.password,
        });
      } catch (err) {
        const errorCode = err.response?.data?.code;

        setRefreshTokenError(ERROR_MESSAGES[errorCode]);
      } finally {
        setLoading(false);
      }
    },
    [user.email],
  );

  const formik = useFormik({
    initialValues: { password: '' },
    onSubmit: handleSubmitRefreshToken,
  });

  const contentText = useMemo(
    () => (
      <>
        <Typography variant="smallStatic" display="block">
          You will be logged out in <b>{Math.ceil(tokenTimeLeft)} seconds.</b>
        </Typography>
        <Typography variant="smallStatic" display="block" mt={2}>
          For your security, sessions automatically end after 1 hour.
          <br />
          If you want to extend your session, please enter your password and
          click &apos;Stay Logged In&apos; button.
        </Typography>

        <Grid container justifyContent="center" mt={2} mb={5}>
          <Grid item xs={4}>
            <PasswordInput
              fullWidth
              data-testid="password-input"
              id="password"
              name="password"
              placeholder="Enter password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              errorValue={formik.errors.password}
              defaultMargin={false}
            />
          </Grid>
        </Grid>

        <ShowMe when={!!refreshTokenError}>
          <Grid item xs={12}>
            <Typography
              data-testid="login-error-text"
              variant="bodyGrey"
              color="error"
            >
              {refreshTokenError}
            </Typography>
          </Grid>
        </ShowMe>
      </>
    ),
    [tokenTimeLeft, formik.values.password, refreshTokenError],
  );

  const isTokenExpireSoon = useMemo(
    () => tokenTimeLeft < 60 && tokenTimeLeft > -1,
    [tokenTimeLeft],
  );

  return (
    <>
      <ConfirmationDialog
        fullWidth
        data-testid="session-expiring-confirmation-dialog"
        open={isTokenExpireSoon}
        title="Are you still there?"
        contentText={contentText}
        infoText=""
        onConfirm={formik.handleSubmit}
        confirmButtonLabel="Stay Logged In"
        closeOnBackgroundClick={false}
        onClose={routeToLogout}
        cancelBtnLabel="Log Out"
        dialogContentDependencies={[contentText]}
        confirmButtonContainerProps={{ md: 3 }}
        confirmButtonProps={{
          buttoncolor: undefined,
          sx: { padding: '14px 24px' },
          disabled: !formik.values.password || tokenTimeLeft < 1,
        }}
        cancelButtonContainerProps={{ md: 3 }}
        cancelButtonProps={{ sx: { padding: '14px 24px' } }}
        {...props}
      />

      <Loading popup loading={loading} />
    </>
  );
};

export default SessionExpiringConfirmationDialog;
