import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dialog from 'components/Dialog';
import useFetchApi from 'hooks/useFetchApi';
import { dateCellRenderer } from 'utils/Datatables/cellRenderer';
import { fetchEntityHistory } from '../HelperFunction';

const HistoryPopUp = ({ entityId, ...props }) => {
  const adminGetApprovalEntityHistory = useMemo(
    () => fetchEntityHistory(entityId),
    [entityId],
  );
  const { apiData: values } = useFetchApi({
    url: adminGetApprovalEntityHistory,
    defaultApiData: [],
    dependencies: [adminGetApprovalEntityHistory],
  });

  return (
    <Dialog
      content={
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid item xs={2}>
            <Typography variant="body" sx={{ fontWeight: 700 }}>
              Updated
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body" sx={{ fontWeight: 700 }}>
              Reason
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" sx={{ fontWeight: 700 }}>
              Edited By
            </Typography>
          </Grid>
          {values.map((item) => (
            <React.Fragment
              key={`${item?.updated_at}-${item?.reason}-${item?.updatedBy}`}
            >
              <Grid item xs={2}>
                <Typography variant="small">
                  {dateCellRenderer({ value: item?.updated_at })}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="small">{item?.reason}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="samll">{item?.updatedBy}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      }
      {...props}
    />
  );
};
export default HistoryPopUp;
