import React, { useEffect, useMemo, useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import config from 'utils/config';
import history from 'utils/history';

import Navbar from 'components/Navbar';
import { useStores } from 'components/StateProvider';

import { COPYRIGHT_TEXT, FULLSCREEN_BROWSER_URLS } from 'constants/general';

import Router from './router';

import './App.css';

const App = () => {
  const {
    drawer: { open: drawerOpen },
    general: { sidebar: hasSidebar, colours: appColours },
  } = useStores();
  const [isPDFViewer, setIsPDFViewer] = useState(false);
  const [isAdminPage, setIsAdminPage] = useState(false);
  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const handleModifyNavbarLayout = (currentPathname) => {
      const isPathAvailable = (path) => currentPathname?.includes(path);
      setIsPDFViewer(FULLSCREEN_BROWSER_URLS.some(isPathAvailable));
    };

    const checkAdminPage = (currentPathname) => {
      setIsAdminPage(currentPathname.includes('/admin'));
    };

    // for initial load (opening the page directly)
    handleModifyNavbarLayout(history?.location?.pathname);
    checkAdminPage(history?.location?.pathname);

    // for redirect
    const listenHistory = history.listen(({ location }) => {
      handleModifyNavbarLayout(location?.pathname);
      checkAdminPage(location?.pathname);
    });

    return () => {
      listenHistory();
    };
  }, []);

  useEffect(() => {
    document.body.style.background = appColours.appBackground;
  }, [appColours]);

  const drawerWidth = useMemo(() => {
    if ((isAdminPage && drawerOpen) || hasSidebar)
      return isMDScreen ? config.drawer.admin.width : 0;

    return drawerOpen && isMDScreen
      ? config.drawer.dashboard.fullWidth
      : config.drawer.dashboard.width;
  }, [drawerOpen, hasSidebar, isMDScreen]);

  return (
    <Box
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        height: '100%',
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(drawerOpen && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          // marginLeft: 0,
        }),
      }}
    >
      {!isPDFViewer && (
        <Navbar isAdminPage={isAdminPage} hasSidebar={hasSidebar} />
      )}
      <div className={isPDFViewer ? 'fullscreen-content' : 'content'}>
        <Router />
      </div>
      {!isPDFViewer && (
        <div className="footer">
          <Typography variant="xsmall">{COPYRIGHT_TEXT}</Typography>
          <div style={{ float: 'right' }}>
            <Typography variant="xsmall">{config.system.version}</Typography>
          </div>
        </div>
      )}
    </Box>
  );
};

export default App;
