import React, { useCallback, useMemo } from 'react';

import DataTable from 'components/DataTable';
import TotalHeaders from 'components/TotalHeaders';

import useDataTables from 'hooks/useDataTables';
import history from 'utils/history';
import ResponseMappers from 'utils/ResponseMappers';
import services from 'api/services';

import {
  fundRedemptionRequestHeaders,
  getFundRedemptionRequestTotalHeader,
} from 'containers/Admin/Approvals/HelperFunction';

const FundRedemptionRequestList = ({ onRowClick, userId, readOnly }) => {
  const { apiData, isFetchLoading, paginationModel, setPaginationModel } =
    useDataTables({
      defaultApiData: [],
      fetchService: () =>
        services.adminGetFundRedemptionRequestsByUserId(userId),
      mapApiData:
        ResponseMappers.admin.fundRedemptionRequest.fundRedemptionRequests,
    });

  const handleAddFundRedemptionRequest = useCallback(
    () => history.push(`/admin/users/${userId}/add-fund-redemption-request`),
    [userId],
  );

  const totalHeader = useMemo(
    () => getFundRedemptionRequestTotalHeader(apiData.length),
    [apiData.length],
  );

  return (
    <div data-testid="user-fundRedemptionRequestList-container">
      <TotalHeaders
        totals={totalHeader}
        onClickAddItem={handleAddFundRedemptionRequest}
        addItemLabel="Create Request"
        disableExportBtn={readOnly}
        disabledAddItemBtn={readOnly}
      />
      <DataTable
        height="656px"
        hideFooter
        sortingMode="client"
        withCheckbox={false}
        pagination={false}
        headerData={fundRedemptionRequestHeaders}
        data={apiData.rows}
        isLoading={isFetchLoading}
        onRowClick={onRowClick}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </div>
  );
};

export default FundRedemptionRequestList;
