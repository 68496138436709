import React, { useCallback, useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import Input from 'components/Input';

const getPasswordFieldInputProps = ({
  handleClickShowPassword,
  showPassword,
}) => ({
  endAdornment: (
    <InputAdornment position="end">
      <IconButton
        data-testid="password-input-visibility-btn"
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
      >
        {showPassword ? (
          <VisibilityOutlinedIcon data-testid="password-input-visible-icon" />
        ) : (
          <VisibilityOffOutlinedIcon data-testid="password-input-invisible-icon" />
        )}
      </IconButton>
    </InputAdornment>
  ),
});

const PasswordInput = ({ defaultMargin = true, showIcon = true, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <Input
      fullWidth
      id="password"
      name="password"
      placeholder="Password"
      type={showPassword ? 'text' : 'password'}
      InputProps={
        showIcon &&
        getPasswordFieldInputProps({
          handleClickShowPassword,
          showPassword,
        })
      }
      sx={defaultMargin ? { mb: { xs: 0, md: '34px' } } : {}}
      {...props}
    />
  );
};

export default PasswordInput;
