import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DataTable from 'components/DataTable';

import {
  amountCellRenderer,
  amountWithDollarCellRenderer,
  dateCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';
import { REPORTING_TEMPLATE_OPEN_END } from 'constants/funds';

export const openEndMarketTransactionHeaders = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'unitPriceNet',
    headerName: 'Unit Value',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ ...props, fractions: 4 }),
  },
  {
    field: 'unitHeld',
    headerName: 'Unit Held',
    flex: 1,
    renderCell: (props) => amountCellRenderer({ ...props, fractions: 2 }),
  },
  {
    field: 'totalValue',
    headerName: 'Total Value',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ ...props, fractions: 2 }),
  },
];

export const closedEndMarketTransactionHeaders = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'unitHeld',
    headerName: 'Unit Held',
    flex: 1,
    renderCell: (props) => amountCellRenderer({ ...props, fractions: 2 }),
  },
  {
    field: 'commitmentCalled',
    headerName: 'Commitment called',
    flex: 1,
    renderCell: (props) => percentCellRenderer({ ...props, fractions: 2 }),
  },
  {
    field: 'funded',
    headerName: 'Funded',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ ...props, fractions: 2 }),
  },
  {
    field: 'totalValue',
    headerName: 'Est. Value (funded)',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ ...props, fractions: 2 }),
  },
];

const MarketTransactionList = ({ transactions = [], portfolio }) => {
  const headers = useMemo(
    () =>
      portfolio?.fund?.reportingTemplate === REPORTING_TEMPLATE_OPEN_END
        ? openEndMarketTransactionHeaders
        : closedEndMarketTransactionHeaders,
    [portfolio?.fund?.reportingTemplate],
  );

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} alignItems="center" columnSpacing={2}>
        <Typography fontWeight="700 !important">Historical Values</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          headerData={headers}
          data={transactions}
          containerProps={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  );
};

export default MarketTransactionList;
