import isFunction from './isFunction';

const condTrueFalse = (cond, t, f, { executeFn = true } = {}) => {
  if (cond) {
    return executeFn && isFunction(t) ? t() : t;
  }

  return executeFn && isFunction(f) ? f() : f;
};

export default condTrueFalse;
