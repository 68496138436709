import React, {
  forwardRef,
  memo,
  useState,
  useRef,
  useImperativeHandle,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';

import palette from 'theme/palette';
import isNotEmptyObject from 'utils/isNotEmptyObject';
import getMobileNumberValue from 'utils/getMobileNumberValue';
import showFieldWhen from 'utils/showFieldWhen';
import condTrueFalse from 'utils/condTrueFalse';
import getDropdownValue from 'utils/getDropdownValue';
import omitObjKeys from 'utils/omitObjKeys';
import getNestedObject from 'utils/getNestedObject';
import useSummarySection from 'hooks/useSummarySection';

import { DATE_MONTH_YEAR } from 'constants/formats';

import { SUMMARY_FAST_UPDATES_FIELDS } from 'constants/investmentEntity';

import thousandSeparator from 'utils/strings/thousandSeparators';
import SectionData from './SectionData';
import FormikField from './components/FormikField';

const editStateContainerStyles = {
  background: palette.form.bgWarningLight,
  padding: '33px 24px',
  borderRadius: '6px',
  marginBottom: '40px',
};

const SummarySection = forwardRef(
  (
    {
      title,
      titleTypographyVariant = 'h5',
      fields = [],
      viewFields = [],
      setValues,
      values = {},
      validationSchema,
      formName,
      withEdit = true,
      validateOnMount = false,
      setIsError,
      sectionListView = false,
      fieldDividerStyles,
      onIsEditChanged,
      onClickEdit,
    },
    ref,
  ) => {
    const formRef = useRef();
    const { handleClickEdit, isEdit, setIsEdit } = useSummarySection({
      cb: onIsEditChanged,
      onClickEdit,
    });
    const [shouldUpdateList, setShouldUpdateList] = useState(false);

    useEffect(() => {
      if (formRef.current) formRef.current.setValues(values);
    }, [values]);

    const handleClickDiscard = useCallback(
      ({ setIsEditParent }) => {
        if (formRef.current) {
          formRef.current.setErrors({});
          formRef.current.setValues(values);
          handleClickEdit();
          if (setIsEditParent) setIsEditParent(false);
        }
      },
      [formRef.current, values],
    );

    const handleClickSave = useCallback(
      async ({ setIsEditParent }) => {
        let validateResult = true;
        if (validationSchema)
          validateResult = await formRef?.current?.validateForm();
        const isFormValid =
          setValues && formRef.current && !isNotEmptyObject(validateResult);
        condTrueFalse(isFormValid, () => {
          setValues({
            ...formRef?.current?.values,
          });
          handleClickEdit(!isEdit);
          if (setIsEditParent) setIsEditParent(false);
        });
      },
      [formRef.current, setValues],
    );

    const isDatePicker = useCallback(
      (fieldProps) => fieldProps.fieldComponent === 'datePicker',
      [],
    );
    const isDropdown = useCallback(
      (fieldProps) => fieldProps.fieldComponent === 'dropdown',
      [],
    );
    const isPhoneNumber = useCallback(
      (fieldProps) => fieldProps.fieldComponent === 'phone',
      [],
    );
    const isUpload = useCallback(
      (fieldProps) => fieldProps.fieldComponent === 'upload',
      [],
    );
    const isYesNoQuestion = useCallback(
      (fieldProps) => fieldProps.fieldComponent === 'yesNoQuestion',
      [],
    );
    const isMultipleChoiceQuestion = useCallback(
      (fieldProps) => fieldProps.fieldComponent === 'multipleChoiceQuestion',
      [],
    );

    const getMultipleChoiceQuestionDisplayValue = useCallback(
      (options, value) =>
        options.find(({ value: optionsValue }) => optionsValue === value)
          ?.displayValue,
      [],
    );
    const getDisplayedValue = useCallback(
      (fieldProps) => {
        const fieldValue = getNestedObject(values, fieldProps.name);

        switch (true) {
          case fieldProps?.isMultipleValue:
            return fieldProps?.keys
              ?.map(({ key, options }) => {
                if (options) return getDropdownValue(values?.[key], options);
                return values?.[key];
              })
              ?.join(' ');
          case isDropdown(fieldProps):
            return getDropdownValue(
              fieldValue,
              fieldProps?.unfilteredOptions || fieldProps?.options,
            );
          case isDatePicker(fieldProps) && !!fieldValue:
            return dayjs(fieldValue).format(DATE_MONTH_YEAR);
          case isUpload(fieldProps):
            return fieldValue?.map((file) => file.fileName)?.join(', ');
          case isPhoneNumber(fieldProps):
            return getMobileNumberValue(values?.countryCode, fieldValue);
          case isYesNoQuestion(fieldProps):
            return condTrueFalse(fieldValue, 'Yes', 'No');
          case isMultipleChoiceQuestion(fieldProps):
            return getMultipleChoiceQuestionDisplayValue(
              fieldProps.options,
              fieldValue,
            );
          case fieldProps?.withCurrency:
            return `${values.currency}$ ${thousandSeparator(fieldValue)}`;
          default:
            return fieldValue;
        }
      },
      [values],
    );

    const listOfFields = useMemo(() => {
      setShouldUpdateList(false);
      if (isEdit) return fields;
      if (!isEdit && viewFields?.length > 0) return viewFields;
      return fields;
    }, [shouldUpdateList, isEdit]);

    useImperativeHandle(ref, () => ({
      getFormValues: () => formRef?.current?.values,
      getFormErrors: () => formRef?.current?.errors,
      getTouchedFields: () => formRef?.current?.touched,
      setIsEdit,
      handleClickSave,
      handleClickDiscard,
      getHandleBlur: () => formRef?.current?.handleBlur,
      handleClickEdit,
      isEdit,
    }));

    return (
      <Grid container sx={condTrueFalse(isEdit, editStateContainerStyles, {})}>
        <Grid
          item
          container
          display="flex"
          justifyContent="space-between"
          xs={12}
        >
          <>
            <Grid item>
              {title && (
                <Typography variant={titleTypographyVariant}>
                  {title}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {condTrueFalse(
                withEdit,
                condTrueFalse(
                  !isEdit,
                  <ModeEditOutlineOutlinedIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={handleClickEdit}
                  />,
                  <>
                    <UndoOutlinedIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={handleClickDiscard}
                    />
                    <SaveOutlinedIcon
                      sx={{ cursor: 'pointer', marginLeft: '18px' }}
                      onClick={handleClickSave}
                    />
                  </>,
                ),
              )}
            </Grid>
          </>
        </Grid>

        {title && (
          <Grid item xs={12}>
            <Divider
              variant="middle"
              sx={{
                margin: '10px 0 30px',
                border: `1px solid ${palette.text.disabled}`,
              }}
            />
          </Grid>
        )}

        <Grid item container xs={12}>
          <Formik
            innerRef={formRef}
            initialValues={values}
            validationSchema={validationSchema}
            validateOnMount={validateOnMount}
            displayName={formName}
          >
            {({ values: formikValues, errors }) => {
              // set error state to parent
              if (setIsError) setIsError(!!Object.keys(errors).length);
              return (
                <Form style={{ width: '100%' }} ref={formRef}>
                  {listOfFields.map(
                    ({
                      showWhen = [],
                      fastField = true,
                      editable = true,
                      ...fieldProps
                    }) =>
                      showFieldWhen({
                        showWhen,
                        values: formikValues,
                        isEdit,
                      }) ? (
                        <FormikField
                          key={fieldProps?.fieldLabel}
                          name={condTrueFalse(
                            isEdit,
                            fieldProps.name,
                            `readonly-${fieldProps.name}`,
                          )}
                          fastField={fastField}
                        >
                          {({ field, form: editForm }) => (
                            <SectionData
                              listView={sectionListView}
                              isEdit={!!(isEdit && editable)}
                              fieldDividerStyles={fieldDividerStyles}
                              fieldProps={{
                                ...fieldProps,
                                ...field,
                                value: condTrueFalse(
                                  isEdit && editable,
                                  getNestedObject(
                                    editForm.values,
                                    fieldProps.name,
                                  ),
                                  getDisplayedValue(fieldProps),
                                ),
                                onChange: (evt) => {
                                  condTrueFalse(
                                    SUMMARY_FAST_UPDATES_FIELDS.includes(
                                      fieldProps.name,
                                    ),
                                    setShouldUpdateList(true),
                                  );

                                  if (
                                    [
                                      'isAustralianRetirement',
                                      'isFinancialInstitution',
                                    ].includes(evt.target.name)
                                  ) {
                                    editForm.setValues({
                                      ...editForm.values,
                                      ...omitObjKeys(
                                        {
                                          isFinancialInstitution: undefined,
                                          isManagedByAnotherInstitution:
                                            undefined,
                                          isNFE: undefined,
                                          hasGIIN: undefined,
                                          hasNoGIINValues: undefined,
                                        },
                                        [evt.target.name],
                                      ),
                                    });
                                  }
                                  if (
                                    evt.target.name === 'hasAustraliaTax' &&
                                    evt.target.value === false
                                  ) {
                                    editForm.setValues({
                                      ...editForm.values,
                                      hasOtherCountryTax: true,
                                      otherCountryTax: '',
                                    });
                                  }

                                  editForm.handleChange(evt);
                                },
                                onBlur: editForm.handleBlur,
                                errorValue: editForm.errors[fieldProps.name],
                                ...condTrueFalse(
                                  isDatePicker(fieldProps),
                                  {
                                    setFieldValue: editForm.setFieldValue,
                                  },
                                  {},
                                ),
                                ...condTrueFalse(
                                  isPhoneNumber(fieldProps),
                                  {
                                    countryCodeValue:
                                      editForm.values?.countryCode,
                                  },
                                  {},
                                ),
                                ...condTrueFalse(
                                  isMultipleChoiceQuestion(fieldProps),
                                  {
                                    childrenProps: {
                                      values: editForm.values,
                                      onChange: editForm.handleChange,
                                    },
                                  },
                                  {},
                                ),
                              }}
                            />
                          )}
                        </FormikField>
                      ) : (
                        ''
                      ),
                  )}
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    );
  },
);

export default memo(SummarySection);
