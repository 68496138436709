import React, { memo } from 'react';

import ShowMe from 'components/ShowMe';

const ImageContent = ({
  height,
  logoSrc,
  headerImageSrc,
  logoStyles = {},
  imageStyles = {},
}) => (
  <div style={{ position: 'relative', height: '100%', width: '100%' }}>
    <ShowMe when={!!logoSrc}>
      <img
        alt="fund-logo"
        src={logoSrc}
        style={{
          position: 'absolute',
          top: 0,
          left: '10%',
          width: 85,
          height: 85,
          objectFit: 'cover',
          backgroundColor: 'white',
          boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
          ...logoStyles,
        }}
      />
    </ShowMe>
    <img
      alt="fund-header-img"
      src={headerImageSrc}
      style={{ width: '100%', height, objectFit: 'cover', ...imageStyles }}
    />
  </div>
);

export default memo(ImageContent);
