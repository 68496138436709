import React, { memo, useCallback, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Input from 'components/Input';

import getNumbers from 'utils/strings/getNumbers';
import thousandSeparator from 'utils/strings/thousandSeparators';

import {
  MIN_FUND_INVESTMENT_AMOUNT,
  MAX_FUND_INVESTMENT_AMOUNT,
} from 'constants/investmentEntity';
import { ReactComponent as SwapHorizontalIcon } from 'assets/icons/swap-horizontal.svg';
import CurrencyInput from 'components/Input/CurrencyInput';

const MoneyToUnitsConverter = ({
  name = 'money',
  value,
  onChange,
  unitPrice = 1,
  listenUnitsValue,
  errors = {},
  minMoney = MIN_FUND_INVESTMENT_AMOUNT,
  maxMoney = MAX_FUND_INVESTMENT_AMOUNT,
  currency = 'USD',
  updateToMax = false,
  autoFocus,
}) => {
  const [amount, setAmount] = useState(value);
  const [units, setUnits] = useState(getNumbers(amount) / unitPrice);

  const handleUpdateUnits = useCallback(
    ({ unitsValue }) => {
      setUnits(unitsValue);
    },
    [amount, unitPrice],
  );

  const handleChange = useCallback(
    (e, { unitsValue } = {}) => {
      let newValue = getNumbers(e.target.value);
      if (newValue > maxMoney) {
        e.target.value = maxMoney;

        if (!updateToMax) return;
      }

      setAmount(e.target.value);

      // Update Units State.
      handleUpdateUnits({
        unitsValue:
          unitsValue || thousandSeparator(getNumbers(newValue) / unitPrice),
      });

      if (newValue < minMoney) {
        newValue = 0;

        e.target.value = 0;
      }

      const newEvent = { ...e };

      if (listenUnitsValue) {
        newEvent.target.value = {
          money: newValue,
          units: newValue / unitPrice,
        };
      }

      onChange(newEvent);
    },
    [unitPrice],
  );

  const handleChangeUnit = useCallback(
    (e) => {
      const newAmountValue = getNumbers(e.target.value) * unitPrice;

      handleChange(
        {
          target: { name, value: thousandSeparator(newAmountValue) },
        },
        { unitsValue: e.target.value },
      );
    },
    [unitPrice],
  );

  return (
    <Grid
      container
      item
      justifyContent="center"
      columnSpacing={{ xs: 0, sm: 1, md: 2 }}
    >
      <Grid item xs={12} sm={5}>
        <CurrencyInput
          fullWidth
          autoFocus={autoFocus}
          data-testid={`investment-amount-${name}-input`}
          id={name}
          name={name}
          placeholder=""
          type="number"
          value={amount}
          onChange={handleChange}
          error={false}
          errorValue={errors[name]}
          currency={currency}
        />
      </Grid>

      <Grid item xs={12} sm={2} textAlign="center">
        <IconButton
          disabled
          sx={{
            width: { xs: '48px', sm: '100%' },
            height: ['40px', 'auto'],
          }}
        >
          <SwapHorizontalIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Input
          fullWidth
          withThousandSeparator
          data-testid="investment-amount-units-input"
          id="units"
          name="units"
          placeholder=""
          type="number"
          value={units}
          onChange={handleChangeUnit}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">Units</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default memo(MoneyToUnitsConverter);
