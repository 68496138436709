import mapValue from 'utils/mapValue';
import getNumbers from 'utils/strings/getNumbers';

const mapFundUpdates = (formValues) => ({
  id: formValues.id,
  fundConfigurationId: formValues.fundConfigurationId,
  date: formValues.date,
  description: formValues.description,
  called: mapValue(getNumbers(formValues.called), formValues.called),
  toCall: mapValue(getNumbers(formValues.toCall), formValues.toCall),
  distribution: mapValue(
    getNumbers(formValues.distribution),
    formValues.distribution,
  ),
  unitPriceGross: mapValue(
    getNumbers(formValues.unitPriceGross),
    formValues.unitPriceGross,
  ),
  unitPriceNet: mapValue(
    getNumbers(formValues.unitPriceNet),
    formValues.unitPriceNet,
  ),
  balanceGross: mapValue(
    getNumbers(formValues.balanceGross),
    formValues.balanceGross,
  ),
  balanceNet: mapValue(
    getNumbers(formValues.balanceNet),
    formValues.balanceNet,
  ),

  updateType: formValues.updateType,
});

const addFundUpdates = (formValues) => {
  const payload = mapFundUpdates(formValues);

  return payload;
};

export default {
  addFundUpdates,
};
