import React, { createContext, useContext, useReducer } from 'react';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

const DashboardLoadingStateContext = createContext();
const DashboardLoadingActionContext = createContext();

const initialState = {
  isLoading: false,
  isFetched: false,
};

const setReturnData = (state, payload) => ({
  ...state,
  ...payload,
});

const reducer = (state, { type, payload }) => {
  if (type === 'SET_DATA') return setReturnData(state, payload);
  return state;
};

// methods
const useDashboardLoadingActionContext = () => {
  const dashboardLoadingAction = useContext(DashboardLoadingActionContext);
  if (typeof dashboardLoadingAction === 'undefined') {
    throw new Error(
      'useDashboardLoadingActionContext must be used within DashboardLoadingProvider',
    );
  }

  const handleSetIsLoading = (isLoading) => {
    dashboardLoadingAction({ type: 'SET_DATA', payload: { isLoading } });
  };
  const handleSetIsFetched = (isFetched) => {
    dashboardLoadingAction({ type: 'SET_DATA', payload: { isFetched } });
  };

  return {
    handleSetIsLoading,
    handleSetIsFetched,
  };
};

// states
const useDashboardLoadingStateContext = () => {
  const dashboardLoadingState = useContext(DashboardLoadingStateContext);
  if (typeof dashboardLoadingState === 'undefined') {
    throw new Error(
      'useDashboardLoadingStateContext must be used within DashboardLoadingProvider',
    );
  }
  return dashboardLoadingState;
};

const useDashboardLoading = () => {
  const states = useDashboardLoadingStateContext();
  const actions = useDashboardLoadingActionContext();

  return {
    ...states,
    ...actions,
  };
};

// providers
export const DashboardLoadingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DashboardLoadingStateContext.Provider value={state}>
      <DashboardLoadingActionContext.Provider value={dispatch}>
        {children}
      </DashboardLoadingActionContext.Provider>
    </DashboardLoadingStateContext.Provider>
  );
};

export const LoadingSpinner = ({ isLoading, isFetched }) =>
  isLoading &&
  !isFetched && (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '20vh' }}
    >
      <CircularProgress />
    </Grid>
  );

export default useDashboardLoading;
