import React from 'react';

import Grid from '@mui/material/Grid';

import FormTitle from 'components/FormTitle';
import FieldContainer from 'components/FieldContainer';
import Dropdown from 'components/Dropdown';

import { FUND_ENTITY_STATUS_OPTIONS } from 'constants/investmentEntity';

const InvestmentStatusSection = ({
  onChange,
  values,
  errors,
  touched,
  onBlur,
}) => (
  <Grid container item>
    <Grid item xs={12}>
      <FormTitle title="Investment Status" />
    </Grid>

    <Grid container item xs={12} rowSpacing={3}>
      <FieldContainer
        item
        label="Set status this investment to"
        labelGridProps={{ xs: 3 }}
        childGridProps={{ container: true, xs: true, columnGap: 2 }}
      >
        <Grid item xs={6}>
          <Dropdown
            data-testid="investmentFund-investment-status-dropdown"
            fullWidth={false}
            id="investmentStatus"
            name="investmentStatus"
            placeholder="Please select investment status"
            options={FUND_ENTITY_STATUS_OPTIONS}
            value={values.investmentStatus}
            onChange={onChange}
            errorValue={touched?.investmentStatus && errors?.investmentStatus}
            onBlur={onBlur}
          />
        </Grid>
      </FieldContainer>
    </Grid>
  </Grid>
);

export default InvestmentStatusSection;
