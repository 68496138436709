import Services from 'api/services';
import { base64ToBlob, downloadFile } from 'utils/download';
import condTrueFalse from 'utils/condTrueFalse';

const fetchDataRoomDownloadURL = async ({
  fundId,
  token,
  handleFetchError,
}) => {
  let res;
  try {
    res = await Services.getDataRoomDocumentDownload(fundId, token);
    return res;
  } catch (error) {
    condTrueFalse(handleFetchError, () =>
      handleFetchError(error?.response?.data),
    );
    return '';
  }
};

const fetchDocumentDownloadURL = async ({ token, handleFetchError }) => {
  let res;
  try {
    res = await Services.downloadDocuments(token);
    return res;
  } catch (error) {
    condTrueFalse(handleFetchError, () =>
      handleFetchError(error?.response?.data),
    );
    return '';
  }
};

const downloadBase64WithBlob = (base64, filename) => {
  if (base64 && filename) {
    const atob = window.atob(base64);
    const blob = base64ToBlob(atob);
    const href = URL.createObjectURL(blob);
    downloadFile({
      href,
      filename,
    });
  }
};

export default {
  downloadBase64WithBlob,
  fetchDataRoomDownloadURL,
  fetchDocumentDownloadURL,
};
