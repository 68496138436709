import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';

import FieldListContainer from 'components/FieldListContainer';
import FieldContainer from 'components/FieldContainer';
import Input from 'components/Input';
import ShowMe from 'components/ShowMe';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const FieldSection = ({
  index,
  values,
  onChange,
  onDeleteFieldSection,
  readOnly,
}) => {
  const handleChange = useCallback(
    (e) => {
      onChange(e, index);
    },
    [index, onChange],
  );

  const handleDeleteFieldSection = useCallback(() => {
    onDeleteFieldSection(index);
  }, [onDeleteFieldSection]);

  return (
    <FieldListContainer listNo={index + 1}>
      <Grid item xs={5.5}>
        <FieldContainer label="Heading" direction="column" alignItems="unset">
          <Input
            fullWidth
            name="heading"
            disabled={readOnly}
            value={values.heading}
            onChange={handleChange}
          />
        </FieldContainer>
      </Grid>
      <Grid item xs={5.5}>
        <FieldContainer label="Value" direction="column" alignItems="unset">
          <Input
            fullWidth
            name="value"
            disabled={readOnly}
            value={values.value}
            onChange={handleChange}
          />
        </FieldContainer>
      </Grid>
      <ShowMe when={!readOnly}>
        <Grid
          item
          xs="auto"
          onClick={handleDeleteFieldSection}
          sx={{ cursor: 'pointer', paddingBottom: 2, alignSelf: 'end' }}
        >
          <TrashIcon />
        </Grid>
      </ShowMe>
    </FieldListContainer>
  );
};

export default memo(FieldSection);
