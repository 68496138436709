import * as yup from 'yup';

const adminArticleValidationSchema = yup.object({
  category: yup.string().required(),
  date: yup.string().required(),
  summary: yup.string().required(),
  title: yup.string().required(),
  content: yup.string().required(),
});

export default adminArticleValidationSchema;
