import mapValue from 'utils/mapValue';

const EVENT_DEFAULT_VALUES = {
  event: {
    id: '',
    eventUid: '',
    category: '',
    title: '',
    date: new Date(),
    link: '',
  },
  isHidden: false,
  createdBy: '',
  createdAt: new Date().toISOString(),
  updatedBy: '',
  updatedAt: '',
};

const event = (values) => ({
  event: {
    ...EVENT_DEFAULT_VALUES.event,
    ...values.event,
  },
  isHidden: values.event?.isHidden,
  createdBy: values.event?.createdBy,
  updatedBy: values.event?.updatedBy,
  createdAt: mapValue(
    values.event?.created_at,
    EVENT_DEFAULT_VALUES.event.createdAt,
  ),
  updatedAt: mapValue(
    values.event?.updated_at,
    EVENT_DEFAULT_VALUES.event.updatedAt,
  ),
});

export default event;
