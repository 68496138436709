import services from 'api/services';

export const UploadDataRoomFiles = async (id, formData) => {
  try {
    const result = await services.adminUploadFileDataRoom(id, formData);
    return result.data;
  } catch (_) {
    return false;
  }
};

export const deleteDataRoomFile = async (payload) => {
  try {
    const result = await services.adminDeleteFileDataRoom(payload);
    return result.data;
  } catch (_) {
    return false;
  }
};

export const getNewTempDeletedFiles = ({
  deletedFiles,
  fileKeys,
  isDeleteCategory,
  category,
  dataRooms,
}) => {
  const newDeletedFiles = new Map(deletedFiles);

  // Set map if not registered yet.
  if (!newDeletedFiles.get(category.id))
    newDeletedFiles.set(category.id, {
      id: category.id,
      documents: [],
    });

  const deletedCategory = newDeletedFiles.get(category.id);

  if (isDeleteCategory) {
    // Set category's `isDeleted` to true.
    newDeletedFiles.set(deletedCategory.id, {
      ...dataRooms.find((dataRoom) => dataRoom.id === category.id),
      isDeleted: true,
    });

    const tempDocuments = category.documents.map((document) => !document.id);
    // If there's unregistered document, delete from S3.
    if (tempDocuments.length) deleteDataRoomFile({ keys: tempDocuments });

    return newDeletedFiles;
  }

  const deletedDocument = category.documents.find((document) =>
    fileKeys.includes(document.key),
  );

  // If deleted document is belong to new Category, delete from S3.
  if (!deletedDocument) {
    deleteDataRoomFile({ keys: fileKeys });
    return newDeletedFiles;
  }

  const newDeletedDocuments = [...deletedCategory.documents];

  // Register deleted document to state.
  newDeletedFiles.set(deletedCategory.id, {
    ...deletedCategory,
    documents: [
      ...newDeletedDocuments,
      { ...deletedDocument, isDeleted: true },
    ],
  });

  return newDeletedFiles;
};

export const mapDocuments = ({ documents = [], category }) =>
  documents.map((document) => ({ ...document, folder: category?.category }));

export const getIsDeletedStatus = ({
  dataRoomId,
  documents,
  deletedFiles,
  folderId,
}) => {
  let mappedDocuments = [...documents];

  if (folderId)
    mappedDocuments = mappedDocuments.map((document, index) => ({
      ...document,
      folderId,
      orderId: index + 1,
    }));

  if (!deletedFiles.get(dataRoomId))
    return { isDeleted: false, documents: mappedDocuments };

  const isDeleted = !!deletedFiles.get(dataRoomId).isDeleted;

  if (isDeleted) return { isDeleted: true, documents };

  return {
    isDeleted: false,
    documents: mappedDocuments.concat(deletedFiles.get(dataRoomId).documents),
  };
};
