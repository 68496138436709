import React, { memo, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import DatePicker from 'components/DatePicker';
import PhoneNumber from 'components/PhoneNumber';
import UploadButton from 'components/Button/UploadButton';
import YesNoQuestion from 'components/YesNoQuestion';
import MultipleChoiceQuestion from 'components/MultipleChoiceQuestion';
import CurrencyInput from 'components/Input/CurrencyInput';

export const fieldComponents = {
  input: Input,
  dropdown: Dropdown,
  datePicker: DatePicker,
  phone: PhoneNumber,
  download: UploadButton,
  upload: UploadButton,
  yesNoQuestion: YesNoQuestion,
  multipleChoiceQuestion: MultipleChoiceQuestion,
  currencyInput: CurrencyInput,
};

const EditSectionData = ({
  fieldProps: {
    fieldComponent,
    fieldLabel,
    fieldDisplay = 'flex',
    ...fieldComponentProps
  },
  isEdit,
  listView = false,
  fieldDividerStyles = {},
}) => {
  const FieldComponent = useMemo(
    () => fieldComponents[fieldComponent],
    [fieldComponent, isEdit],
  );
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const fieldComponentRender = useMemo(() => {
    switch (true) {
      case isEdit: {
        return <FieldComponent {...fieldComponentProps} fullWidth />;
      }
      case !!fieldComponentProps?.renderer: {
        return fieldComponentProps.renderer({
          value: fieldComponentProps.value,
        });
      }
      default: {
        return (
          <Typography variant="h6" sx={fieldComponentProps?.displaySx}>
            {fieldComponentProps.value}
          </Typography>
        );
      }
    }
  }, [fieldComponent, isEdit, fieldComponentProps]);

  return (
    <List sx={{ width: '100%' }}>
      <ListItem disablePadding>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={listView ? 5 : 8}
            display="flex"
            justifyContent={isSM ? 'flex-start' : 'space-between'}
            alignItems="center"
          >
            <Typography variant="bodyGreyStatic">
              {fieldLabel}
              {fieldComponentProps?.required && '*'}
            </Typography>
            <Typography
              variant="bodyGreyStatic"
              sx={{ display: ['none', 'initial'] }}
            >
              :
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={listView ? 7 : 4}
            display={fieldDisplay}
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingLeft: '5px', marginTop: isSM && '5px' }}
          >
            {fieldComponentRender}
          </Grid>
        </Grid>
      </ListItem>
      <Divider
        variant="middle"
        sx={{ margin: '10px 0 30px', ...fieldDividerStyles }}
      />
    </List>
  );
};

export default memo(EditSectionData);
