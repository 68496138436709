import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import history from 'utils/history';
import palette from 'theme/palette';

const textColor = palette.reach.obsidian;
const iconSelectedColor = palette.reach.menuLink;
const navItemSelectedColor = palette.reach.menuLink;

const NavItem = ({ item, open, sx = {} }) => {
  const location = useLocation();

  const itemIcon = useMemo(() => {
    if (!item.icon) return null;

    const Icon = item.icon;
    return <Icon style={{ minWidth: '22px', minHeight: '22px' }} />;
  });

  const isSelected = useMemo(
    () =>
      location.pathname === item.id ||
      (item.id !== '/dashboard' && location.pathname.includes(item.id)),
    [open, location.pathname],
  );

  const handleRoute = () => history.push(item.url);

  return (
    <ListItemButton
      disabled={item.disabled}
      onClick={handleRoute}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: '34px',
        py: 1,
        borderLeft: `5px solid ${
          isSelected ? navItemSelectedColor : 'transparent'
        }`,
        height: '53px',
        '&:hover': {
          bgcolor: `${navItemSelectedColor}30`,
        },
        '&.Mui-selected': {
          bgcolor: 'transparent',
        },
        ...sx,
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            color: isSelected ? iconSelectedColor : textColor,
            borderRadius: 1.5,
            minWidth: 18.75,
            width: 18.75,
            height: 18.75,
            alignItems: 'center',
            justifyContent: 'center',
            // 'svg > path': {
            //   fill: isSelected ? iconSelectedColor : textColor,
            // },
            marginRight: '34px',
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography
            variant="body"
            sx={(theme) => ({
              color: isSelected ? iconSelectedColor : textColor,
              fontSize: open ? '16px' : '1px !important',
              transition: theme.transitions.create('font-size', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            })}
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default NavItem;
