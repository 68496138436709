import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import TabPanels from 'components/TabPanels';
import Tabs from 'components/Tabs';

import services from 'api/services';
import useFetchApi from 'hooks/useFetchApi';
import useDashboardLoading from 'hooks/useDashboardLoading';
import useTabs from 'hooks/useTabs';
import condTrueFalse from 'utils/condTrueFalse';

import ContentContainer from '../ContentContainer';
import {
  fetchPendingTransactions,
  fetchTransactions,
  transactionsTabItems,
} from './HelperFunction';

const Transactions = () => {
  const [selectedInvestmentEntity, setSelectedInvestmentEntity] = useState('');

  const { isFetched, handleSetIsLoading, handleSetIsFetched } =
    useDashboardLoading();
  const { activeTabIdx, handleTabChange } = useTabs({
    tabChangeCallback: () => handleSetIsFetched(false),
  });

  const getTransactions = useMemo(
    () =>
      activeTabIdx
        ? fetchPendingTransactions()
        : fetchTransactions(selectedInvestmentEntity),
    [selectedInvestmentEntity, activeTabIdx],
  );

  useEffect(
    () => condTrueFalse(isFetched, () => handleSetIsFetched(false)),
    [],
  );

  const { apiData: portfolios, isFetchLoading } = useFetchApi({
    url: getTransactions,
    isFetched,
    setIsFetched: handleSetIsFetched,
    setIsLoading: handleSetIsLoading,
    dependencies: [isFetched, getTransactions, activeTabIdx],
    mapApiData: (res) => res.data,
  });

  const getUserInvestmentEntities = useMemo(
    () => services.getInvestmentEntityListUser(),
    [],
  );

  const { apiData: userInvestmentEntities } = useFetchApi({
    url: getUserInvestmentEntities,
    defaultApiData: [],
    mapApiData: ({ data: { data = [] } } = {}) =>
      data.map((entity) => ({
        ...entity,
        label: entity.name,
        value: entity.userInvestorMapId,
      })),
  });

  const handleChangeInvestmentEntity = useCallback((e) => {
    handleSetIsFetched(false);
    setSelectedInvestmentEntity(e.target.value);
  }, []);

  const memoizedTabPanelItems = useMemo(() => {
    const investmentEntityOptions = [{ label: 'All Entity', value: '' }].concat(
      ...userInvestmentEntities,
    );

    return transactionsTabItems({
      portfolios: Array.isArray(portfolios) ? portfolios : [],
      userInvestmentEntities: investmentEntityOptions,
      selectedInvestmentEntity,
      onChangeInvestmentEntity: handleChangeInvestmentEntity,
    });
  }, [portfolios, userInvestmentEntities, selectedInvestmentEntity]);

  const { pendingInvestments, pendingFundRedemptions } = useMemo(
    () => portfolios || {},
    [portfolios],
  );

  if (isFetchLoading) return null;

  return (
    <ContentContainer data-testid="tab-item-transactions">
      <Grid item xs={12}>
        <Tabs
          value={activeTabIdx}
          onChange={handleTabChange}
          centered={false}
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <TabComponents
            activeTabIdx={activeTabIdx}
            tabItems={memoizedTabPanelItems}
            testIdPrefix="admin-details-entity-tab"
          />
        </Tabs>
      </Grid>
      <Grid item xs>
        <TabPanels
          value={activeTabIdx}
          tabItems={memoizedTabPanelItems}
          pendingProps={{ pendingInvestments, pendingFundRedemptions }}
        />
      </Grid>
    </ContentContainer>
  );
};

export default Transactions;
