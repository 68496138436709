import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import DatePicker from 'components/DatePicker';
import DataTable from 'components/DataTable';

import { getFundRegistryHeaders } from './HelperFunction';

const FundRegistry = ({
  fundRegistries = [],
  maxDate,
  onChangeMaxDate,
  currency,
  fundDetails,
}) => {
  const fundRegistryHeaders = useMemo(
    () =>
      getFundRegistryHeaders({
        currency,
        reportingTemplate: fundDetails.reportingTemplate,
      }),
    [currency],
  );

  return (
    <Grid container rowSpacing={3}>
      <Grid container item xs={12} justifyContent="right">
        <Grid item xs="auto">
          <DatePicker
            fullWidth={false}
            name="maxDate"
            testId="maxDate-input"
            setFieldValue={onChangeMaxDate}
            value={maxDate}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          headerData={fundRegistryHeaders}
          data={fundRegistries}
          containerProps={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  );
};

export default FundRegistry;
