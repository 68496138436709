import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import VisibleIcon from '@mui/icons-material/VisibilityOutlined';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ShowMe from 'components/ShowMe';

import condTrueFalse from 'utils/condTrueFalse';
import palette from 'theme/palette';

import { ReactComponent as FolderOpen } from 'assets/icons/folder-open.svg';
import { ReactComponent as FolderClosed } from 'assets/icons/folder.svg';
import { ReactComponent as Document } from 'assets/icons/document-file.svg';

export const StyledListItem = styled(ListItem)(({ theme, firstelement }) => ({
  borderBottom: `1px solid ${theme.palette.border.light}`,
  borderTop:
    firstelement === 'true' ? `1px solid ${theme.palette.border.light}` : null,
  paddingLeft: '8px',
  paddingRight: '8px',
}));

export const LinkComp = ({
  folder = 'My Documents',
  token,
  type = 'document',
  ...props
}) => (
  <Link
    {...props}
    to={`/document/view?folder=${folder}&type=${type}&token=${token}`}
    target="_blank"
    state={{ folder }}
  />
);

const ExpandableListItem = ({ label, childItems, firstElement }) => {
  const [open, setOpen] = useState(false);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <StyledListItem disablePadding firstelement={`${firstElement}`}>
        <ListItemButton onClick={() => setOpen(!open)} disableGutters>
          <ListItemIcon>
            {open ? (
              <>
                <ListItemIcon>
                  <ExpandLess />
                </ListItemIcon>
                <ListItemIcon>
                  <FolderOpen
                    fill={condTrueFalse(
                      firstElement,
                      palette.reach.purpleHaze,
                      palette.reach.black,
                    )}
                  />
                </ListItemIcon>
              </>
            ) : (
              <>
                <ListItemIcon>
                  <ExpandMore />
                </ListItemIcon>
                <ListItemIcon>
                  <FolderClosed
                    fill={condTrueFalse(
                      firstElement,
                      palette.reach.purpleHaze,
                      palette.reach.black,
                    )}
                  />
                </ListItemIcon>
              </>
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={{
                fontWeight: 700,
                color: firstElement ? palette.reach.purpleHaze : null,
              }}
            >
              {label}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </StyledListItem>
      {open && (
        <List component="div" disablePadding>
          {childItems?.map(
            ({ title, id, token, enableView, enableDownload, folder }) => (
              <StyledListItem
                key={`${title}-${id}`}
                disableGutters
                secondaryAction={
                  <>
                    <LinkComp folder={folder} token={token}>
                      <IconButton
                        sx={{ color: palette.link }}
                        disabled={!enableView}
                        state={{ folder }}
                      >
                        <VisibleIcon />
                      </IconButton>
                    </LinkComp>
                    <IconButton
                      component={Link}
                      to={`/document/download?type=documents&token=${token}`}
                      target="_blank"
                      sx={{ color: palette.link }}
                      disabled={!enableDownload}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </>
                }
                sx={{ pl: 9 }}
              >
                <ShowMe when={!isMobileScreen}>
                  <ListItemIcon>
                    <Document />
                  </ListItemIcon>
                </ShowMe>
                <ListItemText
                  primary={title}
                  sx={{
                    color: palette.text.primary,
                    maxWidth: ['75%', '60%', '100%'],
                  }}
                />
              </StyledListItem>
            ),
          )}
        </List>
      )}
    </>
  );
};

export default ExpandableListItem;
