import React, { memo, useCallback, useEffect, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import InputField from 'components/Input';
import FieldContainer from 'components/FieldContainer';
import Dropdown from 'components/Dropdown';
import DragableAccordion from 'components/Accordion/DragableAccordion';
import TextEditor from 'components/TextEditor';

import condTrueFalse from 'utils/condTrueFalse';
import { FUND_DETAILS_CONTENT_POSITION_OPTIONS } from 'constants/funds';

import { useFormik } from 'formik';
import { getAccordionFooter } from './HelperFunction';

const BlockContentAccordion = ({
  index,
  order,
  tabIndex,
  tabValue,
  onDeleteBlockContent,
  onChange,
  readOnly,
  ...props
}) => {
  const fundDetailBlockForm = useFormik({
    initialValues: { ...tabValue },
  });

  useEffect(() => {
    onChange(fundDetailBlockForm.values);
  }, [fundDetailBlockForm.values]);

  const titleItems = useMemo(
    () => (
      <>
        <Grid container item xs>
          <FieldContainer
            label={`Block Title ${order + 1}`}
            direction="row"
            columnSpacing={2}
            labelGridProps={{ xs: 'auto' }}
            childGridProps={{ xs: true }}
          >
            <InputField
              fullWidth
              disabled={readOnly}
              name="title"
              value={fundDetailBlockForm.values.title}
              onChange={fundDetailBlockForm.handleChange}
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={2.5}>
          <FieldContainer
            label="Position"
            direction="row"
            columnSpacing={2}
            labelGridProps={{ xs: 'auto' }}
            childGridProps={{ xs: true }}
          >
            <Dropdown
              name="position"
              disabled={readOnly}
              onChange={fundDetailBlockForm.handleChange}
              value={fundDetailBlockForm.values.position}
              options={FUND_DETAILS_CONTENT_POSITION_OPTIONS}
            />
          </FieldContainer>
        </Grid>
      </>
    ),
    [
      fundDetailBlockForm.values.title,
      fundDetailBlockForm.values.position,
      onChange,
      readOnly,
    ],
  );

  const handleDelete = useCallback(() => {
    onDeleteBlockContent({ tabIndex, contentIndex: index });
  }, [index, tabIndex]);

  const footer = useMemo(
    () =>
      condTrueFalse(!readOnly, () =>
        getAccordionFooter({
          deleteKey: 'Block',
          onDelete: handleDelete,
        }),
      ),
    [handleDelete, readOnly],
  );

  return (
    <DragableAccordion
      readOnly={readOnly}
      titleItems={titleItems}
      footer={footer}
      background="#F2F2F2"
      accordionDetailsProps={{ sx: { margin: 0, background: '#F2F2F2' } }}
      accordionFooterProps={{ sx: { background: '#F2F2F2' } }}
      {...props}
    >
      <TextEditor
        id={`tabs-${tabIndex.toString()}-block-${index.toString()}`}
        onChange={fundDetailBlockForm.handleChange}
        value={fundDetailBlockForm.values.content}
        name="content"
        readOnly={readOnly}
      />
    </DragableAccordion>
  );
};

export default memo(BlockContentAccordion);
