import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import PrevIcon from '@mui/icons-material/ChevronLeft';

import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import Loading from 'components/Loading';
import SecondaryButton from 'components/Button/SecondaryButton';
import ShowMe from 'components/ShowMe';
import { useActions, useStores } from 'components/StateProvider';

import history from 'utils/history';
import getFullName from 'utils/getFullName';
import useDialog from 'hooks/useDialog';
import segmentTrack from 'hooks/useSegmentAnalytic/segmentTrack';
import { USER_STATUS } from 'constants/userStatus';
import { SEGMENT_ANALYTICS } from 'constants/segmentAnalytics';

import DashboardContent from './DashboardContent';
import CompleteProfileDialog from './CompleteProfileDialog';
import { submitProfileVerification } from './CompleteProfileDialog/helperFunctions';
import CompleteProfileSection from './CompleteProfileSection';

const handleRouteToDashboard = () => history.push('/dashboard/funds');

const NewInvestmentDashboard = () => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    handleUpdateUserStatusStore,
    handleGetUserAccessibility,
    handleGetUserProfile,
  } = useActions();
  const {
    auth: { user },
  } = useStores();
  const [searchParams] = useSearchParams();
  const [onboardingStatus, setOnboardingStatus] = useState('');
  const [snackbarError, setSnackbarError] = useState('');
  const [isLoading, setIsLoading] = useState();

  const currentFundId = useMemo(
    () => searchParams.get('fundId'),
    [searchParams.get('fundId')],
  );

  useEffect(() => {
    if (!currentFundId) handleGetUserProfile();
  }, [currentFundId]);

  const {
    isDialogOpen: isCompleteProfileDialogOpen,
    handleOpenDialog: handleOpenCompleteProfileDialog,
    handleCloseDialog: handleCloseCompleteProfileDialog,
  } = useDialog();

  const handleCompleteProfile = useCallback(
    async (formValues) => {
      try {
        setIsLoading(true);
        const newStatus = await submitProfileVerification(formValues);
        setOnboardingStatus(newStatus);

        if (newStatus === USER_STATUS.VERIFIED)
          segmentTrack(SEGMENT_ANALYTICS.USER_ONBOARDED, {
            confirmationStatus: newStatus,
            email: user.email,
          });

        await handleGetUserAccessibility();

        await handleGetUserProfile();

        await handleUpdateUserStatusStore(newStatus);

        handleCloseCompleteProfileDialog();
      } catch (err) {
        setSnackbarError(err.response?.data?.faultData?.faultString);
      } finally {
        setIsLoading(false);
      }
    },
    [user.email],
  );

  const handleCloseConfirmationDialog = useCallback(
    () => setOnboardingStatus(''),
    [],
  );

  const confirmationDialogProps = useMemo(() => {
    switch (onboardingStatus) {
      case USER_STATUS.PENDING_OVERSEAS:
        return {
          open: true,
          title: 'Onboarding process completed',
          subtitle: 'Your application is under review',
          confirmButtonLabel: 'Ok',
          onConfirm: handleCloseConfirmationDialog,
        };
      case USER_STATUS.IN_PROGRESS:
      case USER_STATUS.PENDING:
        return {
          open: true,
          title: 'Onboarding process failed!',
          subtitle: (
            <>
              Please retry the onboarding process, or contact the <b>admin</b>{' '}
              for more information regarding the onboarding process.
            </>
          ),
          confirmButtonLabel: 'Ok',
          onConfirm: handleCloseConfirmationDialog,
        };
      case USER_STATUS.VERIFIED:
        return {
          open: true,
          title: 'Congratulations, your profile is complete',
          subtitle:
            'Thanks for completing your profile. Explore our funds and start your investment journey with us. Remember, our experts are here to help you every step of the way. Therefore, reach out if you have any questions.',
          confirmButtonLabel: 'Ok',
          onConfirm: handleCloseConfirmationDialog,
        };
      case 'CANCELLED':
        return {
          open: true,
          title: 'Onboarding process uncompleted!',
          subtitle: 'Are you sure want to cancel the onboarding process?',
          confirmButtonLabel: 'Yes',
          onConfirm: handleCloseConfirmationDialog,
        };
      default:
        return {
          open: false,
        };
    }
  }, [onboardingStatus]);

  const handleCloseSnackbar = useCallback(() => setSnackbarError(''), []);

  return (
    <Grid container item direction="column" spacing={{ xs: 2, sm: 4 }}>
      <ShowMe when={!currentFundId}>
        <ShowMe when={false}>
          <Grid item>
            <Typography variant="h4">
              Welcome, {`${getFullName(user)} `}
              <Typography variant="body" display={{ xs: 'block' }}>
                Here&apos;s what you may have missed
              </Typography>
            </Typography>
          </Grid>
        </ShowMe>

        <ShowMe
          when={
            user.status_verification !== USER_STATUS.VERIFIED &&
            user.status_verification !== USER_STATUS.PENDING_OVERSEAS &&
            user.status_verification !== USER_STATUS.VERIFIED_OVERSEAS
          }
        >
          <Grid item data-testid="complete-profile-section-container">
            <CompleteProfileSection
              onClickCompleteProfile={handleOpenCompleteProfileDialog}
            />
          </Grid>
        </ShowMe>
      </ShowMe>

      <ShowMe when={!!currentFundId && !isXS}>
        <Grid item>
          <SecondaryButton variant="outlined" onClick={handleRouteToDashboard}>
            <Grid container alignItems="center">
              <PrevIcon />
              <Typography variant="body">Back to Funds</Typography>
            </Grid>
          </SecondaryButton>
        </Grid>
      </ShowMe>

      <DashboardContent
        onOpenCompleteProfileDialog={handleOpenCompleteProfileDialog}
      />

      <CompleteProfileDialog
        open={!!isCompleteProfileDialogOpen}
        onClose={handleCloseCompleteProfileDialog}
        onSubmit={handleCompleteProfile}
      />

      <AdminConfirmationDialog {...confirmationDialogProps} />

      <Snackbar
        open={!!snackbarError}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarError}
        </Alert>
      </Snackbar>

      <Loading popup loading={isLoading} />
    </Grid>
  );
};

export default NewInvestmentDashboard;
