import React from 'react';

import Chip from 'components/Chip';

import { getUserStatusChipColor } from 'utils/chipMappers';
import { USER_STATUS_LABEL } from 'constants/userStatus';
import getCountryByCode from 'utils/getCountryByCode';

// eslint-disable-next-line
export const SUMMARY_FIELDS_USER_COMPLETE_PROFILE = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Status',
    name: 'statusVerification',
    renderer: (statusVerification) => (
      <Chip
        label={USER_STATUS_LABEL[statusVerification?.value]}
        color={getUserStatusChipColor(statusVerification?.value)}
      />
    ),
  },
  {
    fieldComponent: 'datePicker',
    fieldLabel: 'Verification checked on',
    name: 'verificationCheckedOn',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Checked by',
    name: 'checkedBy',
    renderer: (checkedBy) =>
      checkedBy?.value === 'GREEN_ID_VERIFICATION'
        ? 'GreenID (Automatic)'
        : 'Admin (Manual)',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'First Name',
    name: 'firstName',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Middle Name',
    name: 'middleName',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Surname',
    name: 'surname',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Gender',
    name: 'gender',
    renderer: (gender) =>
      gender?.value?.toUpperCase() === 'F' ? 'Female' : 'Male',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Date Of Birth',
    name: 'dateOfBirth',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Country',
    name: 'country',
    renderer: (country) => getCountryByCode(country?.value)?.label,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Passport Number',
    name: 'passport',
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Passport Expiry Date',
    name: 'passportExpiry',
  },
];
