import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import YesNoQuestion from 'components/YesNoQuestion';
import InputField from 'components/Input';
import MultipleChoiceQuestion from 'components/MultipleChoiceQuestion';

import TaxContentContainer from './TaxContentContainer';

const otherCountryTINComponentDefaultProps = { placeholder: 'TIN*' };

const OtherCountryTaxNumber = ({
  values,
  onChange,
  errors,
  touched,
  onBlur,
  isTINAvailableComponentProps = {},
  otherCountryTINComponentProps = otherCountryTINComponentDefaultProps,
  name = 'isTINAvailable',
  multipleChoiceName = 'noTINReason',
  ContainerComponent = TaxContentContainer,
  containerProps = { container: true },
}) => {
  const isTINLabel = useMemo(
    () =>
      isTINAvailableComponentProps?.label ||
      `Do you have a TIN (tax identification number) or equivalent tax number from ${
        values?.otherCountryTax || ''
      }?`,
    [values?.otherCountryTax, isTINAvailableComponentProps?.label],
  );

  const multipleChoiceAnswer = useMemo(
    () => [
      {
        label:
          'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
        key: 'answer-no-1',
        value: '1',
      },
      {
        label:
          'The entity is otherwise unable to obtain a TIN or equivalent number.',
        value: '2',
        key: 'answer-no-2',
        children: values?.noTINReason === '2' && (
          <InputField
            fullWidth
            name="unableObtainTinReason"
            onChange={onChange}
            value={values?.unableObtainTinReason}
            placeholder="Please state why"
            onBlur={onBlur}
            errorValue={
              touched?.unableObtainTinReason && errors.unableObtainTinReason
            }
          />
        ),
      },
      {
        label: 'No TIN is required',
        value: '3',
        key: 'answer-no-3',
        children: (
          <Typography variant="caption">
            Note: Only select this reason if the domestic law of the relevant
            jurisdiction does not require the collection of the TIN issued by
            such jurisdiction.
          </Typography>
        ),
      },
    ],
    [
      values?.noTINReason,
      values?.unableObtainTinReason,
      touched?.unableObtainTinReason,
      errors.unableObtainTinReason,
    ],
  );

  const TINOrNoTINReason = useMemo(() => {
    if (values?.isTINAvailable === null || values?.isTINAvailable === undefined)
      return null;
    if (values?.isTINAvailable)
      return (
        <Grid style={otherCountryTINComponentProps.containerStyle}>
          <FormLabel>TIN or equivalent</FormLabel>
          <InputField
            fullWidth
            id="otherCountryTIN"
            name="otherCountryTIN"
            placeholder={otherCountryTINComponentProps.placeholder}
            onChange={onChange}
            value={values?.otherCountryTIN}
            onBlur={onBlur}
            errorValue={touched?.otherCountryTIN && errors.otherCountryTIN}
            InputProps={otherCountryTINComponentProps?.InputProps}
          />
        </Grid>
      );
    return (
      <MultipleChoiceQuestion
        name={multipleChoiceName}
        label="Please give reason for not providing your TIN"
        value={values?.noTINReason}
        onChange={onChange}
        options={multipleChoiceAnswer}
        errorValue={errors.noTINReason}
        childrenProps={{ values }}
      />
    );
  }, [
    values?.isTINAvailable,
    values?.otherCountryTIN,
    values?.noTINReason,
    values?.unableObtainTinReason,
    touched?.otherCountryTIN,
    errors.otherCountryTIN,
    errors.noTINReason,
  ]);

  return (
    <ContainerComponent {...containerProps}>
      <Grid item xs={12}>
        <YesNoQuestion
          name={name}
          label={isTINLabel}
          value={values?.isTINAvailable}
          onChange={onChange}
          onBlur={onBlur}
          errorValue={errors.isTINAvailable}
        />
      </Grid>
      <Grid item xs={values?.isTINAvailable ? 8 : 12}>
        {TINOrNoTINReason}
      </Grid>
    </ContainerComponent>
  );
};

export default OtherCountryTaxNumber;
