import React, { memo, useRef, useState, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {
  SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION,
  SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_QUESTION_4,
  COUNTRY_OPTIONS,
  getHasNoGIINLabel,
} from 'constants/investmentEntity';

import companyTaxSupportingInformationValidationSchema from 'validations/schema/addInvestment/company/companyTaxSupportingInformationValidationSchema';

import InvestorCard from 'components/InvestorCard';
import Input from 'components/Input';
import SummarySection from 'components/SummarySection';

import TaxDetailsFooter from '../TaxList/TaxDetailsFooter';

const TaxSupportingInformationSummary = ({
  values,
  setValues,
  withEdit = false,
}) => {
  const summarySectionRef = useRef();
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState(false);

  const InvestorTaxForm = useFormik({
    initialValues: { ...values.supportingInformation },
    validationSchema: companyTaxSupportingInformationValidationSchema,
    validateOnChange: true,
    displayName: 'taxSupportingInformation',
  });

  const HasNoGIINSummaryFields = useMemo(
    () => [
      {
        fieldComponent: 'multipleChoiceQuestion',
        fieldLabel: getHasNoGIINLabel(
          false,
          values?.supportingInformation?.hasNoGIINValues,
        ),
        name: 'hasNoGIINValues.question',
        showWhen: [
          {
            key: 'hasGIIN',
            value: (hasGIIN, v) =>
              hasGIIN === false && !!v.isFinancialInstitution,
          },
        ],
        fastField: false,
        options: [
          {
            label: 'Who is the exempt beneficial owner?',
            key: 'answer-no-1',
            value: '1',
            children: (
              <Input
                fullWidth
                name="hasNoGIINValues.exemptBeneficialOwnerName"
                onChange={InvestorTaxForm.handleChange}
                value={
                  InvestorTaxForm.values?.hasNoGIINValues
                    ?.exemptBeneficialOwnerName
                }
                placeholder="name"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={
                  InvestorTaxForm.touched?.hasNoGIINValues
                    ?.exemptBeneficialOwnerName &&
                  InvestorTaxForm.errors?.hasNoGIINValues
                    ?.exemptBeneficialOwnerName
                }
              />
            ),
            showChildWhen: [
              {
                key: 'hasNoGIINValues',
                value: (hasNoGIINValues) => hasNoGIINValues?.question === '1',
              },
            ],
            displayValue:
              values?.supportingInformation?.hasNoGIINValues
                ?.exemptBeneficialOwnerName,
          },
          {
            label:
              'Deemed-Compliant FFI (other than a Sponsored FI or Trustee Document Trust)',
            key: 'answer-no-2',
            value: '2',
            children: (
              <Input
                fullWidth
                name="hasNoGIINValues.deemedCompliantFFI"
                onChange={InvestorTaxForm.handleChange}
                value={
                  InvestorTaxForm?.values?.hasNoGIINValues?.deemedCompliantFFI
                }
                placeholder="..."
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={
                  InvestorTaxForm.touched?.hasNoGIINValues
                    ?.deemedCompliantFFI &&
                  InvestorTaxForm.errors?.hasNoGIINValues?.deemedCompliantFFI
                }
              />
            ),
            showChildWhen: [
              {
                key: 'hasNoGIINValues',
                value: (hasNoGIINValues) => hasNoGIINValues?.question === '2',
              },
            ],
            displayValue:
              values?.supportingInformation?.hasNoGIINValues
                ?.deemedCompliantFFI,
          },
          {
            label:
              'If you are a Sponsored Financial Institution, please provide:',
            key: 'answer-no-3',
            value: '3',
            children: [
              <Input
                fullWidth
                name="hasNoGIINValues.sponsoringEntityName"
                onChange={InvestorTaxForm.handleChange}
                value={
                  InvestorTaxForm?.values?.hasNoGIINValues?.sponsoringEntityName
                }
                placeholder="Sponsoring entity's name"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={
                  InvestorTaxForm.touched?.hasNoGIINValues
                    ?.sponsoringEntityName &&
                  InvestorTaxForm.errors?.hasNoGIINValues?.sponsoringEntityName
                }
              />,
              <Input
                fullWidth
                name="hasNoGIINValues.sponsoringEntityGIIN"
                onChange={InvestorTaxForm.handleChange}
                value={
                  InvestorTaxForm?.values?.hasNoGIINValues?.sponsoringEntityGIIN
                }
                placeholder="Sponsoring entity's GIIN"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={
                  InvestorTaxForm.touched?.hasNoGIINValues
                    ?.sponsoringEntityGIIN &&
                  InvestorTaxForm.errors?.hasNoGIINValues?.sponsoringEntityGIIN
                }
                style={{ marginTop: '18px' }}
              />,
            ],
            showChildWhen: [
              {
                key: 'hasNoGIINValues',
                value: (hasNoGIINValues) => hasNoGIINValues?.question === '3',
              },
            ],
            displayValue:
              values?.supportingInformation?.hasNoGIINValues
                ?.sponsoringEntityName,
          },
          {
            label: 'Trustee Documented Trust, please provide:',
            value: '4',
            key: 'answer-no-4',
            children: [
              <Input
                fullWidth
                name="hasNoGIINValues.trusteeName"
                onChange={InvestorTaxForm?.handleChange}
                value={InvestorTaxForm?.hasNoGIINValues?.trusteeName}
                placeholder="Trustee's name"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={
                  InvestorTaxForm.touched?.hasNoGIINValues?.trusteeName &&
                  InvestorTaxForm.errors?.hasNoGIINValues?.trusteeName
                }
              />,
              <Input
                fullWidth
                name="hasNoGIINValues.trusteeGIIN"
                onChange={InvestorTaxForm?.handleChange}
                value={InvestorTaxForm?.hasNoGIINValues?.trusteeGIIN}
                placeholder="Trustee's GIIN"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={
                  InvestorTaxForm.touched?.hasNoGIINValues?.trusteeGIIN &&
                  InvestorTaxForm.errors?.hasNoGIINValues?.trusteeGIIN
                }
                style={{ marginTop: '18px' }}
              />,
            ],
            showChildWhen: [
              {
                key: 'hasNoGIINValues',
                value: (hasNoGIINValues) => hasNoGIINValues?.question === '4',
              },
            ],
            displayValue:
              values?.supportingInformation?.hasNoGIINValues?.trusteeName,
          },
          {
            label: 'Other',
            value: '5',
            key: 'answer-no-5',
            children: (
              <Input
                fullWidth
                name="hasNoGIINValues.otherReason"
                onChange={InvestorTaxForm?.handleChange}
                value={InvestorTaxForm?.values?.hasNoGIINValues?.otherReason}
                placeholder="Other"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={
                  InvestorTaxForm.touched?.hasNoGIINValues?.otherReason &&
                  InvestorTaxForm.errors?.hasNoGIINValues?.otherReason
                }
              />
            ),
            showChildWhen: [
              {
                key: 'hasNoGIINValues',
                value: (hasNoGIINValues) => hasNoGIINValues?.question === '5',
              },
            ],
            displayValue:
              values?.supportingInformation?.hasNoGIINValues?.otherReason,
          },
        ],
      },
      // mock field to display second input
      {
        fieldComponent: 'multipleChoiceQuestion',
        fieldLabel: getHasNoGIINLabel(
          true,
          values?.supportingInformation?.hasNoGIINValues,
        ),
        name: 'hasNoGIINValues.question',
        showWhen: [
          {
            key: 'hasNoGIINValues',
            value: (hasNoGIINValues, _, isEditSummary) =>
              ['3', '4'].includes(hasNoGIINValues?.question) && !isEditSummary,
          },
        ],
        fastField: false,
        options: [
          {
            key: 'answer-no-3',
            value: '3',
            showChildWhen: [
              {
                key: 'hasNoGIINValues',
                value: (hasNoGIINValues) => hasNoGIINValues?.question === '3',
              },
            ],
            displayValue:
              values?.supportingInformation?.hasNoGIINValues
                ?.sponsoringEntityGIIN,
          },
          {
            value: '4',
            key: 'answer-no-4',
            showChildWhen: [
              {
                key: 'hasNoGIINValues',
                value: (hasNoGIINValues) => hasNoGIINValues?.question === '4',
              },
            ],
            displayValue:
              values?.supportingInformation?.hasNoGIINValues?.trusteeGIIN,
          },
        ],
      },
    ],
    [InvestorTaxForm, values, getHasNoGIINLabel],
  );

  const IsNFESummaryFields = useMemo(
    () => [
      {
        fieldComponent: 'multipleChoiceQuestion',
        fieldLabel: 'Active Non-Financial Entity (NFE)',
        name: 'isNFEValues.question',
        showWhen: [
          {
            key: 'isManagedByAnotherInstitution',
            value: (isManagedByAnotherInstitution, v) =>
              isManagedByAnotherInstitution !== undefined && !!v.isNFE,
          },
        ],
        fastField: false,
        options: [
          {
            label:
              'Less than 50% of the active NFE’s gross income from the preceding calendar year is passive income and less than 50% of its assets during the preceding calendar year are assets held for the production of passive income.',
            key: 'answer-no-1',
            value: '1',
            displayValue:
              'Less than 50% of the active NFE’s gross income from the preceding calendar year is passive income and less than 50% of its assets during the preceding calendar year are assets held for the production of passive income.',
          },
          {
            label:
              'Corporation that is regularly traded or a related entity of a regularly traded corporation.',
            key: 'answer-no-2',
            value: '2',
            displayValue:
              'Corporation that is regularly traded or a related entity of a regularly traded corporation.',
          },
          {
            label:
              'Governmental Entity, International Organization or Central Bank.',
            key: 'answer-no-3',
            value: '3',
            displayValue:
              'Governmental Entity, International Organization or Central Bank.',
          },
          {
            label: 'Other',
            value: '4',
            key: 'answer-no-4',
            children: (
              <Input
                fullWidth
                name="isNFEValues.otherReason"
                onChange={InvestorTaxForm?.handleChange}
                value={InvestorTaxForm?.values?.isNFEValues?.otherReason}
                placeholder="Please state why"
                onBlur={InvestorTaxForm?.handleBlur}
                errorValue={
                  InvestorTaxForm?.touched?.isNFEValues?.otherReason &&
                  InvestorTaxForm?.errors?.isNFEValues?.otherReason
                }
              />
            ),
            showChildWhen: [
              {
                key: 'isNFEValues',
                value: (isNFEValues) => isNFEValues?.question === '4',
              },
            ],
            displayValue:
              values?.supportingInformation?.isNFEValues?.otherReason,
          },
        ],
      },
    ],
    [InvestorTaxForm, values],
  );

  const Question6SummaryFields = useMemo(
    () => [
      {
        fieldComponent: 'yesNoQuestion',
        fieldLabel: (
          <Grid>
            <Typography>
              Does one or more of the following apply to you?
            </Typography>
            <ul>
              <li>
                Is any natural person that exercises control over you (for
                corporations, this would include directors or beneficial owners
                who ultimately own 25% or more of the share capital) a tax
                resident of any country outside Australia?
              </li>
              <br />
              <li>
                If you are a trust, is any natural person including trustee,
                protector, beneficiary, settlor or any other natural person
                exercising ultimate effective control over the trust a tax
                resident of any country outside of Australia?
              </li>
            </ul>
          </Grid>
        ),
        name: 'isControllingPersonANaturalOrTrust',
        showWhen: [{ key: 'isNFE', value: false }],
        fastField: false,
      },
      {
        fieldComponent: 'input',
        fieldLabel: 'Name of the controlling person',
        placeholder: "Person's name",
        name: 'controllingPersonFields.name',
        showWhen: [
          {
            key: 'isNFE',
            value: false,
          },
          {
            key: 'isControllingPersonANaturalOrTrust',
            value: true,
          },
        ],
      },
      {
        fieldComponent: 'datePicker',
        fieldLabel: 'Date of birth',
        placeholder: "Person's date of birth",
        name: 'controllingPersonFields.dateOfBirth',
        showWhen: [
          {
            key: 'isNFE',
            value: false,
          },
          {
            key: 'isControllingPersonANaturalOrTrust',
            value: true,
          },
        ],
      },
      {
        fieldComponent: 'input',
        fieldLabel: 'Address',
        placeholder: "Person's address",
        name: 'controllingPersonFields.address',
        showWhen: [
          {
            key: 'isNFE',
            value: false,
          },
          {
            key: 'isControllingPersonANaturalOrTrust',
            value: true,
          },
        ],
      },
      {
        fieldComponent: 'dropdown',
        fieldLabel: 'Country for tax purposes',
        placeholder: "Person's country",
        name: 'controllingPersonFields.country',
        options: COUNTRY_OPTIONS,
        isBoldOptionLabel: true,
        showWhen: [
          {
            key: 'isNFE',
            value: false,
          },
          {
            key: 'isControllingPersonANaturalOrTrust',
            value: true,
          },
        ],
      },
      {
        fieldComponent: 'yesNoQuestion',
        fieldLabel: 'Other country TIN or equivalent',
        name: 'isTINAvailable',
        required: true,
        showWhen: [
          { key: 'isNFE', value: false },
          {
            key: 'isControllingPersonANaturalOrTrust',
            value: true,
          },
        ],
      },
      {
        fieldComponent: 'input',
        fieldLabel: 'TIN (Taxpayer Identification Number*)',
        name: 'otherCountryTIN',
        showWhen: [
          {
            key: 'isTINAvailable',
            value: true,
          },
          {
            key: 'isControllingPersonANaturalOrTrust',
            value: true,
          },
        ],
      },
      {
        fieldComponent: 'multipleChoiceQuestion',
        fieldLabel: 'Reason not providing your TIN',
        name: 'noTINReason',
        showWhen: [
          {
            key: 'isNFE',
            value: (isNFE, v) => isNFE === false && v.isTINAvailable === false,
          },
          {
            key: 'isControllingPersonANaturalOrTrust',
            value: true,
          },
        ],
        fastField: false,
        options: [
          {
            label:
              'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
            key: 'answer-no-1',
            value: '1',
            displayValue:
              'The country / jurisdiction where the entity is resident does not issue TINs to its residents.',
          },
          {
            label:
              'The entity is otherwise unable to obtain a TIN or equivalent number.',
            value: '2',
            key: 'answer-no-2',
            children: (
              <Input
                fullWidth
                name="unableObtainTinReason"
                onChange={InvestorTaxForm?.handleChange}
                value={InvestorTaxForm?.values?.unableObtainTinReason}
                placeholder="Please state why"
                onBlur={InvestorTaxForm.handleBlur}
                errorValue={InvestorTaxForm.errors.unableObtainTinReason}
              />
            ),
            showChildWhen: [
              { key: 'noTINReason', value: '2' },
              {
                key: 'isControllingPersonANaturalOrTrust',
                value: true,
              },
            ],
            displayValue: values?.supportingInformation?.unableObtainTinReason,
          },
          {
            label: 'No TIN is required',
            value: '3',
            key: 'answer-no-3',
            displayValue: 'No TIN is required',
            children: (
              <Typography variant="caption">
                Note: Only select this reason if the domestic law of the
                relevant jurisdiction does not require the collection of the TIN
                issued by such jurisdiction.
              </Typography>
            ),
          },
        ],
      },
    ],
    [InvestorTaxForm, values, COUNTRY_OPTIONS],
  );

  const TaxSupportSummaryFields = useMemo(
    () => [
      // Question 1-3
      ...SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION,
      ...HasNoGIINSummaryFields,
      // Question 4
      ...SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_QUESTION_4,
      // Question 5
      {
        fieldComponent: 'yesNoQuestion',
        fieldLabel: 'Are you an active Non-Financial Entity (NFE)?',
        name: 'isNFE',
        showWhen: [
          {
            key: 'isManagedByAnotherInstitution',
            value: (isManagedByAnotherInstitution) =>
              isManagedByAnotherInstitution !== undefined,
          },
        ],
        required: true,
      },
      ...IsNFESummaryFields,
      // Question 6
      ...Question6SummaryFields,
    ],
    [
      SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION,
      SUMMARY_FIELDS_COMPANY_TAX_SUPPORTING_INFORMATION_QUESTION_4,
      IsNFESummaryFields,
      HasNoGIINSummaryFields,
      Question6SummaryFields,
    ],
  );
  const handleIsEditChanged = useCallback((newIsEdit) => {
    setIsEdit(newIsEdit);
  }, []);

  const handleEdit = useCallback(() => {
    summarySectionRef?.current?.handleClickEdit();
  }, [summarySectionRef?.current]);

  const handleSave = useCallback(() => {
    summarySectionRef?.current?.handleClickSave({
      setIsEditParent: setIsEdit,
    });
  }, [summarySectionRef?.current, setIsEdit]);

  const handleCancel = useCallback(() => {
    setIsEdit(false);
    summarySectionRef?.current?.handleClickDiscard({
      setIsEditParent: setIsEdit,
    });
  }, [summarySectionRef?.current, setIsEdit]);

  const handleSetValues = useCallback(
    (formValues) => {
      setValues({
        ...values,
        supportingInformation: formValues,
        index: 'supportingInformation', // used in admin edit
      });
    },
    [setValues, values],
  );

  return (
    <InvestorCard
      investorName="Supporting Information"
      showFooterWhenExpand
      footer={
        withEdit && (
          <TaxDetailsFooter
            isEditing={isEdit}
            isMainTax
            onEdit={handleEdit}
            onSave={handleSave}
            onCancel={handleCancel}
            isError={isError}
          />
        )
      }
      accordion
      showChip={false}
    >
      <SummarySection
        withEdit={withEdit}
        title={null}
        fields={TaxSupportSummaryFields}
        setValues={handleSetValues}
        values={values.supportingInformation}
        validationSchema={companyTaxSupportingInformationValidationSchema}
        ref={summarySectionRef}
        setIsError={setIsError}
        onIsEditChanged={handleIsEditChanged}
      />
    </InvestorCard>
  );
};

export default memo(TaxSupportingInformationSummary);
