import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';

import 'suneditor/dist/css/suneditor.min.css';

const defaultOptions = {
  resizingBar: false,
  buttonList: [
    ['font', 'fontSize'],
    ['formatBlock'],
    ['bold', 'underline', 'italic'],
    ['strike', 'blockquote'],
    ['fontColor', 'hiliteColor'],
    ['align', 'list', 'table'],
    ['link', 'image', 'video'],
    ['removeFormat'],
    ['showBlocks'],
  ],
  height: 'auto',
  maxHeight: '600px',
  defaultStyle: 'font-family: PT Serif;',
  font: ['Inter', 'PT Serif', 'Serif', 'sans-serif'],
};

const videoFigureIframeString = ({ height = 'auto' } = {}) =>
  `<div class="se-component se-video-container __se__float-none" contenteditable="false"><figure style="width:100%; height:${height};"><iframe `;

const TextEditor = ({
  onChange = () => true,
  name,
  value,
  options = {},
  backgroundColor = 'white',
  height = 'inherit',
  setDefaultStyle = '',
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const onChangeRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    // Need to manually update onChange function to ref.
    // Because onChange inside `SunEditor` is using some kind of dependency,
    // so it will keep using the old value.
    onChangeRef.current = onChange;
  }, [onChange]);

  const onChangeTextEditor = (val) => {
    const newValue = val.replaceAll(
      new RegExp(videoFigureIframeString(), 'g'),
      videoFigureIframeString({ height: '400px' }),
    );

    const payload = {
      target: {
        name,
        value: newValue,
      },
    };

    onChangeRef.current(payload);
  };

  const editorOptions = useMemo(() => {
    const setOptions = { ...defaultOptions, ...options };

    if (loading && props.hideToolbar) setOptions.buttonList = [];

    return setOptions;
  }, [options, loading, props.hideToolbar]);

  return (
    <div>
      <SunEditor
        {...props}
        height="auto"
        setOptions={editorOptions}
        name={name}
        defaultValue={value}
        onChange={onChangeTextEditor}
        setDefaultStyle={`background-color: ${backgroundColor}; ${setDefaultStyle}`}
      />
    </div>
  );
};

export default memo(TextEditor);
