import * as yup from 'yup';
import { PRESERVED_COUNTRIES } from 'constants/phoneNumber';
import trimMaskInput from 'utils/trimMaskInput';

const companyDetailsValidationSchema = yup.object({
  companyName: yup
    .string()
    .trim()
    .required()
    .min(2)
    .max(50)
    .label('Company Name'),
  country: yup.string().required().label('Country'),
  australianTaxNumber: yup
    .string()
    .when('country', {
      is: 'AU',
      then: yup.string().transform(trimMaskInput).min(5).max(18).required(),
    })
    .label('Australian Tax Number'),
  newZealandTaxNumber: yup
    .string()
    .when('country', {
      is: 'NZ',
      then: yup.string().transform(trimMaskInput).min(5).max(13).required(),
    })
    .label('New Zealand Tax Number'),
  otherTaxNumber: yup
    .string()
    .when('country', {
      is: (country) => !PRESERVED_COUNTRIES.includes(country),
      then: yup.string().transform(trimMaskInput).min(5).max(18).required(),
    })
    .label('Tax Number'),
  isPublicCompany: yup.boolean().required().label('This field'),
  isNonProfitable: yup.boolean().required().label('This field'),
});

export default companyDetailsValidationSchema;
