import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Button from 'components/Button';
import ShowMe from 'components/ShowMe';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';

import palette from 'theme/palette';

import TotalChip from './TotalChip';

const TotalHeaders = ({
  testId,
  exportIcon = ExportIcon,
  exportLabel = 'Export',
  onClickExport,
  disableExportBtn,
  addItemIcon = AddCircleIcon,
  addItemLabel = 'Create Entity',
  onClickAddItem,
  disabledAddItemBtn,
  children,
  totals = [],
  totalFullWidth,
  withDivider = true,
  background = '#F7F7F7',
  spaceEvenly,
  columnSpacing = {},
}) => (
  <Grid
    data-testid={`total-headers-${testId}`}
    item
    container
    xs={12}
    display="flex"
    justifyContent="space-between"
    sx={{
      background,
      borderBottom: '1px solid #F6F6F6',
      borderTop: '1px solid #F6F6F6',
      borderRadius: 0,
      padding: '10px',
    }}
    spacing={1}
  >
    <Grid container item columnSpacing={1}>
      <Grid item xs>
        <ShowMe when={children}>{children}</ShowMe>
      </Grid>
      <ShowMe when={exportLabel || onClickAddItem}>
        <Grid container item xs="auto" spacing={1}>
          {(onClickExport || exportLabel) && (
            <Grid item>
              <Button
                buttoncolor="purpleHazeGradient"
                onClick={onClickExport}
                maxheight={5}
                suffixIcon={exportIcon}
                disabled={disableExportBtn}
              >
                {exportLabel}
              </Button>
            </Grid>
          )}
          {onClickAddItem && (
            <Grid item>
              <Button
                buttoncolor="purpleHazeGradient"
                onClick={onClickAddItem}
                maxheight={5}
                prefixIcon={addItemIcon}
                disabled={disabledAddItemBtn}
              >
                {addItemLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </ShowMe>
    </Grid>
    <Grid item xs={totalFullWidth ? 12 : 'auto'}>
      <Grid
        container
        item
        // xs={12}
        display="flex"
        alignItems="center"
        sx={{ height: '100%' }}
        direction="row"
        columnSpacing={{ xs: 1, ...columnSpacing }}
        rowSpacing={{ xs: 1, sm: 2 }}
      >
        {totals?.map(({ key, totalLabel, totalValue }, index) => (
          <Grid
            key={totalLabel}
            data-testid={`total-headers-item-${key}`}
            container
            item
            xs="auto"
            lg={spaceEvenly}
            direction="row"
          >
            <Grid container item xs alignItems="center">
              <Typography variant="dataRoomLabel" color={palette.text.grey}>
                {totalLabel}
              </Typography>
              {Array.isArray(totalValue) ? (
                <Grid container item direction="column" xs="auto">
                  {totalValue.map((value) => (
                    <Grid item>
                      {' '}
                      <TotalChip label={value} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <TotalChip label={totalValue} />
              )}
            </Grid>
            {withDivider && index + 1 !== totals?.length ? (
              <Grid item display="flex" alignItems="center">
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ margin: '0 10px', height: '25px' }}
                />
              </Grid>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

export default memo(TotalHeaders);
