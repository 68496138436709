import Login from './Login';
import OTP from './OTP';
import UpdatePhoneNumber from './UpdatePhoneNumber';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import AddEditUser from './AddEditUser';
import Export from './Export';
import Investment from './Investment';

const MODULES = {
  LOGIN: 'LOGIN',
  OTP: 'OTP',
  UPDATE_PHONE_NUMBER: 'UPDATE_PHONE_NUMBER',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  ADD_EDIT_USER: 'ADD_EDIT_USER',
  Export: 'Export',
};

const ERROR_CODES = {
  LOGIN: Login.ERROR_CODES,
  OTP: OTP.ERROR_CODES,
  UPDATE_PHONE_NUMBER: UpdatePhoneNumber.ERROR_CODES,
  FORGOT_PASSWORD: ForgotPassword.ERROR_CODES,
  CHANGE_PASSWORD: ChangePassword.ERROR_CODES,
  ADD_EDIT_USER: AddEditUser.ERROR_CODES,
  Export: Export.ERROR_CODES,
  INVESTMENT: Investment.ERROR_CODES,
};

const ERROR_MESSAGES = {
  ...Login.ERROR_MSG,
  ...OTP.ERROR_MSG,
  ...UpdatePhoneNumber.ERROR_MSG,
  ...ForgotPassword.ERROR_MSG,
  ...ChangePassword.ERROR_MSG,
  ...AddEditUser.ERROR_MSG,
  ...Export.ERROR_MSG,
  ...Investment.ERROR_MSG,
};

export { MODULES, ERROR_CODES, ERROR_MESSAGES };
