import { styled } from '@mui/material/styles';

const GradientWrapper = styled('div')(({ height }) => ({
  maxHeight: height || '168px',
  height: 'inherit',
  width: '100%',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, #FFFFFF 100%);',
    zIndex: 1,
  },
}));

export default GradientWrapper;
