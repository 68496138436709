import * as yup from 'yup';

import individualFormValidationSchema from './individualFormValidationSchema';

const overseasValidationSchema = yup.object({
  country: yup.string().required().label('Country'),
  passportNumber: yup.string().required().label('Passport Number'),
  passportExpiryDate: yup.string().required().label('Passport Expiry Number'),
  gender: yup.string().required().label('Gender'),
  ...individualFormValidationSchema(),
});

export default overseasValidationSchema;
