import React from 'react';

import Grid from '@mui/material/Grid';

import { DashboardLoadingProvider } from 'hooks/useDashboardLoading';

import NewInvestmentDashboard from './components/NewInvestmentDashboard';

const DashboardPage = () => (
  <Grid data-testid="dashboard-page" container direction="row" spacing={6}>
    <DashboardLoadingProvider>
      <NewInvestmentDashboard />
    </DashboardLoadingProvider>
  </Grid>
);

export default DashboardPage;
