const PRESERVED_COUNTRIES = [
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
  },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { type: 'divider', code: 'divider', label: 'divider', phone: '-' },
];

export default PRESERVED_COUNTRIES;
