import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const CircularStepper = ({ steps, activeStep, label, circularSize = 60 }) => {
  const stepPercentage = useMemo(
    () => (activeStep ? (activeStep / steps.length) * 100 : 0),
    [activeStep, steps],
  );

  return (
    <Grid display="flex" alignItems="center" justifyContent="center">
      <Box sx={{ position: 'relative', display: 'inline-flex', mr: 2 }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) => theme.palette.border.light,
          }}
          size={circularSize}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          size={circularSize}
          value={stepPercentage}
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="chipLabel"
            component="div"
            sx={{ color: 'black !important', fontWeight: '700 !important' }}
          >
            {`${activeStep + 1} of ${steps.length}`}
          </Typography>
        </Box>
      </Box>
      <Grid>
        <Typography sx={{ fontWeight: 500, color: '#A5A5A5', fontSize: '9px' }}>
          Step {activeStep + 1}
        </Typography>
        <Typography sx={{ fontWeight: 700, fontSize: '12px' }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CircularStepper;
