import React from 'react';

import DashboardInvestmentEntitiesImg from 'assets/images/dashboard-investment-entities.png';

import DashboardImageTitle from '../DashboardImageTitle';
import RedemptionSteps from './RedemptionSteps';

const RedemptionRequestPage = () => (
  <>
    <DashboardImageTitle
      title="Redemption Request"
      imgSrc={DashboardInvestmentEntitiesImg}
    />

    <RedemptionSteps />
  </>
);

export default RedemptionRequestPage;
