import {
  countryCellRenderer,
  dateCellRenderer,
  statusChipCellRenderer,
} from 'utils/Datatables/cellRenderer';

// eslint-disable-next-line
export const investmentEntityHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    cellClassName: 'cellBold',
  },
  {
    field: 'name',
    headerName: 'Entity Name',
    flex: 1,
    cellClassName: 'cellBold',
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 0.75,
    cellClassName: 'cellBold',
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 0.5,
    renderCell: countryCellRenderer,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.5,
    renderCell: statusChipCellRenderer,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    flex: 0.5,
    renderCell: dateCellRenderer,
  },
];

export const getInvestmentEntityTotalHeader = (totalValue) => [
  { totalLabel: 'Total Entities', totalValue },
];
