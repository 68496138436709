import React, { memo } from 'react';

import Input from 'components/Input';
import isNumber from 'utils/isNumber';
import typography from 'theme/typography';

const InputBox = ({ id, value, onChange, readOnly, ...props }) => {
  const handleChange = (e) => {
    const newValue = e.target.value;

    if (newValue && !isNumber(newValue)) return;

    onChange(e, id);
  };

  return (
    <Input
      {...props}
      id={id}
      name={id}
      onChange={handleChange}
      inputProps={{
        readOnly,
        tabIndex: id,
        maxLength: 1,
        style: {
          padding: 0,
          textAlign: 'center',
          ...typography.display3,
        },
      }}
      withEndAdornment={false}
      value={value}
      size="small"
      minheight="54px"
    />
  );
};

export default memo(InputBox);
