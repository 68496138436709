import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import FieldContainer from 'components/FieldContainer';
import condTrueFalse from 'utils/condTrueFalse';

import FormFields from './FormFields';
import { citizenships, validationSchemas } from './helperFunctions';

const inactiveButtonStyle = {
  buttoncolor: 'grey',
  textColor: 'black',
};

const Form = ({ formId, onSubmit, setIsError }) => {
  const [selectedCitizenship, setSelectedCitizenship] = useState(
    citizenships[0],
  );
  const [idType, setIdType] = useState('');

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      ...selectedCitizenship.initialValues,
      citizenship: selectedCitizenship.key,
    },
    onSubmit,
    validationSchema: validationSchemas[idType],
  });

  useEffect(
    () => setIsError(!idType || !!Object.keys(errors).length),
    [errors, idType],
  );

  const handleSelectCitizenship = useCallback((citizenship) => {
    setSelectedCitizenship(citizenship);

    let newIdType = '';
    if (citizenship?.key === 'overseas') newIdType = citizenship?.key;

    setIdType(newIdType);

    resetForm({
      values: { ...citizenship.initialValues, citizenship: citizenship.key },
    });

    // Manually Set Initial Error on Id Type change.
    setFieldError('idType', true);
  }, []);

  const handleChangeIdType = useCallback(
    (e) => {
      const newIdType = e.target.value;

      setIdType(newIdType);
      resetForm({
        values: {
          idType: newIdType,
          citizenship: selectedCitizenship.key,
          ...selectedCitizenship.initialValues[newIdType],
        },
      });

      // Manually Set Initial Error on Id Type change.
      setFieldError('idType', true);
    },
    [selectedCitizenship],
  );

  return (
    <form
      data-testid="complete-profile-form"
      id={formId}
      onSubmit={handleSubmit}
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="body">
            To have full access, complete your profile by verifying your
            identity.
            <br />
            <br />
            By submitting, you confirm that you are authorized to provide the
            information and you agree to a check against official government
            records. You may withdraw your consent at any time by contacting the
            administrator.
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          columnSpacing={2}
          rowSpacing={{ xs: 2, sm: 0 }}
        >
          {citizenships.map((citizenship) => (
            <Grid
              key={citizenship.key}
              item
              xs={12}
              md={12 / citizenships.length}
            >
              <Button
                data-testid={`complete-profile-citizenship-btn-${citizenship.key}`}
                variant="outlined"
                onClick={() => handleSelectCitizenship(citizenship)}
                {...(citizenship.key === selectedCitizenship.key
                  ? { textColor: 'white' }
                  : inactiveButtonStyle)}
                sx={{
                  ':hover': { bgcolor: '#5c5b93' },
                }}
              >
                {citizenship.label}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Grid container item spacing={2}>
          {selectedCitizenship.key !== 'overseas' && (
            <>
              <FieldContainer item xs={12} sm={6} label="Choose your ID type*">
                <Dropdown
                  fullWidth
                  data-testid={`complete-profile-idType-dropdown-${selectedCitizenship.key}`}
                  name="idType"
                  value={idType}
                  onChange={handleChangeIdType}
                  options={selectedCitizenship.idTypeOptions}
                />
              </FieldContainer>
              <Grid item xs={6} />
            </>
          )}
          {selectedCitizenship.fields?.[idType]?.map(
            ({ fieldProps, ...formField }) => (
              <FormFields
                key={formField.name}
                {...formField}
                fieldProps={{
                  ...condTrueFalse(fieldProps, () => fieldProps(values), {}),
                  'data-testid': `${idType}FormField-${formField.name}`,
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
              />
            ),
          )}
          {idType && selectedCitizenship.addressFields && (
            <>
              <Grid container item xs={12} columnSpacing={1} direction="row">
                <Grid item xs="auto">
                  <Typography variant="h4">Address</Typography>
                </Grid>

                <Grid item xs alignSelf="center">
                  <Divider flexItem />
                </Grid>
              </Grid>

              {selectedCitizenship.addressFields.map((formField) => (
                <FormFields
                  key={formField.name}
                  fieldProps={{
                    'data-testid': `addressFormField-${formField.name}`,
                  }}
                  {...formField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  errors={errors}
                />
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
