import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ChipList from 'components/ChipList';

const AutoPopulateChipList = ({
  title = 'Auto populate details',
  onClick,
  selectedIndex,
  items,
}) => (
  <>
    <Grid item textAlign="left" mb={2}>
      <Typography variant="">{title}</Typography>
    </Grid>
    <Grid item textAlign="left" mb={2}>
      <ChipList onClick={onClick} selectedIndex={selectedIndex} items={items} />
    </Grid>
  </>
);

export default AutoPopulateChipList;
