import React, { memo } from 'react';
import Accordion from 'components/Accordion';
import BankDetailsSummary from 'containers/AddInvestmentEntity/components/Forms/BankDetailsSummary';

const BankDetail = ({ values, label = 'Bank Details', ...props }) => (
  <Accordion label={label} labelVariant="cardNameLabel">
    <BankDetailsSummary values={values} sectionListView {...props} />
  </Accordion>
);

export default memo(BankDetail);
