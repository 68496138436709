import React from 'react';
import { Navigate } from 'react-router-dom';

import RegistrationPage from 'containers/RegistrationPage';
import EmailVerificationPage from 'containers/EmailVerificationPage';
import LoginPage from 'containers/LoginPage';
import LogoutPage from 'containers/LogoutPage';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';
import DashboardPage from 'containers/DashboardPage';
import UpdatePhoneNumberPage from 'containers/UpdatePhoneNumberPage';
import ProfileDetailPage from 'containers/ProfileDetailPage';
import ChangePasswordPage from 'containers/ChangePasswordPage';
import ChangePasswordEmailSent from 'containers/ChangePasswordPage/EmailSent';
import NewPasswordPage from 'containers/NewPasswordPage';
import AddInvestmentEntity from 'containers/AddInvestmentEntity';
import SelectInvestorType from 'containers/AddInvestmentEntity/SelectInvestorType';
import AddIndividualInvestmentEntity from 'containers/AddInvestmentEntity/Individual';
import AddCompanyInvestmentEntity from 'containers/AddInvestmentEntity/Company';
import AddTrustInvestmentEntity from 'containers/AddInvestmentEntity/Trust';
import ViewDocumentPage from 'containers/ViewDocumentPage';
import DownloadDocumentPage from 'containers/DownloadDocumentPage';
import InvestmentApplicationPage from 'containers/InvestmentApplicationPage';
import ArticleDetail from 'containers/DashboardPage/components/ArticleDetail';
import RedemptionRequestPage from 'containers/DashboardPage/components/RedemptionRequestPage';

import AddEditUserPage from 'containers/Admin/Users/AddEditUserPage';
import AdminOverview from 'containers/Admin/Overview';
import AdminUsers from 'containers/Admin/Users';
import Approvals from 'containers/Admin/Approvals';
import ApprovalDetails from 'containers/Admin/Approvals/Details';
import Funds from 'containers/Admin/Funds';
import FundApprovalDetails from 'containers/Admin/Approvals/FundDetails';
import FundRedemptionRequestDetails from 'containers/Admin/Approvals/FundRedemptionRequestDetails';
import FundDetails from 'containers/Admin/Funds/Details';
import AddInvestmentFund from 'containers/Admin/Users/AddInvestmentFund';
import AddFundRedemptionRequest from 'containers/Admin/Users/AddFundRedemptionRequest';
import Reporting from 'containers/Admin/Reporting';
import ReportingDetailsOverview from 'containers/Admin/Reporting/Details';

import UserDetails from 'containers/Admin/Users/UserDetails';
import UserPreview from 'containers/Admin/Users/UserPreview';
import PlatformConfiguration from 'containers/Admin/PlatformConfiguration';
import EmailTemplateDetails from 'containers/Admin/PlatformConfiguration/EmailTemplates/EmailTemplateDetails';
import AdminArticlesEvents from 'containers/Admin/ArticlesEvents';
import ArticleDetails from 'containers/Admin/ArticlesEvents/ArticleDetails';
import EventDetails from 'containers/Admin/ArticlesEvents/EventDetails';
import VideoDetails from 'containers/Admin/ArticlesEvents/VideoDetails';

import ProtectedRoute from './ProtectedRoute';

const RoutesList = [
  {
    id: 'login',
    path: '/login',
    element: <LoginPage />,
    exact: true,
  },
  {
    id: 'logout',
    path: '/logout',
    element: (
      <ProtectedRoute publicRoute>
        <LogoutPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'home',
    path: '/',
    element: (
      <ProtectedRoute>
        <Navigate to="/dashboard" />
        {/* <DashboardPage /> */}
      </ProtectedRoute>
    ),
    // exact: true,
  },
  {
    id: 'dashboard',
    path: '/dashboard/*',
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
    exact: false,
  },
  {
    id: 'article-detail',
    path: '/articles/:articleId',
    element: (
      <ProtectedRoute>
        <ArticleDetail />
      </ProtectedRoute>
    ),
    exact: false,
  },
  {
    id: 'redemption-request',
    path: '/redemption-request',
    element: (
      <ProtectedRoute>
        <RedemptionRequestPage />
      </ProtectedRoute>
    ),
    exact: false,
  },
  {
    id: 'profile',
    path: '/profile',
    element: (
      <ProtectedRoute>
        <ProfileDetailPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'Register',
    path: '/register',
    element: <RegistrationPage />,
    exact: true,
  },
  {
    id: 'verify',
    path: '/verify',
    element: <EmailVerificationPage />,
    exact: true,
  },
  {
    id: 'forgot_password',
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
    exact: true,
  },
  {
    id: 'Password-recovery',
    path: '/password-recovery',
    element: <NewPasswordPage />,
    exact: true,
  },
  {
    id: 'updatePhoneNumber',
    path: '/profile/change-phone-number',
    element: (
      <ProtectedRoute hideDrawer>
        <UpdatePhoneNumberPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'change-password',
    path: '/profile/change-password',
    element: (
      <ProtectedRoute hideDrawer>
        <ChangePasswordPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'update-password-email-sent',
    path: '/update-password',
    element: <ChangePasswordEmailSent />,
    exact: true,
  },
  {
    id: 'add-investment-entity',
    path: 'add-investment-entity',
    element: (
      <ProtectedRoute>
        <AddInvestmentEntity />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'add-investment-entity/select-type',
    path: 'add-investment-entity/select-type',
    element: (
      <ProtectedRoute containerSx={{}}>
        <SelectInvestorType />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'add-investment-entity/individual',
    path: 'add-investment-entity/individual',
    element: (
      <ProtectedRoute containerSx={{}}>
        <AddIndividualInvestmentEntity />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'add-investment-entity/company',
    path: 'add-investment-entity/company',
    element: (
      <ProtectedRoute containerSx={{}}>
        <AddCompanyInvestmentEntity />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'add-investment-entity/trust',
    path: 'add-investment-entity/trust',
    element: (
      <ProtectedRoute containerSx={{}}>
        <AddTrustInvestmentEntity />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'view-document',
    path: 'document/view',
    element: (
      <ProtectedRoute
        containerSx={{
          height: '100%',
          width: '100%',
          padding: '0 !important',
          margin: '0!important',
          maxWidth: 'none!important',
        }}
      >
        <ViewDocumentPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'download-document',
    path: 'document/download',
    element: (
      <ProtectedRoute>
        <DownloadDocumentPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'invest',
    path: 'invest/:fundId',
    element: (
      <ProtectedRoute>
        <InvestmentApplicationPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'admin',
    path: 'admin',
    element: (
      <ProtectedRoute admin>
        <AdminOverview />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/funds',
    path: 'admin/funds',
    element: (
      <ProtectedRoute admin>
        <Funds />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/funds/add',
    path: 'admin/funds/add',
    element: (
      <ProtectedRoute admin>
        <FundDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/funds/detail',
    path: 'admin/funds/:idFund',
    element: (
      <ProtectedRoute admin>
        <FundDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/data-rooms',
    path: 'admin/data-rooms',
    element: (
      <ProtectedRoute admin>
        <AdminOverview />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/articles-events',
    path: 'admin/articles-events',
    element: (
      <ProtectedRoute admin>
        <AdminArticlesEvents />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/articles/add',
    path: 'admin/articles/add',
    element: (
      <ProtectedRoute admin>
        <ArticleDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/articles/detail',
    path: 'admin/articles/:articleId',
    element: (
      <ProtectedRoute admin>
        <ArticleDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/events/add',
    path: 'admin/events/add',
    element: (
      <ProtectedRoute admin>
        <EventDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/events/detail',
    path: 'admin/events/:eventId',
    element: (
      <ProtectedRoute admin>
        <EventDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/reporting',
    path: 'admin/reporting',
    element: (
      <ProtectedRoute admin>
        <Reporting />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/reporting/detail',
    path: 'admin/reporting/:fundId',
    element: (
      <ProtectedRoute admin>
        <ReportingDetailsOverview />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/videos/add',
    path: 'admin/videos/add',
    element: (
      <ProtectedRoute admin>
        <VideoDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/videos/detail',
    path: 'admin/videos/:videoId',
    element: (
      <ProtectedRoute admin>
        <VideoDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/users',
    path: 'admin/users',
    element: (
      <ProtectedRoute admin>
        <AdminUsers />
      </ProtectedRoute>
    ),
  },
  {
    id: 'addUser',
    path: 'admin/users/add',
    element: (
      <ProtectedRoute admin>
        <AddEditUserPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'editUser',
    path: 'admin/users/edit/:userId',
    element: (
      <ProtectedRoute admin>
        <UserDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'viewUser',
    path: 'admin/users/view/:userId',
    element: (
      <ProtectedRoute admin>
        <AddEditUserPage />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'addUserInvestmentFund',
    path: 'admin/users/:userId/add-investment-fund',
    element: (
      <ProtectedRoute admin>
        <AddInvestmentFund />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'addUserFundRedemptionRequest',
    path: 'admin/users/:userId/add-fund-redemption-request',
    element: (
      <ProtectedRoute admin>
        <AddFundRedemptionRequest />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'previewUser',
    path: 'admin/users/:userId/preview',
    element: (
      <ProtectedRoute admin>
        <UserPreview />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    id: 'admin/approvals',
    path: 'admin/approvals',
    element: (
      <ProtectedRoute admin>
        <Approvals />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/approvals/details',
    path: 'admin/approvals/details/:entityId',
    element: (
      <ProtectedRoute admin>
        <ApprovalDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/approvals/fund-details',
    path: 'admin/approvals/fund-details/:fundsId',
    element: (
      <ProtectedRoute admin>
        <FundApprovalDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/approvals/fund-redemption-request-details',
    path: 'admin/approvals/fund-redemption-request-details/:fundRedemptionRequestId',
    element: (
      <ProtectedRoute admin>
        <FundRedemptionRequestDetails />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/platform-configuration',
    path: 'admin/platform-configuration',
    element: (
      <ProtectedRoute admin>
        <PlatformConfiguration />
      </ProtectedRoute>
    ),
  },
  {
    id: 'admin/platform-configuration/email-template',
    path: 'admin/platform-configuration/email-template/:id',
    element: (
      <ProtectedRoute admin>
        <EmailTemplateDetails />
      </ProtectedRoute>
    ),
  },
];

export default RoutesList;
