import React, { memo, useMemo } from 'react';

import FieldContainer from 'components/FieldContainer';
import { fieldComponents } from 'components/SummarySection/SectionData';

const FormFields = ({
  fieldType,
  name,
  label,
  options,
  onBlur,
  onChange,
  values,
  touched,
  errors,
  gridProps = {},

  // datepicker props.
  setFieldValue,
  maxDate,
  fieldProps = {},
}) => {
  const FieldComponent = useMemo(
    () => fieldComponents[fieldType] || (() => <div />),
    [fieldType],
  );

  const otherProps = useMemo(() => {
    if (fieldType !== 'datePicker') return {};

    return {
      setFieldValue,
      maxDate,
      errorValue: values?.[name] && touched?.[name] && errors?.[name],
    };
  }, [fieldType, values?.[name], touched?.[name], errors?.[name]]);

  return (
    <FieldContainer item label={label} {...gridProps}>
      <FieldComponent
        fullWidth
        name={name}
        onChange={onChange}
        value={values?.[name]}
        errorValue={touched?.[name] && errors?.[name]}
        onBlur={onBlur}
        options={options}
        {...otherProps}
        {...fieldProps}
      />
    </FieldContainer>
  );
};

export default memo(FormFields);
