import condTrueFalse from 'utils/condTrueFalse';

export const noTINReasonMapping = (value, reason) => {
  switch (value) {
    case '1':
      return 'The country / jurisdiction where the entity is resident does not issue TINs to its residents.';
    case '3':
      return 'No TIN is required.';
    default:
      return reason;
  }
};

const revertMappingTinReason = (reason = '') => {
  switch (true) {
    case reason ===
      'The country / jurisdiction where the entity is resident does not issue TINs to its residents.':
      return '1';
    case reason === 'No TIN is required.':
      return '3';
    case !!reason?.length:
      return '2';
    default:
      return undefined;
  }
};

export const mapPayloadToTaxDetails = (taxDetailsValue) => ({
  hasAustraliaTax: condTrueFalse(
    taxDetailsValue?.isAustralianTax !== undefined,
    !!taxDetailsValue?.isAustralianTax,
    undefined,
  ),
  australiaTax: taxDetailsValue?.taxFileNumber,
  hasOtherCountryTax: condTrueFalse(
    taxDetailsValue?.isOtherCountryResident !== undefined,
    !!taxDetailsValue?.isOtherCountryResident,
    undefined,
  ),
  otherCountryTax: taxDetailsValue?.otherCountryName || '',
  otherCountryTIN: taxDetailsValue?.otherCountryTin,
  isTINAvailable: condTrueFalse(
    taxDetailsValue?.isTINAvailable !== undefined,
    !!taxDetailsValue?.isTINAvailable,
    undefined,
  ),
  noTINReason: revertMappingTinReason(taxDetailsValue?.reasonDesc),
  unableObtainTinReason:
    revertMappingTinReason(taxDetailsValue?.reasonDesc) === '2'
      ? taxDetailsValue?.reasonDesc
      : '',
  signedBy: taxDetailsValue?.signedByName,
  signedAt: taxDetailsValue?.signedAt,
});
