import * as yup from 'yup';
import profileDetail from 'validations/profileDetail';
import dateValidationSchema from '../../dateValidationSchema';

const personalDetailsValidationSchema = yup.object({
  title: yup.string().required().label('Title'),
  ...profileDetail,
  ...dateValidationSchema(),
  country: yup.string().required().label('Country of Citizenship'),
  phoneNumber: yup.number().required().label('Mobile Number'),
  countryCode: yup.object().required().label('Country Code'),
  email: yup.string().email().required().label('Email'),
});

export default personalDetailsValidationSchema;
