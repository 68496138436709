import { useCallback, useMemo } from 'react';

const useFormGridSize = ({ fieldDirection }) => {
  const getCustomInlineGridProps = useCallback(
    ({ itemsPerRow = 1 } = {}) => {
      if (fieldDirection === 'column')
        return {
          labelGridProps: { xs: 12 },
          inputGridProps: { xs: 12, md: 6 * itemsPerRow },
        };

      return {
        labelGridProps: { xs: 2, sm: 3, alignSelf: 'center' },
        inputGridProps: { xs: 9, sm: 6 },
      };
    },
    [fieldDirection],
  );

  const { labelGridProps, inputGridProps } = useMemo(
    () => getCustomInlineGridProps(),
    [getCustomInlineGridProps],
  );

  const { inlineGridContainerProps } = useMemo(() => {
    if (fieldDirection === 'column')
      return {
        inlineGridContainerProps: { xs: 12, md: 6, columnSpacing: 2 },
        flexDirection: 'row',
      };

    return {
      inlineGridContainerProps: { xs: 12, rowSpacing: 2 },
      flexDirection: 'column',
    };
  }, [fieldDirection]);

  return {
    labelGridProps,
    inputGridProps,
    getCustomInlineGridProps,
    inlineGridContainerProps,
  };
};

export default useFormGridSize;
