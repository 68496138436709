import React, { memo, useMemo } from 'react';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const BarChart = ({ title, options = {}, ...props }) => (
  <Chart
    type="bar"
    options={{
      plugins: { legend: { position: 'bottom' } },
      ...options,
    }}
    {...props}
  />
);

export default memo(BarChart);
