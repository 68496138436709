import React, { memo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import ShowMe from 'components/ShowMe';

const FormTitle = ({ title, onDelete }) => (
  <ShowMe when={title}>
    <Grid container item rowSpacing={1} mb={2}>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography
          variant="h4"
          data-testid="form-title"
          display="inline-block"
        >
          {title}
        </Typography>
        <ShowMe when={!!onDelete}>
          <DeleteIcon
            sx={{ cursor: 'pointer', color: 'red' }}
            onClick={onDelete}
          />
        </ShowMe>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  </ShowMe>
);

export default memo(FormTitle);
