import * as yup from 'yup';

const investorSummaryValidationSchema = yup.object({
  agreement: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),
});

export default investorSummaryValidationSchema;
