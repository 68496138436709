const getInputFontStyles = ({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '16px',
  },
});

const getInputStyles = ({ theme, minheight }) => ({
  minHeight: minheight || '54px',
  [theme.breakpoints.up('lg')]: {
    minHeight: minheight || '64px',
  },
  ...getInputFontStyles({ theme }),
});

export { getInputFontStyles, getInputStyles };
