import React, { useCallback, useMemo, useState } from 'react';

import PayloadMappers from 'utils/PayloadMappers';

import TaxSummary from 'containers/AddInvestmentEntity/components/TaxDetailsWithSupportingInformation/Steps/TaxSummary';

import AddTaxDetailDialog from './AddTaxDetailDialog';

const mapValuesTaxSummary = ({ taxDetails, supportingInformation }) => ({
  signedAt: taxDetails[0]?.signedAt
    ? new Date(taxDetails[0]?.signedAt)
    : new Date(),
  signedBy: taxDetails[0]?.signedBy,
  taxPurposes: taxDetails.map((taxDetail, index) => ({
    ...taxDetail,
    investorName: taxDetail.label,
    index,
  })),
  supportingInformation,
});

const CompanyTaxDetail = ({
  onChange,
  entityUpdateKey = 'CompanyTax',
  ...props
}) => {
  const [openTaxDetailDialog, setOpenTaxDetailDialog] = useState(false);

  const taxDetailValue = useMemo(
    () => mapValuesTaxSummary(props),
    [props?.taxDetails, props?.supportingInformation],
  );

  const handleCompanyTaxDetailChange = (newValue) => {
    if (newValue.index === 'supportingInformation') {
      onChange({
        supportingInformation: newValue.supportingInformation,
      });
    }
    if (newValue.index === 'delete') {
      // do nothing
    } else {
      onChange({
        taxDetail: {
          id: newValue.taxPurposes[newValue.index].id,
          ...PayloadMappers.taxDetails(newValue.taxPurposes[newValue.index]),
        },
      });
    }
  };

  const handleDeleteTaxDetail = useCallback((index) => {
    const deleteKey = `delete${entityUpdateKey}`;
    const values = props.taxDetails;

    onChange({
      [deleteKey]: {
        id: values[index].id,
      },
    });
  }, []);

  const handleOpenTaxDetailDialog = useCallback(() => {
    setOpenTaxDetailDialog(true);
  }, []);

  const handleCloseTaxDetailDialog = useCallback(() => {
    setOpenTaxDetailDialog(false);
  }, []);

  const hasAustraliaTax = useMemo(
    () =>
      (taxDetailValue.taxPurposes || []).findIndex(
        (taxPurpose) => taxPurpose.hasAustraliaTax,
      ) >= 0,
    [taxDetailValue.taxPurposes],
  );

  return (
    <>
      <TaxSummary
        setValues={handleCompanyTaxDetailChange}
        values={taxDetailValue}
        onAddTaxPurpose={
          props.taxDetails.length < 5 && handleOpenTaxDetailDialog
        }
        onDelete={handleDeleteTaxDetail}
        hasAustraliaTax={hasAustraliaTax}
        {...props}
      />

      <AddTaxDetailDialog
        existingValues={taxDetailValue}
        entityUpdateKey={entityUpdateKey}
        open={openTaxDetailDialog}
        onClose={handleCloseTaxDetailDialog}
        onSubmit={onChange}
        hasAustraliaTax={hasAustraliaTax}
      />
    </>
  );
};

export default CompanyTaxDetail;
