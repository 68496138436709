import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DataTable from 'components/DataTable';

import {
  dateCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const headers = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'totalCapitalCall',
    headerName: 'Amount Funded',
    flex: 1,
    renderCell: (props) => percentCellRenderer({ value: props.value }),
  },
  {
    field: 'funded',
    headerName: 'Amount Unfunded',
    flex: 1,
    renderCell: (props) =>
      percentCellRenderer({ value: 100 - (props.row.totalCapitalCall || 0) }),
  },
];

const CommitmentList = ({ commitments = [], portfolio }) => {
  const commitmentsData = useMemo(
    () =>
      [...commitments]
        .filter(
          (commitment, index) =>
            !index || commitment.transactionType === 'CAPITAL_CALL',
        )
        .reverse(),
    [commitments],
  );

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} alignItems="center" columnSpacing={2}>
        <Typography fontWeight="700 !important">Historical Values</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          headerData={headers}
          data={commitmentsData}
          containerProps={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  );
};

export default CommitmentList;
