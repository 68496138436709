import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import EllipsisTypography from 'components/Typography/EllipsisTypography';
import ImageContent from 'components/FundCard/FundCardLandscape/ImageContent';

import config from 'utils/config';
import palette from 'theme/palette';

const defaultDate = new Date().toISOString();

const ArticleCard = ({
  id,
  imageSrc = 'https://media.istockphoto.com/id/511061090/photo/business-office-building-in-london-england.jpg?s=612x612&w=0&k=20&c=nYAn4JKoCqO1hMTjZiND1PAIWoABuy1BwH1MhaEoG6w=',
  articleDate = defaultDate,
  name = 'WSJ News Exclusive | A 3% Mortgage Rate in a 7% World? This Startup Says It Can Do That',
  category = 'article category',
  height = '280px',
  onClick,
  articleContentContainerProps = {},
  backgroundColor = config.system.appColours.cardBackground,
}) => {
  const handleClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <Grid
      container
      minHeight={height}
      direction="column"
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <Grid item xs>
        <ImageContent height={175} headerImageSrc={imageSrc} />
      </Grid>

      <Grid
        container
        item
        xs
        direction="column"
        sx={{
          backgroundColor,
        }}
        pt={1}
        px={[1.5, 3]}
        {...articleContentContainerProps}
      >
        <EllipsisTypography
          variant="smallStatic"
          ellipsisAtLine={2}
          color={palette.text.obsidian}
          marginBottom={1.5}
        >
          {name}
        </EllipsisTypography>
        <Typography fontSize="11px" color={palette.text.obsidian}>
          <b>{category}</b> •{' '}
          {new Date(articleDate).toLocaleString('en-AU', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(ArticleCard);
