import React, { useMemo, useCallback, memo } from 'react';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AuthContainer from 'components/AuthContainer/indexv2';
import PasswordInput from 'components/Input/PasswordInput';
import ValidationHints from 'components/ValidationHints';
import ItemContainer from 'components/ItemContainer';
import { useActions, useStores } from 'components/StateProvider';

import usePasswordValidationHints from 'hooks/usePasswordValidationHints';

import newPasswordValidationSchema from 'validations/schema/newPasswordValidationSchema';
import services from 'api/services';
import { ERROR_MESSAGES } from 'constants/errors';

const UpdatePassword = ({ setPageStatus, verificationCode, withTitle }) => {
  const {
    auth: { isAuthenticated },
  } = useStores();
  const { handleLogout } = useActions();

  const form = useFormik({
    validationSchema: newPasswordValidationSchema,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validateOnMount: true,
  });

  const { validationResults, handlePasswordValidations } =
    usePasswordValidationHints({
      value: form.values.password,
      validationSchema: newPasswordValidationSchema,
    });

  const handleNewPasswordChange = useCallback(
    (evt) => {
      form.handleChange(evt);
      handlePasswordValidations(evt.target.value);
    },
    [validationResults],
  );

  const handleUpdatePassword = useCallback(async () => {
    try {
      const response = await services.changePassword({
        password: form.values.password,
        verificationCode,
      });

      if (response.status === 200) {
        setPageStatus('updated');
      }

      if (isAuthenticated) handleLogout();
    } catch (error) {
      form.setFieldError(
        'passwordConfirmation',
        ERROR_MESSAGES[error.response.data.code],
      );
    }
  }, [form.values.password, isAuthenticated]);

  const passwordDependencies = [
    form.errors.password,
    form.errors.passwordConfirmation,
    form.touched.password,
    form.touched.passwordConfirmation,
    form.values.password,
    form.values.passwordConfirmation,
  ];

  const newPasswordItems = useMemo(() => {
    const title = {
      key: 'createNewPasswordTitle',
      item: (
        <Typography variant="h4" textAlign="center">
          Create New Password
        </Typography>
      ),
      marginBottom: 4,
    };

    const items = [
      {
        key: 'enterNewPasswordDesc',
        item: (
          <Typography textAlign="center">
            Please enter your new password below
          </Typography>
        ),
      },
      {
        key: 'passwordInput',
        item: (
          <PasswordInput
            sx={{ mb: { xs: 1, md: 1.5, lg: '17px' } }}
            data-testid="password-input"
            id="password"
            name="password"
            value={form.values.password}
            onChange={handleNewPasswordChange}
            onBlur={form.handleBlur}
            placeholder="New password*"
            required
          />
        ),
        responsive: true,
      },
      {
        key: 'passwordConfirmationInput',
        item: (
          <PasswordInput
            sx={{ mb: '17px' }}
            data-testid="password-confirmation-input"
            id="passwordConfirmation"
            name="passwordConfirmation"
            value={form.values.passwordConfirmation}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder="Confirm password*"
            error={
              form.touched.passwordConfirmation &&
              form.errors.passwordConfirmation
            }
            helperText={
              form.touched.passwordConfirmation &&
              form.errors.passwordConfirmation
            }
            required
          />
        ),
        responsive: true,
      },
      {
        key: 'validationHints',
        item: <ValidationHints hints={validationResults} column={2} />,
        responsive: true,
        childGridProps: { md: 8 },
      },
    ];

    if (withTitle) items.unshift(title);

    return items;
  }, passwordDependencies);

  return (
    <AuthContainer
      isLoggedIn={isAuthenticated}
      title="Create New Password"
      nextLabel="Update Password"
      onNext={handleUpdatePassword}
      isNextButtonDisabled={!!Object.keys(form.errors).length}
    >
      <Grid
        container
        direction="row"
        rowSpacing={2}
        alignItems="center"
        justifyContent="center"
        mt={3}
      >
        {newPasswordItems.map(({ key, item, ...props }) => (
          <ItemContainer key={key} {...props}>
            {item}
          </ItemContainer>
        ))}
      </Grid>
    </AuthContainer>
  );
};

export default memo(UpdatePassword);
