import React from 'react';
import Typography from '@mui/material/Typography';
import InsertChartOutlined from '@mui/icons-material/InsertChartOutlined';

import EmailTemplates from './EmailTemplates';
import SiteConfigurations from './SiteConfigurations';
import Layout from './Layout';
import Pages from './Pages';
import SliderSettings from './SliderSettings';

// eslint-disable-next-line
export const platformConfigurationTabItems = ({ disabled, ...props } = {}) => [
  {
    key: 'siteConfigurations',
    subLabel: 'Site Setup',
    Icon: InsertChartOutlined,
    label: <Typography variant="h6">Site Configurations</Typography>,
    content: <SiteConfigurations fieldKey="siteConfigurations" {...props} />,
  },
  {
    key: 'layout',
    subLabel: 'Layout',
    Icon: InsertChartOutlined,
    label: <Typography variant="h6">Layout</Typography>,
    content: <Layout fieldKey="layout" {...props} />,
  },
  {
    key: 'emailTemplates',
    subLabel: 'Email Templates',
    Icon: InsertChartOutlined,
    label: <Typography variant="h6">Email Templates</Typography>,
    content: <EmailTemplates fieldKey="emailTemplates" {...props} />,
  },
  {
    key: 'pages',
    subLabel: 'Pages',
    Icon: InsertChartOutlined,
    label: <Typography variant="h6">Pages</Typography>,
    content: <Pages fieldKey="pages" {...props} />,
  },
  {
    key: 'sliderSettings',
    subLabel: 'Slider',
    Icon: InsertChartOutlined,
    label: <Typography variant="h6">Slider Settings</Typography>,
    content: <SliderSettings fieldKey="sliderSettings" {...props} />,
  },
];
