import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid';

import Chip from 'components/Chip';

const ChipList = ({
  items = [],
  labelKey = 'label',
  onClick,
  selectedIndex,
} = {}) => {
  const handleClick = useCallback((index) => onClick(index), [onClick]);

  return (
    <Grid container spacing={1}>
      {items.map((item, index) => (
        <Grid item>
          <Chip
            label={item[labelKey]}
            value={index}
            onClick={
              index === selectedIndex ? undefined : () => handleClick(index)
            }
            color={
              index === selectedIndex
                ? 'selectedChipColor'
                : 'unselectedChipColor'
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChipList;
