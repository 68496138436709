import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dropdown from 'components/Dropdown';

import PortfolioList from './PortfolioList';

const TransactionsTab = ({
  portfolios = [],
  userInvestmentEntities = [],
  selectedInvestmentEntity,
  onChangeInvestmentEntity,
}) => (
  <Grid container rowSpacing={3}>
    <Grid container item xs={12} alignItems="center" columnSpacing={2}>
      <Grid item>
        <Typography variant="small" fontWeight="700 !important">
          Investments
        </Typography>
      </Grid>
      <Grid item xs>
        <Divider flexItem variant="middle" sx={{ mr: 0 }} />
      </Grid>
      <Grid item xs={2}>
        <Dropdown
          name="selectedInvestmentEntity"
          value={selectedInvestmentEntity}
          onChange={onChangeInvestmentEntity}
          placeholder="All Entity"
          options={userInvestmentEntities}
          maxheight="44px"
        />
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <PortfolioList portfolios={portfolios} />
    </Grid>
  </Grid>
);

export default TransactionsTab;
