import MUIGrid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const TaxContentContainer = styled(MUIGrid)(({ theme, style = {} }) => ({
  background: theme.palette.reach.blueLight,
  padding: { xs: '4px', md: '10px' },
  margin: [0, '10px'],
  ...style,
}));

export default TaxContentContainer;
