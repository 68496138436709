import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormik } from 'formik';

import Grid from '@mui/material/Grid';

import Button from 'components/Button';

import financialInformationValidationSchema from 'validations/schema/adminAddFunds/financialInformationValidationSchema';
import { mapFinancialInformation } from 'utils/ResponseMappers/admin/fundDetails';

import FundFinancialInformation from './FundFinancialInformation';
import CustodialBankDetails from './CustodialBankDetails';
import SignDocument from './SignDocument';
import FundFinancialInformationForm from './FundFinancialInformationForm';

const FinancialInformation = ({
  values,
  readOnly,
  errors,
  setFieldValue,
  setFieldError,
  fieldKey,
  setDirty,
  onSubmit,
  disableSaveBtn,
  deletedFinancialInformations,
  deletedFundDocuments,
}) => {
  const initiated = useRef();
  const [formFocus, setFormFocus] = useState(0);

  const handleSubmit = async (formValues, formikHelper) => {
    const res = await onSubmit({ [fieldKey]: formValues }, formikHelper);
    const newFinancialInformations = [...formValues];

    res.financialClasses?.forEach((financialClass, index) => {
      newFinancialInformations[index].bankAccountId =
        financialClass.bankAccount.id;
      newFinancialInformations[index].id =
        financialClass.financialInformation.id;
    });

    setFieldValue(fieldKey, [...newFinancialInformations], undefined, false);
    formikHelper.setValues([...newFinancialInformations]);

    initiated.current = false;
  };

  const financialInformationForm = useFormik({
    initialValues: values,
    validateOnChange: true,
    validationSchema: financialInformationValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    initiated.current = false;
  }, []);

  useEffect(() => {
    if (!initiated.current) {
      initiated.current = true;
      return;
    }

    setDirty(true);
  }, [financialInformationForm.values]);

  useEffect(() => {
    const isError = !!Object.keys(financialInformationForm.errors).length;

    if (isError !== errors) setFieldError(fieldKey, isError);
  }, [financialInformationForm.errors]);

  const handleAddClass = useCallback(() => {
    financialInformationForm.setValues([
      ...financialInformationForm.values,
      mapFinancialInformation({}),
    ]);
    setFormFocus(financialInformationForm.values.length);
  }, [financialInformationForm.values]);

  const handleChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      const newValues = [...financialInformationForm.values];

      const newValue = { ...newValues[index], [name]: value };

      newValues[index] = newValue;

      financialInformationForm.setValues(newValues);
    },
    [financialInformationForm.values[formFocus]],
  );

  const handleFormFocus = useCallback((index) => setFormFocus(index), []);

  const handleDeleteFinancialInformation = useCallback(
    ({ financialInformationId, index }) => {
      if (financialInformationId) {
        setFieldValue(
          'deletedFinancialInformations',
          deletedFinancialInformations.concat(financialInformationId),
        );
      }

      const newFinancialInformations = [...financialInformationForm.values];
      newFinancialInformations.splice(index, 1);

      financialInformationForm.setValues([...newFinancialInformations]);
    },
    [financialInformationForm.values, deletedFinancialInformations],
  );

  const handleDeleteSignDocument = useCallback(
    (document, index) => {
      if (document?.[0]?.id) {
        setFieldValue(
          'deletedFundDocuments',
          deletedFundDocuments.concat({
            key: document[0].documentKey,
            fundConfigurationId: document[0].fundConfigurationFk,
          }),
        );
      }

      const newFinancialInformations = [...financialInformationForm.values];
      newFinancialInformations[index].signDocument = [];

      financialInformationForm.setValues([...newFinancialInformations]);

      return Promise.resolve(true);
    },
    [financialInformationForm.values, deletedFundDocuments],
  );

  const FinancialInformationForm = useMemo(
    () =>
      financialInformationForm.values?.map((financialInformation, index) => (
        <Grid
          item
          key={`${index.toString()}-${financialInformation.id}`}
          onFocus={() => handleFormFocus(index)}
        >
          <FundFinancialInformation
            index={index}
            onChange={(e) => handleChange(e, index)}
            onBlur={financialInformationForm.handleBlur}
            values={financialInformation}
            errors={financialInformationForm.errors}
            readOnly={readOnly}
            onDelete={handleDeleteFinancialInformation}
          />

          <CustodialBankDetails
            onChange={(e) => handleChange(e, index)}
            onBlur={financialInformationForm.handleBlur}
            values={financialInformation}
            errors={financialInformationForm.errors}
            readOnly={readOnly}
          />

          <SignDocument
            index={index}
            onChange={(e) => handleChange(e, index)}
            onBlur={financialInformationForm.handleBlur}
            values={financialInformation}
            errors={financialInformationForm.errors}
            uploadBtnDisabled={disableSaveBtn || readOnly}
            onDelete={handleDeleteSignDocument}
          />
        </Grid>
      )),
    [handleChange, handleDeleteFinancialInformation],
  );

  return (
    <Grid container rowSpacing={3}>
      {FinancialInformationForm}
      <Grid item xs={12} textAlign="center">
        <Button
          fullWidth={false}
          data-testid="add-class-btn"
          buttoncolor="purpleHazeGradient"
          sx={{ padding: '10px' }}
          disabled={financialInformationForm.values.length > 3}
          onClick={handleAddClass}
        >
          Add Class
        </Button>
      </Grid>

      <Grid item xs={12} textAlign="right">
        <Button
          fullWidth={false}
          data-testid="submit-btn-financialInfo"
          buttoncolor="purpleHazeGradient"
          sx={{ padding: '10px' }}
          disabled={
            disableSaveBtn ||
            readOnly ||
            !!Object.keys(financialInformationForm.errors).length
          }
          onClick={financialInformationForm.submitForm}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
export default memo(FinancialInformation);
