import Services from 'api/services';
import {
  actionCellRenderer,
  amountWithDollarCellRenderer,
  dateCellRenderer,
  fundUpdatesCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const getFundUpdatesHeaders = ({ onDelete, currency = 'USD' } = {}) => [
  {
    field: 'date',
    headerName: 'Value Date',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(dateCellRenderer),
  },
  {
    field: 'description',
    headerName: 'Descriptions',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(({ value }) => value),
  },
  {
    field: 'called',
    headerName: '% Called',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(percentCellRenderer),
  },
  {
    field: 'toCall',
    headerName: '% to Call',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(percentCellRenderer),
  },
  {
    field: 'distribution',
    headerName: 'Distributions',
    flex: 1,
    renderCell: fundUpdatesCellRenderer(amountWithDollarCellRenderer),
  },
  {
    field: 'unitPriceNet',
    headerName: `Unit Price (Paid-in, ${currency})`,
    flex: 1,
    renderCell: ({ value, row }) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        value,
        row,
        fractions: 4,
      }),
  },
  {
    field: 'unitPriceGross',
    headerName: `Unit Price (Committed, ${currency})`,
    flex: 1,
    renderCell: ({ value, row }) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        value,
        row,
        fractions: 4,
      }),
  },
  {
    field: 'balanceNet',
    headerName: `Balance (Paid-in, ${currency})`,
    flex: 1,
    renderCell: ({ value, row }) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        value,
        row,
        fractions: 4,
      }),
  },
  {
    field: 'balanceGross',
    headerName: `Balance (Committed, ${currency})`,
    flex: 1,
    renderCell: ({ value, row }) =>
      fundUpdatesCellRenderer(amountWithDollarCellRenderer)({
        value,
        row,
        fractions: 4,
      }),
  },
  {
    field: 'deleteAction',
    headerName: '',
    flex: 0.5,
    renderCell: ({ row }) =>
      typeof row.id === 'number' && actionCellRenderer({ row, onDelete }),
  },
];

export const fetchFundUpdates = async (fundId) => {
  try {
    if (!fundId) return [];

    const res = await Services.adminGetFundDetailById(fundId);
    return res?.data;
  } catch (_) {
    return {};
  }
};
