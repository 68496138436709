import Services from 'api/services';

import condTrueFalse from 'utils/condTrueFalse';
import getNumbers from 'utils/strings/getNumbers';

import {
  FUND_ENTITY_STATUS,
  PAYMENT_TYPE_OPTIONS,
  PAY_IN_INSTALLMENT,
} from 'constants/investmentEntity';

const paymentDetailsFormFields = [
  {
    fieldType: 'dropdown',
    name: 'paymentType',
    label: 'Payment type',
    gridProps: {
      xs: 6,
    },
    options: PAYMENT_TYPE_OPTIONS,
  },
  {
    fieldType: 'currencyInput',
    name: 'totalInvestment',
    label: 'Total investment',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'units',
    label: 'Units',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'currencyInput',
    name: 'accessFee',
    label: '+Access fee',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'currencyInput',
    name: 'applicationFee',
    label: 'Application fee',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'currencyInput',
    name: 'amountPayableToday',
    label: 'Amount payable today',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'currencyInput',
    name: 'amountReceived',
    label: 'Amount received',
    gridProps: {
      xs: 6,
    },
  },
];

const installmentInformationFormFields = [
  {
    fieldType: 'currencyInput',
    name: 'amountPayableLater',
    label: 'Amount will be payable later',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'currencyInput',
    name: 'total',
    label: 'Total',
    gridProps: {
      xs: 6,
    },
    disabled: true,
  },
];

const fetchAdminUserEntityList = async ({ userId }) => {
  const res = await Services.adminGetUserEntityList(userId);

  return res?.data?.data;
};

const fetchUserData = async (userId) => {
  const res = await Services.adminGetUser(userId);

  return res?.data;
};

const mapFundConfigurationOption = (fundConfiguration) => ({
  ...fundConfiguration,
  label: fundConfiguration.className,
  value: fundConfiguration.id,
});

const fetchFunds = async () => {
  const res = await Services.adminGetPublicFunds();

  return res?.data?.reduce((funds, fund) => {
    if (!fund?.fundConfigurations?.length) return funds;

    return funds.concat({
      ...fund,
      label: fund?.fundName,
      value: fund?.id,
      fundConfigurations: fund.fundConfigurations.map(
        mapFundConfigurationOption,
      ),
    });
  }, []);
};

const mapPayload = (formValues) => ({
  fundConfigurationId: formValues.fundConfiguration,
  totalInvestment: getNumbers(formValues.totalInvestment),
  signedBy: formValues.userName,
  signedAt: new Date(),
  paymentType: formValues.paymentType,
  userInvestorMapFk: formValues.userEntity,
  fundFk: formValues.fund,
  unit: getNumbers(formValues.units),
  accessFee: getNumbers(formValues.accessFee),
  amountReceived: getNumbers(formValues.amountReceived),
  amountPayableToday: getNumbers(formValues.amountPayableToday),
  status: FUND_ENTITY_STATUS[formValues.investmentStatus]?.portfolioStatus,
  paymentStatus: FUND_ENTITY_STATUS[formValues.investmentStatus]?.status,
  applicationFee: getNumbers(formValues.applicationFee),

  amountPayableLater: condTrueFalse(
    formValues.paymentType === PAY_IN_INSTALLMENT,
    getNumbers(formValues.amountPayableLater),
    0,
  ),
  total: getNumbers(
    condTrueFalse(
      formValues.paymentType === PAY_IN_INSTALLMENT,
      formValues.total,
      formValues.amountReceived,
    ),
  ),
});

const submitUserInvestmentFund = async ({ formValues, cb, setError }) => {
  try {
    const payload = mapPayload(formValues);

    await Services.adminAddUserInvestmentFund(formValues.userId, payload);

    condTrueFalse(cb, cb);
  } catch (err) {
    setError(err.response?.message);
  }
};

export {
  paymentDetailsFormFields,
  installmentInformationFormFields,
  fetchAdminUserEntityList,
  fetchUserData,
  fetchFunds,
  submitUserInvestmentFund,
};
