import React, { useCallback, useMemo } from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import SummarySection from 'components/SummarySection';

import mapValue from 'utils/mapValue';
import {
  SUMMARY_FIELDS_TRUST_PROFILE,
  SUMMARY_FIELDS_ACCOUNTANT,
} from 'constants/investmentEntity';
import trustProfileValidationSchemas from 'validations/schema/addInvestment/trustProfileValidationSchemas';
import { mapDetailsToPayload } from './HelperFunction';

const TrustDetails = ({ values, withEdit, onChange }) => {
  const remappedAccountantSummaryFields = useMemo(
    () => [
      {
        ...SUMMARY_FIELDS_ACCOUNTANT[0],
        fieldComponent: 'download',
        name: 'trustCertificateURL',
        fieldDisplay: 'block',
        renderer: ({ value: certificateURLs }) =>
          certificateURLs.map((certificateUrl) => (
            <Link
              key={certificateUrl.url}
              href={mapValue(certificateUrl.url, '')}
              target="_blank"
              sx={{ display: 'block' }}
            >
              <Typography variant="linkFile">
                {certificateUrl.originalFileName}
              </Typography>
            </Link>
          )),
      },
    ],
    [],
  );

  const setValuesTrustDetails = useCallback((key) => (newValues) => {
    const oldValues =
      key === 'trustDetail' ? values?.detail?.trustDetail : values;
    const { addCertificates, payload } = mapDetailsToPayload({
      oldValues,
      newValues,
      key,
      certificateURLKey: 'trustCertificateURL',
      certificateKey: 'Trust',
    });
    onChange({
      fileUpload: addCertificates,
      fileUploadKey: 'trustCertificate',
      ...payload,
    });
  });

  return (
    <>
      <SummarySection
        fields={SUMMARY_FIELDS_TRUST_PROFILE}
        values={values?.detail?.trustDetail}
        setValues={setValuesTrustDetails('trustDetail')}
        formName="trustProfileForm"
        validationSchema={trustProfileValidationSchemas[0]}
        fieldDividerStyles={{
          margin: '10px 0',
        }}
        title="Trust Details"
        withEdit={withEdit}
      />
      <SummarySection
        fields={remappedAccountantSummaryFields}
        values={values}
        setValues={setValuesTrustDetails('trustAccountantCertificate')}
        formName="trustAccountantCertificateForm"
        validationSchema={trustProfileValidationSchemas[1]}
        fieldDividerStyles={{
          margin: '10px 0',
        }}
        title="Accountant's Certificate"
        withEdit={withEdit}
      />
    </>
  );
};

export default TrustDetails;
