import * as yup from 'yup';

const addressValidationSchema = () => ({
  state: yup.string().required('State is required.'),
  suburb: yup.string().required('Suburb is required.'),
  streetName: yup.string().required('Street Name is required.'),
  streetNumber: yup.string().required('Street Number is required.'),
  postcode: yup.string().required('Post Code is required.'),
});

export default addressValidationSchema;
