import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FundLinearProgress from './FundLinearProgress';

const notes = [
  <>
    <b>Drawdown Period</b>: During this time, we&apos;ll be progressively
    calling on your committed capital to invest into the Fund&apos;s underlying
    opportunities. See the “Commitments” tab above to see how much of your
    capital has been called to date.
  </>,
  <>
    <b>Harvest Period</b>: Over the harvest period, this is when you can
    generally expect to see the returns flowing in as there is a realisation of
    the hard-work that has gone into improving the underlying assets. The
    underlying portfolio companies or assets will eventually be sold during this
    period and any profits will be distributed to you. See both the “Value” &
    “Performance” tabs to understand how your investment is tracking.
  </>,
];

const TermClosedEndTemplate = ({ portfolio }) => (
  <Grid container item xs={12} rowSpacing={3}>
    <Grid item xs={12}>
      <FundLinearProgress
        fundProgress={portfolio?.fundConfiguration?.fundProgress}
      />
    </Grid>
    <Grid item>
      <Typography variant="title">Notes:</Typography>

      <ol>
        {notes.map((note) => (
          <li>
            <Typography>{note}</Typography>
          </li>
        ))}
      </ol>
    </Grid>
  </Grid>
);

export default TermClosedEndTemplate;
