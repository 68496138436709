import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as PortfolioIcon } from 'assets/icons/bank.svg';
import { ReactComponent as FundsIcon } from 'assets/icons/page-setting.svg';
import { ReactComponent as StatementsIcon } from 'assets/icons/new-file.svg';
import { ReactComponent as InvestmentEntitiesIcon } from 'assets/icons/module.svg';
import { ReactComponent as AboutIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as ContactInformationIcon } from 'assets/icons/help-chat.svg';
import { ReactComponent as TransactionIcon } from 'assets/icons/transaction.svg';

const general = [
  {
    id: '/dashboard',
    title: 'Home',
    type: 'item',
    url: '/dashboard',
    icon: DashboardIcon,
  },
  {
    id: '/dashboard/funds',
    title: 'Funds',
    type: 'item',
    url: '/dashboard/funds',
    icon: FundsIcon,
  },
  {
    id: '/dashboard/portfolio',
    title: 'My Portfolio',
    type: 'item',
    url: '/dashboard/portfolio',
    icon: PortfolioIcon,
  },
  {
    id: '/dashboard/documents',
    title: 'My Statements',
    type: 'item',
    url: '/dashboard/documents',
    icon: StatementsIcon,
  },
  {
    id: '/dashboard/transactions',
    title: 'Transactions',
    type: 'item',
    url: '/dashboard/transactions',
    icon: TransactionIcon,
  },
  {
    id: '/dashboard/investment-entity-list',
    title: 'My Entities',
    type: 'item',
    url: '/dashboard/investment-entity-list',
    icon: InvestmentEntitiesIcon,
  },
  {
    id: '/dashboard/about',
    title: 'About Reach Alts',
    type: 'item',
    url: '/dashboard/about',
    icon: AboutIcon,
  },
  {
    id: '/dashboard/contact-information',
    title: 'Contact Information',
    type: 'item',
    url: '/dashboard/contact-information',
    icon: ContactInformationIcon,
  },
];

export default general;
