import trimMaskInput from 'utils/trimMaskInput';

const bankDetails = (bankDetailsValue) => ({
  isAustralianBased: bankDetailsValue?.isAustralianBased || false,
  accountName: bankDetailsValue?.accountName || '',
  accountNumber: trimMaskInput(bankDetailsValue?.accountNumber) || '',
  bsb: trimMaskInput(bankDetailsValue?.bsb) || '',
  bankCode: bankDetailsValue?.bankCode || '',
  bankName: bankDetailsValue?.bankName || '',
  bankAddress: bankDetailsValue?.bankAddress || '',
  country: bankDetailsValue?.country || '',
  swiftCode: bankDetailsValue?.swiftCode || '',
});

export default bankDetails;
