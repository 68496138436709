import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const FieldContainer = ({
  label = 'title',
  rowSpacing = 1,
  variant = 'bodyGrey',
  labelColor,
  children,
  labelGridProps = {},
  childGridProps = {},
  ...props
}) => (
  <Grid
    container
    direction="row"
    rowSpacing={rowSpacing}
    alignItems="center"
    {...props}
  >
    <Grid item xs={12} display="flex" {...labelGridProps}>
      <Typography
        variant={variant}
        sx={{ textTransform: 'capitalize', color: labelColor }}
      >
        {label}
      </Typography>
    </Grid>
    <Grid item xs={12} sx={{ textTransform: 'capitalize' }} {...childGridProps}>
      {children}
    </Grid>
  </Grid>
);

export default FieldContainer;
