import * as yup from 'yup';

const trustProfileFormValidationSchema = yup.object({
  ABN: yup
    .string()
    .test(
      'ABN',
      'ABN must be at least 8 characters and maximum 18 characters',
      (value) => {
        if (value) {
          const schema = yup.string().min(8).max(18);
          return schema.isValidSync(value);
        }

        return true;
      },
    ),
  trustType: yup.string().required().label('Trust type'),
  fullName: yup.string().min(2).max(50).required().label('Full name'),
  country: yup.string().required().label('Country'),
});

export default trustProfileFormValidationSchema;
