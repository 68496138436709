import React from 'react';

import Grid from '@mui/material/Grid';

import EndStepContainer from 'components/EndStepContainer';
import RedirectLoginButton from 'components/Button/RedirectLoginButton';
import SidebarContainer from 'components/SidebarContainer';

const EmailSent = () => {
  const subtitle = (
    <>
      We’ve sent you an email to reset your password.
      <br />
      Please follow the directions in the email to update your password.
    </>
  );
  return (
    <SidebarContainer>
      <EndStepContainer
        title="Update password"
        subtitle={subtitle}
        containerProps={{ rowSpacing: 5 }}
        childrenContainerProps={{ mt: 10 }}
      >
        <Grid item xs={6} md={4}>
          <RedirectLoginButton label="Back to Login" />
        </Grid>
      </EndStepContainer>
    </SidebarContainer>
  );
};

export default EmailSent;
