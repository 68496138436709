import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WarningIcon from 'assets/icons/warning.svg';

const InfoText = ({ text }) => (
  <Grid
    container
    item
    xs={12}
    sx={{ '&.MuiGrid-item': { pt: 0 } }}
    columnSpacing={2}
    justifyContent="center"
  >
    <Grid item xs="auto" alignItems="center" justifyContent="center">
      <img
        src={WarningIcon}
        alt="warning"
        style={{ width: '36px', height: '36px' }}
      />
    </Grid>
    <Grid item xs="auto" style={{ textAlign: 'left', alignSelf: 'center' }}>
      <Typography variant="bodyGrey">{text}</Typography>
    </Grid>
  </Grid>
);

export default InfoText;
