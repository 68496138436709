import * as yup from 'yup';

const trusteeListValidationSchema = yup.object({
  trustees: yup.array().min(1).max(5),
  isConfirmedNoOtherTrustee: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),
});
const trusteeSummaryValidationSchema = yup.object({
  isConfirmedSummary: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),
});

const trusteeListSummaryValidationSchemas = [
  trusteeListValidationSchema,
  trusteeSummaryValidationSchema,
];
export default trusteeListSummaryValidationSchemas;
