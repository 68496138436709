import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Switch from 'components/Switch';
import Chip from 'components/Chip';

const PeriodStatus = ({ readOnly, onChange, values }) => {
  const handlePeriodChange = useCallback(
    (e) => {
      onChange({
        target: {
          name: 'periodStatus',
          value: e.target.checked,
        },
      });

      if (values.showWidget && e.target.checked) {
        onChange({
          target: {
            name: 'showWidget',
            value: !e.target.checked,
          },
        });
      }
    },
    [onChange],
  );

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justifyContent="center"
      spacing={1}
    >
      <Grid item>
        <Chip
          label="Closed-end"
          {...(values?.periodStatus
            ? {}
            : { color: 'fundStatusClosedChipColor' })}
        />
      </Grid>
      <Grid item>
        <Switch
          disabled={readOnly}
          onChange={handlePeriodChange}
          checked={values.periodStatus}
        />
      </Grid>
      <Grid item>
        <Chip
          label="Open-end"
          {...(values?.periodStatus
            ? { color: 'fundStatusOpenChipColor' }
            : {})}
        />
      </Grid>
    </Grid>
  );
};

export default PeriodStatus;
