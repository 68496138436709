import React, { memo, useCallback } from 'react';

import Services from 'api/services';
import LinkExpiredContainer from 'components/LinkExpiredContainer';

const VerificationExpired = ({ resendVerificationEmailPayload }) => {
  const handleResendVerificationEmail = useCallback(async () => {
    try {
      await Services.resendEmailVerification(resendVerificationEmailPayload);
      return true;
    } catch (e) {
      return e;
    }
  }, [resendVerificationEmailPayload]);

  return (
    <LinkExpiredContainer
      context="verification"
      expiredMessage="Account activation link has been expired"
      handleResendEmail={handleResendVerificationEmail}
    />
  );
};

export default memo(VerificationExpired);
