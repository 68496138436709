import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import Footer from 'containers/Admin/Approvals/Details/Footer';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import Loading from 'components/Loading';

import services from 'api/services';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import { useActions } from 'components/StateProvider';

import PlatformColourList from './PlatformColourList';
import { fetchColours } from './HelperFunction';

const SiteConfigurations = () => {
  const { handleUpdateAppColours } = useActions();
  const [colourValues, setColourValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    isDialogOpen: isOpenUpdateDialog,
    handleOpenDialog: handleOpenUpdateDialog,
    handleCloseDialog: handleCloseUpdateDialog,
  } = useDialog();

  const getColours = useMemo(() => fetchColours(), []);
  const { apiData: colours } = useFetchApi({
    url: getColours,
  });

  useEffect(() => {
    setColourValues(colours);

    handleUpdateAppColours(colours);
  }, [colours]);

  const handleChange = useCallback(
    (index, newColour) =>
      setColourValues((prevValues) => {
        const newColours = [...prevValues];

        newColours[index] = { ...newColours[index], colour: newColour };

        handleUpdateAppColours(newColours);

        return newColours;
      }),
    [],
  );

  const handleUpdatePlatformColour = useCallback(async () => {
    try {
      setLoading(true);

      await services.adminUpdatePlatformConfigurationColours({
        colours: colourValues,
      });
      handleOpenDialog();
    } finally {
      setLoading(false);
      handleCloseUpdateDialog();
    }
  }, [colourValues]);

  return (
    <Grid container rowSpacing={4}>
      <Grid item width="100%" px={2}>
        <PlatformColourList colours={colourValues} onChange={handleChange} />
      </Grid>

      <Footer
        approvalLabel="Save"
        withFeedbackBtn={false}
        onApprovalClick={handleOpenUpdateDialog}
        // approvedBtnDisabled={isLoading || !haveCrudPermission}
        // menus={menus}
        // onSubmitEntityApprove={handleOpenUpdateDialog}
        // onSubmitEntityStatusChange={adminUserVerification}
        // downloadBtnDisabled={!haveCrudPermission}
        // feedbackBtnDisabled={!haveCrudPermission}
        // approvedBtnDisabled={!haveCrudPermission}
      />

      <ConfirmationDialog
        open={!!isOpenUpdateDialog}
        title="Warning!"
        infoText=""
        subtitle="Do you want to update the application colours ?"
        onClose={handleCloseUpdateDialog}
        confirmButtonLabel="Update"
        onConfirm={handleUpdatePlatformColour}
        dialogContentDependencies={[handleUpdateAppColours]}
      />

      <SuccessDialog
        open={isDialogOpen}
        onConfirm={handleCloseDialog}
        subtitle="Platform Colour updated successfully!"
      />

      <Loading loading={loading} popup />
    </Grid>
  );
};

export default SiteConfigurations;
