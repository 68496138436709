import dayjs from 'dayjs';

import axios from 'axios';
import api from 'api';
import endpoint from 'api/endpoint';

import {
  COUNTRY_OPTIONS,
  COUNTRY_OPTIONS_ALPHA3,
  STATE_OPTIONS,
} from 'constants/investmentEntity';
import { USER_STATUS } from 'constants/userStatus';

import auPassportValidationSchema from 'validations/schema/completeProfile/auPassportValidationSchema';
import auDriverLicenseValidationSchema from 'validations/schema/completeProfile/auDriverLicenseValidationSchema';
import auMedicareValidationSchema from 'validations/schema/completeProfile/auMedicareValidationSchema';
import auVisaValidationSchema from 'validations/schema/completeProfile/auVisaValidationSchema';
import nzPassportValidationSchema from 'validations/schema/completeProfile/nzPassportValidationSchema';
import nzDriverLicenseValidationSchema from 'validations/schema/completeProfile/nzDriverLicenseValidationSchema';
import overseasValidationSchema from 'validations/schema/completeProfile/overseasValidationSchema';
import config from 'utils/config';

const countryOptions = COUNTRY_OPTIONS.slice(3, COUNTRY_OPTIONS.length);

const genderOptions = [
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'Male',
    value: 'M',
  },
];

const medicareCardOptions = [
  {
    label: 'Green',
    value: 'GREEN',
  },
  {
    label: 'Blue',
    value: 'BLUE',
  },
  {
    label: 'Yellow',
    value: 'YELLOW',
  },
];

export const auAddressInitialValues = {
  state: '',
  suburb: '',
  streetName: '',
  streetNumber: '',
  postcode: '',
};

const individualInfoFormInitialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: null,
  ...auAddressInitialValues,
};

const australianPassportFormInitialValues = {
  passportNumber: '',
  gender: '',
  ...individualInfoFormInitialValues,
};

const australianDriverLicenseFormInitialValues = {
  driversLicenceState: '',
  driversLicenceNumber: '',
  driversCardNumber: '',
  ...individualInfoFormInitialValues,
};

const australianMedicareFormInitialValues = {
  medicareCardNumber: '',
  medicareIndividualReferenceNumber: '',
  medicareCardExpiryDate: null,
  medicareCardColour: '',
  ...individualInfoFormInitialValues,
};

const australianVisaFormInitialValues = {
  visaPassportNumber: '',
  visaCountryOfIssue: '',
  ...individualInfoFormInitialValues,
};

const australiaFormInitialValues = {
  idType: '',
  australianPassport: australianPassportFormInitialValues,
  australianDriverLicense: australianDriverLicenseFormInitialValues,
  medicare: australianMedicareFormInitialValues,
  australianVisa: australianVisaFormInitialValues,
};

const individualFormFields = [
  {
    fieldType: 'input',
    name: 'firstName',
    label: 'First Name*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'middleName',
    label: 'Middle Name',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'lastName',
    label: 'Surname*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'datePicker',
    name: 'dateOfBirth',
    label: 'Date of Birth (DD/MM/YYYY)*',
    maxDate: dayjs(),
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
];

const auAddressFormFields = [
  {
    fieldType: 'input',
    name: 'streetNumber',
    label: 'Street Number*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'input',
    name: 'streetName',
    label: 'Street Name*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'input',
    name: 'postcode',
    label: 'Post Code*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'dropdown',
    name: 'state',
    label: 'State*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
    options: STATE_OPTIONS,
  },
  {
    fieldType: 'input',
    name: 'suburb',
    label: 'Suburb*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
];

const australiaPassportFormFields = [
  {
    fieldType: 'input',
    name: 'passportNumber',
    label: 'Passport Number*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'dropdown',
    name: 'gender',
    label: 'Gender*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
    options: genderOptions,
  },
  ...individualFormFields,
];

const australiaDriverLicenseFormFields = [
  {
    fieldType: 'dropdown',
    name: 'driversLicenceState',
    label: 'Driver License State*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
    options: STATE_OPTIONS,
  },
  {
    fieldType: 'input',
    name: 'driversLicenceNumber',
    label: 'Driver License Number*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'input',
    name: 'driversCardNumber',
    label: 'Card Number*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  ...individualFormFields,
];

const australiaMedicareFormFields = [
  {
    fieldType: 'dropdown',
    name: 'medicareCardColour',
    label: 'Type of Card*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
    options: medicareCardOptions,
  },
  {
    fieldType: 'datePicker',
    name: 'medicareCardExpiryDate',
    label: 'Card Expiry Date*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
    fieldProps: ({ medicareCardColour } = {}) => {
      if (medicareCardColour === 'GREEN')
        return {
          inputFormat: 'MM/YYYY',
          views: ['month', 'year'],
        };

      return {};
    },
  },
  {
    fieldType: 'input',
    name: 'medicareCardNumber',
    label: 'Medicare Card Number*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'medicareIndividualReferenceNumber',
    label: 'Individual Ref Number*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'medicareNameOnCard',
    label: 'Full Name (as appears on the card)*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  ...individualFormFields,
];

const australiaVisaFormFields = [
  {
    fieldType: 'dropdown',
    name: 'visaCountryOfIssue',
    label: 'Country*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
    options: COUNTRY_OPTIONS_ALPHA3,
  },
  {
    fieldType: 'input',
    name: 'visaPassportNumber',
    label: 'Passport Number*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  ...individualFormFields,
];

const australiaFormFields = {
  australianPassport: australiaPassportFormFields,
  australianDriverLicense: australiaDriverLicenseFormFields,
  medicare: australiaMedicareFormFields,
  australianVisa: australiaVisaFormFields,
};

export const validationSchemas = {
  australianPassport: auPassportValidationSchema,
  australianDriverLicense: auDriverLicenseValidationSchema,
  medicare: auMedicareValidationSchema,
  australianVisa: auVisaValidationSchema,
  newZealandPassport: nzPassportValidationSchema,
  newZealandDriverLicense: nzDriverLicenseValidationSchema,
  overseas: overseasValidationSchema,
};

const australianIdTypes = [
  {
    label: 'Australian Passport',
    value: 'australianPassport',
  },
  {
    label: 'Driver License',
    value: 'australianDriverLicense',
  },
  {
    label: 'Medicare ID',
    value: 'medicare',
  },
  {
    label: 'Australian Visa (Foreign Passport)',
    value: 'australianVisa',
  },
];

const newZealandPassportFormFields = [
  {
    fieldType: 'input',
    name: 'passportNumber',
    label: 'Passport Number*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'datePicker',
    name: 'passportExpiryDate',
    label: 'Passport Expiry Date*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  ...individualFormFields,
];

const newZealandDriverLicenseFormFields = [
  {
    fieldType: 'input',
    name: 'driversLicenceNumber',
    label: 'Driver License Number*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'driversLicenceVersion',
    label: 'Driver License Version Number*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
  ...individualFormFields,
];

const newZealandFormFields = {
  newZealandPassport: newZealandPassportFormFields,
  newZealandDriverLicense: newZealandDriverLicenseFormFields,
};

const newZealandPassportFormInitialValues = {
  passportNumber: '',
  passportExpiryDate: null,
  ...individualInfoFormInitialValues,
};

const newZealandDriverLicenseFormInitialValues = {
  driversLicenceNumber: '',
  driversLicenceVersion: '',
  ...individualInfoFormInitialValues,
};

const newZealandFormInitialValues = {
  idType: '',
  newZealandPassport: newZealandPassportFormInitialValues,
  newZealandDriverLicense: newZealandDriverLicenseFormInitialValues,
};

const newZealandIdTypes = [
  {
    label: 'Passport',
    value: 'newZealandPassport',
  },
  {
    label: 'Driver License',
    value: 'newZealandDriverLicense',
  },
];

const overseasFormField = [
  {
    fieldType: 'dropdown',
    name: 'country',
    label: 'Country*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
    options: countryOptions,
  },
  {
    fieldType: 'input',
    name: 'passportNumber',
    label: 'Passport Number*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'datePicker',
    name: 'passportExpiryDate',
    label: 'Passport Expiry Date*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'input',
    name: 'firstName',
    label: 'First Name*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'input',
    name: 'middleName',
    label: 'Middle Name',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'input',
    name: 'lastName',
    label: 'Surname*',
    gridProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    fieldType: 'dropdown',
    name: 'gender',
    label: 'Gender*',
    gridProps: {
      xs: 12,
      sm: 6,
    },
    options: genderOptions,
  },
  {
    fieldType: 'datePicker',
    name: 'dateOfBirth',
    label: 'Date of Birth (DD/MM/YYYY)*',
    maxDate: dayjs(),
    gridProps: {
      xs: 12,
      sm: 6,
    },
  },
];

const overseasFormFields = {
  overseas: overseasFormField,
};

const overseasFormInitialValues = {
  idType: 'overseas',
  country: '',
  passportNumber: '',
  passportExpiryDate: null,
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: null,
  gender: '',
};

export const citizenships = [
  {
    key: 'AU',
    label: 'Australia',
    fields: australiaFormFields,
    initialValues: australiaFormInitialValues,
    idTypeOptions: australianIdTypes,
    addressFields: auAddressFormFields,
  },
  {
    key: 'NZ',
    label: 'New Zealand',
    fields: newZealandFormFields,
    initialValues: newZealandFormInitialValues,
    idTypeOptions: newZealandIdTypes,
    addressFields: auAddressFormFields,
  },
  {
    key: 'overseas',
    label: 'Overseas',
    fields: overseasFormFields,
    initialValues: overseasFormInitialValues,
  },
];

export const formatDate = (date) => {
  const newDate = new Date(date);

  return newDate.toLocaleDateString('en-AU');
};

const mapAustralianPassportPayload = (formValues) => ({
  sourceId: 'passportdvs',
  greenid_passportdvs_number: formValues.passportNumber,
  greenid_passportdvs_givenname: formValues.firstName,
  greenid_passportdvs_middlename: formValues.middleName,
  greenid_passportdvs_surname: formValues.lastName,
  greenid_passportdvs_dob: formatDate(formValues.dateOfBirth),
  greenid_passportdvs_tandc: 'on',
});

const getSourceId = (state) => {
  switch (state) {
    case 'ACT':
      return 'actregodvs';
    case 'NSW':
      return 'nswregodvs';
    case 'NT':
      return 'ntregodvs';
    case 'QLD':
      return 'qldregodvs';
    case 'SA':
      return 'saregodvs';
    case 'TAS':
      return 'tasregodvs';
    case 'VIC':
      return 'vicregodvs';
    case 'WA':
      return 'waregodvs';
    default:
      return 'actregodvs';
  }
};

const mapAustralianDriverLicensePayload = (formValues) => {
  const sourceId = getSourceId(formValues.driversLicenceState);

  return {
    sourceId,
    [`greenid_${sourceId}_number`]: formValues.driversLicenceNumber,
    [`greenid_${sourceId}_cardnumber`]: formValues.driversCardNumber,
    [`greenid_${sourceId}_givenname`]: formValues.firstName,
    [`greenid_${sourceId}_middlename`]: formValues.middleName,
    [`greenid_${sourceId}_surname`]: formValues.lastName,
    [`greenid_${sourceId}_dob`]: formatDate(formValues.dateOfBirth),
    [`greenid_${sourceId}_tandc`]: 'on',
  };
};

const mapMedicarePayload = (formValues) => {
  const expiry = new Date(formValues.medicareCardExpiryDate);
  const greenExpiry = `0${expiry.getMonth() + 1}/${expiry.getFullYear()}`.slice(
    -7,
  );
  const otherExpiry = [
    `0${expiry.getDate()}`.slice(-2),
    `0${expiry.getMonth() + 1}`.slice(-2),
    `/${expiry.getFullYear()}`.slice(-2),
  ];
  const expiryDate =
    formValues.medicareCardColour === 'GREEN'
      ? greenExpiry
      : otherExpiry.join('/');

  return {
    sourceId: 'medicaredvs',
    greenid_medicaredvs_cardColour: formValues.medicareCardColour,
    greenid_medicaredvs_number: formValues.medicareCardNumber,
    greenid_medicaredvs_individualReferenceNumber:
      formValues.medicareIndividualReferenceNumber,
    greenid_medicaredvs_expiry: expiryDate,
    greenid_medicaredvs_nameOnCard: formValues.medicareNameOnCard,
    greenid_medicaredvs_tandc: 'on',
  };
};

const mapAustralianVisaPayload = (formValues = {}) => ({
  sourceId: 'visadvs',
  greenid_visadvs_passport_number: formValues.visaPassportNumber,
  greenid_visadvs_country_of_issue: formValues.visaCountryOfIssue,
  greenid_visadvs_givenname: formValues.firstName,
  greenid_visadvs_middlename: formValues.middleName,
  greenid_visadvs_surname: formValues.lastName,
  greenid_visadvs_dob: formatDate(formValues.dateOfBirth),
  greenid_visadvs_tandc: 'on',
});

const mapNzPassportPayload = (formValues = {}) => ({
  sourceId: 'nzpassport',
  greenid_nzpassport_number: formValues.passportNumber,
  greenid_nzpassport_expiry: formatDate(formValues.passportExpiryDate),
  greenid_nzpassport_givenname: formValues.firstName,
  greenid_nzpassport_middlename: formValues.middleName,
  greenid_nzpassport_surname: formValues.lastName,
  greenid_nzpassport_dob: formatDate(formValues.dateOfBirth),
  greenid_nzpassport_tandc: 'on',
});

const mapNzDriverLicensePayload = (formValues = {}) => ({
  sourceId: 'nztadriverslicence',
  greenid_nztadriverslicence_number: formValues.driversLicenceNumber,
  greenid_nztadriverslicence_versionnumber: formValues.driversLicenceVersion,
  greenid_nztadriverslicence_givenname: formValues.firstName,
  greenid_nztadriverslicence_middlename: formValues.middleName,
  greenid_nztadriverslicence_surname: formValues.lastName,
  greenid_nztadriverslicence_dob: formatDate(formValues.dateOfBirth),
  greenid_nztadriverslicence_tandc: 'on',
});

const getPayload = (formValues = {}) => {
  switch (formValues.idType) {
    case 'australianPassport':
      return mapAustralianPassportPayload(formValues);
    case 'australianDriverLicense':
      return mapAustralianDriverLicensePayload(formValues);
    case 'medicare':
      return mapMedicarePayload(formValues);
    case 'australianVisa':
      return mapAustralianVisaPayload(formValues);
    case 'newZealandPassport':
      return mapNzPassportPayload(formValues);
    case 'newZealandDriverLicense':
      return mapNzDriverLicensePayload(formValues);
    default:
      return formValues;
  }
};

export const submitGreenIDVerification = async (formValues = {}) => {
  const payload = {
    accountId: config.greenID.accountId,
    apiCode: config.greenID.apiCode,
    givenNames: formValues.firstName,
    middleNames: formValues.middleName,
    surname: formValues.lastName,
    email: formValues.email,
    dob: formatDate(formValues.dateOfBirth),
    country: formValues.citizenship,
    postcode: formValues.postcode,
    state: formValues.state,
    streetName: formValues.streetName,
    streetNumber: formValues.streetNumber,
    suburb: formValues.suburb,
    origin: 'simpleui',
  };

  const registerResponse = await axios.post(
    `${config.greenID.url}/df/register`,
    payload,
  );

  const payloadSetFields = {
    accountId: config.greenID.accountId,
    apiCode: config.greenID.apiCode,
    verificationToken: registerResponse.data.verificationToken,
    origin: 'simpleui',
    ...getPayload(formValues),
  };

  const response = await axios.post(
    `${config.greenID.url}/df/setfields`,
    payloadSetFields,
  );

  return response.data;
};

const mapOverseasUserPayload = (formValues = {}) => ({
  firstName: formValues.firstName,
  middleName: formValues.middleName,
  surname: formValues.lastName,
  gender: formValues.gender,
  passport: formValues.passportNumber,
  country: formValues.country,

  // Format to DD/MM/YYYY
  dateOfBirth: formatDate(formValues.dateOfBirth),
  passportExpiry: formatDate(formValues.passportExpiryDate),
});

export const submitProfileVerification = async (formValues = {}) => {
  if (formValues.idType !== 'overseas') {
    const res = await submitGreenIDVerification(formValues);

    const outcome =
      res?.outcome === USER_STATUS.VERIFIED_WITH_CHANGES
        ? USER_STATUS.VERIFIED
        : res?.outcome;

    await api.post(endpoint.SUBMIT_USER_VERIFICATION_GREEN_ID, {
      status:
        outcome === USER_STATUS.VERIFIED
          ? USER_STATUS.VERIFIED
          : USER_STATUS.PENDING,
      reason: res.reason,
    });

    return outcome;
  }

  const payload = mapOverseasUserPayload(formValues);
  const res = await api.post(endpoint.SUBMIT_USER_VERIFICATION, payload);

  return res.data?.user?.status_verification;
};
