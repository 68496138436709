import { ReactComponent as DocumentIcon } from 'assets/icons/document-file.svg';

const application = {
  id: 'group-application',
  title: 'APPLICATION',
  type: 'group',
  children: [
    {
      id: '/admin/approvals',
      title: 'Approvals',
      type: 'item',
      url: '/admin/approvals',
      icon: DocumentIcon,
    },
  ],
};

export default application;
