import { InputAdornment } from '@mui/material';
import Button from 'components/Button';
import React, { useCallback } from 'react';

import InputField from '.';

const UploadInput = ({
  name,
  onChange,
  value,
  accept = 'application/pdf',
  disabled,
  placeholder = 'Select Image',
}) => {
  const handleFilesBrowse = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file) {
        const payload = {
          target: {
            name,
            value: {
              blob: new Blob([file], { type: file.type }),
              url: URL.createObjectURL(file),
              fileName: file.name,
              file,
            },
          },
        };
        onChange(payload);
      }
    },
    [onChange],
  );

  return (
    <InputField
      fullWidth
      placeholder={placeholder}
      disabled={disabled}
      value={value?.fileName || ''}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              buttoncolor="purpleHazeGradient"
              sx={{ padding: '10px 34px' }}
              component="label"
              disabled={disabled}
            >
              {' '}
              Browse
              <input
                type="file"
                onChange={handleFilesBrowse}
                accept={accept}
                hidden
              />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default UploadInput;
