import React, { memo, useCallback, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useFetchApi from 'hooks/useFetchApi';

import { INVESTMENT_ENTITY_STATUSES } from 'constants/investmentEntity';

import useInvestmentEntityDropdown from 'hooks/useInvestmentEntityDropdown';
import HelperFunction from './HelperFunction';

const SelectEntityForm = ({ setValues, values }) => {
  const getInvestmentEntities = useMemo(
    () => HelperFunction.fetchInvestmentEntityListUser(),
    [],
  );

  const { apiData: investmentEntities, isFetchLoading } = useFetchApi({
    url: getInvestmentEntities,
  });

  const { InvestmentEntityDropdownComponent } = useInvestmentEntityDropdown({
    investmentEntities,
  });

  const investmentEntityOptions = useMemo(() => {
    const entitiesOptions = investmentEntities.map((investmentEntity) => ({
      ...investmentEntity,
      label: investmentEntity.name,
      // Should change this to id later on new Endpoint.
      value: investmentEntity.userInvestorMapId,
      customLabel: HelperFunction.getCustomOptionLabel(investmentEntity),
      disabled: investmentEntity.status === INVESTMENT_ENTITY_STATUSES.CANCELED,
    }));
    return entitiesOptions;
  }, [investmentEntities]);

  const handleChangeInvestmentEntity = useCallback(
    (e, originalValue) => {
      const {
        name,
        country,
        status,
        investmentType,
        userInvestorMapId,
        address,
        email,
      } = originalValue;

      setValues({
        ...values,
        entityDetail: {
          name,
          country,
          status,
          investmentType,
          userInvestorMapId,
          address,
          email,
        },
        investmentEntity: e.target.value,
      });
    },
    [investmentEntityOptions],
  );

  if (isFetchLoading) {
    return (
      <Grid item xs={12} textAlign="center" alignSelf="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container item rowSpacing={4}>
      <Grid item xs={12}>
        <Typography variant="body">
          Please select your Investment Entity that is making the redemption
          request:
        </Typography>
        <InvestmentEntityDropdownComponent
          autoFocus
          data-testid="investmentEntity-dropdown"
          name="investmentEntity"
          placeholder="Select your entity"
          autoWidth={false}
          onChange={handleChangeInvestmentEntity}
          value={values.investmentEntity}
        />
      </Grid>
    </Grid>
  );
};

export default memo(SelectEntityForm);
