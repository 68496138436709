import React, { useMemo } from 'react';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FundCardHeader from 'components/FundCard/components/Header/FundCardHeader';
import FundCardPortrait from 'components/FundCard';
import UndefinedLogoSrc from 'assets/icons/undefined-logo.svg';
import UndefinedHeaderSrc from 'assets/icons/undefined-header-image.svg';
import InputField from 'components/Input';
import UploadInput from 'components/Input/UploadInput';

const UploadBtnWithLabelHelper = ({
  label,
  onChange,
  values,
  name,
  helperText,
  accept = 'image/*',
  errorValue,
  disabled,
}) => (
  <>
    <Grid item xs={12}>
      <FormLabel>{label}</FormLabel>
    </Grid>
    <Grid item xs={12}>
      <UploadInput
        name={name}
        onChange={onChange}
        value={values?.[name]}
        accept={accept}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs={12}>
      <FormHelperText>{helperText}</FormHelperText>
    </Grid>
    <Grid item xs={12}>
      <FormHelperText error>{errorValue}</FormHelperText>
    </Grid>
  </>
);

const FundCardTeaserHeader = ({ readOnly, onChange, values, errors = {} }) => {
  const fundCardContentList = useMemo(
    () => [
      {
        heading: values.field1 || 'Field 1',
        value: values.value1 || 'Value 1',
      },
      {
        heading: values.field2 || 'Field 2',
        value: values.value2 || 'Value 2',
      },
      {
        heading: values.field3 || 'Field 3',
        value: values.value3 || 'Value 3',
      },
      {
        heading: values.field4 || 'Field 4',
        value: values.value4 || 'Value 4',
      },
      {
        heading: values.field5 || 'Field 5',
        value: values.value5 || 'Value 5',
      },
    ],
    [values],
  );

  return (
    <Grid container item m={1} rowSpacing={3}>
      <Grid
        container
        item
        xs={12}
        sx={{
          padding: '0px !important',
          border: '1px solid #ccc',
          borderRadius: '6px 6px 0px 0px',
        }}
      >
        <FundCardHeader
          logoSrc={values?.fundLogo?.url || UndefinedLogoSrc}
          headerImageSrc={values?.fundHeaderImg?.url || UndefinedHeaderSrc}
          headerBgColor="#245356"
          width="100%"
          headerTitle={values.fundName || 'Fund Name'}
          headerDesc={values.summary || 'Fund Summary (max 300 character)'}
          useHeaderBgWrapper
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={4}>
          <UploadBtnWithLabelHelper
            name="fundHeaderImg"
            onChange={onChange}
            values={values}
            label="Fund Teaser Header Image"
            helperText="65x90 pixels size (jpg, png) max 2MB"
            errorValue={errors.fundHeaderImg}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={4} p={4}>
          <FundCardPortrait
            title={values.fundName || 'Fund Name'}
            description={values.fundType || 'Fund Type'}
            contentList={fundCardContentList}
            logoSrc={values?.fundLogo?.url || UndefinedLogoSrc}
            headerImageSrc={values?.fundCardImage?.url || UndefinedHeaderSrc}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={12}>
            {/* Fund Logo */}
            <Grid item xs={6}>
              <UploadBtnWithLabelHelper
                name="fundLogo"
                onChange={onChange}
                values={values}
                label="Fund Logo"
                helperText="65x90 pixels size (jpg, png) max 2MB"
                errorValue={errors.fundLogo}
                disabled={readOnly}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2}>
            {/* Fund card image */}
            <Grid item xs={6}>
              <UploadBtnWithLabelHelper
                name="fundCardImage"
                onChange={onChange}
                values={values}
                label="Fund Card Image"
                helperText="400x186 pixels size (jpg, png) max 2MB"
                errorValue={errors.fundCardImage}
                disabled={readOnly}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} mt={4}>
            <Grid item xs={6}>
              <FormLabel> Heading</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <FormLabel> Value</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Field 1 Text"
                name="field1"
                onChange={onChange}
                value={values.field1}
                error={!!errors.field1}
                errorValue={errors.field1}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Value 1 Text"
                name="value1"
                onChange={onChange}
                value={values.value1}
                error={!!errors.value1}
                errorValue={errors.value1}
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Field 2 Text"
                name="field2"
                onChange={onChange}
                value={values.field2}
                error={!!errors.field2}
                errorValue={errors.field2}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Value 2 Text"
                name="value2"
                onChange={onChange}
                value={values.value2}
                error={!!errors.value2}
                errorValue={errors.value2}
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Field 3 Text"
                name="field3"
                onChange={onChange}
                value={values.field3}
                error={!!errors.field3}
                errorValue={errors.field3}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Value 3 Text"
                name="value3"
                onChange={onChange}
                value={values.value3}
                error={!!errors.value3}
                errorValue={errors.value3}
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Field 4 Text"
                name="field4"
                onChange={onChange}
                value={values.field4}
                error={!!errors.field4}
                errorValue={errors.field4}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Value 4 Text"
                name="value4"
                onChange={onChange}
                value={values.value4}
                error={!!errors.value4}
                errorValue={errors.value4}
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Field 5 Text"
                name="field5"
                onChange={onChange}
                value={values.field5}
                error={!!errors.field5}
                errorValue={errors.field5}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                fullWidth
                placeholder="Value 5 Text"
                name="value5"
                onChange={onChange}
                value={values.value5}
                error={!!errors.value5}
                errorValue={errors.value5}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FundCardTeaserHeader;
