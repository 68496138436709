import React, { useCallback, useState } from 'react';

import DataTable from 'components/DataTable';
import FailedDialog from 'components/Dialog/FailedDialog';
import Loading from 'components/Loading';
import TotalHeaders from 'components/TotalHeaders';
import MFADialog from 'components/Dialog/MFADialog';

import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';
import Services from 'api/services';

import condTrueFalse from 'utils/condTrueFalse';
import { downloadFile } from 'utils/download';
import { ERROR_MESSAGES } from 'constants/errors';

import {
  downloadExportData,
  getInvestmentEntityTotalHeader,
  investmentEntityHeaders,
} from './HelperFunction';

const InvestmentEntity = ({ onRowClick }) => {
  const [openMFADialog, setOpenMFADialog] = useState(false);
  const [MFAError, setMFAError] = useState('');
  const [loading, setLoading] = useState(false);
  const { haveCrudPermission } = useAdminRestriction();
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: Services.adminGetApprovalEntities,
    defaultApiData: {},
  });

  const handleExport = useCallback((verificationCode) => {
    const getResponseData = async () => {
      try {
        setLoading(true);
        const responseData = await downloadExportData({
          category: 'entity',
          verificationCode: Number(verificationCode),
        });
        if (responseData) {
          if (responseData?.needVerification) {
            setOpenMFADialog(true);
            return;
          }
          const currentDate = new Date();
          const splittedDate = currentDate
            .toLocaleDateString('en-AU')
            .split('/');
          const filename = `Entity-${splittedDate[0]}${splittedDate[1]}${splittedDate[2]}.xlsx`;
          downloadFile({ href: responseData?.code, filename });
        }

        setOpenMFADialog(false);
      } catch (err) {
        const errorCode = err.response?.data?.code;

        setMFAError(ERROR_MESSAGES[errorCode]);
      } finally {
        setLoading(false);
      }
    };
    getResponseData();
  }, []);

  const handleCloseMFADialog = useCallback(() => {
    setMFAError('');
    setOpenMFADialog(false);
  }, []);

  const handleResetMFAError = useCallback(() => setMFAError(''), []);

  return (
    <div data-testid="investment-entity-container">
      <TotalHeaders
        totals={getInvestmentEntityTotalHeader(apiData.totalItems)}
        onClickExport={handleExport}
        disableExportBtn={!haveCrudPermission}
      />
      <DataTable
        height="656px"
        headerData={investmentEntityHeaders}
        data={apiData.items}
        setSort={setSort}
        isLoading={isFetchLoading}
        totalData={apiData.totalItems}
        onRowClick={onRowClick}
        getRowId={(row) => row?.userInvestorMapId}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />

      <MFADialog
        open={openMFADialog}
        title="Export data authentication"
        subtitle="Data security is our top priority. To be able to export the data, we’ve sent the authentication code to your email."
        onConfirm={handleExport}
        onClose={handleCloseMFADialog}
        errorText={MFAError}
        onClickResendMFA={
          MFAError === ERROR_MESSAGES['error.investment.token.expired'] &&
          handleExport
        }
        dialogContentDependencies={[MFAError]}
        setErrorText={setMFAError}
        initialTimer={condTrueFalse(
          MFAError === ERROR_MESSAGES['error.login.token.expired'],
          1,
          undefined,
        )}
        submitBtnDisabled={!!MFAError}
      />

      <Loading popup label="" loading={loading} />

      <FailedDialog
        open={MFAError && !openMFADialog}
        onConfirm={handleResetMFAError}
        title="Export Error"
        subtitle={MFAError}
      />
    </div>
  );
};

export default InvestmentEntity;
