import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import TabPanels from 'components/TabPanels';
import Tabs from 'components/Tabs';

import useAdminRestriction from 'hooks/useAdminRestriction';
import useTabs from 'hooks/useTabs';

import {
  IEDetailsPropsValueMappers,
  IEDetailsTabItems,
} from './HelperFunction';

const DetailsContent = ({ values, onChange, ...props }) => {
  const { haveCrudPermission } = useAdminRestriction();
  const { activeTabIdx, handleTabChange } = useTabs();

  const tabPanelProps = useMemo(
    () => IEDetailsPropsValueMappers({ values }),
    [values],
  );

  return (
    <Grid container sx={{ border: '1px solid #E4E4E4', borderRadius: '6px' }}>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #E4E4E4' }}>
        <Tabs
          value={activeTabIdx}
          onChange={handleTabChange}
          centered={false}
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <TabComponents
            activeTabIdx={activeTabIdx}
            tabItems={IEDetailsTabItems({ type: values?.type })}
            testIdPrefix="admin-details-entity-tab"
          />
        </Tabs>
      </Grid>
      <Grid item xs>
        <TabPanels
          value={activeTabIdx}
          tabItems={IEDetailsTabItems({
            type: values?.type,
            onChange,
            withEdit: haveCrudPermission,
            userId: values?.userId,
            ...props,
          })}
          {...tabPanelProps}
        />
      </Grid>
    </Grid>
  );
};

export default DetailsContent;
