import React, { memo, useMemo } from 'react';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  SubTitle,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, SubTitle);

const DoughnutChart = ({ data, title, ...props }) => {
  const chartData = useMemo(() => {
    const labels = [];

    const datasets = {
      label: title,
      data: [],
      backgroundColor: [],
    };

    data.forEach((item) => {
      datasets.data.push(Number(item.value));
      datasets.backgroundColor.push(item.color);
      labels.push(item.label);
    });

    return {
      labels,
      datasets: [datasets],
    };
  }, [data]);

  return (
    <Doughnut
      data={chartData}
      options={{ plugins: { legend: { position: 'bottom' } } }}
      {...props}
    />
  );
};

export default memo(DoughnutChart);
