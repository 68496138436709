import * as yup from 'yup';

const companyTaxSummaryValidationSchema = yup.object({
  isConfirmed: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),
});

export default companyTaxSummaryValidationSchema;
