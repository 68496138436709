import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DataTable from 'components/DataTable';

import {
  amountWithDollarCellRenderer,
  dateCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const headers = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'currentTotalInvestments',
    headerName: 'Capital Committed',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'funded',
    headerName: 'Capital Called',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'growth',
    headerName: 'Growth (cum.)',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'distribution',
    headerName: 'Distr. (cum.)',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({ value: props.value, fractions: 2 }),
  },
  {
    field: 'totalReturn',
    headerName: 'Total Return (cum.)',
    flex: 1,
    renderCell: (props) =>
      amountWithDollarCellRenderer({
        value: props.row.growth + props.row.distribution,
        fractions: 2,
      }),
  },
];

const HistoricalValueList = ({ data = [] }) => {
  console.log('@');

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12} alignItems="center" columnSpacing={2}>
        <Typography fontWeight="700 !important">Historical Values</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          headerData={headers}
          data={data}
          containerProps={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  );
};

export default HistoricalValueList;
