import React from 'react';

import SummarySection from 'components/SummarySection';
import { SUMMARY_FIELDS_TRUST_DETAIL } from 'constants/investmentEntity';

const TrustDetail = ({ values }) => (
  <SummarySection
    fields={SUMMARY_FIELDS_TRUST_DETAIL}
    values={values}
    withEdit={false}
    sectionListView
  />
);

export default TrustDetail;
