import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import TextSnippet from '@mui/icons-material/TextSnippet';

import BackButton from 'components/Button/BackButton';
import Button from 'components/Button';

const Header = ({
  onBackButtonClick,
  backButtonLabel = 'Entity Approval',
  onHistoryBtnClick,
}) => (
  <Grid item container xs={12} columnSpacing={2} alignItems="center">
    <Grid item>
      <BackButton
        data-testid="button-back"
        onClick={onBackButtonClick}
        typographyVariant="display3"
        label={backButtonLabel}
      />
    </Grid>
    <Grid item xs>
      <Divider flexItem variant="middle" />
    </Grid>
    {onHistoryBtnClick && (
      <Grid item>
        <Button
          data-testid="button-history"
          buttoncolor="purpleHazeGradient"
          sx={{ padding: '8px 4px 4px 4px', minWidth: '36px' }}
          onClick={onHistoryBtnClick}
        >
          <TextSnippet sx={{ height: '18px', width: '18px' }} />
        </Button>
      </Grid>
    )}
  </Grid>
);

export default Header;
