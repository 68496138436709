import React from 'react';

import Typography from '@mui/material/Typography';

import ValueTab from './ValueTab';
import CommitmentTab from './CommitmentTab';
import PerformanceTab from './PerformanceTab';
import TermTab from './TermTab';

// eslint-disable-next-line
export const investedPortfolioDetailsTabItems = (props = {}) => [
  {
    key: 'portfolioValue',
    label: <Typography variant="h6">Value</Typography>,
    content: <ValueTab fieldKey="portfolioValue" {...props} />,
  },
  {
    key: 'portfolioCommitments',
    label: <Typography variant="h6">Commitments</Typography>,
    content: <CommitmentTab fieldKey="portfolioCommitments" {...props} />,
  },
  {
    key: 'portfolioPerformance',
    label: <Typography variant="h6">Performance</Typography>,
    content: <PerformanceTab fieldKey="portfolioPerformance" {...props} />,
  },
  {
    key: 'portfolioTerm',
    label: <Typography variant="h6">Term</Typography>,
    content: <TermTab fieldKey="portfolioTerm" {...props} />,
  },
];
