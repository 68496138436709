import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import { MuiColorInput } from 'mui-color-input';

import Input from 'components/Input';

const ColourInput = ({ key, title, colour, onChange }) => (
  <Grid container columnSpacing={2}>
    <Grid item xs={6}>
      <Input
        fullWidth
        data-testid={`colour-input-${key}`}
        id={key}
        name={key}
        readOnly
        value={title}
      />
    </Grid>
    <Grid item>
      <MuiColorInput onChange={onChange} value={colour} />
    </Grid>
  </Grid>
);

const PlatformColourList = ({ colours, onChange }) => {
  const colourInputs = useMemo(
    () => (
      <Grid container rowSpacing={2}>
        {colours.map((colour, index) => (
          <Grid item xs={12}>
            <ColourInput
              key={colour.key}
              title={colour.title}
              colour={colour.colour}
              onChange={(newColour) => onChange(index, newColour)}
            />
          </Grid>
        ))}
      </Grid>
    ),
    [colours],
  );

  return <Grid container>{colourInputs}</Grid>;
};

export default PlatformColourList;
