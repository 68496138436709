import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import BarChart from 'components/Chart/BarChart';

import { getFullDateString } from 'utils/date';

import palette from 'theme/palette';

const defaultColor = [
  '#1C457D',
  '#00A2FF',
  '#01D6A1',
  '#FFDB86',
  '#D9D9D9',
  palette.reach.fuchsia,
  palette.reach.blueLight,
  palette.reach.yellowWarning,
];

const NetFundReturnChart = ({ data = [] }) => {
  const chartData = useMemo(() => {
    if (!data.length) return { labels: [], datasets: [] };

    const labels = [];

    const datasets = [
      {
        label: '3 Months',
        field: 'returnNet_3Month',
        data: [],
        backgroundColor: defaultColor[0],
      },
      {
        label: 'YTD',
        field: 'returnNetYtd',
        data: [],
        backgroundColor: defaultColor[1],
      },
      {
        label: '1 Year',
        field: 'returnNet_1Year',
        data: [],
        backgroundColor: defaultColor[2],
      },
      {
        label: '3 Years',
        field: 'returnNet_3Year',
        data: [],
        backgroundColor: defaultColor[3],
      },
      {
        label: '7 Years',
        field: 'returnNet_7Year',
        data: [],
        backgroundColor: defaultColor[4],
      },
      {
        label: '10 Years',
        field: 'returnNet_10Year',
        data: [],
        backgroundColor: defaultColor[5],
      },
      {
        label: 'Since Inception',
        field: 'returnNetSinceInception',
        data: [],
        backgroundColor: defaultColor[6],
      },
    ];

    data.forEach((dataItem) => {
      datasets.reduce((newDataSets, dataset, index) => {
        const datasetData = dataItem[dataset.field];

        if (!datasetData) return newDataSets;

        newDataSets[index].data.push(Number(datasetData || 0));

        return newDataSets;
      }, datasets);

      labels.push(getFullDateString({ date: dataItem.date }));
    });

    return {
      labels,
      datasets: datasets.filter((dataset) => dataset.data.length),
    };
  }, [data]);

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <BarChart
          data={chartData}
          style={{ marginTop: '16px', maxHeight: '300px' }}
          options={{
            scales: {
              y: {
                ticks: {
                  maxTicksLimit: 12,
                  callback: (label) => `${label}%`,
                },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default NetFundReturnChart;
