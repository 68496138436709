import { useCallback, useEffect, useState, useRef } from 'react';

import { useActions, useStores } from 'components/StateProvider';

const useSummarySection = ({ cb, onClickEdit }) => {
  const {
    general: { editingSummary },
  } = useStores();
  const { handleToggleEditSummary } = useActions();
  const [isEdit, setIsEdit] = useState();
  const unsubscribed = useRef(false);

  useEffect(
    () => () => {
      if (unsubscribed.current || !isEdit) return;

      unsubscribed.current = true;
      // Set edit summary state to false on exit.
      handleToggleEditSummary(false);
    },
    [],
  );

  useEffect(() => {
    if (cb) cb(isEdit);
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && editingSummary && isEdit !== editingSummary) {
      setIsEdit(false);
    }
  }, [editingSummary]);

  const handleClickEdit = useCallback(() => {
    if (onClickEdit) return onClickEdit();

    const newIsEdit = isEdit ? 0 : Date.now();
    setIsEdit(newIsEdit);

    handleToggleEditSummary(newIsEdit);

    return newIsEdit;
  }, [isEdit, onClickEdit]);

  return {
    isEdit,
    setIsEdit,
    handleClickEdit,
  };
};

export default useSummarySection;
