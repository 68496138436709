import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import BarChart from 'components/Chart/BarChart';

import { getFullDateString } from 'utils/date';

const defaultColor = ['#1C457D', '#00A2FF', '#D9D9D9', '#01D6A1', '#FFDB86'];

const IRRChart = ({ data = [] }) => {
  const chartData = useMemo(() => {
    if (!data.length) return { labels: [], datasets: [] };

    const labels = [];

    const datasets = [
      {
        label: 'IRR (Net)',
        field: 'irrNet',
        data: [],
        backgroundColor: defaultColor[1],
      },
      {
        label: 'Public Markets Equivalent (PME)',
        field: 'pme',
        data: [],
        backgroundColor: defaultColor[2],
      },
    ];

    data.forEach((dataItem) => {
      datasets.reduce((newDataSets, dataset, index) => {
        newDataSets[index].data.push(Number(dataItem[dataset.field] || 0));

        return newDataSets;
      }, datasets);

      labels.push(getFullDateString({ date: dataItem.date }));
    });

    return { labels, datasets };
  }, [data]);

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <BarChart
          data={chartData}
          style={{ marginTop: '16px', maxHeight: '300px' }}
          options={{
            scales: {
              y: {
                ticks: {
                  // stepSize: 1,
                  maxTicksLimit: 12,
                  callback: (label) => `${label}%`,
                },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default IRRChart;
