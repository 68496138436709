import React from 'react';
import Container from '@mui/material/Container';
import { Navigate, useLocation } from 'react-router-dom';

import AdminDrawer from 'components/AdminDrawer';
import DashboardDrawer from 'components/DashboardDrawer';
import SessionExpiringConfirmationDialog from 'components/Dialog/SessionExpiringConfirmationDialog';
import ShowMe from 'components/ShowMe';
import { useActions, useStores } from 'components/StateProvider';

import condTrueFalse from 'utils/condTrueFalse';
import history from 'utils/history';
import useHotjar from 'hooks/useHotjar';

import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_USER,
} from 'constants/userManagement';
import { STORAGE_KEY } from 'constants/general';

const defaultContainerSx = {
  padding: {
    xl: '40px 0px 0px 0px',
    lg: '40px 0px 0px 0px',
    sm: '40px 0px 0px 0px',
    xs: '40px 12px 0px 12px',
  },
  maxWidth: { xs: 'inherit' },
};

const defaultAdminContainerSx = {
  padding: {
    lg: '40px 60px 0px 60px',
    sm: '40px 30px 0px 30px',
    xs: '40px 12px 0px 12px',
  },
  maxWidth: { xs: 'inherit' },
};

const UPDATE_PHONE_NUMBER_ALLOWED_PATHS = [
  '/logout',
  '/profile/change-phone-number',
];

const UPDATE_PASSWORD_ALLOWED_PATHS = ['/logout', '/password-recovery'];

const ProtectedRoute = ({
  children,
  containerSx,
  admin,
  publicRoute,
  hideDrawer,
}) => {
  const {
    auth: { isAuthenticated, user },
    drawer: { open: drawerOpen },
  } = useStores();
  const { handleToggleDrawer } = useActions();
  const location = useLocation();
  useHotjar({ withHotjar: isAuthenticated && user.role === ROLE_USER });

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{
          // redirect back to document page after login
          href: condTrueFalse(
            history?.location?.pathname?.match('/document/'),
            `${history?.location?.pathname}${history?.location?.search}`,
          ),
        }}
        replace
      />
    );
  }

  if (
    !admin &&
    user.forceChangePassword &&
    user.verificationCode &&
    !UPDATE_PASSWORD_ALLOWED_PATHS.includes(location.pathname)
  ) {
    return (
      <Navigate
        to={`/password-recovery?token=${user.verificationCode}`}
        replace
      />
    );
  }

  if (
    !admin &&
    !user.mobileNumber &&
    !UPDATE_PASSWORD_ALLOWED_PATHS.includes(location.pathname) &&
    !UPDATE_PHONE_NUMBER_ALLOWED_PATHS.includes(location.pathname)
  ) {
    return <Navigate to="/profile/change-phone-number" replace />;
  }

  if (
    !publicRoute &&
    !admin &&
    [ROLE_ADMIN, ROLE_SUPER_ADMIN].includes(user.role) &&
    ![ROLE_USER].includes(sessionStorage.getItem(`${STORAGE_KEY}-tempRole`))
  ) {
    return <Navigate to="/admin" replace />;
  }

  if (
    !publicRoute &&
    admin &&
    ![ROLE_ADMIN, ROLE_SUPER_ADMIN].includes(user.role)
  ) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container
      sx={{
        ...(containerSx || admin
          ? defaultAdminContainerSx
          : defaultContainerSx),
        paddingLeft: !admin && drawerOpen ? '0px' : '',
      }}
    >
      {admin && (
        <AdminDrawer open={drawerOpen} onClose={() => handleToggleDrawer()} />
      )}
      <ShowMe when={!hideDrawer && !admin}>
        <DashboardDrawer open={drawerOpen} onClose={handleToggleDrawer} />
      </ShowMe>
      {children}

      <ShowMe when={!admin}>
        <SessionExpiringConfirmationDialog />
      </ShowMe>
    </Container>
  );
};

export default ProtectedRoute;
