import joinArrayStringWithSeparator from './strings/joinArrayStringWithSeparator';

const getFullName = (data, opt = { withMiddleName: false }) =>
  opt.withMiddleName
    ? joinArrayStringWithSeparator([
        data?.firstName,
        data?.middleName,
        data?.lastName,
      ]) // use this to prevent undefined middleName
    : `${data?.firstName} ${data?.lastName}`;

export default getFullName;
