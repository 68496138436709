const personalDetail = {
  // personalDetails
  title: '',
  dateOfBirth: null,
  country: '',
  // address
  state: '',
  addressCountry: '',
  // investorIdentifications
  idType: '',
  stateOfIssue: '',
  cardNumber: '',
  // accountCertificate
  certificates: [],
  // Summary
  agreement: false,
};

export default personalDetail;
