import React, { memo } from 'react';

import SignSection from 'components/SignSection';
import SummarySection from 'components/SummarySection';

import {
  SUMMARY_FIELDS_REDEMPTION_REQUEST_FUND_DETAILS,
  SUMMARY_FIELDS_REDEMPTION_REQUEST_INVESTOR_DETAILS,
  SUMMARY_FIELDS_REDEMPTION_REQUEST_PAYMENT_INSTRUCTION,
} from 'constants/investmentEntity';

import companyPersonalDetailsValidations from 'validations/schema/addInvestment/company/companyPersonalDetailsValidationSchemas';

const Confirmation = ({ values, summaryValues, onChange }) => (
  <>
    <SummarySection
      withEdit={false}
      title="Investor Details"
      fields={SUMMARY_FIELDS_REDEMPTION_REQUEST_FUND_DETAILS}
      values={summaryValues}
      validationSchema={companyPersonalDetailsValidations[0]}
      formName="investorDetailsForm"
    />
    <SummarySection
      title="Fund Details & Withdrawal Instruction"
      withEdit={false}
      fields={SUMMARY_FIELDS_REDEMPTION_REQUEST_INVESTOR_DETAILS}
      values={summaryValues}
      validationSchema={companyPersonalDetailsValidations[1]}
      formName="fundDetailsForm"
    />
    <SummarySection
      withEdit={false}
      title="Payment Instruction"
      fields={SUMMARY_FIELDS_REDEMPTION_REQUEST_PAYMENT_INSTRUCTION}
      values={summaryValues}
      validationSchema={companyPersonalDetailsValidations[2]}
      formName="paymentInstructionForm"
    />

    <SignSection
      signedBy={summaryValues.signedBy}
      values={values}
      onChange={onChange}
    />
  </>
);

export default memo(Confirmation);
