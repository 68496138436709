import React, { memo, useMemo } from 'react';
import DOMPurify from 'dompurify';

import lazyLoad from 'utils/lazyLoad';

const TextEditor = lazyLoad(() => import('components/TextEditor'));

const ALLOWED_VIDEOS = ['https://vimeo.com/', 'https://www.youtube.com/embed/'];

DOMPurify.addHook('uponSanitizeElement', (node, data) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || '';
    if (!ALLOWED_VIDEOS.some((ALLOWED_URL) => !src.startsWith(ALLOWED_URL))) {
      return node.parentNode.removeChild(node);
    }
  }

  return node;
});

const InnerHtmlContainer = ({
  htmlData = '',
  imageMaxWidth = 'auto',
  ...props
}) => {
  const htmlString = useMemo(
    () =>
      htmlData?.replaceAll(
        '<img src',
        `<img style="max-width: ${imageMaxWidth}" src`,
      ) || '',
    [htmlData],
  );

  return (
    <TextEditor
      hideToolbar
      disable
      value={DOMPurify.sanitize(htmlString, {
        ADD_TAGS: ['iframe'], // or ALLOWED_TAGS
        ADD_ATTR: [
          'allow',
          'allowfullscreen',
          'frameborder',
          'scrolling',
          'target',
        ], // or ALLOWED_ATR
      })}
      options={{ maxHeight: 'auto' }}
      {...props}
    />
  );
};

export default memo(InnerHtmlContainer);
