import React, { memo, useCallback, useEffect, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import InputField from 'components/Input';
import FieldContainer from 'components/FieldContainer';
import Dropdown from 'components/Dropdown';
import SwitchWithLabel from 'components/Switch/SwitchWithLabel';
import DragableAccordion from 'components/Accordion/DragableAccordion';

import condTrueFalse from 'utils/condTrueFalse';
import palette from 'theme/palette';

import { useFormik } from 'formik';
import { renderDataroomOptions } from 'constants/Icons';
import { getAccordionFooter } from './HelperFunction';

const TabAccordion = ({
  index,
  order,
  tabValue,
  onDelete,
  onAddBlockContent,
  onDeleteFundTab,
  onChange,
  readOnly,
  ...props
}) => {
  const { fundContents, ...initialValues } = tabValue; // exclude fundContents to not Update the block contents when tab value changes
  const fundDetailTabForm = useFormik({
    initialValues,
  });

  useEffect(() => {
    onChange(fundDetailTabForm.values);
  }, [fundDetailTabForm.values]);

  const tabIconOptions = useMemo(
    () =>
      renderDataroomOptions({
        iconProps: {
          className: 'active-icon-color',
        },
      }),
    [],
  );

  const titleItems = useMemo(
    () => (
      <>
        <Grid container item xs>
          <FieldContainer
            label={`Tab Title ${order + 1}`}
            direction="row"
            columnSpacing={2}
            labelGridProps={{ xs: 'auto' }}
            childGridProps={{ xs: true }}
          >
            <InputField
              fullWidth
              name="title"
              disabled={readOnly}
              value={fundDetailTabForm.values.title}
              onChange={fundDetailTabForm.handleChange}
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={3} md={2.5}>
          <FieldContainer
            label="Tab Icon"
            direction="row"
            columnSpacing={2}
            labelGridProps={{ xs: 'auto' }}
            childGridProps={{ xs: true }}
          >
            <Dropdown
              name="icon"
              disabled={readOnly}
              value={fundDetailTabForm.values.icon}
              options={tabIconOptions}
              onChange={fundDetailTabForm.handleChange}
            />
          </FieldContainer>
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          <FieldContainer
            name="isLocked"
            value={fundDetailTabForm.values.isLocked}
            label="Locked"
            direction="row"
            columnSpacing={2}
            labelGridProps={{ xs: 'auto' }}
            childGridProps={{ xs: 4 }}
          >
            <SwitchWithLabel
              disabled={readOnly}
              height={25}
              width={50}
              checked={fundDetailTabForm.values.isLocked}
              name="isLocked"
              onChange={fundDetailTabForm.handleChange}
            />
          </FieldContainer>
        </Grid>
      </>
    ),
    [fundDetailTabForm.values, readOnly],
  );

  const handleAdd = useCallback(() => {
    onAddBlockContent(index);
  }, [index]);

  const handleDelete = useCallback(() => {
    onDeleteFundTab(index);
  }, [index]);

  const footer = useMemo(
    () =>
      condTrueFalse(!readOnly, () =>
        getAccordionFooter({
          addKey: 'Block',
          deleteKey: 'Tab',
          onAdd: handleAdd,
          onDelete: handleDelete,
          addIconColor: palette.reach.bluish,
          textColor: palette.reach.bluish,
        }),
      ),
    [handleDelete, readOnly],
  );

  return (
    <DragableAccordion
      titleItems={titleItems}
      footer={footer}
      accordionDetailsProps={{ sx: { padding: '16px 8px', margin: 0 } }}
      {...props}
    />
  );
};

export default memo(TabAccordion);
