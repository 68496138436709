import React, { memo, useCallback } from 'react';
import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { DATE_MONTH_YEAR, DATE_MONTH_YEAR_TIME } from 'constants/formats';

import Input from 'components/Input';

const DatePicker = ({
  name,
  disabled,
  setFieldValue,
  errorValue,
  sx,
  onBlur,
  testId,
  datePickerType = 'date',
  onChange,
  ...props
}) => {
  const handleChangeDate = (value) => {
    if (setFieldValue) setFieldValue(name, new Date(value), true);
  };

  const getInput = useCallback(
    (params) => (
      <Input
        {...params}
        fullWidth
        data-testid={testId || props?.['data-testid']}
        name={name}
        onBlur={onBlur}
        errorValue={errorValue}
        sx={sx}
        disabled={disabled}
      />
    ),
    [name, onBlur, errorValue, sx, disabled, testId, props?.['data-testid']],
  );

  const DatePickerComponent = useCallback(
    (_props) =>
      datePickerType === 'dateTime' ? (
        <MUIDateTimePicker
          {..._props}
          format={DATE_MONTH_YEAR_TIME}
          slots={{ textField: _props.renderInput }}
          value={dayjs(_props.value)}
          minDate={dayjs(_props.minDate)}
          onChange={handleChangeDate}
          // onAccept={handleChangeDate}
        />
      ) : (
        <MUIDatePicker
          {..._props}
          slots={{ textField: _props.renderInput }}
          value={dayjs(_props.value)}
          minDate={dayjs(_props.minDate)}
          onChange={handleChangeDate}
        />
      ),
    [datePickerType, getInput],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerComponent
        format={DATE_MONTH_YEAR}
        disabled={disabled}
        name={name}
        renderInput={getInput}
        minDate={dayjs().subtract(100, 'years')}
        {...props}
      />
    </LocalizationProvider>
  );
};
export default memo(DatePicker);
