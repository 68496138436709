import React, { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

import { Container, Image, SlideContainer } from 'components/ImageCarousel';

import DashboardTitleImg from 'assets/images/dashboard-title.png';

const DashboardImageTitle = ({
  title,
  imgSrc = DashboardTitleImg,
  titleContainerStyles,
}) => {
  const theme = useTheme();

  const defaultTitleContainerStyles = useMemo(() => ({
    left: [
      theme.spacing(0),
      theme.spacing(2),
      theme.spacing(10),
      theme.spacing(15),
      theme.spacing(25),
    ],
  }));

  return (
    <Container>
      <SlideContainer style={{ left: 0 }}>
        <Image src={imgSrc} />
      </SlideContainer>
      <Grid
        sx={{
          position: 'absolute',
          left: '5%',
          top: '40%',
          ...defaultTitleContainerStyles,
          ...titleContainerStyles,
        }}
      >
        <Divider
          flexItem
          orientation="horizontal"
          sx={{
            height: '5px',
            background: '#7877CE',
            maxWidth: '85px',
            mb: '8px',
          }}
        />
        <Typography variant="display1" fontSize={38}>
          {title}
        </Typography>
      </Grid>
    </Container>
  );
};

export default DashboardImageTitle;
