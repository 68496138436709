import React, { useState, useMemo, useCallback } from 'react';

import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import AuthContainer from 'components/AuthContainer/indexv2';
import InputField from 'components/Input';
import Link from 'components/Link';
import SidebarContainer from 'components/SidebarContainer';

import services from 'api/services';

import history from 'utils/history';
import { generateMailTo } from 'utils/email';

import forgotPasswordValidationSchema from 'validations/schema/forgotPasswordValidationSchema';

import { REACH_ADMIN_EMAIL } from 'constants/general';
import { ERROR_MESSAGES } from 'constants/errors';

const mailHref = generateMailTo({
  email: REACH_ADMIN_EMAIL,
  subject: '',
});

const ForgotPasswordPage = () => {
  const [isEmailSent, setEmailSent] = useState(false);
  const form = useFormik({
    initialValues: { email: '' },
    validationSchema: forgotPasswordValidationSchema,
    validationOnMount: true,
  });

  // memo
  const subtitle = useMemo(() => {
    if (isEmailSent)
      return (
        <span>
          Your request has been submitted and if an account exists with this
          email address, you will receive a reset password link. Need
          assistance? Contact{' '}
          <Link href={mailHref} target="_blank">
            {REACH_ADMIN_EMAIL}
          </Link>
        </span>
      );
    return <span>Enter the email address associated with your account.</span>;
  }, [isEmailSent]);

  const nextLabel = useMemo(() => {
    if (isEmailSent) return 'Go to login';
    return 'Next';
  }, [isEmailSent]);

  const inputStatus = useMemo(
    () => (isEmailSent ? 'success' : ''),
    [isEmailSent],
  );
  // function
  const onBack = useCallback(() => history.back(), []);
  const onNext = useCallback(async () => {
    if (isEmailSent) {
      history.replace('/login');
    } else {
      // sent email to provided email address.
      try {
        form.setSubmitting(true);
        const result = await services.forgotPasswordSendEmail(form.values);
        if (result.status === 200) {
          setEmailSent(true);
        }
      } catch (e) {
        form.setFieldError('email', ERROR_MESSAGES[e.response?.data?.code]);
      } finally {
        form.setSubmitting(false);
      }
    }
  }, [isEmailSent, form.values.email]);

  return (
    <SidebarContainer withCompanyLogo>
      <AuthContainer
        title="Forgot Password?"
        onNext={onNext}
        onBack={onBack}
        nextLabel={nextLabel}
        isNextButtonDisabled={
          !form.touched.email || form.errors.email || form.isSubmitting
        }
        childGridProps={{
          textAlign: '-webkit-center',
        }}
      >
        <Typography mb={4} maxWidth={600}>
          {subtitle}
        </Typography>
        <Grid container width={{ xs: 'auto', md: '350px' }} textAlign="center">
          <InputField
            required
            data-testid="email-input"
            type="email"
            id="email"
            name="email"
            placeholder="Enter email"
            fullWidth
            value={form.values.email}
            errorValue={form.errors.email}
            onChange={form.handleChange}
            onKeyDown={form.handleBlur}
            icon={inputStatus}
            InputProps={{ readOnly: isEmailSent }}
          />
        </Grid>
      </AuthContainer>
    </SidebarContainer>
  );
};

export default ForgotPasswordPage;
