import Services from 'api/services';

import initial from 'store/states/initial';

import { ERROR_CODES } from 'constants/errors';
import { STORAGE_KEY } from 'constants/general';
import { ROLE_USER } from 'constants/userManagement';
import { USER_STATUS } from 'constants/userStatus';

const loginAction = async (dispatch, payload) => {
  try {
    let result = {};

    if (payload?.verificationCode) {
      result = await Services.loginVerifyMFA(payload);
    } else {
      result = await Services.login(payload);
    }

    const {
      data: {
        accessToken,
        tokenExpiry,
        user,
        verificationCode,
        needVerificationCode,
        userId,
        appColours,
      },
    } = result;

    if (needVerificationCode) {
      dispatch({
        type: '@@auth/LOGIN_SUCCESS',
        payload: {
          user: { id: userId },
        },
      });

      throw Error(ERROR_CODES.LOGIN.NEED_VERIFICATION_CODE);
    }

    const userValue = {
      ...user,
      middleName: user.middleName || '',
      role: user.userRole?.code || ROLE_USER,
      isVerified:
        user?.status_verification === USER_STATUS.VERIFIED ||
        user?.status_verification === USER_STATUS.VERIFIED_OVERSEAS,
      verificationCode,
    };

    dispatch({
      type: '@@auth/LOGIN_SUCCESS',
      payload: {
        user: userValue,
        token: accessToken,
        isAuthenticated: true,
        tokenExpiry,
      },
    });

    const newColours = appColours.reduce(
      (colours, colour) => ({
        ...colours,
        [colour.key]: colour.colour,
      }),
      { ...initial.general.colours },
    );

    dispatch({
      type: '@@general/UPDATE_COLOURS',
      payload: newColours,
    });

    localStorage.setItem(
      `${STORAGE_KEY}-appColours`,
      JSON.stringify(newColours),
    );
    localStorage.setItem(`${STORAGE_KEY}-token`, accessToken);

    return result;
  } catch (error) {
    dispatch({
      type: '@@auth/LOGIN_FAILURE',
      payload: error,
    });

    throw error;
  }
};

const refreshTokenAction = async (dispatch, payload) => {
  try {
    const result = await Services.refreshToken(payload);

    const {
      data: { accessToken, tokenExpiry },
    } = result;

    dispatch({
      type: '@@auth/REFRESH_TOKEN_SUCCESS',
      payload: {
        token: accessToken,
        tokenExpiry,
        isAuthenticated: true,
      },
    });

    localStorage.setItem(`${STORAGE_KEY}-token`, accessToken);

    return result;
  } catch (error) {
    dispatch({
      type: '@@auth/LOGIN_FAILURE',
      payload: error,
    });

    throw error;
  }
};

const logoutAction = async (dispatch) => {
  try {
    await Services.logout();
  } catch (e) {
    dispatch({
      type: '@@auth/LOGOUT_FAILURE',
      payload: null,
    });

    throw e;
  } finally {
    dispatch({
      type: '@@auth/LOGOUT_SUCCESS',
      payload: null,
      isAuthenticated: false,
      token: null,
    });

    localStorage.setItem(`${STORAGE_KEY}-token`, null);
    localStorage.setItem(STORAGE_KEY, null);
  }
};

export { loginAction, logoutAction, refreshTokenAction };
