import React from 'react';

import './confetti.css';

const confettiPieces = [...Array(13).keys()];

const Confetti = () => (
  <div>
    {confettiPieces.map((confetti) => (
      <div key={confetti} id={confetti} className="confetti-piece" />
    ))}
  </div>
);

export default Confetti;
