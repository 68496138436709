import React, { useCallback, useState } from 'react';

import PersonDetail from 'containers/DashboardPage/components/InvestmentEntityList/PersonDetail';
import AddButton from 'components/Button/AddButton';
import AddBeneficialOwnerDialog from './AddBeneficialOwnerDialog';
import { mapDetailsToPayload } from './HelperFunction';

const BeneficialOwners = ({
  values,
  valueKey,
  title,
  onChange,
  withEdit = true,
  entityUpdateKey = 'BeneficialOwner',
  userId,
}) => {
  const [openBeneficialOwnerDialog, setOpenBeneficialOwnerDialog] =
    useState(false);

  const handleChange = (index) => (newValues) => {
    const { addCertificates, payload } = mapDetailsToPayload({
      oldValues: values[index],
      newValues,
      key: valueKey,
    });

    onChange({
      fileUpload: addCertificates,
      ...payload,
    });
  };

  const handleDeleteBeneficialOwner = (index) =>
    useCallback(() => {
      const deleteKey = `delete${entityUpdateKey}`;

      onChange({
        [deleteKey]: {
          id: values[index].id,
        },
      });
    }, []);

  const handleOpenBeneficialOwnerDialog = useCallback(() => {
    setOpenBeneficialOwnerDialog(true);
  }, []);

  const handleCloseBeneficialOwnerDialog = useCallback(() => {
    setOpenBeneficialOwnerDialog(false);
  }, []);

  return (
    <>
      {values.map((beneficialOwner, index) => (
        <PersonDetail
          title={title}
          values={beneficialOwner}
          key={`person-detail-${beneficialOwner?.lastName}`}
          isBeneficialOwner
          chipColor="individual"
          withEdit={withEdit}
          setValues={handleChange(index)}
          onDelete={withEdit && index > 0 && handleDeleteBeneficialOwner(index)}
        />
      ))}

      {withEdit && values.length < 5 && (
        <AddButton
          onClick={handleOpenBeneficialOwnerDialog}
          label="Add another beneficial owner"
        />
      )}

      <AddBeneficialOwnerDialog
        entityUpdateKey={entityUpdateKey}
        open={openBeneficialOwnerDialog}
        onClose={handleCloseBeneficialOwnerDialog}
        onSubmit={onChange}
        userId={userId}
      />
    </>
  );
};

export default BeneficialOwners;
