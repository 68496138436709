// eslint-disable-next-line
export const HEADER_DEFAULT_BG_COLOR =
  'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.607207) 18.05%, #FFFFFF 95.19%)';

export const DRAFT = 'DRAFT';
export const PUBLISHED = 'PUBLISHED';
export const PUBLISHED_DRAFT = 'PUBLISHED-DRAFT';
export const CLOSED = 'Closed';
export const OPEN = 'Open';

export const FUND_STATUS = {
  [DRAFT]: DRAFT,
  [PUBLISHED]: PUBLISHED,
  [PUBLISHED_DRAFT]: PUBLISHED_DRAFT,
  [CLOSED]: CLOSED,
  [OPEN]: OPEN,
};

export const FUND_CONTENT_POSITIONS = {
  BODY: 'BODY',
  RIGHT: 'RIGHT',
};

export const FUND_DETAILS_CONTENT_POSITION_OPTIONS = [
  {
    label: 'Body',
    value: FUND_CONTENT_POSITIONS.BODY,
  },
  {
    label: 'Right',
    value: FUND_CONTENT_POSITIONS.RIGHT,
  },
];

export const FUND_TYPE_OPTIONS = [
  { label: 'Buyout', value: 'Buyout' },
  { label: 'Private Equity', value: 'Private Equity' },
  { label: 'Infrastructure', value: 'Infrastructure' },
  { label: 'Private Credit', value: 'Private Credit' },
  { label: 'Private Real Estate', value: 'Private Real Estate' },
  { label: 'Distressed Debt', value: 'Distressed Debt' },
  { label: 'Special Situations', value: 'Special Situations' },
  { label: 'Private Debt', value: 'Private Debt' },
  { label: 'Secondaries', value: 'Secondaries' },
  { label: 'Venture Capital', value: 'Venture Capital' },
  {
    label: 'Real Assets - Opportunistic',
    value: 'Real Assets - Opportunistic',
  },
  { label: 'Real Assets - Value-Add', value: 'Real Assets - Value-Add' },
  { label: 'Real Assets - Core', value: 'Real Assets - Core' },
  { label: 'Opportunistic', value: 'Opportunistic' },
  { label: 'Hybrid', value: 'Hybrid' },
  { label: 'Value-Add', value: 'Value-Add' },
  { label: 'Core', value: 'Core' },
  { label: 'Impact', value: 'Impact' },
];

export const CURRENCY_OPTIONS = [
  {
    label: 'AU ($)',
    value: 'AUD',
  },
  {
    label: 'NZ ($)',
    value: 'NZD',
  },
  {
    label: 'US ($)',
    value: 'USD',
  },
  {
    label: 'EUR (€)',
    value: 'EUR',
  },
  {
    label: 'GBP (£)',
    value: 'GBP',
  },
];

export const FUND_PERIODS = {
  CLOSED_END: 'CLOSED_END',
  OPEN_END: 'OPEN_END',
};

export const FUND_CATEGORIES = {
  CASH: 'CASH',
};

export const REPORTING_TEMPLATE_CLOSED_END = 'CLOSED_END';
export const REPORTING_TEMPLATE_OPEN_END = 'OPEN_END';

export const REPORTING_TEMPLATE_OPTIONS = [
  {
    label: 'Closed-end',
    value: REPORTING_TEMPLATE_CLOSED_END,
  },
  {
    label: 'Open-end',
    value: REPORTING_TEMPLATE_OPEN_END,
  },
];

const EVERGREEN_CLASS_TERM = [
  {
    label: 'Evergreen',
    value: 0,
  },
];

export const CLASS_TERM_OPTIONS = EVERGREEN_CLASS_TERM.concat(
  [...Array(20)].map((arr, index) => ({
    label: `~${index + 1} years`,
    value: index + 1,
  })),
);
