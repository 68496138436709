import React, { useCallback, useMemo, useState } from 'react';

import DataTable from 'components/DataTable';
import FailedDialog from 'components/Dialog/FailedDialog';
import Loading from 'components/Loading';
import TotalHeaders from 'components/TotalHeaders';

import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';
import { downloadFile } from 'utils/download';
import { ERROR_MESSAGES } from 'constants/errors';

import Services from 'api/services';

import { onUserTableRowClick, USER_LIST_COLUMNS } from './HelperFunction';

const UserTabContent = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [loading, setLoading] = useState('');
  const [exportError, setExportError] = useState('');
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: Services.adminGetUsers,
    defaultApiData: {},
  });

  const totalHeadersValue = useMemo(
    () => [
      { totalLabel: 'Total Users', totalValue: apiData?.totalItems },
      { totalLabel: 'Total Entities', totalValue: apiData?.totalEntities },
      { totalLabel: 'Raised', totalValue: apiData?.totalFunds },
    ],
    [apiData],
  );

  const handleExport = useCallback(() => {
    const getResponseData = async () => {
      try {
        setLoading(true);
        const responseData = await Services.adminExportUsersReport();
        const currentDate = new Date();
        const splittedDate = currentDate.toLocaleDateString('en-AU').split('/');
        const filename = `Users-Summary-Report-${splittedDate[0]}${splittedDate[1]}${splittedDate[2]}.xlsx`;
        downloadFile({ href: responseData?.data?.code, filename });
      } catch (err) {
        const errorCode = err.response?.data?.code;
        setExportError(ERROR_MESSAGES[errorCode]);
      } finally {
        setLoading(false);
      }
    };
    getResponseData();
  }, []);

  const handleResetexportError = useCallback(() => setExportError(''), []);

  return (
    <>
      <TotalHeaders
        totals={totalHeadersValue}
        disableExportBtn={!haveCrudPermission}
        onClickExport={handleExport}
      />

      <DataTable
        headerData={USER_LIST_COLUMNS}
        data={apiData?.items}
        setSort={setSort}
        isLoading={isFetchLoading}
        totalData={apiData?.totalItems}
        onRowClick={onUserTableRowClick}
        sx={{
          border: 'none',
        }}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />

      <FailedDialog
        open={!!exportError}
        onConfirm={handleResetexportError}
        title="Export Error"
        subtitle={exportError}
      />

      <Loading popup loading={loading} />
    </>
  );
};

export default UserTabContent;
