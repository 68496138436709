import React, { useMemo, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import Dialog from 'components/Dialog';
import Tabs from 'components/Tabs';
import TabPanels from 'components/TabPanels';
import SubmitCancel from 'components/Dialog/Actions/SubmitCancel';
import LoadingSpinner from 'components/LoadingSpinner';

import useTabs from 'hooks/useTabs';
import useUserDetailListData from 'hooks/useUserDetailListData';

import { ReactComponent as PersonAddIcon } from 'assets/icons/person-add.svg';

import {
  addEntityDialogItems,
  getAutoPopulatePersonalDetailsHeader,
} from './HelperFunction';

const AddEntityDialog = ({
  open,
  onClose,
  type = 'individual',
  title,
  titleFormComponent,
  dialogActionsComponent,
  Icon = PersonAddIcon,
  onSubmit,
  initialValues,
  validationSchema,
  withTab = true,
  dialogProps = {},
  userId,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPersonalDetailIndex, setSelectedPersonalDetailIndex] =
    useState({ personals: '', addresses: '' });
  const { activeTabIdx, setActiveTabIdx, handleTabChange } = useTabs();
  const { userDetailList } = useUserDetailListData({ userId });

  useEffect(() => setActiveTabIdx(0), [type]);

  const formikForm = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    setSelectedPersonalDetailIndex({ personals: '', addresses: '' });
  }, [type]);

  const handleSelectAutoPopulateUser = ({ index, recordedData, stepKey }) => {
    setLoading(true);
    formikForm.setValues({
      ...formikForm.values,
      ...recordedData[index],
    });

    setSelectedPersonalDetailIndex((prevSelected) => ({
      ...prevSelected,
      [stepKey]: index,
    }));

    // Delay loading to reset form component.
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const titleComponent = useMemo(
    () => (
      <Grid container rowSpacing={2} display="block" width="100%">
        {title && (
          <Grid item>
            <Typography
              variant="display3"
              display="flex"
              sx={{
                boxShadow: '0px 1px 10px 0px #a7a7a7',
                padding: '16px 8px',
                alignItems: 'center',
              }}
            >
              <Icon style={{ marginRight: '8px' }} />
              {title}
            </Typography>
          </Grid>
        )}
        {titleFormComponent && (
          <Grid container item>
            {titleFormComponent}
          </Grid>
        )}
        {withTab && (
          <Grid item p={2}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={activeTabIdx}
                onChange={handleTabChange}
                centered={false}
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                sx={{ minHeight: 'auto' }}
              >
                <TabComponents
                  activeTabIdx={activeTabIdx}
                  tabItems={addEntityDialogItems({ type })}
                />
              </Tabs>
            </Box>
          </Grid>
        )}
      </Grid>
    ),
    [activeTabIdx, type, titleFormComponent],
  );

  const content = useMemo(
    () => (
      <>
        {getAutoPopulatePersonalDetailsHeader({
          type,
          tabIndex: activeTabIdx,
          userDetailList,
          selectedPersonalDetailIndex,
          onSelectAutoPopulate: handleSelectAutoPopulateUser,
        })}
        {loading ? (
          <LoadingSpinner loading />
        ) : (
          <TabPanels
            value={activeTabIdx}
            tabItems={addEntityDialogItems({
              type,
              errors: formikForm.errors,
              onChange: formikForm.handleChange,
              values: formikForm.values,
              onBlur: formikForm.handleBlur,
              touched: formikForm.touched,
              setFieldValue: formikForm.setFieldValue,
              formikProps: formikForm,
              ...props,
            })}
            boxPanelProps={{ sx: { pt: 0 } }}
          />
        )}
      </>
    ),
    [
      activeTabIdx,
      formikForm.values,
      formikForm.errors,
      type,
      loading,
      userDetailList,
    ],
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      title={titleComponent}
      content={content}
      dialogActions={
        dialogActionsComponent || (
          <SubmitCancel
            cancelLabel="Cancel"
            submitLabel="Save"
            onCancelClick={onClose}
            onSubmitClick={formikForm.handleSubmit}
            submitDisabled={Object.keys(formikForm.errors).length}
          />
        )
      }
      dialogContentProps={{ style: { maxHeight: '700px', paddingTop: '16px' } }}
      dialogTitleProps={{
        justifyContent: 'initial',
        padding: '0px !important',
      }}
      dialogActionsProps={{ style: { boxShadow: '1px 0px 20px 0px #a7a7a7' } }}
      {...dialogProps}
    />
  );
};

export default AddEntityDialog;
