import React, { memo, useCallback, useState } from 'react';
import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import MUIAccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReorderIcon from '@mui/icons-material/ReorderOutlined';
import { styled } from '@mui/material/styles';

import palette from 'theme/palette';

const Container = styled('div')(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

const StyledMUIAccordion = styled(MUIAccordion)(({ theme, sx = {} }) => ({
  background: theme.palette.reach.white,
  border: '1px solid #E4E4E4',
  borderRadius: '6px',
  boxShadow: 'none',
  marginBottom: '16px',
  ...sx,
}));

const StyledMUIAccordionHeader = styled(MUIAccordionSummary)(
  ({ background, border }) => ({
    background,
    border,
    borderRadius: '6px 6px 0px 0px',
    padding: '14.5px 12px',
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  }),
);

const StyledMUIAccordionDetails = styled(MUIAccordionDetails)(
  ({ sx = {} } = {}) => ({
    marginTop: '25px',
    ...sx,
  }),
);

const CollapsedAccordionFooter = styled('div')(({ border, sx = {} }) => ({
  border,
  borderTop: '0',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  padding: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...sx,
}));

const DragableAccordion = ({
  readOnly,
  children,
  onDelete,
  sxProps = {},
  background = 'white',
  border = `1px solid ${palette.form.disabledBorder}`,
  titleItems,
  footer,
  accordionDetailsProps = {},
  accordionFooterProps = {},
  defaultOpen,
  ...props
}) => {
  const [open, setOpen] = useState(!!defaultOpen);

  const handleToggleAccordion = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return (
    <Container style={{ ...sxProps }}>
      <StyledMUIAccordion
        expanded={open}
        {...props}
        sx={{ marginBottom: '0px !important' }}
      >
        <StyledMUIAccordionHeader
          expandIcon={<ExpandMoreIcon onClick={handleToggleAccordion} />}
          background={background}
          border={border}
        >
          <Grid
            container
            direction="row"
            columnSpacing={3}
            alignItems="center"
            paddingRight={1} // Add space between expandIcon
          >
            <Grid item xs="auto">
              <ReorderIcon />
            </Grid>
            {titleItems}
          </Grid>
        </StyledMUIAccordionHeader>
        <StyledMUIAccordionDetails {...accordionDetailsProps}>
          {children}
        </StyledMUIAccordionDetails>
      </StyledMUIAccordion>
      <CollapsedAccordionFooter border={border} {...accordionFooterProps}>
        {footer}
      </CollapsedAccordionFooter>
    </Container>
  );
};

export default memo(DragableAccordion);
