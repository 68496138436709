import * as yup from 'yup';

import individualFormValidationSchema from './individualFormValidationSchema';
import addressValidationSchema from './addressValidationSchema';

const auDriverLicenseValidationSchema = yup.object({
  driversLicenceState: yup.string().required('Driver License is required.'),
  driversLicenceNumber: yup
    .string()
    .required('Driver License Number is required.'),
  driversCardNumber: yup.string().required('Driver Card Number is required.'),
  ...individualFormValidationSchema(),
  ...addressValidationSchema(),
});

export default auDriverLicenseValidationSchema;
