import React from 'react';

import Grid from '@mui/material/Grid';

import FormFields from 'containers/DashboardPage/components/CompleteProfileDialog/FormFields';
import FormTitle from 'components/FormTitle';

import condTrueFalse from 'utils/condTrueFalse';

import { paymentInstructionsFormFields } from './HelperFunction';

const PaymentInstructionsSection = ({ values }) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Payment Instructions" />
    </Grid>

    <Grid container item xs={12} p={2} rowSpacing={3}>
      {paymentInstructionsFormFields.map(({ fieldProps, ...formField }) => (
        <FormFields
          key={formField.name}
          {...formField}
          fieldProps={{
            ...condTrueFalse(fieldProps, () => fieldProps(values), {}),
            'data-testid': `paymentInstructionsFormField-${formField.name}`,
            readOnly: true,
          }}
          values={values}
          gridProps={{
            labelGridProps: { xs: 3 },
            childGridProps: { xs: 4.5 },
          }}
        />
      ))}
    </Grid>
  </Grid>
);

export default PaymentInstructionsSection;
