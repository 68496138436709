import React, { useCallback, useState } from 'react';
import PersonDetail from 'containers/DashboardPage/components/InvestmentEntityList/PersonDetail';
import AddJointInvestorButton from 'components/Button/AddJointInvestorButton';
import AddJointInvestorDialog from './AddJointInvestorDialog';
import { mapDetailsToPayload } from './HelperFunction';

const PersonalDetails = ({
  values,
  onChange,
  withEdit,
  readOnly,
  userId,
  ...props
}) => {
  const [openJointDialog, setOpenJointDialog] = useState(false);

  const handleChange = (index) => (newValues) => {
    const isJoint = !!index;

    const { addCertificates, modifyCertificates, payload } =
      mapDetailsToPayload({
        oldValues: values[index],
        newValues,
        key: 'personalDetail',
        certificateKey: isJoint ? 'Joint' : '',
      });

    if (isJoint) {
      if (modifyCertificates) {
        onChange({
          fileUpload: addCertificates,
          ...payload,
        });

        return;
      }

      onChange({
        fileUpload: addCertificates,
        joint: {
          ...payload,
        },
      });

      return;
    }

    onChange({
      fileUpload: addCertificates,
      ...payload,
    });
  };

  const handleDeleteJoint = useCallback(() => {
    onChange({
      deleteJoint: {
        id: values[1].id,
      },
    });
  }, []);

  const handleOpenJointDialog = useCallback(() => {
    setOpenJointDialog(true);
  }, []);

  const handleCloseJointDialog = useCallback(() => {
    setOpenJointDialog(false);
  }, []);

  return (
    <div data-testid="personal-detail-content">
      {values.map((beneficialOwner, index) => (
        <PersonDetail
          chipText={beneficialOwner?.label}
          values={beneficialOwner}
          key={`person-detail-${beneficialOwner?.id}`}
          isBeneficialOwner={false}
          chipColor="individual"
          withEdit={withEdit}
          setValues={handleChange(index)}
          certificateDownloadable
          onDelete={!readOnly && index && handleDeleteJoint}
          isJoint={index !== 0}
          {...props}
        />
      ))}

      {!readOnly && values.length < 2 && (
        <AddJointInvestorButton
          onClick={handleOpenJointDialog}
          data-testid="add-joint-button"
        />
      )}

      <AddJointInvestorDialog
        data-testid="add-joint-investor-dialog"
        open={openJointDialog}
        onClose={handleCloseJointDialog}
        onSubmit={onChange}
        userId={userId}
      />
    </div>
  );
};

export default PersonalDetails;
