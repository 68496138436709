import palette from './palette';

const components = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: palette.reach.purpleHaze,
        '&.Mui-checked': {
          color: palette.reach.purpleHaze,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 'initial',
        boxShadow: 'none',
      },
    },
  },
};

export default components;
