import React, { useCallback } from 'react';
import Button from 'components/Button';
import history from 'utils/history';

const RedirectLoginButton = ({
  label = 'Login',
  component: Component = Button,
  ...props
}) => {
  const handleLogin = useCallback(() => history.replace('/login'), []);
  return (
    <Component fullWidth onClick={handleLogin} {...props}>
      {label}
    </Component>
  );
};

export default RedirectLoginButton;
