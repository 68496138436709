import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import Footer from 'containers/Admin/Approvals/Details/Footer';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import DetailsPageContainer from 'components/DetailsPageContainer';
import FieldContainer from 'components/FieldContainer';
import Loading from 'components/Loading';
import LoadingProgressDialog from 'components/Dialog/LoadingProgressDialog';
import TabPanels from 'components/TabPanels';
import LoadingSpinner from 'components/LoadingSpinner';
import Tabs from 'components/Tabs';
import FailedDialog from 'components/Dialog/FailedDialog';

import AdminUserHelper from 'containers/Admin/Users/AddEditUserPage/HelperFunction';

import condTrueFalse from 'utils/condTrueFalse';
import getFullName from 'utils/getFullName';
import history from 'utils/history';
import mapValue from 'utils/mapValue';
import PayloadMappers from 'utils/PayloadMappers';
import ResponseMappers from 'utils/ResponseMappers';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useTabs from 'hooks/useTabs';
import useFetchApi from 'hooks/useFetchApi';
import useDialog from 'hooks/useDialog';

import { USER_STATUS } from 'constants/userStatus';
import TrashIcon from 'assets/icons/trash.svg';

import {
  adminUserVerification,
  deleteUser,
  getOrderedDocuments,
  mapDocumentsData,
  updateUserDetails,
  userDetailsTabItems,
} from './HelperFunction';
import { getStatementDocuments } from './StatementDocuments/HelperFunction';

const UserDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [refetch, setRefetch] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPreRegistered] = useState(
    searchParams.get('status') === USER_STATUS['PRE-REGISTERED'],
  );
  const [onBackRoute] = useState(history.location.state?.onBackRoute);
  const { haveCrudPermission, isSuperAdmin } = useAdminRestriction({
    isRestricted: isPreRegistered,
  });
  const { userId } = useParams();
  const documentRef = useRef();

  const {
    isDialogOpen: openDeleteSuccessDialog,
    handleOpenDialog: handleOpenDeleteSuccessDialog,
  } = useDialog();
  const {
    isDialogOpen: openConfirmDeleteDialog,
    handleOpenDialog: handleOpenConfirmDeleteDialog,
    handleCloseDialog: handleCloseConfirmDeleteDialog,
  } = useDialog();

  const fetchDataDocuments = useMemo(
    () => getStatementDocuments(userId),
    [userId, refetch],
  );

  const {
    apiData: statementDocumentsData,
    isFetchLoading: documentFetchLoading,
  } = useFetchApi({
    defaultApiData: null,
    url: fetchDataDocuments,
    dependencies: [fetchDataDocuments],
  });

  const fetchDataUser = useMemo(() => {
    const status = isPreRegistered ? USER_STATUS['PRE-REGISTERED'] : undefined;

    return AdminUserHelper.fetchUserData(userId, status);
  }, [userId, refetch]);

  const { apiData: userData, isFetchLoading: userFetchLoading } = useFetchApi({
    url: fetchDataUser,
    dependencies: [fetchDataUser],
  });

  const userDetailsForm = useFormik({
    initialValues: {
      userProfile: {},
      categories: undefined,
    },
  });

  const handleRefetch = useCallback(() => {
    // Reset Categories Values to null, to trigger the loading.
    userDetailsForm.setFieldValue('categories', undefined);
    setRefetch((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!documentFetchLoading && statementDocumentsData) {
      const mappedDocuments = statementDocumentsData?.map(mapDocumentsData);
      userDetailsForm.setFieldValue('categories', mappedDocuments);
    }
  }, [documentFetchLoading]);

  useEffect(() => {
    if (!userFetchLoading) {
      if (Object.keys(userData).length) {
        const userProfile = {
          userId: userData?.id,
          parsedMobileNumber: userData?.parsedMobileNumber,
          ...ResponseMappers.admin.user(userData),
        };

        userDetailsForm.setFieldValue('userProfile', userProfile);
      } else {
        setError('User Not Found');
      }
    }
  }, [userFetchLoading]);

  console.log(userDetailsForm.values);
  const memoizedTabItems = useMemo(() => {
    const tabItems = userDetailsTabItems({
      userId,
      setFieldValue: userDetailsForm.setFieldValue,
      readOnly: !haveCrudPermission,
      isPreRegistered,
      disabled: isPreRegistered,
    });

    tabItems[1].hidden = !userData?.statusVerification;

    return tabItems;
  }, [
    userId,
    userData?.statusVerification,
    userDetailsForm.values.userProfile,
    haveCrudPermission,
  ]);

  const defaultTabIdx = useMemo(() => {
    const index = memoizedTabItems.findIndex(
      (e) => !e.hidden && e.key === searchParams.get('tab'),
    );
    if (index === -1) return 0;
    return index;
  }, [memoizedTabItems]);

  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx,
    tabs: memoizedTabItems,
  });

  useEffect(() => {
    const currentTab = memoizedTabItems[activeTabIdx];

    const newSearchParams = {
      tab: currentTab?.key,
    };

    if (isPreRegistered) newSearchParams.status = searchParams.get('status');

    setSearchParams(newSearchParams);
  }, [activeTabIdx, memoizedTabItems]);

  useEffect(
    () => () => {
      documentRef.current?.cleanupFiles();
    },
    [documentRef],
  );

  const handleBack = useCallback(() => {
    documentRef.current?.cleanupFiles();

    const route = mapValue(onBackRoute, '/admin/users');

    history.push(route);
  }, [documentRef.current]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);

      const {
        tempDeletedFiles = new Map(),
        categories = [],
        userProfile = {},
      } = userDetailsForm.values;

      const orderedDocuments = getOrderedDocuments({
        tempDeletedFiles,
        categories,
      });

      const { password, ...restUserProfile } =
        PayloadMappers.admin.user(userProfile);

      const payload = {
        ...restUserProfile,
        categories: orderedDocuments,
      };

      const isUpdateSuccess = await updateUserDetails(userId, payload);
      if (isUpdateSuccess) {
        documentRef.current?.resetTempFiles();

        handleRefetch();
      }
    } finally {
      setLoading(false);
    }
  }, [userDetailsForm.values, documentRef.current]);

  const handleDelete = useCallback(async () => {
    handleCloseConfirmDeleteDialog();

    documentRef.current?.cleanupFiles();

    await deleteUser({
      userId,
      cb: handleOpenDeleteSuccessDialog,
      status: isPreRegistered ? USER_STATUS['PRE-REGISTERED'] : undefined,
    });
  }, [documentRef.current, isPreRegistered]);

  const handleDownload = () => true;

  const menus = useMemo(() => {
    const overseasValue = userData?.statusVerification?.includes('_OVERSEAS')
      ? '_OVERSEAS'
      : '';

    return [
      {
        itemLabel: 'Pending',
        value: `PENDING${overseasValue}`,
      },
      {
        itemLabel: 'Cancelled',
        value: `CANCELLED${overseasValue}`,
      },
      {
        itemLabel: 'Unverified',
        value: `UNVERIFIED${overseasValue}`,
      },
    ];
  }, [userData?.statusVerification]);

  const handleApproveUserVerification = useCallback(
    (params) => {
      const newStatus = userData?.statusVerification?.includes('_OVERSEAS')
        ? USER_STATUS.VERIFIED_OVERSEAS
        : USER_STATUS.VERIFIED;

      return adminUserVerification({ ...params, status: newStatus });
    },
    [userData?.statusVerification],
  );

  const isUserVerified = useMemo(
    () =>
      !![USER_STATUS.VERIFIED, USER_STATUS.VERIFIED_OVERSEAS].includes(
        userData?.statusVerification,
      ),
    [userData?.statusVerification],
  );

  const footer = useMemo(() => {
    if (searchParams.get('tab') !== 'verification') return undefined;

    return (
      <Footer
        entityId={userId}
        approvalLabel="Approve"
        menus={menus}
        onSubmitEntityApprove={handleApproveUserVerification}
        onSubmitEntityStatusChange={adminUserVerification}
        onSuccess={handleRefetch}
        downloadBtnDisabled={!haveCrudPermission}
        feedbackBtnDisabled={!haveCrudPermission}
        approvedBtnDisabled={!haveCrudPermission || isUserVerified}
      />
    );
  }, [searchParams.get('tab'), haveCrudPermission, isUserVerified]);

  return (
    <DetailsPageContainer
      onBackButtonClick={handleBack}
      onCancel={handleBack}
      backButtonLabel="User"
      onSubmitClick={handleSave}
      submitBtnLabel="Save"
      onDownloadClick={handleDownload}
      onDelete={handleOpenConfirmDeleteDialog}
      status={userData?.status}
      footer={footer}
      downloadBtnDisabled={!haveCrudPermission}
      submitBtnDisabled={!haveCrudPermission}
      deleteBtnDisabled={!isSuperAdmin}
    >
      <Grid container item rowSpacing={4}>
        <Grid container item xs={5}>
          <Grid item xs={12}>
            <FieldContainer
              label="Username:"
              labelColor="black"
              labelGridProps={{ xs: 4, justifyContent: 'right', paddingX: 2 }}
              childGridProps={{ xs: 8 }}
              rowSpacing={2}
            >
              <Typography variant="body">
                {condTrueFalse(
                  Object.keys(userData).length,
                  getFullName(userData),
                  '-',
                )}
              </Typography>
            </FieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FieldContainer
              label="Email:"
              labelColor="black"
              labelGridProps={{ xs: 4, justifyContent: 'right', paddingX: 2 }}
              childGridProps={{ xs: 8 }}
              rowSpacing={2}
            >
              <Typography variant="body">
                {mapValue(userData?.email, '-')}
              </Typography>
            </FieldContainer>
          </Grid>
        </Grid>
        <Grid container item xs={3.5}>
          <FieldContainer
            label="Joined date:"
            labelColor="black"
            labelGridProps={{ xs: 4, justifyContent: 'right', paddingX: 2 }}
            childGridProps={{ xs: 8 }}
            rowSpacing={2}
          >
            <Typography variant="body">
              {mapValue(userData?.joinedDate, '-')}
            </Typography>
          </FieldContainer>
        </Grid>
        <Grid container item xs={3.5}>
          <FieldContainer
            label="Last login:"
            labelColor="black"
            labelGridProps={{ xs: 4, justifyContent: 'right', paddingX: 2 }}
            childGridProps={{ xs: 8 }}
            rowSpacing={2}
          >
            <Typography variant="body">
              {mapValue(userData?.lastLogin, '-')}
            </Typography>
          </FieldContainer>
        </Grid>
        {userFetchLoading ||
        documentFetchLoading ||
        !userDetailsForm.values.categories ? (
          <LoadingSpinner />
        ) : (
          <Grid container item xs={12} data-testid="userDetailsTabsContents">
            <Grid item xs={12}>
              <Tabs
                key="user-details-tabs"
                value={activeTabIdx}
                onChange={handleTabChange}
                centered={false}
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
              >
                <TabComponents
                  dependencyKey={userDetailsForm.values.userProfile?.userId}
                  activeTabIdx={activeTabIdx}
                  tabItems={memoizedTabItems}
                  testIdPrefix="user-details-tab"
                />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <TabPanels
                dependencyKey={userDetailsForm.values.userProfile?.userId}
                value={activeTabIdx}
                tabItems={memoizedTabItems}
                userProfileProps={{
                  values: userDetailsForm.values.userProfile,
                }}
                documentsProps={{
                  values: userDetailsForm.values.categories,
                  ref: documentRef,
                }}
                investmentEntityProps={{
                  userData: userDetailsForm.values.userProfile,
                }}
                boxPanelProps={{ sx: { padding: 0, pt: 4 } }}
                verificationProps={{
                  values: {
                    ...userData?.userVerification,
                    statusVerification: userData?.statusVerification,
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <LoadingProgressDialog
        subtitle="User has been successfully deleted."
        open={openDeleteSuccessDialog}
        onClose={handleBack}
        withLoadingProgress={false}
        maxWidth="lg"
      />
      <ConfirmationDialog
        open={openConfirmDeleteDialog}
        onClose={handleCloseConfirmDeleteDialog}
        onConfirm={handleDelete}
        subtitle="Are you sure you want to delete this user ?"
        icon={TrashIcon}
      />
      <FailedDialog
        open={!!error}
        onConfirm={() => setError('')}
        subtitle={error}
        confirmButtonContainerProps={{ md: 12 }}
      />
      <Loading popup loading={loading} />
    </DetailsPageContainer>
  );
};

export default UserDetails;
