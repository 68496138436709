import React, { useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import FormTitle from 'components/FormTitle';
import InputField from 'components/Input';
import FieldContainer from 'components/FieldContainer';

import useInvestmentEntityDropdown from 'hooks/useInvestmentEntityDropdown';

import AddEntityDialog from '../UserDetails/InvestmentEntityList/AddEntityDialog';

const InvestorDetailsSection = ({
  onChange,
  values,
  errors,
  investmentEntities,
  userData,
}) => {
  const [openAddEntityDialog, setOpenAddEntityDialog] = useState(false);

  const handleOpenAddEntityDialog = useCallback(() => {
    setOpenAddEntityDialog(true);
  }, []);

  const handleCloseAddEntityDialog = useCallback(() => {
    setOpenAddEntityDialog(false);
  }, []);

  const { InvestmentEntityDropdownComponent } = useInvestmentEntityDropdown({
    investmentEntities,
    onClickAddInvestmentEntity: handleOpenAddEntityDialog,
  });

  const { registeredAddress = '-', email = '-' } = useMemo(() => {
    if (!investmentEntities?.length || !values.userEntity) return {};

    const entityDetails = investmentEntities.find(
      (investmentEntity) => investmentEntity.id === values.userEntity,
    );

    return {
      registeredAddress: entityDetails.address,
      email: entityDetails.email,
    };
  }, [values.userEntity, investmentEntities?.length]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormTitle title="Investor Details" />
      </Grid>

      <Grid container item xs={12} p={2} rowSpacing={3}>
        <FieldContainer
          item
          label="Entity"
          labelGridProps={{ xs: 3 }}
          childGridProps={{ container: true, xs: true, columnGap: 2 }}
        >
          <Grid item xs={6}>
            <InvestmentEntityDropdownComponent
              data-testid="fundRedemptionRequest-entity"
              placeholder="Select Entity"
              onChange={onChange}
              name="userEntity"
              value={values.userEntity}
              error={!!errors.userEntity}
              errorValue={errors.userEntity}
            />
          </Grid>
        </FieldContainer>

        <FieldContainer
          item
          label="Registered Address"
          labelGridProps={{ xs: 3 }}
          childGridProps={{ container: true, xs: true, columnGap: 2 }}
        >
          <Grid item xs={6}>
            <InputField
              fullWidth
              disabled
              data-testid="fundRedemptionRequest-registeredAddress"
              id="registeredAddress"
              name="registeredAddress"
              value={registeredAddress}
            />
          </Grid>
        </FieldContainer>

        <FieldContainer
          item
          label="Email Address"
          labelGridProps={{ xs: 3 }}
          childGridProps={{ container: true, xs: true, columnGap: 2 }}
        >
          <Grid item xs={6}>
            <InputField
              fullWidth
              disabled
              data-testid="fundRedemptionRequest-email"
              id="email"
              name="email"
              value={email}
            />
          </Grid>
        </FieldContainer>
      </Grid>

      <AddEntityDialog
        open={openAddEntityDialog}
        onClose={handleCloseAddEntityDialog}
        userData={userData}
      />
    </Grid>
  );
};

export default InvestorDetailsSection;
