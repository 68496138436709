import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Link from 'components/Link';

import { generateMailTo } from 'utils/email';
import { REACH_ADMIN_EMAIL, REACH_WEBSITE } from 'constants/general';

import InfoContainer from './InfoContainer';
import ContentContainer from '../ContentContainer';

const mailHref = generateMailTo({
  email: REACH_ADMIN_EMAIL,
  subject: '',
});

const contents = [
  {
    label: 'Address',
    contents: [
      'International Tower 3,',
      'Level 17 / 300 Barangaroo Ave,',
      'Barangaroo, Sydney ',
      'NSW 2000',
    ],
  },
  {
    label: 'Telephone',
    contents: ['+61 9000 5060.'],
  },
  {
    label: 'Website',
    contents: [
      <span>
        <Link href={REACH_WEBSITE} target="_blank">
          {REACH_WEBSITE}
        </Link>
      </span>,
    ],
  },
  {
    label: 'Email',
    contents: [
      <span>
        <Link href={mailHref} target="_blank">
          {REACH_ADMIN_EMAIL}
        </Link>
      </span>,
    ],
  },
  {
    label: '',
    contents: [
      'Reach Alternatives IM Pty Ltd trading as Reach Alternative Investments is a corporate authorised representative of Boutique Capital Pty Ltd AFSL 508011, CAR Number 1295777.',
    ],
  },
];

const ContactInformation = () => (
  <ContentContainer data-testid="tab-item-contact-information">
    <Grid container rowSpacing={3} p={4} direction="column">
      <Grid item>
        <Typography variant="display3">Contact Information</Typography>
      </Grid>
      {contents.map((content) => (
        <Grid item>
          <InfoContainer content={content} />
        </Grid>
      ))}
    </Grid>
  </ContentContainer>
);

export default memo(ContactInformation);
