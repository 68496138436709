const mapArticle = (formValues) => ({
  id: formValues.id,
  articleUid: formValues.articleUid,
  category: formValues.category,
  summary: formValues.summary,
  author: formValues.author,
  date: formValues.date,
  tags: formValues.tags,
  title: formValues.title,
  content: formValues.content,

  deletedArticleDocument: formValues.deletedArticleDocument,
  // articleHeaderImgKey: formValues.articleHeaderImg?.fileName,
  // articleLogoImgKey: formValues.articleLogoImg?.fileName,

  isHidden: formValues.isHidden,
  isFeatured: !!formValues.isFeatured,
  createdBy: formValues.createdBy,
  updatedBy: formValues.updatedBy,
});

const setArticleFilePayload = ({
  uploadFilePayload,
  articleHeaderImg,
  articleLogoImg,
  articleDocument,
}) => {
  if (articleHeaderImg)
    uploadFilePayload.push({
      key: 'articleHeaderImg',
      ...articleHeaderImg,
    });
  if (articleLogoImg)
    uploadFilePayload.push({
      key: 'articleLogoImg',
      ...articleLogoImg,
    });
  if (articleDocument) {
    uploadFilePayload.push({
      key: 'articleDocument',
      ...articleDocument,
    });
  }

  return uploadFilePayload;
};

const addArticle = (formValues) => {
  const { article } = formValues;

  const articlePayload = mapArticle({ ...article, ...formValues });

  // Set Upload File Payloads.
  const uploadFile = [];

  setArticleFilePayload({ uploadFilePayload: uploadFile, ...article });

  const payload = {
    article: articlePayload,
  };

  const formData = new FormData();
  formData.append('content', JSON.stringify(payload));

  if (uploadFile.length) {
    uploadFile.forEach(
      (file) => file.file && formData.append(file.key, file.file),
    );
  }

  return formData;
};

export default {
  article: mapArticle,
  addArticle,
};
