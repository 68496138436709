import * as yup from 'yup';

import {
  ACCOUNTANT_CERTIFICATE_MAX_FILES,
  ACCOUNTANT_CERTIFICATE_MAX_UPLOAD_SIZE,
  ACCOUNTANT_CERTIFICATE_ACCEPTED_FILE,
} from 'constants/investmentEntity';

const documentUploadValidation = yup
  .mixed()
  .test('acceptedFileType', 'Only PDF accepted.', (certificates = []) => {
    if (!certificates.length) return true;

    const filteredPdfFiles = certificates.filter((certificate) => {
      if (!certificate.blob && !certificate.file) return true; // already uploaded file
      return certificate?.blob?.type === ACCOUNTANT_CERTIFICATE_ACCEPTED_FILE;
    });
    return filteredPdfFiles.length === certificates.length;
  })
  .test('maxFileSize', 'File size is too large.', (certificates = []) => {
    if (!certificates.length) return true;
    const filteredFiles = certificates.filter((certificate) => {
      if (!certificate.blob && !certificate.file) return true; // already uploaded file
      const fileSize = certificate?.blob?.size || 0;
      const fileSizeInMega = fileSize / 1024 / 1024;
      return fileSizeInMega < ACCOUNTANT_CERTIFICATE_MAX_UPLOAD_SIZE;
    });
    // valid return true
    return filteredFiles.length === certificates.length;
  })
  .test('maxFiles', 'Only two files allowed.', (certificates = []) => {
    if (!certificates.length) return true;
    // valid return true
    return certificates.length <= ACCOUNTANT_CERTIFICATE_MAX_FILES;
  });

export default documentUploadValidation;
