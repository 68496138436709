import React, { memo, useMemo } from 'react';
import { ReactComponent as StepperFirst } from 'assets/icons/stepper-first.svg';
import { ReactComponent as StepperMiddle } from 'assets/icons/stepper-middle.svg';
import { ReactComponent as StepperLast } from 'assets/icons/stepper-last.svg';

const populateClassNameStepper = (props) => {
  const className = [];
  className.push('stepper');
  if (props.isActive) className.push('stepper-active');
  if (props.isDone) className.push('stepper-done');
  return className.join(' ');
};
const populateClassNameContent = (props) => {
  const className = [];
  className.push('stepper-container');
  if (props.isActive || props.isDone) className.push('container-done');
  return className.join(' ');
};

const StepContainer = ({ isFirst, isLast, children, ...props }) => {
  const className = useMemo(
    () => populateClassNameStepper(props),
    [props.isActive, props.isDone],
  );
  const contentClass = useMemo(
    () => populateClassNameContent(props),
    [props.isActive, props.isDone],
  );

  return (
    <>
      {isFirst && (
        <StepperFirst
          className={className}
          width="100%"
          preserveAspectRatio="none"
        />
      )}
      {isLast && (
        <StepperLast
          className={className}
          width="100%"
          preserveAspectRatio="none"
        />
      )}
      {!isFirst && !isLast && (
        <StepperMiddle
          className={className}
          width="100%"
          preserveAspectRatio="none"
        />
      )}
      <div className={contentClass}>
        <div className="stepper-label">{children}</div>
      </div>
    </>
  );
};
export default memo(StepContainer);
