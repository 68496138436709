import React, { useMemo } from 'react';
import MUICard from '@mui/material/Card';

const FundCardContainer = ({ children, disabled, maxWidth, ...props }) => {
  const cardContainerStyles = useMemo(
    () => ({
      maxWidth,
      boxShadow: disabled ? 'none' : '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
      border: '1px solid #E4E4E4',
      width: '100%',
    }),
    [disabled, maxWidth],
  );

  return (
    <MUICard sx={cardContainerStyles} {...props}>
      {children}
    </MUICard>
  );
};

export default FundCardContainer;
