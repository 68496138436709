import React from 'react';
import Typography from '@mui/material/Typography';

import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';

import services from 'api/services';

import Event from './Event';

export const eventTabItems = (props = {}) => [
  {
    key: 'event',
    Icon: PersonIcon,
    label: <Typography variant="h6">Event</Typography>,
    content: <Event fieldKey="event" {...props} />,
  },
];

export const fetchAdminEventDetails = async (id) => {
  try {
    if (!id) return {};

    const res = await services.adminGetEventDetails(id);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const sendAddEvent = async ({ payload }) => {
  try {
    const res = await services.adminAddEvent(payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const editEvent = async ({ id, payload }) => {
  try {
    const res = await services.adminEditEvent(id, payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};
