import React, { useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';

import Dropdown from 'components/Dropdown';

import PayloadMappers from 'utils/PayloadMappers';
import addTrusteeValidationSchema from 'validations/schema/adminUpdateEntity/addTrusteeValidationSchema';

import { getInitialValuesByType } from '../../DetailsTab/HelperFunction';
import AddEntityDialog from '../../DetailsTab/AddEntityDialog';

const TypeOptions = [
  { label: 'Individual', value: 'trusteeIndividual' },
  { label: 'Company', value: 'trusteeCompany' },
];

const AddTrusteeDialog = ({ open, onClose, onSubmit, ...props }) => {
  const [type, setType] = useState('');

  const handleTypeChange = useCallback((e) => {
    setType(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    ({ certificates = [], ...values }) => {
      const fileUploadKey =
        type === 'trusteeCompany'
          ? 'companyCertificate'
          : 'individualCertificate';
      const content =
        type === 'trusteeCompany'
          ? { addCompanyTrustee: PayloadMappers.companyDetails(values) }
          : { addIndividualTrustee: PayloadMappers.personalDetails(values) };
      onSubmit({
        fileUpload: certificates,
        fileUploadKey,
        ...content,
      });
    },
    [type],
  );

  const initialValues = useMemo(() => getInitialValuesByType(type), [type]);

  const titleFormComponent = useMemo(
    () => (
      <Grid container spacing={0.25} padding={1.5}>
        <Grid item xs={12}>
          <Typography variant="h4">Trustee Type</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ margin: '12px 0 18px' }} />
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={2} padding={2}>
            <FormLabel>Type</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name="signedBy"
              value={type}
              onChange={handleTypeChange}
              placeholder="Select Trustee Type"
              fullWidth
              options={TypeOptions}
            />
          </Grid>
        </Grid>
      </Grid>
    ),
    [type, handleTypeChange],
  );

  return (
    <AddEntityDialog
      open={open}
      onClose={onClose}
      type={type}
      title="Add Trustees"
      titleFormComponent={titleFormComponent}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={addTrusteeValidationSchema(type)}
      {...props}
    />
  );
};
export default AddTrusteeDialog;
