import * as yup from 'yup';

import individualFormValidationSchema from './individualFormValidationSchema';
import addressValidationSchema from './addressValidationSchema';

const auPassportValidationSchema = yup.object({
  passportNumber: yup.string().required('Passport Number is required.'),
  gender: yup.string().required('Gender is required.'),
  ...individualFormValidationSchema(),
  ...addressValidationSchema(),
});

export default auPassportValidationSchema;
