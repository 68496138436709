import React, { useCallback, useEffect } from 'react';

import Grid from '@mui/material/Grid';

import FormFields from 'containers/DashboardPage/components/CompleteProfileDialog/FormFields';
import FormTitle from 'components/FormTitle';

import condTrueFalse from 'utils/condTrueFalse';
import getNumbers from 'utils/strings/getNumbers';
import thousandSeparator from 'utils/strings/thousandSeparators';
import {
  getAccessFee,
  getAmountPayableLater,
  getAmountPayableToday,
  getAmountReceived,
  getTotal,
  getUnits,
} from 'hooks/useInvestmentAmountInfo';
import { PAY_IN_INSTALLMENT } from 'constants/investmentEntity';

import { paymentDetailsFormFields } from './HelperFunction';

const PaymentDetailsSection = ({
  onChange,
  values,
  errors,
  setValues,
  fundConfiguration,
  touched,
  onBlur,
}) => {
  const handleChange = useCallback(
    (e) => {
      if (e.target.name !== 'totalInvestment') {
        onChange(e);
        return;
      }

      const investmentAmount = getNumbers(e.target.value);

      // Calculate Units
      const units = getUnits({
        investmentAmount,
        sharePrice: fundConfiguration.sharePrice,
      });

      // Calculate Access Fee
      const accessFee = getAccessFee({
        accessFee: fundConfiguration.accessFee,
        investmentAmount,
      });

      // Calculate Amount Payable Today.
      const amountPayableToday = getAmountPayableToday({
        isPayInInstallment: values.paymentType === PAY_IN_INSTALLMENT,
        fundConfiguration,
        investmentAmount,
      });

      // Calculate Amount Received.
      const amountReceived = getAmountReceived({
        amountPayableToday,
        accessFee,
        applicationFee: fundConfiguration.applicationFee,
      });

      // Calculate Amount Payable Later.
      const amountPayableLater = getAmountPayableLater({
        investmentAmount,
        amountPayableToday,
      });

      // Calculate Total.
      const total = getTotal({
        investmentAmount,
        accessFee,
        applicationFee: fundConfiguration.applicationFee,
      });

      setValues({
        ...values,
        totalInvestment: e.target.value,
        units: thousandSeparator(units),
        accessFee: thousandSeparator(accessFee),
        amountPayableToday: thousandSeparator(amountPayableToday),
        amountReceived: thousandSeparator(amountReceived),
        amountPayableLater: thousandSeparator(amountPayableLater),
        total: thousandSeparator(total),
      });
    },
    [fundConfiguration, onChange, values],
  );

  useEffect(() => {
    if (!fundConfiguration.callable) return;

    const event = {
      target: {
        name: 'totalInvestment',
        value: values.totalInvestment,
      },
    };
    handleChange(event);
  }, [values.paymentType]);

  useEffect(() => {
    if (!fundConfiguration.callable) return;

    const event = {
      target: {
        name: 'totalInvestment',
        value: '0',
      },
    };
    handleChange(event);
  }, [fundConfiguration.id]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormTitle title="Payment Details" />
      </Grid>

      <Grid container item xs={12} p={2} rowSpacing={3}>
        {paymentDetailsFormFields.map(({ fieldProps, ...formField }) => (
          <FormFields
            key={formField.name}
            {...formField}
            fieldProps={{
              ...condTrueFalse(fieldProps, () => fieldProps(values), {}),
              'data-testid': `paymentDetailsFormField-${formField.name}`,
              currency:
                formField.fieldType === 'currencyInput'
                  ? fundConfiguration.dealCurrency
                  : undefined,
            }}
            onChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
            onBlur={onBlur}
            gridProps={{
              labelGridProps: { xs: 3 },
              childGridProps: { xs: 4.5 },
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default PaymentDetailsSection;
