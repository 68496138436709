import React, { memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

const YesNoQuestion = ({
  name,
  value = null,
  onChange,
  alwaysExecuteOnChange = false,
  label,
  errorValue = null,
  disabledYes = false,
  disabledNo = false,
  disabled,
}) => {
  //  has 3 values consist of true, false, and null
  const handleChange = useCallback(
    (e) => {
      const val = {
        target: {
          name,
          value: e.target.name === 'yesAnswer',
        },
      };
      if (e.target.checked || alwaysExecuteOnChange) {
        onChange(val);
      }
    },
    [value],
  );

  return (
    <Grid justifyContent="space-between">
      <Grid item>{label}</Grid>
      <Grid item container direction="row" textAlign="left">
        <Grid item xs={6} md={6}>
          <FormControlLabel
            disabled={disabled || disabledYes}
            control={
              <Checkbox
                data-testid={`${name}-checkbox-item-yes`}
                checked={value === true}
                name="yesAnswer"
                onChange={handleChange}
              />
            }
            label="Yes"
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControlLabel
            disabled={disabled || disabledNo}
            control={
              <Checkbox
                data-testid={`${name}-checkbox-item-no`}
                checked={value === false}
                name="noAnswer"
                onChange={handleChange}
              />
            }
            label="No"
          />
        </Grid>
        <FormHelperText error={!!errorValue}>{errorValue}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default memo(YesNoQuestion);
