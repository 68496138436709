import React, { useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DoughnutChart from 'components/Chart/DoughnutChart';
import Dropdown from 'components/Dropdown';

import formatCurrency from 'utils/formatCurrency';
import config from 'utils/config';

import palette from 'theme/palette';

const drawInnerText = (chart) => {
  const { width, height, ctx } = chart;

  ctx.restore();
  const fontSize = (height / 160).toFixed(2);
  ctx.font = `${fontSize}em sans-serif`;
  ctx.textBaseline = 'middle';

  const { text } = chart.config.options.centerText;
  const textX = Math.round((width - ctx.measureText(text).width) / 2.65);
  const textY = height / 2;

  ctx.fillText(text, textX, textY);
  ctx.save();
};

const plugins = [
  {
    beforeDraw: (chart) => {
      if (
        chart.config.options.centerText.display !== null &&
        typeof chart.config.options.centerText.display !== 'undefined' &&
        chart.config.options.centerText.display
      ) {
        drawInnerText(chart);
      }
    },
  },
];

const defaultColor = [
  '#D9D9D9',
  palette.reach.fuchsia,
  '#2350BA',
  palette.reach.blueLight,
  palette.reach.yellowWarning,
];

const DEFAULT_CURRENCY = 'default';

const currencies = [
  {
    value: 'AUD',
    label: 'Portfolio Value (est., AUD)',
  },
  {
    value: 'USD',
    label: 'Portfolio Value (est., USD)',
  },
  {
    value: DEFAULT_CURRENCY,
    label: 'Portfolio Value (est., fund currency)',
  },
];

const PortfolioOverviewChart = ({ portfolioOverview = [] }) => {
  const currencyData = useMemo(() => config.system.currencyData, []);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0].value);

  const chartData = useMemo(
    () =>
      portfolioOverview.map((portfolio, index) => {
        const getPortfolioValue = () => {
          if (selectedCurrency === DEFAULT_CURRENCY)
            return portfolio.investmentValue;

          return (
            portfolio.investmentValue *
            (currencyData[portfolio?.fundConfiguration?.dealCurrency]?.[
              selectedCurrency
            ] || 1)
          );
        };

        return {
          value: getPortfolioValue(),
          label: portfolio?.fund?.fundName,
          color: defaultColor[index],
        };
      }),
    [portfolioOverview, selectedCurrency],
  );

  const estValues = chartData.reduce(
    (values, portfolio) => values + portfolio.value,
    0,
  );

  const handleChangeCurrency = useCallback(
    (e) => setSelectedCurrency(e.target.value),
    [],
  );

  const portfolioValueByCurrency = useMemo(
    () =>
      portfolioOverview.reduce(
        (byCurrency, portfolio) =>
          byCurrency.set(
            portfolio?.fundConfiguration?.dealCurrency,
            (byCurrency.get(portfolio?.fundConfiguration?.dealCurrency) || 0) +
              portfolio.investmentValue,
          ),
        new Map(),
      ),
    [portfolioOverview],
  );

  const chartTitle = useMemo(
    () =>
      selectedCurrency !== DEFAULT_CURRENCY ? (
        <Typography variant="display3">
          {formatCurrency({ currency: selectedCurrency, value: estValues })}
        </Typography>
      ) : (
        [...portfolioValueByCurrency.keys()].sort().map((currencyKey) => (
          <Typography variant="display3" display="block">
            {formatCurrency({
              currency: currencyKey,
              value: portfolioValueByCurrency.get(currencyKey),
            })}
          </Typography>
        ))
      ),
    [portfolioValueByCurrency, selectedCurrency, estValues],
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12} md={3.5} alignContent="center" textAlign="center">
        {chartTitle}
        <Dropdown
          options={currencies}
          value={selectedCurrency}
          onChange={handleChangeCurrency}
          maxheight="44px"
          disableUnderline
        />
      </Grid>
      <Grid item xs={12} md={8.5} pr={{ xs: 0, lg: '5%' }}>
        <DoughnutChart
          data={chartData}
          style={{ marginTop: '16px', maxHeight: '200px' }}
          options={{
            cutout: 70,
            plugins: {
              borderWidth: 0,
              legend: {
                position: 'right',
                labels: {
                  boxWidth: 28,
                  boxHeight: 28,
                  padding: 10,
                  // font: {
                  //   size: 34
                  // },
                },
                fullWidth: false,
                align: 'middle',
                responsive: false,
              },
              title: {
                display: false,
                text: chartTitle,
                color: 'black',
                fontFamily: 'Inter',
                font: {
                  size: 18,
                },
              },
              subtitle: {
                display: false,
                text: 'Est. Portfolio Value',
              },
            },
            centerText: {
              display: false,
              text: 'Est. Portfolio Value',
            },
          }}
          plugins={plugins}
        />
      </Grid>
    </Grid>
  );
};

export default PortfolioOverviewChart;
