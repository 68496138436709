import React from 'react';

import { Typography } from '@mui/material';

const note1 =
  'The Internal Rate of Return (IRR) is a key performance measure for closed-end funds and is calculated on a net basis (after fees). The IRR measures the annualised rate of return of the fund, taking into account the timing and amount of all cash flows (both inflows and outflows). It represents the discount rate that makes the net present value (NPV) of all cash flows from the investment equal to zero. For example, an IRR of 12% means that the investment has generated a 12% annual return over its lifespan. It helps you understand the efficiency and profitability of your investment, factoring in the time value of money. To assist, we also provide a benchmark against a Public Market Equivalent (PME). This allows you to understand how your investment is doing relative to public markets.';
const note2 =
  "Please note, the return is ‘annualised’ and, therefore, at the start of an investment, this will not reflect actual returns. In addition, during the investment phase, returns will likely be lower (or negative) due to the time required for investments to mature and generate returns. Consequently, early performance should not be seen as indicative of the fund's long-term potential, with returns likely escalating towards the end of the fund’s lifecycle. Past performance is not a guarantee nor necessarily a reliable indicator of future performance.";

const Notes = () => (
  <>
    <Typography fontWeight={700}>Notes:</Typography>
    <br />
    <Typography>{note1}</Typography>
    <br />
    <Typography>{note2}</Typography>
  </>
);

export default Notes;
