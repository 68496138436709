import countries from 'utils/countries';

const countryOptions = (region = '') =>
  countries
    .filter((c) => {
      if (region) return region === c.region;
      return true;
    })
    .map((c) => ({
      label: c.label,
      value: c.code,
    }));

export default countryOptions;
