import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import Footer from 'containers/Admin/Approvals/Details/Footer';
import ImageCarousel from 'components/ImageCarousel';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import UploadImageSliderList from 'components/UploadImageSliderList';
import Loading from 'components/Loading';

import useFetchApi from 'hooks/useFetchApi';
import useDialog from 'hooks/useDialog';
import useAdminRestriction from 'hooks/useAdminRestriction';

import {
  fetchAdminImageSliders,
  updateAdminImageSliders,
} from './HelperFunction';

const SliderSettings = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);

  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  const { apiData } = useFetchApi({
    fetchService: fetchAdminImageSliders,
    defaultApiData: [],
  });

  useEffect(() => {
    if (!apiData.length) return;

    setImages(apiData);
  }, [apiData]);

  const handleAddImage = useCallback((newImage) => {
    setImages((prevCategoriesState) => [
      ...prevCategoriesState,
      { ...newImage },
    ]);
  }, []);

  const handleDeleteImage = useCallback(({ index }) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index].isDeleted = true;

      return newImages;
    });

    // setReinitializeCategory(true);
  }, []);

  const handleChangeImage = useCallback(({ index, newFiles }) => {
    setImages((prevImages) => {
      const newFile = newFiles?.[0];

      if (!newFile) return prevImages;

      const newImages = [...prevImages];
      newImages[index].fileName = newFile.fileName;
      newImages[index].file = newFile.file;
      newImages[index].image = newFile.url;

      return newImages;
    });
  }, []);

  const handleChangeContent = useCallback(({ index, e }) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index].content = e?.target?.value;

      return newImages;
    });
  }, []);

  const handleUpdateSliderImage = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await updateAdminImageSliders(images);

      setImages(res.data);

      handleOpenDialog();
    } finally {
      setIsLoading(false);
    }
  }, [images]);

  const imageList = useMemo(
    () =>
      images.reduce((_imageList, image, index) => {
        if (image.isDeleted) return _imageList;

        return _imageList.concat({ ...image, index });
      }, []),
    [images],
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item width="100%" px={2}>
        <ImageCarousel images={imageList} srcKey="image" />
      </Grid>

      <UploadImageSliderList
        images={imageList}
        setImages={setImages}
        onAddImage={handleAddImage}
        onDeleteImage={handleDeleteImage}
        onChangeImage={handleChangeImage}
        onChangeContent={handleChangeContent}
        disabled={!haveCrudPermission}
      />

      <Footer
        approvalLabel="Save"
        withFeedbackBtn={false}
        onApprovalClick={handleUpdateSliderImage}
        approvedBtnDisabled={isLoading || !haveCrudPermission}
        // menus={menus}
        // onSubmitEntityApprove={handleApproveEntity}
        // onSubmitEntityStatusChange={adminUserVerification}
        // downloadBtnDisabled={!haveCrudPermission}
        // feedbackBtnDisabled={!haveCrudPermission}
        // approvedBtnDisabled={!haveCrudPermission}
      />

      <SuccessDialog
        open={isDialogOpen}
        onConfirm={handleCloseDialog}
        subtitle="Image slider updated successfully!"
      />

      <Loading loading={isLoading} popup />
    </Grid>
  );
};

export default SliderSettings;
