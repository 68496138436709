import React from 'react';

import Grid from '@mui/material/Grid';

import ArticleSection from './ArticleSection';
import EventSection from './EventSection';
import VideoSection from './VideoSection';

const AdminArticlesEvents = () => (
  <Grid container rowSpacing={3}>
    <Grid item xs={12}>
      <ArticleSection />
    </Grid>
    <Grid item xs={12}>
      <EventSection />
    </Grid>
    <Grid item xs={12}>
      <VideoSection />
    </Grid>
  </Grid>
);

export default AdminArticlesEvents;
