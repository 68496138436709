import { useMemo } from 'react';

import calculateValueByPercentage from 'utils/calculateValueByPercentage';
import formatCurrency from 'utils/formatCurrency';
import getNumbers from 'utils/strings/getNumbers';

export const getAmountPayableToday = ({
  isPayInInstallment,
  investmentAmount,
  fundConfiguration,
}) => {
  let payableToday = investmentAmount;

  if (isPayInInstallment) {
    payableToday = calculateValueByPercentage({
      value: investmentAmount,
      percentage: fundConfiguration.callable,
    });
  }

  return payableToday;
};

export const getAccessFee = ({ accessFee, investmentAmount }) =>
  Math.round(
    calculateValueByPercentage({
      value: investmentAmount,
      percentage: accessFee,
    }),
  );

export const getUnits = ({ investmentAmount, sharePrice = 1 }) =>
  (getNumbers(investmentAmount) / sharePrice).toFixed(0);

export const getAmountReceived = ({
  amountPayableToday,
  accessFee,
  applicationFee = 0,
}) => amountPayableToday + accessFee + getNumbers(applicationFee);

export const getAmountPayableLater = ({
  investmentAmount,
  amountPayableToday,
}) => investmentAmount - amountPayableToday;

export const getTotal = ({ investmentAmount, accessFee, applicationFee }) =>
  investmentAmount + accessFee + getNumbers(applicationFee);

const getPaymentAmountInfos = ({
  isPayInInstallment,
  investmentAmount,
  fundConfiguration,
}) => [
  {
    key: 'minPayment',
    label: 'Minimum payment amount',
    variant: 'display4',
    show: !!isPayInInstallment,
    value: `${fundConfiguration.callable}%`,
  },
  {
    key: 'payable',
    label: 'Amount payable',
    variant: 'display4',
    value: formatCurrency({
      currency: fundConfiguration.dealCurrency,
      value: getAmountPayableToday({
        isPayInInstallment,
        investmentAmount,
        fundConfiguration,
      }),
    }),
  },
  {
    key: 'accessFee',
    label: '+ access fee',
    variant: 'h4',
    value: formatCurrency({
      currency: fundConfiguration.dealCurrency,
      value: calculateValueByPercentage({
        value: investmentAmount,
        percentage: fundConfiguration.accessFee,
      }),
    }),
  },
  {
    key: 'applicationFee',
    label: '+ application fee',
    variant: 'h4',
    value: formatCurrency({
      currency: fundConfiguration.dealCurrency,
      value: fundConfiguration?.applicationFee,
    }),
  },
];

const getInstallmentInfos = ({
  accessFee,
  totalPayableToday,
  investmentAmount,
  dealCurrency,
}) => [
  {
    key: 'payableLater',
    label: 'Amount that will be payable later',
    variant: 'h5',
    value: `${formatCurrency({
      currency: dealCurrency,
      value: investmentAmount - totalPayableToday,
    })}`,
  },
  {
    key: 'total',
    label: 'Total',
    variant: 'h5',
    value: `${formatCurrency({
      currency: dealCurrency,
      value: investmentAmount + accessFee,
    })}`,
  },
];

const useInvestmentAmountInfo = ({
  isPayInInstallment,
  investmentAmount,
  fundConfiguration,
}) =>
  useMemo(() => {
    const paymentAmountInfos = getPaymentAmountInfos({
      isPayInInstallment,
      investmentAmount,
      fundConfiguration,
    });

    const accessFee = calculateValueByPercentage({
      value: investmentAmount,
      percentage: fundConfiguration.accessFee,
    });

    // Total Payable Today.
    let totalPayableToday = getNumbers(investmentAmount);

    if (isPayInInstallment) {
      totalPayableToday = calculateValueByPercentage({
        value: investmentAmount,
        percentage: fundConfiguration.callable,
      });
    }

    // Installment Infos.
    const installmentInfos = getInstallmentInfos({
      accessFee,
      dealCurrency: fundConfiguration.dealCurrency,
      investmentAmount,
      totalPayableToday,
    });

    // Add Total Payable Today with Access Fee.
    totalPayableToday += accessFee;

    // Add Total Payable Today with Application Fee.
    totalPayableToday += Number(fundConfiguration?.applicationFee);

    return {
      paymentAmountInfos,
      accessFee,
      totalPayableToday: formatCurrency({
        currency: fundConfiguration?.dealCurrency,
        value: totalPayableToday,
      }),
      installmentInfos,
    };
  }, [isPayInInstallment, investmentAmount, fundConfiguration]);

export default useInvestmentAmountInfo;
