import React from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Checkbox from 'components/Checkbox';

const SignSection = ({
  values,
  onChange,
  signedBy,
  signedAt = new Date(),
  errors,
  touched,
  onBlur,
}) => (
  <Grid container item rowGap={3} sx={{ backgroundColor: '#F6F6F6' }} py={3}>
    <Grid item container xs={12} alignItems="center" pl={2.5}>
      <FormLabel>Name of person submitting this declaration:</FormLabel>
    </Grid>
    <Grid item xs={12} pl={2.5}>
      <Typography variant="body" fontWeight={700} mr={8}>
        {signedBy}
      </Typography>
      <Typography variant="body">
        Date :{' '}
        {signedAt?.toLocaleDateString('en-AU', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </Typography>
    </Grid>
    <Grid item xs={12} pl={2.5}>
      <Checkbox
        label={
          <Typography variant="verificationLink">
            By submitting this application:
          </Typography>
        }
        checked={values?.isSigned}
        name="isSigned"
        onChange={onChange}
        error={touched?.isSigned && errors?.isSigned}
        onBlur={onBlur}
        sx={{}}
      />
    </Grid>
    <Grid item xs={12} pl={2.5}>
      <Typography variant="body">
        I undertake to provide a suitably updated self-certification within 30
        days of any change in circumstances which causes the information
        contained herein to become incorrect. I delcare the information above to
        be true and correct.
      </Typography>
    </Grid>
  </Grid>
);

export default SignSection;
