export const ROLE_USER = 'USER';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_SUPER_ADMIN = 'SUPER_ADMIN';

export const USER_ROLES_OPTIONS = [
  {
    label: 'User',
    value: ROLE_USER,
  },
  {
    label: 'Admin',
    value: ROLE_ADMIN,
  },
  {
    label: 'Super Admin',
    value: ROLE_SUPER_ADMIN,
  },
];

export const MY_DOCUMENTS = 'My Documents';
