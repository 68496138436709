import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Link from 'components/Link';
import { REACH_WEBSITE } from 'constants/general';

import ContentContainer from '../ContentContainer';

const quoteContent =
  '“There is an increasing push globally to gain access to proven private equity managers. We are pleased to be working alongside Reach Alternative Investments, who are pioneering this movement in Australia.”';

const quoteOwner =
  'Harry O’Neill, Client Relations and Capital Raising, EQT Group.';

const contents = [
  'Reach Alternative Investments provides access to the world’s most exclusive institutional-grade private equity and private capital funds run by the world’s largest asset managers. Generally, these funds have only been offered only to institutional investors.',
  'Reach Alternative Investments has managed to build strong relationships with the world’s largest asset managers. We work closely with the fund manager to select funds that meet our investment criteria. Our technology platform provides an end-to-end digital experience.',
  <span>
    To learn more, you can view our company profile in each fund data room,
    visit our website at{' '}
    <Link href={REACH_WEBSITE} target="_blank">
      {REACH_WEBSITE}
    </Link>{' '}
    or give us a call on +61 9000 5060.
  </span>,
];

const About = () => (
  <ContentContainer data-testid="tab-item-about">
    <Grid container rowSpacing={3} p={4}>
      <Grid item>
        <Typography variant="display3">
          About Reach Alternative Investments
        </Typography>
      </Grid>
      <Grid item pl={[0, 5]}>
        <Typography variant="caption" fontStyle="italic">
          {quoteContent}
        </Typography>
      </Grid>
      <Grid item pl={[0, 5]}>
        <Typography variant="caption">{quoteOwner}</Typography>
      </Grid>
      {contents.map((content) => (
        <Grid item>
          <Typography variant="body" fontFamily="PT Serif">
            {content}
          </Typography>
        </Grid>
      ))}
    </Grid>
  </ContentContainer>
);

export default memo(About);
