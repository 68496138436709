import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import MUIInput from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { InputAdornment } from '@mui/material';

import condTrueFalse from 'utils/condTrueFalse';
import isNumber from 'utils/isNumber';
import isFunction from 'utils/isFunction';
import formatMaskedValue from 'utils/formatMaskedValue';
import thousandSeparator from 'utils/strings/thousandSeparators';
import getNumbers from 'utils/strings/getNumbers';

import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

const fieldsetStyle = ({ theme, inputtype }) => {
  if (inputtype === 'success') {
    return {
      border: `1px solid ${theme.palette.reach.green}`,
    };
  }
  if (inputtype === 'error') {
    return {
      border: `1px solid ${theme.palette.reach.red}`,
    };
  }

  return {};
};

const StyledInput = styled(MUIInput)(
  ({ theme, minheight, fontweight = undefined, ...props }) => ({
    boxSizing: 'border-box',
    '& .MuiOutlinedInput-root': {
      minHeight: minheight || '54px',
      background: theme.palette.reach.white,
      fontWeight: fontweight,
      borderRadius: '0px',
      '& fieldset': {
        border: `1px solid ${theme.palette.text.fuchsia}`,
        ...fieldsetStyle({ theme, ...props }),
      },
      '&:hover fieldset': {
        border: !props.disabled && `2px solid ${theme.palette.text.fuchsia}`,
      },
      '&.Mui-disabled fieldset': props.inputtype === 'success' && {
        border: `3px solid ${theme.palette.reach.green}`,
      },
      '&:valid fieldset': {
        border: `2px solid ${theme.palette.reach.green}`,
      },
      '&:invalid fieldset': {
        border: `2px solid ${theme.palette.reach.red}`,
      },
      '&.Mui-focused fieldset': {
        border: `2px solid ${theme.palette.text.fuchsia}`,
      },
      '&.Mui-disabled': {
        // background: theme.palette.form.disabled,
        border: `1px solid ${theme.palette.form.disabledBorder}`,
        color: '#959fb1 !important',
      },
    },
  }),
);

const InputField = ({
  value = '',
  errorValue,
  withEndAdornment = true,
  showIcon = false,
  icon = 'error',
  InputProps,
  onChange,
  name,
  mask,
  maxLength,
  maskInterval,
  withThousandSeparator,
  uncontrolled,
  onBlur,
  allowMinus,
  ...props
}) => {
  const [errorIconVisible, setErrorIconVisible] = useState(false);

  const handleChangeInput = useCallback(
    (e) => {
      if (!onChange) return;

      const newValue = e.target.value;
      const isInvalidPhoneNumber =
        props?.type === 'phoneNumber' ? newValue?.startsWith('0') : false;
      const isNumberType = ['phoneNumber', 'number'].includes(props?.type);

      if (mask) {
        e.target.value = formatMaskedValue({
          value: newValue,
          event: e,
          mask,
          maxLength,
          maskInterval,
          isNumberType,
        });
      } else if (
        // skip if the value is not a number or it prefixed with 0,
        // to avoid invalid phone number (RA-189)
        isNumberType &&
        (!isNumber(newValue, { allowMinus }) || isInvalidPhoneNumber) &&
        newValue !== ''
      ) {
        return;
      }

      onChange(e);
    },
    [onChange, mask],
  );

  const handleKeyDown = useCallback((e) => {
    if (!mask) return e;

    e.target.skipMaskPointer = e.keyCode === 8;
    e.target.isPaste = e.keyCode === 86 && e.metaKey;

    return e;
  }, []);

  useEffect(() => {
    setErrorIconVisible(!!errorValue);
  }, [errorValue]);

  const adornmentIcon = useMemo(() => {
    const iconProps = {
      width: '24px',
      height: '24px',
    };

    switch (icon) {
      case 'success':
        return <CheckIcon style={iconProps} />;
      case 'warning':
        return <WarningIcon style={iconProps} />;
      default:
        return <ErrorIcon style={iconProps} />;
    }
  }, [icon]);

  const iconVisible = useMemo(
    () => showIcon || errorIconVisible,
    [showIcon, errorIconVisible],
  ); // by default icon shown by error, but can be forcefully shown by showIcon props

  const type = useMemo(
    () =>
      condTrueFalse(
        props?.type === 'number',
        'text',
        condTrueFalse(props?.type === 'phoneNumber', 'tel', props?.type),
      ),
    [props?.type],
  );

  const handleBlur = useCallback(
    (e) => {
      if (isFunction(onBlur)) onBlur(e);

      if (!value || !withThousandSeparator) return;

      const event = {
        target: {
          name,
          value: thousandSeparator(parseFloat(value)),
        },
      };

      onChange(event);
    },
    [value, onChange, withThousandSeparator, onBlur],
  );

  const handleFocus = useCallback(() => {
    if (!value || !withThousandSeparator) return;

    const event = {
      target: {
        name,
        value: getNumbers(value, { allowMinus }),
      },
    };

    onChange(event);
  }, [value, onChange, withThousandSeparator, allowMinus]);

  return (
    <StyledInput
      value={uncontrolled ? undefined : value || ''}
      name={name}
      variant="outlined"
      error={errorIconVisible}
      helperText={errorValue}
      InputProps={{
        endAdornment: withEndAdornment && iconVisible && adornmentIcon && (
          <InputAdornment position="end">{adornmentIcon}</InputAdornment>
        ),
        ...InputProps,
      }}
      onChange={handleChangeInput}
      onKeyDown={handleKeyDown}
      {...props}
      type={type}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

export default memo(InputField);
