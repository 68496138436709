import React from 'react';

import Grid from '@mui/material/Grid';

import AdminPageContainer from 'components/AdminPageContainer';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import TotalHeaders from 'components/TotalHeaders';

import history from 'utils/history';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';

import {
  fetchAdminFunds,
  reportingHeaders,
  getFundTotalHeader,
} from './HelperFunction';

const redirectToReportingDetail = ({ row }) => {
  history.push(`/admin/reporting/${row.id}`, {
    onBackRoute: '/admin/reporting',
  });
};

const ReportingSection = () => {
  const { haveCrudPermission } = useAdminRestriction();

  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: fetchAdminFunds,
    defaultApiData: {},
  });

  return (
    <AdminPageContainer
      pageTitle="Reporting"
      withFilter={false}
      withSearch={false}
    >
      <TotalHeaders
        exportLabel=""
        totals={getFundTotalHeader(apiData.totalItems)}
      />
      <Grid item xs={12}>
        <DataTable
          wrapheader
          withCheckbox={false}
          height="656px"
          headerData={reportingHeaders}
          data={apiData.items}
          setSort={setSort}
          isLoading={isFetchLoading}
          totalData={apiData.totalItems}
          containerProps={{ backgroundColor: 'white' }}
          onRowClick={redirectToReportingDetail}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Grid>

      <Loading loading={isFetchLoading} popup />
    </AdminPageContainer>
  );
};

export default ReportingSection;
