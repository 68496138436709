import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

import CardCarousel from 'components/CardCarousel';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';

import ArticleCard from './ArticleCard';
import { fetchArticles } from './HelperFunction';

const height = '280px';

const routeToArticleDetail = (articleId) =>
  history.push(`/articles/${articleId}`);

const MoreArticles = () => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const getArticles = useMemo(() => fetchArticles(), []);
  const { apiData: data } = useFetchApi({
    url: getArticles,
  });

  const articles = useMemo(
    () =>
      data?.map?.((article) => (
        <ArticleCard
          onClick={() => routeToArticleDetail(article.id)}
          height={height}
          articleDate={article.date}
          category={article.category}
          imageSrc={article.articleLogoImgUrl || article.articleHeaderImgUrl}
          name={article.title}
        />
      )),
    [data],
  );

  const numberOfCardPerSlide = useMemo(() => {
    switch (true) {
      case isMobileScreen:
        return 1.25;
      case isTabletScreen:
        return 2.25;
      default:
        return 4;
    }
  }, [isMobileScreen, isTabletScreen]);

  return (
    <Grid container xs={12} minHeight={height} height="100%">
      <CardCarousel
        cards={articles}
        numberOfCardPerSlide={numberOfCardPerSlide}
      />
    </Grid>
  );
};

export default memo(MoreArticles);
