import companyTaxListValidationSchema from './companyTaxListValidationSchema';
import companyTaxSummaryValidationSchema from './companyTaxSummaryValidationSchema';

const companyTaxDetailsValidationSchemas = [
  companyTaxListValidationSchema,
  // tax supporting information has own form
  null,
  companyTaxSummaryValidationSchema,
];

export default companyTaxDetailsValidationSchemas;
