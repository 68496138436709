import React, { memo, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import history from 'utils/history';
import useDashboardLoading from 'hooks/useDashboardLoading';

import FundCardsGrid from './FundCardsGrid';
import FundDetail from './FundDetail';

const FundsTab = ({ handleRedirect, onOpenCompleteProfileDialog }) => {
  const [searchParams] = useSearchParams();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { handleSetIsFetched } = useDashboardLoading();

  const currentFundId = useMemo(
    () => searchParams.get('fundId'),
    [searchParams.get('fundId')],
  );

  const handleViewFundDetail = useCallback(
    (fundId) => () => {
      handleSetIsFetched(false);
      history.push(`?tab=funds&fundId=${fundId}`);
    },
    [],
  );

  const handleBack = useCallback(() => {
    handleSetIsFetched(false);
    history.push('?tab=funds');
  }, []);

  const fundCardMode = useMemo(
    () => (isMobileScreen ? 'portrait' : 'landscape'),
    [isMobileScreen],
  );

  return currentFundId ? (
    <FundDetail
      fundId={currentFundId}
      handleBack={handleBack}
      handleRedirect={handleRedirect}
      onOpenCompleteProfileDialog={onOpenCompleteProfileDialog}
    />
  ) : (
    <FundCardsGrid
      handleViewFundDetail={handleViewFundDetail}
      mode={fundCardMode}
    />
  );
};

export default memo(FundsTab);
