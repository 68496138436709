import condTrueFalse from './condTrueFalse';
import isFunction from './isFunction';

const showFieldWhen = ({ showWhen = [], values = {}, isEdit }) =>
  showWhen.every(({ key: keyDependencies, value: valueDependencies }) =>
    condTrueFalse(
      isFunction(valueDependencies),
      () => valueDependencies(values?.[keyDependencies], values, isEdit),
      values?.[keyDependencies] === valueDependencies,
    ),
  );

export default showFieldWhen;
