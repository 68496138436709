import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';

import ResponseMappers from 'utils/ResponseMappers';
import adminArticleValidationSchema from 'validations/schema/admin/adminArticleValidationSchema';

import ArticleBanner from './ArticleBanner';
import ArticleOverview from './ArticleOverview';

const Article = forwardRef(
  (
    {
      values,
      errors,
      setValues,
      setFieldError,
      setDirty,
      fieldKey,
      onSubmit,
      readOnly,
      imperativeHandleDepedencies = [],
    },
    ref,
  ) => {
    const handleSubmit = async (formValues, formikHelper) => {
      const res = await onSubmit({ [fieldKey]: formValues }, formikHelper);

      // Only manually set values on edit.
      if (formValues.id && res.article.id) {
        const newArticle = ResponseMappers.admin.article(res);
        // Populate from BE response
        setValues(newArticle);
        formikHelper.setValues(newArticle.article);
      }
    };

    const articleForm = useFormik({
      initialValues: values,
      validateOnChange: true,
      validationSchema: adminArticleValidationSchema,
      onSubmit: handleSubmit,
      initialErrors: {},
    });
    const initiated = useRef();

    useEffect(() => {
      initiated.current = false;
    }, []);

    useEffect(() => {
      if (!initiated.current) {
        initiated.current = true;
        return;
      }

      setDirty(true);
    }, [articleForm.values, articleForm.values]);

    useEffect(() => {
      const isError = !!Object.keys(articleForm.errors).length;

      if (isError !== errors) setFieldError(fieldKey, isError);
    }, [articleForm.errors]);

    useImperativeHandle(
      ref,
      () => ({
        getFormValues: () => articleForm.values,
        handleSubmit: articleForm.submitForm,
      }),
      [articleForm.values, ...imperativeHandleDepedencies],
    );

    return (
      <Grid container>
        <ArticleOverview
          readOnly={readOnly}
          onChange={articleForm.handleChange}
          onBlur={articleForm.handleBlur}
          values={articleForm.values}
          errors={articleForm.errors}
          setFieldValue={articleForm.setFieldValue}
        />

        <ArticleBanner
          readOnly={readOnly}
          onChange={articleForm.handleChange}
          values={articleForm.values}
          errors={articleForm.errors}
        />
      </Grid>
    );
  },
);

export default memo(Article);
