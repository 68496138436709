import Services from 'api/services';

import condTrueFalse from 'utils/condTrueFalse';
import getNumbers from 'utils/strings/getNumbers';

const paymentInstructionsFormFields = [
  {
    fieldType: 'input',
    name: 'bankName',
    label: 'Bank Name',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'accountName',
    label: 'Bank Account Name',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'accountNumber',
    label: 'Account Number',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'bsb',
    label: 'BSB',
    gridProps: {
      xs: 6,
    },
  },
  {
    fieldType: 'input',
    name: 'bankAddress',
    label: 'Bank Address',
    gridProps: {
      xs: 6,
    },
  },
];

const fetchAdminUserEntityList = async ({ userId }) => {
  const res = await Services.adminGetUserEntityList(userId);

  return res?.data?.data;
};

const fetchUserData = async (userId) => {
  const res = await Services.adminGetUser(userId);

  return res?.data;
};

const mapFundConfigurationOption = (fundConfiguration) => ({
  ...fundConfiguration,
  label: fundConfiguration.className,
  value: fundConfiguration.id,
});

const fetchFunds = async () => {
  const res = await Services.adminGetPublicFunds();

  return res?.data?.reduce((funds, fund) => {
    if (!fund?.fundConfigurations?.length) return funds;

    return funds.concat({
      ...fund,
      label: fund?.fundName,
      value: fund?.id,
      fundConfigurations: fund.fundConfigurations.map(
        mapFundConfigurationOption,
      ),
    });
  }, []);
};

const mapPayload = (formValues) => ({
  portfolioId: formValues.portfolio,
  redemptionUnitAmount: getNumbers(formValues.redemptionUnitAmount),
  requestedAt: formValues.requestedAt,

  status: formValues.status,
});

const submitUserFundRedemptionRequest = async ({
  formValues,
  cb,
  setError,
}) => {
  try {
    const payload = mapPayload(formValues);

    await Services.adminAddFundRedemptionRequest({ payload });

    condTrueFalse(cb, cb);
  } catch (err) {
    setError(err.response?.message);
  }
};

export {
  paymentInstructionsFormFields,
  fetchAdminUserEntityList,
  fetchUserData,
  fetchFunds,
  submitUserFundRedemptionRequest,
};
