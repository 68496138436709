import React, { memo } from 'react';
import { FastField, Field } from 'formik';

const FormikField = ({ fastField = true, children, ...props }) => {
  if (fastField) {
    return <FastField {...props}>{children}</FastField>;
  }

  return <Field {...props}>{children}</Field>;
};

export default memo(FormikField);
