import services from 'api/services';
import { STORAGE_KEY } from 'constants/general';

const toggleEditSummaryAction = async (dispatch, payload) => {
  dispatch({
    type: '@@general/TOGGLE_EDIT_SUMMARY_SECTION',
    payload,
  });
};

const setSidebarAction = async (dispatch, payload) => {
  dispatch({
    type: '@@general/SET_SIDEBAR',
    payload,
  });
};

const updateAppColours = async (dispatch, payload) => {
  try {
    const newColours = payload.reduce(
      (colours, colour) => ({
        ...colours,
        [colour.key]: colour.colour,
      }),
      {},
    );

    dispatch({
      type: '@@general/UPDATE_COLOURS',
      payload: newColours,
    });

    localStorage.setItem(
      `${STORAGE_KEY}-appColours`,
      JSON.stringify(newColours),
    );
  } catch (error) {
    dispatch({
      type: '@@general/UPDATE_COLOURS_FAILURE',
      payload: error,
    });

    throw error;
  }
};

const updateLatestCurrencyData = async (dispatch) => {
  try {
    const audCurrency = await services.getLatestCurrencyData();
    const nzdCurrency = await services.getLatestCurrencyData({
      currency: 'NZD',
    });
    const usdCurrency = await services.getLatestCurrencyData({
      currency: 'USD',
    });

    const payload = {
      AUD: audCurrency?.data?.rates,
      NZD: nzdCurrency?.data?.rates,
      USD: usdCurrency?.data?.rates,
    };

    dispatch({
      type: '@@general/UPDATE_CURRENCY_DATA',
      payload,
    });

    localStorage.setItem(
      `${STORAGE_KEY}-currencyData`,
      JSON.stringify(payload),
    );
  } catch (error) {
    dispatch({
      type: '@@general/UPDATE_CURRENCY_DATA_FAILURE',
      payload: error,
    });

    throw error;
  }
};

export default {
  toggleEditSummaryAction,
  setSidebarAction,
  updateAppColours,
  updateLatestCurrencyData,
};
