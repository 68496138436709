const mapPieSection = (pieSection = {}) => ({
  ...pieSection,
  label: pieSection.sliceDetails,
});

const mapSummaryChart = (summaryChart) => ({
  ...summaryChart,
  pieSections: summaryChart?.pieSections?.map(mapPieSection),
});

const fundDetails = (values) => {
  const response = {
    ...values,
    summaryChart: values?.summaryChart?.map(mapSummaryChart),
  };

  return response;
};

export default fundDetails;
