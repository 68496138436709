import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';

import Button from 'components/Button';

import condTrueFalse from 'utils/condTrueFalse';

import fundInformationValidationSchema from 'validations/schema/adminAddFunds/fundInformationValidationSchema';

import { FUND_CATEGORIES } from 'constants/funds';

import FundCard from './FundCard';
import Overview from './Overview';
import PeriodSection from './Period';

const FundInformation = ({
  idFund,
  values,
  errors,
  setFieldValue,
  setFieldError,
  setDirty,
  fieldKey,
  onSubmit,
  disableSaveBtn,
  readOnly,
  fundType,
}) => {
  const handleSubmit = async (formValues, formikHelper) => {
    const res = await onSubmit({ [fieldKey]: formValues }, formikHelper);

    // Populate FundId.
    setFieldValue('fundId', res.fundId, undefined, false);
    setFieldValue('idFund', res.idFund, undefined, false);

    // Populate from BE response ?
    setFieldValue(fieldKey, formValues, undefined, false);
  };

  const fundInformationForm = useFormik({
    initialValues: values,
    validateOnChange: true,
    validationSchema: fundInformationValidationSchema,
    onSubmit: handleSubmit,
    initialErrors: condTrueFalse(idFund, {}, { fundInformation: {} }),
  });
  const initiated = useRef();

  useEffect(() => {
    initiated.current = false;
  }, []);

  useEffect(() => {
    if (!initiated.current) {
      initiated.current = true;
      return;
    }

    setDirty(true);
  }, [
    fundInformationForm.values.fundInformation,
    fundInformationForm.values.fundCard,
  ]);

  useEffect(() => {
    const isError = !!Object.keys(fundInformationForm.errors).length;

    if (isError !== errors) setFieldError(fieldKey, isError);
  }, [fundInformationForm.errors]);

  const handleChangeFundInformation = useCallback(
    (e) => {
      const { name, value } = e.target;

      const newFundInformation = {
        ...fundInformationForm.values.fundInformation,
        [name]: value,
      };

      fundInformationForm.setFieldValue(fieldKey, newFundInformation);
    },
    [fundInformationForm.values.fundInformation],
  );

  const handleChangeFundCard = useCallback(
    (e) => {
      const { name, value } = e.target;
      fundInformationForm.setFieldValue('fundCard', {
        ...fundInformationForm.values.fundCard,
        [name]: value,
      });
    },
    [fundInformationForm.values.fundCard],
  );

  const handleChangeDate = useCallback(
    (name, value) => {
      const event = {
        target: {
          name,
          value,
        },
      };

      handleChangeFundInformation(event);
    },
    [handleChangeFundInformation],
  );

  return (
    <Grid container>
      <Overview
        readOnly={readOnly}
        onChange={handleChangeFundInformation}
        isCashFund={fundType === FUND_CATEGORIES.CASH}
        onBlur={fundInformationForm.handleBlur}
        values={fundInformationForm.values.fundInformation}
        errors={fundInformationForm.errors?.fundInformation}
      />

      <PeriodSection
        readOnly={readOnly}
        onChange={handleChangeFundInformation}
        setFieldValue={handleChangeDate}
        onBlur={fundInformationForm.handleBlur}
        values={fundInformationForm.values.fundInformation}
        errors={fundInformationForm.errors?.fundInformation}
      />

      <FundCard
        readOnly={readOnly}
        onChange={handleChangeFundCard}
        values={fundInformationForm.values.fundCard}
        errors={fundInformationForm.errors?.fundCard}
      />

      <Grid item xs={12} textAlign="right">
        <Button
          fullWidth={false}
          data-testid="submit-btn-fundInformation"
          buttoncolor="purpleHazeGradient"
          sx={{ padding: '10px' }}
          onClick={fundInformationForm.submitForm}
          disabled={
            readOnly ||
            disableSaveBtn ||
            !!Object.keys(fundInformationForm.errors).length
          }
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(FundInformation);
