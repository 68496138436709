import React from 'react';

import Chip from '@mui/material/Chip';

import Accordion from 'components/Accordion';
import LabelWithChip from 'components/LabelWithChip';

import condTrueFalse from 'utils/condTrueFalse';
import getCountryByCode from 'utils/getCountryByCode';

import palette from 'theme/palette';
import { TaxSupportSummary, TaxDetailSummary } from './HelperFunction';

const TaxDetailsAccordionLabel = (
  { label, trustType, hasAustraliaTax, otherCountryTax },
  index,
) =>
  index === 0 ? (
    <LabelWithChip label={label} chipColor="individual" chipLabel={trustType}>
      <Chip
        key="true"
        label={condTrueFalse(
          hasAustraliaTax,
          'Australia',
          getCountryByCode(otherCountryTax)?.label,
        )}
        color="individual"
        sx={{ marginLeft: '15px' }}
      />
    </LabelWithChip>
  ) : (
    <LabelWithChip
      label={label}
      chipColor="individual"
      chipLabel={condTrueFalse(
        hasAustraliaTax,
        'Australia',
        getCountryByCode(otherCountryTax)?.label,
      )}
    />
  );

const TrustTaxDetails = ({ values, ...props }) => (
  <Accordion label="Trust Tax Details" labelVariant="cardNameLabel">
    {values?.taxDetails?.map((taxDetail, index) => (
      <Accordion
        label={TaxDetailsAccordionLabel(
          { ...taxDetail, trustType: values.trustType },
          index,
        )}
        labelVariant="cardNameLabel"
        key={`taxDetail-${taxDetail?.id}`}
        sxProps={
          index !== 0
            ? {
                borderLeft: `5px solid ${palette.reach.blueBorder}`,
                paddingLeft: '15px',
              }
            : {}
        }
      >
        <TaxDetailSummary values={taxDetail} withTitle={false} {...props} />
      </Accordion>
    ))}
    <Accordion label="Supporting Information" labelVariant="cardNameLabel">
      <TaxSupportSummary
        values={values.supportingInformation}
        withTitle={false}
        {...props}
      />
    </Accordion>
  </Accordion>
);

export default TrustTaxDetails;
