import React, { useCallback, useState } from 'react';

import { IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FundCardLandscapeContent from 'components/FundCard/components/FundCardLandscapeContent';
import ImageContent from 'components/FundCard/FundCardLandscape/ImageContent';

import useFundCardHeaderStyles from 'hooks/useFundCardHeaderStyles';
import condTrueFalse from 'utils/condTrueFalse';
import config from 'utils/config';

import palette from 'theme/palette';

const FundCardHeaderAccordion = ({
  headerBgColor,
  headerImageSrc,
  logoSrc,
  headerTitle,
  headerDesc,
  onExpandLess,
  onExpandMore,
  children,
  marginTop,
  contentList,
  childGridSx = {},
}) => {
  const [isExpanding, setIsExpanding] = useState(false);

  const { header: headerStyles } = useFundCardHeaderStyles({
    cardHeights: {
      sm: condTrueFalse(logoSrc, '325px', '215px'),
      md: condTrueFalse(logoSrc, '275px', '160px'),
      default: '148px',
    },
    cardMediaStyles: {
      borderRadius: !isExpanding ? '6px' : '6px 6px 0px 0px',
    },
    headerStyles: {
      borderRadius: !isExpanding ? '6px' : '6px 6px 0px 0px',
    },
    chipStyles: (isSM) => ({
      marginLeft: !isSM ? '35px' : '24px',
      marginTop: isSM && '10px',
      borderRadius: '99px',
      textTransform: 'capitalize',
    }),
    headerBgColor,
    disabled: false,
  });

  const handleAccordionClick = useCallback(() => {
    condTrueFalse(isExpanding, onExpandLess, onExpandMore);
    setIsExpanding(!isExpanding);
  }, [isExpanding, onExpandLess, onExpandMore]);

  return (
    <div style={{ marginTop }}>
      <div style={headerStyles}>
        <Grid
          container
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 2,
            marginLeft: 0,
            paddingLeft: 0,
          }}
        >
          <Grid
            container
            item
            xs={12}
            display="flex"
            direction="column"
            alignItems="center"
            sx={{
              height: '100%',
              backgroundColor: config.system.appColours.cardBackground,
            }}
          >
            <Grid
              item
              container
              display="flex"
              alignItems="center"
              sx={{ height: '100%' }}
              columnSpacing={{ xs: 2, md: 3 }}
            >
              {logoSrc && (
                <Grid item xs={2.5} md={2.5} height="100%">
                  <ImageContent
                    height="100%"
                    logoSrc={logoSrc}
                    headerImageSrc={headerImageSrc}
                    logoStyles={{
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      margin: '0 auto',
                    }}
                  />
                </Grid>
              )}
              {(headerTitle || headerDesc) && (
                <Grid
                  container
                  item
                  xs={7.5}
                  md={7.5}
                  display="flex"
                  flexDirection="row"
                  rowSpacing={{ xs: 0, md: 2 }}
                  height="100%"
                >
                  <Grid item xs={12}>
                    <Typography variant="display3">{headerTitle}</Typography>
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.text.primary,
                        marginLeft: 0,
                        display: 'block',
                        marginTop: '4px',
                      })}
                      variant="body"
                    >
                      {headerDesc}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FundCardLandscapeContent contentList={contentList} />
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={1}
                md
                sx={{
                  order: 2,
                  textAlign: 'right',
                  marginRight: 2,
                }}
              >
                <IconButton onClick={handleAccordionClick}>
                  {isExpanding ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {isExpanding ? (
        <Grid
          container
          sx={{
            background: config.system.appColours.cardBackground,
            borderTop: `1px solid ${palette.border.light}`,
            padding: '32px',
            ...childGridSx,
          }}
        >
          {children}
        </Grid>
      ) : null}
    </div>
  );
};

export default FundCardHeaderAccordion;
