import addressValidationSchemas from './addressValidationSchema';
import accountantCertificateValidationSchema from './accountantCertificateValidationSchema';
import personalDetailsValidationSchema from './personalDetailsValidationSchema';
import investorIdentificationValidationSchema from './investorIdentificationValidationSchema';

const trusteesIndividualValidationSchemas = [
  personalDetailsValidationSchema,
  addressValidationSchemas,
  investorIdentificationValidationSchema,
  accountantCertificateValidationSchema,
];

export default trusteesIndividualValidationSchemas;
