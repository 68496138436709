import { TAX_CATEGORY } from 'constants/investmentEntity';
import mapTaxDetailsPayload from './taxDetails';

const taxDetailPurposes = ({
  taxDetails,
  signedBy,
  signedAt,
  isConfirmed,
  taxCategory = TAX_CATEGORY.COMPANY,
}) => ({
  taxPurposes: taxDetails?.taxPurposes?.map((taxPurpose, index) => ({
    ...mapTaxDetailsPayload({ ...taxPurpose, signedBy, signedAt, isConfirmed }),
    taxCategory: index === 0 ? taxCategory : TAX_CATEGORY.OTHER,
  })),
  supportingInformations: taxDetails?.supportingInformation || {},
});

export default taxDetailPurposes;
