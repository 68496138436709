import React, { useState, useCallback, memo, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';

import Button from 'components/Button';
import FormTitle from 'components/FormTitle';
import LoadingSpinner from 'components/LoadingSpinner';
import UploadCategoryList from 'components/UploadCategoryList';

import useUploadCategoryList from 'hooks/useUploadCategoryList';
import { mapDataRoomResponse } from 'utils/ResponseMappers/admin/fundDetails';

import {
  deleteDataRoomFile,
  getIsDeletedStatus,
  UploadDataRoomFiles,
} from './HelperFunction';

const Dataroom = ({
  values = [],
  setFieldValue,
  idFund,
  fieldKey,
  setDirty,
  onSubmit,
  disableSaveBtn,
  readOnly,
}) => {
  const dataRoomsForm = useFormik({
    initialValues: {
      dataRooms: values,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const initiated = useRef();
  const willUnmount = useRef();

  useEffect(() => {
    initiated.current = false;
    willUnmount.current = false;

    return () => {
      willUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (!initiated.current) {
      initiated.current = true;
      return;
    }

    if (typeof setDirty === 'function') setDirty(true);
  }, [JSON.stringify(dataRoomsForm.values?.dataRooms)]);

  const {
    tempDeletedFiles,
    setTempDeletedFiles,
    setTempUploadedFiles,
    handleDeleteFiles,
    handleUploadFiles,
  } = useUploadCategoryList({
    deleteFileAPICall: deleteDataRoomFile,
    uploadFileAPICAll: UploadDataRoomFiles,
    valueBeforeSubmit: values,
    id: idFund,
    willUnmountRef: willUnmount,
  });

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    // Find deleted Category.
    const deletedDataRooms = [...tempDeletedFiles.values()].filter(
      (dataRoom) => dataRoom.isDeleted,
    );
    const orderedDataRooms = dataRoomsForm.values.dataRooms
      .map((dataRoom, index) => ({
        ...dataRoom,
        orderId: index + 1,
        ...getIsDeletedStatus({
          dataRoomId: dataRoom.id,
          documents: dataRoom.documents,
          deletedFiles: tempDeletedFiles,
        }),
        subFolders: dataRoom?.subFolders
          ? dataRoom.subFolders.map((subFolder, idx) => ({
              ...subFolder,
              orderId: idx + 1,
              ...getIsDeletedStatus({
                dataRoomId: subFolder.id,
                documents: subFolder.documents,
                deletedFiles: tempDeletedFiles,
              }),
            }))
          : dataRoom?.subFolders,
      }))
      // Merge deleted category to payload.
      .concat(deletedDataRooms);

    const res = await onSubmit({ [fieldKey]: orderedDataRooms, idFund }); // temporary value, will change this after summaryChart PR

    // Reset temp states.
    setTempUploadedFiles([]);
    setTempDeletedFiles(new Map());

    const newDataRooms = mapDataRoomResponse(res.dataRooms);
    setFieldValue(fieldKey, [...newDataRooms], undefined, false);
    // Update state with new value.
    dataRoomsForm.setFieldValue('dataRooms', [...newDataRooms]);

    setIsLoading(false);

    // Reset initiated to false, because will update `dataRoomsForm` value.
    initiated.current = false;
  }, [dataRoomsForm.values.dataRooms, idFund]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormTitle title="Fund Data Room" />
      </Grid>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <UploadCategoryList
          updateDeletedKeyOnDelete={!!idFund}
          onDeleteFiles={handleDeleteFiles}
          onUploadFiles={handleUploadFiles}
          value={dataRoomsForm.values.dataRooms}
          setFieldValue={dataRoomsForm.setFieldValue}
          fieldName="dataRooms"
          disabled={readOnly}
          maxSize={15}
          withSubFolders={process.env.REACT_APP_ENV !== 'prod'}
        />
      )}
      <Grid item xs={12} textAlign="right">
        <Button
          fullWidth={false}
          data-testid="submit-btn-fundInformation"
          buttoncolor="purpleHazeGradient"
          sx={{ padding: '10px' }}
          onClick={handleSubmit}
          disabled={readOnly || disableSaveBtn || isLoading}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(Dataroom);
