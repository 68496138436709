import React from 'react';

import TrashIcon from '@mui/icons-material/DeleteOutline';

import AddButton from 'components/Button/AddButton';
import IconButton from 'components/Button/IconButton';

const getAccordionFooter = ({
  addKey,
  deleteKey,
  onAdd,
  onDelete,
  disableDelete,
  addIconColor,
  textColor,
} = {}) => (
  <>
    {onAdd ? (
      <AddButton
        label={`Add ${addKey}`}
        sx={{ margin: 0 }}
        onClick={onAdd}
        iconColor={addIconColor}
        textColor={textColor}
      />
    ) : (
      <div />
    )}
    {onDelete && (
      <IconButton
        disabled={disableDelete}
        onClick={onDelete}
        icon={<TrashIcon color={disableDelete ? 'grey' : 'error'} />}
        label={`Delete ${deleteKey}`}
        variant="bodyGrey"
        sx={{
          width: '20px',
          height: '20px',
          minWidth: '28px',
          boxShadow: 'none',
          background: 'transparent',
        }}
      />
    )}
  </>
);

export {
  // eslint-disable-next-line
  getAccordionFooter,
};
