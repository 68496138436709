import React, { useCallback, useMemo, useState } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DataTable from 'components/DataTable';
import Dropdown from 'components/Dropdown';
import LoadingSpinner from 'components/LoadingSpinner';

import useFetchApi from 'hooks/useFetchApi';
import history from 'utils/history';
import config from 'utils/config';

import { PERIOD_ALL, PERIOD_OPTIONS } from 'constants/general';
import { PRE_REGISTERED, USER_STATUS } from 'constants/userStatus';

import {
  fetchAdminUserActivities,
  userActivityHeaders,
} from './HelperFunction';

const redirectToUserDetail = ({ row }) => {
  history.push(
    `/admin/users/edit/${row.id}${
      row.status?.toUpperCase?.() === USER_STATUS['PRE-REGISTERED']
        ? `?status=${USER_STATUS['PRE-REGISTERED']}`
        : ''
    }`,
    {
      onBackRoute: '/admin',
    },
  );
};

const UserActivitySection = () => {
  const [period, setPeriod] = useState(PERIOD_ALL);

  const adminGetOverviewUserActivity = useMemo(
    () => fetchAdminUserActivities({ filter: period }),
    [period],
  );

  const { apiData, isFetchLoading } = useFetchApi({
    url: adminGetOverviewUserActivity,
    defaultApiData: {
      openFunds: 0,
      users: 0,
      onboarded: 0,
      investmentEntities: 0,
      investments: 0,
      raised: {},
    },
    dependencies: [adminGetOverviewUserActivity],
  });

  const handleChangePeriod = useCallback((e) => {
    setPeriod(e.target.value);
  }, []);

  return (
    <Grid
      data-testid="admin-overview-user-activity-section"
      container
      item
      direction="column"
      rowSpacing={2}
      sx={{ background: config.system.appColours.cardBackground }}
    >
      <Grid item>
        <Typography variant="display1">User Activity</Typography>
      </Grid>

      <Grid item display="flex" alignItems="center">
        <FormLabel sx={{ mr: 2 }}>Period</FormLabel>
        <Dropdown
          data-testid="overview-user-activity-period-dropdown"
          fullWidth={false}
          options={PERIOD_OPTIONS}
          value={period}
          onChange={handleChangePeriod}
          name="overviewUserActivityPeriod"
          sx={{ minWidth: '150px' }}
          minheight={5.5}
          maxheight="44px"
        />
      </Grid>

      <Grid item xs={12}>
        {isFetchLoading ? (
          <LoadingSpinner loading />
        ) : (
          <DataTable
            wrapheader
            hideFooter
            sortingMode="client"
            withCheckbox={false}
            pagination={false}
            height="425px"
            headerData={userActivityHeaders}
            data={apiData}
            isLoading={isFetchLoading}
            totalData={apiData.totalItems}
            containerProps={{ backgroundColor: 'white' }}
            onRowClick={redirectToUserDetail}
            columnVisibilityModel={{ status: false }}
            getRowId={(row) =>
              row.status?.toUpperCase?.() === PRE_REGISTERED
                ? `${row.id}-${PRE_REGISTERED}`
                : row.id
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default UserActivitySection;
