import React from 'react';
import Chip from '@mui/material/Chip';

const LabelWithChip = ({ label = '', chipLabel, chipColor, children }) => (
  <span>
    {label}
    {chipLabel && (
      <Chip label={chipLabel} color={chipColor} sx={{ marginLeft: '15px' }} />
    )}
    {children}
  </span>
);

export default LabelWithChip;
