import React, { memo, useCallback, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import palette from 'theme/palette';

import AddButton from 'components/Button/AddButton';
import FieldContainer from 'components/FieldContainer';
import Input from 'components/Input';
import TextEditor from 'components/TextEditor';

import PieSection from './PieSection';

const ChartSection = ({
  readOnly,
  summaryIndex,
  values,
  onAddPieSection,
  onChange,
}) => {
  const [openNoteEditor, setOpenNoteEditor] = useState(!!values.pieNote);

  const handleChangePieSection = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      const newPieSections = [...values.pieSections];
      const newValue = { ...newPieSections[index], [name]: value };

      newPieSections[index] = { ...newValue };

      const event = {
        target: {
          name: 'pieSections',
          value: newPieSections,
        },
      };

      onChange(event);
    },
    [onChange, values.pieSections],
  );

  const handleDeletePieSection = useCallback(
    (index) => {
      const newPieSections = [...values.pieSections];

      newPieSections.splice(index, 1);

      const event = {
        target: {
          name: 'pieSections',
          value: newPieSections,
        },
      };

      onChange(event);
    },
    [onChange],
  );

  const handleOpenNoteEditor = useCallback(() => {
    setOpenNoteEditor(true);
  }, []);

  return (
    <Grid
      container
      rowSpacing={{ xs: 2, lg: 3 }}
      sx={{
        padding: 2,
        paddingTop: 0,
        marginTop: '0px !important',
        border: `1px solid ${palette.form.disabledBorder}`,
      }}
    >
      <Grid
        item
        xs={12}
        borderBottom={`1px solid ${palette.form.disabledBorder}`}
      >
        <Typography variant="display5">Chart</Typography>
      </Grid>

      <Grid item xs={5}>
        <FieldContainer label="Chart Title">
          <Input
            fullWidth
            name="chartTitle"
            onChange={onChange}
            value={values.chartTitle}
            disabled={readOnly}
          />
        </FieldContainer>
      </Grid>

      {values.pieSections.map((pieSection, index) => (
        <Grid key={`pie-section-${index.toString()}`} item xs={12}>
          <PieSection
            index={index}
            values={pieSection}
            readOnly={readOnly}
            onChange={handleChangePieSection}
            onDeletePieSection={handleDeletePieSection}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <AddButton
          label="Add pie section"
          iconColor={palette.reach.bluish}
          textColor="bluish"
          onClick={onAddPieSection}
          sx={{ marginTop: 0 }}
          disabled={!!values.pieSections.length > 4 || readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {openNoteEditor ? (
          <TextEditor
            id={`summary-chartSection-${summaryIndex.toString()}`}
            onChange={onChange}
            value={values.pieNote}
            name="pieNote"
            readOnly={readOnly}
          />
        ) : (
          <AddButton
            label="Add note to appear under pie chart"
            iconColor={palette.reach.bluish}
            textColor="bluish"
            sx={{ marginTop: 0 }}
            onClick={handleOpenNoteEditor}
            disabled={readOnly}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default memo(ChartSection);
