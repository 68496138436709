import getNumbers from 'utils/strings/getNumbers';
import mapValue from 'utils/mapValue';

const fund = (formValues) => ({
  id: formValues.id,
  unit: getNumbers(formValues.investmentUnit),
  paymentType: formValues.paymentType,
  totalInvestment: getNumbers(formValues.totalInvestment),
  accessFee: getNumbers(formValues.accessFee),
  applicationFee: getNumbers(formValues.applicationFee),
  amountPayableToday: mapValue(
    getNumbers(formValues.amountPayableToday),
    undefined,
  ),
  amountReceived: getNumbers(formValues.totalPayableToday),
  amountPayableLater: getNumbers(formValues.amountPayableLater),
  total: getNumbers(formValues.total),
  approvedAt: formValues.approvedAt,
  lockupDays: formValues.lockupDays,
  lockupDaysRemaining: formValues.lockupDaysRemaining,
});

export default fund;
