import React, { memo } from 'react';

import MUIAvatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledAvatar = styled(MUIAvatar)(({ theme }) => ({
  margin: `0 ${theme.spacing(1)}`,
  background: theme.palette.reach.dark,
  color: `${theme.palette.text.white} !important`,
}));

const Avatar = ({ user, typographyVariant = 'avatarText', ...props }) => (
  <StyledAvatar {...props}>
    <Typography variant={typographyVariant}>
      {`${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`}
    </Typography>
  </StyledAvatar>
);

export default memo(Avatar);
