import mapValue from 'utils/mapValue';

const addressDetails = (personalDetail) => ({
  firstAddress: mapValue(personalDetail?.firstAddress, ''),
  secondAddress: mapValue(personalDetail?.secondAddress, ''),
  suburb: mapValue(personalDetail?.suburb, ''),
  city: mapValue(personalDetail?.city, ''),
  state: mapValue(personalDetail?.state, ''),
  postCode: mapValue(personalDetail?.postCode, ''),
  addressCountry: mapValue(personalDetail?.addressCountry, ''),
});

export default addressDetails;
