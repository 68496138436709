import condTrueFalse from 'utils/condTrueFalse';
import trimMaskInput from 'utils/trimMaskInput';

import { noTINReasonMapping } from 'containers/AddInvestmentEntity/Individual/Steps/TaxDetails/HelperFunction';

const taxDetails = (taxDetailsValue) => ({
  // Is Australia your country of residence for tax purpose.
  isAustralianTax: !!taxDetailsValue?.hasAustraliaTax,
  // Tax file number.
  taxFileNumber: condTrueFalse(
    taxDetailsValue?.hasAustraliaTax && taxDetailsValue?.australiaTax,
    trimMaskInput(taxDetailsValue?.australiaTax),
    '',
  ),
  // Residence other country tax purpose.
  isOtherCountryResident: !!taxDetailsValue?.hasOtherCountryTax,
  // Your country for tax purposes.
  otherCountryName: condTrueFalse(
    (!taxDetailsValue?.hasAustraliaTax ||
      taxDetailsValue?.hasOtherCountryTax) &&
      taxDetailsValue?.otherCountryTax,
    taxDetailsValue?.otherCountryTax,
    '',
  ),
  // Do you have TIN.
  isTINAvailable: condTrueFalse(
    !taxDetailsValue?.hasAustraliaTax || taxDetailsValue?.hasOtherCountryTax,
    !!taxDetailsValue?.isTINAvailable,
    false,
  ),
  // TIN or equivalent.
  otherCountryTin: condTrueFalse(
    taxDetailsValue?.isTINAvailable && taxDetailsValue?.otherCountryTIN,
    taxDetailsValue?.otherCountryTIN,
    '',
  ),
  // Reason no TIN.
  reasonDesc: condTrueFalse(
    taxDetailsValue?.otherCountryTax &&
      taxDetailsValue?.isTINAvailable === false,
    noTINReasonMapping(
      taxDetailsValue?.noTINReason,
      taxDetailsValue?.unableObtainTinReason,
    ),
    '',
  ),
  signedByName: taxDetailsValue?.signedBy || '',
  signedAt: taxDetailsValue?.signedAt || '',
  isConfirmed: !!taxDetailsValue?.isConfirmed,
});

export default taxDetails;
