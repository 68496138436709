import Services from 'api/services';

const fetchUserData = async (userId, status) => {
  try {
    const res = await Services.adminGetUser(userId, status);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export default {
  fetchUserData,
};
