import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';

import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import Tabs from 'components/Tabs';
import TabPanels from 'components/TabPanels';

import useTabs from 'hooks/useTabs';

import { investedPortfolioDetailsTabItems } from './HelperFunction';

const DetailsTabs = ({ portfolio }) => {
  const { activeTabIdx, handleTabChange } = useTabs();

  const memoizedTabPanelItems = useMemo(
    () =>
      process.env.REACT_APP_ENV !== 'prod'
        ? investedPortfolioDetailsTabItems({
            // setFieldValue: handleSetFieldValue,
            // setValues,
            // setFieldError,
            // setDirty,
            // onSubmit: handleSubmit,
            // articleId: values.articleId || articleId,
            // readOnly: !haveCrudPermission,
            // fundType,
          })
        : investedPortfolioDetailsTabItems({}).slice(0, 4),
    [],
  );

  return (
    <Grid container item>
      <Grid item xs={12}>
        <Tabs
          value={activeTabIdx}
          onChange={handleTabChange}
          centered={false}
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <TabComponents
            activeTabIdx={activeTabIdx}
            tabItems={memoizedTabPanelItems}
            testIdPrefix="admin-approval-tab"
          />
        </Tabs>
      </Grid>
      <Grid item xs>
        <TabPanels
          value={activeTabIdx}
          tabItems={memoizedTabPanelItems}
          boxPanelProps={{ backgroundColor: 'white' }}
          portfolioValueProps={{ portfolio }}
          portfolioCommitmentsProps={{ portfolio }}
          portfolioPerformanceProps={{ portfolio }}
          portfolioTermProps={{ portfolio }}
        />
      </Grid>
    </Grid>
  );
};

export default DetailsTabs;
