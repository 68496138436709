import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import LoadingGif from 'assets/images/loading.gif';

const LoadingDialog = ({ loading = false, label = 'Loading...' }) => (
  <Dialog open={loading}>
    <Grid container sx={{ p: '8px', textAlign: 'center' }}>
      <Grid item xs={12}>
        <img src={LoadingGif} alt="Loading..." />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="bodyLead" align="left">
          {label}
        </Typography>
      </Grid>
    </Grid>
  </Dialog>
);

export default memo(LoadingDialog);
