import React, { useCallback, useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DocumentItem from './DocumentItem';

const DocumentList = ({ folder, documents = [], subfolder, type }) => {
  const [isExpanding, setIsExpanding] = useState(!subfolder);

  const handleAccordionClick = useCallback(() => {
    setIsExpanding(!isExpanding);
  }, [isExpanding]);

  const documentsComponent = useMemo(
    () =>
      isExpanding
        ? documents.map((document) => (
            <DocumentItem
              enableDownload={document.enableDownload}
              enableView={document.enableView}
              folder={document.folder || folder}
              id={document.id}
              title={document.title}
              token={document.token}
              type={type}
            />
          ))
        : null,
    [documents, isExpanding],
  );

  return (
    <>
      <Grid container item xs={12} alignItems="center" mb={3}>
        <Grid item>
          <Typography variant="small" fontWeight="700 !important">
            {folder}
          </Typography>
        </Grid>
        {subfolder ? (
          <Grid item xs md sx={{ textAlign: 'right' }}>
            <IconButton onClick={handleAccordionClick}>
              {isExpanding ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        ) : (
          <Grid item xs>
            <Divider flexItem variant="middle" />
          </Grid>
        )}
      </Grid>
      {documentsComponent}
    </>
  );
};

export default DocumentList;
