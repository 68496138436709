import React, { memo, useCallback } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import CountdownFlip from 'components/CountdownFlip';
import FormTitle from 'components/FormTitle';
import InputField from 'components/Input';
import ProgressBar from 'components/ProgressBar';
import Switch from 'components/Switch';
import PeriodStatus from './PeriodStatus';
import PeriodDate from './PeriodDate';

const sxBackgroundReach = (color, optionalsx) => (theme) => ({
  backgroundColor: theme.palette.reach[color],
  ...optionalsx,
});

const PeriodSection = ({ readOnly, onChange, values, setFieldValue }) => {
  const handleSwitchChange = useCallback(
    (e) => {
      const payload = {
        target: {
          name: e.target.name,
          value: e.target.checked,
        },
      };
      onChange(payload);
    },
    [onChange],
  );

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <FormTitle title="Period" />
      </Grid>
      <PeriodStatus
        readOnly={readOnly}
        onChange={onChange}
        values={values}
        setFieldValue={setFieldValue}
      />
      <PeriodDate
        readOnly={readOnly}
        onChange={onChange}
        values={values}
        setFieldValue={setFieldValue}
      />
      <Grid
        container
        item
        xs={12}
        sx={sxBackgroundReach('grey')}
        mt={3}
        justifyContent="center"
      >
        <Grid
          container
          item
          xs={4.5}
          p={2}
          border="1px solid grey"
          borderRadius={2}
          rowSpacing={2}
          sx={sxBackgroundReach('white')}
        >
          {values.showWidget && (
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: 1 }}>
                Time left raised:
              </Typography>
              <CountdownFlip
                startAt={`${values.startDate}`}
                endAt={`${values.endDate}`}
                customSize="48px"
              />
            </Grid>
          )}
          {values.showFundPercentage && (
            <Grid item xs={12}>
              <Typography>Raised amount:</Typography>
              <ProgressBar progress={values.fundPercentage} />
            </Grid>
          )}
        </Grid>
        <Grid container item xs={6} p={2} rowSpacing={3} marginLeft={6}>
          <Grid container item>
            <Typography variant="h5">Period Widget</Typography>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <FormLabel>Timer Widget</FormLabel>
            </Grid>
            <Grid item xs="auto">
              <Switch
                name="showWidget"
                onChange={handleSwitchChange}
                checked={values.showWidget}
                customcolor="black"
                disabled={readOnly || values.periodStatus}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <FormLabel>Fund Raised Meter</FormLabel>
            </Grid>
            <Grid item xs="auto">
              <Switch
                name="showFundPercentage"
                disabled={readOnly}
                onChange={handleSwitchChange}
                checked={values.showFundPercentage}
                customcolor="black"
              />
            </Grid>
          </Grid>
          {values.showFundPercentage && (
            <Grid container item xs={12} spacing={2} alignItems="center">
              <Grid item xs={2} minWidth="100px">
                <InputField
                  name="fundPercentage"
                  id="fundPercentage"
                  onChange={onChange}
                  value={values.fundPercentage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={sxBackgroundReach('white', {
                    borderRadius: 2,
                    padding: 0.5,
                  })}
                >
                  please input the value of amount raised to date in percentage
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(PeriodSection);
