import React, { useMemo } from 'react';
import MUICardContent from '@mui/material/CardContent';

const FundCardContentContainer = ({ children, disabled, ...props }) => {
  const cardContentContainerStyles = useMemo(
    () => ({ opacity: disabled && '0.2' }),
    [disabled],
  );

  return (
    <MUICardContent sx={cardContentContainerStyles} {...props}>
      {children}
    </MUICardContent>
  );
};

export default FundCardContentContainer;
