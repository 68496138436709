import { useMediaQuery } from '@mui/material';

const useScreenQuery = () => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isLaptopScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isExtraLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.up('xl'),
  );

  return {
    isMobileScreen,
    isTabletScreen,
    isLaptopScreen,
    isLargeScreen,
    isExtraLargeScreen,
  };
};

export default useScreenQuery;
