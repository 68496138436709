import { useState, useCallback } from 'react';
import { useFormik, setNestedObjectValues } from 'formik';

import history from 'utils/history';

const useSteps = ({
  stepComponents,
  onBack,
  onNext,
  handleBackDependencies = [],
  handleNextDependencies = [],
  firstStepGoBackURL = '/',
  validationSchemas = [],
  formParameters = {},
  onNextInLastPage,
  onBackInFirstPage,
  defaultPageIndex = 0,
  validateFormik = false,
}) => {
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);

  const form = useFormik({
    validationSchema: validationSchemas[pageIndex],
    ...formParameters,
  });

  const handleNext = useCallback(async () => {
    const isLastIndex = pageIndex === stepComponents.length - 1;
    if (isLastIndex && typeof onNextInLastPage !== 'function') return;

    if (onNext) {
      onNext({
        setPageIndex,
      });
      return;
    }

    let nextPageIndex;
    // revalidate current step fields to prevent bypass validation when spamming next btn
    try {
      const currentStepValidationSchema = validationSchemas[pageIndex];
      const validated = await currentStepValidationSchema?.validate(
        form.values,
        {
          abortEarly: false,
        },
      );

      const isValidated = !currentStepValidationSchema || validated;

      if (isValidated) {
        nextPageIndex = pageIndex + 1;
      }
      if (isValidated && isLastIndex && onNextInLastPage)
        onNextInLastPage({ values: form.values });
    } catch (err) {
      // will throw error if yup schema validation is error
      // case is when you want to have the error show up on the fields after submitting
      // touched fields are still empty without this code block
      // @: https://github.com/jaredpalmer/formik/issues/2734#issuecomment-923337541
      if (validateFormik) {
        const formikValidationRes = await form.validateForm();
        if (Object.keys(formikValidationRes).length > 0) {
          form.setTouched(setNestedObjectValues(formikValidationRes, true));
        }
      }
      nextPageIndex = pageIndex;
    }

    nextPageIndex = Math.min(nextPageIndex, stepComponents.length - 1);
    setPageIndex(nextPageIndex);
  }, [pageIndex, form?.values, ...handleNextDependencies]);

  const handleBack = useCallback(() => {
    const isFirstIndex = pageIndex === 0;

    if (isFirstIndex && typeof onBackInFirstPage === 'function') {
      onBackInFirstPage({
        handleSetPageIndex: setPageIndex,
        setFormValues: form.setValues,
        values: form.values,
        stepComponentsLength: stepComponents.length,
      });
      return;
    }

    if (isFirstIndex) {
      history.push(firstStepGoBackURL, history.location.state);
      return;
    }

    const handleBackToPrevStep = () =>
      setPageIndex((prevState) => Math.max(0, prevState - 1));

    if (onBack) {
      onBack({
        setPageIndex,
        values: form.values,
        handleBackToPrevStep,
      });
      return;
    }

    handleBackToPrevStep();
  }, [pageIndex, form?.values, stepComponents, ...handleBackDependencies]);

  return {
    handleBack,
    handleNext,
    pageIndex,
    form,
    setPageIndex,
  };
};

export default useSteps;
