import React, { memo, useMemo } from 'react';

import DataTable from 'components/DataTable';
import TotalHeaders from 'components/TotalHeaders';

import Services from 'api/services';
import useAdminRestriction from 'hooks/useAdminRestriction';
import useDataTables from 'hooks/useDataTables';

import { ADMIN_LIST_COLUMNS } from './HelperFunction';

const AdminTabContent = () => {
  const { haveCrudPermission } = useAdminRestriction();
  const {
    apiData,
    isFetchLoading,
    setSort,
    paginationModel,
    setPaginationModel,
  } = useDataTables({
    fetchService: Services.adminGetAdmins,
    defaultApiData: {},
  });

  const adminListHeaders = useMemo(
    () => [{ totalLabel: 'Total Admin', totalValue: apiData.totalItems }],
    [apiData.totalItems],
  );

  return (
    <>
      <TotalHeaders
        totals={adminListHeaders}
        disableExportBtn={!haveCrudPermission}
      />

      <DataTable
        headerData={ADMIN_LIST_COLUMNS}
        data={apiData.items}
        setSort={setSort}
        isLoading={isFetchLoading}
        totalData={apiData.totalItems}
        sx={{
          border: 'none',
        }}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  );
};

export default memo(AdminTabContent);
