import Services from 'api/services';
import ResponseMappers from 'utils/ResponseMappers';

const fetchAllFunds = async () => {
  try {
    const res = await Services.getFunds();
    return res?.data?.data;
  } catch (_) {
    return [];
  }
};

const fetchFundDetail = async (fundId) => {
  const res = await Services.getFund(fundId);

  return ResponseMappers.fundDetails(res?.data);
};

const fetchFundDataRooms = async (fundId) => {
  try {
    const res = await Services.getFundDataRooms(fundId);
    return res?.data;
  } catch (_) {
    return [];
  }
};

export default {
  fetchAllFunds,
  fetchFundDetail,
  fetchFundDataRooms,
};
