import * as yup from 'yup';

const beneficialOwnerListValidationSchema = yup.object({
  beneficialOwners: yup.array().min(1).max(5),
  isConfirmedNoOtherBeneficialOwner: yup
    .bool()
    .required('Please accept the condition.')
    .isTrue('Please accept the condition.')
    .label('Agreement'),
});

export default beneficialOwnerListValidationSchema;
