import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import AddButton from 'components/Button/AddButton';
import Button from 'components/Button';
import { mapSummaryChartResponse } from 'utils/ResponseMappers/admin/fundDetails';

import Accordion from './Accordion';
import SummaryContent from './SummaryContent';

const defaultPieSection = {
  sliceDetails: '',
  value: '',
  color: '',
};

const defaultChart = {
  visibleOnTabs: [],
  chartTitle: '',
  pieSections: [],
  pieNote: '',
};

const defaultSummaryBlock = {
  title: '',
  fields: [],
  summaryNote: '',
};

const defaultSummaryChart = {
  chart: defaultChart,
  summaryBlock: defaultSummaryBlock,
};

const SummaryChart = ({
  setFieldValue,
  values,
  fieldKey,
  tabTitleOptions = [],
  setDirty,
  onSubmit,
  disableSaveBtn,
  readOnly,
}) => {
  const [deletedSummaries, setDeletedSummaries] = useState([]);
  const initiated = useRef();

  const handleSubmit = async (formValues, formikHelper) => {
    const newFormValues = [...formValues, ...deletedSummaries];
    const res = await onSubmit({ [fieldKey]: newFormValues }, formikHelper);

    // For Edit / Delete Summary chart purpose. Need ID.
    const newSummaryCharts = res.summaryCharts.map(mapSummaryChartResponse);

    formikHelper.setValues(newSummaryCharts);
    setFieldValue(fieldKey, newSummaryCharts, undefined, false);
    setDeletedSummaries([]);

    // Reset initiated to false, because will update `summaryChartForm` value.
    initiated.current = false;
  };

  const summaryChartForm = useFormik({
    initialValues: values,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    initiated.current = false;
  }, []);

  useEffect(() => {
    if (!initiated.current) {
      initiated.current = true;
      return;
    }

    setDirty(true);
  }, [summaryChartForm.values]);

  const handleAddSummary = useCallback(() => {
    const newValues = [...summaryChartForm.values];
    newValues.push(defaultSummaryChart);

    summaryChartForm.setValues(newValues);
  }, [summaryChartForm.values]);

  const handleChangeSummaryChart = useCallback(
    ({ index, fieldName, e }) => {
      const { name, value } = e.target;
      const newValues = [...summaryChartForm.values];
      const newValue = { ...newValues[index] };

      newValue[fieldName] = { ...newValue[fieldName], [name]: value };
      newValues[index] = newValue;

      summaryChartForm.setValues(newValues);
    },
    [summaryChartForm.values],
  );

  const handleAddPieSection = useCallback(
    ({ index, ...params }) => {
      const newPieSections = [
        ...summaryChartForm.values[index].chart.pieSections,
      ];

      newPieSections.push(defaultPieSection);

      const e = {
        target: {
          name: 'pieSections',
          value: newPieSections,
        },
      };
      handleChangeSummaryChart({ index, ...params, e });
    },
    [handleChangeSummaryChart],
  );

  const handleDeleteSummary = useCallback(
    (index) => {
      const newSummaryCharts = [...summaryChartForm.values];
      const deletedSummary = { ...newSummaryCharts[index] };
      deletedSummary.chart.isDeleted = true;

      setDeletedSummaries((prevDeletedSummaries) => [
        ...prevDeletedSummaries,
        deletedSummary,
      ]);

      newSummaryCharts.splice(index, 1);

      summaryChartForm.setValues(newSummaryCharts);
    },
    [summaryChartForm.values],
  );

  return (
    <Grid container rowSpacing={2}>
      <Grid item>
        {summaryChartForm.values.map((value, index) => (
          <Accordion
            key={`summary-accordion-${index.toString()}`}
            index={index}
            id={index}
            onDelete={!readOnly && handleDeleteSummary}
          >
            <SummaryContent
              index={index}
              readOnly={readOnly}
              tabTitleOptions={tabTitleOptions}
              onChange={handleChangeSummaryChart}
              values={value}
              onAddPieSection={handleAddPieSection}
            />
          </Accordion>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item>
        <AddButton
          label="Add Summary"
          iconColor="black"
          textColor="black"
          sx={{ marginTop: 0 }}
          onClick={handleAddSummary}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12} textAlign="right">
        <Button
          fullWidth={false}
          data-testid="submit-btn-summaryChart"
          buttoncolor="purpleHazeGradient"
          sx={{ padding: '10px' }}
          onClick={summaryChartForm.submitForm}
          disabled={disableSaveBtn || readOnly}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(SummaryChart);
