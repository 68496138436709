import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { PORTFOLIO_PAYMENT_DETAILS_FIELD_DISPLAY } from 'constants/dashboard';

import palette from 'theme/palette';

import FieldContainer from 'components/FieldContainer';
import { getPaymentStatus } from 'constants/investmentEntity';
import { getFundStatusChipColor } from 'utils/chipMappers';

const PaymentDetails = ({ tableOnly = false, investment }) => (
  <Grid container>
    {!tableOnly ? (
      <>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1">Payment Details</Typography>
        </Grid>
        <Grid item xs={12} mt={1.5} textAlign="center">
          <Typography>
            Please transfer the following amount for the number of units:
          </Typography>
        </Grid>
      </>
    ) : null}
    <Grid
      container
      sx={{
        background: palette.reach.white,
        borderWidth: '1px 1px 1px 8px',
        borderStyle: 'solid',
        borderColor:
          palette?.[
            `${
              getFundStatusChipColor({
                status: investment?.portfolio?.status,
                paymentStatus: investment?.portfolio?.paymentStatus,
              }) || 'draftChipColor'
            }`
          ]?.main,
        padding: '12px 46px',
        marginTop: '32px',
      }}
    >
      {PORTFOLIO_PAYMENT_DETAILS_FIELD_DISPLAY.map(
        ({ label, name, type, xs, md, renderer = (value) => value }) => (
          <Grid item xs={xs} md={md} key={`field-${label}`}>
            <FieldContainer label={label}>
              {type === 'text' ? (
                <Typography variant="display5">
                  {renderer(investment?.[name])}
                </Typography>
              ) : (
                <Chip
                  label={getPaymentStatus(investment?.portfolio?.paymentStatus)}
                  color={getFundStatusChipColor({
                    status: investment?.portfolio?.status,
                    paymentStatus: investment?.portfolio?.paymentStatus,
                  })}
                  sx={{
                    height: '20px',
                  }}
                />
              )}
            </FieldContainer>
          </Grid>
        ),
      )}
    </Grid>
  </Grid>
);

export default PaymentDetails;
