import * as yup from 'yup';

import generalValidations from '../general';

const financialInformationValidationSchema = yup.object({
  // Fund Financial Information
  amountToRaise: yup.string().notRequired(),
  callable: yup.string().notRequired(),
  currency: yup.string().notRequired(),
  unitPrice: yup.string().notRequired(),
  accessFee: yup.string().notRequired(),
  minInvestment: yup.string().notRequired(),
  maxInvestment: yup.string().notRequired(),

  // Bank Detail
  bankName: generalValidations.bankName(yup).notRequired().nullable(true),
  accountName: generalValidations.accountName(yup).notRequired().nullable(true),
  accountNumber: generalValidations.accountNumber(yup).notRequired(),
  bsb: generalValidations.bsb(yup).notRequired(),
  bankAddress: generalValidations.bankAddress(yup).notRequired(),
  swiftCode: generalValidations.swiftCode(yup).notRequired(),
  country: generalValidations.country(yup).notRequired(),
});

export default financialInformationValidationSchema;
