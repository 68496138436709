import {
  dateCellRenderer,
  fundPostStatusChipCellRenderer,
  fundStatusChipCellRenderer,
  fundVisibilityChipCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const fundHeaders = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.3,
    cellClassName: 'cellBold',
  },
  {
    field: 'fundName',
    headerName: 'Fund Name',
    flex: 1.5,
    cellClassName: 'cellBold',
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 0.75,
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 0.75,
    cellClassName: 'cellBold',
  },
  {
    field: 'isHidden',
    headerName: 'Fund Visibility',
    flex: 0.5,
    renderCell: fundVisibilityChipCellRenderer,
  },
  {
    field: 'isOpen',
    headerName: 'Fund Status',
    flex: 0.5,
    renderCell: fundStatusChipCellRenderer,
  },
  {
    field: 'status',
    headerName: 'Post Status',
    flex: 0.75,
    renderCell: fundPostStatusChipCellRenderer,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.75,
    renderCell: dateCellRenderer,
  },
];

export const getFundTotalHeader = (totalValue) => [
  { totalLabel: 'Total Funds', totalValue },
];
