import React, { memo, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useMediaQuery } from '@mui/material';

import history from 'utils/history';
import WarningIcon from 'assets/icons/warning-2.svg';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import Stepper from 'components/Stepper';
import ShowMe from 'components/ShowMe';
import TextButton from 'components/Button/TextButton';

import useDialog from 'hooks/useDialog';
import palette from 'theme/palette';
import InvestmentEntityContainer from './InvestmentEntityContainer';

const DialogContent = (
  <Typography variant="bodyLead">
    Are you sure want to cancel this process?
    <br />
    This will discard <b>ALL</b> unsaved data,
    <br />
    Do you want to continue?
  </Typography>
);

const handleGoToInitialPage = () =>
  history.push('/add-investment-entity', history?.location?.state);

const InvestmentEntityStepContainer = ({
  title = 'Investor Details',
  stepTitle,
  children,
  containerProps = {},
  stepperContainerProps = {},
  steps = [],
  activeStep,
  isJoint,
  interceptExit = true,
  withCancelAll = true,
  withStepper = true,
  fullWidthChildren = false,
  headChildren,
  lineStepper = false,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { isDialogOpen, handleOpenDialog, handleCloseDialog } = useDialog();

  useEffect(() => {
    const unloadCallback = (e) => {
      if (!interceptExit) return;
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', unloadCallback);

    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, [interceptExit]);

  return (
    <InvestmentEntityContainer {...containerProps}>
      <Grid
        container
        rowSpacing={[1, 4.5]}
        direction="column"
        textAlign={{ xs: 'left', md: 'center' }}
      >
        <ShowMe when={withCancelAll}>
          <Grid item textAlign="right">
            <TextButton
              variant="buttonTextLight"
              Icon={CloseIcon}
              onClick={handleOpenDialog}
            >
              Cancel All
            </TextButton>
          </Grid>
        </ShowMe>
        <ShowMe when={title}>
          <Grid item>
            <Typography
              variant="display2"
              data-testid="investment-entity-title"
            >
              {title}
            </Typography>
          </Grid>
        </ShowMe>
        <ShowMe when={withStepper}>
          <Grid container item justifyContent="center">
            <Grid item xs={12} sm={10} md={8} textAlign="left" {...stepperContainerProps}>
              <ShowMe when={!isMobileScreen && !lineStepper}>
                <Stepper steps={steps} activeStep={activeStep} />
              </ShowMe>
              <ShowMe when={isMobileScreen || lineStepper}>
                <Typography variant="title">
                 {steps[activeStep]?.label} 
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={`${(100 * (activeStep + 1)) / steps.length}`}
                  sx={{
                    height: '8px',
                    backgroundColor: '#BFBFBF',
                    '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate':
                      {
                        backgroundColor: palette.text.fuchsia,
                      },
                  }}
                />
              </ShowMe>
            </Grid>
          </Grid>
        </ShowMe>
        <Grid
          container
          item
          columnSpacing={3}
          justifyContent="center"
          mt={{ xs: 2, md: 0 }}
          px={{ xs: 2, md: 0 }}
        >
          <Grid item xs={12} md={8}>
            {headChildren}
            {stepTitle && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3" textAlign="left">
                    {stepTitle} {isJoint && '(Joint)'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ margin: '12px 0 18px' }} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={fullWidthChildren ? 12 : 8} textAlign="left">
            {children}
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={isDialogOpen}
        title="Warning"
        icon={WarningIcon}
        contentText={DialogContent}
        infoText="This action cannot be undone."
        onClose={handleCloseDialog}
        onConfirm={handleGoToInitialPage}
      />
    </InvestmentEntityContainer>
  );
};

export default memo(InvestmentEntityStepContainer);
