import React, { useMemo } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import palette from 'theme/palette';
import { REPORTING_TEMPLATE_OPEN_END } from 'constants/funds';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
  scales: {
    y: {
      ticks: {
        maxTicksLimit: 5,
      },
    },
  },
  maintainAspectRatio: false,
};

const MarketTransactionLineChart = ({ transactions = [], portfolio }) => {
  const lineChartData = useMemo(() => {
    const getData = (transaction) =>
      portfolio?.fund?.reportingTemplate === REPORTING_TEMPLATE_OPEN_END
        ? transaction.unitPriceNet
        : transaction.investmentValue;

    return [...transactions]?.reverse?.()?.reduce(
      (data, transaction) => ({
        labels: data.labels.concat(
          new Date(transaction.date).toLocaleString('default', {
            month: 'long',
            year: 'numeric',
          }),
        ),
        datasets: [
          {
            label: '',
            borderColor: palette.reach.fuchsia,
            backgroundColor: '#D9D9D9',
            data: data.datasets[0]?.data?.concat(getData(transaction)),
          },
        ],
      }),
      { labels: [], datasets: [{ data: [] }] },
    );
  }, [transactions]);

  const headerText = useMemo(
    () =>
      portfolio?.fund?.reportingTemplate === REPORTING_TEMPLATE_OPEN_END
        ? 'Unit Price'
        : 'Estimated Investment Value (funded)',
    [portfolio],
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Typography variant="body" fontWeight="700 !important">
          {headerText}
        </Typography>
      </Grid>
      {/* <Grid container item xs={12}>
        <Grid container item columnGap={4}>
          <Grid item>
            <Typography variant="bodyLead" fontWeight={600}>
              {thousandSeparator(150000)}
            </Typography>
          </Grid>
          <Grid
            item
            minWidth={100}
            sx={{ backgroundColor: '#EFFBEE' }}
            textAlign="center"
            alignContent="center"
          >
            <Typography variant="small" color="#4B9152">
              +1.66%
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {getFullDateString()}
        </Grid>
      </Grid> */}

      <Grid item xs={12} maxHeight={400}>
        <Line options={options} data={lineChartData} />
      </Grid>
    </Grid>
  );
};

export default MarketTransactionLineChart;
