import React from 'react';
import SummarySection from 'components/SummarySection';

import { PAYMENT_DETAILS_FIELD_WITH_APPROVAL_DATE } from 'constants/investmentEntity';

const PaymentDetailsSummary = ({
  title,
  values,
  setValues,
  withEdit = true,
  sectionListView = false,
  onClickEdit,
}) => (
  <SummarySection
    title={title}
    fields={PAYMENT_DETAILS_FIELD_WITH_APPROVAL_DATE}
    values={values}
    setValues={setValues}
    withEdit={withEdit}
    sectionListView={sectionListView}
    onClickEdit={onClickEdit}
  />
);

export default PaymentDetailsSummary;
