import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CountdownTimer from 'components/CountdownTimer';
import OTPInput from 'components/OTPInput';
import ShowMe from 'components/ShowMe';

import condTrueFalse from 'utils/condTrueFalse';
import palette from 'theme/palette';

import Dialog from '.';
import SubmitCancel from './Actions/SubmitCancel';

const MFADialog = ({
  title = 'Multi-factor authentication',
  subtitle = 'A verification code has been sent to your email. Please enter the code below to continue.',
  contentText,
  infoText,
  icon,
  onClose,
  onConfirm,
  dialogContentDependencies = [],
  confirmButtonContainerProps,
  confirmButtonProps,
  errorText,
  onClickResendMFA,
  submitLabel = 'Continue',
  setErrorText,
  timer = 60,
  initialTimer = 60,
  submitBtnDisabled,
  ...props
}) => {
  const [otp, setOtp] = useState('');
  const otpInputRef = useRef();

  useEffect(() => {
    if (!props.open) setOtp('');
  }, [props.open]);

  const handleChange = useCallback((newOtp) => {
    condTrueFalse(setErrorText, () => setErrorText(''));
    setOtp(newOtp);
  }, []);

  const handleSubmitOTP = useCallback(() => {
    condTrueFalse(onConfirm, () => onConfirm(otp));
  }, [onConfirm, otp]);

  const handleResendMFA = useCallback(() => {
    onClickResendMFA();

    otpInputRef?.current?.handleResetOTP?.();
  }, [onClickResendMFA, otpInputRef?.current]);

  const DialogTitle = useMemo(() => title, [title]);

  const DialogContent = useMemo(
    () => (
      <Grid container rowSpacing={3} direction="column" textAlign="center">
        <Grid item>
          <Divider flexItem orientation="horizontal" />
          <Grid item px="24px" my="24px">
            <Typography variant="body">{subtitle}</Typography>
          </Grid>
          <Divider flexItem orientation="horizontal" />
        </Grid>
        <Grid container item rowSpacing={1} direction="column" px={3}>
          <Grid item>
            <Typography variant="display6">
              Enter the 6-digit authentication code
            </Typography>
          </Grid>
          <Grid item>
            <OTPInput
              submitOnChange
              ref={otpInputRef}
              inputtype={errorText ? 'error' : ''}
              onSubmit={handleChange}
              value={otp}
            />
          </Grid>
          <ShowMe when={infoText}>
            <Grid item>
              <Typography variant="xsmall" fontStyle="italic" color="black">
                {infoText}
              </Typography>
            </Grid>
          </ShowMe>
          <ShowMe when={props.open}>
            <Grid item xs={12}>
              <Typography variant="body" color={palette.reach.red}>
                {errorText}
              </Typography>
              <ShowMe when={onClickResendMFA}>
                <CountdownTimer
                  showTimer={!!timer}
                  initialTimer={initialTimer}
                  timer={timer}
                  onClick={handleResendMFA}
                  label="Get a new code"
                />
              </ShowMe>
            </Grid>
          </ShowMe>
        </Grid>
      </Grid>
    ),
    [
      subtitle,
      handleResendMFA,
      initialTimer,
      timer,
      errorText,
      otp,
      ...dialogContentDependencies,
      confirmButtonProps,
    ],
  );

  return (
    <Dialog
      title={DialogTitle}
      content={DialogContent}
      dialogActions={
        <SubmitCancel
          cancelLabel="Cancel"
          submitLabel={submitLabel}
          onCancelClick={onClose}
          onSubmitClick={handleSubmitOTP}
          submitDisabled={otp.length < 6 || submitBtnDisabled}
        />
      }
      // onClose={onClose}
      maxWidth="sm"
      dialogTitleProps={{
        mt: 2,
        justifyContent: 'auto',
        alignItems: 'auto',
        variant: 'display4',
        minHeight: '32px',
      }}
      dialogContentProps={{
        sx: { padding: 0, overflowY: 'hidden' },
        style: { maxHeight: '500px' },
      }}
      {...props}
    />
  );
};

export default MFADialog;
