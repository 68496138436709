import getSelectedCountry from 'utils/getSelectedCountry';
import mapValue from 'utils/mapValue';

const user = (usersValue) => ({
  firstName: mapValue(usersValue?.firstName, ''),
  middleName: mapValue(usersValue?.middleName, ''),
  lastName: mapValue(usersValue?.lastName, ''),
  role: mapValue(usersValue?.role, ''),
  status: mapValue(usersValue?.status, ''),
  countryCode: getSelectedCountry(usersValue?.parsedMobileNumber?.code),
  phoneNumber: mapValue(usersValue?.parsedMobileNumber?.number, ''),
  email: mapValue(usersValue?.email, ''),
  password: null, // adjustment to avoid empty validation on BE if user didn't change the password
});

export default user;
