import React, { useCallback, useMemo, useState } from 'react';

import { Grid, Typography, IconButton, useMediaQuery } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ShowMe from 'components/ShowMe';

import condTrueFalse from 'utils/condTrueFalse';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import Chip from 'components/Chip';
import Card from './components/Card';
import CardContainer from './components/CardContainer';
import CardHeader from './components/CardHeader';
import CardBody from './components/CardBody';
import CardFooter from './components/CardFooter';

const InvestorCard = ({
  testId,
  id,
  iconType,
  investorName,
  children,
  isJoint = false,
  chipText,
  chipColor,
  chipSx,
  additionalChipText,
  additionalChipColor = 'individual',
  footer,
  showChildren = true,
  showHeader = true,
  onDelete,
  cardContainerStyles,
  accordion = false,
  onExpandMore,
  onExpandLess,
  cardBodyProps = {},
  showChip = true,
  showFooterWhenExpand = false,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isExpanding, setIsExpanding] = useState(false);

  const handleAccordionClick = useCallback(() => {
    condTrueFalse(isExpanding, onExpandLess, onExpandMore);
    setIsExpanding(!isExpanding);
  }, [isExpanding, onExpandLess, onExpandMore]);

  const icon = useMemo(() => {
    const iconProps = { width: '20px', height: '20px' };
    switch (iconType) {
      case 'warning':
        return <WarningIcon {...iconProps} />;
      case 'info':
        return <InfoIcon {...iconProps} className="active-icon-color" />;
      case 'success':
        return <SuccessIcon {...iconProps} />;
      default:
        return null;
    }
  }, [iconType]);

  const ExpandingIcon = useMemo(() => {
    if (isExpanding) return ExpandLessIcon;
    return ExpandMoreIcon;
  }, [isExpanding]);

  const BodyContent = useMemo(() => {
    switch (true) {
      case !showChildren:
        return null;
      case accordion:
        return isExpanding ? (
          children
        ) : (
          <Typography variant="smallGrey">Click to open the details</Typography>
        );
      default:
        return children;
    }
  }, [children, isExpanding, showChildren]);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const chipComponents = useMemo(
    () => (
      <>
        {additionalChipText && (
          <Grid item>
            <Chip
              label={additionalChipText}
              color={additionalChipColor}
              sx={chipSx}
              lowercase={false}
            />
          </Grid>
        )}
        {showChip && (
          <Grid item>
            <Chip
              label={chipText || condTrueFalse(isJoint, 'Joint', 'Individual')}
              color={chipColor || condTrueFalse(isJoint, 'joint', 'individual')}
              sx={chipSx}
              lowercase={false}
            />
          </Grid>
        )}
      </>
    ),
    [
      additionalChipText,
      additionalChipColor,
      showChip,
      chipText,
      chipColor,
      isJoint,
    ],
  );

  return (
    <CardContainer
      container
      ischild={isJoint.toString()}
      style={cardContainerStyles}
      data-testid={testId}
    >
      <Card container direction="row" ischild={isJoint.toString()}>
        {showHeader ? (
          <CardHeader
            container
            justifyContent="space-between"
            columnSpacing={1.5}
            ml={0}
          >
            <Grid container item direction="row" xs="auto" alignItems="center">
              <ShowMe when={!!icon}>
                <Grid item sx={{ marginRight: '20px' }}>
                  {icon}
                </Grid>
              </ShowMe>
              <Grid item>
                <Typography variant="cardNameLabel">{investorName}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              xs="auto"
              columnSpacing={1.5}
              alignItems="center"
            >
              <ShowMe when={!isMobileScreen}>{chipComponents}</ShowMe>
              {onDelete && (
                <Grid item>
                  <IconButton onClick={handleDelete}>
                    <TrashIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <ShowMe when={accordion}>
              <Grid sx={{ marginLeft: 'auto' }}>
                <IconButton onClick={handleAccordionClick}>
                  <ExpandingIcon />
                </IconButton>
              </Grid>
            </ShowMe>
            <ShowMe when={isMobileScreen}>
              <Grid container item xs={12} columnSpacing={1} rowSpacing={1}>
                {chipComponents}
              </Grid>
            </ShowMe>
          </CardHeader>
        ) : null}

        {showChildren && React.Children.count(BodyContent) !== 0 && (
          <CardBody
            sx={{
              textAlign: condTrueFalse(
                accordion && !isExpanding,
                'center',
                'left',
              ),
            }}
            padding={{
              xs: '8px',
              md: condTrueFalse(isExpanding, '32px', '16px 32px'),
            }}
            {...cardBodyProps}
          >
            {BodyContent}
          </CardBody>
        )}

        {(showFooterWhenExpand ? footer && isExpanding : footer) && (
          <CardFooter>{footer}</CardFooter>
        )}
      </Card>
    </CardContainer>
  );
};

export default InvestorCard;
