import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import Dialog from '.';

const AdminConfirmationDialog = ({
  onClose,
  onConfirm,
  confirmBtnDisabled,
  confirmButtonLabel = 'Confirm',
  title,
  subtitle,
  children,
  ...props
}) => (
  <Dialog
    content={
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography variant="body">{subtitle}</Typography>
        </Grid>
        {children && (
          <Grid item xs={12}>
            {children}
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          container
          columnSpacing={1.5}
          justifyContent="flex-end"
        >
          {onClose && (
            <Grid item xs={6} md={3}>
              <Button
                data-testid="button-cancel"
                textColor="bluish"
                variant="text"
                buttoncolor="transparent"
                onClick={onClose}
                sx={{ padding: '10px 34px' }}
              >
                <Typography variant="body">Cancel</Typography>
              </Button>
            </Grid>
          )}
          {onConfirm && (
            <Grid item xs={6} md={3}>
              <Button
                data-testid="button-confirm"
                buttoncolor="purpleHazeGradient"
                onClick={onConfirm}
                disabled={confirmBtnDisabled}
                sx={{ padding: '10px 34px' }}
              >
                {confirmButtonLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    }
    title={
      <Grid container alignItems="center" display="block">
        <Grid item xs="auto">
          <Typography variant="display3">{title}</Typography>
        </Grid>
        <Grid item xs>
          <Divider flexItem variant="middle" />
        </Grid>
      </Grid>
    }
    onClose={onClose}
    {...props}
  />
);

export default AdminConfirmationDialog;
