import React from 'react';
import Typography from '@mui/material/Typography';

import condTrueFalse from 'utils/condTrueFalse';
import Services from 'api/services';

import { ReactComponent as DataRoomIcon } from 'assets/icons/data-room.svg';
import { ReactComponent as FundIcon } from 'assets/icons/fund.svg';
import { ReactComponent as PercentIcon } from 'assets/icons/percent.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as BookmarkBorderIcon } from 'assets/icons/bookmark-border.svg';

import CompanyTaxDetail from './DetailsTab/CompanyTaxDetail';
import PersonalDetails from './DetailsTab/PersonalDetails';
import BeneficialOwners from './DetailsTab/BeneficialOwners';
import TrustDetails from './DetailsTab/TrustDetails';
import Trustees from './DetailsTab/Trustees';
import CompanyDetails from './DetailsTab/CompanyDetails';
import TaxDetailList from './DetailsTab/TaxDetailList';
import BankDetails from './DetailsTab/BankDetails';

export const IEDetailsTabItems = ({ type = '', ...props }) => {
  const tabItems = [
    {
      key: 'taxDetails',
      Icon: PercentIcon,
      label: <Typography variant="body">Tax Details</Typography>,
      content:
        type.toLowerCase() === 'individual' ? (
          <TaxDetailList {...props} />
        ) : (
          <CompanyTaxDetail {...props} />
        ),
    },
    {
      key: 'bankDetails',
      Icon: BookmarkBorderIcon,
      label: <Typography variant="body">Bank Details</Typography>,
      content: <BankDetails {...props} />,
    },
  ];
  switch (type.toLowerCase()) {
    case 'individual':
      return [
        {
          key: 'personalDetail',
          Icon: PersonIcon,
          label: <Typography variant="body">Personal Details</Typography>,
          content: <PersonalDetails {...props} />,
        },
        ...tabItems,
      ];
    case 'company':
      return [
        {
          key: 'companyDetail',
          Icon: FundIcon,
          label: <Typography variant="body">Company Details</Typography>,
          content: <CompanyDetails {...props} />,
        },
        {
          key: 'beneficialOwners',
          Icon: DataRoomIcon,
          label: <Typography variant="body">Beneficial Owner</Typography>,
          content: (
            <BeneficialOwners
              valueKey="companyBeneficialOwner"
              entityUpdateKey="CompanyBeneficialOwner"
              {...props}
            />
          ),
        },
        ...tabItems,
      ];

    default:
      return [
        {
          key: 'trustDetails',
          Icon: FundIcon,
          label: <Typography variant="body">Trust Details</Typography>,
          content: <TrustDetails {...props} />,
        },
        {
          key: 'trustee',
          Icon: DataRoomIcon,
          label: <Typography variant="body">Trustee</Typography>,
          content: <Trustees valueKey="trustees" {...props} />,
        },
        {
          key: 'beneficialOwners',
          Icon: DataRoomIcon,
          label: <Typography variant="body">Beneficial Owner</Typography>,
          content: (
            <BeneficialOwners
              valueKey="companyBeneficialOwner"
              entityUpdateKey="CompanyBeneficialOwner"
              {...props}
            />
          ),
        },
        ...tabItems,
      ];
  }
};

export const IEDetailsPropsValueMappers = ({ values }) => {
  const baseProps = {
    bankDetailsProps: { values: values?.detail?.bankDetail },
    taxDetailsProps: condTrueFalse(
      values?.type.toLowerCase() === 'individual',
      { values: values?.detail?.taxDetail },
      {
        taxDetails: values?.detail?.taxDetails,
        supportingInformation: values?.detail?.supportingInformation,
        entityUpdateKey: 'TaxJurisdiction',
      },
    ),
  };
  switch (values.type.toLowerCase()) {
    case 'individual':
      return {
        ...baseProps,
        personalDetailProps: { values: values?.detail?.personalDetail },
      };
    case 'company':
      return {
        ...baseProps,
        companyDetailProps: {
          values: values?.detail?.companyDetail,
        },
        beneficialOwnersProps: {
          values: values?.detail?.beneficialOwners,
          title: 'Beneficial Details',
        },
      };

    default:
      return {
        ...baseProps,
        beneficialOwnersProps: {
          values: values?.detail?.trustBeneficialOwner,
          title: 'Beneficial Details',
        },
        trusteeProps: {
          values: values?.detail?.trustees,
          title: 'Trustee Individual Details',
        },
        trustDetailsProps: { values },
      };
  }
};

export const fetchAdminApprovalEntity = async (entityId) => {
  try {
    const res = await Services.adminGetApprovalEntity(entityId);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const fetchEntityHistory = async (entityId) => {
  try {
    const res = await Services.adminGetApprovalEntityHistory(entityId);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const entityApprove = async ({
  entityId,
  sendNotificationEmailToUser = true,
}) => {
  try {
    const payload = {
      id: entityId,
      type: 'entity',
      sendNotificationEmailToUser,
    };
    const res = await Services.adminApprove(payload);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const entityStatusChange = async ({ entityId, status, reason }) => {
  try {
    const payload = {
      id: entityId,
      type: 'entity',
      status,
      reason,
    };
    const res = await Services.adminChangeStatus(payload);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const updateApproval = async ({ id, payload, callback, type = '' }) => {
  try {
    let res;
    switch (type.toLowerCase()) {
      case 'individual': {
        res = await Services.editInvestmentEntityIndividual({ id, payload });
        break;
      }
      case 'company': {
        res = await Services.editInvestmentEntityCompany({ id, payload });
        break;
      }
      case 'trust': {
        res = await Services.editInvestmentEntityTrust({ id, payload });
        break;
      }
      default:
        break;
    }
    if (typeof callback === 'function') callback();
    return res?.data;
  } catch (_) {
    return {};
  }
};
