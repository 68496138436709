import React from 'react';
import IndividualTaxDetail from './IndividualTaxDetail';

const TaxDetailList = ({ values, ...props }) =>
  values.map((taxDetail) => (
    <IndividualTaxDetail
      values={taxDetail}
      key={`taxDetail-${taxDetail?.id}`}
      {...props}
    />
  ));

export default TaxDetailList;
