import React from 'react';

import TextField from '@mui/material/TextField';

import AdminConfirmationDialog from './AdminConfirmationDialog';

const AdminFeedbackDialog = ({ onReasonChange, ...props }) => (
  <AdminConfirmationDialog
    data-testid="feedback-confirmation-dialog"
    title="Feedback Reason"
    subtitle="Enter feedback reason for the investor"
    {...props}
  >
    <TextField
      fullWidth
      multiline
      rows={3}
      data-testid="input-feedback"
      id="feedback"
      name="feedback"
      placeholder="Feedback"
      onBlur={onReasonChange}
    />
  </AdminConfirmationDialog>
);

export default AdminFeedbackDialog;
