import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Chip from 'components/Chip';
import EllipsisTypography from 'components/Typography/EllipsisTypography';
import ShowMe from 'components/ShowMe';

import { getPaymentStatus } from 'constants/investmentEntity';
import { getFundStatusChipColor } from 'utils/chipMappers';
import { FUND_STATUS } from 'constants/funds';

import FundCardButton from '../components/FundCardButton';
import FundCardLandscapeContent from '../components/FundCardLandscapeContent';

const FundContent = ({
  status,
  title,
  description,
  onClickViewDetail,
  contentList,
  statusStyles,
  buttonStyles,
  contentStyles,
  isInvested,
  portfolios = [],
  isActive,
  viewOnly,
}) => {
  const { btnLabel, btnOnClick, btnDisabled } = useMemo(() => {
    if (status === FUND_STATUS.Closed)
      return {
        btnLabel: isActive ? 'View details' : 'Fund Closed',
        btnOnClick: onClickViewDetail,
        btnDisabled: false,
      };

    return {
      btnLabel: 'View details',
      btnOnClick: onClickViewDetail,
      btnDisabled: false,
    };
  }, [status, isActive]);

  const fundPortfolioStatusChips = useMemo(
    () => (
      <ShowMe when={isInvested && portfolios.length}>
        {[
          ...new Map(
            portfolios.map((portfolio) => [
              portfolio?.paymentStatus,
              portfolio,
            ]),
          ).values(),
        ].map((portfolio, index) => (
          <Chip
            key={portfolio.id}
            style={{ marginLeft: index ? 'auto' : '8px', marginRight: '8px' }}
            height="20px"
            label={getPaymentStatus(portfolio.paymentStatus)}
            color={getFundStatusChipColor({
              status: portfolio.status,
              paymentStatus: portfolio.paymentStatus,
            })}
          />
        ))}
      </ShowMe>
    ),
    [isInvested, portfolios],
  );

  return (
    <>
      <ShowMe when={!viewOnly}>
        <div
          style={{
            position: 'absolute',
            top: '-8px',
            color: 'white',
            backgroundColor: 'black',
            padding: '0px 20px',
            ...statusStyles,
          }}
        >
          <Typography variant="body" color="white">
            {status}
          </Typography>
        </div>
      </ShowMe>

      <Grid container item xs={8} direction="row">
        <Grid item xs={8} alignSelf="center">
          <EllipsisTypography
            variant="display3"
            display="block"
            ellipsisAtLine={2}
          >
            {title}
          </EllipsisTypography>
          <EllipsisTypography
            variant="body"
            marginTop={1}
            style={{ display: 'inline' }}
          >
            {description}
          </EllipsisTypography>
          {fundPortfolioStatusChips}
        </Grid>
        <ShowMe when={!viewOnly}>
          <Grid item xs={4} textAlign="center" alignSelf="center">
            <FundCardButton
              disabled={btnDisabled}
              label={btnLabel}
              sx={{ width: '100%', maxWidth: '140px', ...buttonStyles }}
              onClick={btnOnClick}
              labelProps={{ color: buttonStyles.color }}
            />
          </Grid>
        </ShowMe>
      </Grid>
      <Grid container item xs={4} sx={contentStyles}>
        <FundCardLandscapeContent contentList={contentList} />
      </Grid>
    </>
  );
};
export default memo(FundContent);
