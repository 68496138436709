import React from 'react';

import Grid from '@mui/material/Grid';

import EndStepContainer from 'components/EndStepContainer';
import Button from 'components/Button';

const EmptyInvestmentEntity = ({ onClickAddInvestmentEntity }) => (
  <EndStepContainer
    title="Add an Investment Entity"
    containerStyles={{
      padding: '55px',
      textAlign: 'center',
    }}
    childrenContainerProps={{
      container: true,
      rowSpacing: 5,
      justifyContent: 'center',
    }}
  >
    <Grid item xs={5} md={4}>
      <Button onClick={onClickAddInvestmentEntity}>
        Add Investment Entity
      </Button>
    </Grid>
  </EndStepContainer>
);

export default EmptyInvestmentEntity;
