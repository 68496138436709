import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  memo,
} from 'react';
import * as pdfjs from 'pdfjs-dist';
import { VariableSizeList } from 'react-window';
import useResizeObserver from 'use-resize-observer';
import { useMediaQuery } from '@mui/material';

import PDFPageWrapper from './PDFPageWrapper';
import PDFPage from './PDFPage';

const PDFViewer = ({
  pdfData,
  width = 'inherit',
  height = '99%',
  zoomScale = 1,
  pageGap = 40,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const windowRef = useRef();
  const listRef = useRef();
  const pdfRef = useRef();

  const [itemCount, setItemCount] = useState(0);
  const [pages, setPages] = useState([]);

  const {
    ref,
    width: internalWidth = 400,
    height: internalHeight = 600,
  } = useResizeObserver();

  const getPdfPage = useCallback(
    (index) => pdfRef.current.getPage(index + 1),
    [],
  );

  const fetchPage = useCallback(
    (index) => {
      if (!pages[index]) {
        getPdfPage(index).then((page) => {
          setPages((prev) => {
            const next = [...prev];
            next[index] = page;
            return next;
          });
          listRef.current.resetAfterIndex(index);
        });
      }
    },
    [getPdfPage, pages],
  );

  const handleItemSize = useCallback(
    (index) => {
      const page = pages[index];
      if (page) {
        const viewport = page.getViewport({ scale: zoomScale });
        return viewport.height + pageGap;
      }
      return 50;
    },
    [pages, zoomScale, pageGap],
  );

  const handleListRef = useCallback(
    (elem) => {
      listRef.current = elem;
      if (windowRef) {
        windowRef.current = elem;
      }
    },
    [windowRef],
  );

  useEffect(() => {
    listRef.current.resetAfterIndex(0);
  }, [zoomScale]);

  const wrapperStyle = useMemo(
    () => ({
      width,
      height,
      border: '1px solid #ccc',
      background: '#ddd',
    }),
    [width, height],
  );

  useEffect(() => {
    const initiatePDF = async () => {
      if (pdfData) {
        const loadingTask = pdfjs.getDocument({ data: pdfData });
        const pdf = await loadingTask.promise;
        pdfRef.current = pdf;

        // eslint-disable-next-line
        setItemCount(pdf._pdfInfo.numPages);

        // Fetch the first page
        const pageNumber = 1;
        pdf.getPage(pageNumber);
      }
    };
    initiatePDF();
  }, [pdfData]);

  return (
    <div ref={ref} style={wrapperStyle}>
      <VariableSizeList
        ref={handleListRef}
        width={internalWidth}
        height={internalHeight}
        itemCount={itemCount}
        itemSize={handleItemSize}
      >
        {({ index, style: pageStyle }) => {
          fetchPage(index);
          return (
            <PDFPageWrapper
              pageStyle={{
                ...pageStyle,
                width: `${
                  internalWidth +
                  (zoomScale - 1 + (isMobileScreen ? 0.5 : 0)) * 600
                }px`,
              }}
            >
              <PDFPage page={pages[index]} scale={zoomScale} />
            </PDFPageWrapper>
          );
        }}
      </VariableSizeList>
    </div>
  );
};

export default memo(PDFViewer);
