import api from 'api';
import ENDPOINT from 'api/endpoint';
import axios from 'axios';
import generateQueryParams from 'utils/generateQueryParams';

const checkEmail = (data) => api.post(ENDPOINT.REGISTRATION_CHECK_EMAIL, data);
const resendEmailVerification = (data) =>
  api.post(ENDPOINT.REGISTRATION_RESEND_EMAIL_VERIFICATION, data);
const verifyEmail = (data) =>
  api.post(ENDPOINT.REGISTRATION_VERIFY_EMAIL, data);

const sendMobileNumberVerificationCode = (data) =>
  api.post(ENDPOINT.REGISTRATION_SEND_MOBILE_NUMBER_VERIFICATION_CODE, data);
const resendMobileNumberVerificationCode = (data) =>
  api.post(ENDPOINT.REGISTRATION_RESEND_MOBILE_NUMBER_VERIFICATION_CODE, data);
const verifyMobileNumberVerificationCode = (data) =>
  api.post(ENDPOINT.REGISTRATION_VERIFY_MOBILE_NUMBER_VERIFICATION_CODE, data);
const register = (data) => api.post(ENDPOINT.AUTH_REGISTER, data);

const updateProfileSendMobileNumberVerificationCode = (data) =>
  api.post(ENDPOINT.USER_PROFILE_SEND_MOBILE_NUMBER_VERIFICATION_CODE, data);
const updateProfileResendMobileNumberVerificationCode = (data) =>
  api.post(ENDPOINT.USER_PROFILE_RESEND_MOBILE_NUMBER_VERIFICATION_CODE, data);
const updateProfileVerifyMobileNumberVerificationCode = (data) =>
  api.post(ENDPOINT.USER_PROFILE_VERIFY_MOBILE_NUMBER_VERIFICATION_CODE, data);
const updateProfileSaveMobileNumber = (data) =>
  api.post(ENDPOINT.USER_PROFILE_SAVE_MOBILE_NUMBER, data);

const updateConsent = (data) =>
  api.put(ENDPOINT.REGISTRATION_UPDATE_CONSENT, data);

const login = (data) => api.post(ENDPOINT.AUTH_LOGIN, data);
const refreshToken = (data) => api.post(ENDPOINT.AUTH_REFRESH_TOKEN, data);
const loginVerifyMFA = (data) => api.post(ENDPOINT.AUTH_LOGIN_VERIFY_MFA, data);
const logout = () => api.post(ENDPOINT.AUTH_LOGOUT);

const forgotPasswordSendEmail = (data) =>
  api.post(ENDPOINT.FORGOT_PASSWORD_SEND_EMAIL, data);
const forgotPasswordVerifyToken = (data) =>
  api.post(ENDPOINT.FORGOT_PASSWORD_VERIFY, data);
const changePassword = (data) => api.post(ENDPOINT.PASSWORD_RESET, data);
const forgotPasswordResendEmail = (data) =>
  api.post(ENDPOINT.FORGOT_PASSWORD_RESEND_EMAIL, data);

const changePasswordSendEmail = (data) =>
  api.post(ENDPOINT.CHANGE_PASSWORD_SEND_EMAIL, data);
const changePasswordVerifyToken = (data) =>
  api.post(ENDPOINT.CHANGE_PASSWORD_VERIFY, data);

const getUserProfile = () => api.get(ENDPOINT.GET_USER_PROFILE);

const getUserAccessibility = () => api.get(ENDPOINT.GET_USER_ACCESSIBILITY);

const setUserViewFundStatus = () =>
  api.post(ENDPOINT.SET_USER_VIEW_FUND_STATUS);

const getFunds = () => api.get(ENDPOINT.GET_FUNDS);
const getFund = (fundId) => api.get(`${ENDPOINT.GET_FUNDS}/${fundId}`);

const getFundDataRooms = (fundId) =>
  api.get(`${ENDPOINT.GET_FUNDS}/${fundId}/data-rooms`);

const getDataRoomDocumentView = (fundId, token) =>
  api.get(`${ENDPOINT.GET_FUNDS}/${fundId}/data-rooms/view`, {
    headers: {
      'X-Authorization': token,
    },
  });
const getDataRoomDocumentDownload = (fundId, token) =>
  api.get(`${ENDPOINT.GET_FUNDS}/${fundId}/data-rooms/download`, {
    headers: {
      'X-Authorization': token,
    },
  });

// ARTICLES
const getArticles = () => api.get(ENDPOINT.GET_ARTICLES);
const getArticle = (articleId) =>
  api.get(`${ENDPOINT.GET_ARTICLES}/${articleId}`);
const getFeaturedArticles = () => api.get(`${ENDPOINT.GET_ARTICLES}/featured`);
const getArticleDocument = ({ id }) =>
  api.get(`${ENDPOINT.GET_ARTICLES}/article-document/${id}`);

// EVENTS
const getEvents = () => api.get(ENDPOINT.GET_EVENTS);
const getEvent = (eventId) => api.get(`${ENDPOINT.GET_EVENTS}/${eventId}`);

// VIDEOS
const getVideos = () => api.get(ENDPOINT.GET_VIDEOS);
const getVideo = (eventId) => api.get(`${ENDPOINT.GET_VIDEOS}/${eventId}`);

const addInvestmentEntityUpload = ({ id, entityType, payload }) =>
  api.post(
    `${ENDPOINT.ADD_INVESTMENT_ENTITY_UPLOAD}?investorId=${id}&type=${entityType}`,
    payload,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
const addInvestmentEntityIndividual = ({ payload, queryParams }) =>
  api.post(
    `${ENDPOINT.ADD_INVESTMENT_ENTITY_INDIVIDUAL}?${generateQueryParams(
      queryParams,
    )}`,
    payload,
  );
const addInvestmentEntityCompany = ({ payload, queryParams }) =>
  api.post(
    `${ENDPOINT.ADD_INVESTMENT_ENTITY_COMPANY}?${generateQueryParams(
      queryParams,
    )}`,
    payload,
  );
const addInvestmentEntityTrust = ({ payload, queryParams }) =>
  api.post(
    `${ENDPOINT.ADD_INVESTMENT_ENTITY_TRUST}?${generateQueryParams(
      queryParams,
    )}`,
    payload,
  );
const getInvestmentEntityUserDetailList = (userId) =>
  api.get(
    `${ENDPOINT.GET_INVESTMENT_ENTITY_USER_DETAIL_LIST}${
      userId ? `?userId=${userId}` : ''
    }`,
  );

const getPortfolioPayments = () => api.get(ENDPOINT.GET_INVESTMENT_PAYMENTS);
const getInvestedPortofolios = () => api.get(ENDPOINT.GET_INVESTED_INVESTMENTS);
const getInvestedPortofoliosOverview = () =>
  api.get(ENDPOINT.GET_INVESTMENT_OVERVIEW);
const getPortfolioPaymentsByInvestmentEntityId = (investmentEntityId) =>
  api.get(
    `${ENDPOINT.GET_INVESTED_INVESTMENTS}/byInvestmentEntityId/${investmentEntityId}`,
  );
const getPortfolioPendingPaymentsByInvestmentEntityId = () =>
  api.get(ENDPOINT.GET_PENDING_INVESTMENT_PAYMENTS);

const getInvestmentEntityList = () => api.get(ENDPOINT.INVESTMENT_ENTITY);
const getPortfoliosByInvestmentEntity = (investmentEntityId) =>
  api.get(`${ENDPOINT.INVESTMENT_ENTITY}/${investmentEntityId}`);
const getInvestedPortfoliosByInvestmentEntity = (investmentEntityId) =>
  api.get(
    `${ENDPOINT.INVESTMENT_ENTITY}/invested/by-investment-entity-id/${investmentEntityId}`,
  );

const getInvestmentEntityListUser = () => api.get(ENDPOINT.GET_INVESTMENT_USER);
const submitFundInvestment = ({ fundId, payload }) =>
  api.post(`${ENDPOINT.SUBMIT_FUND_INVESTMENT}/${fundId}`, payload);
const redeemCashFundInvestment = ({ portfolioId, payload }) =>
  api.post(`${ENDPOINT.REDEEM_CASH_FUND_INVESTMENT}/${portfolioId}`, payload);

const getDocumentSigning = ({ fundId, fundConfigurationId }) =>
  api.get(
    `${ENDPOINT.GET_FUNDS}/${fundId}/document-sign/${fundConfigurationId}`,
  );

const getDocumentLists = () => api.get(`${ENDPOINT.DOCUMENTS}`);

const getDocument = (id, token) =>
  api.get(`${ENDPOINT.DOCUMENTS}/${id}`, {
    headers: {
      'X-Authorization': token,
    },
  });

const downloadDocuments = (token) =>
  api.get(`${ENDPOINT.DOCUMENTS_DOWNLOAD}`, {
    headers: {
      'X-Authorization': token,
    },
  });
const viewDocuments = ({ folder, token }) =>
  api.get(`${ENDPOINT.DOCUMENTS_VIEW}?folder=${folder}`, {
    headers: {
      'X-Authorization': token,
    },
  });

const getUserTransactions = ({ fundConfigurationId, userInvestorMapId } = {}) =>
  api.get(
    `${ENDPOINT.TRANSACTIONS}/${fundConfigurationId}/${userInvestorMapId}`,
  );

const getPortfolioMarketTransactions = ({
  fundConfigurationId,
  userInvestorMapId,
} = {}) =>
  api.get(
    `${ENDPOINT.MARKET_TRANSACTIONS}/${fundConfigurationId}/${userInvestorMapId}`,
  );
const getUserInvestmentCommitmentsByFundConfigurationId = ({
  fundConfigurationId,
  userInvestorMapId,
} = {}) =>
  api.get(
    `${ENDPOINT.USER_INVESTMENT_COMMITMENTS}/by-fund-configuration-id/${fundConfigurationId}/${userInvestorMapId}`,
  );

const getPendingFundRedemptionRequest = () =>
  api.get(`${ENDPOINT.FUND_REDEMPTION_REQUEST}/pending-redemptions`);
const addFundRedemptionRequest = ({ payload } = {}) =>
  api.post(ENDPOINT.FUND_REDEMPTION_REQUEST, payload);

const adminAddUser = (payload) => api.post(ENDPOINT.ADMIN_USER, payload);
const adminEditUser = (userId, payload) =>
  api.put(`${ENDPOINT.ADMIN_USER}/${userId}`, payload);
const adminGetUser = (userId, status) =>
  api.get(
    `${ENDPOINT.ADMIN_USER}/${userId}${status ? `?status=${status}` : ''}`,
  );
const adminDeleteUser = (userId, status) =>
  api.delete(
    `${ENDPOINT.ADMIN_USER}/${userId}${status ? `?status=${status}` : ''}`,
  );
const adminExportUsersReport = () => api.get(`${ENDPOINT.ADMIN_USER}/report`);
const adminGetPreviewToken = (userId) =>
  api.get(`${ENDPOINT.ADMIN_USER}/preview-token/${userId}`);

const adminGetAdmins = (queryParams) =>
  api.get(`${ENDPOINT.ADMIN_GET_ADMINS}${generateQueryParams(queryParams)}`);
const adminGetUsers = (queryParams) =>
  api.get(`${ENDPOINT.ADMIN_GET_USERS}${generateQueryParams(queryParams)}`);

// Approval Entities.
const adminGetApprovalEntities = (queryParams) =>
  api.get(
    `${ENDPOINT.ADMIN_GET_APPROVAL_ENTITIES}${generateQueryParams(
      queryParams,
    )}`,
  );
const adminGetApprovalEntity = (id) =>
  api.get(`${ENDPOINT.ADMIN_GET_APPROVAL_ENTITY}/${id}`);
const adminDeleteApprovalEntity = (id) =>
  api.delete(`${ENDPOINT.ADMIN_GET_APPROVAL_ENTITY}/${id}`);
const adminGetApprovalEntityHistory = (id) =>
  api.get(`${ENDPOINT.ADMIN_GET_APPROVAL_ENTITY}/${id}/audit`);

// Approval Funds.
const adminGetApprovalFunds = (queryParams) =>
  api.get(
    `${ENDPOINT.ADMIN_GET_APPROVAL_FUNDS}${generateQueryParams(queryParams)}`,
  );
const adminUpdateApprovalFund = ({ id, payload }) =>
  api.put(`${ENDPOINT.ADMIN_UPDATE_APPROVAL_FUND}/${id}`, payload);

// Admin Fund Redemption Requests.
const adminAddFundRedemptionRequest = ({ payload } = {}) =>
  api.post(ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST, payload);
const adminUpdateFundRedemptionRequest = ({ id, payload }) =>
  api.put(`${ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST}/${id}`, payload);
const adminDeleteFundRedemptionRequest = (id) =>
  api.delete(`${ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST}/${id}`);
const adminGetPaginatedFundRedemptionRequests = (queryParams) =>
  api.get(
    `${ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST}?${generateQueryParams(
      queryParams,
    )}`,
  );
const adminGetFundRedemptionRequestsByUserId = (userId) =>
  api.get(`${ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST}/byUserId/${userId}`);
const adminGetFundRedemptionRequestDetails = (id) =>
  api.get(`${ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST}/${id}`);
const adminApproveFundRedemptionRequest = (payload) =>
  api.post(`${ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST}/approve`, payload);
const adminRejectFundRedemptionRequest = (payload) =>
  api.post(`${ENDPOINT.ADMIN_FUND_REDEMPTION_REQUEST}/reject`, payload);

const adminGetFundPortfolios = (id) =>
  api.get(`${ENDPOINT.ADMIN_UPDATE_APPROVAL_FUND}/${id}`);

const adminApprove = (payload) =>
  api.post(ENDPOINT.ADMIN_APPROVAL_APPROVE, payload);
const adminChangeStatus = (payload) =>
  api.post(ENDPOINT.ADMIN_APPROVAL_REJECT, payload);

const adminUserVerification = ({ id, payload }) =>
  api.post(ENDPOINT.ADMIN_USER_VERIFICATION(id), payload);

const editInvestmentEntityIndividual = ({ id, payload }) =>
  api.post(`${ENDPOINT.ADMIN_APPROVAL_EDIT_ENTITY_INDIVIDUAL}/${id}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const editInvestmentEntityCompany = ({ id, payload }) =>
  api.post(`${ENDPOINT.ADMIN_APPROVAL_EDIT_ENTITY_COMPANY}/${id}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const editInvestmentEntityTrust = ({ id, payload }) =>
  api.post(`${ENDPOINT.ADMIN_APPROVAL_EDIT_ENTITY_TRUST}/${id}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

// ADMIN FUNDS
const adminGetFunds = (queryParams) =>
  api.get(`${ENDPOINT.ADMIN_GET_FUNDS}${generateQueryParams(queryParams)}`);
const adminGetFundEntity = (id) =>
  api.get(`${ENDPOINT.ADMIN_FUND_ENTITY}/${id}`);
const adminDeleteFundEntity = (id) =>
  api.delete(`${ENDPOINT.ADMIN_FUND_ENTITY}/${id}`);
const adminGetFundDetailById = (id) =>
  api.get(`${ENDPOINT.ADMIN_FUNDS}/byId/${id}`);

const adminAddFunds = (payload) =>
  api.post(`${ENDPOINT.ADMIN_FUNDS}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const adminEditFund = (id, payload) =>
  api.put(`${ENDPOINT.ADMIN_FUNDS}/${id}`, payload);
const adminDeleteFunds = (id) => api.delete(`${ENDPOINT.ADMIN_FUNDS}/${id}`);
const adminPublishFunds = (id) =>
  api.post(`${ENDPOINT.ADMIN_FUNDS}/publish/${id}`);

// ADMIN PORTFOLIO
const adminGetPortfoliosByFundConfigurationIdAndUserId = ({
  fundConfigurationId,
  userId,
}) =>
  api.get(
    `${ENDPOINT.ADMIN_PORTFOLIO}/byFundConfigurationId/${fundConfigurationId}/user-id/${userId}`,
  );

// ADMIN ARTICLES
const adminGetArticles = (queryParams) =>
  api.get(`${ENDPOINT.ADMIN_GET_ARTICLES}${generateQueryParams(queryParams)}`);
const adminGetArticleDetails = (id) =>
  api.get(`${ENDPOINT.ADMIN_ARTICLES}/${id}`);
const adminAddArticle = (payload) =>
  api.post(`${ENDPOINT.ADMIN_ARTICLES}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const adminEditArticle = (id, payload) =>
  api.put(`${ENDPOINT.ADMIN_ARTICLES}/${id}`, payload);
const adminDeleteArticles = (id) =>
  api.delete(`${ENDPOINT.ADMIN_ARTICLES}/${id}`);
const adminPublishArticles = (id) =>
  api.post(`${ENDPOINT.ADMIN_ARTICLES}/publish/${id}`);

// ADMIN EVENTS
const adminGetEvents = (queryParams) =>
  api.get(`${ENDPOINT.ADMIN_GET_EVENTS}${generateQueryParams(queryParams)}`);
const adminGetEventDetails = (id) => api.get(`${ENDPOINT.ADMIN_EVENTS}/${id}`);
const adminAddEvent = (payload) =>
  api.post(`${ENDPOINT.ADMIN_EVENTS}`, payload);
const adminEditEvent = (id, payload) =>
  api.put(`${ENDPOINT.ADMIN_EVENTS}/${id}`, payload);
const adminDeleteEvents = (id) => api.delete(`${ENDPOINT.ADMIN_EVENTS}/${id}`);
const adminPublishEvents = (id) =>
  api.post(`${ENDPOINT.ADMIN_EVENTS}/publish/${id}`);

// ADMIN VIDEOS
const adminGetVideos = (queryParams) =>
  api.get(`${ENDPOINT.ADMIN_GET_VIDEOS}${generateQueryParams(queryParams)}`);
const adminGetVideoDetails = (id) => api.get(`${ENDPOINT.ADMIN_VIDEOS}/${id}`);
const adminAddVideo = (payload) =>
  api.post(`${ENDPOINT.ADMIN_VIDEOS}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const adminEditVideo = (id, payload) =>
  api.put(`${ENDPOINT.ADMIN_VIDEOS}/${id}`, payload);
const adminDeleteVideos = (id) => api.delete(`${ENDPOINT.ADMIN_VIDEOS}/${id}`);
const adminPublishVideos = (id) =>
  api.post(`${ENDPOINT.ADMIN_VIDEOS}/publish/${id}`);

const adminUploadFileDataRoom = (id, payload) =>
  api.post(`${ENDPOINT.ADMIN_FUNDS_DATA_ROOM}/${id}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const adminUploadStatementDocuments = (id, payload) =>
  api.post(`${ENDPOINT.ADMIN_FUNDS_DATA_ROOM}/${id}?type=documents`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const adminDeleteFileDataRoom = (payload) =>
  api.post(`${ENDPOINT.ADMIN_FUNDS_DATA_ROOM}/delete`, payload);
const adminDeleteStatementDocuments = (payload) =>
  api.post(`${ENDPOINT.ADMIN_FUNDS_DATA_ROOM}/delete?type=documents`, payload);
const adminGetFundDetail = (id) => api.get(`${ENDPOINT.ADMIN_FUNDS}/${id}`);

const adminGetUserEntityList = (userId) =>
  api.get(`${ENDPOINT.ADMIN_ENTITY}/${userId}`);
const adminGetUserStatementDocuments = (userId) =>
  api.get(`${ENDPOINT.ADMIN_STATEMENT_DOCUMENTS}/${userId}`);

const adminResetUserPassword = (userId) =>
  api.get(`${ENDPOINT.ADMIN_USER_RESET_PASSWORD}/${userId}`);

const adminExportInvestment = (category) =>
  api.get(`${ENDPOINT.ADMIN_EXPORT_INVESTMENT}?category=${category}`);

const adminVerifyExportInvestment = (category, payload) =>
  api.post(
    `${ENDPOINT.ADMIN_EXPORT_VERIFY_INVESTMENT}?category=${category}`,
    payload,
  );

const adminGetOverview = (filter) =>
  api.get(`${ENDPOINT.ADMIN_GET_OVERVIEW}?filter=${filter}`);

const adminGetOverviewUserActivity = (filter) =>
  api.get(`${ENDPOINT.ADMIN_GET_OVERVIEW_USER_ACTIVITY}?filter=${filter}`);

const adminGetPublicFunds = () => api.get(`${ENDPOINT.ADMIN_GET_PUBLIC_FUNDS}`);

const adminAddUserInvestmentFund = (userId, payload) =>
  api.post(`${ENDPOINT.ADMIN_ADD_USER_INVESTMENT_FUND}/${userId}`, payload);

const adminGetPlatformConfigurationEmailTemplates = () =>
  api.get(ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_EMAIL_TEMPLATES);
const adminGetPlatformConfigurationEmailTemplateDetails = (id) =>
  api.get(`${ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_EMAIL_TEMPLATES}/${id}`);
const adminUpdatePlatformConfigurationEmailTemplateDetails = (payload) =>
  api.post(ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_EMAIL_TEMPLATES, payload);
const adminGetPlatformConfigurationEmailPreview = (id, payload) =>
  api.post(
    `${ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_EMAIL_TEMPLATES}/${id}`,
    payload,
  );

const adminGetPlatformConfigurationImageSlider = () =>
  api.get(ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_IMAGE_SLIDER);
const adminUpdatePlatformConfigurationImageSlider = (payload) =>
  api.post(
    `${ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_IMAGE_SLIDER}/upload`,
    payload,
  );
const adminGetPlatformConfigurationColours = () =>
  api.get(ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_COLOURS);
const adminUpdatePlatformConfigurationColours = (payload) =>
  api.post(ENDPOINT.ADMIN_PLATFORM_CONFIGURATION_COLOURS, payload);

// ADMIN FUND UPDATES
const adminGetFundUpdatesByFundConfigurationId = (fundConfigurationId) =>
  api.get(`${ENDPOINT.ADMIN_FUND_UPDATES}/${fundConfigurationId}`);
const adminGetFundUpdatesOverviewByFundConfigurationId = (
  fundConfigurationId,
) => api.get(`${ENDPOINT.ADMIN_FUND_UPDATES}/overview/${fundConfigurationId}`);
const adminAddFundUpdates = (payload) =>
  api.post(ENDPOINT.ADMIN_FUND_UPDATES, payload);
const adminDeleteFundUpdate = (id) =>
  api.delete(`${ENDPOINT.ADMIN_FUND_UPDATES}/${id}`);

// ADMIN FUND REGISTRY
const adminGetFundRegistriesByFundConfigurationId = ({
  fundConfigurationId,
  maxDate,
}) =>
  api.get(
    `${ENDPOINT.ADMIN_FUND_REGISTRY}/${fundConfigurationId}/${maxDate || ''}`,
  );

// ADMIN FUND PERFORMANCE
const adminGetFundPerformancesByFundConfigurationId = ({
  fundConfigurationId,
}) =>
  api.get(
    `${ENDPOINT.ADMIN_FUND_PERFORMANCE}/by-fund-configuration-id/${fundConfigurationId}`,
  );
const adminAddFundPerformance = (payload) =>
  api.post(ENDPOINT.ADMIN_FUND_PERFORMANCE, payload);
const adminEditFundPerformance = (payload) =>
  api.put(`${ENDPOINT.ADMIN_FUND_PERFORMANCE}/${payload.id}`, payload);
const adminDeleteFundPerformance = (id) =>
  api.delete(`${ENDPOINT.ADMIN_FUND_PERFORMANCE}/${id}`);

// FUND PERFORMANCE
const getFundPerformancesByPortfolioId = ({ portfolioId }) =>
  api.get(`${ENDPOINT.FUND_PERFORMANCE}/by-portfolio-id/${portfolioId}`);
const getPortfolioPerformancesByPortfolioId = ({ portfolioId }) =>
  api.get(`${ENDPOINT.FUND_PERFORMANCE}/portfolio-performance/${portfolioId}`);

// Get Currency Data
const getLatestCurrencyData = ({ currency = 'AUD' } = {}) =>
  axios.get(`https://api.exchangerate-api.com/v4/latest/${currency}`);

export default {
  checkEmail,
  resendEmailVerification,
  verifyEmail,
  sendMobileNumberVerificationCode,
  resendMobileNumberVerificationCode,
  verifyMobileNumberVerificationCode,
  updateConsent,
  register,
  login,
  refreshToken,
  loginVerifyMFA,
  logout,
  updateProfileSendMobileNumberVerificationCode,
  updateProfileResendMobileNumberVerificationCode,
  updateProfileVerifyMobileNumberVerificationCode,
  updateProfileSaveMobileNumber,
  forgotPasswordResendEmail,
  forgotPasswordSendEmail,
  forgotPasswordVerifyToken,
  changePassword,
  getUserProfile,
  getUserAccessibility,
  setUserViewFundStatus,
  changePasswordSendEmail,
  changePasswordVerifyToken,
  getFunds,
  getFund,
  getArticles,
  getArticle,
  getEvents,
  getEvent,
  getVideos,
  getVideo,
  getFeaturedArticles,
  getArticleDocument,
  getFundDataRooms,
  getDataRoomDocumentView,
  getDataRoomDocumentDownload,
  addInvestmentEntityUpload,
  addInvestmentEntityIndividual,
  addInvestmentEntityCompany,
  addInvestmentEntityTrust,
  getInvestmentEntityUserDetailList,
  getPortfolioPayments,
  getInvestedPortofolios,
  getInvestedPortofoliosOverview,
  getPortfolioPaymentsByInvestmentEntityId,
  getPortfolioPendingPaymentsByInvestmentEntityId,
  getInvestmentEntityList,
  getPortfoliosByInvestmentEntity,
  getInvestedPortfoliosByInvestmentEntity,
  getDocumentSigning,
  getDocumentLists,
  getDocument,
  downloadDocuments,
  viewDocuments,
  getUserTransactions,
  getPortfolioMarketTransactions,
  getUserInvestmentCommitmentsByFundConfigurationId,
  getInvestmentEntityListUser,
  submitFundInvestment,
  redeemCashFundInvestment,
  getPendingFundRedemptionRequest,
  addFundRedemptionRequest,
  adminAddUser,
  adminEditUser,
  adminGetUser,
  adminGetUsers,
  adminDeleteUser,
  adminExportUsersReport,
  adminGetPreviewToken,
  adminGetAdmins,
  adminGetApprovalEntities,
  adminGetApprovalEntity,
  adminDeleteApprovalEntity,
  adminGetApprovalEntityHistory,
  adminApprove,
  adminChangeStatus,
  adminUserVerification,
  editInvestmentEntityIndividual,
  editInvestmentEntityCompany,
  editInvestmentEntityTrust,
  adminGetApprovalFunds,
  adminUpdateApprovalFund,
  adminAddFundRedemptionRequest,
  adminUpdateFundRedemptionRequest,
  adminDeleteFundRedemptionRequest,
  adminGetPaginatedFundRedemptionRequests,
  adminGetFundRedemptionRequestsByUserId,
  adminGetFundRedemptionRequestDetails,
  adminApproveFundRedemptionRequest,
  adminRejectFundRedemptionRequest,
  adminGetFunds,
  adminGetFundEntity,
  adminDeleteFundEntity,
  adminAddFunds,
  adminEditFund,
  adminDeleteFunds,
  adminPublishFunds,
  adminGetArticles,
  adminGetArticleDetails,
  adminAddArticle,
  adminGetEvents,
  adminGetEventDetails,
  adminAddEvent,
  adminEditEvent,
  adminPublishEvents,
  adminDeleteEvents,
  adminGetVideos,
  adminGetVideoDetails,
  adminAddVideo,
  adminEditVideo,
  adminPublishVideos,
  adminDeleteVideos,
  adminEditArticle,
  adminDeleteArticles,
  adminPublishArticles,
  adminUploadFileDataRoom,
  adminDeleteFileDataRoom,
  adminGetFundDetail,
  adminGetFundDetailById,
  adminGetFundPortfolios,
  adminGetUserEntityList,
  adminUploadStatementDocuments,
  adminDeleteStatementDocuments,
  adminGetUserStatementDocuments,
  adminResetUserPassword,
  adminExportInvestment,
  adminVerifyExportInvestment,
  adminGetOverview,
  adminGetOverviewUserActivity,
  adminGetPublicFunds,
  adminAddUserInvestmentFund,
  adminGetPlatformConfigurationEmailTemplates,
  adminGetPlatformConfigurationEmailTemplateDetails,
  adminUpdatePlatformConfigurationEmailTemplateDetails,
  adminGetPlatformConfigurationEmailPreview,
  adminGetPlatformConfigurationImageSlider,
  adminUpdatePlatformConfigurationImageSlider,
  adminGetPlatformConfigurationColours,
  adminUpdatePlatformConfigurationColours,
  adminGetFundUpdatesByFundConfigurationId,
  adminGetFundUpdatesOverviewByFundConfigurationId,
  adminAddFundUpdates,
  adminDeleteFundUpdate,
  adminGetFundRegistriesByFundConfigurationId,
  adminGetPortfoliosByFundConfigurationIdAndUserId,
  getLatestCurrencyData,
  adminGetFundPerformancesByFundConfigurationId,
  adminAddFundPerformance,
  adminEditFundPerformance,
  adminDeleteFundPerformance,
  getFundPerformancesByPortfolioId,
  getPortfolioPerformancesByPortfolioId,
};
