import React, { useCallback, useMemo, useState } from 'react';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import FundRedemptionDialog from 'components/Dialog/FundRedemptionDialog';
import Link from 'components/Link';
import Loading from 'components/Loading';
import SuccessDialog from 'components/Dialog/SuccessDialog';

import Services from 'api/services';
import getNumbers from 'utils/strings/getNumbers';
import { generateMailTo } from 'utils/email';
import { REACH_ADMIN_EMAIL } from 'constants/general';
import { ERROR_MESSAGES } from 'constants/errors';

import useDialog from './useDialog';

const mailHref = generateMailTo({
  email: REACH_ADMIN_EMAIL,
  subject: '',
});

const useCashFundRedemption = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {
    isDialogOpen: activeRedeemInvestment,
    handleOpenDialog: setActiveRedeemInvestment,
    handleCloseDialog,
  } = useDialog();
  const {
    isDialogOpen: redemptionAmount,
    handleOpenDialog: handleOpenRedeemConfirmation,
    handleCloseDialog: handleCloseRedeemConfirmation,
  } = useDialog();
  const {
    isDialogOpen: isSuccessDialogOpen,
    handleOpenDialog: handleOpenSuccessDialog,
    handleCloseDialog: handleCloseSuccessDialog,
  } = useDialog();

  const handleRedeemCashFund = useCallback(async () => {
    try {
      handleCloseRedeemConfirmation();
      setLoading(true);

      await Services.redeemCashFundInvestment({
        portfolioId: activeRedeemInvestment?.portfolio?.id,
        payload: { redemptionAmount: getNumbers(redemptionAmount) },
      });

      handleOpenSuccessDialog();
    } catch (err) {
      setError(
        ERROR_MESSAGES[err.response?.data?.code] || err.response?.data?.message,
      );
    } finally {
      setLoading(false);
    }
  }, [activeRedeemInvestment?.portfolio?.id, redemptionAmount]);

  const redemptionSuccessText = useMemo(
    () => (
      <span>
        Your request for a redemption from the&nbsp;
        {activeRedeemInvestment?.portfolio?.fund?.fundName} has been received. A
        member of the team will contact you shortly.
        <br />
        <br />
        In the meantime, if you have any questions, please do not hesitate to
        get in touch at&nbsp;
        <Link href={mailHref} target="_blank">
          {REACH_ADMIN_EMAIL}
        </Link>
      </span>
    ),
    [activeRedeemInvestment?.portfolio?.fund?.fundName],
  );

  const handleConfirmSuccessDialog = useCallback(() => {
    handleCloseSuccessDialog();
    handleCloseDialog();
  }, []);

  const FundRedemptionDialogComponent = useCallback(
    () => (
      <FundRedemptionDialog
        open={!!activeRedeemInvestment}
        onClose={handleCloseDialog}
        portfolio={activeRedeemInvestment?.portfolio}
        title={activeRedeemInvestment?.portfolio?.fund?.fundName}
        onConfirm={handleOpenRedeemConfirmation}
        errorText={error}
      />
    ),
    [activeRedeemInvestment, handleCloseDialog, error],
  );

  const RedemptionConfirmationDialogComponent = useCallback(
    () => (
      <ConfirmationDialog
        open={!!redemptionAmount}
        title="Warning!"
        infoText=""
        subtitle="Do you want to redeem this investment?"
        onClose={handleCloseRedeemConfirmation}
        confirmButtonLabel="Redeem"
        onConfirm={handleRedeemCashFund}
        dialogContentDependencies={[handleRedeemCashFund]}
      />
    ),
    [redemptionAmount, handleRedeemCashFund],
  );

  const RedemptionSuccessDialogComponent = useCallback(
    () => (
      <SuccessDialog
        open={isSuccessDialogOpen}
        onConfirm={handleConfirmSuccessDialog}
        title="Thank you"
        subtitle={redemptionSuccessText}
      />
    ),
    [isSuccessDialogOpen, redemptionSuccessText],
  );

  const RedemptionLoadingComponent = useCallback(
    () => <Loading popup loading={loading} />,
    [loading],
  );

  return {
    FundRedemptionDialogComponent,
    RedemptionConfirmationDialogComponent,
    RedemptionSuccessDialogComponent,
    RedemptionLoadingComponent,
    setActiveRedeemInvestment,
  };
};

export default useCashFundRedemption;
