import React from 'react';
import Typography from '@mui/material/Typography';

import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';

import services from 'api/services';

import Article from './Article';

export const articleTabItems = (props = {}) => [
  {
    key: 'article',
    Icon: PersonIcon,
    label: <Typography variant="h6">Article</Typography>,
    content: <Article fieldKey="article" {...props} />,
  },
];

export const fetchAdminArticleDetails = async (id) => {
  try {
    if (!id) return {};

    const res = await services.adminGetArticleDetails(id);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const sendAddArticle = async ({ payload }) => {
  try {
    const res = await services.adminAddArticle(payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};

export const editArticle = async ({ id, payload }) => {
  try {
    const res = await services.adminEditArticle(id, payload);
    return res?.data;
  } catch (err) {
    return err;
  }
};
