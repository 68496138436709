import React, { useCallback, useEffect } from 'react';

import config from 'utils/config';

const useRecaptcha = ({ onChange, loadRecaptcha = true } = {}) => {
  // useEffect(() => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id);

  //     if (!isScriptExist) {
  //       const recaptchaElement = document.createElement("div");
  //       recaptchaElement.id = 'g-recaptcha-element';
  //       document.body.appendChild(recaptchaElement);

  //       const script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.src = url;
  //       script.id = id;
  //       script.onload = function () {
  //         if (callback) callback();
  //       };
  //       document.body.appendChild(script);
  //     }

  //     if (isScriptExist && callback) callback();
  //   }

  //   // load the script by passing the URL
  //   loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=explicit`, () => {
  //     // if (!document.getElementById('g-recaptcha')) return;
  //     console.log("Script loaded!", window.grecaptcha, window.grecaptcha.render);
  //     // document.createElement("div");
  //     window.grecaptcha?.render?.('g-recaptcha', {
  //       'sitekey' : SITE_KEY
  //     });
  //   });

  //   return () => {
  //     if (document.getElementById("recaptcha-key")) document.getElementById("recaptcha-key").remove();
  //   };
  // }, []);

  useEffect(() => {
    if (!loadRecaptcha) return () => null;

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      'https://www.google.com/recaptcha/api.js?render=explicit',
      () => {
        window.grecaptcha?.ready(() => {
          if (!document.getElementById('g-recaptcha')?.firstChild)
            window.grecaptcha.render('g-recaptcha', {
              sitekey: config.googleRecaptcha.key,
              callback: onChange,
              // 'theme' : 'light'
            });
        });
      },
    );

    return () => {
      if (document.getElementById('recaptcha-key'))
        document.getElementById('recaptcha-key').remove();
      if (document.getElementById('g-recaptcha')?.firstChild)
        document.getElementById('g-recaptcha').removeChild();
    };
  }, []);

  // useEffect(() => {
  //   window.grecaptcha?.ready(() => {
  //     if (!document.getElementById('g-recaptcha')?.firstChild)
  //       window.grecaptcha.render('g-recaptcha', {
  //         sitekey: SITE_KEY,
  //         callback: (e) => console.log(e)
  //         // 'theme' : 'light'
  //       });
  //   });
  // }, []);

  const getRecaptchaToken = useCallback(() =>
    // window.grecaptcha.ready(() => {
    //   window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => token);
    // })
    {
      if (!window.grecaptcha?.getResponse) return '';
      return window.grecaptcha.getResponse();
    }, []);

  const RecaptchaComponent = useCallback(
    () =>
      loadRecaptcha ? <div id="g-recaptcha" className="g-recaptcha" /> : '',
    [loadRecaptcha],
  );

  return {
    getRecaptchaToken,
    RecaptchaComponent,
    // recaptchaElement,
  };
};

// export const RecaptchaComponent = ({ onChange }) => (
//   <div id="g-recaptcha" className="g-recaptcha" data-callback={onChange} />
// );

export default useRecaptcha;
