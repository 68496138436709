import {
  amountWithDollarCellRenderer,
  classLockupDaysCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const FUND_REDEMPTION_REQUEST_DETAILS_FIELDS = [
  {
    label: 'Redemption Request',
    name: 'id',
  },
  {
    label: 'Entity Name',
    name: 'entityName',
  },
  {
    label: 'Status',
    name: 'status',
  },
];

export const FUND_REDEMPTION_REQUEST_FIELD_DISPLAY = [
  {
    label: 'Fund Name',
    name: 'fundName',
    xs: 12,
    md: 5,
  },
  {
    label: 'Total Commitment',
    name: 'committedAmount',
    xs: 3,
    md: 2,
    renderer: (value) => amountWithDollarCellRenderer({ value }),
  },
  {
    label: 'Value',
    name: 'estimatedValue',
    xs: 3,
    md: 2,
    renderer: (value) => amountWithDollarCellRenderer({ value }),
  },
  {
    label: 'Lockup',
    name: 'classLockupDays',
    xs: 3,
    md: 1,
    renderer: (value) =>
      classLockupDaysCellRenderer({
        value,
        row: { lockupDaysRemaining: value },
      }),
  },
  {
    label: 'Reference Number',
    name: 'referenceNumber',
    xs: 3,
    md: 2,
  },
];

export const FUND_REDEMPTION_REQUEST_DETAILS_SUMMARY_FIELD = [
  {
    fieldComponent: 'input',
    fieldLabel: 'Fund Class',
    name: 'className',
    editable: false,
  },
  {
    fieldComponent: 'input',
    fieldLabel: 'Redemption Amount',
    name: 'redemptionUnitAmount',
    type: 'number',
  },
  {
    fieldComponent: 'datePicker',
    fieldLabel: 'Request Date',
    name: 'requestedAt',
  },
];

export const FUND_REDEMPTION_REQUEST_STATUS_LABEL = {
  PENDING: 'Received',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};
