const mapVideo = (formValues) => ({
  id: formValues.id,
  videoUid: formValues.videoUid,
  category: formValues.category,
  summary: formValues.summary,
  link: formValues.link,
  date: formValues.date,
  title: formValues.title,
  content: formValues.content,

  isHidden: !!formValues.isHidden,
  createdBy: formValues.createdBy,
  updatedBy: formValues.updatedBy,
});

const setArticleFilePayload = ({ uploadFilePayload, videoThumbnailImg }) => {
  if (videoThumbnailImg)
    uploadFilePayload.push({
      key: 'videoThumbnailImg',
      ...videoThumbnailImg,
    });

  return uploadFilePayload;
};

const addVideo = (formValues) => {
  const { video } = formValues;

  const videoPayload = mapVideo({ ...video, ...formValues });

  // Set Upload File Payloads.
  const uploadFile = [];

  setArticleFilePayload({ uploadFilePayload: uploadFile, ...video });

  const payload = {
    video: videoPayload,
  };

  const formData = new FormData();
  formData.append('content', JSON.stringify(payload));

  if (uploadFile.length) {
    uploadFile.forEach(
      (file) => file.file && formData.append(file.key, file.file),
    );
  }

  return formData;
};

export default {
  video: mapVideo,
  addVideo,
};
