import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { FUND_REDEMPTION_REQUEST_DETAILS_FIELD_DISPLAY } from 'constants/dashboard';

import palette from 'theme/palette';

import FieldContainer from 'components/FieldContainer';
import { getPaymentStatus } from 'constants/investmentEntity';
import { getFundStatusChipColor } from 'utils/chipMappers';

const FundRedemptionRequestDetails = ({ fundRedemptionRequest }) => (
  <Grid container>
    <Grid
      container
      sx={{
        background: palette.reach.white,
        borderWidth: '1px 1px 1px 8px',
        borderStyle: 'solid',
        borderColor:
          palette?.[
            `${
              getFundStatusChipColor({
                status: fundRedemptionRequest?.status,
              }) || 'draftChipColor'
            }`
          ]?.main,
        padding: '12px 46px',
        marginTop: '32px',
      }}
    >
      {FUND_REDEMPTION_REQUEST_DETAILS_FIELD_DISPLAY.map(
        ({ label, name, type, xs, md, renderer = (value) => value }) => (
          <Grid item xs={xs} md={md} key={`field-${label}`}>
            <FieldContainer label={label}>
              {type === 'text' ? (
                <Typography variant="display5">
                  {renderer(fundRedemptionRequest?.[name])}
                </Typography>
              ) : (
                <Chip
                  label={getPaymentStatus(fundRedemptionRequest?.status)}
                  color={getFundStatusChipColor({
                    status: fundRedemptionRequest?.status,
                  })}
                  sx={{
                    height: '20px',
                  }}
                />
              )}
            </FieldContainer>
          </Grid>
        ),
      )}
    </Grid>
  </Grid>
);

export default FundRedemptionRequestDetails;
