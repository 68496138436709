const mapEvent = (formValues) => ({
  id: formValues.id,
  eventUid: formValues.eventUid,
  category: formValues.category,
  link: formValues.link,
  date: formValues.date,
  title: formValues.title,
  content: formValues.content,

  isHidden: !!formValues.isHidden,
  createdBy: formValues.createdBy,
  updatedBy: formValues.updatedBy,
});

const addEvent = (formValues) => {
  const { event } = formValues;

  const eventPayload = mapEvent({ ...event, ...formValues });

  const payload = {
    event: eventPayload,
  };

  return payload;
};

export default {
  event: mapEvent,
  addEvent,
};
