import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import MUIGrid from '@mui/material/Grid';
import MUITypography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { ACCOUNTANT_CERTIFICATE_MAX_UPLOAD_SIZE } from 'constants/investmentEntity';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import useUploadFiles from 'hooks/useUploadFiles';

const FileContainer = styled(MUIGrid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.palette.reach.blueLight,
  padding: '15px 24px',
  marginBottom: '12px',
}));

const Label = styled(MUITypography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
}));

const ButtonLabel = styled(MUITypography)(({ theme, disabled }) => ({
  fontFamily: theme.typography.fontFamily,
  color: disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.link,
}));

const StyledAddCircleIcon = styled(AddCircleIcon)(({ theme, disabled }) => ({
  marginRight: '10px',
  height: '22px',
  width: '22px',
  color: disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.link,
}));

const UploadButton = ({
  label = 'Attach a new file',
  infos = [
    `Maximum ${ACCOUNTANT_CERTIFICATE_MAX_UPLOAD_SIZE}MB (each file)`,
    'Maximum 2 files',
  ],
  maxFiles = 2,
  maxSize = ACCOUNTANT_CERTIFICATE_MAX_UPLOAD_SIZE,
  maxFileNameLength = 40,
  accept = 'application/pdf',
  multiple = false,
  onChange,
  name,
  value = [],
  errorValue,
  onRemoveValidation,
  ...props
}) => {
  const { files, handleUpload, isDisabled, getFileName, handleRemoveFile } =
    useUploadFiles({
      name,
      value,
      onChangeCallback: onChange,
      maxFiles,
      maxSize,
      maxFileNameLength,
      id: 'file',
      accept,
      onRemoveValidation,
    });

  return (
    <MUIGrid container>
      <MUIGrid mb={files.length && '34.5px'} item container xs={12}>
        {files?.map((file, index) => (
          <FileContainer key={file.fileName} container>
            <MUIGrid item>
              <Label
                variant="h6"
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {getFileName(file.fileName || file.originalFileName)}
              </Label>
            </MUIGrid>
            <MUIGrid
              item
              onClick={handleRemoveFile(file, index)}
              sx={{ cursor: 'pointer' }}
            >
              <TrashIcon />
            </MUIGrid>
          </FileContainer>
        ))}
      </MUIGrid>
      <MUIGrid item container xs={12}>
        <Button
          disabled={isDisabled}
          {...props}
          component="label"
          sx={{ textTransform: 'none' }}
        >
          <StyledAddCircleIcon disabled={isDisabled} />
          <ButtonLabel variant="h6" disabled={isDisabled}>
            {label}
          </ButtonLabel>
          <input
            hidden
            onChange={handleUpload}
            accept={accept}
            multiple={multiple}
            type="file"
            id="file"
          />
        </Button>
      </MUIGrid>
      <MUIGrid item xs={12}>
        <FormHelperText error={!!errorValue}>{errorValue}</FormHelperText>
      </MUIGrid>
      <MUIGrid item container mt="26.5px" xs={12}>
        {infos.map((info) => (
          <MUIGrid item mr="25px" key={info}>
            <Label
              variant="xsmall"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <InfoIcon style={{ marginRight: '5px' }} /> {info}
            </Label>
          </MUIGrid>
        ))}
      </MUIGrid>
    </MUIGrid>
  );
};
export default memo(UploadButton);
