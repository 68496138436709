const green = '#4BA865';
const red = '#DC3618';
const purple = '#834FF6';
const deepPurple = '#4B4A80';
const black = '#000000';
const semiBlack = '#161616';
const link = '#746CC0';
const menuLink = '#7776CA';
const grey = '#F5F5F5';
const inactiveGrey = '#959FB1';
const blueLight = '#F5FBFF';
const white = '#FFFFFF';
const bluish = '#8692A6';
const purpleHaze = '#483B96';
const fuchsia = '#7A70B5';
const obsidian = '#292929';
const blue = '#29A6FF';
const yellowWarning = '#F5A300';
const disabled = '#C4C4C4';
const darkGrey = '#4A4A4A';
const darkBlue = '#36356B';
const lightGrey = '#B3B3B3';
const palette = {
  reach: {
    white,
    dark: 'linear-gradient(88.39deg, #272727 -8.25%, #4D4D4D 107.31%)',
    disabled: 'linear-gradient(88.39deg, #DBDBDB -8.25%, #E4E4E4 107.31%)',
    disabledGreyDark:
      'linear-gradient(88.39deg, #555555 -8.25%, #666666 107.31%)',
    purpleGradient: 'linear-gradient(91.25deg, #673CD6 0%, #9862D9 99.46%)',
    purpleHazeGradient: 'linear-gradient(91.25deg, #483B96 0%, #6554C8 99.46%)',
    pastel: '#BEA3F8',
    purpleHaze,
    purple,
    red,
    green,
    grey,
    inactiveGrey,
    grey5: '#FAFAFA',
    black,
    blueLight,
    link,
    blueBorder: '#7EC4F6',
    blueAppBarPDF: '#5763B6',
    yellowWarning,
    transparent: 'transparent',
    obsidian,
    bluish,
    menuLink,
    deepPurple,
    darkBlue,
    fuchsia,
  },
  primary: {
    main: purple,
  },
  text: {
    black,
    semiBlack,
    primary: '#4E4E4E',
    white: '#FFFFFF',
    grey: '#7C8189',
    darkGrey: '#696F79',
    back: bluish,
    bluish,
    purpleHaze,
    purple,
    hyperlink: '#7540E3',
    button: '#5A728C',
    disabled,
    fuchsia,
    obsidian,
    link,
    menuLink,
  },
  light: {
    main: white,
  },
  success: {
    main: green,
    text: green,
  },
  error: {
    main: red,
    text: red,
  },
  errorGray: {
    main: '#5A728C',
  },
  disabled: {
    main: disabled,
    contrastText: white,
  },
  redIcon: {
    main: '#B37272',
    text: red,
  },
  activeIcon: {
    main: purple,
  },
  individual: {
    main: blue,
    contrastText: white,
  },
  individualIcon: {
    main: blue,
  },
  joint: {
    main: bluish,
    contrastText: white,
  },
  jointIcon: {
    main: bluish,
  },
  trusteeIndividualChipColor: {
    main: green,
    contrastText: white,
  },
  trusteeCompanyChipColor: {
    main: purpleHaze,
    contrastText: white,
  },
  fundStatusOpenChipColor: {
    main: purpleHaze,
    contrastText: white,
  },
  fundStatusClosedChipColor: {
    main: black,
    contrastText: white,
  },
  articleFeaturedChipColor: {
    main: green,
    contrastText: white,
  },
  articleDraftChipColor: {
    main: black,
    contrastText: white,
  },
  form: {
    disabled: '#DDDFE3',
    disabledBorder: '#BDC3CD',
    bgWarningLight: '#FFFBE5',
  },
  link: '#746CC0',
  saveButton: {
    main: purpleHaze,
    contrastText: white,
  },
  border: {
    light: '#E4E4E4',
    light2: '#F6F6F6',
  },
  approvedChipColor: {
    main: green,
    contrastText: white,
  },
  pendingChipColor: {
    main: yellowWarning,
    contrastText: white,
  },
  rejectedChipColor: {
    main: red,
    contrastText: white,
  },
  completedChipColor: {
    main: green,
    contrastText: white,
  },
  investedChipColor: {
    main: purpleHaze,
    contrastText: white,
  },
  cancelledChipColor: {
    main: red,
    contrastText: white,
  },
  adminChipColor: {
    main: purpleHaze,
    contrastText: white,
  },
  super_adminChipColor: {
    main: red,
    contrastText: white,
  },
  activeChipColor: {
    main: green,
    contrastText: white,
  },
  suspendedChipColor: {
    main: yellowWarning,
    contrastText: white,
  },
  blockedChipColor: {
    main: red,
    contrastText: white,
  },
  'pre-registeredChipColor': {
    main: purpleHaze,
    contrastText: white,
  },
  darkGrey: {
    main: darkGrey,
  },
  adminFundStatusOpenChipColor: {
    main: green,
    contrastText: white,
  },
  adminFundStatusClosedChipColor: {
    main: black,
    contrastText: white,
  },
  adminFundVisibleChipColor: {
    main: green,
    contrastText: white,
  },
  adminFundHiddenChipColor: {
    main: black,
    contrastText: white,
  },
  publishedChipColor: {
    main: green,
    contrastText: white,
  },
  draftChipColor: {
    main: yellowWarning,
    contrastText: white,
  },
  portfolioPendingChipColor: {
    main: bluish,
    contrastText: white,
  },
  publishedOverseasChipColor: {
    main: blue,
    contrastText: white,
  },
  newChipColor: {
    main: purpleHaze,
    contrastText: white,
  },
  selectedChipColor: {
    main: menuLink,
    contrastText: white,
  },
  unselectedChipColor: {
    main: lightGrey,
    contrastText: white,
  },
  black: {
    main: black,
    contrastText: white,
  },
  purpleHaze: {
    main: purpleHaze,
    contrastText: white,
  },
  blue: {
    main: blue,
    contrastText: white,
  },
};
export default palette;
