import getMobileNumberValue from 'utils/getMobileNumberValue';
import mapValue from 'utils/mapValue';

const user = (usersValue) => ({
  firstName: mapValue(usersValue?.firstName, ''),
  middleName: mapValue(usersValue?.middleName, ''),
  lastName: mapValue(usersValue?.lastName, ''),
  password: usersValue?.password,
  role: mapValue(usersValue?.role, ''),
  status: mapValue(usersValue?.status, ''),
  mobileNumber: getMobileNumberValue(
    usersValue?.countryCode,
    usersValue?.phoneNumber,
  ),
  email: mapValue(usersValue?.email, ''),
});

export default user;
