import services from 'api/services';
// eslint-disable-next-line
export const resetPassword = async (userId) => {
  try {
    const result = await services.adminResetUserPassword(userId);
    return result.data;
  } catch (error) {
    return error;
  }
};
