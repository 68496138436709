const INVALID_TOKEN = 'error.investment.invalid-token.please-resend-code';
const TOKEN_EXPIRED = 'error.investment.token.expired';
const TEMP_LOCK = 'error.investment.temporary.locked';
const REACH_TRIAL_LIMIT = 'error.investment.reach.trial.limit';

const ERROR_CODES = {
  INVALID_TOKEN,
  TOKEN_EXPIRED,
  TEMP_LOCK,
  REACH_TRIAL_LIMIT,
};

const ERROR_MSG = {
  [INVALID_TOKEN]: 'Wrong code, please try again',
  [TOKEN_EXPIRED]: 'The code you entered has expired.',
  [TEMP_LOCK]:
    'For your security reason export data has been temporary locked for 24 hours due too multiple failed attempts. To unlock it, please contact your admin.',
  [REACH_TRIAL_LIMIT]:
    'Due too multiple failed attempts, export data has been temporary locked for 24 hours. To unlock it, please contact admin.',
};

export default { ERROR_CODES, ERROR_MSG };
