import React, { memo, useMemo } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import Dropdown from 'components/Dropdown';
import FormTitle from 'components/FormTitle';
import Input from 'components/Input';
import YesNoQuestion from 'components/YesNoQuestion';

import { COUNTRY_OPTIONS } from 'constants/investmentEntity';

const CompanyDetailsForm = ({
  errors,
  onChange,
  values,
  onBlur,
  touched,
  title,
}) => {
  const { taxFieldLabel, taxFieldName, taxFieldType, taxFieldMask } =
    useMemo(() => {
      switch (values.country) {
        case 'AU':
          return {
            taxFieldLabel: 'ACN / ABN',
            taxFieldName: 'australianTaxNumber',
            taxFieldType: 'number',
            taxFieldMask: '-',
          };
        case 'NZ':
          return {
            taxFieldLabel: 'NZBN / NZCN',
            taxFieldName: 'newZealandTaxNumber',
            taxFieldType: 'number',
            taxFieldMask: '-',
          };
        default:
          return {
            taxFieldLabel: 'Tax number',
            taxFieldName: 'otherTaxNumber',
            taxFieldType: 'text',
            taxFieldMask: undefined,
          };
      }
    }, [values.country]);

  return (
    <>
      <FormTitle title={title} />
      <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
        <FormLabel>Registered company name*</FormLabel>
        <Input
          autoFocus
          data-testid="companyname-input"
          name="companyName"
          placeholder="Registered company name*"
          onChange={onChange}
          value={values.companyName}
          errorValue={touched.companyName && errors.companyName}
          onBlur={onBlur}
        />
      </Grid>
      <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
        <FormLabel>Country where the business is registered*</FormLabel>
        <Dropdown
          data-testid="country-dropdown"
          name="country"
          placeholder="Select country*"
          isBoldOptionLabel
          onChange={onChange}
          value={values.country}
          errorValue={touched.country && errors.country}
          onBlur={onBlur}
          options={COUNTRY_OPTIONS}
        />
      </Grid>
      <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
        <FormLabel>{taxFieldLabel}*</FormLabel>
        <Input
          data-testid="company-tax-number-input"
          id={taxFieldName}
          name={taxFieldName}
          placeholder="Number*"
          onChange={onChange}
          value={values[taxFieldName]}
          errorValue={touched[taxFieldName] && errors[taxFieldName]}
          onBlur={onBlur}
          type={taxFieldType}
          mask={taxFieldMask}
          maskInterval={3}
        />
      </Grid>
      <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
        <YesNoQuestion
          name="isPublicCompany"
          value={values.isPublicCompany}
          errorValue={touched.isPublicCompany && errors.isPublicCompany}
          onChange={onChange}
          label="Are you a public company?*"
        />
      </Grid>
      <Grid item mt={2} xs={12} md={6} display="flex" flexDirection="column">
        <YesNoQuestion
          name="isNonProfitable"
          value={values.isNonProfitable}
          errorValue={touched.isNonProfitable && errors.isNonProfitable}
          onChange={onChange}
          label="Is this a charity or not-for-profit?*"
        />
      </Grid>
    </>
  );
};

export default memo(CompanyDetailsForm);
