import config from './config';

const generateMailTo = ({
  email,
  subject,
  body = '',
  bcc = config.public.emailBcc,
}) => `mailto:${email}?subject=${subject}&bcc=${bcc}&body=${body}`;

const sendEmailTo = (props) => {
  window.open(generateMailTo(props));
};

export { generateMailTo, sendEmailTo };
