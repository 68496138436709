import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import DataTable from 'components/DataTable';
import LoadingSpinner from 'components/LoadingSpinner';

import useFetchApi from 'hooks/useFetchApi';
import services from 'api/services';
import { REPORTING_TEMPLATE_OPEN_END } from 'constants/funds';

import {
  transactionOpenEndHeaders,
  transactionCloseEndHeaders,
} from './HelperFunctions';

const PortfolioUpdates = ({ portfolio = {} }) => {
  const fetchTransactions = useMemo(
    () =>
      services.getUserTransactions({
        fundConfigurationId: portfolio.fundConfigurationId,
        userInvestorMapId: portfolio.userInvestorMapFk,
      }),
    [portfolio.fundConfigurationId, portfolio.userInvestorMapFk],
  );

  const { apiData: portfolios, isFetchLoading } = useFetchApi({
    url: fetchTransactions,
    mapApiData: (res) => res.data,
  });

  const tableHeaders = useMemo(
    () =>
      portfolio?.fund?.reportingTemplate === REPORTING_TEMPLATE_OPEN_END
        ? transactionOpenEndHeaders
        : transactionCloseEndHeaders,
    [portfolio?.fund?.reportingTemplate],
  );

  if (isFetchLoading) return <LoadingSpinner loading />;

  return (
    <Grid item xs={12}>
      <DataTable
        wrapheader
        hideFooter
        pagination={false}
        withCheckbox={false}
        headerData={tableHeaders}
        data={portfolios}
        containerProps={{ backgroundColor: 'white' }}
        oddrowcolor="#F6F6F6"
      />
    </Grid>
  );
};

export default memo(PortfolioUpdates);
