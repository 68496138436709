import React, { useMemo, memo, useCallback } from 'react';

import Input from 'components/Input';
import AutocompleteCodeCountry from './components/AutocompleteCountryCode';
import { phoneNumberFieldInputStyles, phoneNumberFieldStyles } from './styles';

const PhoneNumberField = ({
  onChange,
  countryCodeValue,
  autocompleteTestId,
  ...props
}) => {
  const handleChangeCodeCountry = useCallback((_event, newValue) => {
    const e = {
      target: {
        value: newValue,
        name: 'countryCode',
      },
    };

    onChange(e);
  }, []);

  // Child component props
  const PhoneNumberFieldInputProps = useMemo(
    () => ({
      startAdornment: (
        <AutocompleteCodeCountry
          data-testid={autocompleteTestId}
          onChange={handleChangeCodeCountry}
          value={countryCodeValue}
          inputValue={countryCodeValue?.code || ''}
          disabled={props.disabled}
        />
      ),
      sx: phoneNumberFieldInputStyles({
        errorValue: props?.errorValue,
        disabled: props?.disabled,
      }),
    }),
    [props?.errorValue, countryCodeValue?.code, props?.disabled],
  );

  return (
    <Input
      variant="outlined"
      sx={phoneNumberFieldStyles}
      placeholder="Mobile number"
      InputProps={PhoneNumberFieldInputProps}
      onChange={onChange}
      {...props}
      type="phoneNumber"
    />
  );
};

export default memo(PhoneNumberField);
