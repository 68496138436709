import React, { useCallback, useEffect } from 'react';

import lazyLoad from 'utils/lazyLoad';
import history from 'utils/history';
import SidebarContainer from 'components/SidebarContainer';
import { useStores } from 'components/StateProvider';

import { AUSTRALIA_DEFAULT_VALUE } from 'constants/phoneNumber';

import useSteps from 'hooks/useSteps';

import updatePhoneNumberValidationSchemas from 'validations/schema/updatePhoneNumberValidationSchemas';

const UpdatePhoneNumber = lazyLoad(() => import('./Steps/UpdatePhoneNumber'));
const UpdatePhoneNumberVerifyOTP = lazyLoad(() =>
  import('./Steps/UpdatePhoneNumberVerifyOTP'),
);
const UpdatePhoneNumberSuccess = lazyLoad(() =>
  import('./Steps/UpdatePhoneNumberSuccess'),
);

const stepComponents = [
  <UpdatePhoneNumber />,
  <UpdatePhoneNumberVerifyOTP />,
  <UpdatePhoneNumberSuccess />,
];

const UpdatePhoneNumberPage = () => {
  const {
    auth: { user },
  } = useStores();

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: updatePhoneNumberForm,
  } = useSteps({
    stepComponents,
    validationSchemas: updatePhoneNumberValidationSchemas,
    formParameters: {
      initialValues: {
        countryCode: AUSTRALIA_DEFAULT_VALUE,
      },
    },
    firstStepGoBackURL: '/profile',
  });

  useEffect(() => {
    updatePhoneNumberForm?.validateForm();
  }, [pageIndex, updatePhoneNumberForm?.validateForm]);

  const handleRedirectProfile = useCallback(() => history.push('/profile'), []);

  return (
    <SidebarContainer>
      <form
        style={{ height: 'inherit' }}
        onSubmit={updatePhoneNumberForm.handleSubmit}
      >
        {React.cloneElement(stepComponents[pageIndex], {
          errors: updatePhoneNumberForm.errors,
          values: updatePhoneNumberForm.values,
          touched: updatePhoneNumberForm.touched,
          pageIndex,
          handleNext,
          handleBack,
          onChange: updatePhoneNumberForm.handleChange,
          onBlur: updatePhoneNumberForm.handleBlur,
          setFieldError: updatePhoneNumberForm.setFieldError,
          setFieldValue: updatePhoneNumberForm.setFieldValue,
          handleRedirectProfile,
          user,
        })}
      </form>
    </SidebarContainer>
  );
};

export default UpdatePhoneNumberPage;
