import * as yup from 'yup';

import individualFormValidationSchema from './individualFormValidationSchema';
import addressValidationSchema from './addressValidationSchema';

const auMedicareValidationSchema = yup.object({
  medicareCardColour: yup.string().required('Type of Card is required.'),
  medicareCardExpiryDate: yup.string().required().label('Card Expiry Date'),
  medicareCardNumber: yup.string().required().label('Medicare Card Number'),
  medicareIndividualReferenceNumber: yup
    .string()
    .required()
    .label('Individual Ref Number'),
  medicareNameOnCard: yup.string().required().label('Full Name'),
  ...individualFormValidationSchema(),
  ...addressValidationSchema(),
});

export default auMedicareValidationSchema;
