import React, { useMemo } from 'react';

import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  amountCellRenderer,
  amountWithDollarCellRenderer,
} from 'utils/Datatables/cellRenderer';
import { REPORTING_TEMPLATE_CLOSED_END } from 'constants/funds';

const InvestmentInfo = ({ portfolio }) => {
  const estimatedValue = useMemo(
    () =>
      portfolio.unitBalance *
      (portfolio.fundConfiguration?.fundUpdates?.[0]?.unitPriceNet || 0),
    [portfolio],
  );

  const infos = useMemo(() => {
    if (portfolio?.fund?.reportingTemplate === REPORTING_TEMPLATE_CLOSED_END) {
      return [
        {
          title: 'Units',
          value: amountCellRenderer({ value: portfolio.unit, fractions: 2 }),
        },
        {
          title: 'Commitment',
          value: amountWithDollarCellRenderer({
            value: portfolio.committedAmount,
          }),
        },
        {
          title: 'Est. Value (funded)',
          value: amountWithDollarCellRenderer({
            value: estimatedValue,
            fractions: 2,
          }),
        },
      ];
    }

    return [
      {
        title: 'Units',
        value: amountCellRenderer({
          value: portfolio.unitBalance,
          fractions: 2,
        }),
      },
      {
        title: 'Unit Price',
        value: amountWithDollarCellRenderer({
          value: portfolio?.fundConfiguration?.fundUpdates?.[0]?.unitPriceNet,
        }),
      },
      {
        title: 'Est. Value',
        value: amountWithDollarCellRenderer({
          value: estimatedValue,
          fractions: 2,
        }),
      },
    ];
  }, [portfolio, estimatedValue]);

  return (
    <Grid container item minHeight={125} alignContent="center">
      <Grid item xs={3.5} textAlign="center">
        <Typography variant="bodyLead" fontWeight={500}>
          {infos[0].value}
        </Typography>
        <Typography>{infos[0].title}</Typography>
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs textAlign="center">
        <Typography variant="bodyLead" fontWeight={500}>
          {infos[1].value}
        </Typography>
        <Typography>{infos[1].title}</Typography>
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={3.5} textAlign="center">
        <Typography variant="bodyLead" fontWeight={500}>
          {infos[2].value}
        </Typography>
        <Typography>{infos[2].title}</Typography>
      </Grid>
    </Grid>
  );
};

export default InvestmentInfo;
