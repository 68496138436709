import React from 'react';

import { Typography } from '@mui/material';

const note1 =
  "This figures displayed are the net returns for the fund over various time periods. Net returns reflect the performance after deducting all fees and expenses. For periods longer than 1 year, the returns are annualised to provide a consistent measure of the fund's average yearly performance, allowing you to compare the fund's performance over different time horizons. The longer a fund runs, the more time periods will be visible. Past performance is not a guarantee nor necessarily a reliable indicator of future performance.";
const note2 =
  "Please note, the return is ‘annualised’ and, therefore, at the start of an investment, this will not reflect actual returns. In addition, during the investment phase, returns will likely be lower (or negative) due to the time required for investments to mature and generate returns. Consequently, early performance should not be seen as indicative of the fund's long-term potential, with returns likely escalating towards the end of the fund’s lifecycle. Past performance is not a guarantee nor necessarily a reliable indicator of future performance.";

const Notes = () => (
  <>
    <Typography fontWeight={700}>Notes:</Typography>
    <br />
    <Typography>{note1}</Typography>
  </>
);

export default Notes;
