import user from './user';
import fundDetails from './fundDetails';
import article from './article';
import event from './event';
import video from './video';
import fundRedemptionRequest from './fundRedemptionRequest';

export default {
  user,
  fundDetails,
  article,
  event,
  video,
  fundRedemptionRequest,
};
