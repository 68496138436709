import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import { getAccordionFooter } from 'containers/Admin/Funds/Details/FundDetails/HelperFunction';

import Category from './Category';
import DraggableContainer from './DraggableContainer';

const defaultStatementData = {
  category: '',
  documents: [],
  subFolders: [],
};

const UploadCategoryList = ({
  value = [],
  setFieldValue,
  fieldName,
  onUploadFiles,
  onDeleteFiles,
  disabled,
  maxSize,
  withSubFolders,
}) => {
  const [categories, setCategories] = useState(value);
  const [reinitializeCategory, setReinitializeCategory] = useState(false);

  const handleAddCategories = useCallback(() => {
    setCategories((prevCategoriesState) => [
      ...prevCategoriesState,
      { ...defaultStatementData, tempId: new Date().getTime() },
    ]);
  }, []);

  const handleChangeCategories = useCallback(
    (categoryIndex) => (categoryValues) => {
      if (reinitializeCategory) setReinitializeCategory(false);
      setCategories((prevCategoriesState) => {
        const newCategories = [...prevCategoriesState];
        newCategories[categoryIndex] = categoryValues;
        return newCategories;
      });
    },
    [reinitializeCategory],
  );

  const handleDeleteCategories = useCallback(
    (categoryIndex) =>
      ({ category }) => {
        setCategories((prevCategoriesState) =>
          prevCategoriesState.filter((_, index) => index !== categoryIndex),
        );

        onDeleteFiles(
          category.documents.map((document) => document.key),
          { category, isDeleteCategory: true },
        );

        setReinitializeCategory(true);
      },
    [],
  );

  useEffect(() => {
    if (setFieldValue) setFieldValue(fieldName, categories);
  }, [JSON.stringify(categories), setFieldValue, fieldName]);

  const handleMoveCategory = useCallback((index, newIndex) => {
    setCategories((prevCategories) => {
      const newCategories = [...prevCategories];

      // Swap the category index.
      [newCategories[index], newCategories[newIndex]] = [
        newCategories[newIndex],
        newCategories[index],
      ];

      return newCategories;
    });
  }, []);

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        {categories.map((category, index) => (
          <Grid
            item
            xs={12}
            p={1}
            key={`category-${category.id || category.tempId}`}
          >
            <DraggableContainer
              type="category"
              id={category.id}
              index={index}
              onMove={handleMoveCategory}
            >
              <Category
                value={category}
                index={index + 1}
                onChange={handleChangeCategories(index)}
                onDeleteCategory={handleDeleteCategories(index)}
                reinitializeCategory={reinitializeCategory}
                onUploadFiles={onUploadFiles}
                onDeleteFiles={onDeleteFiles}
                disabled={category.disabled || disabled}
                maxSize={maxSize}
                withSubFolders={
                  !(category.disabled || disabled) && withSubFolders
                }
              />
            </DraggableContainer>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        {getAccordionFooter({
          addKey: 'Category',
          onAdd: !disabled && handleAddCategories,
          addIconColor: 'black',
          textColor: 'black',
        })}
      </Grid>
    </Grid>
  );
};

export default UploadCategoryList;
