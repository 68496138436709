import Services from 'api/services';
import {
  actionCellRenderer,
  amountCellRenderer,
  amountWithDollarCellRenderer,
  dateCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';

export const getClosedEndFundPerformanceHeaders = ({ onDelete }) => [
  {
    field: 'date',
    headerName: 'Value Date',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'irrGross',
    headerName: 'IRR (Gross)',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'irrNet',
    headerName: 'IRR (Net)',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'moicNet',
    headerName: 'MOIC (Net)',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'tvpi',
    headerName: 'TVPI (Gross)',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'dpi',
    headerName: 'DPI (Gross)',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'rvpi',
    headerName: 'RVPI (Gross)',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'pme',
    headerName: 'PME (Gross)',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'deleteAction',
    headerName: '',
    flex: 0.5,
    renderCell: ({ row }) => actionCellRenderer({ row, onDelete }),
  },
];

export const getOpenEndFundPerformanceHeaders = ({ onDelete }) => [
  // {
  //   field: 'id',
  //   headerName: 'ID',
  //   flex: 0.33,
  // },
  {
    field: 'date',
    headerName: '',
    flex: 1.5,
    renderCell: dateCellRenderer,
  },
  {
    field: 'returnGross_3Month',
    headerName: 'Gross',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnNet_3Month',
    headerName: 'Net',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnGrossYtd',
    headerName: 'Gross',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnNetYtd',
    headerName: 'Net',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnGross_1Year',
    headerName: 'Gross',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnNet_1Year',
    headerName: 'Net',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnGross_3Year',
    headerName: 'Gross',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnNet_3Year',
    headerName: 'Net',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnGross_7Year',
    headerName: 'Gross',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnNet_7Year',
    headerName: 'Net',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnGross_10Year',
    headerName: 'Gross',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnNet_10Year',
    headerName: 'Net',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnGrossSinceInception',
    headerName: 'Gross',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'returnNetSinceInception',
    headerName: 'Net',
    flex: 1,
    renderCell: ({ value }) => percentCellRenderer({ value, fractions: 2 }),
  },
  {
    field: 'deleteAction',
    headerName: '',
    flex: 0.5,
    renderCell: ({ row }) => actionCellRenderer({ row, onDelete }),
  },
];

export const openEndFundPerformanceGroupingModel = [
  {
    groupId: 'Value Date',
    children: [{ field: 'date' }],
  },
  {
    groupId: '3 Months',
    children: [{ field: 'returnGross_3Month' }, { field: 'returnNet_3Month' }],
  },
  {
    groupId: 'YTD',
    children: [{ field: 'returnGrossYtd' }, { field: 'returnNetYtd' }],
  },
  {
    groupId: '1 Year',
    children: [{ field: 'returnGross_1Year' }, { field: 'returnNet_1Year' }],
  },
  {
    groupId: '3 Years',
    children: [{ field: 'returnGross_3Year' }, { field: 'returnNet_3Year' }],
  },
  {
    groupId: '7 Years',
    children: [{ field: 'returnGross_7Year' }, { field: 'returnNet_7Year' }],
  },
  {
    groupId: '10 Years',
    children: [{ field: 'returnGross_10Year' }, { field: 'returnNet_10Year' }],
  },
  {
    groupId: 'Since Inception',
    children: [
      { field: 'returnGrossSinceInception' },
      { field: 'returnNetSinceInception' },
    ],
  },
];

export const fetchAdminFundPerformancesByFundConfigurationId = async ({
  fundConfigurationId,
}) => {
  try {
    if (!fundConfigurationId) return {};

    const res = await Services.adminGetFundPerformancesByFundConfigurationId({
      fundConfigurationId,
    });
    return res?.data;
  } catch (_) {
    return {};
  }
};
