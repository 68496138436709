import React, { memo, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

import AdminFieldWrapper from 'components/AdminFormContainer/AdminFieldWrapper';
import ShowMe from 'components/ShowMe';
import Dropdown from 'components/Dropdown';
import FormDivider from 'components/OptionDivider/FormDivider';

import {
  USER_STATUS_OPTIONS,
  ACTIVE,
  SUSPENDED,
  BLOCKED,
} from 'constants/userStatus';
import { USER_ROLES_OPTIONS } from 'constants/userManagement';

import palette from 'theme/palette';
import UserDetailsForm from './UserDetailsForm';
import GroupAutoComplete from './GroupAutoComplete';

const dividerStyles = {
  margin: '16px 0',
};

const UserForm = ({
  errors = {},
  isView,
  onChange,
  onBlur,
  handleChangePassword,
  handleResetPassword,
  handleResendVerificationEmail,
  handleRedirectUserList,
  touched = {},
  validationResults,
  values = {},
  isEdit,
}) => {
  const userStatusOptionsRemapped = useMemo(() => {
    let userStatusOptions = USER_STATUS_OPTIONS.filter((userStatus) =>
      [SUSPENDED, BLOCKED]?.includes(userStatus?.value),
    );
    userStatusOptions = [
      { label: 'Enabled', value: ACTIVE },
      ...userStatusOptions,
    ];
    return userStatusOptions;
  }, []);

  return (
    <Grid container data-testid="user-form">
      <AdminFieldWrapper
        handleBack={handleRedirectUserList}
        title="User Details"
      >
        <UserDetailsForm
          errors={errors}
          isView={isView}
          onChange={onChange}
          onBlur={onBlur}
          handleChangePassword={handleChangePassword}
          handleResetPassword={handleResetPassword}
          handleResendVerificationEmail={handleResendVerificationEmail}
          touched={touched}
          validationResults={validationResults}
          values={values}
          isEdit={isEdit}
        />
      </AdminFieldWrapper>

      <AdminFieldWrapper title="Role &amp; Access">
        <Grid item container mt={4} xs={12} display="flex" flexDirection="row">
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel>Platform role</FormLabel>
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              data-testid="role-dropdown"
              disabled={isView}
              options={USER_ROLES_OPTIONS}
              onBlur={onBlur}
              value={values.role}
              errorValue={touched.role && errors.role}
              onChange={onChange}
              name="role"
              placeholder="User"
            />
          </Grid>
        </Grid>
      </AdminFieldWrapper>
      <FormDivider
        width="100%"
        style={dividerStyles}
        bordercolor={palette.border.light2}
      />
      <AdminFieldWrapper title="User status">
        <Grid item container xs={12} display="flex" flexDirection="row">
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel>Status</FormLabel>
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              data-testid="status-dropdown"
              disabled={isView}
              options={userStatusOptionsRemapped}
              onBlur={onBlur}
              value={values.status}
              errorValue={touched.status && errors.status}
              onChange={onChange}
              name="status"
              placeholder="Enabled"
            />
            <ShowMe when={!isView}>
              <FormHelperText>Enabled / Suspended / Blocked</FormHelperText>
            </ShowMe>
          </Grid>
        </Grid>
      </AdminFieldWrapper>

      <AdminFieldWrapper title="Group">
        <Grid item container xs={12} display="flex" flexDirection="row">
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel>Status</FormLabel>
          </Grid>
          <Grid item xs={4}>
            <GroupAutoComplete disabled={isView} />
          </Grid>
        </Grid>
      </AdminFieldWrapper>
    </Grid>
  );
};

export default memo(UserForm);
