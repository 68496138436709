import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const Card = styled(Grid)(({ theme, ischild }) => ({
  border: `1px solid ${theme.palette.reach.blueBorder}`,
  borderRadius: '5px',
  background:
    ischild === 'true'
      ? theme.palette.reach.grey5
      : theme.palette.reach.blueLight,
}));

export default Card;
