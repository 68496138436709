import React, { useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from 'components/Chip';
import Input from 'components/Input';

const inputRenderer = (params) => <Input {...params} />;
const tagRenderer = (tagValue, getTagProps) =>
  tagValue.map((option, index) => (
    <Chip
      label={option.label}
      color={option.color}
      {...getTagProps({ index })}
    />
  ));
const optionLabel = (option) => option.label;
const options = [
  { label: 'Financial Wealth', value: 'FINANCIAL_WEALTH', color: 'blue' },
  { label: 'Growth', value: 'GROWTH', color: 'purpleHaze' },
];
const GroupAutoComplete = ({ onChange, value, disabled }) => {
  const handleAutoCompleteChange = useCallback(() => {
    // handle change here
  }, []);

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      value={value}
      onChange={handleAutoCompleteChange}
      renderInput={inputRenderer}
      renderTags={tagRenderer}
      getOptionLabel={optionLabel}
      options={options}
    />
  );
};

export default GroupAutoComplete;
