import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import history from 'utils/history';
import EndStepContainer from 'components/EndStepContainer';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import InvestmentEntityContainer from './InvestmentEntityContainer';

const routeToDashboard = () =>
  history.replace(history?.location?.state?.href || '/');

const InvestmentSuccess = ({
  buttonLabel = 'View Offerings',
  onClick = routeToDashboard,
  endStepSubtitle = 'Thank you for submitting your application for our review.',
  reviewText = 'Therefore, we will carefully review your application before approving your account for investment.',
  containerLabel,
}) => (
  <InvestmentEntityContainer
    containerLabel={containerLabel}
    style={{ textAlign: 'center' }}
    onNext={onClick}
    nextButtonLabel={buttonLabel}
  >
    <EndStepContainer
      title={
        <>
          <CheckIcon height={40} />
          <Typography variant="h2" mt={2}>
            Thank you!
          </Typography>
          <Typography variant="h2">
            Your application is under review!
          </Typography>
        </>
      }
      containerStyles={{
        marginTop: 0,
      }}
      subtitle={<Typography variant="body">{endStepSubtitle}</Typography>}
    >
      <Grid item container xs={12} mt={4} justifyContent="center">
        <Grid item xs={12} md={7}>
          <Typography variant="body">
            We are committed to provide you with the best quality service.&nbsp;
            {reviewText}
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} mt={4}>
          <Typography variant="body">
            We will reach out to you as soon as possible when we have completed
            our review or if we need anything further information.
          </Typography>
        </Grid>
      </Grid>
    </EndStepContainer>
  </InvestmentEntityContainer>
);

export default InvestmentSuccess;
