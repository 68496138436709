import React from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

import condTrueFalse from 'utils/condTrueFalse';

import PaginationDataTable from './PaginationDataTable';

const StyledDataGrid = styled(DataGrid)(
  ({ wrapheader, evenrowcolor = '#F6F6F6' }) => ({
    '& .MuiDataGrid-columnHeaderTitle': wrapheader && {
      textOverflow: 'clip',
      whiteSpace: 'break-spaces',
      lineHeight: 1,
    },
    '& .MuiDataGrid-columnHeaderTitle, .cellBold': {
      fontWeight: 700,
    },
    '& .MuiDataGrid-row:nth-child(even)': {
      backgroundColor: evenrowcolor,
    },
    // Hide Borders
    '&.MuiDataGrid-root, .MuiDataGrid-columnHeaders': {
      border: '0px !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  }),
);

const DataTable = ({
  headerData,
  data = [],
  pageSize = 10,
  totalData = 100,
  height = '400px',
  width = '100%',
  isLoading,
  setPage,
  setSort,
  withCheckbox = true,
  pagination = true,
  containerProps = {},
  pageSizeOptions = [10, 50, 100],
  setPaginationModel,
  ...props // beside the mentioned above, everything else is optional
}) => (
  <div style={{ height, width, ...containerProps }}>
    <StyledDataGrid
      columns={headerData}
      rows={data}
      loading={isLoading}
      rowCount={totalData}
      pageSize={pageSize}
      checkboxSelection={withCheckbox}
      pagination={pagination}
      paginationMode="server"
      onPageChange={(newPage) =>
        condTrueFalse(setPage, () => setPage(newPage + 1))
      }
      sortingMode="server"
      onSortModelChange={(sortMode) =>
        condTrueFalse(setSort, () => setSort(sortMode))
      }
      disableColumnMenu
      components={{
        Pagination: pagination ? PaginationDataTable : undefined,
      }}
      pageSizeOptions={pageSizeOptions}
      onPaginationModelChange={setPaginationModel}
      {...props}
    />
  </div>
);

export default DataTable;
