import React from 'react';
import MUITab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

import palette from 'theme/palette';

const StyledTab = styled(MUITab)(({ theme, minwidth }) => ({
  minWidth: minwidth || 'auto',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    '& .MuiTab-iconWrapper': {
      display: 'none',
    },
  },
}));

const Tab = ({
  icon: Icon = () => <span />,
  iconColorSelected = 'active-icon-color',
  iconColor = '',
  selected,
  hidden,
  ...props
}) => (
  <StyledTab
    {...props}
    icon={
      <Icon
        width={24}
        height={24}
        className={selected ? iconColorSelected : iconColor}
      />
    }
    style={{
      textTransform: 'none',
      color: selected ? palette.reach.purple : 'inherit',
      display: hidden ? 'none' : undefined,
    }}
  />
);

export default Tab;
