import React, { useCallback, useState, useMemo, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import SecondaryButton from 'components/Button/SecondaryButton';
import TextButton from 'components/Button/TextButton';
import { useStores, useActions } from 'components/StateProvider';
import {
  REACH_ADMIN_EMAIL,
  REACH_EMAIL_SUBJECT_PERSONAL_AMENDMENT,
} from 'constants/general';
import { sendEmailTo } from 'utils/email';

import BottomContainer from './components/BottomContainer';
import ContactAdminDialogContent from './components/ContactAdminDialogContent';
import ProfileForm from './components/ProfileForm';

const handleSendMail = () =>
  sendEmailTo({
    email: REACH_ADMIN_EMAIL,
    subject: REACH_EMAIL_SUBJECT_PERSONAL_AMENDMENT,
  });

const ProfileDetailPage = () => {
  const {
    auth: { user },
  } = useStores();
  const { handleGetUserProfile } = useActions();

  useEffect(() => {
    handleGetUserProfile();
  }, []);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const dialogActions = useMemo(
    () => (
      <SecondaryButton
        data-testid="contact-admin-close-btn"
        variant="outlined"
        onClick={handleCloseDialog}
      >
        Close
      </SecondaryButton>
    ),
    [],
  );

  return (
    <Grid
      container
      spacing={5}
      style={{ placeItems: 'center' }}
      direction="column"
      data-testid="profile-detail-page-container"
    >
      <Grid item>
        <Typography variant="h1">My Details</Typography>
      </Grid>
      <Grid item>
        <Avatar
          data-testid="profile-detail-page-avatar"
          user={user}
          sx={{ width: 125, height: 125 }}
          typographyVariant="avatarTextBig"
        />
      </Grid>
      <Grid item textAlign="center">
        <Typography variant="h5" fontWeight={700} marginBottom={1}>
          {user.firstName} {user.lastName}
        </Typography>
        <Typography variant="bodyGrey">{user.email}</Typography>
      </Grid>

      <ProfileForm values={user} />

      <Grid
        container
        item
        spacing={{ xs: 3, sm: 1 }}
        textAlign="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="bodyGrey">
            To change or update your profile detail you need to contact the
            administrator.
          </Typography>
        </Grid>
        <Grid item>
          <TextButton
            data-testid="contact-admin-btn"
            onClick={handleOpenDialog}
          >
            Why I should contact administrator to edit my details?
          </TextButton>
        </Grid>
      </Grid>

      <Grid container item justifyContent="center">
        <Grid item xs={10} sm={6} md={4} lg={3}>
          <Button
            data-testid="send-mail-btn"
            fullWidth
            onClick={handleSendMail}
          >
            <Typography variant="subtitle1White">
              Contact administrator
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} width="100%">
        <hr />
      </Grid>

      <BottomContainer values={user} />

      <Dialog
        dataTestKey="contact-admin"
        open={openDialog}
        onClose={handleCloseDialog}
        title="Why I need to contact administrator"
        content={ContactAdminDialogContent}
        dialogActions={dialogActions}
      />
    </Grid>
  );
};

export default ProfileDetailPage;
