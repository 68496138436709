import * as yup from 'yup';

const yupShouldTrue = yup
  .bool()
  .required('Please accept the condition.')
  .isTrue('Please accept the condition.');

const bankSummaryValidationSchema = yup.object({
  isConfirmed: yupShouldTrue,
  ePaymentAgreement: yupShouldTrue,
});

export default bankSummaryValidationSchema;
