import React, { useCallback } from 'react';
import TrusteeDetails from 'containers/DashboardPage/components/InvestmentEntityList/TrusteeDetails';
import { Divider, Grid } from '@mui/material';
import useDialog from 'hooks/useDialog';
import AddButton from 'components/Button/AddButton';
import { mapDetailsToPayload } from './HelperFunction';
import AddTrusteeDialog from '../Dialog/AddTrusteeDialog';

const getPayloadKey = (label) => {
  if (label === 'Individual') return 'individualTrustee';
  return 'companyTrustee';
};

const Trustees = ({ values = [], onChange, withEdit, userId }) => {
  const {
    isDialogOpen: isAddTrusteeDialogOpen,
    handleOpenDialog: handleOpenAddTrusteeDialog,
    handleCloseDialog: handleCloseAddTrusteeDialog,
  } = useDialog();

  const handleChange = (newValues) => {
    const oldValues = values?.find((trustee) => trustee.id === newValues.id);

    if (oldValues) {
      const { label = '', ...restValues } = newValues;

      const { addCertificates, payload } = mapDetailsToPayload({
        oldValues,
        newValues: restValues,
        key: getPayloadKey(label),
        certificateKey: label,
      });
      onChange({
        fileUpload: addCertificates,
        fileUploadKey: `${label.toLowerCase()}Certificate`,
        ...payload,
      });
    }
  };
  const handleDelete = useCallback(
    (index) => () => {
      const selectedTrustee = values[index];
      const deleteKey =
        selectedTrustee.label === 'Company'
          ? 'deleteCompanyTrustee'
          : 'deleteIndividualTrustee';
      onChange({
        [deleteKey]: {
          id: selectedTrustee.id,
        },
      });
    },
    [],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <TrusteeDetails
          setValues={handleChange}
          withEdit={withEdit}
          values={values}
          certificateDownloadable
          onDelete={withEdit && values?.length > 1 && handleDelete}
        />
      </Grid>
      <Grid item xs={12}>
        {withEdit && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <AddButton
                onClick={handleOpenAddTrusteeDialog}
                disabled={values?.length >= 5}
                label="Add another trustee"
              />
            </Grid>
            <AddTrusteeDialog
              open={isAddTrusteeDialogOpen}
              onClose={handleCloseAddTrusteeDialog}
              onSubmit={onChange}
              userId={userId}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Trustees;
