import React, { useCallback } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DatePicker from 'components/DatePicker';

const PeriodDate = ({ readOnly, values, setFieldValue }) => {
  const handleChangeStartDate = useCallback(
    (name, date) => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0);
      setFieldValue(name, newDate);
    },
    [values, setFieldValue],
  );

  const handleChangeEndDate = useCallback(
    (name, date) => {
      const newDate = new Date(date);
      newDate.setHours(23, 59, 59);
      setFieldValue(name, newDate);
    },
    [values, setFieldValue],
  );

  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5.5}>
        <FormLabel>Start Date</FormLabel>
        <DatePicker
          fullWidth
          name="startDate"
          testId="startDate-input"
          disabled={readOnly}
          maxDate={values.endDate}
          setFieldValue={handleChangeStartDate}
          value={values.startDate}
        />
      </Grid>
      <Grid container item xs={1} alignItems="center" justifyContent="center">
        <Typography paddingTop={2}> to </Typography>
      </Grid>
      <Grid item xs={5.5}>
        <FormLabel>End Date</FormLabel>
        <DatePicker
          fullWidth
          testId="endDate-input"
          name="endDate"
          disabled={!!values.periodStatus || readOnly}
          maxDate={null}
          minDate={values.startDate}
          setFieldValue={handleChangeEndDate}
          value={values.endDate}
        />
      </Grid>
    </Grid>
  );
};

export default PeriodDate;
