import React, { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import EndStepContainer from 'components/EndStepContainer';
import Confetti from 'components/Confetti';
import Dialog from 'components/Dialog';
import SubmitCancel from 'components/Dialog/Actions/SubmitCancel';

import history from 'utils/history';

import palette from 'theme/palette';

import InvestmentEntityContainer from '../AddInvestmentEntity/components/InvestmentEntityContainer';

const routeToDashboard = () =>
  history.replace(history?.location?.state?.href || '/');

const routeToMyPortfolio = () => history.replace('/dashboard/portfolio');

const FundInvestmentSuccess = ({
  buttonLabel = 'My Portfolio',
  onClick = routeToDashboard,
  endStepSubtitle = 'Thank you for submitting your investment application form.',
  reviewText = 'Therefore, we will carefully review your investment application.',
  containerLabel,
}) => {
  const content = useMemo(
    () => (
      <EndStepContainer
        title={
          <div style={{ position: 'relative' }}>
            <Typography variant="h2">Thank you,</Typography>
            <Typography variant="h2">your application is complete!</Typography>
            <Grid item xs={12} mt={2}>
              <Divider
                sx={{
                  backgroundColor: palette.reach.purpleHaze,
                  borderWidth: 1,
                }}
              />
            </Grid>

            <Confetti />
          </div>
        }
        containerStyles={{
          marginTop: 0,
        }}
        subtitle={
          <Typography variant="h4" color={palette.text.fuchsia}>
            {endStepSubtitle}
          </Typography>
        }
      >
        <Grid item container xs={12} justifyContent="center">
          <Typography variant="bodyPrimary">
            We are committed to provide you with the best quality service.&nbsp;
            {reviewText}&nbsp; We will reach out to you as soon as possible when
            we have completed our review or if we need anything further
            information.
          </Typography>
        </Grid>
      </EndStepContainer>
    ),
    [],
  );

  return (
    <InvestmentEntityContainer
      containerLabel={containerLabel}
      style={{ textAlign: 'center', minHeight: '600px' }}
    >
      <Dialog
        fullWidth
        open
        title=" "
        dialogTitleProps={{
          backgroundColor: palette.reach.obsidian,
          minHeight: 24,
        }}
        content={content}
        dialogActions={
          <SubmitCancel
            cancelLabel={buttonLabel}
            submitLabel="Fund Dashboard"
            containerProps={{ justifyContent: 'center' }}
            cancelBtnProps={{ color: palette.reach.bluish }}
            onCancelClick={routeToMyPortfolio}
            onSubmitClick={onClick}
          />
        }
        dialogContentProps={{
          style: { maxHeight: '700px', paddingTop: '16px' },
        }}
      />
    </InvestmentEntityContainer>
  );
};
export default FundInvestmentSuccess;
