import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DoughnutChart from 'components/Chart/DoughnutChart';

import palette from 'theme/palette';
import { amountWithDollarCellRenderer } from 'utils/Datatables/cellRenderer';

const defaultColor = [
  palette.reach.fuchsia,
  '#D9D9D9',
  '#2350BA',
  palette.reach.blueLight,
  palette.reach.yellowWarning,
];

const CommitmentsChart = ({ commitments }) => {
  const chartData = useMemo(() => {
    const lastCommitment = commitments[commitments.length - 1];

    if (!lastCommitment) return [];

    const data = [
      {
        value: lastCommitment.funded,
        label: `Funded ${lastCommitment.totalCapitalCall}%`,
        color: defaultColor[0],
      },
      {
        value: lastCommitment.currentTotalInvestments - lastCommitment.funded,
        label: `Unfunded ${100 - lastCommitment.totalCapitalCall}%`,
        color: defaultColor[1],
      },
    ];

    return data;
  }, [commitments]);

  const chartTitle = useMemo(
    () =>
      commitments[commitments.length - 1]?.totalCapitalCall >= 100
        ? 'Funded'
        : 'Partially Funded',
    [commitments],
  );

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12} md={3.5} alignContent="center">
        <Typography variant="bodyLead" fontWeight={700} mb={2} display="block">
          {chartTitle}
        </Typography>
        <Typography variant="body" display="block">
          Total commitment:
        </Typography>
        <Typography variant="body">
          {amountWithDollarCellRenderer({
            value: commitments[commitments.length - 1]?.currentTotalInvestments,
            fractions: 2,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8.5} pr={{ xs: 0, lg: '5%' }}>
        <DoughnutChart
          data={chartData}
          style={{ marginTop: '16px', maxHeight: '300px' }}
          options={{
            cutout: 110,
            plugins: {
              borderWidth: 0,
              legend: {
                position: 'right',
                labels: {
                  boxWidth: 28,
                  boxHeight: 28,
                  padding: 10,
                },
                fullWidth: false,
                align: 'middle',
                responsive: false,
              },
              title: {
                display: false,
                text: chartTitle,
                color: 'black',
                fontFamily: 'Inter',
                font: {
                  size: 18,
                },
              },
              subtitle: {
                display: false,
                text: 'Est. Portfolio Value',
              },
            },
            centerText: {
              display: false,
              text: 'Est. Portfolio Value',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CommitmentsChart;
