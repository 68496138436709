import React from 'react';
import { TaxSupportSummary, TaxDetailSummary } from './HelperFunction';

const CompanyTaxDetail = ({
  taxDetails,
  supportingInformation,
  withEdit = false,
  onChange,
}) => (
  <>
    {taxDetails?.map((taxDetail) => (
      <TaxDetailSummary
        values={taxDetail}
        key={`taxDetail-${taxDetail?.id}`}
        withEdit={withEdit}
        setValues={onChange}
      />
    ))}
    <TaxSupportSummary
      values={supportingInformation}
      withEdit={withEdit}
      setValues={onChange}
    />
  </>
);
export default CompanyTaxDetail;
