import React, { memo, useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import { FUND_CARD_DEFAULT_CONTENTS } from 'constants/general';
import palette from 'theme/palette';
import config from 'utils/config';

import ImageContent from './ImageContent';
import FundContent from './FundContent';

const fundContentBorderTopWidth = 8;

const FundCardLandscape = ({
  contentList = FUND_CARD_DEFAULT_CONTENTS,
  headerImageSrc = '',
  logoSrc = '',
  status = 'Open',
  title = '[Fund name]',
  fundType = '[Fund short desc]',
  onClickViewDetail,
  height = 180,
  isOpen,
  isInvested,
  portfolios,
  viewOnly,
}) => {
  const [isActive, setIsActive] = useState(false);

  const onMouseEnter = useCallback(() => setIsActive(true), []);

  const onMouseLeave = useCallback(() => setIsActive(false), []);

  const {
    borderColor,
    statusStyles,
    buttonStyles = {},
    contentStyles,
  } = useMemo(() => {
    if (isActive || viewOnly)
      return {
        borderColor: palette.reach.menuLink,
        statusStyles: {
          backgroundColor: palette.reach.menuLink,
          right: 'auto',
          left: 0,
          transition: 'background-color .3s linear',
        },
        buttonStyles: {
          backgroundColor: palette.reach.menuLink,
          color: 'white',
          borderColor: 'transparent',
          transition: 'background-color .5s linear',
          '&:hover': {
            backgroundColor: palette.reach.menuLink,
            borderColor: 'transparent',
          },
        },
        contentStyles: {
          opacity: 1,
          transition: 'opacity .5s linear',
        },
      };

    const statusPosition = isOpen ? { right: 0 } : { left: 0 };

    return {
      borderColor: 'black',
      statusStyles: {
        ...statusPosition,
        transition: 'background-color .5s linear',
      },
      buttonStyles: {
        transition: 'background-color .5s linear',
      },
      contentStyles: {
        opacity: 0,
        transition: 'opacity .5s linear',
      },
    };
  }, [isActive]);

  return (
    <Grid
      container
      columnSpacing={2}
      minHeight={height}
      maxHeight={height}
      sx={{ cursor: 'pointer' }}
      onClick={onClickViewDetail}
    >
      <Grid container item xs={4} maxHeight="inherit">
        <ImageContent
          height={height}
          logoSrc={logoSrc}
          headerImageSrc={headerImageSrc}
        />
      </Grid>

      <Grid
        container
        item
        xs={8}
        direction="column"
        sx={{
          backgroundColor: config.system.appColours.cardBackground,
          borderTop:
            !viewOnly && `${fundContentBorderTopWidth}px ${borderColor} solid`,
          position: 'relative',
          transition: 'border-top .5s',
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <FundContent
          viewOnly={viewOnly}
          contentList={contentList}
          description={fundType}
          onClickViewDetail={onClickViewDetail}
          status={status}
          title={title}
          fundStatusTop={`${fundContentBorderTopWidth * -1}px`}
          statusStyles={statusStyles}
          buttonStyles={buttonStyles}
          contentStyles={contentStyles}
          isInvested={isInvested}
          portfolios={portfolios}
          isActive={isActive}
        />
      </Grid>
    </Grid>
  );
};
export default memo(FundCardLandscape);
