import * as yup from 'yup';

import getNumbers from 'utils/strings/getNumbers';

const redemptionAmountValidationSchema = yup.object({
  selectedPortfolio: yup.object().nullable(),
  redemptionUnitAmount: yup
    .mixed()
    .when('selectedPortfolio', (selectedPortfolio, schema) =>
      schema.test({
        test: (redemptionUnitAmount) =>
          getNumbers(redemptionUnitAmount) <= selectedPortfolio.unit,
        message: 'Approved Amount cannot be greater than owned units',
      }),
    )
    .required()
    .label('Redemption Amount'),
});

export default redemptionAmountValidationSchema;
