import React from 'react';

import Grid from '@mui/material/Grid';

import useDataTables from 'hooks/useDataTables';
import services from 'api/services';

import MarketTransactionList from './MarketTransactionList';
import MarketTransactionLineChart from './MarketTransactionLineChart';

const ValueTab = ({ portfolio }) => {
  const {
    pageSize,
    apiData: transactions,
    isFetchLoading,
    setPage,
    setSort,
  } = useDataTables({
    fetchService: () =>
      services.getPortfolioMarketTransactions({
        fundConfigurationId: portfolio?.fundConfigurationId,
        userInvestorMapId: portfolio?.userInvestorMapFk,
      }),
    defaultApiData: [],
  });

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <MarketTransactionLineChart
          transactions={transactions}
          portfolio={portfolio}
        />
      </Grid>
      <Grid item xs={12}>
        <MarketTransactionList
          transactions={transactions}
          portfolio={portfolio}
        />
      </Grid>
    </Grid>
  );
};

export default ValueTab;
