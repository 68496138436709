import React, { useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import AdminConfirmationDialog from 'components/Dialog/AdminConfirmationDialog';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import SplitButton from 'components/SplitButton';

import useDialog from 'hooks/useDialog';
import condTrueFalse from 'utils/condTrueFalse';
import PayloadMappers from 'utils/PayloadMappers';
import Services from 'api/services';

import { ReactComponent as AddCircleIcon } from 'assets/icons/add-circle.svg';

import { getFundUpdatesHeaders } from './HelperFunction';
import { fundUpdatesTypes } from './FundUpdatesDialog/helperFunctions';
import FundUpdatesDialog from './FundUpdatesDialog';

const FundUpdates = ({
  fundUpdates = [],
  fundConfigurationId,
  onSubmitCb,
  currency,
}) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    isDialogOpen: isDialogPriceUpdateOpen,
    handleOpenDialog: handleOpenPriceUpdateDialog,
    handleCloseDialog: handleCloseDialogPriceUpdate,
  } = useDialog();
  const {
    isDialogOpen: dialogDeleteOpen,
    handleOpenDialog: handleOpenDeleteDialog,
    handleCloseDialog: handleCloseDeleteDialog,
  } = useDialog();

  const addUpdateBtnOptions = useMemo(
    () =>
      fundUpdatesTypes.map((fundUpdateType) => ({
        label: fundUpdateType.label,
        onClick: () => handleOpenPriceUpdateDialog(fundUpdateType),
      })),
    [handleOpenPriceUpdateDialog],
  );

  const handleClickAddUpdate = useCallback(
    (e, index) => {
      condTrueFalse(
        addUpdateBtnOptions[index].onClick,
        addUpdateBtnOptions[index].onClick,
      );
    },
    [addUpdateBtnOptions],
  );

  const handleDeleteFundUpdate = useCallback(async () => {
    try {
      const id = dialogDeleteOpen;

      setIsLoading(true);

      await Services.adminDeleteFundUpdate(id);

      onSubmitCb();
    } catch (err) {
      setError(err.response?.data || 'Error on deleting Fund updates');
      setIsLoading(false);
    }
  }, [dialogDeleteOpen]);

  const fundUpdatesHeaders = useMemo(
    () => getFundUpdatesHeaders({ onDelete: handleOpenDeleteDialog, currency }),
    [currency],
  );

  const handleSubmit = useCallback(
    async (formValues) => {
      try {
        setIsLoading(true);
        const payload = PayloadMappers.admin.fundUpdates.addFundUpdates({
          ...formValues,
          fundConfigurationId,
          updateType: isDialogPriceUpdateOpen?.key,
        });

        await Services.adminAddFundUpdates(payload);

        onSubmitCb();
      } catch (err) {
        setError(err.response?.data || 'Error on creating Fund updates');
        setIsLoading(false);
      }
    },
    [fundConfigurationId, isDialogPriceUpdateOpen?.key],
  );

  return (
    <Grid container rowSpacing={3}>
      <Grid container item xs={12} justifyContent="right">
        <SplitButton
          label="Add Update"
          prefixIcon={AddCircleIcon}
          options={addUpdateBtnOptions}
          onClick={handleClickAddUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <DataTable
          wrapheader
          hideFooter
          pagination={false}
          withCheckbox={false}
          headerData={fundUpdatesHeaders}
          data={fundUpdates}
          containerProps={{ backgroundColor: 'white' }}
        />
      </Grid>

      <FundUpdatesDialog
        open={!!isDialogPriceUpdateOpen}
        onClose={handleCloseDialogPriceUpdate}
        onSubmit={handleSubmit}
        selectedFundUpdatesType={isDialogPriceUpdateOpen}
        errorText={error}
        disableSubmitBtn={isLoading}
      />

      <Loading popup loading={isLoading} />

      <AdminConfirmationDialog
        title="Confirm Delete?"
        subtitle={
          <strong>Are you sure you want to delete this Fund Update?</strong>
        }
        open={dialogDeleteOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteFundUpdate}
        confirmButtonLabel="Yes"
        maxWidth="sm"
        confirmBtnDisabled={isLoading}
      />
    </Grid>
  );
};

export default FundUpdates;
