import React, { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import Chip from 'components/Chip';
import InnerHtmlContainer from 'components/InnerHtmlContainer';

import palette from 'theme/palette';

import SubmitCancel from './Actions/SubmitCancel';
import Dialog from '.';

const EventDetailDialog = ({
  open,
  title,
  category,
  eventDate,
  content,
  link,
  onClose,
}) => {
  const handleRouteToLink = useCallback(() => {
    if (!link) return;

    let url = link;

    if (!/^https?:\/\//i.test(link)) {
      url = `https://${link}`;
    }

    window.open(url, '_blank');
  }, [link]);

  const { fullDate, time } = useMemo(
    () => ({
      date: new Date(eventDate).getDate(),
      month: new Date(eventDate).toLocaleString('default', { month: 'short' }),
      fullDate: new Date(eventDate).toDateString(),
      time: new Date(eventDate).toLocaleTimeString('default', {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'long',
      }),
    }),
    [eventDate],
  );

  const dialogContent = useMemo(
    () => (
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <Chip label={category} color="selectedChipColor" sx={{ mb: 4 }} />
          <IconButton sx={{ float: 'right' }} onClick={onClose}>
            <CloseIcon color="black" />
          </IconButton>
          <Typography
            variant="display2"
            display="block"
            color={palette.text.primary}
            mb={1}
          >
            {title}
          </Typography>
          <Typography variant="display6" color={palette.text.primary}>
            {fullDate} - {time}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <InnerHtmlContainer
            htmlData={content}
            setDefaultStyle="padding: 0;"
          />
        </Grid>
      </Grid>
    ),
    [title, fullDate, time, category, content],
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      title=" "
      dialogTitleProps={{
        backgroundColor: palette.reach.obsidian,
        minHeight: 8,
        padding: '0px !important',
      }}
      content={dialogContent}
      dialogActions={
        <SubmitCancel
          submitLabel="Go to event page"
          containerProps={{ justifyContent: 'center' }}
          cancelBtnProps={{ color: palette.reach.bluish }}
          onSubmitClick={handleRouteToLink}
        />
      }
      dialogContentProps={{
        style: { maxHeight: '700px', padding: '36px 48px' },
      }}
    />
  );
};

export default EventDetailDialog;
