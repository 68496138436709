import React, { useCallback, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import useDialog from 'hooks/useDialog';
import history from 'utils/history';

import { useStores } from 'components/StateProvider';

import HistoryPopUp from './Dialog/HistoryPopUp';
import Footer from './Footer';
import Header from './Header';

const handleRedirectToApprovalList = () => history.push('/admin/approvals');

const DetailsContainer = ({
  children,
  entityId,
  isApproved,
  readOnly,
  onDelete,
}) => {
  const {
    general: { editingSummary },
  } = useStores();
  const {
    isDialogOpen: isHistoryPopupOpen,
    handleOpenDialog: handleOpenHistoryPopUp,
    handleCloseDialog: handleCloseHistoryPopUp,
  } = useDialog();

  const menus = useMemo(
    () => [
      {
        itemLabel: 'Pending',
        value: 'PENDING',
      },
      {
        itemLabel: 'Reject',
        value: 'REJECTED',
      },
    ],
    [],
  );

  const handleBack = useCallback(() => history.back(), []);

  return (
    <>
      <Paper elevation={3} sx={{ padding: '20px' }}>
        <Grid container rowSpacing={3}>
          {/* header */}
          <Header
            onBackButtonClick={handleBack}
            backButtonLabel="Entity Approval"
            onHistoryBtnClick={handleOpenHistoryPopUp}
          />
          {/* content */}
          <Grid item xs={12}>
            {children}
          </Grid>

          {/* footer */}
          <Footer
            entityId={entityId}
            menus={menus}
            approvedBtnDisabled={!!editingSummary || isApproved || readOnly}
            feedbackBtnDisabled={!!editingSummary || readOnly}
            downloadBtnDisabled={readOnly}
            approvalLabel="Approve"
            onSuccess={handleRedirectToApprovalList}
            deleteBtnDisabled={readOnly}
            onDelete={onDelete}
          />
        </Grid>
      </Paper>
      <HistoryPopUp
        data-testid="history-dialog"
        entityId={entityId}
        open={isHistoryPopupOpen}
        onClose={handleCloseHistoryPopUp}
      />
    </>
  );
};

export default DetailsContainer;
