import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import history from 'utils/history';
import getFullName from 'utils/getFullName';
import PayloadMappers from 'utils/PayloadMappers';
import ResponseMappers from 'utils/ResponseMappers';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import useTabs from 'hooks/useTabs';
import useAdminRestriction from 'hooks/useAdminRestriction';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import Loading from 'components/Loading';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import Tabs from 'components/Tabs';
import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import TabPanels from 'components/TabPanels';
import { useStores } from 'components/StateProvider';

import DetailsContainer from './DetailsContainer';
import {
  editArticle,
  fetchAdminArticleDetails,
  articleTabItems,
  sendAddArticle,
} from './HelperFunction';

const tabIdMapper = ['article'];

const handleRedirectToArticleList = () =>
  history.push('/admin/articles-events');

const ArticleDetails = () => {
  const {
    auth: { user },
  } = useStores();
  const {
    isDialogOpen: isTabAlertDialogOpen,
    handleOpenDialog: handleOpenTabAlertDialog,
    handleCloseDialog: handleCloseTabAlertDialog,
  } = useDialog();
  const { haveCrudPermission } = useAdminRestriction();

  const articleFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fundType] = useState(searchParams.get('type'));
  const [fetchStatus, setFetchStatus] = useState(true);
  const [isError, setIsError] = useState(false);
  // Manually manage 'dirty', because the form value is a deep object.
  const [dirty, setDirty] = useState(false);
  const { articleId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    isDialogOpen: openSuccessDialog,
    handleOpenDialog: handleOpenSuccessDialog,
    handleCloseDialog: handleCloseSuccessDialog,
  } = useDialog({
    onClose: (articleUid) => {
      if (!articleId && articleUid) {
        history.replace(`/admin/articles/${articleUid}?tab=article`);

        return;
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
  });

  const adminGetArticleDetails = useMemo(
    () => fetchAdminArticleDetails(articleId),
    [articleId, fetchStatus],
  );

  const { apiData: dataValues, isFetchLoading } = useFetchApi({
    url: adminGetArticleDetails,
    defaultApiData: {},
    dependencies: [adminGetArticleDetails],
  });

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  ); // trigger useMemo changes to refetch

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    setValues,
    handleChange,
  } = useFormik({
    initialValues: articleId ? {} : ResponseMappers.admin.article({}),
  });

  useEffect(() => {
    if (
      !isFetchLoading &&
      Object.keys(dataValues).length &&
      dataValues?.article?.id
    )
      setValues(ResponseMappers.admin.article(dataValues));
  }, [isFetchLoading, dataValues?.article?.id]);

  const handleSubmit = useCallback(
    async (formValues) => {
      try {
        setIsLoading(true);

        const payload = PayloadMappers.admin.article.addArticle({
          ...formValues,
          articleUid: values.article.articleUid,
          isHidden: values.isHidden,
          isFeatured: values.isFeatured,
          submitToDraft: true,
          createdBy: getFullName(user),
          updatedBy: getFullName(user),
        });

        let res;
        if (!articleId) {
          res = await sendAddArticle({ payload });
        } else {
          res = await editArticle({ id: articleId, payload });
        }

        if (res?.article?.id) {
          handleOpenSuccessDialog(res.article.articleUid);
        }

        setDirty(false);

        return res;
      } finally {
        setIsLoading(false);
      }
    },
    [values.article?.articleUid, values.isHidden, values.isFeatured],
  );

  const handleSaveDraftArticle = useCallback(
    () => articleFormRef.current?.handleSubmit?.(),
    [handleSubmit, articleFormRef.current?.handleSubmit],
  );

  const defaultTabIdx = useMemo(() => {
    if (!values.articleUid) return 0;

    const index = tabIdMapper.indexOf(searchParams.get('tab'));
    if (index === -1) return 0;
    return index;
  }, []);

  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx,
    callbackDependencies: [values.articleUid],
  });

  useEffect(() => {
    searchParams.set('tab', tabIdMapper[activeTabIdx]);
    setSearchParams(searchParams);
  }, [activeTabIdx]);

  useEffect(() => {
    setIsError(!!Object.values(errors).filter((err) => !!err).length);
  }, [errors, values.article?.title]);

  const handleSetFieldValue = useCallback(
    (field, value, shouldValidate, updateDirty = true) => {
      if (updateDirty) setDirty(true);

      setFieldValue(field, value, shouldValidate);
    },
    [setFieldValue],
  );

  const memoizedTabPanelItems = useMemo(
    () =>
      articleTabItems({
        setFieldValue: handleSetFieldValue,
        setValues,
        setFieldError,
        setDirty,
        onSubmit: handleSubmit,
        articleId: values.articleId || articleId,
        readOnly: !haveCrudPermission,
        fundType,
      }),
    [handleSubmit, values.articleId, haveCrudPermission],
  );

  const onTabChange = useCallback(
    (_props, index) => {
      if ((articleId || values.articleId) && !dirty)
        return handleTabChange(_props, index);

      return handleOpenTabAlertDialog(index.toString());
    },
    [values.articleId, dirty],
  );

  const tabAlertDialogProps = useMemo(() => {
    if (articleId || values.articleId)
      return {
        subtitle: 'Do you want to discard the changes you made?',
        onClose: handleCloseTabAlertDialog,
        confirmButtonLabel: 'Discard',
        onConfirm: () => {
          handleTabChange({}, Number(isTabAlertDialogOpen));
          handleCloseTabAlertDialog();
          setDirty(false);
        },
      };

    return {
      subtitle: 'Please submit Article detail information first',
      onConfirm: handleCloseTabAlertDialog,
      confirmButtonLabel: 'Ok',
    };
  }, [values.articleUid, isTabAlertDialogOpen]);

  const headerInfoValues = useMemo(
    () => ({
      articleId: values.article?.id || articleId,
      articleUid: values.article?.articleUid,
      isFeatured: values.isFeatured,
      isHidden: values.isHidden,
      createdBy: values.createdBy,
      createdAt: values.createdAt,
      updatedBy: values.updatedBy,
      updatedAt: values.updatedAt,
    }),
    [
      values.article?.articleUid,
      values.isFeatured,
      values.isHidden,
      values.articleId,
      values.createdBy,
      values.createdAt,
      values.updatedAt,
      values.updatedBy,
      articleId,
    ],
  );

  return (
    <DetailsContainer
      refetch={handleRefetch}
      fundStatus={values.article?.status}
      disableSaveDraftBtn={isError}
      onBack={handleRedirectToArticleList}
      onChange={handleChange}
      values={headerInfoValues}
      backButtonLabel={articleId ? 'Article Details' : 'Add new Article'}
      onSaveDraft={handleSaveDraftArticle}
      setIsLoading={setIsLoading}
    >
      {isFetchLoading ||
      !Object.keys(values).length ||
      (articleId && !values?.article?.articleUid) ? (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item textAlign="center">
            <CircularProgress size={25} data-testid="progress-screen" />
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={4} data-testid="article-details-content">
          <Grid item xs={12}>
            <Tabs
              key={`${values.article?.id}-${dirty}-${isTabAlertDialogOpen}`}
              value={activeTabIdx}
              onChange={onTabChange}
              centered={false}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              <TabComponents
                activeTabIdx={activeTabIdx}
                tabItems={memoizedTabPanelItems}
                testIdPrefix="add-articles-tab"
              />
            </Tabs>
          </Grid>
          <Grid item xs>
            <TabPanels
              value={activeTabIdx}
              tabItems={memoizedTabPanelItems}
              articleProps={{
                values: values.article,
                errors: !!errors.article,
                ref: articleFormRef,
                imperativeHandleDepedencies: [handleSubmit],
              }}
              boxPanelProps={{ sx: { padding: 0 } }}
            />
          </Grid>
        </Grid>
      )}

      <ConfirmationDialog
        open={!!isTabAlertDialogOpen}
        title="Warning!"
        infoText=""
        {...tabAlertDialogProps}
        dialogContentDependencies={[tabAlertDialogProps.onConfirm]}
      />

      <SuccessDialog
        open={openSuccessDialog}
        onConfirm={handleCloseSuccessDialog}
      />

      <Loading loading={isLoading} popup />
    </DetailsContainer>
  );
};

export default ArticleDetails;
