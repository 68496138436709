import React from 'react';

import SummarySection from 'components/SummarySection';
import {
  SUMMARY_FIELDS_PERSONAL_DETAILS,
  SUMMARY_FIELDS_ADDRESS,
  SUMMARY_FIELDS_ADDRESS_VIEW,
  SUMMARY_FIELDS_IDENTIFICATION,
} from 'constants/investmentEntity';

import companyBeneficialOwnerValidationSchemas from 'validations/schema/addInvestment/company/beneficialOwner';

const Summary = ({
  setValues,
  values,
  detailsTitle = 'Beneficial Details',
  withEdit = true,
  sectionListView = false,
}) => (
  <>
    <SummarySection
      title={detailsTitle}
      fields={SUMMARY_FIELDS_PERSONAL_DETAILS(true)}
      setValues={setValues}
      values={values}
      validationSchema={companyBeneficialOwnerValidationSchemas[1]}
      formName="personalDetailsForm"
      withEdit={withEdit}
      sectionListView={sectionListView}
    />
    <SummarySection
      title="Address"
      fields={SUMMARY_FIELDS_ADDRESS}
      viewFields={SUMMARY_FIELDS_ADDRESS_VIEW}
      setValues={setValues}
      values={values}
      validationSchema={companyBeneficialOwnerValidationSchemas[2]}
      formName="addressForm"
      withEdit={withEdit}
      sectionListView={sectionListView}
    />
    <SummarySection
      title="Identification of Investor"
      fields={SUMMARY_FIELDS_IDENTIFICATION}
      setValues={setValues}
      values={values}
      validationSchema={companyBeneficialOwnerValidationSchemas[3]}
      formName="identificationForm"
      withEdit={withEdit}
      sectionListView={sectionListView}
    />
  </>
);

export default Summary;
