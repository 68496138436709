import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dropdown from 'components/Dropdown';
import SearchInput from 'components/Input/SearchInput';
import palette from 'theme/palette';

const SearchFilterHeader = ({
  searchValue,
  onChangeSearch,
  statusValue,
  statusOptions = [],
  onChangeStatus,
}) => (
  <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
    {onChangeSearch && (
      <Grid item xs="auto">
        <SearchInput value={searchValue} onChange={onChangeSearch} />
      </Grid>
    )}
    {onChangeStatus && (
      <>
        <Grid item xs="auto">
          <Typography variant="dataRoomLabel" color={palette.text.grey}>
            Status
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Dropdown
            value={statusValue}
            onChange={onChangeStatus}
            options={statusOptions}
            minheight={5.5}
            maxheight="44px"
            minWidth="120px"
          />
        </Grid>
      </>
    )}
  </Grid>
);

export default SearchFilterHeader;
