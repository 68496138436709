import React, { memo, useCallback } from 'react';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

// Reference: MUI Documentation.
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  margin: 3,
  boxShadow: `inset 0 0 0 1px ${theme.palette.reach.purpleHaze}, inset 0 -1px 0 ${theme.palette.reach.purpleHaze}`,
  backgroundColor: '#DDDFE3',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const defaultSx = { marginTop: ['40px', '64px'] };
const Checkbox = ({
  name,
  label,
  error,
  checked,
  onChange,
  sx = defaultSx,
  ...props
}) => {
  const handleChange = useCallback(
    (e) => {
      e.target.value = e.target.checked;
      onChange(e);
    },
    [onChange],
  );

  return (
    <>
      <FormControlLabel
        sx={sx}
        control={
          <MUICheckbox
            name={name}
            onChange={handleChange}
            checked={checked}
            icon={<BpIcon />}
            {...props}
          />
        }
        label={label}
      />
      <FormHelperText error>{error}</FormHelperText>
    </>
  );
};
export default memo(Checkbox);
