import React from 'react';

import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import palette from 'theme/palette';
import TextButton from './TextButton';

const AddButton = ({
  label,
  sx,
  iconColor = palette.text.link,
  textColor = 'link',
  ...props
}) => (
  <TextButton
    Icon={AddCircleIcon}
    iconSx={{
      width: '20px',
      height: '20px',
      marginRight: 'unset',
      color: iconColor,
    }}
    sx={{ margin: '34.5px 0 0 14px', ...sx }}
    {...props}
  >
    <Typography
      variant="buttonTextBluish"
      color={textColor}
      sx={{ marginLeft: '14px' }}
    >
      {label}
    </Typography>
  </TextButton>
);

export default AddButton;
