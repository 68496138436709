import React, { useEffect, useState, memo, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import TextButton from 'components/Button/TextButton';
import palette from 'theme/palette';
import ShowMe from 'components/ShowMe';

// local function
const formatTimerDisplay = (sec) => {
  const secNum = parseInt(sec, 10);
  const hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

// timer in seconds
const CountDownTimer = ({
  timer = 10,
  label = 'Resend code',
  onClick,
  containerStyle,
  typographyVariant = 'h6',
  disabled = false,
  showTimer = true,
  direction = 'row',
  initialTimer,
}) => {
  const [timeLeft, setTimeLeft] = useState(Number(initialTimer || timer));

  useEffect(() => {
    if (!timeLeft) return undefined;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

  const handleResetTimer = useCallback(() => {
    setTimeLeft(timer);

    if (typeof onClick === 'function') onClick();
  }, [onClick, timer]);

  return (
    <Grid
      container
      spacing={2}
      direction={direction}
      style={{
        justifyContent: 'center',
        ...containerStyle,
      }}
    >
      {showTimer && (
        <Grid item>
          <Typography
            variant={typographyVariant}
            sx={{
              display: 'flex',
              alignItems: 'center',
              placeContent: 'center',
            }}
          >
            {formatTimerDisplay(timeLeft)}
          </Typography>
        </Grid>
      )}
      <ShowMe when={!!onClick}>
        <Grid item>
          <TextButton
            data-testid="reset-button"
            disabled={timeLeft > 0 || disabled}
            variant="buttonTextLightSerif"
            typographySx={{ fontWeight: 600, color: palette.text.menuLink }}
            onClick={handleResetTimer}
          >
            {label}
          </TextButton>
        </Grid>
      </ShowMe>
    </Grid>
  );
};
export default memo(CountDownTimer);
