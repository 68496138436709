import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { REPORTING_TEMPLATE_CLOSED_END } from 'constants/funds';

import TermOpenEndTemplate from './TermOpenEndTemplate';
import TermClosedEndTemplate from './TermClosedEndTemplate';

const TermTab = ({ portfolio }) => {
  const TermComponent = useCallback(() => {
    if (portfolio?.fund?.reportingTemplate === REPORTING_TEMPLATE_CLOSED_END) {
      return <TermClosedEndTemplate portfolio={portfolio} />;
    }

    return <TermOpenEndTemplate />;
  }, [portfolio.reportingTemplate]);

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Typography variant="title">Fund Lifecycle</Typography>
      </Grid>
      <Grid item xs={12}>
        <TermComponent />
      </Grid>
    </Grid>
  );
};

export default TermTab;
