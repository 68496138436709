import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import history from 'utils/history';
import getFullName from 'utils/getFullName';
import PayloadMappers from 'utils/PayloadMappers';
import ResponseMappers from 'utils/ResponseMappers';
import useDialog from 'hooks/useDialog';
import useFetchApi from 'hooks/useFetchApi';
import useTabs from 'hooks/useTabs';
import useAdminRestriction from 'hooks/useAdminRestriction';

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';
import SuccessDialog from 'components/Dialog/SuccessDialog';
import Tabs from 'components/Tabs';
import TabComponents from 'containers/DashboardPage/components/FundDetail/TabComponents';
import TabPanels from 'components/TabPanels';
import { useStores } from 'components/StateProvider';
import { FUND_CATEGORIES } from 'constants/funds';

import DetailsContainer from './DetailsContainer';
import {
  editFund,
  fetchAdminFundDetail,
  fundTabItems,
  sendAddFunds,
} from './HelperFunction';

const tabIdMapper = [
  'fundInformation',
  'fundDetails',
  'summaryChart',
  'financialInformations',
  'dataRoom',
];

const handleRedirectToFundList = () => history.push('/admin/funds');

const FundDetails = () => {
  const {
    auth: { user },
  } = useStores();
  const {
    isDialogOpen: isTabAlertDialogOpen,
    handleOpenDialog: handleOpenTabAlertDialog,
    handleCloseDialog: handleCloseTabAlertDialog,
  } = useDialog();
  const { haveCrudPermission } = useAdminRestriction();

  const [searchParams, setSearchParams] = useSearchParams();
  const [fundType] = useState(searchParams.get('type'));
  const [fetchStatus, setFetchStatus] = useState(true);
  const [isError, setIsError] = useState(false);
  // Manually manage 'dirty', because the form value is a deep object.
  const [dirty, setDirty] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const { idFund } = useParams();

  const adminGetFundDetail = useMemo(
    () => fetchAdminFundDetail(idFund),
    [idFund, fetchStatus],
  );

  const { apiData: dataValues, isFetchLoading } = useFetchApi({
    url: adminGetFundDetail,
    defaultApiData: {},
    dependencies: [adminGetFundDetail],
  });

  const handleRefetch = useCallback(
    () => setFetchStatus((prevState) => !prevState),
    [],
  ); // trigger useMemo changes to refetch

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    setValues,
    handleChange,
  } = useFormik({
    initialValues: idFund
      ? {}
      : ResponseMappers.admin.fundDetails({
          fundInformation: {
            overview: fundType === FUND_CATEGORIES.CASH ? '' : undefined,
          },
        }),
  });

  useEffect(() => {
    if (!isFetchLoading && Object.keys(dataValues).length)
      setValues(ResponseMappers.admin.fundDetails(dataValues));
  }, [isFetchLoading]);

  const handleOpenSuccessDialog = useCallback(
    () => setOpenSuccessDialog(true),
    [],
  );

  const handleCloseSuccessDialog = useCallback(() => {
    setOpenSuccessDialog(false);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleSubmit = useCallback(
    async (formValues) => {
      const payload = PayloadMappers.admin.fundDetails.addFundDetails({
        ...formValues,
        fundId: values.fundId,
        isHidden: values.isHidden,
        isOpen: values.isOpen,
        isRedemptionVisible: values.isRedemptionVisible,
        reportingTemplate: values.reportingTemplate,
        submitToDraft: true,
        createdBy: getFullName(user),
        updatedBy: getFullName(user),
        isCashFund: fundType === FUND_CATEGORIES.CASH,
        deletedFinancialInformations: values.deletedFinancialInformations,
        deletedFundDocuments: values.deletedFundDocuments,
      });

      let res;
      if (!idFund) {
        res = await sendAddFunds({ payload });
      } else {
        res = await editFund({ id: idFund, payload });
      }

      if (res?.idFund) {
        setFieldValue('fundId', res.fundId);
        handleOpenSuccessDialog();

        if (values.deletedFinancialInformations)
          setFieldValue('deletedFinancialInformations', []);
        if (values.deletedFundDocuments)
          setFieldValue('deletedFundDocuments', []);
      }

      setDirty(false);

      return res;
    },
    [
      values.fundId,
      values.isHidden,
      values.isOpen,
      values.deletedFinancialInformations,
      values.deletedFundDocuments,
      values.isRedemptionVisible,
      values.reportingTemplate,
    ],
  );

  const defaultTabIdx = useMemo(() => {
    if (!values.fundId) return 0;

    const index = tabIdMapper.indexOf(searchParams.get('tab'));
    if (index === -1) return 0;
    return index;
  }, []);

  const { activeTabIdx, handleTabChange } = useTabs({
    defaultTabIdx,
    callbackDependencies: [values.fundId],
  });

  useEffect(() => {
    searchParams.set('tab', tabIdMapper[activeTabIdx]);
    setSearchParams(searchParams);
  }, [activeTabIdx]);

  useEffect(() => {
    setIsError(
      !!Object.values(errors).filter((err) => err).length ||
        !values.fundInformation?.fundInformation?.fundName,
    );
  }, [errors, values.fundInformation?.fundInformation?.fundName]);

  const handleSetFieldValue = useCallback(
    (field, value, shouldValidate, updateDirty = true) => {
      if (updateDirty) setDirty(true);

      setFieldValue(field, value, shouldValidate);
    },
    [setFieldValue],
  );

  const memoizedTabPanelItems = useMemo(
    () =>
      fundTabItems({
        setFieldValue: handleSetFieldValue,
        setValues,
        setFieldError,
        setDirty,
        onSubmit: handleSubmit,
        idFund: values.idFund || idFund,
        readOnly: !haveCrudPermission,
        fundType,
      }),
    [handleSubmit, values.idFund, haveCrudPermission],
  );

  const tabTitleOptions = useMemo(
    () =>
      values.fundDetails?.map((fundDetail) => ({
        label: fundDetail.title,
        value: fundDetail.idTab,
      })),
    [values.fundDetails],
  );

  const onTabChange = useCallback(
    (_props, index) => {
      if ((idFund || values.idFund) && !dirty)
        return handleTabChange(_props, index);

      return handleOpenTabAlertDialog(index.toString());
    },
    [values.idFund, dirty],
  );

  const tabAlertDialogProps = useMemo(() => {
    if (idFund || values.idFund)
      return {
        subtitle: 'Do you want to discard the changes you made?',
        onClose: handleCloseTabAlertDialog,
        confirmButtonLabel: 'Discard',
        onConfirm: () => {
          handleTabChange({}, Number(isTabAlertDialogOpen));
          handleCloseTabAlertDialog();
          setDirty(false);
        },
      };

    return {
      subtitle: 'Please submit Fund Information first',
      onConfirm: handleCloseTabAlertDialog,
      confirmButtonLabel: 'Ok',
    };
  }, [values.fundId, isTabAlertDialogOpen]);

  const headerInfoValues = useMemo(
    () => ({
      idFund: values.idFund || idFund,
      isOpen: values.isOpen,
      isHidden: values.isHidden,
      isRedemptionVisible: values.isRedemptionVisible,
      reportingTemplate: values.reportingTemplate || '',
      createdBy: values.createdBy,
      createdAt: values.createdAt,
      updatedBy: values.updatedBy,
      updatedAt: values.updatedAt,
    }),
    [
      values.isOpen,
      values.isHidden,
      values.idFund,
      values.createdBy,
      values.createdAt,
      values.updatedAt,
      values.updatedBy,
      idFund,
      values.isRedemptionVisible,
      values.reportingTemplate,
    ],
  );

  return (
    <DetailsContainer
      refetch={handleRefetch}
      fundStatus={values.status}
      disableSaveDraftBtn={isError}
      onBack={handleRedirectToFundList}
      onChange={handleChange}
      values={headerInfoValues}
      backButtonLabel={idFund ? 'Fund Details' : 'Add new Fund'}
    >
      {isFetchLoading || !Object.keys(values).length ? (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item textAlign="center">
            <CircularProgress size={25} data-testid="progress-screen" />
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={4} data-testid="fund-details-content">
          <Grid item xs={12}>
            <Tabs
              key={`${values.fundId}-${dirty}-${isTabAlertDialogOpen}`}
              value={activeTabIdx}
              onChange={onTabChange}
              centered={false}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
            >
              <TabComponents
                activeTabIdx={activeTabIdx}
                tabItems={memoizedTabPanelItems}
                testIdPrefix="add-funds-tab"
              />
            </Tabs>
          </Grid>
          <Grid item xs>
            <TabPanels
              value={activeTabIdx}
              tabItems={memoizedTabPanelItems}
              fundInformationProps={{
                values: values.fundInformation,
                errors: !!errors.fundInformation,
              }}
              dataRoomProps={{
                values: values.dataRooms,
                errors: !!errors.dataRooms,
              }}
              fundDetailsProps={{
                values: values.fundDetails,
                errors: !!errors.fundDetails,
              }}
              financialInformationsProps={{
                values: values.financialInformations,
                errors: !!errors.financialInformations,
                deletedFinancialInformations:
                  values.deletedFinancialInformations,
                deletedFundDocuments: values.deletedFundDocuments,
              }}
              summaryChartProps={{
                values: values.summaryChart,
                tabTitleOptions,
              }}
              boxPanelProps={{ sx: { padding: 0 } }}
            />
          </Grid>
        </Grid>
      )}

      <ConfirmationDialog
        open={!!isTabAlertDialogOpen}
        title="Warning!"
        infoText=""
        {...tabAlertDialogProps}
        dialogContentDependencies={[tabAlertDialogProps.onConfirm]}
      />

      <SuccessDialog
        open={openSuccessDialog}
        onConfirm={handleCloseSuccessDialog}
      />
    </DetailsContainer>
  );
};

export default FundDetails;
