import React, { memo } from 'react';

import Grid from '@mui/material/Grid';

import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import FieldContainer from 'components/FieldContainer';
import FormTitle from 'components/FormTitle';
import TextEditor from 'components/TextEditor';
import Input from 'components/Input';

import { ARTICLE_CATEGORY_OPTIONS } from 'constants/articles';

const minDate = new Date().toISOString();

const EventOverview = ({
  readOnly,
  onChange,
  values,
  errors = {},
  onBlur,
  setFieldValue,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <FormTitle title="Event" />
    </Grid>

    <Grid container item xs={12} rowSpacing={3}>
      <FieldContainer
        item
        xs={7}
        label="Event Category"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Dropdown
          name="category"
          placeholder="Event Category"
          options={ARTICLE_CATEGORY_OPTIONS}
          disabled={readOnly}
          onChange={onChange}
          value={values.category}
          error={!!errors.category}
          errorValue={errors.category}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Event Name"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="title-input"
          id="title"
          name="title"
          placeholder="Event Name"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.title}
          error={!!errors.title}
          errorValue={errors.title}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Date and Time"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <DatePicker
          fullWidth
          name="date"
          testId="date-input"
          onChange={onChange}
          value={values.date}
          onBlur={onBlur}
          setFieldValue={setFieldValue}
          datePickerType="dateTime"
          minDate={minDate}
        />
      </FieldContainer>
      <FieldContainer
        item
        xs={7}
        label="Link"
        labelGridProps={{ xs: 4 }}
        childGridProps={{ xs: 8 }}
      >
        <Input
          fullWidth
          data-testid="link-input"
          id="link"
          name="link"
          placeholder="link"
          disabled={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          value={values.link}
          error={!!errors.link}
          errorValue={errors.link}
        />
      </FieldContainer>
      <FieldContainer
        item
        label=""
        variant="title"
        childGridProps={{ sx: { minHeight: '300px' } }}
      >
        <TextEditor
          id="event-content-texteditor"
          onChange={onChange}
          value={values.content}
          name="content"
          options={{ imageFileInput: false }}
        />
      </FieldContainer>
    </Grid>
  </Grid>
);

export default memo(EventOverview);
