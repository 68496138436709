import React from 'react';

import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboardOutlined';

import Services from 'api/services';

import {
  amountCellRenderer,
  amountWithDollarCellRenderer,
  dateCellRenderer,
  percentCellRenderer,
} from 'utils/Datatables/cellRenderer';

import FundUpdates from './FundUpdates';
import FundRegistry from './FundRegistry';
import FundPerformance from './FundPerformance';

export const getFirstOverviewHeaders = ({ currency = 'USD' } = {}) => [
  {
    field: 'date',
    headerName: 'Last Update',
    flex: 1,
    renderCell: dateCellRenderer,
  },
  {
    field: 'totalAllApplications',
    headerName: 'Applications',
    flex: 1,
    renderCell: amountCellRenderer,
  },
  {
    field: 'totalApprovedApplications',
    headerName: 'Investors',
    flex: 1,
    renderCell: amountCellRenderer,
  },
  {
    field: 'term',
    headerName: 'Term',
    flex: 1,
  },
  {
    field: 'totalValueApprovedApplications',
    headerName: `Total Commitments (${currency})`,
    flex: 1,
    renderCell: amountWithDollarCellRenderer,
  },
  {
    field: 'called',
    headerName: '% Called',
    flex: 1,
    renderCell: percentCellRenderer,
  },
  {
    field: 'toCall',
    headerName: '% to Call',
    flex: 1,
    renderCell: percentCellRenderer,
  },
];

export const getSecondOverviewHeaders = ({ currency = 'USD' } = {}) => [
  {
    field: 'totalApprovedUnits',
    headerName: 'Total Units',
    flex: 1,
    renderCell: amountCellRenderer,
  },
  {
    field: 'distribution',
    headerName: 'Total Distributions',
    flex: 1,
    renderCell: amountWithDollarCellRenderer,
  },
  {
    field: 'unitPriceNet',
    headerName: `Unit Price (Paid-in, ${currency})`,
    flex: 1,
    renderCell: amountWithDollarCellRenderer,
  },
  {
    field: 'unitPriceGross',
    headerName: `Unit Price Committed, ${currency})`,
    flex: 1,
    renderCell: amountWithDollarCellRenderer,
  },
  {
    field: 'balanceNet',
    headerName: `Balance (Paid-in, ${currency})`,
    flex: 1,
    renderCell: amountWithDollarCellRenderer,
  },
  {
    field: 'balanceGross',
    headerName: `Balance (Committed, ${currency})`,
    flex: 1,
    renderCell: amountWithDollarCellRenderer,
  },
];

export const fetchAdminFundDetailById = async (id) => {
  try {
    if (!id) return {};

    const res = await Services.adminGetFundDetailById(id);
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const fetchAdminFundUpdatesByFundConfigurationId = async (
  fundConfigurationId,
) => {
  try {
    if (!fundConfigurationId) return {};

    const res = await Services.adminGetFundUpdatesByFundConfigurationId(
      fundConfigurationId,
    );
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const fetchAdminFundRegistriesByFundConfigurationId = async ({
  fundConfigurationId,
  maxDate,
}) => {
  try {
    if (!fundConfigurationId) return {};

    const res = await Services.adminGetFundRegistriesByFundConfigurationId({
      fundConfigurationId,
      maxDate,
    });
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const fetchAdminFundUpdatesOverviewByFundConfigurationId = async (
  fundConfigurationId,
) => {
  try {
    if (!fundConfigurationId) return {};

    const res = await Services.adminGetFundUpdatesOverviewByFundConfigurationId(
      fundConfigurationId,
    );
    return res?.data;
  } catch (_) {
    return {};
  }
};

export const getReportingDetailsService = async ({
  fundConfigurationId,
  maxDate,
}) => {
  const fundUpdates = await fetchAdminFundUpdatesByFundConfigurationId(
    fundConfigurationId,
  );
  const fundUpdatesOverviewData =
    await fetchAdminFundUpdatesOverviewByFundConfigurationId(
      fundConfigurationId,
    );

  const fundUpdatesOverview = {
    firstOverview: [
      getFirstOverviewHeaders().reduce(
        (overviews, header) => ({
          ...overviews,
          [header.field]: fundUpdatesOverviewData[header.field],
        }),
        { id: 'overview-1' },
      ),
    ],
    secondOverview: [
      getSecondOverviewHeaders().reduce(
        (overviews, header) => ({
          ...overviews,
          [header.field]: fundUpdatesOverviewData[header.field],
        }),
        { id: 'overview-2' },
      ),
    ],
  };

  const fundRegistries = await fetchAdminFundRegistriesByFundConfigurationId({
    fundConfigurationId,
    maxDate,
  });

  return {
    fundUpdates,
    fundUpdatesOverview,
    fundRegistries,
  };
};

export const reportingTabItems = (props = {}) => [
  {
    key: 'fundUpdates',
    Icon: SpaceDashboardIcon,
    label: <Typography variant="h6">Fund Updates</Typography>,
    content: <FundUpdates fieldKey="fundUpdates" {...props} />,
  },
  {
    key: 'fundRegistry',
    Icon: DashboardIcon,
    label: <Typography variant="h6">Fund Registry</Typography>,
    content: <FundRegistry fieldKey="fundRegistry" {...props} />,
  },
  {
    key: 'fundPerformance',
    Icon: DashboardIcon,
    label: <Typography variant="h6">Fund Performance</Typography>,
    content: <FundPerformance fieldKey="fundPerformance" {...props} />,
  },
];
