import React from 'react';

import Grid from '@mui/material/Grid';

import useDataTables from 'hooks/useDataTables';
import services from 'api/services';

import CommitmentList from './CommitmentList';
import CommitmentsChart from './CommitmentsChart';

const CommitmentTab = ({ portfolio }) => {
  const {
    pageSize,
    apiData: commitments,
    isFetchLoading,
    setPage,
    setSort,
  } = useDataTables({
    fetchService: () =>
      services.getUserInvestmentCommitmentsByFundConfigurationId({
        fundConfigurationId: portfolio?.fundConfigurationId,
        userInvestorMapId: portfolio?.userInvestorMapFk,
      }),
    defaultApiData: [],
  });

  return (
    <Grid container rowSpacing={4}>
      <Grid
        item
        xs={12}
        p={{ xs: 0, lg: '0px 10% 0px 0%', xl: '0px 15% 0px 0%' }}
      >
        <CommitmentsChart commitments={commitments} portfolio={portfolio} />
      </Grid>
      <Grid item xs={12}>
        <CommitmentList commitments={commitments} portfolio={portfolio} />
      </Grid>
    </Grid>
  );
};

export default CommitmentTab;
