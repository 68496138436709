import React, { memo, useCallback, useEffect } from 'react';

import lazyLoad from 'utils/lazyLoad';
import useSteps from 'hooks/useSteps';

import trustProfileValidationSchemas from 'validations/schema/addInvestment/trustProfileValidationSchemas';
import TrustProfileStepContainer from './TrustProfileStepContainer';

const TrustProfileForm = lazyLoad(() => import('./TrustProfileForm'));
const TrustProfileDocumentForm = lazyLoad(() =>
  import(
    'containers/AddInvestmentEntity/components/Forms/TrustProfileDocumentForm'
  ),
);
const SelectTrustees = lazyLoad(() => import('./Steps/SelectTrustees'));

const stepComponents = [
  <TrustProfileForm />,
  <TrustProfileDocumentForm />,
  <SelectTrustees />,
];

const TrustProfile = ({
  onNext,
  onBack,
  values,
  setValues,
  SideIcon,
  steps,
  isSubmitting,
  activeStep,
  startAtLastStep,
}) => {
  const handleOnNextInLastPage = ({ values: trustProfileValues }) => {
    const { trusteeType, ...trustProfile } = trustProfileValues;
    if (setValues) {
      setValues({ ...values, trusteeType, trustProfile });
    }
    onNext();
  };

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: trustProfileForm,
  } = useSteps({
    stepComponents,
    validateFormik: true,
    validationSchemas: trustProfileValidationSchemas,
    formParameters: {
      initialValues: {
        trustType: '',
        country: '',
        fullName: '',
        trustProfileDocuments: [],
        ...values?.trustProfile,
      },
    },
    onNextInLastPage: handleOnNextInLastPage,
    onBackInFirstPage: onBack,
    defaultPageIndex: startAtLastStep ? stepComponents.length - 1 : 0,
  });

  useEffect(() => {
    trustProfileForm?.validateForm();
  }, [pageIndex, trustProfileForm?.validateForm]);

  const handleStepBack = useCallback(() => {
    const isSelectTrustee = pageIndex === stepComponents.length - 1;

    if (isSelectTrustee && values?.addingNewTrustee) {
      handleNext();

      return;
    }

    handleBack();
  }, [pageIndex, values]);

  return (
    <TrustProfileStepContainer
      title="Trust Details"
      containerProps={{
        onBack: handleStepBack,
        onNext: handleNext,
        isNextButtonDisabled:
          !!Object.keys(trustProfileForm.errors).length || isSubmitting,
      }}
      SideIcon={SideIcon}
      steps={steps}
      stepTitle="Trust / Superannuation Details"
      activeStep={activeStep}
      isSelectTrustee={pageIndex === stepComponents.length - 1}
    >
      {React.cloneElement(stepComponents[pageIndex], {
        form: trustProfileForm,
        errors: trustProfileForm.errors,
        values: trustProfileForm.values,
        touched: trustProfileForm.touched,
        pageIndex,
        onNext: handleNext,
        onBack: handleBack,
        onChange: trustProfileForm.handleChange,
        setValues: trustProfileForm.setValues,
        onBlur: trustProfileForm.handleBlur,
        setFieldError: trustProfileForm.setFieldError,
      })}
    </TrustProfileStepContainer>
  );
};

export default memo(TrustProfile);
