import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import DashboardHelperFunctions from 'containers/DashboardPage/HelperFunction';
import InvestmentEntityStepContainer from 'containers/AddInvestmentEntity/components/InvestmentEntityStepContainer';

import Loading from 'components/Loading';
import ShowMe from 'components/ShowMe';
import FailedDialog from 'components/Dialog/FailedDialog';
import { useStores } from 'components/StateProvider';

import useSteps from 'hooks/useSteps';
import useFetchApi from 'hooks/useFetchApi';
import segmentTrack from 'hooks/useSegmentAnalytic/segmentTrack';
import getFullName from 'utils/getFullName';
import condTrueFalse from 'utils/condTrueFalse';
import history from 'utils/history';
import getNumbers from 'utils/strings/getNumbers';
import investmentApplicationSchemas from 'validations/schema/investmentApplication/investmentApplicationSchemas';

import { SEGMENT_ANALYTICS } from 'constants/segmentAnalytics';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import FundCard from 'components/FundCard';
import palette from 'theme/palette';

import FundInvestmentSuccess from './FundInvestmentSuccess';
import SelectEntity from './Steps/SelectEntity';
import InvestmentAmount from './Steps/InvestmentAmount';
import DocumentSigning from './Steps/DocumentSigning';
import EntityDetail from './EntityDetail';
import HelperFunction from './HelperFunction';

const handleRedirectPortfolioTab = () => history.replace('/?tab=portfolio');

const steps = [
  {
    label: 'Select Entity',
    component: <SelectEntity />,
  },
  {
    label: 'Investment Amount',
    component: <InvestmentAmount />,
  },
  {
    label: 'Sign Contract',
    component: <DocumentSigning />,
  },
  {
    label: '',
    component: (
      <FundInvestmentSuccess
        buttonLabel="My Portfolio"
        onClick={handleRedirectPortfolioTab}
        endStepSubtitle="Thank you for submitting your investment application form."
        reviewText="Therefore, we will carefully review your application before approving your account for investment."
        containerLabel={<b>Investment Application Form</b>}
        withIcon={false}
        buttonColor="purpleHazeGradient"
      />
    ),
  },
];

const stepComponents = steps.map((step) => step.component);
const stepLabels = steps.reduce((labels, step) => {
  if (!step.label) return labels;

  return labels.concat({ label: step.label });
}, []);

const InvestmentApplicationPage = () => {
  const { fundId } = useParams();
  const [error, setError] = useState('');

  const {
    auth: { user },
  } = useStores();

  const [startAtLastStep, setStartAtLastStep] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFundDetail = useMemo(
    () => DashboardHelperFunctions.fetchFundDetail(fundId),
    [fundId],
  );
  const { apiData: fundDetailData } = useFetchApi({
    defaultApiData: {},
    url: getFundDetail,
  });

  const {
    handleBack,
    handleNext,
    pageIndex,
    form: investmentApplicationForm,
  } = useSteps({
    stepComponents,
    validateFormik: true,
    validationSchemas: investmentApplicationSchemas,
    formParameters: {
      initialValues: {
        investmentAmount: 0,
        investmentEntity: '',
        signedAt: new Date(),
        signedBy: getFullName(user, { withMiddleName: true }),
        isConfirmed: false,
        fundId,
      },
      onSubmit: HelperFunction.submitFundInvestment,
    },
    firstStepGoBackURL: `/dashboard/funds?tab=funds&fundId=${fundId}`,
  });

  useEffect(() => {
    investmentApplicationForm?.validateForm();
  }, [pageIndex, investmentApplicationForm?.validateForm]);

  const handleNextStep = async ({ submit } = {}) => {
    if (!submit) {
      setStartAtLastStep(false);
      handleNext();

      return;
    }

    try {
      setLoading(true);
      if (!investmentApplicationForm.isSubmitting) {
        await investmentApplicationForm.submitForm();

        segmentTrack(SEGMENT_ANALYTICS.SUBMIT_FUND_INVESTMENT, {
          investmentAmount: getNumbers(
            investmentApplicationForm.values.investmentAmount,
          ),
          fundName: fundDetailData?.fundInformation?.fundName,
          email: user?.email,
        });
        handleNext();
      }
    } catch (err) {
      setError('Fund investment submit failed');
    } finally {
      setLoading(false);
    }
  };

  const handleBackStep = (args) => {
    setStartAtLastStep(true);
    handleBack(args);
  };

  const containerProps = useMemo(
    () => ({
      withHeader: false,
      isNextButtonDisabled:
        !!Object.keys(investmentApplicationForm.errors).length ||
        investmentApplicationForm.isSubmitting,
      backgroundcolor: 'transparent',
      sx: {
        px: [0, 2, 10, 15, 25],
      },
    }),
    [
      pageIndex,
      investmentApplicationForm.values,
      investmentApplicationForm.errors,
      investmentApplicationForm.isSubmitting,
    ],
  );

  const isSuccessStep = useMemo(
    () => pageIndex === steps.length - 1,
    [pageIndex],
  );

  const selectedFundConfiguration = useMemo(() => {
    if (
      !investmentApplicationForm.values.financialInformationClass ||
      !fundDetailData?.financialInformations.length
    ) {
      return null;
    }

    return fundDetailData.financialInformations.find(
      (financialInformation) =>
        financialInformation.id ===
        Number(investmentApplicationForm.values.financialInformationClass),
    );
  }, [
    investmentApplicationForm.values.financialInformationClass,
    fundDetailData?.financialInformations,
  ]);

  return (
    <InvestmentEntityStepContainer
      title={condTrueFalse(isSuccessStep, '', 'Investment Application Form')}
      containerProps={containerProps}
      stepperContainerProps={{ sm: 12, md: 12 }}
      steps={stepLabels}
      activeStep={pageIndex}
      withCancelAll={false}
      withStepper={!isSuccessStep}
      fullWidthChildren
      lineStepper
    >
      <ShowMe when={!isSuccessStep && fundDetailData}>
        <FundCard
          viewOnly
          mode="landscape"
          title={fundDetailData?.fundInformation?.fundName}
          fundType={fundDetailData?.fundInformation?.type}
          headerBgColor={
            fundDetailData?.fundInformation?.headerColor || palette.reach.purple
          }
          headerImageProps={{
            style: { position: 'absolute', bottom: '4px' },
          }}
          contentList={fundDetailData?.fundInformation?.fundCard}
          headerImageSrc={
            fundDetailData?.fundInformation?.imageAndDocument?.header
          }
          logoSrc={fundDetailData?.fundInformation?.imageAndDocument?.logo}
          isOpen={fundDetailData?.fundInformation?.isOpen}
          disabled={!fundDetailData?.fundInformation?.isOpen}
          isInvested={fundDetailData?.isInvested}
        />
      </ShowMe>
      <ShowMe when={pageIndex > 0 && !isSuccessStep}>
        <EntityDetail
          entityDetail={investmentApplicationForm.values?.entityDetail}
        />
      </ShowMe>
      {React.cloneElement(stepComponents[pageIndex], {
        startAtLastStep,
        onNext: handleNextStep,
        onBack: handleBackStep,
        SideIcon: PersonIcon,
        steps: stepLabels,
        values: investmentApplicationForm.values,
        setValues: investmentApplicationForm.setValues,
        isSubmitting: investmentApplicationForm.isSubmitting,
        onChange: investmentApplicationForm.handleChange,
        fundInformationClasses: fundDetailData?.financialInformations,
        fundConfiguration: selectedFundConfiguration,
        errors: investmentApplicationForm.errors,
        touched: investmentApplicationForm.touched,
        onBlur: investmentApplicationForm.handleBlur,
      })}

      <Loading popup loading={loading} />
      <FailedDialog
        open={!!error}
        onConfirm={() => setError('')}
        subtitle={error}
      />
    </InvestmentEntityStepContainer>
  );
};

export default InvestmentApplicationPage;
