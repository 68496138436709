import mapValue from 'utils/mapValue';

const personalDetails = (personalDetail) => ({
  title: mapValue(personalDetail?.title, ''),
  firstName: mapValue(personalDetail?.firstName, ''),
  middleName: mapValue(personalDetail?.middleName, ''),
  lastName: mapValue(personalDetail?.lastName, ''),
  countryCode: mapValue(personalDetail?.countryCode, ''),
  phoneNumber: mapValue(personalDetail?.phoneNumber, ''),
  email: mapValue(personalDetail?.email, ''),
  dateOfBirth: mapValue(personalDetail?.dateOfBirth, ''),
  country: mapValue(personalDetail?.country, ''),
});

export default personalDetails;
